<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu> 
	</mat-drawer>
    <div style="background-color: #f6f6f6;padding: 20px;">
<br>
<div class="row">
<div class="col-lg-12 col-md-12">
   
    <h4 class="titlehead viewRpt"> <span (click)="viewReport()"> View Report</span></h4>
    <!-- <h4 class="titlehead" style="padding-left: 5px;position: relative;top: 4px;z-index: 9;">Whatsapp Dashboard</h4>  -->
    </div>
    </div>
<br>
    <mat-card class="CN-Card1">
        <div class="row">
            <div class="col-md-3 col-sm-12 col-xs-12">
                <span>
                    <mat-select [ngClass]="'arrowDDLcs'" class="dropdownCs" [(ngModel)]="selectedProject">
                        <mat-option [value]="selectedProject">{{selectedProject}}</mat-option>                          
                    </mat-select>
                </span>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
                <mat-select [ngClass]="'arrowDDLcs'"  class="dropdownCs" [(value)]="selectedChannel" (selectionChange)="navigateTo($event.value)">
					<mat-option value="view/outbound" *ngIf="settings.web && settings.web[0].definition.includes('outbound')==true">Outbound</mat-option>
					<mat-option value="dashboard-inbound" *ngIf="settings.web && settings.web[0].definition.includes('inbound')==true">Inbound</mat-option>
					<mat-option value="whatsapp-dashboard" *ngIf="settings.web && settings.web[0].definition.includes('whatsapp')==true">Whatsapp</mat-option>
                </mat-select>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
                <span >
                    <mat-select [ngClass]="'arrowDDLcs'" class="dropdownCs" [(value)]="selectedDayWise"
                        (selectionChange)="OnChangeDayTime($event.value)">
                        <mat-option value="Today">Today</mat-option>
                        <mat-option value="This Month">This Month</mat-option>
                        <mat-option value="This Year">This Year</mat-option>
                    </mat-select>
                </span>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 cn-dat">{{getCurrentDate()}}</div>
        </div>
    </mat-card>





    <div class="row">
        <div class="col-md col-sm-12 col-xs-12">
            <mat-card class="CN-Card cardW">
                <p><span class="CN-Tab-head1">Sent </span></p>
                <p class="cn-p CNP1">{{wa_today[0]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                <hr class="hr1">
                <p class="cn-p CNP2">{{wa_yesterday[0]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                <div style="display: none;">
                <hr class="hr2">
                <p [hidden]="wa_today[0]!=wa_yesterday[0]">
                          <span>
                              <mat-icon class="upArrow">arrow_upward</mat-icon>
                          </span>
                  <span class="upData">100%</span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
                </p>
                <p [hidden]="wa_today[0]==wa_yesterday[0] || wa_today[0]<wa_yesterday[0]">
                          <span>
                              <mat-icon class="upArrow">arrow_upward</mat-icon>
                          </span>
                  <span class="upData">{{ ((wa_today[0]-wa_yesterday[0])/wa_yesterday[0]) | percent }} </span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
                </p>
                <p [hidden]="wa_today[0]==wa_yesterday[0] || wa_today[0]>wa_yesterday[0]">
                            <span>
                                <mat-icon class="downArrow">arrow_downward</mat-icon>
                            </span>
                  <span class="downData">{{((wa_yesterday[0]-wa_today[0])/wa_yesterday[0]) | percent}} </span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
              </p>
            </div>
            </mat-card>
        </div>
        <div class="col-md col-sm-12 col-xs-12">
            <mat-card class="CN-Card cardW">
                <p><span class="CN-Tab-head1">Delivered </span></p>
                <p class="cn-p CNP3">{{wa_today[1]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                <hr class="hr1">
                <p class="cn-p CNP4">{{wa_yesterday[1]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                <div style="display: none;">
                <hr class="hr2">
                <p [hidden]="wa_today[1]!=wa_yesterday[1]">
                        <span>
                            <mat-icon class="upArrow">arrow_upward</mat-icon>
                        </span>
                  <span class="upData">100%</span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
                </p>
                <p [hidden]="wa_today[1]==wa_yesterday[1] || wa_today[1]<wa_yesterday[1]">
                        <span>
                            <mat-icon class="upArrow">arrow_upward</mat-icon>
                        </span>
                  <span class="upData">{{ ((wa_today[1]-wa_yesterday[1])/wa_yesterday[1]) | percent }} </span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
                </p>
                <p [hidden]="wa_today[1]==wa_yesterday[1] || wa_today[1]>wa_yesterday[1]">
                          <span>
                              <mat-icon class="downArrow">arrow_downward</mat-icon>
                          </span>
                  <span class="downData">{{((wa_yesterday[1]-wa_today[1])/wa_yesterday[1]) | percent}} </span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
                </p>
                </div>
            </mat-card>
        </div>
        <div class="col-md col-sm-12 col-xs-12">
            <mat-card class="CN-Card cardW">
                <p><span class="CN-Tab-head1">Received </span></p>
                <p class="cn-p CNP5" style="border-left:8px solid #d6d022">{{wa_today[4]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                <hr class="hr1">
                <p class="cn-p CNP6" style="border-left: 8px solid #736a6a;">{{wa_yesterday[4]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                <div style="display: none;">
                <hr class="hr4">
                <p [hidden]="wa_today[4]!=wa_yesterday[4]">
                        <span>
                            <mat-icon class="upArrow">arrow_upward</mat-icon>
                        </span>
                  <span class="upData">100%</span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
                </p>
                <p [hidden]="wa_today[4]==wa_yesterday[4] || wa_today[4]<wa_yesterday[4]">
                        <span>
                            <mat-icon class="upArrow">arrow_upward</mat-icon>
                        </span>
                  <span class="upData">{{ ((wa_today[4]-wa_yesterday[4])/wa_yesterday[4]) | percent }} </span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
                </p>
                <p [hidden]="wa_today[4]==wa_yesterday[4] || wa_today[4]>wa_yesterday[4]">
                          <span>
                              <mat-icon class="downArrow">arrow_downward</mat-icon>
                          </span>
                  <span class="downData">{{((wa_yesterday[4]-wa_today[4])/wa_yesterday[4]) | percent}} </span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
                </p>
                </div>
            </mat-card>
        </div>
        <div class="col-md col-sm-12 col-xs-12">
            <mat-card class="CN-Card cardW">
                <p><span class="CN-Tab-head1">Read </span></p>
                <p class="cn-p CNP5">{{wa_today[2]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                <hr class="hr1">
                <p class="cn-p CNP6">{{wa_yesterday[2]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                <div style="display: none;">
                <hr class="hr2">
                <p [hidden]="wa_today[2]!=wa_yesterday[2]">
                        <span>
                            <mat-icon class="upArrow">arrow_upward</mat-icon>
                        </span>
                  <span class="upData">100%</span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
                </p>
                <p [hidden]="wa_today[2]==wa_yesterday[2] || wa_today[2]<wa_yesterday[2]">
                        <span>
                            <mat-icon class="upArrow">arrow_upward</mat-icon>
                        </span>
                  <span class="upData">{{ ((wa_today[2]-wa_yesterday[2])/wa_yesterday[2]) | percent }} </span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
                </p>
                <p [hidden]="wa_today[2]==wa_yesterday[2] || wa_today[2]>wa_yesterday[2]">
                          <span>
                              <mat-icon class="downArrow">arrow_downward</mat-icon>
                          </span>
                  <span class="downData">{{((wa_yesterday[2]-wa_today[2])/wa_yesterday[2]) | percent}} </span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
                </p>
                </div>
            </mat-card>
        </div>
        <div class="col-md col-sm-12 col-xs-12">
            <mat-card class="CN-Card cardW">
                <p><span class="CN-Tab-head1">Failed </span> </p>
                <p class="cn-p CNP7">{{wa_today[3]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                <hr class="hr1">
                <p class="cn-p CNP8">{{wa_yesterday[3]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                <div style="display: none;">
                <hr class="hr2">
                <p [hidden]="wa_today[3]!=wa_yesterday[3]">
                      <span>
                          <mat-icon class="upArrow">arrow_upward</mat-icon>
                      </span>
                  <span class="upData">100%</span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
                </p>
                <p [hidden]="wa_today[3]==wa_yesterday[3] || wa_today[3]<wa_yesterday[3]">
                      <span>
                          <mat-icon class="upArrow">arrow_upward</mat-icon>
                      </span>
                  <span class="upData">{{ ((wa_today[3]-wa_yesterday[3])/wa_yesterday[3]) | percent }} </span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
                </p>
                <p [hidden]="wa_today[3]==wa_yesterday[3] || wa_today[3]>wa_yesterday[3]">
                        <span>
                            <mat-icon class="downArrow">arrow_downward</mat-icon>
                        </span>
                  <span class="downData">{{((wa_yesterday[3]-wa_today[3])/wa_yesterday[3]) | percent}} </span>
                  <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
                </p>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <mat-card class="CN-Card cardW">
                <p><span class="CN-Tab-head1">Sent Vs Delivered</span> </p>
                <div [chart]="sentDeliveredChart"> </div>
            </mat-card>
        </div>
    </div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <mat-table class="matTableCs" [dataSource]="dataSource">
                <ng-container matColumnDef="user_id">
                   <mat-header-cell *matHeaderCellDef> Agent Id </mat-header-cell>
                   <mat-cell *matCellDef="let element"> {{element.user_id}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                   <mat-header-cell *matHeaderCellDef> Agent Name </mat-header-cell>
                   <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <mat-header-cell *matHeaderCellDef> Agent Phone </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.phone}} </mat-cell>
                 </ng-container>
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef> Agent Email </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="contacted_customers">
                    <mat-header-cell *matHeaderCellDef> Contacted Customers </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.contacted_customers}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="total_messages_sent">
                    <mat-header-cell *matHeaderCellDef> Total Message Sent </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.total_messages_sent}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="template_messages_sent">
                    <mat-header-cell *matHeaderCellDef> Template Message Sent</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.template_messages_sent}} </mat-cell>
                 </ng-container>                                 
                 <ng-container matColumnDef="chat_messages_sent">
                    <mat-header-cell *matHeaderCellDef > Chat Message Sent </mat-header-cell>
                    <mat-cell *matCellDef="let element" > {{element.chat_messages_sent}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="messages_received">
                    <mat-header-cell *matHeaderCellDef > Message Received </mat-header-cell>
                    <mat-cell *matCellDef="let element" > {{element.messages_received}} </mat-cell>
                 </ng-container>
    
                 <ng-container matColumnDef="messages_failed">
                    <mat-header-cell *matHeaderCellDef > Message Failed </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.messages_failed}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="avg_first_message_response">
                    <mat-header-cell *matHeaderCellDef > Avg First Message Response </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.avg_first_message_response}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="avg_message_response">
                    <mat-header-cell *matHeaderCellDef > Avg Message Response </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.avg_message_response}} </mat-cell>
                 </ng-container>
                
                <mat-header-row *matHeaderRowDef=displayedColumns></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    


































</div>
    </mat-drawer-container>
