<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu> 
	  
	  
	</mat-drawer>
<div style="background: #f6f6f6;">

<div class="row">

  <div class="col-md-12">
    <h4 class="titlehead" style="margin-top:0;padding-left: 17px;position: relative;top: 75px;z-index: 9;">Upload Audio</h4>
  </div>	
</div>
<br><br>
<p class="text-center mt-3"><mat-icon>graphic_eq</mat-icon>
<span class="textNam">Attach Audio File</span><br>
</p>
<div class="upload-btn-wrapper text-center">
    <button class="btn">Choose File</button>
    <input type="file" id="fileID" (change)="fileEvent($event)" />
  </div>
  <br>
  <p class="text-center"><span  class="textNam"> {{_fileNameView}}</span>
    
    <span  class="textNam" style="color:green;font-size:20px;" *ngIf="fileUploaded"> {{_successMsg}}</span>
</p>
  <div  class="text-center" id="imagePreview"></div>

  <p class="text-center" *ngIf="showHidePleaseWaitMsg">
    <span class="textNam" style="color:#e40659">Please Wait.....</span>
    </p>

  <div  class="text-center">
      <button class="fileCancel" (click)="cancelBtn()">Cancel </button>
      <button class="fileSubmit" (click)="submitBtn()">Submit </button>
  </div>
  <br>
</div>
</mat-drawer-container>