<div style="background-color: #f2f2f2;">
    <br><br>
<mat-card class="mainCardRegister">

    <div class="row">
        <div class="col-md-12 col-sm-12 text-center">
        <img src="../assets/web_images/v-comnexEngageIPic.png" class="pt-4" alt="Gignex Logo" style="width: 150px;"/> 
        <p class="regTitle">REGISTRATION FORM</p>  
        <hr class="hrLin">
        <img src="../assets/web_images/regLogoIcon.svg" alt="Reg Logo" class="regImg"/> 
      <p class="text-right">
        <span class="reset-ui"><a href="javascript:void(0)"(click)="resetForm()" >Reset</a></span>
        <span class="sign-button-ui" (click)="signInBtn()">Sign In instead</span> </p>
        </div>
    </div>






    <div class="row">
        <div class="col-md-2 col-sm-12" style="background: url(../assets/web_images/verticalLineImg.svg) top left no-repeat;"></div>
<div class="col-md-8  col-sm-12">
    <!-- <div class="row">      
        <div class="col-md-12 col-sm-12 text-right">
            <span class="signbutn" (click)="signInBtn()">SignIn</span> 
        </div>
        </div>
    
        <h2 class="h2Register">Register</h2> -->
        
    <p *ngIf="showHideMessage" [ngStyle]="{'color':msgColor}">{{messageDisplay}}</p>
      
    <form  [formGroup]="createdForm">

   
    <table class="wd-100">           
        <!-- <tr><td><span style="float: right;"><a href="javascript:void(0)"(click)="resetForm()" >Reset</a></span></td></tr>     -->
        <tr><td>
        
            <div class="input-label-group">
                <label>Full Name*</label>
               <input matInput autocomplete="off" placeholder="Please enter your full name" (keypress)="omit_special_char_num($event)"  formControlName="fullName" required maxlength="100">
            </div>
            <span class="errCs" *ngIf="createdForm.get('fullName').invalid && createdForm.get('fullName').touched">Please enter full name *</span>
          
        </td></tr>
        <tr><td>
            <div style="display: flex;">
                <div class="input-label-group" style="display: inline-block;width: 60%;">
                    <label>Std Code</label>
                        <select  formControlName="countryCode"  required style="width: 100%;" (change)="changeCountryCode($event.target.value)">
                         <option value="+91" selected>+91</option>
                         <option value="+1">+1</option>
                         <!-- <option value="+92">+92</option>                         
                         <option value="+971">+971</option> -->
                         
                       </select>
                </div>
                <div class="input-label-group"  style="display: inline-block;margin-left: 10px;width: 100%;">
                    <label>Mobile Number*</label>
                   <input matInput  autocomplete="off" maxlength="10" placeholder="Enter 10 digit mobile"   (keypress)="onlyNumberValidation($event)"  formControlName="mobileNo" required>
                </div>
               
            </div>
            <span class="errCs" *ngIf="createdForm.get('mobileNo').invalid && createdForm.get('mobileNo').touched || createdForm.controls['mobileNo'].hasError('minlength')">Please enter your 10 digit mobile number *</span>
         
        </td></tr>
        <tr><td>
            <div class="input-label-group">
                <label>Email*</label>
               <input matInput  autocomplete="off"  placeholder="Enter login Id"  formControlName="email" required>
            </div>
            <span class="errCs" *ngIf="createdForm.get('email').invalid && createdForm.get('email').touched ">Please enter valid email *</span>
         
        </td></tr>
        <tr><td>
            <div class="input-label-group">
                <label>Password*</label>
               <input matInput  autocomplete="off"   type="password" placeholder="Please set a password for login"  formControlName="password" required maxlength="15">
            </div>           
            <span class="errCs" *ngIf="createdForm.get('password').invalid && createdForm.get('password').touched "> Please enter password between 8 and 15 character*</span>
        </td></tr>
         <tr><td class="pt-4">
             <mat-label style="color: gray;font-size: 12px;">Gender</mat-label><br>
            <mat-radio-group aria-label="Gender" style="font-size:12px;" [ngClass]="'reggennder mRadio'"  formControlName="gender">
                <mat-radio-button value="M" >Male &nbsp;&nbsp;</mat-radio-button>
                <mat-radio-button value="F">Female&nbsp;&nbsp;</mat-radio-button>
                <mat-radio-button value="0">Prefer not to disclose</mat-radio-button>
              </mat-radio-group>
        </td></tr>
        <!-- <tr><td>
            <div class="input-label-group">
                <label>Age*</label>
               <input matInput autocomplete="off" placeholder="Please enter your age"  (keypress)="onlyNumberValidation($event)" maxlength="3"  formControlName="age" required>
            </div>
        </td></tr> -->
        <tr><td>
            <div class="input-label-group">
                <label>DOB*</label>
                <input matInput (click)="dobPicker.open()" formControlName="age" [max]="todayDate" [matDatepicker]="dobPicker" placeholder="Please enter your dob" readonly >    
                <!-- formControlName="dob" required  -->
                <mat-datepicker #dobPicker></mat-datepicker>
            </div>
            <span class="errCs" *ngIf="createdForm.get('age').invalid && createdForm.get('age').touched "> Please enter your dob *</span>
        </td></tr>
        <tr><td>
            <div class="input-label-group">
                <label>State*</label>
                <select placeholder="State" formControlName="state" required (change)="changeCountry($event.target.value, true)">
                    <option *ngFor ="let count of countryList" [value]="count.name">{{count.name}} </option>
                </select>
                    <!-- <select  formControlName="state" required>
                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadra & Nagar Haveli and Daman & Diu">Dadra & Nagar Haveli and Daman & Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Ladakh">Ladakh</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                   </select> -->
            </div>
            <span class="errCs" *ngIf="createdForm.get('state').invalid && createdForm.get('state').touched "> Please Select State *</span>
        </td></tr>
        <tr><td>
           
            <div  class="input-label-group" >
                <label>City *</label>
                <!-- <mat-form-field class="wd-100 no-line" style="height: 40px;text-indent: 6px;">                    -->
                    <input type="text" class="wd-100" (change)="cityChange()"
                           matInput
                           formControlName="myControl"
                           [matAutocomplete]="auto" maxlength="200" formControlName="city">
                           <mat-icon matSuffix style="position: absolute;
                           right: 1px;
                           padding-top: 7px;">keyboard_arrow_down</mat-icon>
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectedFile($event.option.value)" required>
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option}}
                      </mat-option>
                      <mat-option value="Other"> Other  </mat-option>
                    </mat-autocomplete>
                  <!-- </mat-form-field> -->
            </div>
            <span class="errCs" *ngIf="createdForm.get('city').invalid && createdForm.get('city').touched "> Please select city from dropdown *</span>
        </td></tr>



        <tr *ngIf="showHideOtherCity"><td>
            <div class="input-label-group">
                <label>Other City*</label>
               <input matInput  autocomplete="off"   type="text" placeholder="Please enter city"  formControlName="othercity" maxlength="15">
            </div>           
            <span class="errCs" *ngIf="createdForm.get('othercity').invalid && createdForm.get('othercity').touched "> Please enter city*</span>
        </td></tr>






        <tr><td>
            <div class="input-label-group">
                <label>Source*</label>
                    <select  formControlName="source" required>
                     <option value="Direct Walk-In">Direct Walk-In</option>
                     <option value="Consultants">Consultants</option>
                     <option value="Employee Refer">Employee Refer</option>
                     <option value="Mass SMS">Mass SMS</option>
                     <option value="Social Media">Social Media</option>
                     <option value="Friends Outside">Friends Outside</option>
                     <option value="Mass Email">Mass Email</option>
                     <option value="Job Fair">Job Fair</option>
                     <option value="Re-Hire">Re-Hire</option>
                     <option value="T-Best Training">T-Best Training</option>
                     <option value="Campus">Campus</option>
                     <option value="Job Board">Job Board</option>
                     <option value="IGrow">IGrow</option>
                     <option value="Re-deployment">Re-deployment</option>
                     <option value="NGOs">NGOs</option>
                     <option value="Flyers">Flyers</option>
                   </select>
            </div>
            <span class="errCs" *ngIf="createdForm.get('source').invalid && createdForm.get('source').touched "> Please Select Source *</span>
        </td></tr>
        <tr><td class="pt-3">
            <div class="input-label-group">
                <p class="pDes">Please visit <a href="https://www.typing.com/student/tests" target="_blank" rel="noopener noreferrer" style="color: #3fb91f;">www.typing.com</a> to complete your typing test*</p>
            </div>
        </td></tr>
        <tr><td>
            <div class="input-label-group">
                <label>Typing speed(words/min)*</label>
               <input matInput type="text" autocomplete="off"  maxlength="3" (keypress)="onlyNumberValidation($event)" placeholder="Please enter typing speed*"  formControlName="typingSpeed"  required>
            </div>
            <span class="errCs" *ngIf="createdForm.get('typingSpeed').invalid && createdForm.get('typingSpeed').touched "> Please enter typing speed*</span>
        </td></tr>
        <tr><td>
            <div class="input-label-group">
                <label>Language you know*</label>
                    <mat-select panelClass="adjustWdRange" multiple class="mulSel"  formControlName="language" required>                   
					<mat-option value="English">English</mat-option>
					<mat-option value="Kannada">Kannada</mat-option>
					<mat-option value="Telugu">Telugu</mat-option>
					<mat-option value="Tamil">Tamil</mat-option>
					<mat-option value="Malayalam">Malayalam</mat-option>
					<mat-option value="Hindi">Hindi</mat-option>
					<mat-option value="Marathi">Marathi</mat-option>
					<mat-option value="Bengali">Bengali</mat-option>
					<mat-option value="Gujarati">Gujarati</mat-option>
					<mat-option value="Punjabi">Punjabi</mat-option>
					<mat-option value="Assamese">Assamese</mat-option>
					<mat-option value="Oriya">Oriya</mat-option>
                   </mat-select>
            </div>
            <span class="errCs" *ngIf="createdForm.get('language').invalid && createdForm.get('language').touched "> Please Select Language you know*</span>
        </td></tr>
        <tr>
            <td>
                <div class="input-label-group">
                    <label>Educational Qualification*</label>
                        <select placeholder="Educational Qualification*"  formControlName="educatioanl" required>
                         <option value="SSC">SSC</option>
                         <option value="HSC">HSC</option>
                         <option value="Diploma">Diploma</option>
                         <option value="Graduate">Graduate</option>
                         <option value="PostGraduate">Post Graduate</option>                         
                         <option value="PUC">PUC</option>
                         <option value="SSLC">SSLC</option>
                         <!-- <option value="Junior high school">Junior high school</option>
                         <option value="Senior high school">Senior high school</option>
                         <option value="High school Diploma">High school diploma</option>
                         <option value="Junior  College">Junior college</option>
                         <option value="Graduation">Graduation</option>
                         <option value="Post Graduation">Post graduation</option> -->
                       </select>
                </div>
                <span class="errCs" *ngIf="createdForm.get('educatioanl').invalid && createdForm.get('educatioanl').touched "> Please Select Educational Qualification *</span>
            </td>
        </tr>
        <tr>
            <td>
                <div class="input-label-group">
                    <label>Work Experience*</label>
                        <select placeholder="Work Experience*"  formControlName="workExperience" required>
                         <option value="0">0 Years</option>
                         <option value="1">1 Years</option>                         
                         <option value="2">2 Years</option>
                         <option value="3">3 Years</option>
                         <option value="4">4 Years</option>
                         <option value="5">5 Years</option>                         
                         <option value="6">6 Years</option>
                         <option value="7">7 Years</option>
                         <option value="8">8 Years</option>
                         <option value="9">9 Years</option>                         
                         <option value="10">10 Years</option>
                         <option value="10+">10+ Years</option>
                       </select>
                </div>
                <span class="errCs" *ngIf="createdForm.get('workExperience').invalid && createdForm.get('workExperience').touched "> Please Select Work Experience *</span>
            </td>
        </tr>
        <!-- <tr><td>
            <mat-label style="color: gray;font-size: 12px;">Experience in customer support *</mat-label><br>
           <mat-radio-group  style="font-size:12px;"  formControlName="experienceCustomerSupport">
               <mat-radio-button value="Yes">Yes &nbsp;&nbsp;</mat-radio-button>
               <mat-radio-button value="No" >No</mat-radio-button>
             </mat-radio-group>
       </td></tr> -->
       <!-- <tr><td>
        <mat-label style="color: gray;font-size: 12px;">Business Process *</mat-label><br>
       <mat-radio-group  style="font-size:12px;"  formControlName="businessProcess">
           <mat-radio-button value="Voice" >Voice &nbsp;&nbsp;</mat-radio-button>
           <mat-radio-button value="Non Voice" >Non Voice</mat-radio-button>
         </mat-radio-group>
   </td></tr> -->
   <!-- <tr>
    <td>
        <div class="input-label-group">
            <label>Experience in voice support *</label>
          
                <select placeholder="Experience in voice support *"  formControlName="expVoiceSupport" required>                      
                    <option value="0">0 Years</option>
                    <option value="1">1 Years</option>                         
                    <option value="2">2 Years</option>
                    <option value="3">3 Years</option>
                    <option value="4">4 Years</option>
                    <option value="5">5 Years</option>                         
                    <option value="6">6 Years</option>
                    <option value="7">7 Years</option>
                    <option value="8">8 Years</option>
                    <option value="9">9 Years</option>                         
                    <option value="10">10 Years</option>
                    <option value="10+">10+ Years</option>
                  </select>
        </div>
       
    </td>
</tr> -->
<tr>
    <td>
        <div class="input-label-group">
            <label>Choose your set up *</label>
            
        <!-- <mat-form-field class="wd-100 no-line"> -->
            <mat-select multiple class="mulSel" panelClass="adjustWdRange"  formControlName="setup" required>
             <mat-option value="Broadband Internet"> Broadband Internet</mat-option>
             <mat-option value="Wireless Internet (2G, 3G, 4G)">Wireless Internet (2G, 3G, 4G)</mat-option>                         
             <mat-option value="Android Phone"> Android Phone</mat-option>
             <mat-option value="Desktop/Laptop">Desktop/Laptop</mat-option>                         
             <mat-option value="Power Backup">Power Backup</mat-option>
           </mat-select>
       <!-- </mat-form-field> -->
       </div>
       <span class="errCs" *ngIf="createdForm.get('setup').invalid && createdForm.get('setup').touched "> Please Choose your set up *</span>
    </td>
</tr>
<br>
<tr >
    <td>
        <mat-label style="color: gray;font-size: 12px;">Upload Resume (Support PDF and Word File format only)</mat-label><br>
           
            
            <div class="upload-btn-wrapper">
				<button class="btnbrowse" >Browse</button>
				<span class="only-show-on-hover">PDF and Word File only</span>
             <input type="file" #fileUpload class="filecs" formControlName="file" (change)="changeFile($event)"  accept="application/pdf,application/msword,
             application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
             <span style="color: #bababa;margin-left: 15px">{{currentFileobj.name}}</span>
			  </div>    
       
    </td>
</tr>
    </table>
</form>
</div>
<div class="col-md-2 col-sm-12" style="background: url(../assets/web_images/verticalLineImg.svg) bottom right no-repeat;"></div>
    </div>
        <div class="row">
            <div class="col-md-2 col-sm-12"></div>
<div class="col-md-8 col-sm-12 text-center">
        <button class="JoinSubmit" (click)="registerBtn(createdForm)" [ngClass]="disabledBtnRegister || !createdForm.valid ? 'cls11' : ''" [disabled]="disabledBtnRegister || createdForm.invalid">REGISTER</button>
      
        <!-- <p class="CR" style="margin-top: 25px;" (click)="backtoLogin()">Back</p> -->
        <p class="CR" style="margin-top: 25px;" >Or Import with</p>
</div>
<div class="col-md-2 col-sm-12"></div>
            </div>
            <div class="text-center">
                <a href="javascript:void(0)" (click)= "signInWithGoogle()"><span><img class="socialIconReg" src="../assets/GoogleIcon.png" /></span></a>
                <a href="javascript:void(0)" (click)= "signInWithFB()"><span><img class="socialIconReg" src="../assets/facebookIcon.png" /></span></a>
                <a><span><img class="socialIconReg" (click)="signInWithLinkedin()" src="../assets/linkedinIcon.png" /></span></a>
                <br>
            </div>
<br><br>

</mat-card>
<br>
<br>
</div>