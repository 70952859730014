<div class="col-md-12">
	<h4 style="text-align: center;">
		<b>
			Notification
		</b>
	</h4>
	<div class="row" style="margin-top:4px;" ><!---->
		<div class="col-md-12">
				<div>
					<h6>{{readdata.title}}</h6>
					<p>{{readdata.message}}</p>
				</div>
		</div>
	</div>

	<div class="col-md-12">
		<div style="text-align: right;margin-top: 30px;">
			<button mat-button (click)="close()" class="mat-focus-indicator mat-button mat-button-base" style="border: 1px solid #3fb91f !important; background: #fff !important; color: #3fb91f !important;">Close</button>
		</div>
	</div>
</div>