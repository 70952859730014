<div>
    <mat-sidenav-container autosize style="min-height: calc(100vh);">
      <mat-sidenav #sidenav  mode="side" opened="true" style=" background: white !important;" class="">
      
        <div>
          <img src="../assets/smarterbizLighting.png" class="logoTag">
  
          <mat-nav-list>
            <mat-list-item  [routerLink]="['./demo-portal-dashboard']" [routerLinkActive]="['active']">
              <p class="wd-100">
                <!-- <img src="../assets/demo-portal-dashboard-icon.png"> -->
                <span><mat-icon>assignment</mat-icon></span>
                &nbsp;&nbsp; Dashboard
              </p>
            </mat-list-item>
            <mat-list-item [routerLink]="['./demo-portal-setting']" [routerLinkActive]="['active']">
              <p class="wd-100">
                <span><mat-icon>settings</mat-icon></span>
                <!-- <img src="../assets/demo-portal-setting-icon.png"> -->
                &nbsp;&nbsp;  Setting
              </p>
            </mat-list-item>
        </mat-nav-list>
        </div>
      </mat-sidenav>
      <div class="TR-sidenav-content">      
          <mat-card class="hpartHeight">
            <div class="row">            
              <div class="col-md-6 col-sm-6 col-xs-6 text-left">
            <p class="headingText">Demo Portal</p>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6 text-right">
            
            </div>
            </div>
          </mat-card>       
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-container>
  </div>