<form [formGroup]="campaignForm">
	<div class="scrollingDiv">
		<table class="tbl">
			<tr style="border-bottom: 1px solid #ccc;">
				<td style="width: 35%;">
					<h5 class="hd5" style="color: #fd6c21;">Create Campaign</h5>
				</td>
				<td style="text-align: right;position: sticky;top:0;">
					<i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i>
				</td>
			</tr>
			<tr>
				<td><span>Campaign Name</span></td>
				<td>
					<mat-form-field>
						<input matInput autocomplete="off" placeholder="Campaign Name" formControlName="name"
							[ngClass]="{'is-invalid': _form.name.errors}" (keypress)="omit_special_char($event)">
						<div *ngIf="_form.name.errors" class="invalid-feedback">
							<div *ngIf="_form.name.errors.required">Campaign name is required</div>
						</div>
					</mat-form-field>
				</td>
			</tr>
			<tr>
				<td><span>Campaign Date</span></td>
				<td>
					<mat-form-field style="width: 115px;padding-right: 10px;">
						<input matInput [matDatepicker]="picker" [matDatepickerFilter]="hidePreviousDates"
							placeholder="Date" readonly (click)="picker.open()" formControlName="date">
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
					<input type="time" placeholder="Time" value="00.00" min="00:00" max="24:00" formControlName="time"
						[ngClass]="{'is-invalid': _form.time.errors}" required>
					<div *ngIf="_form.time.errors" class="invalid-feedback">
						<div *ngIf="_form.time.errors.timeError">{{ _form.time.errors.timeError }}</div>
					</div>
				</td>
			</tr>
			<tr>
				<td><span>Master Campaign</span></td>
				<td>
					<mat-form-field *ngIf="master_campaign_field.input == 'FT'">
						<input matInput placeholder="Campaign Type" formControlName="master_campaign"
							[ngClass]="{'is-invalid': _form.master_campaign.errors}"
							(keypress)="omit_special_char($event)">
						<div *ngIf="_form.master_campaign.errors" class="invalid-feedback">
							<div *ngIf="_form.master_campaign.errors.required">Put default as others</div>
						</div>
					</mat-form-field>
					<mat-form-field *ngIf="master_campaign_field.input == 'DD'">
						<mat-select formControlName="master_campaign" [(value)]="master_campaign_field.default_value"
							[ngClass]="{'is-invalid': _form.master_campaign.errors}">
							<mat-option *ngFor="let val of master_campaign_field.values" [value]="val">
								{{val}}
							</mat-option>
						</mat-select>
						<div *ngIf="_form.master_campaign.errors" class="invalid-feedback">
							<div *ngIf="_form.master_campaign.errors.required">Select atleast one type</div>
						</div>
					</mat-form-field>
				</td>
			</tr>

			<tr *ngIf="incallscreen_atrs.visibility">
				<td><span>Incall Screen</span></td>
				<td>
					<mat-form-field>
						<mat-select formControlName="screenname" placeholder="Select Form">
							<mat-option [value]="v.definition"
								*ngFor="let v of incallscreen_atrs.pre_values['Incallscreen']">{{v.name}}</mat-option>
						</mat-select>
					</mat-form-field>

				</td>
			</tr>

			<!-- <tr>
				<td><span>Select Form</span></td>
				<td>
					<mat-form-field>
						<mat-select formControlName="formidName" placeholder="Select Form">
							<mat-option>None</mat-option>
							<mat-option [value]="v.id" *ngFor="let v of _formlist">{{v.form_name}}</mat-option>
						</mat-select>
					</mat-form-field>

				</td>
			</tr> -->
			<!-- *ngIf="(_companyName != 'BANK1-TESTING') || (_companyName != 'SBI-CC' )" -->
			<tr>
				<td><span>Select Agents</span></td>
				<td>
					<mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
						formControlName="agent_selected_option">
						<mat-radio-button class="example-radio-button" *ngFor="let option of agent_radio_buttons"
							[value]="option.value" [checked]="option.checked">
							{{ option.name }}
						</mat-radio-button>
					</mat-radio-group>
				</td>
			</tr>
			<tr *ngIf="campaignForm.get('agent_selected_option').value=='web'">
				<td><span></span></td>
				<td>
					<mat-accordion>
						<mat-expansion-panel class="panel_div">
							<mat-expansion-panel-header style="font-size: 12px;color:gray;padding-left: 0px;">
								Selected Agents ({{getSelectedCount()}})
							</mat-expansion-panel-header>
							<div>
								<mat-tree [dataSource]="dataSourceTree" [treeControl]="treeControl">
									<mat-tree-node style="min-height: 28px;" *matTreeNodeDef="let node"
										matTreeNodeToggle matTreeNodePadding>
										<button mat-icon-button disabled></button>
										<mat-checkbox style="font-size: 12px !important;white-space: pre;"
											[checked]="checklistSelection.isSelected(node)"
											(change)="todoLeafItemSelectionToggle(node)"
											class="checklist-leaf-node mcx1" [disabled]="node.disabled">
											<dl>
												<dt class="agent_name">{{node.name}} - [{{node.email}}]</dt>
												<dt *ngIf="node.active_campaign" class="agent_campaign">
													<span>{{beautify_active_campaign(node.active_campaign)}}</span>
												</dt>
											</dl>
										</mat-checkbox>
									</mat-tree-node>
									<mat-tree-node style="min-height: 28px;" *matTreeNodeDef="let node; when: hasChild"
										matTreeNodePadding>
										<button mat-icon-button matTreeNodeToggle
											[attr.aria-label]="'toggle ' + node.filename"
											(click)="expand_collapse_all_childs(node)">
											<mat-icon class="mat-icon-rtl-mirror">
												{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
											</mat-icon>
										</button>

										<mat-checkbox style="font-size: 12px !important;"
											[checked]="descendantsAllSelected(node)"
											[indeterminate]="descendantsPartiallySelected(node)"
											(change)="todoItemSelectionToggle(node)" class="checklist-node">
											{{node.name}}
										</mat-checkbox>
									</mat-tree-node>
								</mat-tree>
								<!-- {{getSelected()}} -->
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</td>
			</tr>
			<tr>
				<td id="dial_mode">Dial Mode
				</td>
				<td>
					<mat-radio-group style="display: inline-block;" aria-labelledby="dial_mode" class="dial-radio-group"
						formControlName="priority">
						<mat-radio-button class="dial-radio-button" *ngFor="let col of dial_mode.dial_modes"
							[value]="col.name">{{col.name}}
							<select class="form-control" id="pd_mode_selection"
								(change)="setDialRatio($event.target.value)"
								*ngIf="col.priority == 2 && col.dial_ratio.dd_values">
								<!--*ngIf='col.name == "Predictive"'-->
								<option *ngFor="let dr of col.dial_ratio.dd_values" [ngValue]="dr">{{ dr }}</option>
							</select>
						</mat-radio-button>
					</mat-radio-group>
				</td>
			</tr>
			<tr>
				<td id="dial_mode">Work Mode
				</td>
				<td>
					<select class="form-control" id="pd_mode_selection" (change)="onChange($event.target.value)"
						formControlName="campaign_mode">
						<!--*ngIf='col.name == "Predictive"'-->
						<option *ngFor="let dr of campaign_work_mode" [ngValue]="dr">{{ dr }}</option>
					</select>
				</td>
			</tr>
			<tr>
				<td><span>Custom Message</span></td>
				<td>
					<mat-form-field *ngIf="custom_message_field.input == 'FT'">
						<input matInput placeholder="Custom Message" formControlName="custom_message"
							[ngClass]="{'is-invalid': _form.custom_message.errors}"
							(keypress)="omit_special_char($event)">
						<div *ngIf="_form.custom_message.errors" class="invalid-feedback">
							<div *ngIf="_form.custom_message.errors.required">Put default as others</div>
						</div>
					</mat-form-field>
					<mat-form-field *ngIf="custom_message_field.input == 'DD'">
						<mat-select formControlName="custom_message" [(value)]="master_campaign_field.default_value"
							[ngClass]="{'is-invalid': _form.custom_message.errors}">
							<mat-option *ngFor="let val of custom_message_field.values" [value]="val">
								{{val}}
							</mat-option>
						</mat-select>
						<div *ngIf="_form.custom_message.errors" class="invalid-feedback">
							<div *ngIf="_form.custom_message.errors.required">Select atleast one type</div>
						</div>
					</mat-form-field>
				</td>
			</tr>
			<tr>
				<td><span>Data</span><span> (only supports xlsx file)</span></td>
				<td style="line-height:26px;">
					<!--  <input type="file" #fileUpload class="filecs" formControlName="file" (change)="changeFile($event)"/> -->
					<!-- <label for="file">Upload</label> -->
					<!--class="upload-btn-wrapper"-->
					<div class="upload-btn-wrapper">
						<button class="btnbrowse">Browse</button>
						<input type="file" #fileUpload class="filecs" formControlName="file"
							(change)="changeFile($event)" />
					</div>
				</td>
			</tr>
			<tr>
				<td></td>
				<td style="color: #bababa;">{{currentFileobj.name}}</td>
			</tr>
			<tr>
				<td>&nbsp;</td>
				<td>
					<div style="margin-top: 10px;">
						<!-- <a href="#">
							<span mat-button (click)="download_file()">Download Excel Template
							&nbsp;&nbsp; <i class="fa fa-file-excel" aria-hidden="true"></i>
						</span>
						</a> -->
						<span class="v-download-cd ml-2" (click)="download_file()">
							<!-- <a href="{{templateddownloadurl}}" target="_blank">
							Data Upload Sheet.xlsx
						</a> -->
							Data Upload Sheet.xlsx
						</span>
					</div>
				</td>
			</tr>
			<tr>
				<td> <span>Submit</span></td>

				<td>
					<div style="margin:8px 0px;">
						<!-- <button type="button" mat-button color="accent" [disabled]="!campaignForm.valid"  (click)="submit_Camapign(campaignForm)">Assign Now</button>&ensp;
							<button mat-button color="accent" style="margin-left:10px;" [disabled]="!campaignForm.valid" (click)="assign_later(campaignForm)">Assign Later</button>&nbsp; -->


						<!--vk start-->
						<!--[disabled]="!campaignForm.valid"-->
						<button class="btnAssign" (click)="submit_Camapign(campaignForm)">Assign Now</button>
						<div class="vk-dropdown">
							<button class="btnLater" style="border-left:1px solid #ccc !important">
								<i class="fa fa-caret-down"></i>
							</button>
							<div class="vk-dropdown-content">
								<a href="#"><button class="btnLater1" (click)="assign_later(campaignForm)">Assign
										Later</button></a>
							</div>
						</div>

						<!--vk end-->
						<!--<div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
								<button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
								<div class="dropdown-menu" ngbDropdownMenu>
								  <button ngbDropdownItem mat-button color="accent" [disabled]="!campaignForm.valid" (click)="assign_later(campaignForm)">Assign Later</button>	
								</div>
							</div>-->
					</div>
				</td>
			</tr>
		</table>
	</div>
	<!--<p *ngIf="showHideAtleastOneAgent" style="color:red;font-size:20px;font-family: poppins;"> * Select at least one agent</p>-->
	<p class="succData">{{ campaignUpload_msg }}</p>
</form>
<div id="cover-spin" *ngIf="showLoader"></div>