import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MyserviceService} from '../myservice.service';
import { ApiService } from '../api.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Utils } from '../utils';
import * as moment from 'moment';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
//import { ViewDetailsDialogComponent } from '../view-details-dialog/view-details-dialog.component';
import { DatePipe } from '@angular/common';
import { ViewDetailsDialogComponent } from './view-details-dialog/view-details-dialog.component';
import {Router} from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MAT_DATE_FORMATS } from '@angular/material/core';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-contiinex-reports',
  templateUrl: './contiinex-reports.component.html',
  styleUrls: ['./contiinex-reports.component.scss'],
  providers: [DatePipe,{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }]
})
export class ContiinexReportsComponent implements OnInit {
  currentTitle:any="Reports";
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource();
  customer_name:string;
  project_name:string = "";
  projects:any;
  ChannelsData:any;
  selected:any;
  selectedChannel:any;
  selectedType: string = "";
  showHideDateCondition: boolean = false;
  today = new Date();
	tomorrow = moment().add(1,'days');
  utils = new Utils();
	startDateFilter = this.utils.localToUTC(moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
	endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
  byDateValue:any="ByDate";
	@ViewChild(' fromInput', {
		read: MatInput
	}) fromInput: MatInput;
  //@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  //@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  //@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('OutboundPaginator') OutboundPaginator: MatPaginator;
  @ViewChild('whatsappPaginator') whatsappPaginator: MatPaginator;
  @ViewChild('UPaginator') UPaginator: MatPaginator;
  @ViewChild(MatSort) whatsappSort: MatSort;

  myUser: any;
  selectedProject:any;
  api_end_point:any;
  constructor(private _service:MyserviceService, private API: ApiService,private dialog: MatDialog,private datePipe: DatePipe,private router: Router) { }

  ngOnInit() {
	this.api_end_point = this._service.get_API_End_Point();
    this.myUser = this._service.get_loggedin_user();
    this.selectedProject=this.myUser.company;

    if(this.byDateValue=="ByDate"){
      this.startDateFilter=this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);;
      this.endDateFilter=this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
    }
    this.selected="";
    this.selectedChannel="";
    this.getReportData();
    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
      this.projects = this._service.get_loggedin_user().project;
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      this.project_name = this.myUser.company ;//String(this._service.get_current_project()).toLowerCase();
      this.project_name = this.project_name == 'undefined' ? '' : this.project_name;

    }
    this.ChannelsData=['WhatsApp'];
    //this.getProjectReport();
  }
  changeRadioByDay(val:any){
    if(val=="ByDate"){
      this.startDateFilter=this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);;
      this.endDateFilter=this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);

    }
    if(val=="ByMonth"){
      this.startDateFilter = this.utils.localToUTC(moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
      this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
    }

  }
  OnChangeProject(val:any){
   // console.log(val);
    this._service.set_current_project(val);
    if(!val || val!="allProjects"){
      this.selectedChannel="";
      this.project_name =this.myUser.company;// String(this._service.get_current_project()).toLowerCase();
      this.project_name = this.project_name == 'undefined' ? '' : this.project_name;
    } else{
      this.selectedChannel="";
      this.project_name = val;
    }
    //this.getProjectReport()

  }

  OnChangeChannel(val:any){
    this.selectedChannel = val;
    this.selectedType = '';
    if(val == ''){
     // this.getProjectReport()
    }

  }


  channelRefresh(){
    if(!this.selectedType){
      return;
    }
    // if(this.selectedChannel == 'Outbound IVR'){
    //   this.getIvrReport();
    // }else
    if(this.selectedChannel == 'WhatsApp'){
      this.getWaReport();
    }
    // else{
    //   this.getProjectReport()
    // }
  }

  change_date(key: string, event: MatDatepickerInputEvent<Date>) {
		if (key == 'start_date') {
			this.startDateFilter = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.startDateFilter).diff(moment(this.endDateFilter)) >= 0) {
				this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
				//this.endDateFilter = "";
				//alert("Start date should be less than end date");
				//return;
			}
		} else {
			this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.startDateFilter).diff(moment(this.endDateFilter)) >= 0) {
				//	this.endDateFilter = "";
				this.endDateFilter = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this.showHideDateCondition=true;
				return;
			} else {
				this.showHideDateCondition=false;
			}

		}
	}

  ngAfterViewInit(){
    // this.dataSource.paginator = this.paginator
  }

  // getProjectReport(){

  //   this.displayedColumns = ['channel','receiver_phone', 'status' , 'start_time', 'end_time','details'];
  //   this.API.getProjectReport(this.customer_name,this.project_name,this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter),this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter)).subscribe((res: any) => {
  //     let ELEMENT_DATA = res.success;
  //     this.dataSource = new MatTableDataSource(ELEMENT_DATA);
  //     this.UPaginator.pageIndex = 0;
  //     this.dataSource.paginator = this.UPaginator;
  //     // setTimeout(() => this.dataSource.paginator = this.paginator);
  //   }, (err) => {

  //   })

  //}
  // getIvrReport(){
  //   this.displayedColumns = [ 'customer_number' ,'event_type', 'dtmf_digit', 'start_time', 'end_time', 'call_duration','details'];
  //   this.API.getChannelReportIVR(this.customer_name,this.project_name,this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter),this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter)).subscribe((res: any) => {
  //     let ELEMENT_DATA = res.success;
  //     this.dataSource = new MatTableDataSource(ELEMENT_DATA);
  //     this.OutboundPaginator.pageIndex = 0;
  //     this.dataSource.paginator = this.OutboundPaginator;

  //     // setTimeout(() => this.dataSource.paginator = this.paginator);

  //   }, (err) => {

  //   })
  // }
  getWaReport(){
    //debugger;
    this.displayedColumns = ['sender_name','sender_phone','receiver_name', 'receiver_phone','content','whatsapp_number','status','created_at','message_type','direction','details'];
    let _userDetail = this._service.get_loggedin_user();
    let groupid=_userDetail.groupid;
    this.API.getChannelReportWA(groupid,this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter),this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter),this.api_end_point).subscribe((res: any) => {
      let ELEMENT_DATA = res.success;
    //  console.log(ELEMENT_DATA)
      this.dataSource = new MatTableDataSource(ELEMENT_DATA);
      this.whatsappPaginator.pageIndex = 0;
      this.dataSource.paginator = this.whatsappPaginator;

    this.dataSource.sort = this.whatsappSort;
      // setTimeout(() => this.dataSource.paginator = this.paginator);
    }, (err) => {

    })
  }

  extractDataObject(ref, obj) {
		let arr = [];
		for (var i in ref) {
			if (obj.hasOwnProperty(ref[i])) {
        if( ref[i] == 'event_type' && obj['event_type']){
          arr.push(this.getEventName(obj['event_type']))
        }else if( ref[i] == 'start_time' && obj['start_time']){
          arr.push(this.datePipe.transform(obj['start_time'], 'dd-MMM-yyyy h:mm:ss a'))
        }else if( ref[i] == 'end_time' && obj['end_time']){
          arr.push(this.datePipe.transform(obj['end_time'], 'dd-MMM-yyyy h:mm:ss a'))
        }else if( ref[i] == 'sent_at' && obj['sent_at']){
          arr.push(this.datePipe.transform(obj['sent_at'], 'dd-MMM-yyyy h:mm:ss a'))
        }else if( ref[i] == 'delivered_at' && obj['delivered_at']){
          arr.push(this.datePipe.transform(obj['delivered_at'], 'dd-MMM-yyyy h:mm:ss a'))
        }else if( ref[i] == 'read_at' && obj['read_at']){
          arr.push(this.datePipe.transform(obj['read_at'], 'dd-MMM-yyyy h:mm:ss a'))
        }
        else if( ref[i] == 'created_at' && obj['created_at']){
          arr.push(this.datePipe.transform(obj['created_at'], 'dd-MMM-yyyy h:mm:ss a'))
        }
        else{
          arr.push(obj[ref[i]])
        }

			} else {
				arr.push('')
			}
		}
		return arr;
  }



  downloadReport(){
    let data = this.dataSource.filteredData ? this.dataSource.filteredData : this.dataSource.data;
    var data_mapper = {};

    data_mapper = {'channel':'Channel', 'receiver_phone': 'Customer', 'status': 'Status' , 'start_time': 'Start yime', 'end_time': 'End time'};
    // if(this.selectedChannel == 'Outbound IVR'){
    //   data_mapper = {'project': 'Project', 'event_type': 'Event', 'customer_number': 'Recipient' ,'cli_number': 'Sender', 'customer':'Customer','dtmf_digit': 'Digit', 'start_time': 'Start time', 'end_time': 'End time','call_duration':'Duration' ,'hangup_cause':'Hangup cause','campaign':'Campaign','call_url':'Call url','call_price': 'Call price', 'audit_score': 'Audit score'};
    // }
    //['sender_name','sender_phone','receiver_name', 'receiver_phone','content','whatsapp_number','status','created_at','message_type','direction','details'];


    if(this.selectedChannel == 'WhatsApp'){
      data_mapper = {'message_id':'Reference ID','created_at':'Date','sent_at':'Sent At','delivered_at':'Delivered At','read_at': 'Read At','failed_at':'Failed At','status': 'Status','content_type':'Content Type','message_type':'Message Type','direction':'Direction','sender_name': 'Sender Name','sender_phone': 'Sender','receiver_name':'Recipient Name' ,'receiver_phone': 'Recipient','whatsapp_number':'Whatsapp Number','template_name': 'Template Name', 'content': 'Content','media_url':'Media Url' };
    }
    let headers = Object.values(data_mapper);
    if (data && data.length) {
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Candidate Data');
      worksheet.addRow(headers);
      data.forEach((d: any) => {
        // if(d.certified_date != null && d.certified_date != 'Uncertified'){
        //   let row = worksheet.addRow(this.extractDataObject(Object.keys(_data_mapper), d));
        // }
        let row = worksheet.addRow(this.extractDataObject(Object.keys(data_mapper), d));

        //console.log('printing row', row);
      })
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'Report-'+(this.selectedChannel ? this.selectedChannel : 'project') +'.xlsx');
      })
    }
  }

  getCurrentDate(){
    let myDate = new Date();
  let daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Aug', 'Oct', 'Nov', 'Dec'];
  let date = myDate.getDate();
  let month = monthsList[myDate.getMonth()];
  let year = myDate.getFullYear();
  let day = daysList[myDate.getDay()];
  let today = `${date} ${month} ${year}, ${day}`;
  return today;
  }
  getReportData(){
    let ELEMENT_DATA = [
    ];

    this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    this.dataSource.paginator = this.UPaginator;
  }
  viewDetailsDialog(element:any,channelType:any){
    let _element={
      "element":element,
      "channelType":channelType
    }
    const dialogRef = this.dialog.open(ViewDetailsDialogComponent, {
      width: '770px',
      height: '85%',
      disableClose:true,
      data: _element
    });
    dialogRef.afterClosed().subscribe(result => {
     // console.log('The dialog was closed');
    });
  }
  getEventName(val){
    var event = 'Call Missed';
    switch(val){
      case 'ivr_out_completed':
        event = 'Call Connected';
        break;
      case 'DND Number':
          event = 'DND Number';
          break;
      default:
          break;
    }
    return event;
  }
  backTowhatsapp(){
    this.router.navigateByUrl('/whatsapp-dashboard');
  }
}
