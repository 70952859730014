<div class="mDiv" style="margin: 70px 30px;min-height: 100vh;">
	<div class="row">
		<div class="col-lg-6 col-md-6 col-sm-12 divTab">
			<div class="headingcontact">
				<p class="font">Contact list</p>
				<mat-form-field class="cForm" style="font-size: 13px;width:100%;position: sticky;top: -7px;"
					appearance="outline" [ngClass]="'bgWhite'">
					<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" autocomplete="off">
					<i class="fa fa-search search-icon" matSuffix style="position: relative;
					top: -2px;"></i>
				</mat-form-field>
			</div>
			<div class="mScroll example-container">
				<!--<mat-form-field class="cForm" style="font-size: 13px;width:100%;position: sticky;top: -7px;" appearance="outline" [ngClass]="'bgWhite'">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" autocomplete="off">
                    <i class="fa fa-search search-icon" matSuffix style="position: relative;
                    top: -2px;"></i>
                    </mat-form-field>-->

				<table mat-table [dataSource]="dataSource" class="mtable">
					<ng-container matColumnDef="Name" >
						<th mat-header-cell *matHeaderCellDef style="display: none;">&nbsp;</th>
						<td mat-cell *matCellDef="let element" class="CR"   (click)="nameRowRecord(element)"><!--[ngClass]="{'green' : element, 'red': !element}"-->
							{{element.Name}}
							<span class="psType"
								style="font-size: 14px !important;
                    position: relative;float: right;top: 2px;color: #f44336;">{{element.type.substring(0,1).toUpperCase()}}</span>
						</td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="displayedColumns" style="display: none;"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"
						(click)="selectedRow = selectedRow === row ? null : row"
						[ngClass]="{ 'selected': row === selectedRow }" ></tr><!--[ngClass]="'arrowcs'"-->
				</table>
			</div>
		</div>
		<div class="col-lg-6 col-md-6 col-sm-12 selectedcontact" *ngIf="selectedContact">
			<p class="plft">
			<div class="row">
				<div class="col-md-12">
					<div class="row">
						<div class="col-md-12">
							<div class="row">
								<div class="col-md-12">
									<table style="width:100%">
										<tr>
											<td rowspan="2"><img src="../../../assets/desktop_calling/img_avatar.png"
													alt="Avatar" class="circleimg">
											</td>
											<td><b>{{_nameUser}}</b></td>
										</tr>
										<tr>
											<td><span>{{selectedContact.Number}}</span>
											</td>
											<td>
												<span class="iconWd" *ngIf="_wTemplate.options.includes('Call')"
													matTooltip="Call"><img (click)="CallChatPhoneClick('phone')"
														class="cr" src="../assets/desktop_calling/cc-call-img.svg">
												</span>&ensp;
												<span class="iconWd" *ngIf="_wTemplate.options.includes('Whatsapp')"
													matTooltip="Whatsapp">
													<img (click)="CallChatPhoneClick('whatsapp')" class="cr"
														src="../assets/desktop_calling/cc-wp-img.svg"> </span>&ensp;
												<span class="iconWde" *ngIf="_wTemplate.options.includes('Email')"
													matTooltip="Email">
													<img (click)="CallChatPhoneClick('text')" class="cr"
														src="../assets/web_images/envelopeGrayIcon.svg"> </span>
											</td>
										</tr>
									</table>

									<hr class="hrLine">
									<div *ngIf="showHideWhatsapp">
										<div class="row plft pt-4">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Select WhatsApp Template</span><br>
												<mat-select [ngClass]="'arrowcs'" class="dropdownCs mt-3"
													[(ngModel)]="templateddlValue"
													(selectionChange)="changeWhatsappTemplate($event.value)"
													placeholder="Select a Template">
													<mat-option [value]="v.template_name"
														*ngFor="let v of _ddlListExtraOption">
														{{v.template_name}}</mat-option>
												</mat-select>
											</div>
										</div>

										<div class="row plft pt-4">
											<div class="col-lg-12 col-md-12 col-sm-12 " >
												<p [innerHTML]="templatepart1 | replaceLineBreaks" style="padding-left: 15px;">{{templatepart1}}
												<br><br>
												<!-- <span [innerHTML]="templatepart2">{{templatepart2}}</span></p>
												 -->

											</div>
										</div>

										<div class="row plft pt-4" *ngIf="ismedia">
											<div class="col-lg-12 col-md-12 col-sm-12 " >
												<img src={{media}} style="width: 100%;" />
											</div>
										</div>

										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Relationship_Manager">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Relationship_Manager</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput class="mt-3 dtcs" autocomplete="off"
														[(ngModel)]="relationshipManagerModel">
												</mat-form-field>
											</div>
										</div>
										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Product_Name">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Product_Name</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput class="mt-3 dtcs" autocomplete="off"
														[(ngModel)]="Product_Name">
												</mat-form-field>
											</div>
										</div>
										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Tracking_Link">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Tracking Link</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput class="mt-3 dtcs" autocomplete="off"
														[(ngModel)]="trackingLinkModel">
												</mat-form-field>
											</div>
										</div>
										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Phone_Number">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Phone number</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput (keypress)="onlyNumberValidation($event)"
														class="mt-3 dtcs" autocomplete="off" maxlength="15"
														[(ngModel)]="phoneNoModel">
												</mat-form-field>
											</div>
										</div>
										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Accept_Key">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Accept_Key</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput class="mt-3 dtcs" autocomplete="off"
														[(ngModel)]="Accept_Key">
												</mat-form-field>
											</div>
										</div>
										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Reject_Key">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Reject_Key</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput class="mt-3 dtcs" autocomplete="off"
														[(ngModel)]="Reject_Key">
												</mat-form-field>
											</div>
										</div>
										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Publisher_Name">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Publisher_Name</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput class="mt-3 dtcs" autocomplete="off"
														[(ngModel)]="Publisher_Name">
												</mat-form-field>
											</div>
										</div>

										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Service_Station">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Service_Station</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput class="mt-3 dtcs" autocomplete="off"
														[(ngModel)]="Service_Station">
												</mat-form-field>
											</div>
										</div>

										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Vehicle_Number">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Vehicle_Number</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput class="mt-3 dtcs" autocomplete="off"
														[(ngModel)]="Vehicle_Number">
												</mat-form-field>
											</div>
										</div>
										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Invoice_Number">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Invoice_Number</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput class="mt-3 dtcs" autocomplete="off"
														[(ngModel)]="Invoice_Number">
												</mat-form-field>
											</div>
										</div>


										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Service_Name">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Service_Name</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput class="mt-3 dtcs" autocomplete="off"
														[(ngModel)]="Service_Name">
												</mat-form-field>
											</div>
										</div>
										
										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Product_Link">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Product_Link</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput class="mt-3 dtcs" autocomplete="off"
														[(ngModel)]="Product_Link">
												</mat-form-field>
											</div>
										</div>

										<div class="row plft pt-4" *ngIf="_rowExtraOptionData.Web_Link">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<span class="lvl">Web_Link</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input matInput class="mt-3 dtcs" autocomplete="off"
														[(ngModel)]="Web_Link">
												</mat-form-field>
											</div>
										</div>
										<div class="row plft pt-4">
											<!-- *ngIf="_rowExtraOptionData['Next_Update_Time']['type']=='DATE'" -->
											<div class="col-lg-12 col-md-12 col-sm-12"
												*ngIf="_rowExtraOptionData.Next_Update_Time">
												<span class="lvl">Next_Update_Time</span> <br>
												<mat-form-field appearance="outline" class="mt-3 cForm"
													style="width:100%">
													<input #fromInput matInput [min]="minDate" [matDatepicker]="picker"
														class="mt-3 dtcs" placeholder="Date" [(ngModel)]="dateSelect"
														readonly (click)="picker.open()">
													<mat-datepicker-toggle matSuffix [for]="picker"
														style="font-size: 18px !important;">
													</mat-datepicker-toggle>
													<mat-datepicker #picker></mat-datepicker>
												</mat-form-field>
											</div>
										</div>

										<div class="row plt">
											<div class="col-lg-12 col-md-12 col-sm-12">
												<button class="submit-btn-ui" (click)="saveBtn()"> Submit</button>
											</div>
										</div>

									</div>
									<div *ngIf="showHidePhone">
										<b>Call</b>
									</div>
									<div *ngIf="showHideText">
										<b>Text</b>

									</div>
								</div>
							</div>
						</div>
						<!--<span class="tabUserName">{{_nameUser}} ({{selectedContact.Number}})</span><span class="psType">{{_psType}}</span> </p>-->

					</div>
				</div>
			</div>
		</div>