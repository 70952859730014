import { Component, OnInit,ElementRef, HostListener, } from '@angular/core';
import {Router} from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/api.service';
import { Title, Meta } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SEOService } from 'src/app/canonical_service';

@Component({
  selector: 'app-new-contiinex-comnex-engage-page',
  templateUrl: './new-contiinex-comnex-engage-page.component.html',
  styleUrls: ['./new-contiinex-comnex-engage-page.component.scss']
})
export class NewContiinexComnexEngagePageComponent implements OnInit {
  myTitle = 'Contiinex | Unified Communication as a Service';
  constructor(private seoService: SEOService,private metaTagService: Meta,private titleService:Title,private router:Router,private location: Location,private API : ApiService,protected $gaService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.seoService.updateCanonicalUrl('https://www.contiinex.com/Unified-Communication-as-a-Service');
    this.titleService.setTitle(this.myTitle);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Conversational AI with WhatsApp Bot, IVR & Dialler all working individually yet together!'},"name='description'"
    );
    this.metaTagService.updateTag(
      { name: 'url', content: 'https://www.contiinex.com/Unified-Communication-as-a-Service'},"name='url'"
    );
    this.metaTagService.updateTag(
      { property: 'og:title', content: this.myTitle },"property='og:title'"
    );
    this.metaTagService.updateTag(
      { property: 'og:description', content: 'Conversational AI with WhatsApp Bot, IVR & Dialler all working individually yet together!'}, "property='og:description'"
    );
    this.metaTagService.updateTag(
      { property: 'og:url', content: 'https://www.contiinex.com/Unified-Communication-as-a-Service'}, "property='og:url'"
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: 'Conversational AI with WhatsApp Bot, IVR & Dialler all working individually yet together!'}, "name='twitter:description'"
    );
    this.metaTagService.updateTag(
      { itemprop: 'description', content: 'Conversational AI with WhatsApp Bot, IVR & Dialler all working individually yet together!'},"itemprop='description'"
  );
  this.metaTagService.updateTag(
      { itemprop: 'url', content: 'https://www.contiinex.com/Unified-Communication-as-a-Service'},"itemprop='url'"
  );
  this.metaTagService.updateTag(
      { itemprop: 'name', content: this.myTitle },"itemprop='name'"
  );
  }
  bookDemoBtn(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  this.router.navigateByUrl('/Contact');
  }
}
