
<div class="row mt-4 mb-4">
	<div class="col-lg-12 col-md-12 col-sm-12">
	
		<carousel  [arrowsOutside]="false" [autoplay]="false" [dots]="false"
			[loop]="true" [cellsToShow]="3" class="croCls">
		
			<div class="carousel-cell  btnsCrousal " *ngFor="let v of jsonData;let index=index;">
				<mat-card class="mcdz" [ngClass]="{ activeMcdz: isActive(index) }" (click)="openCardTab(index,v)">				
					<table class="mcdz-table">
						<tr style="background: #fd6c21;color: white;">
							<td colspan="2"><b class="tbBld">{{v.CampaignName}}</b> <span style="float: right;"><button mat-mini-fab class="btnBag">
								{{v.priority}}
							   </button></span></td></tr>
						<!-- <tr><td><b>Available</b></td><td>{{v.Available}}</td></tr>
						<tr><td><b>Completed</b></td><td>{{v.Completed}}</td></tr> -->

						<tr class="bdrbm1"><td><b> Campaign&nbsp;Type</b></td><td class="text-right">{{v.campaignType}}</td></tr>
						
						<tr class="bdrbm1"><td><b>Total&nbsp;Data </b></td><td class="text-right">{{v.totalData}}</td></tr>
						<tr class="bdrbm1"><td><b>Assigned&nbsp;Data </b></td><td class="text-right">{{v.assignedData}}</td></tr>
						<tr class="bdrbm1"><td><b> Called&nbsp;Data</b></td><td class="text-right">{{v.calledData}}</td></tr>
						<tr><td><b>Available&nbsp;Data </b></td><td class="text-right">{{v.availableData}}</td></tr>
						<tr style="background: #f0f0f0;"><td colspan="2" class="text-center mt-2" ><b>Start Time </b></td></tr>
						<tr style="background: #f0f0f0;"><td colspan="2"  class="text-center">{{v.createdDate}}</td></tr>
					</table>
				</mat-card>
			</div>
		
			
		</carousel>
		<div class="mt-4">
			<table class="viewTablex1">
				<tr>
					<td><b>Details</b></td>
					<td><b>Customer Name</b></td>
					<td><b>Phone</b></td>					
					<td><b>Call Status</b>
						<mat-icon [matMenuTriggerFor]="menuFilter" class="menuFiltercs"> arrow_drop_down</mat-icon>
						<mat-menu #menuFilter="matMenu">
						  <button mat-menu-item><b>Available </b></button>
						  <button mat-menu-item><b> Dialled</b></button>
						</mat-menu>
					</td>
					<td><b>Updated At</b></td>
					<td colspan="2"></td>
				</tr>
				<tbody *ngFor="let a of vTable;let index=index;">
				<tr>
					<td>
						
						<mat-icon class="vCallCellIconx1" style="background: #fd6c21;" (click)="contactNameDetails(a)"  matTooltip="View Details">open_in_new</mat-icon>
							
					</td>
					<td>{{a.customerName}}</td>
					<td>{{a.phone}}</td>					
					<td>{{a.callStatus}}</td>
					<td>{{a.updatedAt}}</td>
					<td><mat-icon class="vCallCellIconx1" matTooltip="Call">call</mat-icon></td>
					<td>
						<!-- <span class="nameIco" matTooltip="Contact Details" (click)="contactNameDetails(a)">{{a.customerName ? a.customerName.charAt(0) : 'NA'}}</span>
						<mat-icon class="vCallCellIconx1"  matTooltip="WhatsApp">whatsapp</mat-icon> -->
					</td>
					

				
				</tr>
			</tbody>
			</table>
		</div>
	</div>
</div>