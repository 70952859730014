
<!-- <div style="overflow: hidden;">
    <div class="row">
        <div class="col-md-10 col-sm-10 col-xs-10">        
            <h2 class="d-head"> Uploade New Candidates</h2>
             </div>
             <div class="col-md-2 col-sm-2 col-xs-2" style="position: relative;right: 14px;">
                <mat-icon class="CR" style="color:red;font-size:28px;" (click)="closeDialog()">cancel_outlined</mat-icon>
             </div>
    </div>
    <form [formGroup]="createdForm">
     
        <div class="row pdt">      
                <div class="col-md-12 col-sm-12 col-xs-12">
                 <div class="upload-btn-wrapper">
      <button class="btn" style="width: 180px;">Upload </button>
      <input type="file" id="fileID" (change)="fileUploadChange($event)" />
      <span style="color: green;">   {{fileUploadStatus}}</span>
    </div>
             </div>            
        </div>  
        <div class="row pdt">      
            <div class="col-md-12 col-sm-12 col-xs-12">
                <button mat-button class="submitbtn" style="width: 180px;" (click)="downloadTemplate()">Download Template</button>
    
                </div>
            </div>
            <br><br>
        </form>
      </div> -->
   <!-- --- -->

   <div class="scrollAss">
    <form [formGroup]="createdForm">
        <div class="row">
            <div class="col-md-12">
            <table class="tbl mt-3" style="width: 100%;">
                <tr style="border-bottom: 1px solid #ccc;">
                    <td><h5 class="hd5">Upload New Candidates</h5></td>
                    <td style="text-align: right;"> 
                        <i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closeDialog()"></i>
                    </td>
            </tr>
            <tr><td colspan="2"><br></td></tr>
            <tr>
                <td>&nbsp;</td>
                <td> 
                    <span class="dnldbtn">
                    <button mat-button *ngIf="!isRecruiterComponent" style="background: #3fb90d !important" (click)="download_file()">Download Excel Template
                    &nbsp;&nbsp;  <i class="fa fa-file-excel" aria-hidden="true"></i>
                    </button>
                    <button mat-button *ngIf="isRecruiterComponent"  (click)="download_file()">Download Excel Template
                        &nbsp;&nbsp;  <i class="fa fa-file-excel" aria-hidden="true"></i>
                        </button>
                    </span>
                </td>
            </tr>
            <!-- <tr><td colspan="2"><br></td></tr> -->
            <tr>
                <td><span class="upSiz">Upload New Candidates<br/> (only supports xlsx file)*</span></td>
                <td>   
                    <div class="upload-btn-wrapper" style="width: 105px;">
                        <button class="btnbrowse">Select file</button>
                    <input type="file" #fileUpload class="filecs" id="fileID" formControlName="file"  (change)="changeFile($event)"/>
                    </div>
                    <span>
                        <button mat-button  *ngIf="!isRecruiterComponent" style="background: #3fb90d !important" class="upload_btn uploadSave" (click)="submitCandidate(createdForm)">Submit  </button>
                        <button mat-button  *ngIf="isRecruiterComponent" class="upload_btn uploadSave" (click)="submitCandidate(createdForm)">Submit  </button>
                    </span>
                </td>
            </tr>
            <tr>
                <td></td>
                <td style="color: #bababa;">
                    {{currentFileobj.name}}
                </td>
            </tr>
            </table>
            <p *ngIf="showHideErrorFile" style="color:red;font-size:20px;font-family:poppins;text-align: center;">* Please upload file</p>
            </div>
        </div>
        <!-- -->
        <div class="row" *ngIf="showduplicatedata">
            <div class="col-md-12">
                <h5 style="font-size: 14px;color: red;text-align: center;margin-top: 10px;">Candidate updated</h5>
            </div>
            <div style="height: 250px; overflow: auto; width: 100%;">
            <table mat-table [dataSource]="dataSource" class="tbl mt-3" style="width: 100%;">

                
                <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
            
                <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> email </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                </ng-container>
            
                <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef> Phone No </th>
                <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            </div>
        </div>        
        <p class="succData">
            {{ fileUploadStatus }}
        </p>
        <p class="errData" *ngIf="showHideUploadMsg">
            {{ showHideUploadMsg }}
        </p>
    </form>
    </div>
   