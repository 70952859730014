
<h3 class="sBiz-title">SmarterBiz</h3>
<br>
<div class="text-center">
    <p  class="hdtext"><span> Get started with SmarterBiz</span></p>
    <p class="LText">Boost your operational efficiency with realtime<br>
        customer journey orchestration across channels.</p>
   
</div>

<div>
<mat-card class="m_card">
    <p *ngIf="showHideMessage" style="font-weight: bold;" [ngStyle]="{'color':msgColor}">{{messageDisplay}}</p>
    <form  [formGroup]="createdForm">
   <div class="row">
       <div class="col-md-6 col-sm-6 col-xs-12">
           <span class="labelNameText">First name </span>
           <input type="text"  formControlName="fname" (keypress)="omit_special_char($event)" class="inputTextType" maxlength="20"/>
       </div>
       <div class="col-md-6 col-sm-6 col-xs-12">
        <span class="labelNameText">Last name </span>
        <input type="text" formControlName="lname" (keypress)="omit_special_char($event)" class="inputTextType"  maxlength="20"/>
       </div>
   </div>
   <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelNameText">Work email address </span>
        <input type="text"  formControlName="email" (change)="validateEmail()" class="inputTextType"  maxlength="50"/>
    </div>
</div>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelNameText">Company Name </span>
        <input type="text"  formControlName="companyName" (keypress)="omit_special_char($event)" class="inputTextType"  maxlength="50"/>
    </div>
</div>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelNameText">How many employees work at your company ? </span>
        <input type="text" maxlength="5" (keypress)="onlyNumberValidation($event)"  formControlName="empWork" class="inputTextType"/>
    </div>
</div>
<div class="row">
    <div class="col-md-6 col-sm-6 col-xs-12">
        <span class="labelNameText">Country code </span>
        <input type="text"  formControlName="countryCode" class="inputTextType"  maxlength="6"/>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12">
     <span class="labelNameText">Phone </span>
     <input type="text"  formControlName="phone" (keypress)="onlyNumberValidation($event)" class="inputTextType"  maxlength="10"/>
    </div>
</div>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelNameText">Your location </span>
        <input type="text"  formControlName="location"  class="inputTextType" maxlength="200"/>
    </div>
</div>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <mat-checkbox color="primary" class="mcbx" formControlName="checkProceed">
            Checking this with proceeding you agree to our terms and
conditions and data protection regulations.
       </mat-checkbox>
    </div>
</div>
<br><br>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
       <button class="onboard-submit" (click)="onSubmitBtn(createdForm)">SUBMIT</button>
    </div>
</div>
<br><br>
</form>
</mat-card>
</div> 

