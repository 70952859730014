<span style="position: relative;
top: -15px;
right: 20px;
float: right;
z-index: 9;
cursor: pointer;">
  <img src="../assets/contiinex/image/closeicon.svg" width="40px" style="position: fixed;" (click)="closeDialog()" /></span>
<div class="containerOne">
  <div class="ms-centeredText ms-scrolly">
    <div>
    <mat-card class="msCardy">
     <div  *ngIf="isShowEyeOn"> <p class="vk-header-title" *ngIf="previewHeader && _txtFormatText=='TEXT'">
       <!-- {{_viewData.header.text}} -->
      {{headerViewUI}}
      </p></div>
     <div  *ngIf="isShowEyeOff"> <p class="vk-header-title" *ngIf="previewHeader && _txtFormatText=='TEXT' && _headerExampleHandle !='' "> {{_headerExampleHandle}}</p></div>

      <p *ngIf="_ImageFormatText=='IMAGE'" class="text-center mb-2">
        <mat-icon style="color: #ff6c00;vertical-align: middle;">image</mat-icon><span class="ml-2">IMAGE</span> </p>

      <p *ngIf="_videoFormatText=='VIDEO'" class="text-center mb-2">
        <mat-icon style="color: #ff6c00;vertical-align: middle;">video_library</mat-icon><span class="ml-2">VIDEO</span>      
      </p>
      <p *ngIf="_documentFormatText=='DOCUMENT'" class="text-center mb-2">
        <mat-icon style="color: #ff6c00;vertical-align: middle;">picture_as_pdf</mat-icon><span class="ml-2">DOCUMENT</span>      
      </p>





     
        <p style="font-family: Poppins;font-size:12.5px;" id="resultdivTemplate1"></p>     
   
        <p style="font-family: Poppins;font-size:12.5px;" id="resultdivTemplate2"></p>
 

     
      <!-- <p *ngIf="_bodyExampleHandle !=''" style="word-break: break-all;">[ {{_bodyExampleHandle}} ] </p> -->


      <p class="vk-footer-title" *ngIf="previewFooter">{{_viewData.footer.text}}</p>
    </mat-card>
      <!-- <p class="mt-2" *ngIf="previewCTAButtons[0]"><button class="button-vk-cs">{{_viewData.buttons[0].text}}</button></p>
      <p class="mt-2" *ngIf="previewCTAButtons[1]"><button class="button-vk-cs">{{_viewData.buttons[1].text}}</button></p>
      <p class="mt-2" *ngIf="previewCTAButtons[2]"><button class="button-vk-cs">{{_viewData.buttons[2].text}}</button></p> -->
<div *ngFor="let a of _viewData.buttons">
  <p class="mt-2"><button class="button-vk-cs">
   <span *ngIf="a.type=='PHONE_NUMBER'"><mat-icon style="vertical-align: bottom;">call </mat-icon> </span>
   <span *ngIf="a.type=='URL'"><mat-icon style="vertical-align: bottom;">chat </mat-icon> </span>
   
   {{a.text}}
  </button></p>

</div>


    </div>
  </div>
  <img class="img-responsive pImgWidth" src="../assets/contiinex/image/ms-preview-img.png"  alt="Preview"/>
</div>


<p class="text-center mb-1" *ngIf="isShowEyeOn"><mat-icon style="color: #fff;cursor: pointer; font-size: 34px;" (click)="eyeVisibilty('on')">visibility</mat-icon></p>
<p class="text-center mb-1" *ngIf="isShowEyeOff"><mat-icon style="color: #fff;cursor: pointer; font-size: 34px;" (click)="eyeVisibilty('off')">visibility_off</mat-icon></p>