
<mat-card class="pCard">
    <div class="vk-container rowBdrBtm">
        <div class="mt-2" style="width: 160px;"><span class="recName1">Agents List</span> </div>                                        
        <div class="" style="width:220px;" > 
                <mat-form-field>
                    <input matInput #searchInput [(ngModel)]="searchFilter"  (keyup)="applyFilter($event.target.value)" autocomplete="off" placeholder="Search">
                    <i matSuffix class="fa fa-search searchicon" style="color: #d5d5d5;"
                        aria-hidden="true"></i>
                </mat-form-field>
            </div>
            <div  class="mt-2"  >
                <button class="app-btn-ui" (click)="uploadExcelBtn()" style="width:auto;padding: 3px 15px;border: 1.6px solid #fd6c21;"><img src="../assets/web_images/uploadExcelIcon.png" style="filter: invert(0%) sepia(95%) saturate(53%) hue-rotate(145deg) brightness(90%) contrast(102%);"/>  Update Paid Amount </button> 
            </div>
            <div class="mt-2" style="width: 110px;">
                <button class="approval-btn-ui" style="background: #f9f8f8;color: #fd6c21;" (click)="refreshBtn()">Refresh</button> 
            </div>
            <div class="mt-2">
                <button class="approval-btn-ui" (click)="approvalAllBtn()">APPROVE ALL</button>
            </div> 
            <div class="mt-2">
                <mat-select [ngClass]="'arrowcsDDL'" class="dropdownDDL" (selectionChange)="changeFilter($event.value)" style="width:150px;" placeholder="Select"  [(value)]="selectedFilter">
                  <mat-option value="All">All</mat-option>
                    <mat-option value="Approve">Approve</mat-option>
                    <mat-option value="In process">In Process</mat-option>
                    <mat-option value="Approved">Approved</mat-option>
                </mat-select>
            </div>
        </div>
        <p style="color: red;text-align: right;font-size: 16px;" *ngIf="showHideErrorMsg">*Approval Amount should be less than current balance.</p>
        <div #TABLE>
            <mat-table #table id="ExampleTable" class="matTableCs" [dataSource]="dataSource">
				<ng-container matColumnDef="gig_id">
					<mat-header-cell *matHeaderCellDef style="flex:0 0 130px;"> Gig ID </mat-header-cell>
					<mat-cell *matCellDef="let element" style="flex:0 0 130px;"> {{element.gig_id}} </mat-cell>
				 </ng-container>
                <ng-container matColumnDef="user_id">
                   <mat-header-cell *matHeaderCellDef style="flex:0 0 130px;"> Agent ID </mat-header-cell>
                   <mat-cell *matCellDef="let element" style="flex:0 0 130px;"> {{element.user_id}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef style="flex:0 0 150px;"> Agent Name </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex:0 0 150px;"> {{element.name}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef> Agent Email </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="pan_number">
                    <mat-header-cell *matHeaderCellDef> PAN Number </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.pan_number}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="actual_earnings">
                    <mat-header-cell *matHeaderCellDef> Gross Earnings </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{currency_code| currencySymbol}} {{element.actual_earnings | number:'1.2-2'}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="total_deductions">
                    <mat-header-cell *matHeaderCellDef> Tax Deductions </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{currency_code| currencySymbol}} {{element.total_deductions | number:'1.2-2'}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="total_earnings">
                    <mat-header-cell *matHeaderCellDef > Net Earnings </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{currency_code| currencySymbol}} {{element.total_earnings | number:'1.2-2'}} </mat-cell>
                 </ng-container>                                 
                 <ng-container matColumnDef="total_payments">
                    <mat-header-cell *matHeaderCellDef > Total Payments </mat-header-cell>
                    <mat-cell *matCellDef="let element" >{{currency_code| currencySymbol}} {{element.total_payments | number:'1.2-2'}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="current_balance">
                    <mat-header-cell *matHeaderCellDef > Current Balance </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{currency_code| currencySymbol}} {{element.current_balance | number:'1.2-2'}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="last_payment_date">
                    <mat-header-cell *matHeaderCellDef > Last Payment Date </mat-header-cell>
                    <mat-cell *matCellDef="let element" > {{element.last_payment_date}} </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="approval_amount">
                    <mat-header-cell *matHeaderCellDef> Approval Amount </mat-header-cell>
                    <mat-cell *matCellDef="let element">                                         
                       <input type="text" *ngIf="!element.approval_amount"  (keyup)="valueInputChange(element.changingBalance,element.current_balance)" [(ngModel)]="element.changingBalance"/>   
                       <input type="text" *ngIf="element.approval_amount" [(ngModel)]="element.approval_amount"  disabled="true" style="background-color: #dddddd;"/> 
                    </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="approval">
                    <mat-header-cell *matHeaderCellDef> Approval </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index" style="cursor: pointer;">                                         
                        <button class="app-btn-ui" *ngIf="((element.approval_request_status == null) || (element.approval_request_status == '')) && !element.approval_progress" (click)="approveBtn(element, i)" > Approve </button> 
                        <button class="app-btn-ui" *ngIf="((element.approval_request_status == null) || (element.approval_request_status == '')) && element.approval_progress" style="color:brown;cursor: default !important;" disabled="true" > Approve </button> 
                        <button class="app-btn-ui" *ngIf="element.approval_request_status == 'IN PROCESS'" style="color:brown;cursor: default !important;" disabled="true"> In Process </button>                                    
                        <button class="app-btn-ui" *ngIf="element.approval_request_status == 'APPROVED'" style="color:brown;cursor: default !important;" disabled="true"> Approved </button>                                    
                    </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="editAmt">
                    <mat-header-cell *matHeaderCellDef > Add Amount </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                   		<img src="../assets/web_images/editAmtIcon.svg" style="cursor: pointer;" (click)="editAmountDialog(element)">
                    </mat-cell>
                 </ng-container>
                 <ng-container matColumnDef="chat">
                    <mat-header-cell *matHeaderCellDef style="flex:0 0 90px;"> Chat </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex:0 0 90px;">   
						<a href="javascript:void(0)" (click)="openAndFindAgentInChat(element)">
							<img src="../assets/web_images/approvalChatIcon.svg"   style="cursor: pointer;"/>
							<!-- <span class="notificationCount"> 0</span> -->
						</a>
					</mat-cell>
                 </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            <div class="text-right">
                <button (click)="approvalChatBtn()" class="app-btn-ui mr-3" style="width:auto;padding: 1px 15px 8px 15px;border: 1.6px solid #fd6c21;">
                    <mat-icon style="position: relative;top: 7px;">
                        chat
                    </mat-icon>&nbsp;&nbsp; Chat </button> 
        
                <button class="app-btn-ui" (click)="exportTable()" style="width:auto;padding: 1px 15px 8px 15px;border: 1.6px solid #fd6c21;"><mat-icon style="position: relative;top: 7px;">
                    download_for_offline
                </mat-icon>&nbsp;&nbsp; Download Excel </button> 
            </div>
        </div>
</mat-card>
<div class="chatAprovalCS" *ngIf="showHide">
    <mat-card style="padding: 2px 0;">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <span style="position: absolute;top: -20px;right: 33px;z-index: 2;cursor: pointer;">
                    <img src="../assets/closeicon.svg" width="40px" style="position: absolute;" (click)="closeChat()" /></span>
            </div>
        </div>
        <div style="padding:0 2px;">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="hOne">Chat</h1>
                </div>
            </div>
        
        
            <div class="row">
                <div class="col-md-4 leftScroll" style="min-height: 350px;height: 350px!important;">
                    <div class="searchDiv">
                        <i class="fa fa-search iconIsearch" aria-hidden="true"></i>
                        <input type="text" placeholder="search messages" (keyup)="filterSearch($event.target.value)"/>
                    </div>
        
                    <div class="chat_list" *ngFor="let val of groupList;let $index=index" (click)="tabkeyMenu(val.key,$index)"
                        [ngClass]="{active_chat: $index == selectedActive}">
                        <div class="chat_people">
                            <table width="100%">
                                <tr>
                                    <td style="vertical-align: initial;width: 20%;">
                                        <span> <img src="../assets/userImage.png"
                                                style="border: 1px solid #cccccc;width: 28px;height: 28px;border-radius: 50%;">
                                        </span>
                                    </td>
                                    <td>
                                        <table class="tableuser">
                                            <tr>
                                                <td style="font-size: 13px;"> <b>{{val.tab_name}}</b> </td>
                                                <td style="text-align: right;">
                                                    <span class="chat_time">
                                                        {{val.last_message_time}}
                                                        <span *ngIf="val.unread_messages > 0"
                                                            class="badgenewcs">{{val.unread_messages}}</span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"> <span class="chat_p">{{val.last_message}} </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="rightMaindiv1" style="width: 100%;">
                        <div class="parentdiv" *ngIf="IsVisible" style="min-height: auto !important;">
                            <div class="headerPart">
                                <table width="100%">
                                    <tr>
                                        <td style="vertical-align: initial; width: 6%;">
                                            <span> <img src="../assets/userImage.png"
                                                    style="border: 1px solid #ccc;width: 40px;height: 40px;border-radius: 50%;margin-bottom: 8px;">
                                            </span>
                                        </td>
                                        <td>
                                            <table class="tableuser">
                                                <tr>
                                                    <td> <b>{{_dataTabName}}</b> </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
        
                            <div class="bodyPart rightScroll" id="messageBody" style="height: 245px;">
                                <div *ngIf="groupList[selectedActive]">
                                    <div class="mesgs"
                                        *ngFor="let msg of groupList[selectedActive].messages;let $msgIndex=index">
                                        <div class="incoming_msg"
                                            *ngIf="myUser.id != msg.from_id">
                                            <div class="received_msg">
                                                <div class="received_withd_msg">
                                                    <p *ngIf="msg.message_type == 'TEXT'"><span style="font-size:11px;display:block;color:black"> {{msg.user}}
                                                        </span>{{msg.message}} <span class="time_date"> {{msg.message_time}}
                                                        </span></p>
														<p *ngIf="msg.message_type == 'IMAGE'"><span style="font-size:11px;display:block;color:black"> {{msg.user}}
                                                        </span><img src={{msg.message}} style="width: 100%;" /> <span class="time_date"> {{msg.message_time}}
                                                        </span></p>
														<p *ngIf="msg.message_type == 'AUDIO'"><span style="font-size:11px;display:block;color:black"> {{msg.user}}
                                                        </span><audio style="width:100%" controls> <source src={{msg.message}} type="audio/ogg"> <source src={{msg.message}} type="audio/mpeg"> </audio> <span class="time_date"> {{msg.message_time}}
                                                        </span></p>
														
                                                </div>
                                            </div>
                                        </div>
        
                                        <div class="outgoing_msg"
                                            *ngIf="myUser.id == msg.from_id && msg.message_type == 'TEXT'">
                                            <div class="sent_msg">
                                                <p><span style="font-size:11px;display:block;color:black"> {{msg.user}}
                                                    </span>{{msg.message}}
                                                    <span class="time_date">{{msg.message_time}}</span>
                                                </p>
                                            </div>
                                        </div>
        
                                    </div>
                                </div>
        
                            </div>
                            <div class="footerPart" style="bottom: 0;">
                                <span class="reqField" *ngIf="mandatoryFiled">Required field</span>
        
                                <div class="row">
                                    <div class="col-md-9 col-sm-9 mr-2">
                                        <input type="text" id="textarea" (keyup)="onTextMessage()" [(ngModel)]="txtmsg"
                                            class="inputtext" placeholder="Type a message here…" required="true"
                                            [disabled]="socketConnection" (keydown.enter)="sendMessage_With_Enter(txtmsg)" />
        
                                        <!-- <div class="image-upload">
                                            <label for="file-input" class="fa fa-paperclip input-icon1"></label>
                                            <input id="file-input" type="file" (change)="uploadFile($event)"
                                                file-model="myFile" />
                                        </div> -->
                                    </div>
                                    <div class="col-md-1  col-sm-2">
                                        <!-- <button class="btncs" id="send" style="right:0px" (click)="endSession()"><i
                                                class="fa fa-times" aria-hidden="true"></i></button> -->
                                    </div>
                                    <div class="col-md-1  col-sm-2">
                                        <button class="btncs" style="right: 25px;;background: #fd6c21;" id="send"
                                            (click)="sendMessage_With_Enter(txtmsg)"><i class="fa fa-send"
                                                aria-hidden="true"></i></button>
                                    </div>
                                </div>
        
        
                            </div>
                        </div>
                        <!--end chat online header-->
                    </div>
                </div>
            </div>
        
        
        
        
            <div *ngIf="socketConnection" class="interNetMesg" id="closeInternetMsg">
                <p><span class="warn-cs"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                    No internet connection.<br>
                    Kindly check your internet connectivity</p>
            </div>
        
            <div id="myModal" class="modalLarge">
                <span class="closeLarge" (click)="closeLarge()">&times;</span>
                <img class="modal-large-content" id="img01">
            </div>
        
        
        
        </div>
        </mat-card>

</div>
<div id="cover-spin" *ngIf="currentLoader">
	<mat-spinner strokeWidth="3" [diameter]="100"></mat-spinner>
</div>