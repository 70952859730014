import { Component,OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { MyserviceService } from '../../myservice.service';
import { MessagingService } from '../../messaging.service';

@Component({
  selector: 'app-plugin',
  templateUrl: './plugin.component.html',
  styleUrls: ['./plugin.component.scss']
})
export class PluginComponent implements OnInit {

  title = 'agent-plugin-App';
  _auditScore:any;
  _auditTime:any;
	navItems: string[] = ["home", "assessment", "workSlot", "callHistory", "earnings", "employee", "contacts", "chat", "text","whatsapp"];
  message: any;
  notifications: any = {count:0, notif:[]};
  _countLeadLength:any;
  _countfollowUpLength:any;
  textMaxMin:any="MaxMize";
  callStatus;
  _pathUrl:any="";
  api_end_point:any;
  constructor(public router:Router, private service:MyserviceService,private API : ApiService, private messagingService: MessagingService) { 
    }
    user: any = this.service.get_loggedin_user();
  ngOnInit() {
	this.api_end_point = this.service.get_API_End_Point();
	  this.callStatus = this.service.getLastCallStatus();
	  this.service.getCallStatus().subscribe(obj => {
		  console.log("home-call-plugin", obj);
		  this.callStatus = obj;

	  })
	  const userId = this.user.id;
    this.messagingService.requestPermission(userId)
     this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage;
    console.log(this.message);
    this.messagingService.getMessage().subscribe(obj => {
		
      if(obj != null){
		  console.log("recccccc1", obj.data.data)
		  var notifData = JSON.parse(obj.data.data)
		  if (notifData.title != 'Customer Page URL UPDATE'){
			  var oldNotif = this.notifications;

			  oldNotif.count = oldNotif.count + 1;
			  oldNotif.notif.push(notifData);
			  this.service.updateNotificationStatus(oldNotif)
		  }else{
			//   var data = JSON.parse(obj.data.data)
			  var updateObj = this.callStatus;

			  this.service.updateCallStatus(updateObj);

			  this.API.getWidgetUserByPhone(''+notifData.customer_number,this.api_end_point).subscribe((data: any) => {
				  let _response = data;
				  if (_response && _response.success) {

					  var updateObj = this.callStatus || {};

					  updateObj.widgetUser = _response.success;
					  this.service.updateCallStatus(updateObj);
				  }
				  else {

				  }
			  });
		  }
		  
        
		 
      }
      
    });
  this.getHomeData();
  //this.getDashboardList();
  this.getContacted();
  console.log(this.user.groupid)
  let stateSettings :any = this.service.get_stage_settings();
    if(stateSettings && stateSettings.extraoptions){
      for(let opt of stateSettings.extraoptions){
        if(opt.name == "agent_tabs"){
          this.navItems = JSON.parse(opt.definition)
        }
      }
    }
    if(this.user.status != 'On Board' && this.user.status != 'OJT'){
      this.navItems = ["assessment"]
    }else if (this.navItems.indexOf('assessment') > -1){
      this.navItems.splice(this.navItems.indexOf('assessment'), 1);
    }
    // for(let tab of this.navItems){
      
    // }

  // if(this.user.groupid == 31180){
  //   this.navItems = ["home","callHistory","chat","text"]
  // }
    }
    logout(){
      var obj = {"activity_type":"signout","gps_status":"{\"latitude\":0,\"longitude\":0}","call_recording_status":"false","battery_status":"100%","user_id": this.user.id,"version":"26"} as any;
    this.API.agentStartEndSession(obj,this.user.server_url).subscribe(
      (res: any) => {
        console.log(res);
        this.service.clearAllItems();
		window.sessionStorage.clear();
		if(window.location.href.includes('dev.youearn.in')){
			window.location.href = 'https://dev.contiinex.com/';
		  }
		 else if(window.location.href.includes('dev.contiinex.com')){
			window.location.href='https://dev.contiinex.com/';
		}	
		else if(window.location.href.includes('localhost:4200')){
			window.location.href='http://localhost:4200/Login';
		}	
		else if(window.location.href.includes('https://www.contiinex.com')){
			window.location.href='https://www.contiinex.com/';
		}
		  else{		
			window.location.href = 'https://www.contiinex.com/';
		  }
        navigator.mediaDevices.getUserMedia({video: true, audio: false})
        .then(mediaStream => {         
          const stream = mediaStream;
          const tracks = stream.getTracks();
  
          tracks[0].stop;
        })
      }, (err) => {
        console.log(err)
      }
    )
      
    }
    clickFace(){
      //commented path of face...//
     // this.router.navigateByUrl('/agentPlugin/face');
      
    }
    // profileClick(){
    //   this.router.navigateByUrl('/agentPlugin/profile');
    // }
    getHomeData() {
      let _today: any = new Date();
      let _startDate = new Date(_today.setDate(_today.getDate() - 30)).toISOString();
      let _endDate = new Date(_today.setDate(_today.getDate() + 30)).toISOString();
      let userid = this.user.id;
      let obj = {
        "userid": userid,
        "start_time": _startDate,
        "end_time": _endDate,
        "agents_list": userid
      }
      this.API.agentPluginHome(obj,this.api_end_point).subscribe((data: any) => {
        let _response = data;
        if (_response) {
           this._auditScore= _response.audited_score;
           this._auditTime= _response.talk_time;
        }
        else{
  
        }
      });
    }
    getDashboardList(){
      let _today: any = new Date();
      let _startDate = new Date(_today.setDate(_today.getDate() - 30)).toISOString();
      let _endDate = new Date(_today.setDate(_today.getDate() + 30)).toISOString();
      let userid = this.user.id;
      let obj = {
        "userid": userid,
        "start_time": _startDate,
        "end_time": _endDate,
        "skip":0,
        "calendar_type":"smartercalendar",
        "top":100
  
      }
      this.API.agentPluginDashboardList(obj,this.api_end_point).subscribe((data: any) => {
        let _response = data.event;
        if (_response.length !=0) {
          let ELEMENT_DATA=_response;
         // let _followupData=ELEMENT_DATA.filter(v=>{return v.calendar_type== "smartercalendar"})
         let _followupData=ELEMENT_DATA[0].flpcount[0];
         this._countfollowUpLength=this.API.followupTodo(_followupData);
        }
        else{
          this._countfollowUpLength=this.API.followupTodo(0);
        }
      });
    }
    getContacted(){
      let _today: any = new Date();
      let _startDate =new Date(_today.setDate(_today.getDate() - 30)).toISOString();
      let _endDate = new Date(_today.setDate(_today.getDate() + 30)).toISOString() ;
      let userid = this.user.id;
      let obj = {
        "userid": userid,
        "start_time": _startDate,
        "end_time": _endDate
      }
      this.API.agentPluginContacted(obj,this.api_end_point).subscribe((data: any) => {
        
        let _response = data.success;
        if (_response) {
          let ELEMENT_DATA=_response;
          let _contactedData=ELEMENT_DATA.filter(v=>{return v.event_type== "call"})
         this._countLeadLength=this.API.leadsConnected(_contactedData.length);
        }
      });
  
    } 
    showHideStartDialPopup:boolean=false;
    openStartDialPopup(){
      this.showHideStartDialPopup=true;
  7
  
    }
    closeStartDialPopup(){
      this.showHideStartDialPopup=false;
      window.scroll(0,0);
    }
    minimizeStartDialPopup(val:any){
      this.textMaxMin="";
      this.textMaxMin=val;
      
    }
    maxmizeStartDialPopup(val:any){
      this.textMaxMin="";
      this.textMaxMin=val;
    }
 
    getUrlforDisabled() {
      this._pathUrl="";
      this._pathUrl=window.location.pathname;
      if (this._pathUrl =="/agentPluginPC/face") {
        return true;
      } else {
        return false;
      }
    }
}
