import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared.module';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartModule } from 'angular-highcharts';

import { ActivateGuard } from './activate.guard';
import { MyserviceService } from './myservice.service';
import { TokenInterceptorService } from './token-interceptor.service';
import { ServicesService } from './dashboard/services.service';
import { ApiService } from './api.service';
import { SocketelsService } from './socketels.services';
import { RtddataService } from './rtdData.service';
import { Utils } from './utils';


import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { SigninComponent } from './signin/signin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RecruiterComponent } from './dashboard/recruiter/recruiter.component';
import { CampaignComponent } from './dashboard/campaign/campaign.component';
import { RecruiterTableComponent } from './dashboard/recruiter-table/recruiter-table.component';
import { CampaignTableComponent } from './dashboard/campaign-table/campaign-table.component';
import { CampaignDialogComponent } from './dashboard/campaign/campaign-dialog/campaign-dialog.component';

import { DashboardComponent as DashboardComponentV2 } from './campaign-v2/dashboard.component';
import { CampaignComponent as CampaignComponentV2 } from './campaign-v2/campaign/campaign.component';
import { CampaignTableComponent as CampaignTableComponentV2 } from './campaign-v2/campaign-table/campaign-table.component';
import { CampaignDialogComponent as CampaignDialogComponentV2 } from './campaign-v2/campaign/campaign-dialog/campaign-dialog.component';


import { AgencyComponent } from './agency/agency.component';
import { CustomerComponent } from './customer/customer.component';
import { StorySliderComponent } from './home/story-slider/story-slider.component';
import { UearnSlideComponent } from './home/uearn-slide/uearn-slide.component';
import { TechsupportComponent } from './techsupport/techsupport.component';
import { IddailogComponent } from './techsupport/iddailog/iddailog.component';
import { OpsdashboardComponent } from './opsdashboard/opsdashboard.component';
import { PmdashboardComponent } from './pmdashboard/pmdashboard.component';
import { TrainingComponent } from './training/training.component';
import { AgentsComponent } from './dashboard/agents/agents.component';
import { AgentsTableComponent } from './dashboard/agents/agents-table/agents-table.component';
import { OnboardTechsupportComponent } from './onboard-techsupport/onboard-techsupport.component';
import { DocUploadComponent } from './opsdashboard/doc-upload/doc-upload.component';
import { DocViewComponent } from './opsdashboard/doc-view/doc-view.component';
import { ClientStatusComponent } from './dashboard/client-status/client-status.component';
import { EditIDComponent } from './onboard-techsupport/edit-id/edit-id.component';
import { InboundComponent } from './pmdashboard/inbound/inbound.component';
import { OutboundComponent } from './pmdashboard/outbound/outbound.component';
import { DashboardTrainingComponent } from './dashboard-training/dashboard-training.component';
import { AgentsListComponent } from './agents-list/agents-list.component';
import { DashboardBatchonliveTableComponent } from './dashboard-batchonlive-table/dashboard-batchonlive-table.component';
import { DashboardTraineravailibityTableComponent } from './dashboard-traineravailibity-table/dashboard-traineravailibity-table.component';
import { AgentInfoDialogComponent } from './agent-info-dialog/agent-info-dialog.component';
import { BatchListComponent } from './batch-list/batch-list.component';
import { BatchCreateComponent } from './batch-create/batch-create.component';
import { TrainerListComponent } from './trainer-list/trainer-list.component';
import { TrainerCreateComponent } from './trainer-create/trainer-create.component';
import { TrainerInfoDialogComponent } from './trainer-info-dialog/trainer-info-dialog.component';
import { TrainersAvailibilityComponent } from './trainers-availibility/trainers-availibility.component';
import { AssignBatchComponent } from './assign-batch/assign-batch.component';
import { ReportComponent } from './report/report.component';
import { OnJobTrainingComponent } from './on-job-training/on-job-training.component';
import { OnJobTrainingAgentComponent } from './on-job-training-agent/on-job-training-agent.component';
import { CertificationComponent } from './certification/certification.component';
import { CreateOjtBatchComponent } from './create-ojt-batch/create-ojt-batch.component';
import { SendMessageComponent } from './send-message/send-message.component';
import { SendMessageAgentComponent } from './send-message-agent/send-message-agent.component';
import { SendNotificationDialogComponent } from './send-notification-dialog/send-notification-dialog.component';
import { BatchDaysTrainingComponent } from './batch-days-training/batch-days-training.component';
import { CalandarComponent } from './calandar/calandar.component';
import { OjtAgentInfoComponent } from './ojt-agent-info/ojt-agent-info.component';
import { HiringComponent } from './hiring/hiring.component';
import { DocStatusComponent } from './opsdashboard/doc-status/doc-status.component';
import { ManagementTeamComponent } from './management-team/management-team.component';
import { PmdashComponent } from './pmdash/pmdash.component';
import { ManagementTeamDialogComponent } from './management-team-dialog/management-team-dialog.component';
import { PmdashboardDailogComponent } from './pmdashboard-dailog/pmdashboard-dailog.component';
import { Hiring2Component } from './hiring2/hiring2.component';
import { VoiceCallAuditComponent } from './voice-call-audit/voice-call-audit.component';
import { EmailAuditComponent } from './email-audit/email-audit.component';
import { ChatAuditComponent } from './chat-audit/chat-audit.component'
import { ExcelService } from './reportExcel.service';
import { AuditorManagementComponent } from './auditor/auditor-management/auditor-management.component';
import { AuditorDashboardComponent } from './auditor/auditor-dashboard/auditor-dashboard.component';
import { AuditorProjectsComponent } from './auditor/auditor-projects/auditor-projects.component';
import { AuditorAuditorsComponent } from './auditor/auditor-auditors/auditor-auditors.component';
import { AuditorReportsComponent } from './auditor/auditor-reports/auditor-reports.component';
import { AuditorHelpComponent } from './auditor/auditor-help/auditor-help.component';
import { AuditorProjectViewDialogComponent } from './auditor/auditor-project-view-dialog/auditor-project-view-dialog.component';
import { AuditorAddClientDialogComponent } from './auditor/auditor-add-client-dialog/auditor-add-client-dialog.component';
import { AuditorProjectEditDialogComponent } from './auditor/auditor-project-edit-dialog/auditor-project-edit-dialog.component';
import { FkEmpComponent } from './fk-emp/fk-emp.component';
import { AttributeTableComponent } from './fk-emp/attribute-table/attribute-table.component';
import { AttributeChartComponent } from './fk-emp/attribute-chart/attribute-chart.component';
import { DevTestComponent } from './dev-test/dev-test.component';
import { DashboardInboundComponent } from './dashboard-inbound/dashboard-inbound.component';
import { CampaignAgentsComponent } from './campaign-v2/campaign-agents/campaign-agents.component';
import { AuditorDataComponent } from './auditor/auditor-data/auditor-data.component';
import { AuditorDataViewDialogComponent } from './auditor/auditor-data-view-dialog/auditor-data-view-dialog.component';
import { EmployeeviewComponent } from './fk-emp/employeeview/employeeview.component';
import { CampaignDetailsComponent } from './campaign-v2/campaign-details/campaign-details.component';
import { CampaignDetailsAgentDialogComponent } from './campaign-v2/campaign-details-agent-dialog/campaign-details-agent-dialog.component';
import { CallFlowFormComponent } from './call-flow-web-form/call-flow-form/call-flow-form.component';
import { CallFormCreateComponent } from './call-flow-web-form/call-form-create/call-form-create.component';
import { CallFreeTextComponent } from './call-flow-web-form/call-free-text/call-free-text.component';
import { CallNumberComponent } from './call-flow-web-form/call-number/call-number.component';
import { CallDateComponent } from './call-flow-web-form/call-date/call-date.component';
import { CallTimeComponent } from './call-flow-web-form/call-time/call-time.component';
import { CallRatingSeekbarComponent } from './call-flow-web-form/call-rating-seekbar/call-rating-seekbar.component';
import { CallDatetimeComponent } from './call-flow-web-form/call-datetime/call-datetime.component';
import { CallCheckboxComponent } from './call-flow-web-form/call-checkbox/call-checkbox.component';
import { CallRadiobuttonComponent } from './call-flow-web-form/call-radiobutton/call-radiobutton.component';
import { CallDropdownComponent } from './call-flow-web-form/call-dropdown/call-dropdown.component';
import { CallSearchableDropdownComponent } from './call-flow-web-form/call-searchable-dropdown/call-searchable-dropdown.component';
import { CallMultiselectionDropdownComponent } from './call-flow-web-form/call-multiselection-dropdown/call-multiselection-dropdown.component';
import { CallYesnoButtonComponent } from './call-flow-web-form/call-yesno-button/call-yesno-button.component';
import { CallFollowupComponent } from './call-flow-web-form/call-followup/call-followup.component';
import { CallAttachmentComponent } from './call-flow-web-form/call-attachment/call-attachment.component';
import { CallButtonComponent } from './call-flow-web-form/call-button/call-button.component';
import { CallMessageComponent } from './call-flow-web-form/call-message/call-message.component';
import { CallFormPreviewDialogComponent } from './call-flow-web-form/call-form-preview-dialog/call-form-preview-dialog.component';
import { CallDuringFormSummaryDialogComponent } from './call-flow-web-form/call-during-form-summary-dialog/call-during-form-summary-dialog.component';
import { CallNumberPickerComponent } from './call-flow-web-form/call-number-picker/call-number-picker.component';

import { ForgotPasswordComponent } from './home/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './home/reset-password/reset-password.component';
import { HomeHeaderComponent } from './home/home-header/home-header.component';
import { JoinusBusinessComponent } from './home/joinus-business/joinus-business.component';
import { JoinusComponent } from './home/joinus/joinus.component';
import { FaqComponent } from './home/faq/faq.component';
import { OrchestrationComponent } from './orchestration/orchestration/orchestration.component';
import { OrchestrationEmailComponent } from './orchestration/orchestration-email/orchestration-email.component';
import { OrchestrationEmailTemplateComponent } from './orchestration/orchestration-email-template/orchestration-email-template.component';
import { OrchestrationSmsTemplateComponent } from './orchestration/orchestration-sms-template/orchestration-sms-template.component';
import { OrchestrationWhatsappTemplateComponent } from './orchestration/orchestration-whatsapp-template/orchestration-whatsapp-template.component';
import { OrchestrationEmaildeliveredAgentTemplateComponent } from './orchestration/orchestration-emaildelivered-agent-template/orchestration-emaildelivered-agent-template.component';
import { OrchestrationAvailableAgentTemplateComponent } from './orchestration/orchestration-available-agent-template/orchestration-available-agent-template.component';
import { HomeAgentPluginComponent } from './agentPlugin/home-agent-plugin/home-agent-plugin.component';
import { WorkSlotAgentPluginComponent } from './agentPlugin/work-slot-agent-plugin/work-slot-agent-plugin.component';
import { CallHistoryAgentPluginComponent } from './agentPlugin/call-history-agent-plugin/call-history-agent-plugin.component';
import { EarningsAgentPluginComponent } from './agentPlugin/earnings-agent-plugin/earnings-agent-plugin.component';
import { StartDialDialogComponent } from './agentPlugin/start-dial-dialog/start-dial-dialog.component';
import { RegisterAgentComponent } from './agentPlugin/register-agent/register-agent.component';
import { LoginAgentComponent } from './agentPlugin/login-agent/login-agent.component';
import { PluginComponent } from './agentPlugin/plugin/plugin.component';
import { FaceRecognizeComponent } from './agentPlugin/face-recognize/face-recognize.component';
import { AssesmentComponent } from './agentPlugin/assesment/assesment.component';
import { ProfilePluginComponent } from './agentPlugin/profile-plugin/profile-plugin.component';
import { ProfileAttendanceDialogComponent } from './agentPlugin/profile-attendance-dialog/profile-attendance-dialog.component';
import { ProfileLeaveDialogComponent } from './agentPlugin/profile-leave-dialog/profile-leave-dialog.component';
import { ProfileResignationDialogComponent } from './agentPlugin/profile-resignation-dialog/profile-resignation-dialog.component';
import { AssessmentComponent } from './dashboard/assessment/assessment.component';
import { CreateDialogComponent } from './dashboard/assessment/create-dialog/create-dialog.component';
import { AssessmentTableComponent } from './dashboard/assessment/assessment-table/assessment-table.component';
import { ChatAgentComponent } from './agentPlugin/chat-agent/chat-agent.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FeedDashComponent } from './feed-dash/feed-dash.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { OrcheContentComponent } from './orchestration/orche-content/orche-content.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { CallrecordsdashComponent } from './callrecordsdash/callrecordsdash.component';
import { AgentDashComponent } from './sharekhan/agent-dash/agent-dash.component';
import { AgentFeedBackComponent } from './sharekhan/agent-feed-back/agent-feed-back.component';
import { SharekhanComponent } from './sharekhan/sharekhan/sharekhan.component';
import { TlTableCountsComponent } from './sharekhan/tl-table-counts/tl-table-counts.component';
import { MessagingService } from './messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire'
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { ActivityComponent } from './sharekhan/activity/activity.component';
import { AgentActivityComponent } from './sharekhan/agent-activity/agent-activity.component';
import { VoiceCallUploadAudioComponent } from './voice-call-upload-audio/voice-call-upload-audio.component';
import { OnboardingGetStartedComponent } from './onboarding/onboarding-get-started/onboarding-get-started.component';
import { OnboardingProcessPage1Component } from './onboarding/onboarding-process-page1/onboarding-process-page1.component';
import { OnboardingProcessPage2Component } from './onboarding/onboarding-process-page2/onboarding-process-page2.component';
import { OnboardingProcessPage3Component } from './onboarding/onboarding-process-page3/onboarding-process-page3.component';
import { OnboardingProcessPage4Component } from './onboarding/onboarding-process-page4/onboarding-process-page4.component';
import { OnboardingProcessPage5Component } from './onboarding/onboarding-process-page5/onboarding-process-page5.component';
import { OnboardingProcessPage6Component } from './onboarding/onboarding-process-page6/onboarding-process-page6.component';
import { OnboardingProcessPage7Component } from './onboarding/onboarding-process-page7/onboarding-process-page7.component';
import { DemoPortalComponent } from './demo-portal/demo-portal/demo-portal.component';
import { DemoPortalDashboardComponent } from './demo-portal/demo-portal-dashboard/demo-portal-dashboard.component';
import { DemoPortalCrcCallComponent } from './demo-portal/demo-portal-crc-call/demo-portal-crc-call.component';
import { DemoPortalSettingComponent } from './demo-portal/demo-portal-setting/demo-portal-setting.component';
import { VoiceBiometricComponent } from './voice-biometric/voice-biometric.component';
import { AssessmentUploadComponent } from './training/assessment-upload/assessment-upload.component';
import { GenericComponent } from './generic/generic.component';
import { GenericOutboundComponent, OutboundApiErrorDialog } from './generic/generic-outbound/generic-outbound.component';
// import { GenericOutboundService } from './generic/generic-outbound/generic-outbound.service';
import { GenericAgentactivityComponent } from './generic/generic-agentactivity/generic-agentactivity.component';
import { GenericFeedbackComponent } from './generic/generic-feedback/generic-feedback.component';
import { DemoPortalObdCallComponent } from './demo-portal/demo-portal-obd-call/demo-portal-obd-call.component';
import { DemoPortalDobdCallComponent } from './demo-portal/demo-portal-dobd-call/demo-portal-dobd-call.component';
import { DemoPortalSmsComponent } from './demo-portal/demo-portal-sms/demo-portal-sms.component';
import { DemoPortalWhatsappComponent } from './demo-portal/demo-portal-whatsapp/demo-portal-whatsapp.component';
import { DemoPortalSipCallComponent } from './demo-portal/demo-portal-sip-call/demo-portal-sip-call.component';
import { DemoPortalEmailComponent } from './demo-portal/demo-portal-email/demo-portal-email.component';
import { AuditFormsComponent } from './audit-forms/audit-forms.component';
import { AuditFormDialogComponent } from './audit-form-dialog/audit-form-dialog.component';

import { CertificationScoreDialogComponent } from './auditor/certification-score-dialog/certification-score-dialog.component';
import { BatchCreateUploadDialogComponent } from './auditor/batch-create-upload-dialog/batch-create-upload-dialog.component';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
	GoogleLoginProvider,
	FacebookLoginProvider
} from 'angularx-social-login';
import { SendAppLinkDialogComponent } from './dashboard/recruiter-table/send-app-link-dialog/send-app-link-dialog.component';
import { TimeslotSettingComponent } from './timeslot-setting/timeslot-setting.component';
import { DashboardAddmoreDialogComponent } from './dashboard-addmore-dialog/dashboard-addmore-dialog.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MoreSlotDialogComponent } from './more-slot-dialog/more-slot-dialog.component';
import { FeedbackCondidatesDialogComponent } from './dashboard/recruiter-table/feedback-condidates-dialog/feedback-condidates-dialog.component';
import { MultiselectSearchFixDirective } from './directive/app-multiselect-search-fix.directive';
import { DashboardAddagentComponent } from './dashboard-addagent/dashboard-addagent.component';
import { WhatsappDashboardComponent } from './generic/whatsapp-dashboard/whatsapp-dashboard.component';
import { PaymentDashboardComponent } from './payment-dashboard/payment-dashboard.component';
import { PaymentDashboardConfigureComponent } from './payment-dashboard-configure/payment-dashboard-configure.component';
import { PaymentDashboardHistoryComponent } from './payment-dashboard-history/payment-dashboard-history.component';
import { PaymentDashboardApprovalComponent } from './payment-dashboard-approval/payment-dashboard-approval.component';
import { PaymentDashboardDetailsviewDialogComponent } from './payment-dashboard-detailsview-dialog/payment-dashboard-detailsview-dialog.component';
import { PaymentDashboardTableComponent } from './payment-dashboard-table/payment-dashboard-table.component';
import { PaymentDashboardApprovalEditDialogComponent } from './payment-dashboard-approval-edit-dialog/payment-dashboard-approval-edit-dialog.component';

import { ContiinexReportsComponent } from './contiinex-reports/contiinex-reports.component';
import { ViewDetailsDialogComponent } from './contiinex-reports/view-details-dialog/view-details-dialog.component';
import { PaymentDashboardApprovalChatDialogComponent } from './payment-dashboard-approval-chat-dialog/payment-dashboard-approval-chat-dialog.component';
import { PaymentDashboardApprovalUploadfileDialogComponent } from './payment-dashboard-approval-uploadfile-dialog/payment-dashboard-approval-uploadfile-dialog.component';
import { PaymentDashboardUploadDialogComponent } from './payment-dashboard-upload-dialog/payment-dashboard-upload-dialog.component';
import { ContiinexWebApplicationComponent } from './contiinex-web-application/contiinex-web-application.component';
import { ContiinexComnexPageComponent } from './contiinex-comnex-page/contiinex-comnex-page.component';
import { ContiinexGignexPageComponent } from './contiinex-gignex-page/contiinex-gignex-page.component';
import { ContiinexAinexPageComponent } from './contiinex-ainex-page/contiinex-ainex-page.component';
import { ContiinexContactusPageComponent } from './contiinex-contactus-page/contiinex-contactus-page.component';
import { ContiinexWebLoginComponent } from './contiinex-web-login/contiinex-web-login.component';
import { RecruiterAgentDetailsDialogComponent } from './recruiter-agent-details-dialog/recruiter-agent-details-dialog.component';
import { ContactCallPluginComponent } from './agentPlugin/contact-call-plugin/contact-call-plugin.component';
import { FaqNewComponent } from './home/faq-new/faq-new.component';
import { PaymentDashboardEventsComponent } from './payment-dashboard-events/payment-dashboard-events.component';
import { ContiinexWebForgotResetPasswordComponent } from './contiinex-web-forgot-reset-password/contiinex-web-forgot-reset-password.component';
import { ContactRmUploadDailogComponent } from './timeslot-setting/contact-rm/contact-rm-upload-dailog/contact-rm-upload-dailog.component';
import { QuestionnaireResponseDialogComponent } from './questionnaire-response-dialog/questionnaire-response-dialog.component';
import { CurrencySymbolPipe } from './currency-symbol.pipe';
import { CampaignDetailsViewPageComponent } from './campaign-v2/campaign-details-view-page/campaign-details-view-page.component';
import { CampaignDetailsAddAgentDialogComponent } from './campaign-v2/campaign-details-add-agent-dialog/campaign-details-add-agent-dialog.component';
import { CampaignDetailsTransferAgentDataDialogComponent } from './campaign-v2/campaign-details-transfer-agent-data-dialog/campaign-details-transfer-agent-data-dialog.component';
import { CampaignCentralCreatePageComponent } from './campaign-v2/campaign-central-create-page/campaign-central-create-page.component';
import { BADashboardComponent } from './badashboard/badashboard.component';
import { CentralcampaignComponent } from './centralcampaign/centralcampaign.component';
import { CentralDashboardComponent } from './central-dashboard/central-dashboard.component';
import { BadashboardDataPageComponent } from './badashboard-data-page/badashboard-data-page.component';
import { BadashboardDialedPageComponent } from './badashboard-dialed-page/badashboard-dialed-page.component';
import { BadashboardContactedPageComponent } from './badashboard-contacted-page/badashboard-contacted-page.component';
import { BadashboardAgentsPageComponent } from './badashboard-agents-page/badashboard-agents-page.component';
import { BadashboardActiveTimePageComponent } from './badashboard-active-time-page/badashboard-active-time-page.component';
import { BadashboardQdePageComponent } from './badashboard-qde-page/badashboard-qde-page.component';
import { CentralDashboardDataPageComponent } from './central-dashboard-data-page/central-dashboard-data-page.component';
import { CentralDashboardDialedPageComponent } from './central-dashboard-dialed-page/central-dashboard-dialed-page.component';
import { CentralDashboardContactedPageComponent } from './central-dashboard-contacted-page/central-dashboard-contacted-page.component';
import { CentralDashboardAgentsPageComponent } from './central-dashboard-agents-page/central-dashboard-agents-page.component';
import { CentralDashboardActiveTimePageComponent } from './central-dashboard-active-time-page/central-dashboard-active-time-page.component';
import { CentralDashboardQdePageComponent } from './central-dashboard-qde-page/central-dashboard-qde-page.component';
import { CentralSearchPipe } from './central-search.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { HmsStringPipe, LocalStringPipe, MaskPhoneNumber } from './pipes.pipe';
import { CentralDasboardDdSectionPartComponent } from './central-dasboard-dd-section-part/central-dasboard-dd-section-part.component';

import { NewContiinexHomePageComponent } from './contiinex-website/new-contiinex-home-page/new-contiinex-home-page.component'
import { NewContiinexMenuPageComponent } from './contiinex-website/new-contiinex-menu-page/new-contiinex-menu-page.component';
import { NewContiinexComnexEngagePageComponent } from './contiinex-website/new-contiinex-comnex-engage-page/new-contiinex-comnex-engage-page.component';
import { NeContiinexComnexEnablePageComponent } from './contiinex-website/ne-contiinex-comnex-enable-page/ne-contiinex-comnex-enable-page.component';
import { NewContiinexNewContactPageComponent } from './contiinex-website/new-contiinex-new-contact-page/new-contiinex-new-contact-page.component';
import { NewContiinexWhatsappApiPageComponent } from './contiinex-website/new-contiinex-whatsapp-api-page/new-contiinex-whatsapp-api-page.component';
import { NewContiinexComnexExperiencePageComponent } from './contiinex-website/new-contiinex-comnex-experience-page/new-contiinex-comnex-experience-page.component';
import { NewContiinexPrivacyPolicySectionPageComponent } from './contiinex-website/new-contiinex-privacy-policy-section-page/new-contiinex-privacy-policy-section-page.component';

import { NewContiinexFooterSectionPageComponent } from './contiinex-website/new-contiinex-footer-section-page/new-contiinex-footer-section-page.component';

import { ContiinexLoginNewuiComponent } from './contiinex-website/contiinex-login-newui/contiinex-login-newui.component';

import { ContiinexRegisterNewuiComponent } from './contiinex-website/contiinex-register-newui/contiinex-register-newui.component';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { ContiinexProfileSettingsComponent } from './comnex/contiinex-profile-settings/contiinex-profile-settings.component';
import {ContiinexProfilePreviewDialogComponent} from './comnex/contiinex-profile-preview-dialog/contiinex-profile-preview-dialog.component';
import {ContiinexSelectChannelDialogComponent } from './comnex/contiinex-select-channel-dialog/contiinex-select-channel-dialog.component';
import { ContiinexCampaignPageComponent } from './comnex/contiinex-campaign-page/contiinex-campaign-page.component';
import { ContiinexCreateCampaignPageComponent } from './comnex/contiinex-create-campaign-page/contiinex-create-campaign-page.component';

import { MatSelectFilterModule } from 'mat-select-filter';
import { MatTimepickerModule } from 'mat-timepicker';
import { ContiinexMessageTemplateComponent } from './comnex/contiinex-message-template/contiinex-message-template.component';
import { ContiinexMessageTemplateCreateComponent } from './comnex/contiinex-message-template-create/contiinex-message-template-create.component';
import { ContiinexMessagePreviewDialogComponent } from './comnex/contiinex-message-preview-dialog/contiinex-message-preview-dialog.component';
import { ContiinexSandboxUiComponent } from './comnex/contiinex-sandbox-ui/contiinex-sandbox-ui.component';
import { ContiinexReports1Component } from './comnex/contiinex-reports1/contiinex-reports1.component';
import { ViewDetailsDialog1Component } from './comnex/view-details-dialog1/view-details-dialog1.component';
import { ContiinexDashboardComponent } from './comnex/contiinex-dashboard/contiinex-dashboard.component';
import { ProjectOneComponent } from './comnex/project-one/project-one.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { WhatsappDashboard1Component } from './comnex/whatsapp-dashboard1/whatsapp-dashboard1.component';
import { ChannelsOutboundIvrComponent } from './comnex/channels-outbound-ivr/channels-outbound-ivr.component';
import { AllChannelsDashboardComponent } from './comnex/all-channels-dashboard/all-channels-dashboard.component';
import { ContiinexOutboundComponent } from './comnex/contiinex-outbound/contiinex-outbound.component';
import { ContiinexInboundComponent } from './comnex/contiinex-inbound/contiinex-inbound.component';
import { InboundIvrComponent } from './comnex/inbound-ivr/inbound-ivr.component';
import { ContiinexSmsDashboardComponent } from './comnex/contiinex-sms-dashboard/contiinex-sms-dashboard.component';
import { ContiinexShopifyDashboardComponent } from './comnex/contiinex-shopify-dashboard/contiinex-shopify-dashboard.component';
import { ContiinexShopifyTeamComponent } from './comnex/contiinex-shopify-team/contiinex-shopify-team.component';
import { ContiinexShopifyBillingComponent } from './comnex/contiinex-shopify-billing/contiinex-shopify-billing.component';
import { ContiinexShopifyTeamDeleteDialogComponent } from './comnex/contiinex-shopify-team-delete-dialog/contiinex-shopify-team-delete-dialog.component';
import { ContiinexAddTeamMemberDialogComponent } from './comnex/contiinex-add-team-member-dialog/contiinex-add-team-member-dialog.component';

import { ContiinexShopifySettingsComponent } from './comnex/contiinex-shopify-settings/contiinex-shopify-settings.component';
import { ContiinexSettingTemplateEditDialogComponent } from './comnex/contiinex-setting-template-edit-dialog/contiinex-setting-template-edit-dialog.component';
import { ContiinexProcessFlowBuilderComponent } from './comnex/contiinex-process-flow-builder/contiinex-process-flow-builder.component';
import { ContiinexProcessImportDialogComponent } from './comnex/contiinex-process-import-dialog/contiinex-process-import-dialog.component';
import { ContiinexProcessDeleteDialogComponent } from './comnex/contiinex-process-delete-dialog/contiinex-process-delete-dialog.component';
import { ContiinexPreviewDialogComponent } from './comnex/contiinex-preview-dialog/contiinex-preview-dialog.component';
import { ContiinexDetailsViewComponent } from './comnex/contiinex-details-view/contiinex-details-view.component';
import { CampaignFunnelDashboardComponent } from './comnex/campaign-funnel-dashboard/campaign-funnel-dashboard.component';
import { ContiinexCompanyPageComponent } from './contiinex-website/contiinex-company-page/contiinex-company-page.component';
import { DesktopContactListPageComponent } from './desktop-contact-list-page/desktop-contact-list-page.component';
import { DesktopDashboardComponent } from './desktop-calling/desktop-dashboard/desktop-dashboard.component';
import { DesktopMenuComponent } from './desktop-calling/desktop-menu/desktop-menu.component';
import { CallDialogComponent } from './desktop-calling/call-dialog/call-dialog.component';
import { EndSessionDialogComponent } from './desktop-calling/end-session-dialog/end-session-dialog.component';
import { AudioDialogComponent } from './desktop-calling/audio-dialog/audio-dialog.component';
import { AudioFollowupDialogComponent } from './desktop-calling/audio-followup-dialog/audio-followup-dialog.component';
import { ContactListDesktopCallingComponent } from './desktop-calling/contact-list-desktop-calling/contact-list-desktop-calling.component';

import { DesktopCallingNewPageComponent } from './desktop-calling-new-page/desktop-calling-new-page.component';
import { DesktopContactDetailsPageComponent } from './desktop-contact-details-page/desktop-contact-details-page.component';
import { ChatDesktopCallingComponent } from './desktop-calling/chat-desktop-calling/chat-desktop-calling.component';
import { SafePipe} from './safe.pipe';
import {ReplaceLineBreaks} from './desktop-calling/replacelinebreaks';
import { SpeedTestModule } from 'ng-speed-test';
import { ContactListCampaignDetailsPageComponent } from './desktop-calling/contact-list-campaign-details-page/contact-list-campaign-details-page.component';
import { ContactListCampaignContactDialogPageComponent } from './desktop-calling/contact-list-campaign-contact-dialog-page/contact-list-campaign-contact-dialog-page.component';
import { GenericOutboundNotificationDialogComponent } from './generic/generic-outbound-notification-dialog/generic-outbound-notification-dialog.component';
import { DownloadAttachmentComponent } from './campaign-v2/download-attachment/download-attachment.component';
import { CentralDashboardAgentsPageDialogComponent } from './central-dashboard-agents-page-dialog/central-dashboard-agents-page-dialog.component';
import { DesktopContactListWhatsappUiDialogComponent } from './desktop-calling/desktop-contact-list-whatsapp-ui-dialog/desktop-contact-list-whatsapp-ui-dialog.component';
import { LcCordinatorPageComponent } from './lc-cordinator-page/lc-cordinator-page.component';
import { DesktopCampaignListPageComponent } from './desktop-campaign-list-page/desktop-campaign-list-page.component';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { LcUploadDialogComponent } from './lc-upload-dialog/lc-upload-dialog.component';
import { GenericAgentsTreeViewComponent } from './generic-agents-tree-view/generic-agents-tree-view.component';
import { ConfirmationDialogComponent } from './desktop-calling/confirmation-dialog/confirmation-dialog.component';
import { UserLogoutComponent } from './user-logout/user-logout.component';
import { SwiftStatusComponent } from './desktop-calling/desktop-menu/swift-status/swift-status.component';


@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		SignupComponent,
		SigninComponent,
		DashboardComponent,
		RecruiterComponent,
		CampaignComponent,
		RecruiterTableComponent,
		CampaignTableComponent,
		CampaignDialogComponent,
		DashboardComponentV2,
		CampaignComponentV2,
		CampaignTableComponentV2,
		CampaignDialogComponentV2,
		AgencyComponent,
		CustomerComponent,
		StorySliderComponent,
		UearnSlideComponent,
		TechsupportComponent,
		IddailogComponent,
		OpsdashboardComponent,
		PmdashboardComponent,
		TrainingComponent,
		AgentsComponent,
		AgentsTableComponent,
		OnboardTechsupportComponent,
		DocUploadComponent,
		DocViewComponent,
		ClientStatusComponent,
		EditIDComponent,
		InboundComponent,
		DashboardTrainingComponent,
		AgentsListComponent,
		DashboardBatchonliveTableComponent,
		DashboardTraineravailibityTableComponent,
		AgentInfoDialogComponent,
		BatchListComponent,
		BatchCreateComponent,
		TrainerListComponent,
		TrainerCreateComponent,
		TrainerInfoDialogComponent,
		TrainersAvailibilityComponent,
		AssignBatchComponent,
		ReportComponent,
		OnJobTrainingComponent,
		OnJobTrainingAgentComponent,
		CertificationComponent,
		CreateOjtBatchComponent,
		SendMessageComponent,
		SendMessageAgentComponent,
		SendNotificationDialogComponent,
		BatchDaysTrainingComponent,
		CalandarComponent,
		OjtAgentInfoComponent,
		HiringComponent,
		OutboundComponent,
		DocStatusComponent,
		ManagementTeamComponent,
		PmdashComponent,
		ManagementTeamDialogComponent,
		PmdashboardDailogComponent,
		Hiring2Component,
		VoiceCallAuditComponent,
		EmailAuditComponent,
		ChatAuditComponent,
		AuditorManagementComponent,
		AuditorDashboardComponent,
		AuditorProjectsComponent,
		AuditorAuditorsComponent,
		AuditorReportsComponent,
		AuditorHelpComponent,
		AuditorProjectViewDialogComponent,
		AuditorAddClientDialogComponent,
		AuditorProjectEditDialogComponent,
		DevTestComponent,
		DashboardInboundComponent,
		CampaignAgentsComponent,
		FkEmpComponent,
		AttributeTableComponent,
		AttributeChartComponent,
		AuditorDataComponent,
		AuditorDataViewDialogComponent,
		EmployeeviewComponent,
		CampaignDetailsComponent,
		CampaignDetailsAgentDialogComponent,
		CallFlowFormComponent,
		CallFormCreateComponent,
		CallFreeTextComponent,
		CallNumberComponent,
		CallDateComponent,
		CallTimeComponent,
		CallRatingSeekbarComponent,
		CallDatetimeComponent,
		CallCheckboxComponent,
		CallRadiobuttonComponent,
		CallDropdownComponent,
		CallSearchableDropdownComponent,
		CallMultiselectionDropdownComponent,
		CallYesnoButtonComponent,
		CallFollowupComponent,
		CallAttachmentComponent,
		CallButtonComponent,
		CallMessageComponent,
		CallFormPreviewDialogComponent,
		CallDuringFormSummaryDialogComponent,
		CallNumberPickerComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		HomeHeaderComponent,
		JoinusBusinessComponent,
		JoinusComponent,
		FaqComponent,
		OrchestrationComponent,
		OrchestrationEmailComponent,
		OrchestrationEmailTemplateComponent,
		OrchestrationSmsTemplateComponent,
		OrchestrationWhatsappTemplateComponent,
		OrchestrationEmaildeliveredAgentTemplateComponent,
		OrchestrationAvailableAgentTemplateComponent,
		FeedDashComponent,
		TableFilterComponent,
		OrcheContentComponent,
		AudioPlayerComponent,
		CallrecordsdashComponent,
		AgentDashComponent,
		AgentFeedBackComponent,
		SharekhanComponent,
		TlTableCountsComponent,
		HomeAgentPluginComponent,
		WorkSlotAgentPluginComponent,
		CallHistoryAgentPluginComponent,
		EarningsAgentPluginComponent,
		StartDialDialogComponent,
		RegisterAgentComponent,
		LoginAgentComponent,
		PluginComponent,
		FaceRecognizeComponent,
		AssesmentComponent,
		ProfilePluginComponent,
		ProfileAttendanceDialogComponent,
		ProfileLeaveDialogComponent,
		ProfileResignationDialogComponent,
		AssessmentComponent,
		CreateDialogComponent,
		AssessmentTableComponent,
		ChatAgentComponent,
		ActivityComponent,
		AgentActivityComponent,
		VoiceCallUploadAudioComponent,
		OnboardingGetStartedComponent,
		OnboardingProcessPage1Component,
		OnboardingProcessPage2Component,
		OnboardingProcessPage3Component,
		OnboardingProcessPage4Component,
		OnboardingProcessPage5Component,
		OnboardingProcessPage6Component,
		OnboardingProcessPage7Component,
		DemoPortalComponent,
		DemoPortalDashboardComponent,
		DemoPortalCrcCallComponent,
		DemoPortalSettingComponent,
		VoiceBiometricComponent,
		AssessmentUploadComponent,
		GenericComponent,
		GenericOutboundComponent,
		GenericAgentactivityComponent,
		GenericFeedbackComponent,
		DemoPortalObdCallComponent,
		DemoPortalDobdCallComponent,
		DemoPortalSmsComponent,
		DemoPortalWhatsappComponent,
		DemoPortalSipCallComponent,
		DemoPortalEmailComponent,
		AuditFormsComponent,
		AuditFormDialogComponent,
		CertificationScoreDialogComponent,
		BatchCreateUploadDialogComponent,
		SendAppLinkDialogComponent,
		TimeslotSettingComponent,
		DashboardAddmoreDialogComponent,
		MoreSlotDialogComponent,
		FeedbackCondidatesDialogComponent,
		MultiselectSearchFixDirective,
		DashboardAddagentComponent,
		WhatsappDashboardComponent,
		PaymentDashboardComponent,
		PaymentDashboardConfigureComponent,
		PaymentDashboardHistoryComponent,
		PaymentDashboardApprovalComponent,
		PaymentDashboardDetailsviewDialogComponent,
		PaymentDashboardTableComponent,
		PaymentDashboardApprovalEditDialogComponent,
		ContiinexReportsComponent,
		ViewDetailsDialogComponent,
		PaymentDashboardApprovalChatDialogComponent,
		PaymentDashboardApprovalUploadfileDialogComponent,
		PaymentDashboardUploadDialogComponent,
		ContiinexWebApplicationComponent,
		ContiinexComnexPageComponent,
		ContiinexGignexPageComponent,
		ContiinexAinexPageComponent,
		ContiinexContactusPageComponent,
		ContiinexWebLoginComponent,
		RecruiterAgentDetailsDialogComponent,
		ContactCallPluginComponent,
		FaqNewComponent,
		PaymentDashboardEventsComponent,
		ContiinexWebForgotResetPasswordComponent,
		ContactRmUploadDailogComponent,
		QuestionnaireResponseDialogComponent,
		CurrencySymbolPipe,
		CampaignDetailsViewPageComponent,
		CampaignDetailsAddAgentDialogComponent,
		CampaignDetailsTransferAgentDataDialogComponent,
		CampaignCentralCreatePageComponent,
		BADashboardComponent,
		CentralcampaignComponent,
		CentralDashboardComponent,
		BadashboardDataPageComponent,
		BadashboardDialedPageComponent,
		BadashboardContactedPageComponent,
		BadashboardAgentsPageComponent,
		BadashboardActiveTimePageComponent,
		BadashboardQdePageComponent,
		CentralDashboardDataPageComponent,
		CentralDashboardDialedPageComponent,
		CentralDashboardContactedPageComponent,
		CentralDashboardAgentsPageComponent,
		CentralDashboardActiveTimePageComponent,
		CentralDashboardQdePageComponent,
		CentralSearchPipe,
		LocalStringPipe,
		HmsStringPipe,
		MaskPhoneNumber,
		CentralDasboardDdSectionPartComponent,
		NewContiinexHomePageComponent,
		NewContiinexMenuPageComponent,
		NewContiinexComnexEngagePageComponent,
		NeContiinexComnexEnablePageComponent,
		NewContiinexNewContactPageComponent,
		NewContiinexWhatsappApiPageComponent,
		NewContiinexComnexExperiencePageComponent,
		NewContiinexPrivacyPolicySectionPageComponent,
		NewContiinexFooterSectionPageComponent,
		ContiinexLoginNewuiComponent,
		ContiinexRegisterNewuiComponent,
		ContiinexProfileSettingsComponent,
		ContiinexProfilePreviewDialogComponent,
		ContiinexSelectChannelDialogComponent,
		ContiinexCampaignPageComponent,
		ContiinexCreateCampaignPageComponent,
		ContiinexMessageTemplateComponent,
		ContiinexMessageTemplateCreateComponent,
        ContiinexMessagePreviewDialogComponent,
		ContiinexSandboxUiComponent,
		ContiinexReports1Component,
		ViewDetailsDialog1Component,
		ContiinexDashboardComponent ,
		ProjectOneComponent,
		WhatsappDashboard1Component,
		ChannelsOutboundIvrComponent,
		AllChannelsDashboardComponent,
		ContiinexOutboundComponent,
		ContiinexInboundComponent,
		InboundIvrComponent,
		ContiinexSmsDashboardComponent,
		ContiinexShopifyDashboardComponent,
		ContiinexShopifyTeamComponent,
        ContiinexShopifyBillingComponent,
		ContiinexShopifyTeamDeleteDialogComponent,
		ContiinexAddTeamMemberDialogComponent,
		ContiinexSettingTemplateEditDialogComponent,
		ContiinexShopifySettingsComponent,
		ContiinexProcessFlowBuilderComponent,
		ContiinexProcessImportDialogComponent ,
		ContiinexProcessDeleteDialogComponent,
		ContiinexPreviewDialogComponent,
		ContiinexDetailsViewComponent,
		CampaignFunnelDashboardComponent,
		ContiinexCompanyPageComponent,
		DesktopContactListPageComponent,
		DesktopDashboardComponent,
		DesktopMenuComponent,
		CallDialogComponent,
		EndSessionDialogComponent,
		AudioDialogComponent,
		AudioFollowupDialogComponent,
		ContactListDesktopCallingComponent,
		DesktopCallingNewPageComponent,
		DesktopContactDetailsPageComponent,
		ChatDesktopCallingComponent,
		SafePipe,
		ReplaceLineBreaks,
		ContactListCampaignDetailsPageComponent,
		ContactListCampaignContactDialogPageComponent,
		GenericOutboundNotificationDialogComponent,
		DownloadAttachmentComponent,
		CentralDashboardAgentsPageDialogComponent,
		DesktopContactListWhatsappUiDialogComponent,
		LcCordinatorPageComponent,
		DesktopCampaignListPageComponent,
		LcUploadDialogComponent,
		GenericAgentsTreeViewComponent,
		ConfirmationDialogComponent,
		OutboundApiErrorDialog,
		UserLogoutComponent,
		SwiftStatusComponent
	],
	imports: [
		AngularMultiSelectModule,
		BrowserModule,
		MaterialModule,
		SharedModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		CdkStepperModule,
		CdkTableModule,
		CdkTreeModule,
		DragDropModule,
		PortalModule,
		ScrollingModule,
		NgbModule,
		ChartModule,
		NgCircleProgressModule.forRoot(),
		AngularFireDatabaseModule,
		AngularFireAuthModule,
		AngularFireMessagingModule,
		AngularFireModule.initializeApp(environment.firebase),
		SocialLoginModule,
		Ng2SearchPipeModule,
		NgxGoogleAnalyticsModule.forRoot('UA-48809880-2'),
		NgxGoogleAnalyticsRouterModule,
		MatSelectFilterModule,
		MatTimepickerModule,
		IvyCarouselModule,
		SpeedTestModule,
		MomentDateModule,
		// SettingsModule,
		//AmazingTimePickerModule
	],
	exports: [
		CdkStepperModule,
		CdkTableModule,
		CdkTreeModule,
		DragDropModule,
		PortalModule,
		ScrollingModule
	],
	entryComponents: [
		AgentInfoDialogComponent, 
		TrainerInfoDialogComponent, 
		AssignBatchComponent, 
		SendNotificationDialogComponent, 
		OjtAgentInfoComponent, 
		ManagementTeamDialogComponent, 
		AuditorProjectViewDialogComponent, 
		AuditorAddClientDialogComponent, 
		AuditorProjectEditDialogComponent, 
		AuditorDataViewDialogComponent, 
		CampaignDetailsAgentDialogComponent, 
		CallFormPreviewDialogComponent, 
		CallDuringFormSummaryDialogComponent, 
		AuditFormDialogComponent, 
		CertificationScoreDialogComponent, 
		BatchCreateUploadDialogComponent,
		// MatDialogModule,
		ConfirmationDialogComponent
	],
	providers: [
		ActivateGuard,
		MyserviceService,
		MessagingService,
		ServicesService,
		ApiService,
		ExcelService,
		SocketelsService,
		RtddataService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptorService,
			multi: true
		},
		Utils,
		AsyncPipe,
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(
							'320336915610-8lri21c2qfiddevcud44228nk0d6it1t.apps.googleusercontent.com'
						)
					},
					{
						id: FacebookLoginProvider.PROVIDER_ID,
						provider: new FacebookLoginProvider('153494306628104')
					}
				]
			} as SocialAuthServiceConfig,
		}
	],
	bootstrap: [AppComponent],
	schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {

}
