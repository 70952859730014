
<div   style="margin: 8% 8% 2% auto;">

    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-11 col-sm-12">
            <h2 class="b-title">Become a Customer Experience Expert</h2>
            <p *ngIf="showHideMessage" [ngStyle]="{'color':msgColor}">{{messageDisplay}}</p>
        </div>
        </div>
        <form [formGroup]="createdForm">
             
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-4 col-sm-12">
            <table class="wd-100">               
                <tr><td>
                    <div class="input-label-group">
                        <label>Full Name*</label>
                       <input matInput autocomplete="off" placeholder="Full Name*" (keypress)="omit_special_char($event)"  formControlName="fullName" required>
                    </div>
                </td></tr>
                <tr><td>
                    <div class="input-label-group">
                        <label>Mobile Number*</label>
                       <input matInput  autocomplete="off" maxlength="10" placeholder="Mobile Number*"   (keypress)="onlyNumberValidation($event)"  formControlName="mobileNo" required>
                    </div>
                  
                </td></tr>
                <tr><td>
                    <div class="input-label-group">
                        <label>Email*</label>
                       <input matInput  autocomplete="off"  placeholder="Email*"  formControlName="email" required>
                    </div>
                </td></tr>
                <tr><td>
                    <div class="input-label-group">
                        <label>Password*</label>
                       <input matInput  autocomplete="off"   type="password" placeholder="Password*"  formControlName="password" required>
                    </div>
                   
                </td></tr>
                 <tr><td>
                     <mat-label style="color: gray;font-size: 12px;">Gender</mat-label><br>
                    <mat-radio-group aria-label="Gender" style="font-size:12px;"  formControlName="gender">
                        <mat-radio-button value="M" [checked]="true">Male &nbsp;&nbsp;</mat-radio-button>
                        <mat-radio-button value="F">Female&nbsp;&nbsp;</mat-radio-button>
                        <mat-radio-button value="0">Prefer not to disclose</mat-radio-button>
                      </mat-radio-group>
                </td></tr>
                <tr><td>
                    <div class="input-label-group" style="border: 1px solid #ccc;border-radius: 10px;">
                        <label>Language you know*</label>
                        <mat-form-field class="wd-100 no-line">
                            <mat-select multiple  formControlName="language" required>
                             <mat-option value="English">English</mat-option>
                             <mat-option value="Kanada">Kanada</mat-option>                         
                             <mat-option value="Telugu">Telugu</mat-option>
                             <mat-option value="Tamil">Tamil</mat-option>
                             <mat-option value="Hindi">Hindi</mat-option>
                           </mat-select>
                       </mat-form-field>
                    </div>
                   
                </td></tr>
              
            </table>
            </div>
            <div class="col-md-4 col-sm-12">
                <table class="wd-100">
                    <tr>
                        <td>
                            <div class="input-label-group">
                                <label>Educational Qualification*</label>
                                    <select placeholder="Educational Qualification*"  formControlName="educatioanl" required>
                                     <option value="Graduate">Graduate</option>
                                     <option value="PostGraduate">Post Graduate</option>                         
                                     <option value="PUC">PUC</option>
                                     <option value="SSLC">SSLC</option>
                                   </select>
                            </div>
                          
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="input-label-group">
                                <label>Work Experience*</label>
                                    <select placeholder="Work Experience*"  formControlName="workExperience" required>
                                     <option value="0">0 Years</option>
                                     <option value="1">1 Years</option>                         
                                     <option value="2">2 Years</option>
                                     <option value="3">3 Years</option>
                                     <option value="4">4 Years</option>
                                     <option value="5">5 Years</option>                         
                                     <option value="6">6 Years</option>
                                     <option value="7">7 Years</option>
                                     <option value="8">8 Years</option>
                                     <option value="9">9 Years</option>                         
                                     <option value="10">10 Years</option>
                                     <option value="10+">10+ Years</option>
                                   </select>
                            </div>
                           
                        </td>
                    </tr>
                    <tr><td>
                        <mat-label style="color: gray;font-size: 12px;">Experience in customer support *</mat-label><br>
                       <mat-radio-group  style="font-size:12px;"  formControlName="experienceCustomerSupport">
                           <mat-radio-button value="Yes">Yes &nbsp;&nbsp;</mat-radio-button>
                           <mat-radio-button value="No"  [checked]="true">No</mat-radio-button>
                         </mat-radio-group>
                   </td></tr>
                   <tr><td>
                    <mat-label style="color: gray;font-size: 12px;">Business Process *</mat-label><br>
                   <mat-radio-group  style="font-size:12px;"  formControlName="businessProcess">
                       <mat-radio-button value="Voice" [checked]="true">Voice &nbsp;&nbsp;</mat-radio-button>
                       <mat-radio-button value="Non Voice" >Non Voice</mat-radio-button>
                     </mat-radio-group>
               </td></tr>
               <tr>
                <td>
                    <div class="input-label-group">
                        <label>Experience in voice support *</label>
                      
                            <select placeholder="Experience in voice support *"  formControlName="expVoiceSupport" required>                      
                                <option value="0">0 Years</option>
                                <option value="1">1 Years</option>                         
                                <option value="2">2 Years</option>
                                <option value="3">3 Years</option>
                                <option value="4">4 Years</option>
                                <option value="5">5 Years</option>                         
                                <option value="6">6 Years</option>
                                <option value="7">7 Years</option>
                                <option value="8">8 Years</option>
                                <option value="9">9 Years</option>                         
                                <option value="10">10 Years</option>
                                <option value="10+">10+ Years</option>
                              </select>
                    </div>
                   
                </td>
            </tr>
            <tr>
                <td>
                    <div class="input-label-group" style="border: 1px solid #ccc;border-radius: 10px;">
                        <label>Choose your set up *</label>
                        
                    <mat-form-field class="wd-100 no-line">
                        <mat-select multiple  formControlName="setup" required>
                         <mat-option value="Broadband Internet"> Broadband Internet</mat-option>
                         <mat-option value="Wireless Internet (2G, 3G, 4G)">Wireless Internet (2G, 3G, 4G)</mat-option>                         
                         <mat-option value="Android Phone"> Android Phone</mat-option>
                         <mat-option value="Desktop/Laptop">Desktop/Laptop</mat-option>                         
                         <mat-option value="Power Backup">Power Backup</mat-option>
                       </mat-select>
                   </mat-form-field>
                   </div>
                </td>
            </tr>
                </table>
            </div>
            </div>
        </form>
            <div class="row">
                <div class="col-md-1"></div>
<div class="col-md-3 col-sm-12">
        <button class="JoinSubmit" (click)="joinusBtn(createdForm)">Join Us</button>
        <!-- [disabled]="!createdForm.valid" -->
</div>
            </div>
</div>