<div class="row">
	<div class="col-md-12">
		<mat-card style="margin: 0 10px;box-shadow: 0px 0px 4px 0px #dfdfdf;">
			<div class="row">
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon8.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1">{{getAllTotalAgents()}}</span>
								<span class="nameLabel1">Agents</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon9.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1" style="color:#50bafd;">{{getAllTotalLoggedIn()}}</span>
								<span class="nameLabel1">Logged In</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="col-md-3">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon10.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1" style="color:#f3385d;">{{getAllTotalLive()}}</span>
								<span class="nameLabel1">Live</span>
							</td>
						</tr>
					</table>
				</div>
			</div>

		</mat-card>
	</div>
</div>
<div class="row ">
	<div class="col-md-12">
		<mat-card style="margin: 0 12px;" class="mt-3">
			<mat-button-toggle-group class="matBtnTogBA" #group="matButtonToggleGroup" [value]="selecteBtnSM"
				(change)="smChangeToggle($event.value)">
				<mat-button-toggle value="ZSM">ZSM</mat-button-toggle>
				<mat-button-toggle value="RSM">RSM</mat-button-toggle>
				<mat-button-toggle value="ASM">ASM</mat-button-toggle>
			</mat-button-toggle-group>
			<div class="row mt-4 mb-3">
				<div class="col-md-3">
					<mat-form-field style="width:100%">
						<mat-select [(value)]="selectedDate" placeholder="Choose Date"
							(selectionChange)="dateChangeDDL($event.value)">
              <mat-option value="Today">Today</mat-option>
							<mat-option value="Yesterday">Yesterday</mat-option>
							<mat-option value="customDate">Custom Date</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-3" *ngIf="showHideDateRangeDiv">
					<mat-form-field style="width:100%">
						<mat-label>Select Date Range</mat-label>
						<mat-date-range-input [formGroup]="rangeDate" [rangePicker]="rangeCustomDatePicker"
							[max]="maxDate">
							<input matStartDate formControlName="startDate" placeholder="Start date" readonly
								(click)="rangeCustomDatePicker.open()" #dateRangeStart>
							<input matEndDate formControlName="endDate" placeholder="End date" readonly
								(click)="rangeCustomDatePicker.open()" #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="rangeCustomDatePicker"></mat-datepicker-toggle>
						<mat-date-range-picker #rangeCustomDatePicker></mat-date-range-picker>
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<app-central-dasboard-dd-section-part></app-central-dasboard-dd-section-part>


				</div>
				<div class="col-md-3">
					<mat-form-field style="width:100%">
						<input matInput #searchInput [(ngModel)]="nameSearch" (keyup)="applyFilter($event.target.value)"
							autocomplete="off" placeholder="Search">
						<i matSuffix class="fa fa-search searchicon" style="color: #d5d5d5;" aria-hidden="true"></i>
					</mat-form-field>
				</div>
				<div class="col-md-3 mt-2">
					<button mat-button class="downloadBtn"> Download <mat-icon class="ml-2">file_download</mat-icon>
					</button>
				</div>
			</div>
			<table matSort (matSortChange)="sortData($event)" class="vTableV">
				<tr>
					<th mat-sort-header="name">NAME</th>
					<th mat-sort-header="totalAgents"> TOTAL AGENTS</th>
					<th mat-sort-header="loggedIn">LOGGED IN %</th>
					<th mat-sort-header="totalLoggedIn">TOTAL LOGGED IN</th>
					<th mat-sort-header="liveIn">LIVE IN %</th>
					<th mat-sort-header="totalLive">TOTAL LIVE</th>
					<th mat-sort-header="totalLive">DIAL PER AGENT</th>
				</tr>
				<tbody>
					<ng-container *ngFor="let v of sortedData | async | customerSearchFilter: nameSearch;let j = index;let first=first;">
						<tr *ngIf="v.visible">
							<td>
								<a><span class="v-actionViewMore" [ngStyle]="{'text-transform':'Capitalize','color':getcolorStatus(isASM_selected,stage_setting_access)}" (click)="actionViewMore(v)">
									<!--[ngStyle]="{'background-color':isASM_selected && stage_setting_access? 'blue'}"-->
									{{v.name}}
								</span>
								</a>
							</td>
							<td>{{v.totalAgents | localstring}}</td>
							<td>{{v.loggedIn | localstring}}</td>
							<td>{{v.totalLoggedIn | localstring}}</td>
							<td>{{v.liveIn | localstring}}</td>
							<td>{{v.totalLive | localstring}}</td>
							<td>{{v.dialperagent | number : '1.0-0'}}</td>
						</tr>
					</ng-container>
				</tbody>
				<tfoot>
					<tr style="background: #f3f3f3;">
						<td><b>All Total</b></td>
						<td>{{getTotalValue('totalAgents') | localstring}}</td>
						<td>{{getTotalValueloggedIn() | localstring}}</td>
						<td>{{getTotalValue('totalLoggedIn') | localstring}}</td>
						<td>{{getTotalValueliveIn() | localstring}}</td>
						<td>{{getTotalValue('dialperagent') | localstring}}</td>
					</tr>
				</tfoot>
			</table>
		</mat-card>
	</div>
</div>
