<app-menu [navigateLink]="currentTitle"></app-menu>
  <div>
    <div>
        <mat-card style="height:75px; display: none;">
         <div class="row pico">
           <div class="col-md-2 col-sm-12 col-xs-12">
            <img src="../assets/uearnIcon.svg" class="logoTag">
             </div>
             <div class="col-md-8 col-sm-12 col-xs-12">
              &nbsp;
            
                  </div>
             <div class="col-md-2 col-sm-12 col-xs-12" style="padding-top:7px;">
                <table class="wd-100 tbl">
                    <tr><td> <img  src="../assets/LineDot.svg"/></td>
                      <td class="text-center">
                        <img  src="../assets/orchSearchIcon.svg"/>
                      </td>
                      <td> <img  src="../assets/LineDot.svg"/></td>
                      <td class="text-center">  <img  src="../assets/orchbellIcon.svg" />
                    </td> 
                    <td> <img  src="../assets/LineDot.svg"/></td>                
                      <td class="text-center">  <img  src="../assets/orchPersonIcon.svg"/>
                    </tr>
                  </table>
         
               </div>
          
             </div>
        </mat-card>
       </div>
      <div style="margin-top:45px;">
  <mat-sidenav-container autosize style="min-height: calc(100vh);background: #F0F0F6;">
    <mat-sidenav [ngClass]="'orchoSideNav'" #sidenav class="TR-sidenav" mode="side" opened="true" style="width: 110px !important;background: #6f2fa3 !important;">
      <div>
      <mat-nav-list style="padding: 0px !important;">  
        <mat-list-item style="height: 80px;"  [routerLink]="['./email']" [routerLinkActive]="['active']">       
           <p matLine>  <img  src="../assets/orchDashboardIcon.svg" class="TR-imag"/></p>
        </mat-list-item>
        <mat-list-item style="height: 80px;"  [routerLink]="['./orche']" [routerLinkActive]="['active']">       
          <p matLine> 
             <img  src="../assets/PlusImage.svg" class="TR-imag"/>
            </p>
       </mat-list-item>
        <mat-list-item style="height: 80px;"  [routerLink]="['./bb']" [routerLinkActive]="['active']">
            <p matLine> <img  src="../assets/orchTimeIcon.svg" class="TR-imag"/></p>
         </mat-list-item>
         <mat-list-item style="height: 80px;"  [routerLink]="['./cc']" [routerLinkActive]="['active']">
            <p matLine> <img  src="../assets/orchhelpIcon.svg" class="TR-imag"/></p>
         </mat-list-item>
 

  
      </mat-nav-list>
    </div>
    </mat-sidenav>
    <div class="TR-sidenav-content">
  
    <router-outlet></router-outlet>
  
    </div>
  
  </mat-sidenav-container>
</div>
  </div>