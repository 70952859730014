import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-onboarding-process-page6',
  templateUrl: './onboarding-process-page6.component.html',
  styleUrls: ['./onboarding-process-page6.component.scss']
})
export class OnboardingProcessPage6Component implements OnInit {

  constructor(private router:Router) { 
    window.scroll(0,0);
  }

  ngOnInit(): void {
  }
  onNextBtn(){
    this.router.navigateByUrl('/onboarding_process_page7')
      }
}
