<!-- Campaign Dashboard starts -->
<table class="maintable">
	<tr>
		<td>
			<div class="containecs">
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Total Campaigns</div>
						<div class="card-body">
							<table class="tablecs">
								<!-- <div class="tile-row">
									<span class="pr-2 flex-fill"> <span class="tile-title">Application</span><hr class="level1" [style.width.%]="campaignSummary.summary.total_perc"/></span>
									<span class="tile-text"><span class="tile-percent">{{campaignSummary.summary.total_perc}}%</span>({{ campaignSummary.summary.total }})</span>
								</div> -->
								<tr><td>Total Campaigns</td></tr>
								<tr><td>Total Data</td><td>{{ campaignSummary.summary.total }}</td></tr>
								<tr><td>Data To Call</td><td>{{ campaignSummary.summary.data_tocall }}</td></tr>
								<tr><td>Data Called</td><td>{{ campaignSummary.summary.data_called }}</td></tr>
							</table>               						
						</div> 					   
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Total Active Campaigns</div>
						<div class="card-body">
							<table class="tablecs">
								<!-- <div class="tile-row">
									<span class="pr-2 flex-fill"> <span class="tile-title">Application</span><hr class="level1" [style.width.%]="campaignSummary.summary.total_perc"/></span>
									<span class="tile-text"><span class="tile-percent">{{campaignSummary.summary.total_perc}}%</span>({{ campaignSummary.summary.total }})</span>
								</div> -->
								<tr><td>Total Campaigns</td></tr>
								<tr><td>Total Data</td><td>{{ campaignSummary.summary.total }}</td></tr>
								<tr><td>Data To Call</td><td>{{ campaignSummary.summary.data_tocall }}</td></tr>
								<tr><td>Data Called</td><td>{{ campaignSummary.summary.data_called }}</td></tr>
							</table>               						
						</div> 					   
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Total InActive Campaigns</div>
						<div class="card-body">
							<table class="tablecs">
								<!-- <div class="tile-row">
									<span class="pr-2 flex-fill"> <span class="tile-title">Application</span><hr class="level1" [style.width.%]="campaignSummary.summary.total_perc"/></span>
									<span class="tile-text"><span class="tile-percent">{{campaignSummary.summary.total_perc}}%</span>({{ campaignSummary.summary.total }})</span>
								</div> -->
								<tr><td>Total Campaigns</td></tr>
								<tr><td>Total Data</td><td>{{ campaignSummary.summary.total }}</td></tr>
								<tr><td>Data To Call</td><td>{{ campaignSummary.summary.data_tocall }}</td></tr>
								<tr><td>Data Called</td><td>{{ campaignSummary.summary.data_called }}</td></tr>
							</table>               						
						</div> 					   
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Data Sources</div>
						<div class="card-body scroll">
							<table class="table table-sm">
								<thead>
								  <tr>
									<th scope="col">Source</th>
									<th scope="col">Campaign</th>
									<th scope="col">Application</th>
								  </tr>
								</thead>
								<tbody>
								  <tr>
									<td>Mark</td>
									<td>Otto</td>
									<td>@mdo</td>
								  </tr>
								  <tr>
									<td>Jacob</td>
									<td>Thornton</td>
									<td>@fat</td>
								  </tr>
								  <tr>
									<td>Larry the Bird</td>
									<td>@twitter</td>
									<td>34</td>
								  </tr>
								</tbody>
							  </table>             						
						</div> 					   
					</div>
				</div>
				<!-- <div class="colcomn">
					<div class="card">
						<div class="card-header">Campaigns</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Total</td><td>{{ campaignSummary.campaigns.total }}</td></tr>
								<tr><td>Active</td><td>{{ campaignSummary.campaigns.active }}</td></tr>
								<tr><td>InActive</td><td>{{ campaignSummary.campaigns.inactive }}</td></tr>
							</table>
						</div>					   
					</div>
				</div> -->
				<!-- <div class="colcomn">
					<div class="card">
						<div class="card-header">Today Campaigns</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Total</td><td>{{ campaignSummary.today_campaigns.total }}</td></tr>
								<tr><td>Active</td><td>{{ campaignSummary.today_campaigns.active }}</td></tr>							   
								<tr><td>InActive</td><td>{{ campaignSummary.today_campaigns.inactive }}</td></tr>							   
							</table>
						</div>					   
					</div>
				</div> -->
				<!-- <div class="colcomn">
					<div class="card">
						<div class="card-header">Agency - Type</div>
						<div class="card-body">
							<table  class="tablecs">
								<tr><td>UEarn Organic</td><td>{{ campaignSummary.agencies.organic }}</td></tr>
								<tr><td>Online</td><td>{{ campaignSummary.agencies.online }}</td></tr>							   
								<tr><td>Offline</td><td>{{ campaignSummary.agencies.offline }}</td></tr>
							</table>
						</div>					   
					</div>
				</div> -->
				<!-- <div class="colcomn">
					<div class="card">
						<div class="card-header">Agencies</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Vahan</td><td>{{ campaignSummary.agency_name.Vahan }}</td></tr>
								<tr><td>Workex</td><td>{{ campaignSummary.agency_name.Workex }}</td></tr>							   
								<tr><td>Capricco</td><td>{{ campaignSummary.agency_name.Capricco }}</td></tr>							   
							</table>
						</div>					   
					</div>
				</div> -->
			</div>
		</td>
	</tr>
</table>