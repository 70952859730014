import { Component, OnInit,ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import {Router} from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { MyserviceService } from 'src/app/myservice.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-call-history-agent-plugin',
  templateUrl: './call-history-agent-plugin.component.html',
  styleUrls: ['./call-history-agent-plugin.component.scss']
})
export class CallHistoryAgentPluginComponent implements OnInit {
   displayedColumns = ['name', 'number', 'start_time', 'time','iconDialed'];
  dataSource:any = new MatTableDataSource();  
  dataSourceContacted:any = new MatTableDataSource(); 
  @ViewChild(MatPaginator) pag1: MatPaginator;
  @ViewChild(MatPaginator) pag2: MatPaginator;
  toggleValue:any="totalDialed";
  showHideForTotalDialed:boolean=true;  
  showHideForContacted:boolean=false;
  _caller_id: string = "";
	c2cEndPoint: string = "";
	subscriptionDial: Subscription;
	subscription: Subscription;
	callStatus: any;
	api_end_point:any
  constructor(private dialog: MatDialog,public router:Router, private API: ApiService, private myservice: MyserviceService
    ) { }
    user: any = this.myservice.get_loggedin_user();
  
  ngOnInit() {   
	this.api_end_point = this.myservice.get_API_End_Point();
	  this.callStatus = this.myservice.getLastCallStatus();
    if(this.toggleValue=="totalDialed"){
      this.getContacted();
    }
	  let stateSettings: any = this.myservice.get_stage_settings();

	  let _inNumberOneDefinition = stateSettings.cloudtelephonyout.filter(v => { return v.name == "innumber1" });
	  this._caller_id = _inNumberOneDefinition[0].definition;
	  console.log(this._caller_id);

	  if (stateSettings && stateSettings.extraoptions) {
		  for (let opt of stateSettings.extraoptions) {
			  if (opt.name == "c2cendpoint") {
				  console.log(opt);
				  this.c2cEndPoint = opt.definition;
			  }
		  }
	  }
	  this.subscriptionDial = this.myservice.getCallStatus().subscribe(obj => {
		  console.log("home-call-history", obj);
		  this.callStatus = obj;
		  
	  })
   }
 
  togleChange(value:any){
this.toggleValue=value;
if(value=="totalDialed"){
this.showHideForTotalDialed=true;
this.showHideForContacted=false;
this.getContacted();
}
else if(value=="contacted"){
  this.showHideForTotalDialed=false;
  this.showHideForContacted=true;
  this.getContacted();
  }
else{
  this.showHideForTotalDialed=false;
  this.showHideForContacted=false;
}
  }
  getContacted(){
    let _today: any = new Date();
    let _startDate =new Date(_today.setDate(_today.getDate() - 30)).toISOString();
    let _endDate = new Date(_today.setDate(_today.getDate() + 30)).toISOString() ;
    let userid = this.user.id;
    let obj = {
      "userid": userid,
      "start_time": _startDate,
      "end_time": _endDate
    }
    this.API.agentPluginContacted(obj,this.api_end_point).subscribe((data: any) => {
      let _response = data.success;
      if (_response) {
       // debugger;
        let ELEMENT_DATA=_response;
        let _contactedData=ELEMENT_DATA.filter(v=>{return v.event_type== "call"})
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.dataSource.paginator = this.pag1;
        this.dataSourceContacted= new MatTableDataSource(_contactedData);
        this.dataSourceContacted.paginator = this.pag2;

      }
    });

  }
  callCustomer(obj){
    console.log(obj);
	//   this.callStatus = obj;
//     var data = {
//       "telco_partner": "TWILIO",
//       "agent_number": this.user.phone,
//       "customer_number": obj.number,
//       "cli": "+15512617144"
//   }
//     this.API.gsmtogsmcall(data, this.user.server_url).subscribe(
//       (res: any) => {
//         console.log(res)
//         if(res.status== "success"){
//           alert("Dialing...")
//         }
        
//       }, (err) => {
//         console.log(err)
//       }
//     )

	//   var data: any = {
	// 	  "customer_number": obj.number,
	// 	  "agent_number": this.user.phone,
	// 	  "user_id": this.user.id,
	// 	  "caller_id": this._caller_id,
	// 	  "networktype": "WIFI",
	// 	  "pingdelay": "45"
	//   }
	//   if (this.user.sip_id) {
	// 	  data.sip_id = this.user.sip_id
	//   }



	//   this.API.sipOutboundCall(data, this.user.server_url, this.c2cEndPoint).subscribe((res: any) => {
	// 	  debugger;
	// 	  console.log(res)

	//   }, (err) => {
	// 	  console.log(err)
	//   }
	//   )

	  var updateObj = this.callStatus;
	  if (updateObj.status == undefined){
		  updateObj.dialNum = obj.number;;
		  updateObj.dialing = 1;
		  this.myservice.updateCallStatus(updateObj);
		  updateObj = this.callStatus;
		  updateObj.dialing = 2;
		  this.myservice.updateCallStatus(updateObj);
	  }
	  
	//   if (this.callStatus && this.callStatus.dialing && this.callStatus.dialing == 1) {
	// 	  updateObj.dialing = 2;
	// 	  this.myservice.updateCallStatus(updateObj);
	//   }
	//   if (this.callStatus && !this.callStatus.dialing) {
	// 	  updateObj.dialing = 1;
	// 	  this.myservice.updateCallStatus(updateObj);
	//   }

  }
  maskNumber(no){
    return no.replace(no.substring(0,8), "XXXXX");
  }
}

