
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, ObjectUnsubscribedError, Subscription } from 'rxjs';
import * as _ from 'underscore';
import { MatDialog } from '@angular/material/dialog';
import { RtddataService } from '../rtdData.service';
import { MyserviceService } from '../myservice.service';
import { Utils } from '../utils';
import {CentralDashboardAgentsPageDialogComponent} from '../central-dashboard-agents-page-dialog/central-dashboard-agents-page-dialog.component'
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import { ApiService } from '../api.service';


@Component({
	selector: 'app-central-dashboard-agents-page',
	templateUrl: './central-dashboard-agents-page.component.html',
	styleUrls: ['./central-dashboard-agents-page.component.scss']
})
export class CentralDashboardAgentsPageComponent implements OnInit {
	private subscription: Subscription = new Subscription();
	_fetchData: any = [];
	sortedData: BehaviorSubject<[]> = new BehaviorSubject([]);
	private utils = new Utils();
	zsmData: any = [];
	rsmData: any = [];
	asmData: any = [];
	AllData: any = [];
	selecteBtnSM: any;
	selectedDate: any = "Today";
	showHideDateRangeDiv: boolean = false;
	showRolesTabs$ = this.service.CENTRAL_BI_ROLES.asObservable();

	nameSearch: any = "";
	rangeDate = new FormGroup({
		startDate: new FormControl(),
		endDate: new FormControl(),
	});
	ddList: BehaviorSubject<any> = new BehaviorSubject([]);
	ddListSelected = [];

	@ViewChild('selectZSM') selectZSM: MatSelect;
	allSelectedZsm = false;
	@ViewChild('selectRSM') selectRSM: MatSelect;
	allSelectedRsm = false;
	@ViewChild('selectASM') selectASM: MatSelect;
	allSelectedAsm = false;
	@ViewChild('selectUser') selectUser: MatSelect;
	toggleMasterSelection: boolean = true;

	maxDate = new Date();
	util = new Utils();
	api_end_point:any;
	userid:any;
	isASM_selected:boolean = false;
	stage_setting_access:boolean=false;

  myUser: any;
  _data: any;
  start_date:any;
  end_date:any;

	constructor(
		private service: MyserviceService,
		private rtd: RtddataService,
		private API: ApiService,
		private dialog: MatDialog) {
	}

	ngOnInit() {
		var Stagesettings_details;
		Stagesettings_details = this.service.getSettingsWithKeyValue('web','name','rtd_asm_dashboard');
		this.api_end_point = this.service.get_API_End_Point();
		this.userid = this.service.get_loggedin_user();
    this.myUser = this.service.get_loggedin_user();
		let obj1 ={
			groupid:this.userid.groupid
		}
		//console.log(Stagesettings_details,"\n",this.userid.groupid);
		if (Stagesettings_details == null) {
			this.API.get_groupid_settingsAPI(obj1,this.api_end_point).subscribe(
				(res: any) => {
					// debugger;
					if (res.error) {
						console.log(res.error);
					}
					else {
						console.log(res.success);
						console.log(res.success.web[0].name)
					}
				})
		}
		else {
			if (Stagesettings_details.definition == "on") {
				this.stage_setting_access = true;
			}
		}
		this.subscription.add(
			this.showRolesTabs$.subscribe(roles => {
				this.selecteBtnSM = roles[0];
				this.changeddlList();
			})
		)

		this.subscription.add(
			this.rtd.zsmData.subscribe(zsmData => {
				// console.log('zsm data', zsmData);
				this.zsmData = zsmData;
				this.AllData.push(zsmData);
				this.updateFetchData(this.selecteBtnSM);
			})
		)
		this.subscription.add(
			this.rtd.rsmData.subscribe(rsmData => {
				// console.log('rsm data', rsmData);
				this.rsmData = rsmData;
				this.AllData.push(rsmData);
				this.updateFetchData(this.selecteBtnSM);
			})
		)
		this.subscription.add(
			this.rtd.asmData.subscribe(asmData => {
				//console.log('asm data', asmData);
				this.asmData = asmData;
				this.AllData.push(asmData);
				this.updateFetchData(this.selecteBtnSM);
			})
		)

    this._data = {};
		let centraldata;
		this._data.ParentChildchecklist = [];
		//console.log("----"+JSON.stringify(this.myUser));
		var obj = {
			role: this.myUser.role,
			role_id: this.myUser.groupid
		}
		//console.log("--2-" + JSON.stringify(obj))
		this.API.getcenteralgroupid(obj,this.api_end_point).subscribe((res: any) => {
			if (res.errors) {
				//console.log(res.errors);
			} else {
				//console.log("--3-" + JSON.stringify(res.success))
				let centraldata = res.success;
        for (var c in centraldata) {
					//console.log(centraldata[c].center_name);
					var obj;

						obj = {
							id: centraldata[c].id, groupid: centraldata[c].groupid, center_name: centraldata[c].center_name, ASM:centraldata[c].asm_name, RSM: centraldata[c].rsm_name,  ZSM:centraldata[c].zsm_name,TotalAgents: centraldata[c].user_count	}
						this._data.ParentChildchecklist.push(obj);
				}
        console.log(JSON.stringify(this._data.ParentChildchecklist));
      }
    })

	}

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    // debugger;
      console.log(dateRangeStart.value);
      console.log(dateRangeEnd.value);


      if(dateRangeStart.value && dateRangeEnd.value)
      {
        let start_time = '18:29';

      let StartdateTime = moment(dateRangeStart.value + 'T' + start_time, 'MM/DD/YYYYTHH:mm:ss');
      console.log(StartdateTime.format('YYYY-MM-DDTHH:mm:ss'))
      let enddateTime = moment(dateRangeEnd.value + 'T' + start_time, 'MM/DD/YYYYTHH:mm:ss');
      console.log(enddateTime.format('YYYY-MM-DDTHH:mm:ss'))

      let obj ={
        "start_date":StartdateTime.format('YYYY-MM-DDTHH:mm:ss'),
        "end_date":enddateTime.format('YYYY-MM-DDTHH:mm:ss')
      }
      function openInNewTab(url) {
        window.open(url, '_blank').focus();
      }
      let url_download = 'https://dev.smarter-biz.com/api/v1/bi_dashboard_report?start_date='
      let final_url = url_download.concat(obj.start_date,'&','end_date=',obj.end_date,'&access_token=',this.myUser.access_token)
      console.log("--final-",final_url);
      openInNewTab(final_url);
      }
    }


	changeddlList() {
		this.subscription.add(
			this.service.ddlSelection.subscribe(ddlData => {
				// console.log('printing ddl Data', ddlData);
				this.ddList.next(ddlData);
				this.updateFetchData(this.selecteBtnSM);
				// console.log('ddl List', this.ddList.getValue());
			})
		)
	}

	sortData(sort: Sort) {
		const data = this.sortedData.getValue();
		if (!sort.active || sort.direction === '') {
			return;
		}

		const arr = data.sort((a: any, b: any) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'ZSM':
					return this.compare(a.name, b.name, isAsc);
				case 'totalAgents':
					return this.compare(a.totalAgents, b.totalAgents, isAsc);
				case 'loggedIn':
					return this.compare(a.loggedIn, b.loggedIn, isAsc);
				case 'totalLoggedIn':
					return this.compare(a.totalLoggedIn, b.totalLoggedIn, isAsc);
				case 'liveIn':
					return this.compare(a.liveIn, b.liveIn, isAsc);
				case 'totalLive':
					return this.compare(a.totalLive, b.totalLive, isAsc);
				case 'agentsalive_count':
					return this.compare(a.agentsalive_count, b.agentsalive_count, isAsc);
				case 'dialperagent':
						return this.compare(a.dialperagent, b.dialperagent, isAsc);
				default:
					return 0;
			}
		});
		this.sortedData.next(arr);
	}

	smChangeToggle(val: any) {
    this.selectedDate ="Today";
		// console.log('value on click', val);
		this.selecteBtnSM = val;
		this.service.SELECTED_BI_ROLE.next(val);
		this.selectedDate = "";
		this.nameSearch = "";
		this.changeddlList();

		this.rangeDate.reset();
		this.showHideDateRangeDiv = false;

		this.updateFetchData(val)
	}


	async updateFetchData(val){
		if (val == "ZSM") {
			this.isASM_selected=false;
      this.selectedDate ="Today";
			this._fetchData = await this.modifiedValues(this.zsmData, 'zsm')
		}
		else if (val == "RSM") {
			this.isASM_selected=false;
      this.selectedDate ="Today";
			this._fetchData = await this.modifiedValues(this.rsmData, 'rsm')

		}
		else if (val == "ASM") {
			this.isASM_selected=true;
      this.selectedDate ="Today";
			this._fetchData = await this.modifiedValues(this.asmData, 'asm');
		}
		this.sortedData.next(this._fetchData);
	}
	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	getTotalValue(variable: string) {
		let total = 0;
		const values = this.sortedData.getValue();
		if (values && values.length) {
			for (let j = 0; j < values.length; j++) {
				if (values[j]['visible']) {
					if(values[j][variable] !=null || values[j][variable] !=undefined)
					{
					total += Number(values[j][variable]);
					}
				}
			}
			return total;
		}
	}
	modifiedValues(data, role){
		// console.log('modified values data', data);
		let arr = [];
		const ddlList = this.ddList.getValue()[role];
		// console.log('printing ddList', ddlList);
		for(var i in data){
			let obj:any = {};
			if(ddlList && ddlList.hasOwnProperty(i)){
				obj.name = ddlList[i].name;
				obj.visible = ddlList[i].visible;
				obj.id = ddlList[i].id;
				obj.totalAgents = data[i]['usercount'] || 0;
				obj.loggedIn = this.utils.getPercentage(data[i]['rtd_signedin_count'], data[i]['usercount'], 1)
				obj.agentsalive_count = data[i]['rtd_agentsalive_count']
				obj.totalLoggedIn = data[i]['rtd_signedin_count'] || 0
				obj.liveIn = this.utils.getPercentage(data[i]['rtd_agentsalive_count'], data[i]['rtd_signedin_count'], 1)
				obj.totalLive = data[i]['rtd_agentsalive_count'] || 0
				obj.dialperagent = this.utils.getDivision(data[i]['rtd_dialled_count'],data[i]['rtd_signedin_count'])
				//console.log('obj', obj);
        //for download report
        obj.totalDataAvailable = (data[i]['rtd_data_pushed_count'] > 0) ? data[i]['rtd_data_pushed_count'] : 0; //data[i]['rtd_pd_data_pushed_count']+data[i]['rtd_pd_national_data_pushed_count']);
				obj.nationalCampaign = (data[i]['rtd_national_data_pushed_count'] > 0) ? data[i]['rtd_national_data_pushed_count'] : 0; //+data[i]['rtd_pd_national_data_pushed_count']);
				obj.localCampaign = (data[i]['rtd_local_data_pushed_count'] > 0) ? data[i]['rtd_local_data_pushed_count'] : 0; //-data[i]['rtd_national_data_pushed_count']); data[i]['rtd_pd_data_pushed_count'])-data[i]['rtd_pd_national_data_pushed_count']
				obj.referenceCustomers = (data[i]['rtd_ref_customer_count'] > 0) ? data[i]['rtd_ref_customer_count'] : 0;
				obj.manualDialed = (data[i]['rtd_manual_consumed_count'] > 0) ? data[i]['rtd_manual_consumed_count'] : 0;

        obj.dataUtilization = this.utils.getPercentage(data[i]['rtd_new_data_dialled_count'],data[i]['rtd_data_pushed_count']);
				obj.Utilization = data[i]['rtd_new_data_dialled_count'];
				obj.pushed_Count=data[i]['rtd_data_pushed_count']
				obj.followUpsMissed = data[i]['rtd_followup_data_count'];
				obj.totalDialed = data[i]['rtd_dialled_count'];
				obj.newDataDialed = data[i]['rtd_new_data_dialled_count'];
				obj.followUpDialed = data[i]['rtd_flp_data_dialled_count'];
        obj.connected = this.utils.getPercentage(data[i]['rtd_contacted_count'], data[i]['rtd_dialled_count']);
				obj.connected_count = data[i]['rtd_contacted_count'];
				obj.dialled_count = data[i]['rtd_dialled_count'];
				obj.totalContacted = data[i]['rtd_contacted_count'];
				obj.dnc = data[i]['rtd_dnd_count'];
				obj.wrongNumber = data[i]['rtd_wrong_number_dialed_count'];
				obj.customer_contacted = data[i]['rtd_unique_customer_contacted_count'];
				obj.rnr = data[i]['rtd_rnr_data_count'];
        obj.idleTime = data[i]['rtd_idletime_count']
        obj.waitTime = data[i]['rtd_waittime_count']
        obj.spokenTime = data[i]['rtd_spokentime_count']
        obj.wrapTime = data[i]['rtd_wraptime_count']
        obj.activeTime = data[i]['rtd_activetime_count']
        obj.usercount = data[i]['usercount'] || 0;
        obj.diallcount = data[i]['rtd_dialled_count']
        obj.signedIn = data[i]['rtd_signedin_count']
				arr.push(obj);
			}
		}
		return arr;
	}

	dateChangeDDL(val: any) {
		this.showHideDateRangeDiv = false;
		if (val == "Yesterday") {
      debugger;
			this.showHideDateRangeDiv = false;
      this.selectedDate = "Yesterday";
      let start_time = '01:30';
      let dateRangeStart =  moment().subtract(1, "days").format("DD-MM-YYYY");
      let dateRangeEnd =moment().subtract(0, "days").format("DD-MM-YYYY");
      let yesterday_startdate = moment(dateRangeStart + 'T' + start_time, 'DD-MM-YYYYTHH:mm:ss');
      console.log(yesterday_startdate.format('DD-MM-YYYYTHH:mm:ss'))
      let yesterday_enddate = moment(dateRangeEnd + 'T' + start_time, 'DD-MM-YYYYTHH:mm:ss');
      console.log(yesterday_enddate.format('DD-MM-YYYYTHH:mm:ss'))

      // let yesterday_startdate = moment().add(0, 'days').startOf("day").format('YYYY-MM-DDTHH:mm:ss');
      // let yesterday_enddate = moment().add(0, 'days').endOf("day").format('YYYY-MM-DDTHH:mm:ss');
      console.log(yesterday_startdate,"---",yesterday_enddate);
        this.start_date = yesterday_startdate.format('YYYY-MM-DDTHH:mm:ss');
        this.end_date = yesterday_enddate.format('YYYY-MM-DDTHH:mm:ss');
    }
		else if (val == "customDate") {
			this.showHideDateRangeDiv = true;
		}

	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.nameSearch = filterValue;
	}

	// getSummationData(variable){
	// 	let total = 0;
	// 	let dataValue:any = this.sortedData.getValue();
	// 	if(dataValue && dataValue.length){
	// 		for (let j = 0; j < dataValue.length; j++) {
	// 			if(dataValue[j].rData && dataValue[j].rData.length){
	// 				for (let k = 0; k < dataValue[j].rData.length; k++)
	// 					total += Number(dataValue[j].rData[k][variable]);
	// 			}
	// 		}
	// 	}
	// 	return total;
	// }
	// getSummationData(variable) {
	// 	//debugger;
	// 	let total = 0;
	// 	let dataValue: any = this.AllData;
	// 	//this.sortedData.getValue();

	// 	if (dataValue && dataValue.length) {
	// 		for (let j = 0; j < dataValue.length; j++) {
	// 			console.log("--datavalue--",JSON.stringify(dataValue));
	// 			if (dataValue[j]) {
	// 				for (let k in dataValue[j]) {
	// 					//console.log(dataValue[j][k]);
	// 					if (dataValue[j][k][variable]) {
	// 						if(dataValue[j][k][variable] !=null || dataValue[j][k][variable] !=undefined)
	// 						{
	// 						total += Number(dataValue[j][k][variable]);
	// 						//console.log(total);
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// 	return total;
	// }
	getSummationData(variable){
		let total = 0;
		const values = this.sortedData.getValue();
		//console.log(JSON.stringify(values));
		if (values && values.length) {
			for (let j = 0; j < values.length; j++) {
				if (values[j][variable] !=null||values[j] !=undefined) {

						total += Number(values[j][variable]);
				}
			}
			return total;
		}
	}
	getAllTotalLive() {
		return this.getSummationData('totalLive'); //this.getSummationData('rtd_agentsalive_count');
	}
	getAllTotalLoggedIn() {
		return this.getSummationData('totalLoggedIn');
		// return this.util.getDivision(this.getSummationData('rtd_signedin_count'),this.getSummationData('usercount'));
	}
	getAllTotalAgents() {
		return this.getSummationData('totalAgents');
	}

	getTotalValueloggedIn()
	{
		let totalavail = 0;
		let total=0;
		const values = this.sortedData.getValue();
		if (values && values.length) {
			for (let j = 0; j < values.length; j++) {
					if(values[j]['totalLoggedIn'] !=null || values[j]['totalLoggedIn'] !=undefined)
					{
						totalavail += Number(values[j]['totalLoggedIn']);
						total += Number(values[j]['totalAgents']);
					}
			}
			return this.utils.getPercentage(totalavail,total,1);
		}
	}
	getTotalValueliveIn()
	{
		let total = 0;
		let count=0;
		const values = this.sortedData.getValue();
		if (values && values.length) {
			for (let j = 0; j < values.length; j++) {

					if(values[j]['agentsalive_count'] !=null || values[j]['agentsalive_count'] !=undefined)
					{
					total += Number(values[j]['agentsalive_count']);
					count +=Number(values[j]['totalLoggedIn']);
					}

			}
			return this.utils.getPercentage(total,count,1);
		}
	}
	actionViewMore(details)
	{
		console.log(this.isASM_selected);
		console.log(JSON.stringify(details));
		if(this.isASM_selected && this.stage_setting_access)
		{
		//debugger;
		let groupdata = this.sortedData['_value'];
		//console.log(JSON.stringify(groupdata));
			var dialogRef = this.dialog.open(CentralDashboardAgentsPageDialogComponent, {
			minWidth: '85%',
			hasBackdrop: true,
			data: details
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == "true") {
				//this.service.SUPERVISOROBJ.next(null);
				//this.getRtdValues()

			}
		});
		}
	}
	getcolorStatus(val1:any,val2:any){
		//console.log(val1,"\n",val2);
		if(val1 && val2)
		{
			return '#0000FF';
		}else{
			return '#000000';
		}
	}
  extractDataObject(ref, obj) {
    let arr = [];
    for (var i in ref) {
      if (obj.hasOwnProperty(ref[i])) {
        if( ref[i] == 'id' && obj['agent_reference_id']){
          arr.push(obj['agent_reference_id'])
        }else{
          if(obj[ref[i]] == undefined)
          {
            arr.push(0);
          }
          else{
            arr.push(obj[ref[i]])
          }
        }

      } else {
        arr.push('')
      }
    }
    return arr;
  }
  get_time_format(sec) {
    //console.log('printing seconds',sec);
    if (!sec || isNaN(sec)) {
      // alert("Input data is incorrect!");
      return '00:00:00';
    }
    if (sec <= 0) {
      //alert("The second date occurred earlier than the first one!");
      return '00:00:00';
    }
    var minute = 60, hour = 60 * minute, day = 24 * hour;
    var hours = Math.floor(sec / hour);
    sec -= hours * hour;
    var minutes = Math.floor(sec / minute);
    sec -= minutes * minute;
    var seconds = Math.floor(sec);
    var hours_string, minutes_string, seconds_string

    var time = moment().set("hour", hours).set("minute", minutes).set("second", seconds);
    //console.log(time)
    return moment(time).format('HH:mm:ss');
    //return hours+":"+minutes+":"+seconds;
  }
  downloadReport(){
    debugger;
    this._fetchData = this.modifiedValues(this.asmData, 'asm');
    let asm_data = this._fetchData;
    console.log(JSON.stringify(asm_data));
    let data = this._data.ParentChildchecklist;
    console.log("--data-sample--",JSON.stringify(data));

    var data_mapper = {};

    data_mapper = {'center_name':'Center Name', 'ZSM': 'ZSM', 'RSM': 'RSM' , 'ASM': 'ASM', 'TotalAgents': 'Total Agents',
    'AgentsDial%': 'Agents Dial%',
    'ReferenceCustomers': 'Reference Customers',
    'TotalDialled':'Total Dialled',
    'NewDataDialled':'New Data Dialled',
    'FollowupsDialled':'Followups Dialled',
    'ManualDialled':'Manual Dialled',
    'NationalDataDialled':'National Data Dialled',
    'LocalDataDialled':'Local Data Dialled',
    'PredectiveDataDialled':'Predective Data Dialled',
    'NormalDataDialled':'Normal Data Dialled',
    'Dialled/Agent':'Dialled / Agent',
    'TotalContacted':'Total Contacted',
    'Contacted/Agent':'Contacted/Agent',
    'Contact%':'Contact %',
    'DNC':'DNC',
    'RNR':'RNR',
    'WrongNumber':'Wrong Number',
    'NOTINTERESTED':'NOT INTERESTED',
    'PANGenerated':'PAN Generated',
    'PANApproved':'PAN Approved',
    'QDEGenerated':'QDE Generated',
    'QDEApproved':'QDE Approved',
    'QDERejected':'QDE Rejected',
    'QDE/Agent':'QDE/Agent',
    'IdleTime':'Idle Time',
    'WaitTime':'Wait Time',
    'SpokenTime':'Spoken Time',
    'WrapTime':'Wrap Time',
    'ActiveTime':'Active Time'};

    let headers = Object.values(data_mapper);
    if (data && data.length) {
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Candidate Data');
      worksheet.addRow(headers);
      //this.updateFetchData('ASM');
      console.log(data);
      //console.log(this.sortedData._value);
      data.forEach((d: any) => {
        asm_data.filter((e)=>{
          console.log(e.name,"---",d.ASM);
          if(e.name==d.ASM)
          {

            d['AgentsDial%'] = e.totalLoggedIn
            d['TotalContacted'] = e.totalContacted
            d['TotalDialled'] = e.totalDialed
            d['NewDataDialled'] = e.newDataDialed
            d['FollowupsDialled'] = e.followUpDialed
            d['Dialled/Agent'] = e.dialperagent
            d['Contacted/Agent'] = e.connected
            d['TotalContacted'] = e.connected_count
            d['RNR'] = e.rnr
            d['WrongNumber'] = e.wrongNumber
            d['IdleTime'] = this.get_time_format(e.idleTime)
            d['WaitTime'] = this.get_time_format(e.waitTime)
            d['SpokenTime'] = this.get_time_format(e.spokenTime)
            d['WrapTime'] =  this.get_time_format(e.wrapTime)
            d['ActiveTime'] = this.get_time_format(e.activeTime)
            d['NationalDataDialled']=e.totalDialed
            d['ManualDialled']=e.manualDialed
            d['DNC']=0
            d['ReferenceCustomers']=0
            d['PANApproved'] =0
            d['QDEGenerated']=0
            d['QDEApproved']=0
            d['QDERejected']=0
            d['QDE/Agent']=0
            d['PredectiveDataDialled'] = e.totalDialed

            return ;
          }
        })
        console.log(d);
        // if(data[i])
        // {
        //   let current_row={name:data[i]['name'],totalDataAvailable:data[i]['totalDataAvailable'],nationalCampaign:data[i]['nationalCampaign'],manualDialed:data[i]['manualDialed'],localCampaign:data[i]['localCampaign'],referenceCustomers:data[i]['referenceCustomers']}
        //   worksheet.addRow(current_row)
        // }

        worksheet.addRow(this.extractDataObject(Object.keys(data_mapper), d));
        })
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'Dashboard-data-Report.xlsx');
      })

  }
}
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
