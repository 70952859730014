<br><br>
   <!--start AInex-->
   <div class="animation-One">
    <p class="text-center paraAdjust"> <span class="t2">
            <!-- Ainex -->
            <img src="../assets/image/ainexProductImg.png" alt="Image" style="width: 5em;" />
        </span>
        <br>
        <span class="t4 txtMiddle">
            <h1 class="lbl-ttl" style="font-size: 1.6em;top: 22px;font-weight: 600;">Speech And Video Analytics </h1> <br><br>
            With the Contiinex AI powered Speech-to-Text engine, you can now transcribe practically all the languages with an accuracy rate of up to 96% from voice recordings on a real-time basis. The Speech engine comes with the capabilities of working both inside your firewall and online.<br>
            Experience customer conversation with detail and draw valuable insights to the business. Save your time and cost with AiNex.
        </span>
    </p>

    <br><br>
    <div class="row paraAdjust alignCenter">
        <div class="col-md-1 col-sm-1 col-xs-12"></div>
        <div class="col-md-5 col-sm-5 col-xs-12">
            <img src="../assets/image/AInexImg1.png" alt="Image" style="width:100%;" />
        </div>
        <div class="col-md-5 col-sm-5 col-xs-12">
            <span class="t4">
                <b class="lbl-ttl"> Speech-to-text transcription</b><br><br>
                Unlike the other engines, Ainex Speech-to-text transcribes your data only for your process and does not cross leverage your data for other processes. With deep Speech-To-Text-Speech capabilities, Contiinex has helped customers not just to transcribe their agent-client voice recording but have advanced to identifying keywords and basis this it has the intelligence to auto-trigger multiple events ranging from sending an auto-quotation or raising an auto-service ticket.<br><br>

                Contiinex Speech-To-Text engine is platform agnostic that you can plug and play into your existing platform. It gives the true meaning to ‘Voice of Customer’, so you can take action basis it!
            </span>
        </div>
        <div class="col-md-1 col-sm-1 col-xs-12"></div>
    </div> <br><br>
    <div class="AinexCsForWeb">
        <div class="row paraAdjust alignCenter">
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
            <div class="col-md-5 col-sm-5 col-xs-12">
                <span class="t4">
                    <b  class="lbl-ttl">AI based biometric solution with Ainex </b><br><br>
                    Ainex with its Biometric Technology caters to user authorisation and authentication using voice and video analysis. Ainex Biometric intelligence allows you to identify and verify your callers in a jiffy. Ainex uses voice patterns and specific, distinctive facial details to produce unique identification for every individual. It’s Built to increase security, save your time and cost.
</span>
                <br><br>
            </div>
            <div class="col-md-5 col-sm-5 col-xs-12">
                <img src="../assets/image/AinexImg2.png" style="width:100%;" alt="Image" />
            </div>
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
        </div>
    </div>
    <div class="row paraAdjust alignCenter AinexCsForMobile">
        <div class="col-md-1 col-sm-1 col-xs-12"></div>
        <div class="col-md-5 col-sm-5 col-xs-12">
            <img src="../assets/image/AinexImg2.png" style="width:100%;" alt="Image" />
        </div>
        <div class="col-md-5 col-sm-5 col-xs-12">
            <span class="t4">
                <b  class="lbl-ttl">AI based biometric solution with Ainex </b><br><br>
                Ainex with its Biometric Technology caters to user authorisation and authentication using voice and video analysis. Ainex Biometric intelligence allows you to identify and verify your callers in a jiffy. Ainex uses voice patterns and specific, distinctive facial details to produce unique identification for every individual. It’s Built to increase security, save your time and cost.

            </span>
            <br><br>
        </div>
        <div class="col-md-1 col-sm-1 col-xs-12"></div>
    </div>
    <div class="row rowMLRZero paraAdjust" style="background-color: #f5f5f5;padding: 70px 0px;">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <p class="text-center t4" style="line-height: 35px;padding: 50px;">
                <span>
                    <h2 style="color:#000000">Ainex comes with</h2>
                </span><br>
                <span> <b class="lbl-ttl" style="font-weight: 500;"> Speech-To-Text-Speech transcription
                    </b> <br></span>
                <span class="txtMiddle"> It’s a plug and play module to convert live or recoded conversation</span><br><br>
                <span><b class="lbl-ttl" style="font-weight: 500;"> Voice and Vision signature
                        authentication and authorisation </b> <br> </span>
                <span class="txtMiddle">Register, authenticate and verify the caller, speaker or an agent </span><br><br>
                <span> <b class="lbl-ttl" style="font-weight: 500;"> Automated Audit for call centre </b>
                    <br></span>
                <span class="txtMiddle"> Delivers speed, accuracy and efficacy to the process </span><br><br>
                <span> <b class="lbl-ttl" style="font-weight: 500;"> Dictation Assistive Technology </b>
                    <br> </span>
                <span class="txtMiddle"> Convert your spoken words to text. Super power to write fast! </span>
            </p>
        </div>
    </div>

    <br><br>
    <div class="row paraAdjust">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <p class="text-center t4" style="font-size: 0.8em;line-height: 35px;">
                <span>
                    <h2 style="color:#000000">With AiNex, you will </h2>
                </span><br>
                <span>
                    <ul style="text-align: initial;display: inline-block;">
                        <li>
                            Deliver great customer experience
                        </li>
                        <li>
                            Make conversations insightful and relevant, improve self-serve option for
                            customers
                        </li>
                        <li>
                            Save time of human workforce for more critical tasks
                        </li>
                        <li>
                            Cut down on operational cost
                        </li>
                    </ul>
                </span><br>
            </p>
        </div>
    </div>

</div>
<!--end AInex-->