
<mat-card class="mt-4" style="margin: 8px 15px;">
    <div class="row" style="padding: 50px 0 0 0;" *ngIf="welcomeUser">
        <div class="col-md-12 text-center">
            <h3 class="hWelcome">Welcome…!</h3>
            <h2 class="hUser">{{ myUser.name }}</h2>
            <h4 class="hDesc"> We would like to now create your photo profile for identification and security purpose.</h4>
            <p><img src="../assets/web_images/nextIconImage.svg" (click)="readyWebCam()" matTooltip="Next" matTooltipPosition="after" class="nextImageIcon" /></p>
        </div>
    </div>
    <br>


    <div class="row mTopcs" >
        <div class="col-md-12 text-center">
            <div>
                <div style="width:100%;display:flex;justify-content: center;" *ngIf="captureDone == false" >
                    <div class="formobileFaceDetails"  *ngIf="instructionCameraUser">
                        <p class="mb-0"><img src="../assets/web_images/instHair.svg" /></p>
                        <p class="text-center"><span class="para1">Tuck your <b style="color:#a86400">hair</b> behind<br>your ears</span></p>
                        <p class="mb-0"><img src="../assets/web_images/instTime.svg" /></p>
                        <p class="text-center"><span class="para2">Hold your face steadily <br>for <b style="color:#a86400">30 second</b></span></p>

                        <p class="mb-0"><img src="../assets/web_images/instEye.svg" /></p>
                        <p class="text-center"><span class="para3">Keep your <b style="color:#a86400">eyes</b>  open</span></p>

                        <p class="mb-0"><img src="../assets/web_images/instGreenBox.svg" /></p>
                        <p class="text-center"><span class="para4">Align your face in the <br> <b
                                    style="color:#a86400">green box</b></span>
                        </p>
                    </div>
                </div>
                <div style="width:100%;display: inline-flex;justify-content: center;" *ngIf="showHideTimer">
                    <span class="borderLabel"><label  [ngClass]="errorMessage ? 'redColor' : 'greenColor'"> {{seconds}} </label> Seconds Left </span>
                </div>
    <div style="width:100%;display:flex;justify-content: center;">
        <div class="text-right forwebFaceDetails" style="width:275px" *ngIf="captureDone == false" >
            <div style="height: 195px;position: relative; top: -32px;" *ngIf="instructionCameraUser">
                <p class="mb-0"><img src="../assets/web_images/instHair.svg" /></p>
                <p class="text-center"><span class="para1">Tuck your <b style="color:#a86400">hair</b>  behind<br>your ears</span></p>
                <br><br>
            </div>
            <div style="position: relative; top: 22px;" *ngIf="instructionCameraUser">
                <p class="mb-0"><img src="../assets/web_images/instTime.svg" /></p>
                <p class="text-center"><span class="para2">Hold your face steadily <br>for <b
                            style="color:#a86400">30 second</b></span></p>
            </div>
        </div>

        <div style="width:275px">
            <div class="borderGapCamera"  [ngStyle]="{'outline': showHideCameraDiv == false ? '3px solid #20d224' : 'none'}" 
              [ngClass]="errorMessage ? 'OutlineRed' : 'none'">
                <div class="matCamCard" style="box-shadow: none !important;"
                    [ngClass]="showHideCameraDiv == false ? 'class1 vido' : 'class2' ">
                    <video #video style="height: 220px;width: 220px;" class="vid" autoplay
                        [ngStyle]="{'border': showHideCameraDiv == false ? '2px solid #fae5a4' : '0px'}"></video>
                    <canvas #canvas class="dotedOval"
                        [ngStyle]="{'border': showHideCameraDiv == false ? 'none' : '0px'}"></canvas>
                    <span [ngStyle]="{'display': captureDone ? 'block' : 'none'}">
                        <canvas #canvas2 class="vid" style="position: absolute;height: 215px;width: 215px;top: 2px;left: 2px;"></canvas>
                    </span>
                </div>
            </div>



        </div>
        <div class="text-left forwebFaceDetails" style="width:275px" *ngIf="captureDone == false" >
            <div style="height: 195px;position: relative; top: -32px;" *ngIf="instructionCameraUser">
                <p class="mb-0"><img src="../assets/web_images/instEye.svg" /></p>
                <p class="text-center"><span class="para3">Keep your <b style="color:#a86400">eyes</b>
                        open</span></p>
                <br><br>
            </div>
            <div style="position: relative; top: 22px;" *ngIf="instructionCameraUser">
                <p class="mb-0"><img src="../assets/web_images/instGreenBox.svg" /></p>
                <p class="text-center"><span class="para4">Align your face in the <br> <b
                            style="color:#a86400">green box</b></span>
                </p>
            </div>
        </div>
    </div>

</div>
</div>
</div>
<span>
    <div class="p-1">
        <canvas #canvas2 class="vid" style="position: absolute;display: none;"></canvas>
    </div>
    <span *ngIf="faceShowing == false && showHideCameraDiv == false">
        <h3 class="text1" style="color:#3fb90d;font-size: 22px;">
            Your face is not evenly lit</h3>                      
    </span>
    <span *ngIf="faceShowing == true && captureDone == false">

        <h3  *ngIf="errorMessage" class="text1" style="color:red;font-size: 17px; font-weight: bold;text-transform: capitalize;">
            {{errorMessage}}</h3> 

        <div class="row">
            <div class="col-md-12 text-center" >
                <button class="readyBtn" *ngIf="!errorMessage" (click)="capture()" style="margin-top: 4px;"
                    [ngStyle]="{'background': captureStarted ? '#fff' : '#3fb90d', 'color':captureStarted ? 'green' :'#fff', 'font-weight':captureStarted ? 'bold' :'normal'}"
                    [disabled]="captureStarted">{{captureStarted ? 'Processing please wait…' : 'START'}}</button><br>
                <h5 class="h5Text"><b style="color:red">* &nbsp;</b>This picture will be used for identification and facial recognition
                </h5>
            </div>
        </div>
    </span>
</span>

<span *ngIf="captureDone == true">
    <p style="text-align: center;"><img src="../assets/web_images/doneCapture.svg"/></p>
    <h3 class="text1" style="color: #a86400;font-size: 26px;font-weight: 600;"> Captured successfully</h3>
    <p class="h5Text"><span> We have now activated your facial biometric</span>
    </p>
    <div class="row">
        <div class="col-md-12 text-center">
            <button class="readyBtn" (click)="navigateAfterFacial()">Done</button>
        </div>
    </div>
</span>


<br><br>

    </mat-card>

<br>
<!------------------------------------------------------------->


<!-- <mat-card class="mt-2">
    <div class="row">
        <div class="col-md-10 text-center">
            <div>
                <span *ngIf="showHideCameraDiv">
                    <h3 class="text1" style="margin-bottom: 15px;">
                        Welcome {{ myUser.name }},<br/>
                        We would like to now create your photo profile for identification and security purpose.</h3>
                    <mat-card class="faceCard">
                        <div>
                            <div class="row">
                                <div class="col-md-12">
                                    <img src="../assets/userImg.svg" style="width: 88%;padding-left: 34px;" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <button class="readyBtn" (click)="readyWebCam()">I am ready</button>
                                </div>
                            </div>
                        </div>

                    </mat-card>
                    <h3 class="text1">
                        Align your face in the green oval box<br>
                        and tuck your hair behind your ears.</h3>
                    <p class="text2"><span>
                            <b>Note:</b> This picture will be used for identification and facial recognition
                        </span>
                    </p>
                </span>
                <span  >
                    <mat-card class=""  style="width: fit-content;margin: 0 auto;" [ngClass]="showHideCameraDiv == false ? 'class1 vido' : 'class2' ">
                        <video #video style="height: 270px;width: 270px;" class="vid" autoplay [ngStyle]="{'border': showHideCameraDiv == false ? '8px solid #9969' : '0px'}" ></video>
                        <canvas #canvas style="top: 40px;left: 62px;width: 180px;height: 225px;border-radius: 56% 45% 65% 65% / 60% 60% 60% 60%;"  [ngStyle]="{'border': showHideCameraDiv == false ? '6px dotted green' : '0px'}"></canvas>
                        <span [ngStyle]="{'display': captureDone ? 'block' : 'none'}">
                            <canvas #canvas2 class="vid" style="position: absolute;height: 250px;width: 250px;" ></canvas>
                        </span>
                        
                    </mat-card>
                    <div class="p-1">
                        <canvas #canvas2 class="vid" style="position: absolute;display: none;"></canvas>
                    </div>
                    <span *ngIf="faceShowing == false && showHideCameraDiv == false">
                        <h3 class="text1" style="color:#e81c5e;font-size: 22px;">
                            Your face is not evenly lit</h3>
                        <p class="text2" style="color:#97a1ae;"><span>
                                Align your face to a light source
                            </span>
                        </p>
                    </span> 
                     <span *ngIf="faceShowing == true && captureDone == false">
                        <h3 class="text1" style="color:#e81c5e;font-size: 22px;">
                            {{errorMessage}}</h3>
                            
                    
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button class="readyBtn" (click)="capture()" [ngStyle]="{'background': captureStarted ? '#d6d6d6' : '#e81c5e'}" [disabled]="captureStarted">{{captureStarted ? 'Wait... processing' : 'Next'}}</button>
                            </div>
                        </div>
                    </span>
                </span>
                <span *ngIf="captureDone == true">
                    <h3 class="text1" style="color:#e81c5e;font-size: 22px;">
                        Captured successfully</h3>
                    <p class="text2" style="color:#97a1ae;"><span>
                        We have now activated your facial biometrics.
                        </span>
                    </p>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <button class="readyBtn" (click)="navigateAfterFacial()">Done</button>
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="col-md-2 text-center" *ngIf="showHideTimer">
            <p class="secondcs">{{seconds}}</p>
        </div>
    </div>
</mat-card> -->
<!-- <canvas #canvas  style="position: absolute;"></canvas> -->
