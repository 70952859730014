<div class="row">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <span style="position: absolute;top: -36px;right: 15px;z-index: 2;cursor: pointer;">
      <img src="../assets/closeicon.svg" width="40px" style="position: fixed;" (click)="closeDialog()" /></span>
  </div>
</div>
<form [formGroup]="uploadForm">
  <div class="mainDialogDiv">
	<div class="row">
		<div class="col-md-12">
			<h1 class="hOne">Upload</h1>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<mat-form-field>
				<mat-label>Event Type</mat-label>
				<mat-select [(value)]="selectedUploadType" name="upload_type">
					<mat-option *ngFor="let u of uploadTypes" [value]="u.value">
					{{u.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
    <div class="row">
      <div class="col-md-12">
		<a *ngIf="selectedUploadType == 'sales'" href="/api/v1/attachments/Payment_sales_push.xlsx?access_token={{access_token}}" target="_blank">Download Template</a>
		<a *ngIf="selectedUploadType == 'call_and_qualityscore'" href="/api/v1/attachments/PaymentByQualityScore.xlsx?access_token={{access_token}}" target="_blank">Download Template</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="upload-btn-wrapper" style="width: 105px;">
          <button class="btnbrowse">Choose file</button>
          <input type="file" class="filecs" formControlName="file" (change)="changeFile($event.target.files)" />
        </div>
		<span *ngIf="file">{{file.name}}</span>
      </div>
      <div class="col-md-6">
        <mat-form-field style="width:90%">
          <input matInput placeholder="Unit price" maxlength="15"  (keypress)="decimalFilter($event)" formControlName="amount_perevent" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button class="submit-btn-ui" (click)="saveFile()"> Submit</button>
      </div>
    </div>
  </div>
</form>
