<app-new-contiinex-menu-page></app-new-contiinex-menu-page>
<div class="v-mainDivCO">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12"> 
            <div class="mainWB">
                <div class="row animation-One2x" style="align-items: center;">
                    <div class="col-md-1 col-sm-1 col-xs-12"></div>
                
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="wb-center">
                
                <p class="wb-banner-name">
                    WhatsApp<br>
                Business<br>
                API.
                </p>
                
                </div>
                <p> <button class="wb-reg-btn" (click)="openRegisterPage('banner')">Register</button> </p>
                
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                
                        <img src="../assets/contiinex/image/wb-banner-img.png" style="width: 100%;"/>
                        <!-- <img src="../assets/contiinex/image/wb-banner1.png" class="wb-banner1" />
                        <img src="../assets/contiinex/image/wb-banner2.png" class="wb-banner2"/>
                        <img src="../assets/contiinex/image/wb-banner3.png" class="wb-banner3"/> -->
                
                    </div>
                
                    <div class="col-md-1 col-sm-1 col-xs-12"></div>
                    </div>
                <br><br>
                <!--
                <div>
                    <p class="v-hd2 mb-1">WhatsApp Business API</p>
                    <h3 class="v-txt2x">
                      Connect with your customer through the most accepted messaging channel with
                      Contiinex’s WhatsApp Business API &amp; messaging API’s
                    </h3>
                </div>
                -->
                
                <div style="background-color: #f8f8f8;padding: 70px 6px;">
                    <div class="row" style="align-items: center;">
                        <div class="col-md-1 col-sm-1 col-xs-12"></div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <img src="../assets/contiinex/image/wa-whatsapp-solution.png" class="waWhatsappImg"/>
                        </div>
                
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <p class="v-hd2 mb-1 watitle1 text-left">WhatsApp Business API</p>
                          <h3 class="v-txt2x waDesc1 text-left" >
                            WhatsApp Business offers solution to develop meaningful and impactful connections with your customers and reach out to billions of other customers in an efficient and yet a secured way.
                            WhatsApp business API allows you to programmatically integrate the workflows through personalized notifications and 2-way conversations. WhatsApp business
                            API is flexible, easy to integrate with any CRM, On-premise software, contact centre and support software to provide an exceptional connect and engagement with customers
                          </h3>
                        </div>
                        <div class="col-md-1 col-sm-1 col-xs-12"></div>
                
                    </div>
                </div>
                
                
                <div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12 v-whatsbg-cs" style="justify-content: center; display: flex;background-image: url('../assets/contiinex/image/wa-whats-bg.png');    padding: 10px;">
                                <div class="wacontainer">
                                    <img src="../assets/contiinex/image/wa-wp-image.png"/>
                                    <div class="wacentered">
                                      <p class="v-hd2 mb-1 text-left">WhatsApp Solution </p>
                                      <h3 class="v-txt2x wa-whatsapp-desc" >
                                        Connect with your customer through the most accepted messaging channel with Contiinex’s WhatsApp Business API & messaging API’s
                                        We will enable you to host them as a service and deploy a feature rich WhatsApp Business solution for you in no time.
                                      </h3>
                
                                    </div>
                                  </div>
                            </div>
                        </div>
                </div>
                
                
                
                
                
                <div class="waEasyBg" style="background-image: url('../assets/contiinex/image/waEasyBg.png');margin-top: 50px;padding-bottom: 40px;">
                    <div class="row text-center">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <br>
                          <p style="margin-bottom: -18px;"> <img src="../assets/contiinex/image/waThreeEasy.png" class="easyImg"/> </p>
                          <p class="v-hd2 mb-0">Easy Steps</p>
                          <p class="v-hd2 mb-0 easyDes">To Start Interacting With Your Customer On WhatsApp</p>
                <br>
                          <div class="row text-center mt-4">
                            <div class="col-md-1 col-sm-1 col-xs-12"></div>
                            <div class="col-md col-sm col-xs-12">
                <p style="height: 150px;"> <img src="../assets/contiinex/image/easy1.png" class="easy1Img"/></p>
                <p class="e11">Signup for your<br>
                    WhatsApp Business Account</p>
                            </div>
                            <div class="col-md col-sm col-xs-12">
                                <p style="height: 150px;"> <img src="../assets/contiinex/image/easy2.png" class="easy1Img"/></p>
                                <p class="e11">Get Your Own Personalized <br>
                                    WhatsApp Business Account</p>
                
                            </div>
                            <div class="col-md col-sm col-xs-12">
                                <p style="height: 150px;"> <img src="../assets/contiinex/image/easy3.png" class="easy1Img" style="width: 97px;"/></p>
                
                                <p class="e11">Engage Contiinex bots to send updates or converse with your customer</p>
                            </div>
                            <div class="col-md-1 col-sm-1 col-xs-12"></div>
                            </div>
                
                        </div>
                
                    </div>
                    <p class="mt-4 text-center"><span class="v-view-demo" title="Register" (click)="openRegisterPage('easySteps')"> REGISTER </span>
                    </p>
                </div>
                
                
                
                
                
                
                <div class="waBgBg1" style="background-image: url('../assets/contiinex/image/BgBG1.png');margin-top: 50px;padding-bottom: 40px;">
                    <p class="v-hd2 mb-0">The WhatsApp Business API Supports <br>
                        10 Message Template Categories</p>
                <br>
                    <div class="flexcontainer11 mt-3">
                        <div style="background-color: #dfe3dd;">Issue Resolution</div>
                        <div style="background-color:#f5e5cc;">Appointment Update</div>
                        <div style="background-color:#e2ecf5;">Account Update</div>
                        <div style="background-color:#f5e5cc;">Reservation Update </div>
                        <div style="background-color:#fef2ff;">Personal Finance Update</div>
                
                        <div style="background-color: #d6ebe7;">Payment Update</div>
                        <div style="background-color:#e5dd9e;">Ticket Update</div>
                        <div style="background-color:#d7e1f7;">Shipping Update</div>
                        <div style="background-color:#d6f8c5;">Transportation Update </div>
                        <div style="background-color:#ffdbd3;">Alert Update</div>
                      </div>
                
                    <div class="row text-center mt-4">
                        <div class="col-md-2 col-sm-12 col-xs-12"></div>
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <mat-card class="mcx11">
                                <p><img src="../assets/contiinex/image/iconx-x1.png"/></p>
                                <p><span class="titlex1"> Send And Receive Multimedia</span></p>
                                <p class="descx11">
                                    Your conversations with your customers can contain rich multimedia content including images, pdfs, audio, video, docs, stickers and emojis.<br>
                                    This will empower your businesses to communicate in a more engaging format while being able to share tutorials, utility bills, invoice statements etc.
                
                                     </p>
                
                            </mat-card>
                        </div>
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <mat-card class="mcx11">
                                <p><img src="../assets/contiinex/image/iconx-x2.png"/></p>
                                <p><span class="titlex1">Interactive Messages </span></p>
                                <p class="descx11">Interactive messages give your customers a simpler way to find and select what they want from your business on WhatsApp. List Messages, Reply Buttons, Single Product Message, Multi-Product message are the varied forms to quickly drive the conversations and receive the intent from your customers with less effort </p>
                
                            </mat-card>
                        </div>
                    <div class="col-md-2 col-sm-12 col-xs-12"></div>
                    </div>
                
                
                    <div class="row text-center">
                        <div class="col-md-2 col-sm-12 col-xs-12"></div>
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <mat-card class="mcx11">
                                <p><img src="../assets/contiinex/image/iconx-x3.png"/></p>
                                <p><span class="titlex1">Interactive Buttons </span></p>
                                <p class="descx11">Enterprise can send messages containing buttons for powerful experience to the user.<br>
                                    WhatsApp Business solution allows you to add interactivity to the text or media message through the Button feature. </p>
                
                            </mat-card>
                        </div>
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <mat-card class="mcx11">
                                <p><img src="../assets/contiinex/image/iconx-x4.png"/></p>
                                <p><span class="titlex1">Commerce Platform </span></p>
                                <p class="descx11">The Commerce platform enables e-commerce solutions and retailers to deeply integrate their infrastructure with the tools available to sell their products
                                    across the Facebook Family of apps, including Facebook Shops, Marketplace, and Instagram Shopping.
                                     </p>
                
                            </mat-card>
                        </div>
                    <div class="col-md-2 col-sm-12 col-xs-12"></div>
                    </div>
                
                    <div class="row text-center">
                        <div class="col-md-2 col-sm-12 col-xs-12"></div>
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <mat-card class="mcx11">
                                <p><img src="../assets/contiinex/image/iconx-x5.png"/></p>
                                <p><span class="titlex1"> Language Options</span></p>
                                <p class="descx11">Connecting with the customers in their preferred regional language has been one of the most striking features of WhatsApp.Unleash the power of conversations with different language options to choose from. The language support can be enabled in both notification and conversational flows. </p>
                
                            </mat-card>
                        </div>
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <mat-card class="mcx11">
                                <p><img src="../assets/contiinex/image/iconx-x6.png"/></p>
                                <p><span class="titlex1"> Notification Templates</span></p>
                                <p class="descx11">For opted-in customers, WhatsApp provides a mechanism to send additional types of information - defined and approved as templates. Message templates may be defined for specific business use cases like appointment reminders, shipping information, issue resolution, payment updates, bills etc.</p>
                
                            </mat-card>
                        </div>
                    <div class="col-md-2 col-sm-12 col-xs-12"></div>
                    </div>
                
                <div>
                    <br><br>
                    <p class="v-hd2 mt-4 mb-0">Opt-in: </p>
                    <p class="v-xx">All business-initiated messages require customer opt-in</p>
                        <h3 class="v-txt2x v-xx2">
                            Opt-ins must be collected before initiating any conversation with a customer using WhatsApp.<br>
                            It is your responsibility, as a business, to store customer opt-ins and to ensure each customer that<br> you choose to contact has agreed to receive messages on WhatsApp.
                    </h3>
                
                </div>
                
                
                
                
                
                    <div style="margin-top: 50px;padding-bottom: 10px;">
                        <div class="row text-center">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <br>
                              <p class="v-hd2 mb-0 workxx">How it works ?</p>
                              <p class="v-hd2 mb-0 workDesc">All 3 are required for opt-in</p>
                    <br>
                              <div class="row text-center mt-0">
                                <div class="col-md-1 col-sm-1 col-xs-12"></div>
                                <div class="col-md col-sm col-xs-12">
                                    <p style="height: 45px;"> <img src="../assets/contiinex/image/work01.png" class="work1Img"/></p>
                                    <p class="e11">Businesses must clearly state that a person is opting in to receive messages from the business over WhatsApp</p>
                                                </div>
                                <div class="col-md col-sm col-xs-12">
                                    <p style="height: 45px;"> <img src="../assets/contiinex/image/work02.png" class="work1Img"/></p>
                                    <p class="e11">Businesses must clearly state the business’ name that a person is opting in to receive messages from</p>
                
                                </div>
                                <div class="col-md col-sm col-xs-12">
                                    <p style="height: 45px;"> <img src="../assets/contiinex/image/work03.png" class="work1Img"/></p>
                
                                    <p class="e11">Businesses must comply with applicable law</p>
                                </div>
                                <div class="col-md-1 col-sm-1 col-xs-12"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                <br>
                    <div style="margin-top: 30px;padding-bottom: 10px;margin-bottom: -78px;">
                        <div class="row text-center">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <p style="height: 45px;"> <img src="../assets/contiinex/image/wpIconx1.png" style="width:70px;"/></p>
                                <p class="descx11 xxz"> Signup for your WhatsApp Business Account</p>
                                <p class="mt-3 text-center"><span class="v-view-demo" title="Register" (click)="openRegisterPage('signupText')" style="background-color: #fff;"> REGISTER </span>
                                </p>
                            </div>
                        </div>
                    </div>
                
                
                
                
                
                </div>
                </div>
                
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
                <br><br>
                <app-new-contiinex-footer-section-page></app-new-contiinex-footer-section-page>
            </div>
            </div>
</div>
