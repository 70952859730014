
<div class="row" style="padding: 31px 12px 31px 12px;" >
    <div class="col-md-12">
        <div class="m-div" style="height:auto">
            <p><span class="s-hd">SMS</span></p>
        
            
          

            <div class="row">
                <div class="col-md-6"  style="height:100px">
                    <label class="lb2">How to represent</label>
                    <mat-form-field [ngClass]="'orchDDlOutline'" style="font-size: 12px;padding: 20px;width: 100%;" appearance="outline"  class="pb-0">
                        <mat-select placeholder="Select">
                            <mat-option value="1">Option1</mat-option>
                            <mat-option value="2">Option2</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
            </div>
            <div class="row"  style="height:100px">
                <div class="col-md-6 ">
                    <label class="lb2">To</label>
                    <mat-form-field [ngClass]="'orchDDlOutline'" style="font-size: 12px;padding: 20px;width: 100%;" appearance="outline"  class="pb-0">
                        <mat-select placeholder=">">
                            <mat-option value="1">Option1</mat-option>
                            <mat-option value="2">Option2</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!-- <span style="display: block;">* “>” Grater than icon represents the name of the enduser. It will show the name
                    </span> -->
                    </div>
                    <div  class="col-md-6 ">
                        <label class="lb2">Subject</label><br>
                        <mat-form-field [ngClass]="'orchDDlOutline'" style="font-size: 12px;padding: 20px;width: 100%;" appearance="outline"  class="pb-0">
                            <input matInput>
                            </mat-form-field>
                    </div>
            </div>
            <div class="row">
                <div class="col-md-12 ">
                    <label class="lb2">Content</label><br>
                    <mat-form-field [ngClass]="'orchDDlOutline'" style="font-size: 12px;padding: 20px;width: 100%;" appearance="outline"  class="pb-0" >
                        <input matInput>
                        </mat-form-field>
                    </div>
            </div>
          
            <div class="row">
                <div class="col-md-12 text-right" style="position: relative;
                right: 20px;">
                <button class="saveSubmit">SAVE</button>
            </div>
        </div>
        <br>

            </div>
        </div>
        </div>
      

