<mat-drawer-container autosize>
    <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
        <app-menu  [navigateLink]="currentTitle"></app-menu>
    </mat-drawer>
    <app-logout></app-logout>
<div class="CN-Main sbPad">
    <div class="row">
        <div class="col-md-9">
             <p class="CN-Tab-head mb-4">Campaign Dashboard</p>
        </div>
        <div class="col-md-3">
            <mat-form-field style="width:100%">
                <mat-select placeholder="Select Channel" [(ngModel)]="selectedDDl" (selectionChange)="ddlChange($event.value)" disabled>
                    <mat-option value="Call">Call</mat-option>
                    <mat-option value="WhatsApp">WhatsApp</mat-option>
                    <mat-option value="SMS">SMS</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div>
        <div>
            <div class="row" *ngIf="showHideCampaignList">
                <div class="col-md-3" style="padding-right: 0;">
                    <div class="card">
                        <div class="card-header">Campaigns</div>
                        <div class="card-body">
                            <table class="Cam-table">
                                <tr>
                                    <td><span>Data assigned</span>
                                        <mat-progress-bar mode="determinate" [ngClass]="'color-one'" value="10">
                                        </mat-progress-bar>
                                    </td>
                                    <td class="tdData1"><span>10% (10)</span></td>
                                </tr>
                                <tr>
                                    <td><span>Data Dialled</span>
                                        <mat-progress-bar mode="determinate" [ngClass]="'color-two'" value="5">
                                        </mat-progress-bar>
                                    </td>
                                    <td class="tdData2"><span>20% (20)</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Data Contacted</span>
                                        <mat-progress-bar mode="determinate" [ngClass]="'color-three'" value="10">
                                        </mat-progress-bar>
                                    </td>
                                    <td class="tdData3"><span>10% (10)</span>
                                    </td>
                                </tr>
                            </table>

                        </div>
                    </div>
                </div>
                <div class="col-md-3" style="padding-right: 0;">
                    <div class="card">

                        <div class="card-header">Total Active Campaigns
                        </div>
                        <div class="card-body">
                            <table class="Cam-table">
                                <tr>
                                    <td><span>Data assigned</span>
                                        <mat-progress-bar mode="determinate" [ngClass]="'color-one'" value="0">
                                        </mat-progress-bar>
                                    </td>
                                    <td class="tdData1"><span>0% (0)</span></td>
                                </tr>
                                <tr>
                                    <td><span>Data Dialled</span>
                                        <mat-progress-bar mode="determinate" [ngClass]="'color-two'" value="17">
                                        </mat-progress-bar>
                                    </td>
                                    <td class="tdData2"><span>17% (17)</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Data Contacted</span>
                                        <mat-progress-bar mode="determinate" [ngClass]="'color-three'" value="100">
                                        </mat-progress-bar>
                                    </td>
                                    <td class="tdData3"><span>100% (100)</span>
                                    </td>
                                </tr>
                            </table>

                        </div>
                    </div>
                </div>
                <div class="col-md-3" style="padding-right: 0;">
                    <div class="card">
                        <div class="card-header">Total InActive Campaigns
                        </div>
                        <div class="card-body">

                            <table class="Cam-table">
                                <tr>
                                    <td><span>Data assigned</span>
                                        <mat-progress-bar mode="determinate" [ngClass]="'color-one'" value="10">
                                        </mat-progress-bar>
                                    </td>
                                    <td class="tdData1"><span>10% (10)</span></td>
                                </tr>
                                <tr>
                                    <td><span>Data Dialled</span>
                                        <mat-progress-bar mode="determinate" [ngClass]="'color-two'" value="20">
                                        </mat-progress-bar>
                                    </td>
                                    <td class="tdData2"><span>20% (20)</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Data Contacted</span>
                                        <mat-progress-bar mode="determinate" [ngClass]="'color-three'" value="5">
                                        </mat-progress-bar>
                                    </td>
                                    <td class="tdData3"><span>5% (5)</span>
                                    </td>
                                </tr>
                            </table>

                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-header" *ngIf="this.user.groupid == 81419">Channels</div>
                        <div class="card-header" *ngIf="this.user.groupid != 81419">Data Sources</div>
                        <div class="card-body scroll">
                            <table class="tablecs">
                                <thead *ngIf="this.user.groupid == 81419">
                                    <tr>
                                        <th class="data_source_headers">Channel</th>
                                        <th class="data_source_headers">Clicks</th>
                                        <th class="data_source_headers">Throughput</th>
                                    </tr>
                                </thead>
                                <thead *ngIf="this.user.groupid != 81419">
                                    <tr>
                                        <th class="data_source_headers">Source</th>
                                        <th class="data_source_headers">Data</th>
                                        <th class="data_source_headers">Utilized</th>
                                    </tr>
                                </thead>
                                <!-- <tbody *ngIf="this.user.groupid == 81419">
							
							<tr *ngFor="let key of getJsonKeys(summary.channels)">
								<td class="data_source_campaign">{{ key }}</td>
								<td class="data_source_data">
									{{  summary.channels[key].clicks }}</td>
								<td class="numeric_threshold"
									[class]="numericThresholdBycolor(summary.channels[key].throughput)">
									{{  summary.channels[key].throughput }}</td>
							</tr> 
						</tbody>
						<tbody *ngIf="this.user.groupid != 81419">
							<tr *ngFor="let campaign of getJsonKeys(summary.data_source)">
								<td class="data_source_campaign">{{ campaign }}</td>
								<td class="data_source_data">
									{{ summary.data_source[campaign].data }}</td>
								<td class="numeric_threshold"
									[class]="numericThresholdBycolor(summary.data_source[campaign].called_percent)">
									{{ summary.data_source[campaign].called_percent }}%</td>
							</tr>
						</tbody> -->
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <mat-card class=" mb-4">
                <div class="row">
                    <div class="col-md-12">
                        <div class="pDiv1">
                            <div class="divprintRt">


                                <div class="row">
                                    <div class="col-md-6">
                                        <span class="recName" style="top: 20px;">Campaign Summary</span>
                                        <!-- <span class="datecs">{{ today }}</span>
                            <span class="year_dropdown pl-3 pr-3">
                                <mat-form-field>
                                    <mat-label>Select Month</mat-label>
                                    <mat-select style="padding-top: 3px;" (selectionChange)="change_selectedMonth($event)">
                                        <mat-option *ngFor="let month of months_filter" [value]="month.value">
                                            {{month.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </span> 
                        	<span style="padding-left: 12px;"  (click)="refreshCampaignData()"><button  class="matBtn" mat-button>Go</button></span>
                        -->

                                    </div>
                                    <div class="col-md-6 text-right">
                                        <span class="createbtn">
                                            <button mat-button class="matBtn mt-3 mr-4" (click)="refreshBtn()" >Refresh</button>
                                        </span>
                                        <span class="createbtn">
                                            <button mat-button class="matBtn mt-3 mr-4" (click)="openCreateCampaign()"
                                                [disabled]="isCampbtnDisable">Create campaign +</button>
                                        </span>
                                        <span class="searchSpan">
                                            <mat-form-field>
                                                <input matInput #_searchValue (keyup)="applyFilter($event.target.value)" placeholder="Search">
                                                <i matSuffix class="fa fa-search searchicon" aria-hidden="true"></i>
                                            </mat-form-field>
                                        </span>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <br>

                        <div class="pDiv1">
                            <mat-table [dataSource]="dataSourceCampaign" class="cTable" matSort>
                                <ng-container matColumnDef="id">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header> ID </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="campaign_name">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header  style="flex:0 0 170px"> Campaign Name </mat-header-cell>
                                    <mat-cell *matCellDef="let element"  style="flex:0 0 170px"> {{element.campaign_name}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="template_name">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header> Template&nbsp;Name </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.template_name}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="language_code">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header> Language </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.language_code}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="scheduled_at">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header style="flex:0 0 180px"> Scheduled Date </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="flex:0 0 180px"> {{convertDTime(element.scheduled_at)}} </mat-cell>
                                </ng-container>

                                

                                <ng-container matColumnDef="created_at">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header style="flex:0 0 180px"> Created Date </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="flex:0 0 180px"> {{convertDTime(element.created_at)}} </mat-cell>
                                </ng-container>



                                <ng-container matColumnDef="status">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header> Status </mat-header-cell>
                                    <mat-cell *matCellDef="let element" [matTooltip]="element.failed_reason" [matTooltipPosition]="'left'"> <b> {{ element.status }} </b></mat-cell>
                                  
                                </ng-container>
                                <ng-container matColumnDef="data_loaded">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header>Data&nbsp;Uploaded </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.data_loaded}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="data_sent">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header> Sent </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.data_sent}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="data_failed">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header> Failed </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.data_failed}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="downloadReports">
                                    <mat-header-cell *matHeaderCellDef>Reports </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <button *ngIf="element.status=='COMPLETED' || element.status=='INPROGRESS'" style="white-space: nowrap;	background: white;color: #fd6c21;	font-size: 13px;border: 1px solid #ccc;border-radius: 4px;	padding: 5px;position:relative" class="download_btn" (click)="downloadReport(element)"> Download
                                        </button>
                                      
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef=displayedColumnsCampaigan></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsCampaigan"></mat-row>
                            </mat-table>
                            <mat-paginator #campaignpaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                            </mat-paginator>
                        </div>

                    </div>
                </div>
            </mat-card>
<br><br><br>
        </div>
    </div>
</div>
</mat-drawer-container>
<div id="cover-spin" *ngIf="currentLoader"></div>