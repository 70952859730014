<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">        
        <h2 class="d-head">Attendance</h2>
    </div> 
</div>
<form>
<div class="row pdt">
    <div class="col-md-3 col-sm-12 col-xs-12">
        <mat-form-field>
            <input matInput [matDatepicker]="picker"  [min]="todayDate" placeholder="Current date" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
    </div>
    
</div>

<div class="row pdt"> 
    <div class="col-md-6 col-sm-12 col-xs-12"></div>  
        <div class="col-md-6 col-sm-12 col-xs-12 rightside">
            <button mat-button class="cancelbtn" style="color: #e40659 !important;
            border: solid 1px #e40659 !important;"  (click)="closeDialog()">Cancel</button>&nbsp;&nbsp;
            <button mat-button class="submitbtn" style="background: #e40659 !important;
            border: 1px solid #e40659 !important;">Submit</button>
        </div>
      
        </div>
    
    </form>