<br><br>
<div class="text-center mainDiv">
    <h1> <span class="t2">Contact us</span></h1>
    <p> <span class="t2">View in Map</span>
    </p>
</div>
<div>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62224.95129333724!2d77.61370217816183!3d12.90389844441512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14f304fcd5e7%3A0x34445ad087d84293!2sSmarter%20BIZ%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1597493399340!5m2!1sen!2sin" class="mapcss" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        
</div>
<div>
    <br>
    <h4 class="text-center"> <span >Lets Get In Touch Now</span></h4>
    <mat-card class="getTouchCard">
        <br>
        <p class="msgerrorcs" [ngStyle]="{'color':msgColor}" *ngIf="showHideMsg">{{showHideErrorMsg}}</p>
        <form [formGroup]="createdForm">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="mField myfield">
                    <mat-label>First Name</mat-label>
                    <input matInput autocomplete="off"  required  formControlName="firstName" (keypress)="omit_special_char($event)">
                  </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field  class="mField myfield">
                    <mat-label>Last Name</mat-label>
                    <input matInput  autocomplete="off"   formControlName="lastName" (keypress)="omit_special_char($event)">
                  </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="mField myfield">
                    <mat-label>Your Email Adress</mat-label>
                    <input matInput  autocomplete="off"  required  formControlName="email" (change)="validateEmail();">
                  </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="mField myfield">
                    <mat-label>Which Related Problem You Are Facing?</mat-label>
                    <mat-select  formControlName="relatedProblem" required>
                      <mat-option value="General Enquiry">General Enquiry</mat-option>
                      <mat-option value="Apply for Job">Apply for Job</mat-option>
                      <mat-option value="Sales Enquiry">Sales Enquiry</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="mField myfield">
                    <mat-label>Type Your Message</mat-label>
                    <textarea  autocomplete="off"  matInput placeholder=""  formControlName="message" required></textarea>
                  </mat-form-field>
            </div>
        </div>
    
        <div class="row">
            <div class="col-md-12" style="text-align: left;">
          <p><button class="V-Btn" style="outline: none;border: none;height: 45px;padding: 10px;" (click)="submitBtn(createdForm)"  [ngClass]="!createdForm.valid ? 'cls1' : 'V-Btn'"  [disabled]="!createdForm.valid"  title="Submit Form">Submit</button></p>
            </div>
        </div>
     
    </form>
    
    </mat-card>
</div>