<div style="overflow-y: auto;height: 450px;position: relative;right: -13px;">
    <div style="position: sticky;top: 0;background: white;z-index: 3;">
    <div class="row">
        <div class="col-md-11 col-sm-10">
            <h2 style="text-align: left;"> Slot Details</h2>
        </div>
        <div class="col-md-1 col-sm-2 text-right">
            <button mat-icon-button (click)="close()">
                <span class="mat-button-wrapper">
                    <i aria-hidden="true" class="fa fa-times-circle closeicondash"></i>
                </span>
            </button>
        </div>
    </div>
    <hr>
</div>
    <br>
    <form [formGroup]="createdForm">
        <div class="row">
            <div class="col-md-3">
                <b>Date</b><br><span>{{slotsettings.date}}</span>
            </div>
            <div class="col-md-3">
                <b>Start & End Time</b><br><span>{{slotsettings.time}}</span>
            </div>
            <div class="col-md-3">
                <b>Capacity</b><br><span><input type="number" formControlName="capacity"
                        [(ngModel)]="slotsettings.capacity"  min="0" style="border: none;border-bottom: 2px solid #ccc;outline: none;"></span>
            </div>
            <div class="col-md-3">
                <b>Booked Count</b><br><span>{{slotsettings.booked}}</span>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-3">
                <b>Waiting Count</b><br><span>{{slotsettings.waiting}}</span>
            </div>
            <div class="col-md-3">
                <b>Status</b><br><span>{{slotsettings.status}}</span>
            </div>
            <div class="col-md-3">

            </div>
            <div class="col-md-3">
                <button mat-raised-button color="accent" style="align-items: center;background-color: #3fb90d !important;"
                    (click)="updateslot(createdForm)">Submit</button>
            </div>
        </div>


<br>
<div class="row">
    <div class="col-md-12 text-right">
        <p *ngIf="showHidePassingMessage" [ngStyle]="{'color':_color}" style="font-weight: bold;font-size: 20px;">
            {{passingMessage}}</p>
    </div>
</div>
</form>


<div class="row" >
    <div class="col-md-12"> 
        <form [formGroup]="updatecapcityslotForm">
               
                <table  mat-table [dataSource]="dataSource" matSort>
                  
                    <ng-container matColumnDef="agent_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                           Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{element.name}}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="agent_email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Email
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{element.email}}</p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="agent_phone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <p>Phone </p>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{element.phone}}</p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="slot_status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <p>Status </p>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{element.slot_status}}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="audited_calls">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <p> Audited Calls </p>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{ getAgentAuditData(element, 'audited_calls') }}</p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="audit_score">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <p> Audit_Score </p>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{ getAgentAuditData(element, 'avg_score') }}</p>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>
                            <p>Actions</p>
                        </th>
                        <td mat-cell *matCellDef="let element">

                            <button class="btn" mat-button color="accent" [ngClass]="element.slotstatusaction=='Book'?'classbook':'classcancel'"
                                (click)="Bookedslot(element)">{{element.slotstatusaction | uppercase}}</button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </form>

</div>
</div>
</div>











<!-- <div class="row" >
            <div class="col-md-11"> 
                <h2 style="text-align: center;"> Slot Details</h2> 
            </div>
            <div class="col-md-1">
                <button _ngcontent-neg-c315="" mat-icon-button="" (click)="close()" class="mat-focus-indicator mat-icon-button mat-button-base">
                    <span class="mat-button-wrapper">
                        <i _ngcontent-neg-c315="" aria-hidden="true" class="fa fa-times-circle closeicondash"></i>
                    </span>
                    <div class="mat-button-focus-overlay"></div>
                </button>
            </div>
            
        </div>
        <hr> -->
<!-- 
<div class="col-md-12" style="padding:0px 0px 0px 0px;margin: 0px 0px 0px 0px;">
    <form [formGroup]="createdForm">
        <div class="row">
            <div class="col-md-2">
                <p>Date</p>
            </div>
            <div class="col-md-2">
                <p>{{slotsettings.date}}</p>
            </div>

            <div class="col-md-2">
                <p>Start & End Time</p>
            </div>
            <div class="col-md-2">
                <p>{{slotsettings.time}}</p>
            </div>

            <div class="col-md-2">
                <p>Capacity</p>
            </div>
            <div class="col-md-2">
                <p><input type="number" formControlName="capacity" [(ngModel)]="slotsettings.capacity">
                </p>
            </div>

            <div class="col-md-2">
                <p>Booked Count</p>
            </div>
            <div class="col-md-2">
                <p>{{slotsettings.booked}}</p>
            </div>

            <div class="col-md-2">
                <p>Waiting Count</p>
            </div>
            <div class="col-md-2">
                <p>{{slotsettings.waiting}}</p>
            </div>

            <div class="col-md-2">
                <p>Status</p>
            </div>
            <div class="col-md-2">
                <p>{{slotsettings.status}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">

            </div>
            <div class="col-md-4">

            </div>
            <div class="col-md-4">
                <button mat-raised-button color="accent" style="align-items: center;"
                    (click)="updateslot(createdForm)">Submit</button>
            </div>
        </div>
        <div class="row">
            <p *ngIf="showHidePassingMessage" [ngStyle]="{'color':_color}" style="font-weight: bold;font-size: 20px;">
                {{passingMessage}}</p>
        </div>
    </form>
    <div class="row" style="padding-top: 10px;">
      
        <form [formGroup]="updatecapcityslotForm">
            <div class="row">
               
                <table mat-table [dataSource]="dataSource">
                  
                    <ng-container matColumnDef="agent_name">
                        <th mat-header-cell *matHeaderCellDef>
                            <p>Name </p>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{element.name}}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="agent_email">
                        <th mat-header-cell *matHeaderCellDef>
                            <p>Email. </p>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{element.email}}</p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="agent_phone">
                        <th mat-header-cell *matHeaderCellDef>
                            <p>Phone </p>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{element.phone}}</p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="slot_status">
                        <th mat-header-cell *matHeaderCellDef>
                            <p>Status </p>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{element.slot_status}}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="audited_calls">
                        <th mat-header-cell *matHeaderCellDef>
                            <p> Audited Calls </p>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{ getAgentAuditData(element, 'audited_calls') }}</p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="audit_score">
                        <th mat-header-cell *matHeaderCellDef>
                            <p> Audit_Score </p>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{ getAgentAuditData(element, 'avg_score') }}</p>
                        </td>
                    </ng-container>

                    <ng-container cdkColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>
                            <p>Actions</p>
                        </th>
                        <td mat-cell *matCellDef="let element">

                            <button class="btn" mat-button color="accent"
                                (click)="Bookedslot(element)">{{element.slotstatusaction}}</button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
          
        </form>
    </div>
</div> -->

<div id="cover-spin" *ngIf="currentLoader"></div>