import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import * as _ from 'underscore';


import { ServicesService } from '../services.service';
import { Chart } from 'angular-highcharts';
import { take } from 'rxjs/operators'


@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, AfterViewInit {

	campaignSummary: any;
	campaignSummaryChart: any;
	dashSummary: any = {
		'l1_data': {
			'total': 0,
			'selected': 0,
			'rejected': 0
		},
		'l2_data': {
			'total': 0,
			'selected': 0,
			'rejected': 0
		},
		'client_data': {
			'total': 0,
			'selected': 0,
			'rejected': 0
		},
		'training': {
			'total': 0,
			'selected': 0,
			'rejected': 0
		},
		allData: []
	}
	agentsCampaignGrp: {};
	updateCampaign: boolean = false;
	@Output() refreshCampaign:EventEmitter<any> = new EventEmitter()
	@Output() applicantDataRefresh: EventEmitter<any> = new EventEmitter();
	@Output() resetTrigger: EventEmitter<any> = new EventEmitter();
	@Input() refreshTriggered: boolean;
	
	constructor(
		private dashService: ServicesService
	) {
		this.campaignSummary = this.dashService.campaignSummary_default();
	 }

	 ngOnChanges() {
		
		if(this.refreshTriggered){
			this.updateCampaign = true;
			this.makeGrpByCampaign();
		}
	  }

	ngOnInit() {
		// debugger;
		this.campaignSummary = this.dashService.campaignSummary_default();
		
		
		this.refreshCampaign.emit(null);
		this.applicantDataRefresh.emit(null);
		this.updateCampaign = false;
		this.dashService.campaignSummaryData.subscribe(
			(d: any) => {
				
				
				this.campaignSummary = d;
				
				
				// debugger;
			}
		)
		this.dashService.dashSummaryData.subscribe(
			(d: any) => {
				if (d) {
					
					this.dashSummary = d;
					if(d.allData.length > 0){
						// this.updateCampaign = true;
						this.makeGrpByCampaign();
					}
				}
				// debugger;
			}
		)
		this.prepareBarChart();
	}
	
	makeGrpByCampaign(){
		this.agentsCampaignGrp = {};
		const allCampaigns = this.campaignSummary.allData;
		var totalSelected = 0,totalRejected = 0, totalSelectedAct = 0, totalSelectedInAct = 0,totalRejectedAct = 0, totalRejectedInAct = 0,totalSelectedL1 = 0,totalRejectedL1 = 0,totalSelectedL2 = 0,totalRejectedL2 = 0,totalSelectedClient = 0,totalRejectedClient = 0;
		for(const campaign of allCampaigns){
			for(const agent of this.dashSummary.allData){
				if(campaign.campaign == agent.campaign){
					if(agent.current_status == 'Level 1 Selected' || agent.current_status == 'Level 2 Selected'|| agent.current_status == 'Client Selected' || agent.current_status == 'Training Completed' ){
						totalSelected = totalSelected + 1;
						if(agent.current_status == 'Level 1 Selected'){
							totalSelectedL1 = totalSelectedL1 + 1;
						}
						if(agent.current_status == 'Level 2 Selected'){
							totalSelectedL2 = totalSelectedL2 + 1;
						}
						if(agent.current_status == 'Client Selected'){
							totalSelectedClient = totalSelectedClient + 1;
						}
						if(campaign.active_status == 1){
							totalSelectedAct = totalSelectedAct + 1;
						}else{
							totalSelectedInAct = totalSelectedInAct + 1;
						}
					}else if(agent.current_status == 'Level 1 Rejected' || agent.current_status == 'Level 2 Rejected'|| agent.current_status == 'Client Rejected' || agent.current_status == 'Training Rejected' ){
						totalRejected = totalRejected + 1;
						if(agent.current_status == 'Level 1 Rejected'){
							totalRejectedL1 = totalRejectedL1 + 1;
						}
						if(agent.current_status == 'Level 2 Rejected'){
							totalRejectedL2 = totalRejectedL2 + 1;
						}
						if(agent.current_status == 'Client Rejected'){
							totalRejectedClient = totalRejectedClient + 1;
						}
						if(campaign.active_status == 1){
							totalRejectedAct = totalRejectedAct + 1;
						}else{
							totalRejectedInAct = totalRejectedInAct + 1;
						}
					}
				}
				
			}
		}
		this.campaignSummary.summary.data_selected = totalSelected;
		this.campaignSummary.summary.data_selected_perc = this.calculatePercBy(totalSelected,this.dashSummary.allData.length);
		this.campaignSummary.summary.data_rejected = totalRejected;
		this.campaignSummary.summary.data_rejected_perc = this.calculatePercBy(totalRejected,this.dashSummary.allData.length);
		
		this.campaignSummary.campaigns_active.data_selected = totalSelectedAct;
		this.campaignSummary.campaigns_active.data_selected_perc = this.calculatePercBy(totalSelectedAct,this.dashSummary.allData.length);
		this.campaignSummary.campaigns_active.data_rejected = totalRejectedAct;
		this.campaignSummary.campaigns_active.data_rejected_perc = this.calculatePercBy(totalRejectedAct,this.dashSummary.allData.length);

		this.campaignSummary.campaigns_inactive.data_selected = totalSelectedInAct;
		this.campaignSummary.campaigns_inactive.data_selected_perc = this.calculatePercBy(totalSelectedInAct,this.dashSummary.allData.length);
		this.campaignSummary.campaigns_inactive.data_rejected = totalRejectedInAct;
		this.campaignSummary.campaigns_inactive.data_rejected_perc = this.calculatePercBy(totalRejectedInAct,this.dashSummary.allData.length);

		this.campaignSummary.summary.data_selected_l1 = totalSelectedL1;
		this.campaignSummary.summary.data_selected_l1_perc = this.calculatePercBy(totalSelectedL1,totalSelectedL1+totalRejectedL1);
		this.campaignSummary.summary.data_rejected_l1 = totalRejectedL1;
		this.campaignSummary.summary.data_rejected_l1_perc = this.calculatePercBy(totalRejectedL1,totalSelectedL1+totalRejectedL1);

		this.campaignSummary.summary.data_selected_l2 = totalSelectedL2;
		this.campaignSummary.summary.data_selected_l2_perc = this.calculatePercBy(totalSelectedL2,totalSelectedL2+totalRejectedL2);
		this.campaignSummary.summary.data_rejected_l2 = totalRejectedL2;
		this.campaignSummary.summary.data_rejected_l2_perc = this.calculatePercBy(totalRejectedL2,totalSelectedL2+totalRejectedL2);

		this.campaignSummary.summary.data_selected_client = totalSelectedClient;
		this.campaignSummary.summary.data_selected_client_perc = this.calculatePercBy(totalSelectedClient,totalSelectedClient+totalRejectedClient);
		this.campaignSummary.summary.data_rejected_client = totalRejectedClient;
		this.campaignSummary.summary.data_rejected_client_perc = this.calculatePercBy(totalRejectedClient,totalSelectedClient+totalRejectedClient);

		
		if(this.updateCampaign){
			
			if(this.refreshTriggered == true){
				this.resetTrigger.emit(null);
			}
			

			this.dashService.updateCampaignSummary(this.campaignSummary)

		}
		
	}

	calculatePercBy(num1,num2){
		if(num1 == 0 && num2 == 0){
			return "0.0";
		}
		var value: any = (num1/num2)*100;
		value = value.toFixed(1);
		return value
	}

	ngAfterViewInit() {
		// debugger;
		this.campaignSummary = this.dashService.campaignSummary_default();
		this.dashService.campaignSummaryData.subscribe(
			(d: any) => {
				this.campaignSummary = d;
				// debugger;
			}
		)
	}

	getAgencyData(obj: any) {
		let loc_obj = {};
		if (obj) {
			let keys = _.keys(obj);
			for (var i = 0; i < 3; i++){
				loc_obj[keys[i]] = obj[keys[i]]
			}
		}
		return loc_obj;
	}

	prepareBarChart(){
		let chartdata = new Chart({
			title: {
				text: ''
			},
			credits: {
				enabled: false
			},
			plotOptions: {
				pie: {
					cursor: 'pointer',
					dataLabels: {
						distance: -30,
						enabled: true,
						color: 'white',
						style: {
							fontWeight: '500',
						}
					},
					showInLegend: false,
					borderWidth: 5,
				},
			},

			series: [
				{
					states: {
						inactive: {
							opacity: 1
						},
						hover: {
							enabled: false
						}
					},
					name: 'Active',
					data: [
						
						{
							name: '58%',
							y: 58, color: '#606d35'
						}, {
							name: '20%',
							y: 28, color: '#ff6619'
						},
						{
							name: '32%',
							y: 32, color: '#fbbf29'
						}],
					type: 'pie',
					
					innerSize: '40%',
				}]
		});
		this.campaignSummaryChart = chartdata;
	}
}
