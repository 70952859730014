import { Component, OnInit,ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-dashboard-batchonlive-table',
  templateUrl: './dashboard-batchonlive-table.component.html',
  styleUrls: ['./dashboard-batchonlive-table.component.scss']
})
export class DashboardBatchonliveTableComponent implements OnInit {
  displayedColumns = ['live', 'id', 'end_date', 'name'];
  dataSource:any = new MatTableDataSource(); 
  api_end_point:any;
  constructor(private API: ApiService,private myService: MyserviceService) { 
    }

  ngOnInit() {

    this.getTabledata();
	this.api_end_point = this.myService.get_API_End_Point();
  }
  getTabledata(){
    this.API.getTrainingDashboard(this.api_end_point).subscribe((data:any)=>{
      let _response=data.success;
      if(_response){
        if(data.success["Batch Data"]){
        let elementData=_response["Batch Data"];
        this.dataSource = new MatTableDataSource(elementData);
        this.dataSource.filterPredicate = function(data, filter: string): boolean {
          return data.id.toString().toLowerCase().includes(filter) || data.end_date.toString().toLowerCase().includes(filter) || data.name.toString().toLowerCase().includes(filter)
        };
        }
      }
    }
    )
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
