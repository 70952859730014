<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu>
	</mat-drawer>
	<br><br><br><br>
	<div class="v-mainDetailsDiv">
		<mat-card>

			<div>
				<form [formGroup]="campaignForm">
					<div class="row">
						<div class="col-md-6 mb-2 pt-1">
							<h4 class="titlehead vThdx1" style="position: relative;top: 0px;z-index: 9;">Create
								Campaigns</h4>
						</div>
						<div class="col-md-6 v-lftRyt mb-2">
							<span class="v-back-cd" (click)="backToCampaignDashboard()">
								<mat-icon class="mr-2 v-back-ico">arrow_left</mat-icon>Back to Campaign Dashboard
							</span>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-4">
							<div class="input-label-group">
								<!--  <input matInput autocomplete="off" maxlength="100"></p> -->
								<label>Campaign Name</label>
								<p>
									<input matInput autocomplete="off" formControlName="name"
										[ngClass]="{'is-invalid': _form.name.errors}"
										(keypress)="omit_special_char($event)">
								</p>
								<div *ngIf="_form.name.errors" class="invalid-feedback">
									<div *ngIf="_form.name.errors.required">Campaign name is required</div>
								</div>

							</div>
						</div>
						<div class="col-md-4">
							<div class="input-label-group">
								<label>Master Campaign</label>
								<!-- <p> <input matInput autocomplete="off" maxlength="100"></p> -->
								<p *ngIf="campaign_type_field.input == 'FT'">
									<input matInput formControlName="campaign_type"
										[ngClass]="{'is-invalid': _form.campaign_type.errors}"
										(keypress)="omit_special_char($event)">
								</p>
								<!-- <div *ngIf="_form.campaign_type.errors" class="invalid-feedback">
								<div *ngIf="_form.campaign_type.errors.required">Put default as others</div>
							</div>
						<p *ngIf="campaign_type_field.input == 'DD'">
							<mat-select formControlName="campaign_type"
								[ngClass]="{'is-invalid': _form.campaign_type.errors}">
								<mat-option *ngFor="let val of campaign_type_field.values" [value]="val">
									{{val}}
								</mat-option>
							</mat-select>
						</p> -->
								<div *ngIf="_form.campaign_type.errors" class="invalid-feedback">
									<div *ngIf="_form.campaign_type.errors.required">Select atleast one campaign_type
									</div>
								</div>

							</div>
						</div>
						<div class="col-md-4">
							<div class="input-label-group">
								<label>Select Form</label>
								<!-- <p>
							<mat-select placeholder="Select A Product">
								<mat-option value="Gignex">Gignex</mat-option>
							</mat-select> 
						</p>-->
								<p>
									<mat-select formControlName="screenname" placeholder="Select Form">
										<mat-option [value]="v.definition" *ngFor="let v of incallscreen.Incallscreen">
											{{v.name}}</mat-option>
									</mat-select>
								</p>
							</div>
						</div>
					</div>

					<div class="row mt-3">
						<div class="col-md-4">
							<div class="input-label-group">
								<label>Campaign Date</label>
								<!-- <p>
							<input matInput [matDatepicker]="pickerDate" autocomplete="off" readonly
								(click)="pickerDate.open()">
							<mat-datepicker-toggle matSuffix [for]="pickerDate" class="dateCalIcon"
								style="color: darkgray;"> </mat-datepicker-toggle>
							<mat-datepicker #pickerDate></mat-datepicker>
						</p> -->
								<p>
									<input matInput [matDatepicker]="picker" [matDatepickerFilter]="hidePreviousDates"
										readonly (click)="picker.open()" formControlName="date">
									<mat-datepicker-toggle matSuffix [for]="picker" class="dateCalIcon"
										style="color: darkgray;"> </mat-datepicker-toggle>
									<mat-datepicker #picker></mat-datepicker>
								</p>
							</div>
						</div>
						<div class="col-md-4">
							<div class="input-label-group">
								<label>Time</label>
								<!-- <p>
							<input matInput type="time" autocomplete="off" maxlength="10">
						</p> -->
								<p><input type="time" matInput placeholder="Time" value="00.00" min="00:00" max="24:00"
										formControlName="time" [ngClass]="{'is-invalid': _form.time.errors}" required>
								</p>
								<div *ngIf="_form.time.errors" class="invalid-feedback">
									<div *ngIf="_form.time.errors.timeError">{{ _form.time.errors.timeError }}</div>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="input-label-group">
								<label>Upload Data
									
									<span class="v-download-cd ml-2" (click)="download_file()">
										<!-- <a href="{{templateddownloadurl}}" target="_blank">
										Data Upload Sheet.xlsx
									</a> -->
									Data Upload Sheet.xlsx
									</span>
								</label>
								<div class="fileVinput">
									<span style="position: relative;top: 5px;color: darkgray;" *ngIf="false">Supports
										only xlsx file</span>
									<span style="position: relative;top: 5px;color: darkgray;font-size: 8px;"
										*ngIf="currentFileobj.name?true:false">{{currentFileobj.name}}</span>
									<div class="uploadWrapper" style="float: right;bottom: 1px;">

										<button class="btnBrowse">Browse</button>
										<input type="file" name="uploadfile" formControlName="file"
											(change)="changeFile($event)" />
										<!-- <button class="btnbrowse">Browse</button>
									<input type="file" #fileUpload class="filecs" formControlName="file"
										(change)="changeFile($event)" />-->

									</div>
								</div>
								<!-- <p><span>{{currentFileobj.name}}</span></p>	 -->
							</div>
						</div>
					</div>

					<div class="row mt-3">
						<div class="col-md-4">
							<div class="input-label-group">
								<label>Work Mode
								</label>
								<div>
									<select class="form-control" id="pd_mode_selection" formControlName="campaign_mode">
										<!--*ngIf='col.name == "Predictive"'-->
										<option *ngFor="let dr of campaign_work_mode" [ngValue]="dr">{{ dr }}</option>
									</select>
								</div>
							</div>
						</div>
						
						<!-- <div class="col-md-3">
						</div> -->

						<div class="col-md-8">
							<div class="input-label-group">
								<label>Dial Mode</label>
								<div class="radioVinput">
									<mat-radio-group style="display: inline-block;" aria-labelledby="dial_mode"
										class="v-matRadioGp" formControlName="priority">
										<mat-radio-button class="dial-radio-button"
											*ngFor="let col of dial_mode.dial_modes" [value]="col.name">{{col.name}}
											<div style="display: inline-block;">
												<select class="form-control" id="pd_mode_selection"
													(change)="setDialRatio($event.target.value)"
													*ngIf="col.priority == 2 && col.dial_ratio.dd_values"
													style="width:90px;">
													<!--*ngIf='col.name == "Predictive"'-->
													<option *ngFor="let dr of col.dial_ratio.dd_values" [ngValue]="dr">
														{{ dr }}</option>
												</select>
											</div>
										</mat-radio-button>
									</mat-radio-group>
								</div>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-12">
							<div class="input-label-group">
								<label>Select Agent</label>
							</div>
						</div>
					</div>
					<br>
				</form>
				<div class="row">
					<div class="col-md-12">
						<!-- Master Checkbox -->
						<ul class="list-group">
							<li class="list-group-item" style="background: #ff894cfa;color: #fff;">
								<table class="parentTable1">
									<tr>
										<td style="width:55px;">
											<span (click)="expandCollapseAll(_data)">
												<i class="fas fa-angle-up"
													*ngIf="_data.isAllCollapsed;else isCollapsed"></i>
												<ng-template #isCollapsed><i class="fas fa-angle-down"></i>
												</ng-template>
											</span>

										</td>
										<td style="width:220px;"> Center Name </td>
										<td style="width:250px;"> RSM </td>
										<td> TEAM SIZE</td>
										<td> Selected Count</td>
										<td style="text-align: right;">
											<span (click)="selectUnselectAll(_data)" style="cursor: pointer;">
												<span *ngIf="_data.isAllSelected;else noneSelected">Unselect All </span>
												<ng-template #noneSelected>Select All </ng-template>
											</span>
										</td>
									</tr>
								</table>
							</li>
						</ul>

						<ul class="list-group">
							<!-- Parent Item -->
							<li class="list-group-item" *ngFor="let item of _data.ParentChildchecklist">
								<table class="parentTable1">
									<tr>
										<td style="width:55px;">
											<!-- Parent Arrow -->
											<span (click)="expandCollapse(item)">
												<i class="fas fa-angle-up" *ngIf="item.isClosed;else isCollapsed"></i>
												<ng-template #isCollapsed>
													<i class="fas fa-angle-down"></i>
												</ng-template>
											</span>
										</td>
										<td style="width:220px;">
											{{item.center_name}}
										</td>
										<td style="width:250px;">
											{{item.rsm}}
										</td>
										<td style="width:150px;">
											{{item.teamSize}}
										</td>
										<td style="width:150px;">
											{{item.total}}
										</td>
										<td style="text-align: right;right: 21px;position: relative;">
											<!-- Parent Checkbox -->
											<mat-checkbox [(ngModel)]="item.isSelected" name="list_name"
												value="{{item.id}}" (ngModelChange)="parentCheck(item,$event)">
											</mat-checkbox>
											<!----formControlName="parentname"---(change)="parentCheck(item)"-->
										</td>
									</tr>
								</table>


								<div class="child-list" [hidden]="item.isClosed">


									<div class="input-label-group mt-0">
										<p><input matInput autocomplete="off" [(ngModel)]="searchedKeyword"
												placeholder="Search.." style="min-width: 300px;"></p>
									</div>

									<ul class="list-group level-two">
										<!-- Child Item -->
										<li class="list-group-item level-two"
											*ngFor="let itemChild of item.childList | filter:searchedKeyword ">
											<table class="parentTable2">
												<tr>
													<td style="width:200px">
														{{itemChild.name}}
													</td>
													<td matTooltip="{{itemChild.active_campaign}}">
													 {{itemChild.active_campaign | slice:0:40}}
													 <b *ngIf="itemChild.active_campaign && itemChild.active_campaign !=null">...</b>
													</td>
													<td></td>
													<td></td>
													<td class="text-right">
														<!-- Child Checkbox -->
														<mat-checkbox [(ngModel)]="itemChild.isSelected"
															name="list_name_child" value="{{itemChild.id}}"
															(ngModelChange)="childCheck(item,itemChild,$event)">
															<!---formControlName="childname"----- --(change)="childCheck(item,item.childList)"-->
														</mat-checkbox>

													</td>
												</tr>
											</table>

										</li>

									</ul>

								</div>
							</li>
						</ul>

					</div>
					<!-- <div class="col-md-12">
				{{stringify(_data)}}
			</div> -->
				</div>

				<div style="display: flex;flex-wrap: wrap;justify-content: flex-end;">

					<!-- <div style="width:185px; margin-top: 7px;">
					<p>Total Agent Selected: </p>
				</div> -->
					<div style="width:185px">
						<button class="vbtnxk" style="color: #8994a1;" (click)="cancelBtn()">CANCEL</button>
					</div>
					<div style="width:185px; margin-top: 7px;">
						<!-- <button class="vbtnxk"> SUBMIT </button> -->
						<button class="btnAssign" (click)="submit_Camapign()">Assign Now</button>
						<div class="vk-dropdown">
							<button class="btnLater" style="border-left:1px solid #ccc !important">
								<i class="fa fa-caret-down"></i>
							</button>
							<div class="vk-dropdown-content">
								<button class="btnLater1" (click)="assign_later()">Assign
									Later</button>
							</div>
						</div>
					</div>
				</div>
				<br><br>
			</div>

		</mat-card>
	</div>
</mat-drawer-container>
<div id="cover-spin" *ngIf="showLoader"></div>