import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-payment-dashboard-upload-dialog',
	templateUrl: './payment-dashboard-upload-dialog.component.html',
	styleUrls: ['./payment-dashboard-upload-dialog.component.scss']
})
export class PaymentDashboardUploadDialogComponent implements OnInit {

	api_end_point:any;
	constructor(
		public dialogRef: MatDialogRef<PaymentDashboardUploadDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public viewData,
		public formbuilder: FormBuilder,
		private API: ApiService, private _snackBar: MatSnackBar,
		private myService: MyserviceService
	) { }

	file = null;
	uploadForm: FormGroup;
	access_token = this.myService.get_access_token();
	uploadTypes = [
		{value: 'sales', name: 'Sales'},
		{value: 'call_and_qualityscore', name: 'Call Quality Score'}
	];
	selectedUploadType = this.uploadTypes[0]['value'];

	ngOnInit() {
		this.api_end_point = this.myService.get_API_End_Point();
		this.uploadForm = this.formbuilder.group({
			file: [''],
			amount_perevent: ['']
		})
	}
	closeDialog() {
		this.dialogRef.close();
	}
	saveFile() {
		//console.log('file', this.uploadForm.value);
		if (!this.file) {
		//	alert('Please upload the file');
			this._snackBar.open('Please upload the file', 'close', {
				duration: 4000,
			});
			return;
		}
		var formData = new FormData();
		formData.append('attachment', this.file);
		formData.append('event_type', this.selectedUploadType);
		if (this.uploadForm.value.amount_perevent && this.uploadForm.value.amount_perevent.length) {
			formData.append('amount_perevent', this.uploadForm.value.amount_perevent);
		}
		this.API.uploadPaymentExcel(formData,this.api_end_point).subscribe((res:any) => {
			if (res.success) {
				//alert(res.success);
				this._snackBar.open(res.success, 'close', {
					duration: 4000,
				});
				this.dialogRef.close();
			} else {
			//	alert(res.errors);
				this._snackBar.open(res.errors, 'close', {
					duration: 4000,
				});
			}
		})
		// uploadPaymentExcel(formData: FormData)
		// 
  	}
	changeFile(files: File[]) {
		// debugger;
		// this.uploadForm.controls['file'].reset();
		//console.log('event targe files', files);
		// debugger;
		if (files && files.length) {
			// debugger;
			let filePath = files[0].name;
			// debugger;
			let allowedExtensions = /(\.xls|\.xlsx)$/i;
			if (!allowedExtensions.exec(filePath)) {
			//	alert('Please upload file having extensions .xls/.xlsx only.');
				this._snackBar.open('Please upload file having extensions .xls/.xlsx only.', 'close', {
					duration: 4000,
				});
				return false;
			}
			// debugger;
			this.file = files[0];
			// this.uploadForm.get('file').patchValue(this.file.name);
		} else {
			console.log('no files');
		}
	}
	onlyNumberValidation(evt){
		var charCode = (evt.which) ? evt.which : evt.keyCode;
				if (charCode != 46 && charCode > 31 
				  && (charCode < 48 || charCode > 57))
				   return false;

				   
				return true;
	  }

	  decimalFilter(event: any) {
		const reg = /^-?\d*(\.\d{0,2})?$/;
		let input = event.target.value + String.fromCharCode(event.charCode);
	 
		if (!reg.test(input)) {
			event.preventDefault();
		}
	 }

}
