import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'underscore';


import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';
import { APIRESPONSE } from '../../interface';
import { GenericOutboundService } from '../../generic/generic-outbound/generic-outbound.service';

import { Utils } from '../../utils';

class TLsummaryCounts {
	private _input_data = [];
	private agents_count: number = 0;
	public dialled: number = 0;
	public unique_dialled: number = 0;
	public contacted: number = 0;
	public unique_contacted: number = 0;
	public rnr: number = 0;
	public forms: number = 0;
	public active_time_sec: number = 0;
	public wait_time_sec: number = 0;
	public wrap_time_sec: number = 0;
	public idle_time_sec: number = 0;
	public spoken_time_sec: number = 0;
	public login_time_sec: number = 0;
	public break_time_sec: number = 0;
	public unavailable_time_sec: number = 0;
	public aht_pulse: number = 0;
	public audit_score: number | string = 0;
	public ctc_score: number | string = 0;
	constructor(agents_data) {
		// console.log('tlcounts class input', agents_data);
		if (agents_data && agents_data.length) {
			this._input_data = agents_data
			this.agents_count = agents_data.length
			this.dialled = this.sum_allnumbers('dialled');
			this.unique_dialled = this.sum_allnumbers('unique_dialled');
			this.contacted = this.sum_allnumbers('contacted');
			this.unique_contacted = this.sum_allnumbers('unique_contacted');
			this.rnr = this.sum_allnumbers('rnr');
			this.forms = this.sum_allnumbers('forms');
			this.active_time_sec = this.sum_allnumbers('active_time');
			this.wait_time_sec = this.sum_allnumbers('wait_time');
			this.wrap_time_sec = this.sum_allnumbers('wrap_time');
			this.idle_time_sec = this.sum_allnumbers('idle_time');
			this.spoken_time_sec = this.sum_allnumbers('spoken_time');
			this.login_time_sec = this.sum_allnumbers('login_time');
			this.break_time_sec = this.sum_allnumbers('break_time');
			this.unavailable_time_sec = this.sum_allnumbers('unavailable_time');
			this.aht_pulse = this.sum_allnumbers('aht');
			this.audit_score = this.sum_allnumbers('audit_score');
			this.ctc_score = this.sum_allnumbers('ctc_score');
		}
	}

	sum_allnumbers(key:string) {
		let s = 0;
		for (var i in this._input_data) {
			if (this._input_data[i].hasOwnProperty(key)) {
				if (!isNaN(this._input_data[i][key])) {
					s += parseInt(this._input_data[i][key])
				}
			}
		}
		return s;
	}
}

@Component({
	selector: 'app-tl-table-counts',
	templateUrl: './tl-table-counts.component.html',
	styleUrls: ['./tl-table-counts.component.scss']
})
export class TlTableCountsComponent implements OnInit, OnChanges, OnDestroy {

	@Input() table_data;

	supervisorData: object = null;

	table_raw_data = null;

	tl_sourceCallSummary: any = new MatTableDataSource();
	api_end_point = this.myService.get_API_End_Point();
	tl_dataColumnsCallSummary = [
		'supervisor',
		'agents',
		'dialled',
		'unique_dialled',
		'contacted',
		'unique_contacted',
		'rnr',
		'forms',
		'aht_pulse'
	];

	constructor(private myService: MyserviceService, private API: ApiService, private outbound_service: GenericOutboundService) { }
	public dialled: number;
	public unique_dialled: number;
	public contacted: number;
	public unique_contacted: number;
	public rnr: number;
	public forms: number;
	public active_time_sec: number;
	public wait_time_sec: number;
	public wrap_time_sec: number;
	public idle_time_sec: number;
	public spoken_time_sec: number;
	public audit_score: string | number;
	public ctc_audit: string | number;


	ngOnInit(): void {
		// console.log('onInit', this.table_data);
		this.getSuperisorDetails()
	}

	ngOnChanges() {
		// console.log('on changes', this.table_data.data.subs);
		// this.table_data.data.subscribe(data=> {
		// 	this.table_raw_data = data;
		// 	console.log('on changes', JSON.stringify(this.table_raw_data));
			
		// })
		this.outbound_service.agent_table_data.subscribe(val=>{
			this.table_raw_data = val.agent_data;
			this.initiateSummary();
		})
		/* if (this.table_data && this.table_data.data && this.table_data.data.length) {
			this.table_raw_data = this.table_data.data;
			console.log('on changes', JSON.stringify(this.table_raw_data));
			this.initiateSummary();
		} */
	}


	getSuperisorDetails() {
		this.API.getSupervisors(this.api_end_point).subscribe((res: APIRESPONSE) => {
			if (res.errors) {
				console.log('Error in the API response', res.errors)
			}
			if (res.success) {
				this.supervisorData = res.success;
				// this.get_supervisor_object(res.success);
			}
		})
	}

	get_supervisor_object(supervisor_res){
		let obj = {}
		if(supervisor_res){
			if(this.table_raw_data && this.table_raw_data.length){
				const table_data_grp = _.groupBy(this.table_raw_data, 'user_id');
				// console.log('table_data_grp', table_data_grp);
				for(var i in table_data_grp){
					if(supervisor_res.hasOwnProperty(i)){
						obj[i] = supervisor_res[i];
					}
				}
				return obj;
			}
		}
		return obj
	}

	initiateSummary() {
		let superviosrObject = this.groupBySupervisor();
		// console.log('supervisorObject', superviosrObject);
		let p = [];
		for (var i in superviosrObject) {
			let summary = this.getSummary(superviosrObject[i]['data']);
			let obj = {
				'supervisor': superviosrObject[i]['name'],
				'agents': summary['agents_count'],
				'dialled': summary['dialled'],
				'unique_dialled': summary['unique_dialled'],
				'contacted': summary['contacted'],
				'unique_contacted': summary['unique_contacted'],
				'rnr': summary['rnr'],
				'forms': summary['forms'],
				'aht_pulse': summary['aht_pulse']
			}
			p.push(obj);
		}
		this.tl_sourceCallSummary = new MatTableDataSource(p)
		// console.log('p summary', p);
	}

	getSummary(data) {
		// console.log('input summary array', data);
		let p = new TLsummaryCounts(data)
		return p;
	}


	groupBySupervisor() {
		// console.log('supervisor list', this.supervisorData);
		let p = this.get_supervisor_object(this.supervisorData);
		// console.log('p', p)
		// console.log('table raw data', this.table_raw_data)
		// debugger;
		let supervisorGroup = {};
		// console.log('printing table raw data', this.table_raw_data);
		for (var i in p) {
			if (p[i]) {
				// console.log('p[i]', i, p[i]);
				if (!supervisorGroup.hasOwnProperty(p[i]['user_id'])) {
					supervisorGroup[p[i]['user_id']] = p[i]
					supervisorGroup[p[i]['user_id']]['data'] = [];
				}
				if (this.table_raw_data) {
					// console.log(i)
					// let agent_data = _.findWhere(this.table_raw_data, { "user_id": Number(i)});
					const agent_data = this.table_raw_data.filter(agent => {
						return agent.user_id == Number(i)
					})
					// console.log('Agent data', agent_data);
					if (agent_data.length) {
						supervisorGroup[p[i]['user_id']]['data'].push(agent_data[0])
					}
				}
			}
			// debugger;
		}
		return supervisorGroup;
	}
	ngOnDestroy(): void {
		this.outbound_service.agent_table_data.unsubscribe();
	}

}
