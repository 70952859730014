import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Utils } from '../../utils';
import { ApiService } from '../../api.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { MatInput} from '@angular/material/input';
import { MyserviceService } from '../../myservice.service';

@Component({
  selector: 'app-auditor-reports',
  templateUrl: './auditor-reports.component.html',
  styleUrls: ['./auditor-reports.component.scss']
})
export class AuditorReportsComponent implements OnInit {
  displayedColumns = ['report_name', 'type', 'created_at', 'download'];
  dataSource = new MatTableDataSource();
  startDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false,moment().subtract(7,'d'));
  endDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
  selectedReport: string = "Project";
  myUser: any;
  api_end_point:any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(' fromInput', {
		read: MatInput
    })  fromInput: MatInput;
    
  constructor(public router: Router,private utils: Utils,private API: ApiService, private service: MyserviceService) { }

  ngOnInit() {
    this.myUser = this.service.get_loggedin_user();
	this.api_end_point = this.service.get_API_End_Point();
    this.getReportList();
  }
  getReportList() {
    this.API.getAuditReportGeneratedList(this.myUser.id).subscribe(
			(res: any) => {
				if(res.success){
          this.dataSource = new MatTableDataSource(res.success);
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = function (data: any, filter: any): boolean {
            return data.report_name.toString().toLowerCase().includes(filter) || data.created_at.toString().toLowerCase().includes(filter)
    };
        }
				
			}, (err) => {
				// debugger;
			}
		)
    // let ELEMENT_DATA = [
    //   { report: 'fle_name1', type: 'Project', created: '20/09/2020', download: 'Inprogress' },
    //   { report: 'fle_name1', type: 'Project', created: '20/09/2020', download: 'Inprogress' },
    //   { report: 'fle_name1', type: 'Project', created: '20/09/2020', download: '' }
    // ];
    
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  change_date_report(key: string, event: MatDatepickerInputEvent<Date>) {
		if (key == 'start_date') {
			this.startDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.startDate).diff(moment(this.endDate)) >= 0) {
				this.endDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			}
		} else {
			this.endDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.startDate).diff(moment(this.endDate)) >= 0) {
				this.endDate = undefined;
				this.fromInput.value = '';
				alert("Start date should be less than end date");
			} else {

			}

		}
  }

  downloadReportFromUrl(url){
    this.API.downloadReportFromUrl(url,null,this.api_end_point).then(
						() => {
							// campaign_data.showLoader = false;
						}
					)
  }
  
  downloadReport(){
	  //console.log(this.startDate, this.endDate)
	  if(this.startDate == undefined || this.endDate == undefined){
		  return;
	  }else{
		switch (this.selectedReport){
			case "Project":
			  this.downloadReportProject();
			  break
			case "Auditor":
			  this.downloadReportAuditor();
			  break
			case "Project/Auditor":
			  this.downloadReportCombine();
			  break
			case "Costing":
			  this.downloadReportAuditorEarnings();
			  break
			default:
			  break;
		  }
	  }
    
  }

  downloadReportProject() {
		var current_date = new Date();
		/*end_date = new Date().toISOString();*/
		var obj: any = {};
		obj.start_date = this.utils.localToUTC(this.startDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.endDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		obj.download = true;
		obj.production_group = this.myUser.id;
		obj.type = this.selectedReport;
    
		this.API.downloadAuditProjectReport(obj).subscribe(
			(res: any) => {
				// debugger;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
						() => {
							// campaign_data.showLoader = false;
						}
					)
				} else {
					alert(res.errors)
				}
			}, (err) => {
				// debugger;
			}
		)
  }
  
   downloadReportAuditor() {
		var current_date = new Date();
		/*end_date = new Date().toISOString();*/
		var obj: any = {};
		obj.start_date = this.utils.localToUTC(this.startDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.endDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		obj.download = true;
		obj.production_group = this.myUser.id;
		obj.type = this.selectedReport;
    
		this.API.downloadAuditAuditorReport(obj).subscribe(
			(res: any) => {
				// debugger;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
						() => {
							// campaign_data.showLoader = false;
						}
					)
				} else {
					alert(res.errors)
				}
			}, (err) => {
				// debugger;
			}
		)
  }
  
  downloadReportCombine() {
		var current_date = new Date();
		/*end_date = new Date().toISOString();*/
		var obj: any = {};
		obj.start_date = this.utils.localToUTC(this.startDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.endDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		obj.download = true;
		obj.production_group = this.myUser.id;
		obj.type = this.selectedReport;
    
		this.API.downloadAuditCombineReport(obj).subscribe(
			(res: any) => {
				// debugger;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
						() => {
							// campaign_data.showLoader = false;
						}
					)
				} else {
					alert(res.errors)
				}
			}, (err) => {
				// debugger;
			}
		)
  }
  
   downloadReportAuditorEarnings() {
		var current_date = new Date();
		/*end_date = new Date().toISOString();*/
		var obj: any = {};
		obj.start_date = this.utils.localToUTC(this.startDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.endDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		obj.download = true;
		obj.production_group = this.myUser.id;
		obj.type = this.selectedReport;
    
		this.API.downloadAuditEarningsReport(obj).subscribe(
			(res: any) => {
				// debugger;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
						() => {
							// campaign_data.showLoader = false;
						}
					)
				} else {
					alert(res.errors)
				}
			}, (err) => {
				// debugger;
			}
		)
  }

}

