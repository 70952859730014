<mat-card>
    <form [formGroup]="createdForm">
    <div class="row">
        <div class="col-md-6 col-sm-12">
<img src="../assets/loginImage.png" width="100%"/>
        </div>
        <div class="col-md-5  col-sm-12" style="margin: 4% 0 0 25px;">
            <h1 class="login-head" style="margin-bottom: 2px;">Login</h1>
            <p style="color: gray;font-family: 'Poppins';font-size: 16px;">Don’t have an account ?
                <span class="CR" (click)="createAccount()" style="color:#e40659;font-weight:500">Create your account </span></p>
            <br>
            <table class="wd-100">
                <tr>
                    <td colspan="2">
                        <mat-form-field style="width: 73%;">
                            <input matInput autocomplete="off"  placeholder="Username"  formControlName="email" autofocus>
                            <mat-icon style="color: #cbcbcb;font-size: 33px;position: relative;top: 9px;" matSuffix>person</mat-icon>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td colspan="2"> <br></td>
                </tr>
                <tr>
                    <td colspan="2">
                        <mat-form-field style="width: 73%;">
                            <input matInput type="password" autocomplete="off" placeholder="Password"  formControlName="password">
                            <mat-icon style="color: #cbcbcb;font-size: 28px;position: relative;top: 6px;" matSuffix>lock</mat-icon>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-checkbox class="m-box"> &nbsp;Remember Me</mat-checkbox>
                    </td>
                    <td>
                        <span class="CR pl-2" style="color: #e40659;font-weight: 500;font-size: 14px;position: relative;right: 17px;">Forgot Password? </span>
                    </td>
                </tr>
            </table>
            <br>  <br>  <br><br>
            <div class="row">
                <div class="col-md-1 col-sm-12"></div>
                <div class="col-md-6 col-sm-12 text-center">
                    <button class="JoinSubmit" (click)="loginBtn(createdForm)">LOGIN</button>
                </div>
                <div class="col-md-5 col-sm-12"></div>
            </div>
        </div>
        <div class="col-md-1 col-sm-12">

        </div>
    </div>
</form>
</mat-card>