import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';

@Component({
  selector: 'app-trainer-info-dialog',
  templateUrl: './trainer-info-dialog.component.html',
  styleUrls: ['./trainer-info-dialog.component.scss']
})
export class TrainerInfoDialogComponent implements OnInit {
  showHidePopupDelete:boolean=false;
  createdForm:FormGroup;
  isreadonly:any=true;
  constructor(public dialogRef: MatDialogRef<TrainerInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewData,public formbuilder:FormBuilder) {
    this.createdForm = this.formbuilder.group({
      name:[''],
      username:[''],
      rating:[''],
      currentBatch:[''],
      batchType:[''],
      lastUpdateOn:[''],
      aadharNumber:[''],
      location:['']
    })
   }

  ngOnInit() {
    if(this.viewData){
      this.createdForm =  this.formbuilder.group({
        name: [this.viewData.name],
        username: [this.viewData.email],
        rating: [this.viewData.rating.toFixed(1)],
        currentBatch: [this.viewData.batch_code],
        batchType: [this.viewData.batch_type],
        lastUpdateOn: [this.viewData.created_at],
        aadharNumber: [this.viewData.adhaar_number],
        location: [this.viewData.location]
     })
    }
  }
  closeDialog(){
    this.dialogRef.close();
  }
 
  openWarningMessage(){
this.showHidePopupDelete=true;
}
closedeletePopup(){
this.showHidePopupDelete=false;
}
}

