
<div class="row mt-2" *ngIf="showHideDashboard">            
    <div class="col-md-12 col-sm-12 col-xs-12 text-left">


        <ul class="flexcontainer">
            <li class="flexitem">
               <mat-card (click)="clickPortalMenu('crcCall')">
                <img src="../assets/crcImg.png"><br>
                <span>C2C Call</span>
                </mat-card>
            </li>
            <li class="flexitem">
                <mat-card (click)="clickPortalMenu('obd')">
                    <img src="../assets/obdImg.png"><br>
                    <span>OBD/IVR CALL</span>
                </mat-card>
            </li>
            <li class="flexitem">
                <mat-card (click)="clickPortalMenu('dobd')">
                    <img src="../assets/dobdImg.png"><br>
                    <span>DOBD/DIVR Call</span>
                </mat-card>
            </li>
           
            <li class="flexitem">
                <mat-card (click)="clickPortalMenu('sip')">
                    <img src="../assets/sipImg.png"><br>
                    <span>SIP Call</span>
                </mat-card>
            </li>
            <li class="flexitem">
                <mat-card (click)="clickPortalMenu('sms')">
                    <img src="../assets/smsImg.png"><br>
                    <span>SMS</span>
                </mat-card>
            </li>
            <li class="flexitem">
                <mat-card (click)="clickPortalMenu('whatsapp')">
                    <img src="../assets/whatsappImg.png"><br>
                    <span>WhatsApp</span>
                    
                </mat-card>
            </li>
            <li class="flexitem">
                <mat-card (click)="clickPortalMenu('email')">
                    <img src="../assets/emailImg.png"><br>
                    <span>Email</span>
                </mat-card>
            </li>
          </ul>
          
          
        </div>
        </div>


        <div *ngIf="showHideCrcCall">
            <app-demo-portal-crc-call></app-demo-portal-crc-call>
        </div>
        <div *ngIf="showHideOBDCall">
            <app-demo-portal-obd-call></app-demo-portal-obd-call>
        </div>
        <div *ngIf="showHideDOBDCall">
            <app-demo-portal-dobd-call></app-demo-portal-dobd-call>
        </div>
        <div *ngIf="showHideSMS">
            <app-demo-portal-sms></app-demo-portal-sms>
        </div>
        <div *ngIf="showHideWhatsapp">
            <app-demo-portal-whatsapp></app-demo-portal-whatsapp>
        </div>

        <div *ngIf="showHideSipCall">
            <app-demo-portal-sip-call></app-demo-portal-sip-call>
        </div>
        <div *ngIf="showHideEmail">
            <app-demo-portal-email></app-demo-portal-email>
        </div>