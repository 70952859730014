
<div class="row">
    <div class="col-md col-sm-12 col-xs-12">
        <mat-tab-group [ngClass]="'mTabV1'"  (selectedIndexChange)="selectedsetValue($event)">
            <mat-tab label="All messages">
                <div class="row">
                    <div class="col-md col-sm-12 col-xs-12">
                        <mat-card class="CN-Card cardW">
                            <p><span class="CN-Tab-head1">Sent </span>
                                <span style="float: right;"><mat-icon class="ml-2 sInfoX" matTooltip="The number of messages your business submitted to be sent to users on WhatsApp" matTooltipClass="myInfoTooltip">info</mat-icon></span></p>
                            <p class="cn-p CNP1 CNPSh2">{{wa_today[0]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                            <hr class="hr1">
                            <p class="cn-p CNP2 CNPSh2">{{wa_yesterday[0]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                            <div style="display: none;">
                            <hr class="hr2">
                            <p [hidden]="wa_today[0]!=wa_yesterday[0]">
                                      <span>
                                          <mat-icon class="upArrow">arrow_upward</mat-icon>
                                      </span>
                              <span class="upData">100%</span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
                            </p>
                            <p [hidden]="wa_today[0]==wa_yesterday[0] || wa_today[0]<wa_yesterday[0]">
                                      <span>
                                          <mat-icon class="upArrow">arrow_upward</mat-icon>
                                      </span>
                              <span class="upData">{{ ((wa_today[0]-wa_yesterday[0])/wa_yesterday[0]) | percent }} </span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
                            </p>
                            <p [hidden]="wa_today[0]==wa_yesterday[0] || wa_today[0]>wa_yesterday[0]">
                                        <span>
                                            <mat-icon class="downArrow">arrow_downward</mat-icon>
                                        </span>
                              <span class="downData">{{((wa_yesterday[0]-wa_today[0])/wa_yesterday[0]) | percent}} </span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
                          </p>
                        </div>
                        </mat-card>
                    </div>
                    <div class="col-md col-sm-12 col-xs-12">
                        <mat-card class="CN-Card cardW">
                            <p><span class="CN-Tab-head1">Delivered </span>
                                <span  style="float: right;"><mat-icon class="ml-2 sInfoX"  matTooltip="The number of WhatsApp messages which got successfully delivered to the users" matTooltipClass="myInfoTooltip">info</mat-icon></span></p>
                            <p class="cn-p CNP3">{{wa_today[1]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                            <hr class="hr1">
                            <p class="cn-p CNP3">{{wa_yesterday[1]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                            <div style="display: none;">
                            <hr class="hr2">
                            <p [hidden]="wa_today[1]!=wa_yesterday[1]">
                                    <span>
                                        <mat-icon class="upArrow">arrow_upward</mat-icon>
                                    </span>
                              <span class="upData">100%</span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
                            </p>
                            <p [hidden]="wa_today[1]==wa_yesterday[1] || wa_today[1]<wa_yesterday[1]">
                                    <span>
                                        <mat-icon class="upArrow">arrow_upward</mat-icon>
                                    </span>
                              <span class="upData">{{ ((wa_today[1]-wa_yesterday[1])/wa_yesterday[1]) | percent }} </span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
                            </p>
                            <p [hidden]="wa_today[1]==wa_yesterday[1] || wa_today[1]>wa_yesterday[1]">
                                      <span>
                                          <mat-icon class="downArrow">arrow_downward</mat-icon>
                                      </span>
                              <span class="downData">{{((wa_yesterday[1]-wa_today[1])/wa_yesterday[1]) | percent}} </span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
                            </p>
                            </div>
                        </mat-card>
                    </div>
                    <!--start schedule-->
                    <div class="col-md col-sm-12 col-xs-12">
                        <mat-card class="CN-Card cardW">
                            <p><span class="CN-Tab-head1">Scheduled </span>
                                <span  style="float: right;"><mat-icon class="ml-2 sInfoX"  matTooltip="The number of WhatsApp messages which got successfully scheduled." matTooltipClass="myInfoTooltip">info</mat-icon></span></p>
                            <p class="cn-p CNPSh1">{{wa_today[4]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                            <hr class="hr1">
                            <p class="cn-p CNPSh1">{{wa_yesterday[4]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                            <div style="display: none;">
                            <hr class="hr2">
                            <p [hidden]="wa_today[4]!=wa_yesterday[4]">
                                    <span>
                                        <mat-icon class="upArrow">arrow_upward</mat-icon>
                                    </span>
                              <span class="upData">100%</span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
                            </p>
                            <p [hidden]="wa_today[4]==wa_yesterday[4] || wa_today[4]<wa_yesterday[4]">
                                    <span>
                                        <mat-icon class="upArrow">arrow_upward</mat-icon>
                                    </span>
                              <span class="upData">{{ ((wa_today[4]-wa_yesterday[4])/wa_yesterday[4]) | percent }} </span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
                            </p>
                            <p [hidden]="wa_today[4]==wa_yesterday[4] || wa_today[4]>wa_yesterday[4]">
                                      <span>
                                          <mat-icon class="downArrow">arrow_downward</mat-icon>
                                      </span>
                              <span class="downData">{{((wa_yesterday[4]-wa_today[4])/wa_yesterday[4]) | percent}} </span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
                            </p>
                            </div>
                        </mat-card>
                    </div>
                
                    <!--end schedule-->
                    <div class="col-md col-sm-12 col-xs-12">
                        <mat-card class="CN-Card cardW">
                            <p><span class="CN-Tab-head1">Received </span>
                                <span  style="float: right;"><mat-icon class="ml-2 sInfoX"  matTooltip="The number of WhatsApp messages which was received by your Business Number" matTooltipClass="myInfoTooltip">info</mat-icon></span></p>
                            <p class="cn-p CNP6">{{wa_today[2]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                            <hr class="hr1">
                            <p class="cn-p CNP6">{{wa_yesterday[2]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                            <div style="display: none;">
                            <hr class="hr2">
                            <p [hidden]="wa_today[2]!=wa_yesterday[2]">
                                    <span>
                                        <mat-icon class="upArrow">arrow_upward</mat-icon>
                                    </span>
                              <span class="upData">100%</span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
                            </p>
                            <p [hidden]="wa_today[2]==wa_yesterday[2] || wa_today[2]<wa_yesterday[2]">
                                    <span>
                                        <mat-icon class="upArrow">arrow_upward</mat-icon>
                                    </span>
                              <span class="upData">{{ ((wa_today[2]-wa_yesterday[2])/wa_yesterday[2]) | percent }} </span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
                            </p>
                            <p [hidden]="wa_today[2]==wa_yesterday[2] || wa_today[2]>wa_yesterday[2]">
                                      <span>
                                          <mat-icon class="downArrow">arrow_downward</mat-icon>
                                      </span>
                              <span class="downData">{{((wa_yesterday[2]-wa_today[2])/wa_yesterday[2]) | percent}} </span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
                            </p>
                            </div>
                        </mat-card>
                    </div>
                    <div class="col-md col-sm-12 col-xs-12">
                        <mat-card class="CN-Card cardW">
                            <p><span class="CN-Tab-head1">Failed </span>
                                <span  style="float: right;"><mat-icon class="ml-2 sInfoX"  matTooltip="The number of WhatsApp messages which got failed during submission either due to Invalid parameters or invalid Contact" matTooltipClass="myInfoTooltip">info</mat-icon> </span></p>
                            <p class="cn-p CNP7">{{wa_today[3]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                            <hr class="hr1">
                            <p class="cn-p CNP7">{{wa_yesterday[3]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                            <div style="display: none;">
                            <hr class="hr2">
                            <p [hidden]="wa_today[3]!=wa_yesterday[3]">
                                  <span>
                                      <mat-icon class="upArrow">arrow_upward</mat-icon>
                                  </span>
                              <span class="upData">100%</span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
                            </p>
                            <p [hidden]="wa_today[3]==wa_yesterday[3] || wa_today[3]<wa_yesterday[3]">
                                  <span>
                                      <mat-icon class="upArrow">arrow_upward</mat-icon>
                                  </span>
                              <span class="upData">{{ ((wa_today[3]-wa_yesterday[3])/wa_yesterday[3]) | percent }} </span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
                            </p>
                            <p [hidden]="wa_today[3]==wa_yesterday[3] || wa_today[3]>wa_yesterday[3]">
                                    <span>
                                        <mat-icon class="downArrow">arrow_downward</mat-icon>
                                    </span>
                              <span class="downData">{{((wa_yesterday[3]-wa_today[3])/wa_yesterday[3]) | percent}} </span>
                              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
                            </p>
                            </div>
                        </mat-card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="!showHideTemplateStatus">
                        <mat-card class="CN-Card cardW">
                            <p><span class="CN-Tab-head1">Sent Vs Delivered Vs Received Vs Scheduled</span> </p>
                            <div [chart]="sentDeliveredReceivedChart"> </div>
                        </mat-card>
                    </div>
                    <div class="col-md-7 col-sm-12 col-xs-12" *ngIf="showHideTemplateStatus">
                        <mat-card class="CN-Card cardW">
                            <p><span class="CN-Tab-head1">Sent Vs Delivered Vs Received  Vs Scheduled</span> </p>
                            <div [chart]="sentDeliveredReceivedChart"> </div>
                        </mat-card>
                    </div>
                    <div class="col-md-5 col-sm-12 col-xs-12" *ngIf="showHideTemplateStatus">
                        <mat-card class="CN-Card cardW" style="overflow: hidden auto;height: 372px;padding: 0 10px;">
                         <table style="width: 100%;position: sticky;top: 2px;background: #fff;height: 58px;z-index: 2;">
                             <tr>
                                 <td>  <span class="CN-Tab-head1">Template Status</span> </td>
                                 <td class="text-right"><span class="CN-Tab-head1">Total</span>  </td>
                             </tr>
                         </table>
                
                                <mat-card-title></mat-card-title>
                                <mat-card-content class="camStatusScroll1">
                                     <mat-tree [dataSource]="dataSourceTree" [treeControl]="treeControl" class="example-tree">
                                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                                            {{node.name}} <span style="position: absolute;
                                            right: 17px;">{{node.val}} </span>
                                        </mat-tree-node>
                                        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                                            <div class="mat-tree-node" style="border-top: 1px solid #f1f0f0;">
                                           
                                                            <button mat-icon-button matTreeNodeToggle style="color:'#ff5722'"
                                                            [attr.aria-label]="'Toggle ' + node.name">                                          
                                                      <i *ngIf="!treeControl.isExpanded(node)" class="fa fa-plus" style="font-size: 24px;" aria-hidden="true"></i>
                                                      <i *ngIf="treeControl.isExpanded(node)" class="fa fa-minus" style="font-size: 24px;" aria-hidden="true"></i>
                                                     
                                                    </button>
                                           
                                             <span><b>{{node.name}} </b> </span> <span style="position: absolute;
                                              right: 17px;font-weight: bold;">{{node.val}} </span>
                                            </div>
                                            <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                                                role="group">
                                              <ng-container matTreeNodeOutlet></ng-container>
                                          </div>
                                        </mat-nested-tree-node>
                                      </mat-tree>
                
                                </mat-card-content>
                
                        </mat-card>
                    </div>
                <!--
                     <div class="col-md-3 col-sm-12 col-xs-12" >
                        <mat-card class="CN-Card cardW">
                            <p class="CN-Tab-head1">Today’s Summary</p>
                            <div style="min-width: 150px; height: 150px; max-width: 150px;" [chart]="donutChartrecord">
                            </div>
                            <table style="width:100%">
                                <tr>
                                    <td class="text-right"><span class="lv1">Today’s Total</span></td>
                                    <td><span class="lv2">{{wa_total_today}} </span></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><span class="lv1">Yesterday;s Total</span></td>
                                    <td><span class="lv2">{{wa_total_yesterday}} </span></td>
                                </tr>
                            </table>
                        </mat-card>
                    </div>
                -->
                </div>
                 </mat-tab>
            <mat-tab label="All conversations"> 
             <div class="row mt-4">
                       <!--start conversation-->
                       <div class="col-md-4 col-sm-12 col-xs-12">
                        <mat-card class="CN-Card cardW">
                            <p><span class="CN-Tab-head1">Total Counts </span>
                                <span  style="float: right;"><mat-icon class="ml-2 sInfoX"  matTooltip="The number of messaging conversations on WhatsApp between your business and users. A conversation includes all messages delivered within a 24-hour period." matTooltipClass="myInfoTooltip">info</mat-icon></span></p>
                            <p class="cn-p CNPSh2">{{conversation_today[0]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                            <hr class="hr1">
                            <p class="cn-p CNPSh2">{{conversation_yesterday[0]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                      
                        </mat-card>
                    </div>
                
                    <div class="col-md-4 col-sm-12 col-xs-12">
                        <mat-card class="CN-Card cardW">
                            <p><span class="CN-Tab-head1">Business Initiated </span>
                                <span  style="float: right;"><mat-icon class="ml-2 sInfoX"  matTooltip="The number of messaging conversations on WhatsApp between your business and users which are started by your business." matTooltipClass="myInfoTooltip">info</mat-icon></span></p>
                            <p class="cn-p CNP3">{{conversation_today[1]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                            <hr class="hr1">
                            <p class="cn-p CNP3">{{conversation_yesterday[1]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                      
                        </mat-card>
                    </div>
                    <div class="col-md-4 col-sm-12 col-xs-12">
                        <mat-card class="CN-Card cardW">
                            <p><span class="CN-Tab-head1">User Initiated </span>
                                <span  style="float: right;"><mat-icon class="ml-2 sInfoX"  matTooltip="The number of messaging conversations on WhatsApp between your business and users which are started by the users." matTooltipClass="myInfoTooltip">info</mat-icon></span></p>
                            <p class="cn-p CNPSh1">{{conversation_today[2]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
                            <hr class="hr1">
                            <p class="cn-p CNPSh1">{{conversation_yesterday[2]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
                      
                        </mat-card>
                    </div>
                    <!--end conversation-->
                 </div>
                 <div  class="row mt-2">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <mat-card class="CN-Card cardW">
                            <p><span class="CN-Tab-head1">Business Initiated vs User Initiated</span> </p>
                            <div [chart]="businessInitiatedvsUserInitiatedChart"> </div>
                        </mat-card>
                    </div>
                 </div>
             </mat-tab>
          </mat-tab-group>
        </div>
        </div>
<div id="cover-spin" *ngIf="currentLoader"></div>
