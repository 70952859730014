import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ServicesService } from '../../services.service';
import { MyserviceService } from '../../../myservice.service';
import { RecruiterAgentDetailsDialogComponent } from '../../../recruiter-agent-details-dialog/recruiter-agent-details-dialog.component';

import { Utils } from '../../../utils';

@Component({
  selector: 'app-agents-table',
  templateUrl: './agents-table.component.html',
  styleUrls: ['./agents-table.component.scss']
})
export class AgentsTableComponent implements OnInit, OnChanges, AfterViewInit {
	table_data: any = [];
	team_details: any = [];

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) tableSort: MatSort;
	
	public dataSource = new MatTableDataSource(this.table_data);

	@Input('receiveFromDashboard') fromDash: any;

	displayedColumns = [
		'user_id',
		'name',
		'team_lead',
		'level',
		'status',
		'dialled',
		'unique_dialled',
		'contacted',
		'unique_contacted',
		'rnr',
		'forms',
		'audit_score',
		'ctc_score',
		'break_time',
		'idle_time',
		'wait_time',
		'spoken_time',
		'wrap_time',
		'login_time',
		'more'
	];

	constructor(
		private dialog: MatDialog,
		private dashService: ServicesService,
		private myService: MyserviceService,
		private utils: Utils,
	) { }

	ngOnInit() {
		this.dashService.agents_table_data.subscribe((d) => {
			if (d) {
				// console.log('agent-table data', d);
				for (var i in d) {
					d[i]['level'] = this.getAgentRecruitmentRole(d[i])
				}
				this.table_data = d;
				// console.log('init subscribe data', d);
				this.refreshTableData();
			}
		});
		this.team_details = this.myService.getFullTeam();
	}

	ngAfterViewInit() {
		// console.log('After view init app agents table');
		this.dashService.agents_table_data.subscribe((d) => {
			if (d) {
				this.table_data = d;
				// console.log('subscribe data', d)
				this.refreshTableData();
			}
		})
	}	

	ngOnChanges(changes: SimpleChanges) {
		// console.log('changes in the agents table', changes)
	}

	refreshTableData() {
		// console.log('table data refresh', this.table_data);
		this.dataSource = new MatTableDataSource(this.table_data);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.tableSort;
	}

	openDetailActivity(row) {
		// console.log('printing row', row);
		var obj = row;
		// console.log('obj prepared', obj);
		var dialogRef = this.dialog.open(RecruiterAgentDetailsDialogComponent, {
			width: '55%',
			height:'60%',
			disableClose: true,
			data: obj
		});
		dialogRef.afterClosed();
	}

	converttoHMS(seconds:number) {
		return this.utils.convertSectoHMS(seconds);
	}

	getAgentRecruitmentRole(obj:any) {
		// console.log('printing object input', obj);
		// console.log('printing team details', this.team_details);
		let id = obj.user_id;
		try {
			if (typeof id == "number") {
				id = id.toString();
			}
			if (this.team_details.hasOwnProperty(id)) {
				let p = this.team_details[id][0];
				return p.project;
			}
		} catch (e) {
			return '';
		}
		return '';
	}
}
