import { Injectable, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'underscore'

import { ApiService } from '../api.service';

export interface TrainingTableData {
	'sl_no': number,
	'attribute': string,
	'training_process': number,
	'tech_requirement': number,
	'training_module': number,
	'payout': number,
	'product': number,
	'training_duration': number
};
export interface OjtTableData {
	'sl_no': number,
	'attribute': string,
	'ojt_timings': number,
	'sme_support': number,
	'tech_support': number,
	'feedback': number
};
export interface CertficationTableData {
	'sl_no': number,
	'attribute': string,
	'audit_status': number,
	'sme_support': number,
	'tech_support': number,
	'feedback': number
};
export interface ProductionTableData {
	'sl_no': number,
	'attribute': string,
	'tech_support': number,
	'sme_support': number,
	'process': number,
	'payout': number,
	'paid': number,
	'satisfied': number
};
export interface RetentionTableData {
	'sl_no': number,
	'attribute': string,
	'training': number,
	'floor': number,
	'product': number,
	'payout': number,
	'joining': number,
	'immediate': number
}
export interface EmailTableData {
	'sl_no': number,
	'attribute': string,
	'training': number,
	'sme_support': number,
	'product': number,
	'quality': number,
	'payout': number
}
export interface ChartData {
	title: string,
	name: string,
	data: any
}
interface dashboard_data_input {
	start_date: string | moment.Moment,
	end_date: string | moment.Moment
}

@Injectable({
	providedIn: 'root'
})
export class ServicesService {

	public tabs_data = [
		{
			'title': 'Training',
			'data_val': 'training',
			'data_key': 'TRAINING'
		},
		{
			'title': 'OJT',
			'data_val': 'ojt',
			'data_key': 'OJT'
		},
		{
			'title': 'Certification',
			'data_val': 'certification',
			'data_key': 'CERTIFICATION'
		},
		{
			'title': 'Production',
			'data_val': 'production',
			'data_key': 'PRODUCTION'
		},
		{
			'title': 'Retention',
			'data_val': 'retention',
			'data_key': 'RETENTION'
		},
		{
			'title': 'Email',
			'data_val': 'email',
			'data_key': 'EMAIL'
		}
	]

	/* summary data variable starts */
	public api_data_response = null;
	summary_data = function () {
		return {
			total_dialled: 0,
			total_connected: 0,
			total_rnr: 0,
			unique_dialled: 0,
			unique_contacted: 0,
			not_reachable: 0,
			wrong_number: 0,
			callback: 0,
			divisons_contacted_unique: {
				'TRAINING': 0,
				'OJT': 0,
				'EMAIL': 0,
				'PRODUCTION': 0,
				'RETENTION': 0,
				'CERTIFICATION': 0
			}
		}
	}
	public summary_data_dash = new BehaviorSubject<any>(this.summary_data());
	// public summary_data_dash = new Subscription(this.summary_data);
	/* summary data variable ends */

	/* Table data variables starts */
	public default_table_data = {
		'CERTIFICATION': function () {
			return [
				{
					'sl_no': 1,
					'attribute': 'Satisfied',
					'audit_status': 0,
					'sme_support': 0,
					'tech_support': 0,
					'feedback': 0
				}, {
					'sl_no': 2,
					'attribute': 'Dissatisfied',
					'audit_status': 0,
					'sme_support': 0,
					'tech_support': 0,
					'feedback': 0
				}, {
					'sl_no': 3,
					'attribute': 'Highly Dissatisfied',
					'audit_status': 0,
					'sme_support': 0,
					'tech_support': 0,
					'feedback': 0
				}, {
					'sl_no': 4,
					'attribute': 'Yes',
					'audit_status': 0,
					'sme_support': 0,
					'tech_support': 0,
					'feedback': 0
				}, {
					'sl_no': 5,
					'attribute': 'No',
					'audit_status': 0,
					'sme_support': 0,
					'tech_support': 0,
					'feedback': 0
				}
			]
		},
		'EMAIL': function () {
			return [
				{
					'sl_no': 1,
					'attribute': 'Agree',
					'training': 0,
					'sme_support': 0,
					'product': 0,
					'quality': 0,
					'payout': 0
				},
				{
					'sl_no': 2,
					'attribute': 'Somewhat Agree',
					'training': 0,
					'sme_support': 0,
					'product': 0,
					'quality': 0,
					'payout': 0
				},
				{
					'sl_no': 3,
					'attribute': 'Disagree',
					'training': 0,
					'sme_support': 0,
					'product': 0,
					'quality': 0,
					'payout': 0
				},
				{
					'sl_no': 4,
					'attribute': 'Good',
					'training': 0,
					'sme_support': 0,
					'product': 0,
					'quality': 0,
					'payout': 0
				},
				{
					'sl_no': 5,
					'attribute': 'Average',
					'training': 0,
					'sme_support': 0,
					'product': 0,
					'quality': 0,
					'payout': 0
				},
				{
					'sl_no': 6,
					'attribute': 'No Support',
					'training': 0,
					'sme_support': 0,
					'product': 0,
					'quality': 0,
					'payout': 0
				},
				{
					'sl_no': 7,
					'attribute': 'Satisfied',
					'training': 0,
					'sme_support': 0,
					'product': 0,
					'quality': 0,
					'payout': 0
				},
				{
					'sl_no': 8,
					'attribute': 'Dissatisfied',
					'training': 0,
					'sme_support': 0,
					'product': 0,
					'quality': 0,
					'payout': 0
				},
				{
					'sl_no': 9,
					'attribute': 'Highly Dissatisfied',
					'training': 0,
					'sme_support': 0,
					'product': 0,
					'quality': 0,
					'payout': 0
				}
			]
		},
		'OJT': function () {
			return [
				{
					'sl_no': 1,
					'attribute': 'Satisfied',
					'ojt_timings': 0,
					'sme_support': 0,
					'tech_support': 0,
					'feedback': 0
				}, {
					'sl_no': 2,
					'attribute': 'Dissatisfied',
					'ojt_timings': 0,
					'sme_support': 0,
					'tech_support': 0,
					'feedback': 0
				}, {
					'sl_no': 3,
					'attribute': 'Highly Dissatisfied',
					'ojt_timings': 0,
					'sme_support': 0,
					'tech_support': 0,
					'feedback': 0
				}, {
					'sl_no': 4,
					'attribute': 'Yes',
					'ojt_timings': 0,
					'sme_support': 0,
					'tech_support': 0,
					'feedback': 0
				}, {
					'sl_no': 5,
					'attribute': 'No',
					'ojt_timings': 0,
					'sme_support': 0,
					'tech_support': 0,
					'feedback': 0
				}
			]
		},
		'PRODUCTION': function () {
			return [
				{
					"sl_no": 1,
					"attribute": "Satisfied",
					"tech_support": 0,
					"sme_support": 0,
					"process": 0,
					"payout": 0,
					"paid": 0,
					"satisfied": 0
				},
				{
					"sl_no": 2,
					"attribute": "Dissatisfied",
					"tech_support": 0,
					"sme_support": 0,
					"process": 0,
					"payout": 0,
					"paid": 0,
					"satisfied": 0
				},
				{
					"sl_no": 3,
					"attribute": "Highly Dissatisfied",
					"tech_support": 0,
					"sme_support": 0,
					"process": 0,
					"payout": 0,
					"paid": 0,
					"satisfied": 0
				},
				{
					"sl_no": 4,
					"attribute": "Clearly Understandable",
					"tech_support": 0,
					"sme_support": 0,
					"process": 0,
					"payout": 0,
					"paid": 0,
					"satisfied": 0
				},
				{
					"sl_no": 5,
					"attribute": "Complex",
					"tech_support": 0,
					"sme_support": 0,
					"process": 0,
					"payout": 0,
					"paid": 0,
					"satisfied": 0
				},
				{
					"sl_no": 6,
					"attribute": "Need Training",
					"tech_support": 0,
					"sme_support": 0,
					"process": 0,
					"payout": 0,
					"paid": 0,
					"satisfied": 0
				},
				{
					"sl_no": 7,
					"attribute": "Yes",
					"tech_support": 0,
					"sme_support": 0,
					"process": 0,
					"payout": 0,
					"paid": 0,
					"satisfied": 0
				},
				{
					"sl_no": 8,
					"attribute": "No",
					"tech_support": 0,
					"sme_support": 0,
					"process": 0,
					"payout": 0,
					"paid": 0,
					"satisfied": 0
				}
			]
		},
		'RETENTION': function () {
			return [
				{
					'sl_no': 1,
					'attribute': 'Satisfied',
					'training': 0,
					'floor': 0,
					'product': 0,
					'payout': 0,
					'joining': 0,
					'immediate': 0
				}, {
					'sl_no': 2,
					'attribute': 'Dissatisfied',
					'training': 0,
					'floor': 0,
					'product': 0,
					'payout': 0,
					'joining': 0,
					'immediate': 0
				}, {
					'sl_no': 3,
					'attribute': 'Highly Dissatisfied',
					'training': 0,
					'floor': 0,
					'product': 0,
					'payout': 0,
					'joining': 0,
					'immediate': 0
				}, {
					'sl_no': 4,
					'attribute': 'Yes',
					'training': 0,
					'floor': 0,
					'product': 0,
					'payout': 0,
					'joining': 0,
					'immediate': 0
				}, {
					'sl_no': 5,
					'attribute': 'No',
					'training': 0,
					'floor': 0,
					'product': 0,
					'payout': 0,
					'joining': 0,
					'immediate': 0
				}
			]
		},
		'TRAINING': function () {
			return [
				{
					"sl_no": 1,
					"attribute": "Satisfied",
					"training_process": 0,
					"tech_requirement": 0,
					"training_module": 0,
					"payout": 0,
					"product": 0,
					"training_duration": 0
				},
				{
					"sl_no": 2,
					"attribute": "Dissatisfied",
					"training_process": 0,
					"tech_requirement": 0,
					"training_module": 0,
					"payout": 0,
					"product": 0,
					"training_duration": 0
				},
				{
					"sl_no": 3,
					"attribute": "Highly Dissatisfied",
					"training_process": 0,
					"tech_requirement": 0,
					"training_module": 0,
					"payout": 0,
					"product": 0,
					"training_duration": 0
				},
				{
					"sl_no": 4,
					"attribute": "Clearly Understandable",
					"training_process": 0,
					"tech_requirement": 0,
					"training_module": 0,
					"payout": 0,
					"product": 0,
					"training_duration": 0
				},
				{
					"sl_no": 5,
					"attribute": "Complex",
					"training_process": 0,
					"tech_requirement": 0,
					"training_module": 0,
					"payout": 0,
					"product": 0,
					"training_duration": 0
				},
				{
					"sl_no": 6,
					"attribute": "Need Training",
					"training_process": 0,
					"tech_requirement": 0,
					"training_module": 0,
					"payout": 0,
					"product": 0,
					"training_duration": 0
				},
				{
					"sl_no": 7,
					"attribute": "Yes",
					"training_process": 0,
					"tech_requirement": 0,
					"training_module": 0,
					"payout": 0,
					"product": 0,
					"training_duration": 0
				},
				{
					"sl_no": 8,
					"attribute": "No",
					"training_process": 0,
					"tech_requirement": 0,
					"training_module": 0,
					"payout": 0,
					"product": 0,
					"training_duration": 0
				}
			]
		}
	}
	public table_data_subscriber = {
		'CERTIFICATION': new BehaviorSubject(this.default_table_data['CERTIFICATION']()),
		'EMAIL': new BehaviorSubject(this.default_table_data['EMAIL']()),
		'OJT': new BehaviorSubject(this.default_table_data['OJT']()),
		'PRODUCTION': new BehaviorSubject(this.default_table_data['PRODUCTION']()),
		'RETENTION': new BehaviorSubject(this.default_table_data['RETENTION']()),
		'TRAINING': new BehaviorSubject(this.default_table_data['TRAINING']())
	}
	/* Table data variables ends */

	/* Pie chart data variable starts */
	total_chart_data = new BehaviorSubject({
		"title": "",
		"name": "Connected",
		"data": [{
			name: 'Training',
			y: 0,
			selected: true
		}, {
			name: 'OJT',
			y: 0
		}, {
			name: 'Certification',
			y: 0
		}, {
			name: 'Production',
			y: 0
		}, {
			name: 'Retention',
			y: 0
		}, {
			name: 'Email',
			y: 0
		}]
	})
	default_chart_data = {
		"CERTIFICATION": function () {
			return {
				"title": "Certification Data",
				"name": "Attributes",
				"data": [{
					name: 'Satisfied',
					y: 0,
					selected: true
				}, {
					name: 'Dissatisfied',
					y: 0
				}, {
					name: 'Highly Dissatisfied',
					y: 0
				}, {
					name: 'Yes',
					y: 0
				}, {
					name: 'No',
					y: 0
				}]
			}
		},
		"EMAIL": function () {
			return {
				"title": "Email Data",
				"name": "Attributes",
				"data": [{
					name: 'Agree',
					y: 0,
					selected: true
				}, {
					name: 'Somewhat Agree',
					y: 0
				}, {
					name: 'Disagree',
					y: 0
				}, {
					name: 'Good',
					y: 0
				}, {
					name: 'Average',
					y: 0
				}, {
					name: 'No Support',
					y: 0
				}, {
					name: 'Satisfied',
					y: 0
				}, {
					name: 'Dissatisfied',
					y: 0
				}, {
					name: 'Highly Dissatisfied',
					y: 0
				}]
			}
		},
		"OJT": function () {
			return {
				"title": "OJT Data",
				"name": "Attributes",
				"data": [{
					name: 'Satisfied',
					y: 0,
					selected: true
				}, {
					name: 'Dissatisfied',
					y: 0
				}, {
					name: 'Highly Dissatisfied',
					y: 0
				}, {
					name: 'Yes',
					y: 0
				}, {
					name: 'No',
					y: 0
				}]
			}
		},
		"PRODUCTION": function () {
			return {
				"title": "Production Data",
				"name": "Attributes",
				"data": [{
					name: 'Satisfied',
					y: 0,
					selected: true
				}, {
					name: 'Dissatisfied',
					y: 0
				}, {
					name: 'Highly Dissatisfied',
					y: 0
				}, {
					name: 'Clearly Understandable',
					y: 0
				}, {
					name: 'Complex',
					y: 0
				}, {
					name: 'Need Training',
					y: 0
				}, {
					name: 'Yes',
					y: 0
				}, {
					name: 'No',
					y: 0
				}]
			}
		},
		"RETENTION": function () {
			return {
				"title": "Retention Data",
				"name": "Attributes",
				"data": [{
					name: 'Satisfied',
					y: 0,
					selected: true
				}, {
					name: 'Dissatisfied',
					y: 0
				}, {
					name: 'Highly Dissatisfied',
					y: 0
				}, {
					name: 'Yes',
					y: 0
				}, {
					name: 'No',
					y: 0
				}]
			}
		},
		"TRAINING": function () {
			return {
				"title": "Training Data",
				"name": "Attributes",
				"data": [{
					name: 'Satisfied',
					y: 0,
					selected: true
				}, {
					name: 'Dissatisfied',
					y: 0
				}, {
					name: 'Highly Dissatisfied',
					y: 0
				}, {
					name: 'Clearly Understandable',
					y: 0
				}, {
					name: 'Complex',
					y: 0
				}, {
					name: 'Need Training',
					y: 0
				}, {
					name: 'Yes',
					y: 0
				}, {
					name: 'No',
					y: 0
				}]
			}
		},
	}
	divison_chart_data = {
		"title": "",
		"name": "Connected",
		"data": [{
			name: 'Training',
			y: 0,
			selected: true
		}, {
			name: 'OJT',
			y: 0
		}, {
			name: 'Certification',
			y: 0
		}, {
			name: 'Production',
			y: 0
		}, {
			name: 'Retention',
			y: 0
		}, {
			name: 'Email',
			y: 0
		}]
	}
	public chart_data_subscriber = {
		'CERTIFICATION': new BehaviorSubject(this.default_chart_data['CERTIFICATION']()),
		'EMAIL': new BehaviorSubject(this.default_chart_data['EMAIL']()),
		'OJT': new BehaviorSubject(this.default_chart_data['OJT']()),
		'PRODUCTION': new BehaviorSubject(this.default_chart_data['PRODUCTION']()),
		'RETENTION': new BehaviorSubject(this.default_chart_data['RETENTION']()),
		'TRAINING': new BehaviorSubject(this.default_chart_data['TRAINING']())
	}
	/* Pie chart data variable ends */

	table_data_map = {
		'training': {
			'table_data': this.table_data_subscriber['TRAINING'].asObservable(),
			'column_map': [
				{
					"title": "Sr. No",
					"key": "sl_no"
				},
				{
					"title": "Attributes",
					"key": "attribute"
				},
				{
					"title": "Training Process",
					"key": "training_process"
				},
				{
					"title": "Tech Requirement",
					"key": "tech_requirement"
				},
				{
					"title": "Training Module",
					"key": "training_module"
				},
				{
					"title": "Payout and Service Agreement",
					"key": "payout"
				},
				{
					"title": "Product and Workflow",
					"key": "product"
				},
				{
					"title": "Training Duration",
					"key": "training_duration"
				}
			],
			'chart_data': this.chart_data_subscriber['TRAINING'].asObservable()
		},
		'ojt': {
			'table_data': this.table_data_subscriber['OJT'].asObservable(),
			'column_map': [
				{
					"title": "Sr. No",
					"key": "sl_no"
				},
				{
					"title": "Attributes",
					"key": "attribute"
				},
				{
					"title": "OJT Date and Timings",
					"key": "ojt_timings"
				},
				{
					"title": "SME Support",
					"key": "sme_support"
				},
				{
					"title": "Tech Support",
					"key": "tech_support"
				},
				{
					"title": "Overall Feedback",
					"key": "feedback"
				}
			],
			'chart_data': this.chart_data_subscriber['OJT'].asObservable()
		},
		'certification': {
			'table_data': this.table_data_subscriber['CERTIFICATION'].asObservable(),
			'column_map': [
				{
					"title": "Sr. No",
					"key": "sl_no"
				},
				{
					"title": "Attributes",
					"key": "attribute"
				},
				{
					"title": "Audit Status",
					"key": "audit_status"
				},
				{
					"title": "SME Support (Assessment)",
					"key": "sme_support"
				},
				{
					"title": "Tech Support",
					"key": "tech_support"
				},
				{
					"title": "Overall Feedback",
					"key": "feedback"
				}
			],
			'chart_data': this.chart_data_subscriber['CERTIFICATION'].asObservable()
		},
		'production': {
			'table_data': this.table_data_subscriber['PRODUCTION'].asObservable(),
			'column_map': [
				{
					"title": "Sr.No",
					"key": "sl_no"
				},
				{
					"title": "Attributes",
					"key": "attribute"
				},
				{
					"title": "Tech support",
					"key": "tech_support"
				},
				{
					"title": "Support from team leader/SME",
					"key": "sme_support"
				},
				{
					"title": "Product/Process Workflow",
					"key": "process"
				},
				{
					"title": "Payout Structure",
					"key": "payout"
				},
				{
					"title": "Paid on Time",
					"key": "paid"
				},
				{
					"title": "Satisfied with Payout",
					"key": "satisfied"
				}
			],
			'chart_data': this.chart_data_subscriber['PRODUCTION'].asObservable()
		},
		'retention': {
			'table_data': this.table_data_subscriber['RETENTION'].asObservable(),
			'column_map': [
				{
					"title": "Sr.No",
					"key": "sl_no"
				},
				{
					"title": "Attributes",
					"key": "attribute"
				}, {
					"title": "Training Feedback",
					"key": "training"
				}, {
					"title": "Floor Support (TL & SME)",
					"key": "floor"
				}, {
					"title": "Product /Process",
					"key": "product"
				}, {
					"title": "Pay out concerns",
					"key": "payout"
				}, {
					"title": "Joining Back",
					"key": "joining"
				}, {
					"title": "Immediate Joiner",
					"key": "immediate"
				}

			],
			'chart_data': this.chart_data_subscriber['RETENTION'].asObservable()
		},
		'email': {
			'table_data': this.table_data_subscriber['EMAIL'].asObservable(),
			'column_map': [
				{
					"title": "Sr.No",
					"key": "sl_no"
				},
				{
					"title": "Attributes",
					"key": "attribute"
				}, {
					"title": "Training Feedback",
					"key": "training"
				}, {
					"title": "Support from Team leader/SME",
					"key": "sme_support"
				}, {
					"title": "Product /Process Flow",
					"key": "product"
				}, {
					"title": "Quality Feedback",
					"key": "quality"
				}, {
					"title": "Satisfied with Payout",
					"key": "payout"
				},
			],
			'chart_data': this.chart_data_subscriber['EMAIL'].asObservable()
		},
		'total': {
			'chart_data': this.total_chart_data.asObservable()
		}
	}

	constructor(private API: ApiService) {
		// console.log('FK_emp services starts');
	}

	getQuestionKeyforTitle(division: string, title: string) {
		var key = '';
		let k_map = this.table_data_map[division.toLowerCase()].column_map;
		for (var k in k_map) {
			if (k_map[k]['title'] == title) {
				key = k_map[k]['key'];
				break;
			}
		}
		return key;
	}

	getQuestion_type(q: string, dvision: string) {
		// console.log('Input q', q);
		var title = '';
		var q_mapper = {
			'CERTIFICATION': [{ "title": "Audit Status", "q": "Were you aware of your audit status ?" }, { "title": "SME Support", "q": "Did you get sufficient help from your SME during Assessment ?" }, { "title": "Tech Support", "q": "Did you get sufficient Tech Support ?" }, { "title": "Overall Certification Process", "q": "Are you happy with the overall Certification Process ?" }],
			'EMAIL': [{ "title": "Training Feedback", "q": "Is the training that you are receiving good? Please feel free to give us a feedback so that we can improve." }, { "title": "Support from Team leader/Sme", "q": "Are you getting appropriate training on topics to perform your task from your TL and SME ?" }, { "title": "Support from Team leader/Sme", "q": "Are you getting support from SME/TL in handling your queries with regards to incidents ?" }, { "title": "Product & Workflow Process", "q": "Have you been able to understand the product and the workflow process ?" }, { "title": "Quality Feedback", "q": "Do you understand all quality parameters and are you getting timely feedback on the same, both through Transmon and TL's ?" }, { "title": "Satisfied with Payout", "q": "Are you satisfied with the payout as per your signed service agreement?" }],
			'OJT': [{ "title": "OJT Date and Time", "q": "Were you aware of the OJT Start Date and Timing ?" }, { "title": "SME Support", "q": "Did you get sufficient help from your SME ?" }, { "title": "Tech Support", "q": "Did you get sufficient Tech Support ?" }, { "title": "Overall Process", "q": "Are you happy with the overall OJT process ?" }],
			'PRODUCTION': [{ "title": "Tech Support", "q": "Are you receiving sufficient Tech Support ?" }, { "title": "TL/SME Support", "q": "Did you get sufficient help from your TL/SME ?" }, { "title": "TL/SME Support", "q": "Did you get sufficient help from your TL/SME ?" }, { "title": "Product and Process", "q": "Have you been able to understand the product and the workflow process ?" }, { "title": "Pay Structure", "q": "Do you understand the payout structure ? " }, { "title": "Paid on time", "q": " Are you getting paid as per the timelines ?" }, { "title": "Satisfied with Payout", "q": "Are you satisfied with the payout as per your signed service agreement?" }],
			'RETENTION': [{ "title": "Training Feedback", "q": "Is the training that you are receiving good? Please feel free to give us a feedback so that we can improve." }, { "title": "Floor Support (TL&SME)", "q": "Is your “SME - Buddy” helping you with your doubts and concern? If not where do you think he / she can improve." }, { "title": "Product and Process", "q": "Have you been able to understand the products and the work flow process, if not please let me know your concerns so that we can help you get a better understanding of the same" }, { "title": "Payout Concerns", "q": "Are you satisfied with the payout as per your signed service agreement? If not, please let me know what is the concern, so we can help resolve it." }, { "title": "Join Us Back", "q": "Would you like to join us back?" }, { "title": "Immediate Joiner", "q": "Can you join back in the next 3 days ?" }],
			'TRAINING': [{ "title": "Training Process", "q": "Is the training process that you are receiving good? Please feel free to give us a feedback so that we can improve." }, { "title": "Tech Requirement", "q": "Is the Tech Requirement understood ? (Android Phone, Non Jio Sim Card, Working Laptop/Desktop, Wifi Connection with good speed)" }, { "title": "Training Module", "q": "Is the Training module easy to understand - Is the training delivery easy to understand" }, { "title": "Payout and Service Agreement", "q": "Is the Payout and Service Agreement Understood ?" }, { "title": "Product & Workflow Process", "q": "Have you been able to understand the product and the workflow process ?" }, { "title": "Training Duration", "q": "Are you comfortable with the duration of the training ?" }]
		}
		let obj = q_mapper[dvision];
		// console.log('getQuestiontype obj', obj);
		for (var k in obj) {
			if (obj[k]['q'] === q) {
				title = obj[k]['title'];
				// console.log('question matched', q);
				break;
			}
		}
		return title;
	}

	mapDatatoSubscriber(obj: any, division: string) {
		let table_data = this.default_table_data[division]();
		if (table_data) {
			for (var k in table_data) {
				if (table_data[k]['attribute'] && obj.hasOwnProperty(table_data[k]['attribute'])) {
					let loc_obj = obj[table_data[k]['attribute']];
					for (var p in loc_obj) {
						if (table_data[k].hasOwnProperty(p)) {
							table_data[k][p] = loc_obj[p];
						}
					}
				}
			}
		}
		// console.log('final table data', table_data);
		this.table_data_subscriber[division].next(table_data);
	}

	mapDatatoChartSubscribers(obj: any, division: string) {
		// console.log('chart data object', division, obj);
		let chart_data = this.default_chart_data[division]();
		for (var i in chart_data.data) {
			let name = chart_data.data[i]['name'];
			if (obj.hasOwnProperty(name)) {
				let t = 0;
				let a = _.each(obj[name], function (val, key) {
					// console.log('chart object each', key, name);
					if (key && key.length) {
						t += val;
					}
				});
				// console.log('chart data value', t);
				chart_data.data[i]['y'] = t;
			}
		}
		// console.log('Chart data', division, chart_data);
		this.chart_data_subscriber[division].next(chart_data);
	}

	applyTableSubscribers(obj: any, division: string) {
		if (obj && obj[division]) {
			let data = obj[division].data;
			if (data.length) {
				var arr = [];
				for (var i in data) {
					if (data[i].hasOwnProperty('feedback') && data[i]['feedback'].length) {
						for (var k in data[i]['feedback']) {
							var obj: any = {};
							obj['question'] = data[i]['feedback'][k]['question'];
							obj['answer'] = data[i]['feedback'][k]['answer'];
							obj['title'] = this.getQuestion_type(obj['question'], division);
							obj['key'] = this.getQuestionKeyforTitle(division, obj['title']);
							obj['call_id'] = data[i]['id'];
							arr.push(obj)
						}
					}
				}
				if (arr.length) {
					let obj: any = {};
					let p = _.groupBy(arr, 'answer');
					for (var k in p) {
						obj[k] = {}
						let a = _.groupBy(p[k], 'key');
						for (var t in a) {
							if (!obj[k][t]) {
								obj[k][t] = 0;
							}
							obj[k][t] += a[t].length;
						}
					}
					// console.log('printing training array', obj);
					this.mapDatatoSubscriber(obj, division);
					this.mapDatatoChartSubscribers(obj, division);
				}
			}
		}
	}

	initializeAllsbuscribers() {
		this.summary_data_dash.next(this.summary_data());
		this.total_chart_data.next(JSON.parse(JSON.stringify(this.divison_chart_data)));
		for (var i in this.tabs_data) {
			let key = this.tabs_data[i]['data_key'];
			this.table_data_subscriber[key].next(this.default_table_data[key]());
			this.chart_data_subscriber[key].next(this.default_chart_data[key]());
		}
	}

	applyDatatosubscribers(obj: any) {
		let summary = this.summary_data();
		this.api_data_response = obj;
		let chart_data = JSON.parse(JSON.stringify(this.divison_chart_data))
		if (obj.total) {
			if (obj.total.dialled) {
				summary.total_dialled = obj.total.dialled;
			}
			if (obj.total.rnr) {
				summary.total_rnr = obj.total.rnr;
			}
			if (obj.total.connected) {
				summary.total_connected = obj.total.connected;
			}
			if (obj.total.unique_contact) {
				summary.unique_contacted = obj.total.unique_contact;
			}
			if (obj.total.unique_dialled) {
				summary.unique_dialled = obj.total.unique_dialled;
			}
			if (obj.total.not_reachable) {
				summary.not_reachable = obj.total.not_reachable;
			}
			if (obj.total.wrong_number) {
				summary.wrong_number = obj.tota.wrong_number;
			}
			if (obj.total.callback) {
				summary.callback = obj.total.callback;
			}
		}

		if (obj['TRAINING']) {
			summary.divisons_contacted_unique['TRAINING'] = obj['TRAINING'].unique_contact;
			chart_data.data[0]['y'] = obj['TRAINING'].unique_contact;
		}
		if (obj['OJT']) {
			summary.divisons_contacted_unique['OJT'] = obj['OJT'].unique_contact;
			chart_data.data[1]['y'] = obj['OJT'].unique_contact;
		}
		if (obj['CERTIFICATION']) {
			summary.divisons_contacted_unique['CERTIFICATION'] = obj['CERTIFICATION'].unique_contact;
			chart_data.data[2]['y'] = obj['CERTIFICATION'].unique_contact;
		}
		if (obj['PRODUCTION']) {
			summary.divisons_contacted_unique['PRODUCTION'] = obj['PRODUCTION'].unique_contact;
			chart_data.data[3]['y'] = obj['PRODUCTION'].unique_contact;
		}
		if (obj['RETENTION']) {
			summary.divisons_contacted_unique['RETENTION'] = obj['RETENTION'].unique_contact;
			chart_data.data[4]['y'] = obj['RETENTION'].unique_contact;
		}
		if (obj['EMAIL']) {
			summary.divisons_contacted_unique['EMAIL'] = obj['EMAIL'].unique_contact;
			chart_data.data[5]['y'] = obj['EMAIL'].unique_contact;
		}

		this.summary_data_dash.next(summary);
		this.total_chart_data.next(chart_data);
	}

	getSummaryObject() {
		return this.summary_data_dash.asObservable();
	}

	getDashboardData(obj: dashboard_data_input) {
		this.initializeAllsbuscribers()
		let promise = new Promise((resolve, reject) => {
			this.API.getfk_emp_dashboard(obj).subscribe((data: any) => {
				if (data && data.success) {
					// console.log('data received', data.success);
					this.applyDatatosubscribers(data.success);
					for (var i in data.success) {
						// console.log('printig i in success', i);
						if (i != 'total') {
							this.applyTableSubscribers(data.success, i);
						}
					}
					resolve();
				} else {
					reject(data.errors)
				}
			}, (error: any) => {
				// alert('Error in the api call')
				reject(error)
			})
		})
		return promise;
	}
}
