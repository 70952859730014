<mat-card style="margin-top:10px;">
<div class="pad-LR">
<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">  
          <a routerLink="/managementTeam" > <h2 class="d-head">ADD NEW TRAINERS<img src="../assets/underline.svg"
            class="linebelow"> </h2> </a>
<br>
<div>
    <mat-button-toggle-group [ngClass]="'tgGroupClass'"  name="fontStyle" aria-label="Font Style" (change)="changebuttonToggle($event.value)">
        <mat-button-toggle [ngClass]="'tgClass'" style=" width:115px !important;" value="addBulkTrainers" checked>Add Bulk Trainers</mat-button-toggle>
        <mat-button-toggle [ngClass]="'tgClass'" style=" width:115px !important;" value="addSingleTrainer">Add Single Trainer</mat-button-toggle>
      </mat-button-toggle-group>
</div>
<br>
<div *ngIf="showHideAddBulkTrainers">

    <div class="row pdt">
        <div class="col-md-4 col-sm-12 col-xs-12">
            <mat-form-field  [ngClass]="'inputClr'">     
                <mat-select placeholder="SELECT AGENTS" multiple>
                  <mat-option value="option1">Option 1</mat-option>
                  <mat-option value="option2">Option 2</mat-option>
                  <mat-option value="option3">Option 3</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
                   <mat-form-field  [ngClass]="'inputClr'">
                <input matInput [matDatepicker]="startpicker" placeholder="DATE" readonly (click)="startpicker.open()">
                <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                <mat-datepicker #startpicker></mat-datepicker>
              </mat-form-field>
    
    
        </div>
      
    
    </div>
    <div class="row pdt">
        <div class="col-md-4 col-sm-12 col-xs-12">
           
                <mat-form-field  [ngClass]="'inputClr'">
                    <textarea matInput placeholder="Notes"></textarea>
                  </mat-form-field>
          
            </div>
            </div>
</div>
<div *ngIf="showHideAddSingleTrainers">

    <div class="row pdt">
        <div class="col-md-4 col-sm-12 col-xs-12">
            <mat-form-field  [ngClass]="'inputClr'">
                <input matInput placeholder="NAME">
              </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
            <mat-form-field  [ngClass]="'inputClr'">
                <input matInput placeholder="USER NAME">
              </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
            <mat-form-field  [ngClass]="'inputClr'">
                <input matInput placeholder="USER ID">
              </mat-form-field>
        </div>    
    </div>
        <div class="row pdt">
            <div class="col-md-4 col-sm-12 col-xs-12">
                <mat-form-field  [ngClass]="'inputClr'">
                    <input matInput placeholder="PHONE NUMBER">
                  </mat-form-field>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12">
                <mat-form-field [ngClass]="'inputClr'">     
                    <mat-select placeholder="STATUS">
                      <mat-option>None</mat-option>
                      <mat-option value="option1">Active</mat-option>
                      <mat-option value="option2">Inactive</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12">
                <mat-form-field [ngClass]="'inputClr'">     
                    <mat-select placeholder="LOCATION">
                      <mat-option>None</mat-option>
                      <mat-option value="Remote">Remote</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
</div> 
<br>
<div class="row pdt">         
    <div class="col-md-12 col-sm-12 col-xs-12 rightside">
        <button mat-button class="cancelbtn" (click)="backtoTrainerList()" >BACK</button>
        <button mat-button class="cancelbtn" >CANCEL</button>
        <button mat-button class="submitbtn">UPDATE</button>
    </div>          
    </div>
 

</div>
</div>

</div>
</mat-card>