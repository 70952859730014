<div style="padding: 3px 30px;">
	<div class="row">
		<div class="col-md-12 col-sm-17 col-xs-12" >
			<i class="fa fa-times-circle closeicon" style="position: absolute;right: -24px;top: -6px;" aria-hidden="true" (click)="closePopup()"></i>
		</div>
		<div class="col-md-12 col-sm-12 col-xs-12">
			<h3 class="d-head"> Recruiter Agent Details</h3>
		</div>
	</div>
<br>
		<div class="row">
			<div class="col-md-4">
				<p class="mb-1" style="color: #9b9b9b;">Name</p>
				<p style="color: black;word-break: break-all;">{{selectedagentdetail.name}}</p>
			</div>
			<div class="col-md-4">
				<p class="mb-1" style="color: #9b9b9b;">Email</p>
				<p style="color: black;word-break: break-all;">{{selectedagentdetail.email}}</p>
			</div>
			<div class="col-md-4">
				<p class="mb-1" style="color: #9b9b9b;">Phone</p>
				<p style="color: black;">{{selectedagentdetail.phone}}</p>
			</div>
		</div>
		<div  class="row pdt">
			<div class="col-md-4">
				<p class="mb-1" style="color: #9b9b9b;">Contacted</p>
				<p style="color: black;">{{selectedagent.contacted}}</p>
			</div>
			<div class="col-md-4">
				<p class="mb-1" style="color: #9b9b9b;">Unique_Dialled</p>
				<p style="color: black;">{{selectedagent.unique_dialled}}</p>
			</div>
		</div>
		<div class="row pt-2">

			<div class="col-md-4">
				<p class="mb-0" style="color: #9b9b9b;">Select Agent</p>
				<mat-form-field>
					<mat-label>Select Agent</mat-label>
					<mat-select [formControl]="toppings" multiple>
						<mat-option *ngFor="let topping of toppingList" [value]="topping.email">{{topping.email}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-4">

				<button mat-button style="margin: 22px 2px;padding: 3px 20px;" (click)="saveButton()">Data Transfer</button>
			</div>
		</div>

</div>
<!--<div id="cover-spin" *ngIf="currentLoader"></div>-->