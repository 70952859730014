import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/api.service';
import { MyserviceService } from '../../myservice.service';
import * as _ from 'underscore';
@Component({
  selector: 'app-campaign-details-agent-dialog',
  templateUrl: './campaign-details-agent-dialog.component.html',
  styleUrls: ['./campaign-details-agent-dialog.component.scss']
})
export class CampaignDetailsAgentDialogComponent implements OnInit {
	messageAlert:any="";
	showHide_messageAlert:boolean=false;
	greencss:any='red';

  input_obj:any;
  campaign_start_at: any = '';
	last_refreshed_at: any = '';
	agent_details: any = [];
	agent_table_arr = [];
	campaign_newAgents = [];
	_data = [];
	selected_all: boolean = false;
  currentLoader:boolean=false;
  login_user = this.myService.get_loggedin_user();
  _lastRefreshDate:any;
  api_end_point:any
  constructor(public dialogRef: MatDialogRef<CampaignDetailsAgentDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public _capmaign_data: any,private API: ApiService,private myService: MyserviceService) { }

  ngOnInit() {
	this.api_end_point = this.myService.get_API_End_Point();
    this.input_obj=(this._capmaign_data)._data;
    this._lastRefreshDate=(this._capmaign_data).lastRefreshDate;
    this.getAgentDataDetails();
  }
  closePopup(){
    this.dialogRef.close();
  }
  getAgentDataDetails() {
	this.showHide_messageAlert=false;
		this.currentLoader=true;
		let obj = {
			'campaign': this.input_obj['campaign'],
			'center_id': this.login_user.groupid,
			'priority': this.input_obj.priority,
			'agents_list': this.input_obj.agents_list
		}
		console.log(JSON.stringify(obj));
		this.API.getCampaignAgentsData(obj).subscribe((d: any) => {
			if (d && d.success) {
				this.currentLoader=false;
				let s = d.success;
				let p = _.flatten(_.values(s));
				this.agent_table_arr = p;
				let q = d.team;
				for (var i in q) {
					if (!s.hasOwnProperty(q[i]['email'])) {
						q[i]['add_selected'] = false;
						this.campaign_newAgents.push(q[i]);
						this._data.push(q[i])
					}
				}
			}
			else if(d.errors){
				//alert("Agents not found. So, data is not there.");
				this.messageAlert="Agents not found. So, data is not there.";
				this.showHide_messageAlert=true;
				this.currentLoader=false;
			}
		})
	}
  updateAllSelected() {
		this.selected_all = this.campaign_newAgents != null && this.campaign_newAgents.every(t => t.add_selected);
	}
	someSelected(): boolean {
		if (this.campaign_newAgents == null) {
			return false;
		}
		return this.campaign_newAgents.filter(t => t.add_selected).length > 0 && !this.selected_all;
	}
	setAll(select: boolean) {
		this.selected_all = select;
		if (this.campaign_newAgents == null) {
			return;
		}
		this.campaign_newAgents.forEach(t => t.add_selected = select);
	}
	addAgents_tocampaign() { 
		this.showHide_messageAlert=false; 
		//console.log('printing agents list', this.campaign_newAgents);
		let obj = {
            "agents_list": [],
            "campaign_name": this.input_obj['campaign'],
            "priority": this.input_obj['priority']
		}
		this.campaign_newAgents.forEach(t => {
			if (t.add_selected)
				obj.agents_list.push(t.email)
		})
		if (obj.agents_list.length) {
			this.API.addAgentstoCampaign(obj,this.api_end_point).subscribe((d: any) => {
				if (d.success) {
		 // alert('Agent add successfully');
		 this.messageAlert="Agent add successfully";
		 this.showHide_messageAlert=true;
		 this.greencss='green';
		 setTimeout(() =>{
			this.dialogRef.close(true);
		 }, 3000);
		 
         
				} else if(d.errors) {
					//alert(d.errors);
					this.messageAlert=d.errors;
					this.showHide_messageAlert=true;
					this.greencss='red';
				} else {
					//alert('Unknown error');
					this.messageAlert="Unknown error";
					this.showHide_messageAlert=true;
					this.greencss='red';
				}
			})
		} else {
			//alert('Select atlease one agent');
			this.messageAlert="Select atlease one agent";
					this.showHide_messageAlert=true;
					this.greencss='red';
		}
  }
  changeSearch(val:any){
	  this.campaign_newAgents.forEach(t => t.add_selected = false);
    this.campaign_newAgents= _.filter(this._data, function(item) { 
      return item.name.indexOf(val) != -1; 
   });

  // this.campaign_newAgents.filter(function(el) {
  //    return el.name === val;
  //  });

  }
  getSelectedAgentCount(){
    var a= this.campaign_newAgents.filter(t => {
      return t.add_selected==true;
})
return (a.length);
  }
}
