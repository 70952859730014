
    <div class="row">
        <div class="col-md-10">        
            <h3 class="d-head"> Data To Call <!--{{element.campaign}}--></h3>
            <p *ngIf="showHidePassingMessage" [ngStyle]="{'color':_color}" style="font-weight: bold;font-size: 20px;">{{passingMessage}}</p>
          </div>
          <div class="col-md-2">
            <button mat-button (click)="add_more_agent()">Add Agents</button>
          </div>
    </div>
<form [formGroup]="createdForm">
    <div class="row">
        <div class="col-md-12" *ngIf="column_addagent" style="padding-top: 5px;padding-bottom:15px;">
            
            <div class="col-md-7" style="float: left;">

                <div>                
                    <div id="agentlist" style="padding-top: 5px;">
                        <mat-list role="list">
                            <mat-list-item role="listitem" *ngFor="let hero of agents_list" >
                                <label><input type="checkbox" formControlName="agentdatalist" (click)="addlistcheck(hero)">&ensp;&nbsp;{{hero.email}}</label>
                        </mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>
            <div class="col-md-5" style="float: left;">
                <button mat-button (click)="addLocalCampaignAgents(createdForm)">Add</button>
            </div>
        </div>
    </div>
</form>
<form [formGroup]="agentcreatedForm">
    <div class="row">
        <div class="col-md-12 col-sm-7 col-xs-12">
        <table mat-table [dataSource]="dataSource">
            
                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <!--<mat-slide-toggle (change)="changed(element)"></mat-slide-toggle>-->
                        <mat-slide-toggle formControlName="activestatus" [checked]="element.active_status === 'true' ? true : false" (change)="setValue( i , $event )"></mat-slide-toggle>
                    </td>
                </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Agent Email </th>
                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                </ng-container>
                <ng-container matColumnDef="data">
                    <th mat-header-cell *matHeaderCellDef> Data to Call </th>
                    <td mat-cell *matCellDef="let element"> {{element.data}} </td>
                </ng-container>
                <ng-container matColumnDef="followup">
                    <th mat-header-cell *matHeaderCellDef> Followup ToDo </th>
                    <td mat-cell *matCellDef="let element"> {{element.followup}} </td>
                </ng-container>
                <ng-container matColumnDef="dialled">
                    <th mat-header-cell *matHeaderCellDef> Dialled </th>
                    <td mat-cell *matCellDef="let element"> {{element.dialled}} </td>
                </ng-container>
                <ng-container matColumnDef="contacted">
                    <th mat-header-cell *matHeaderCellDef> Contacted </th>
                    <td mat-cell *matCellDef="let element"> {{element.contacted}} </td>
                </ng-container>
                <ng-container matColumnDef="inactive">
                    <th mat-header-cell *matHeaderCellDef> Data Inactive </th>
                    <td mat-cell *matCellDef="let element"> {{element.inactive}} </td>
                </ng-container>
                <!--<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            
            <!--<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>-->
            <mat-paginator [length]="20" [pageSize]="5" [pageSizeOptions]="[5]"> </mat-paginator>
        </div>
    </div>

    <div class="row pdt" >      
        <div class="col-md-12 col-sm-12 col-xs-12 rightside">
            <button mat-button  (click)="closeDialog('')">Cancel</button>&nbsp;&nbsp;&nbsp;

            <button mat-button (click)="saveButton(agentcreatedForm)">Save</button>
        </div>
    </div>
</form>
<div id="cover-spin" *ngIf="currentLoader"></div>