import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { MenuComponent } from './menu/menu.component';
import { CommonModule } from '@angular/common';
import { TableComponent } from './utilities/table/table.component';
import { ProgressbarComponent } from './shared-components/progressbar/progressbar.component';
import { LogoutComponent } from './logout/logout.component';


@NgModule({
    declarations: [
        ProgressbarComponent,
        MenuComponent, 
        TableComponent,
        LogoutComponent],
    imports: [
        CommonModule,
        MaterialModule
    ],
    exports: [
        ProgressbarComponent,
        MenuComponent, 
        TableComponent,
        MaterialModule,
        LogoutComponent
    ]
})
export class SharedModule {

}