import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import * as _ from 'underscore';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/api.service';
import { MatInput } from '@angular/material/input';
import { Utils } from '../utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyserviceService } from '../myservice.service';
import { environment } from '../../environments/environment';
import { PaymentDashboardUploadDialogComponent } from '../payment-dashboard-upload-dialog/payment-dashboard-upload-dialog.component';

@Component({
	selector: 'app-payment-dashboard-events',
	templateUrl: './payment-dashboard-events.component.html',
	styleUrls: ['./payment-dashboard-events.component.scss']
})
export class PaymentDashboardEventsComponent implements OnInit {

	displayedColumns: string[] = ['date', 'owner', 'fileName', 'file_type', 'url'];
	dataSource: any = new MatTableDataSource();


	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	selectedCurYear: any;
	selectedMonth: any;
	table_data: any = [];
	_numberMonthCurrent: any;
	_historyRecord: any = [];
	currentLoader: boolean = false;
	NoHistoty: any = "";
	showHideNoHistotyMsg: boolean = false;
	localtime: any;
	access_token;
	api_end_point:any;
	@ViewChild('searchInput', {
		read: MatInput
	}) searchInput: MatInput;
	constructor(public dialog: MatDialog, private API: ApiService, private utils: Utils, private myService: MyserviceService,private _snackBar: MatSnackBar) { }

	ngOnInit() {
		this.api_end_point = this.myService.get_API_End_Point();
		this.getCurrentMonthYear();
		this.getTableData();
		this.access_token = this.myService.get_access_token();
		
	}

	getCurrentMonthYear() {
		let _aboutToday = new Date();
		// let _fullDate = _aboutToday.getUTCDate();
		let _getNowMonth = _aboutToday.toLocaleString('default', { month: 'long' });
		let _getCurrentMonth = _getNowMonth.substring(0, 3)
		let _getCurrentYear = _aboutToday.getFullYear();
		this.selectedMonth = _getCurrentMonth;
		this.selectedCurYear = _getCurrentYear.toString();

		this._numberMonthCurrent = _aboutToday.getMonth();

	}

	getFileType(report_type) {
		if (report_type == 'bulk_payment_upload') {
			return 'Earnings Upload';
		}
		if (report_type == 'payment_update') {
			return 'Payment Update';
		}
		return 'Others';
	}

	getTableData() {
		this.table_data = [];
		this.currentLoader = true;
		this.showHideNoHistotyMsg = false;
		let firstDay = new Date(this.selectedCurYear, this._numberMonthCurrent, 1);
		let lastDay = new Date(this.selectedCurYear, this._numberMonthCurrent + 1, 0);

		let d1 = moment(firstDay).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
		let d2 = moment(lastDay).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
		let obj = {
			"start_date": d1,
			"end_date": d2
		};
		this.API.getPaymentFiles(obj,this.api_end_point).subscribe((data: any) => {
			if (data.success) {
				this.currentLoader = false;
				this.table_data = [];
				for (var i in data.success) {
					let url = data.success[i]['url'].split('/');
					// console.log('url', url);
					this.table_data.push({
						//'date': this.utils.localConvert(data.success[i]['created_at'],'YYYY-MM-DDTHH:mm:ss', 'lll'),
						'date': moment(data.success[i]['created_at']).local().format('MMM Do YYYY, h:mm:ss A'),
						'url': data.success[i]['url'],
						'owner': this.myService.getAgentObject(data.success[i]['user_id']).name,
						'fileName': url.pop(),
						'file_type': this.getFileType(data.success[i]['report_type'])
					})
				}
				this.dataSource = new MatTableDataSource(this.table_data);
				this.dataSource.paginator = this.paginator;
			}
			if (data.errors) {
				this._snackBar.open(data.errors, "close");
				this.currentLoader = false;
				this.showHideNoHistotyMsg = true;
				this.dataSource = new MatTableDataSource();
				this.dataSource.paginator = this.paginator;
				setTimeout(() => {
					this.showHideNoHistotyMsg = false;
				}, 4000)
				return;
			}
			this.dataSource.paginator = this.paginator;

		})
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}

	changeYear(val: any) {
		this.selectedCurYear = "";
		this.selectedCurYear = val;
		this.getTableData();
		this.searchInput.value = '';

	}
	changeMonth(val: any) {
		this.selectedMonth = "";
		this.selectedMonth = val;
		if (this.selectedMonth == "Jan") { this._numberMonthCurrent = 0 }
		if (this.selectedMonth == "Feb") { this._numberMonthCurrent = 1 }
		if (this.selectedMonth == "Mar") { this._numberMonthCurrent = 2 }
		if (this.selectedMonth == "Apr") { this._numberMonthCurrent = 3 }
		if (this.selectedMonth == "May") { this._numberMonthCurrent = 4 }
		if (this.selectedMonth == "Jun") { this._numberMonthCurrent = 5 }
		if (this.selectedMonth == "Jul") { this._numberMonthCurrent = 6 }
		if (this.selectedMonth == "Aug") { this._numberMonthCurrent = 7 }
		if (this.selectedMonth == "Sep") { this._numberMonthCurrent = 8 }
		if (this.selectedMonth == "Oct") { this._numberMonthCurrent = 9 }
		if (this.selectedMonth == "Nov") { this._numberMonthCurrent = 10 }
		if (this.selectedMonth == "Dec") { this._numberMonthCurrent = 11 }

		this.getTableData();
		this.searchInput.value = '';
	}
	refreshBtn() {
		//this.currentLoader = true;
		this.getTableData();
		this.searchInput.value = '';
	}

	uploadExcelBtn() {
		const dialogRef = this.dialog.open(PaymentDashboardUploadDialogComponent, {
			width: '600px',
			height: '50%',
			disableClose: true,
			data: ""
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log("Dialog is closed");
			this.refreshBtn();
		});
	}

	download_file(obj) {
		// console.log('file obj', obj);
		window.open(obj.url+`?access_token=${this.access_token}`, "_blank");
	}
}
