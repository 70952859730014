<div class="mainScroll-1">
<div class="row">
    <div class="col-md-12 col-sm-7 col-xs-12 d-flex justify-content-between align-items-center head-underline">
        <h2 class="d-head "> {{dialogTitle}}</h2>
        <button mat-icon-button (click)="closeDialog()"><i class="fa fa-times-circle closeicondash"
                aria-hidden="true"></i></button>

    </div>
   <div class="col-md-12 text-right">

    <p *ngIf="showHideSlotChanged"  style="font-family: poppins;font-size:20px;color:#fd6c21;text-align: right; font-weight: 600;">Slot config changed..!!</p>
   </div>
    <div class="col-md-12 col-sm-7 col-xs-12 pl-0" *ngIf="!showSlotPreview && !showEditTimings && !showEditSlot">
        <div class="d-flex mt-3">
            <div class="d-flex flex-grow-1 flex-column">
                <span class="mx-3" style="color: #fd6c21;"><b>Working Days:</b> &nbsp;&nbsp;<span
                        *ngFor="let day of slotConfigWeek" > <span *ngIf="day.isSelected">{{ day.label }} </span></span></span>
                <span class="mx-3 mt-2" style="color: #fd6c21;"><b>Working Hours:</b>
                    &nbsp;{{master_work_timings[0]['ist_time']}} to {{master_work_timings[1]['ist_time']}}</span>
            </div>
          
            <div class="ml-auto p-2 bd-highlight"> <button class="btn btn-outline-secondary" (click)="switchEditTimings()">Edit Timings</button></div>
        </div>
    </div>
</div>
<div *ngIf="showEditSlot">
    <form [formGroup]="slotEditForm" >
        <div class="row pdt">
            <div class="col-md-5 col-sm-12 col-xs-12">
                <mat-form-field class="wdh">
                    <input matInput type="number" (keypress)="validateNegative($event)" min="0" placeholder="Enter Max Agents" formControlName="maxAgents">
                </mat-form-field>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 d-flex ">

                <div class=" p-2 bd-highlight ml-auto"> <button class="btn btn-secondary" (click)="editSlot()">Update</button></div>
            </div>
                </div>
                </form>
</div>
<div *ngIf="!showEditSlot">
<form [formGroup]="slotForm" *ngIf="!showSlotPreview && !showEditTimings">
    <p class="textAlertMessage" *ngIf="showHide_messageAlert">{{messageAlert}}</p>
    <div class="row pdt">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <mat-form-field>
                <input matInput [matDatepicker]="startpicker" [min]="todayDate" placeholder="From"
                    formControlName="slotCreateStartDate" readonly (click)="startpicker.open()">
                <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                <mat-datepicker #startpicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="ml-3">
                <input matInput [matDatepicker]="endpicker" [min]="todayDate" placeholder="To"
                    formControlName="slotCreateEndDate" readonly (click)="endpicker.open()">
                <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
                <mat-datepicker #endpicker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-md-5 col-sm-12 col-xs-12">
            <mat-form-field class="wdh">
                <input matInput type="number" (keypress)="validateNegative($event)" min=0 placeholder="Enter Max Agents" formControlName="maxAgents" required>
            </mat-form-field>
        </div>
        <div class="w-100"></div>
        <div class="col-md-5 col-sm-12 col-xs-12">
            <mat-form-field class="wdh">
                <input matInput type="number" (keypress)="validateNegative($event)"  min=1 placeholder="Enter Each Slot Duration (Hours):" (input)="maxInput()" formControlName="slotDuration" required>
            </mat-form-field>
        </div>
        <div class="w-100"></div>
  
        <div class="col-md-12 col-sm-12 col-xs-12">

            <button mat-button (click)="time_splitter()">Check Slot Timings</button>
        </div>

    

    </div>
</form>
    <div *ngIf="showEditTimings">
    <div class="w-100"></div>
    <div class="row pdt w-100">
        <div class="col-md-12 col-sm-12 col-xs-12 d-flex justify-content-between align-items-center">
            <mat-checkbox style="color: #455c6e;" (change)="toggle24x7( $event)" ><b>24 x 7 Working</b></mat-checkbox>
            <div class=" p-2 bd-highlight"> <button class="btn btn-secondary" (click)="saveCustomStages()">Save Timings</button></div>
        </div>
        <div class="col-md-8 col-sm-12 col-xs-12  d-flex flex-wrap">
            <div *ngFor="let d of slotConfigWeek;let i=index" style="min-width: 90px;">
                <mat-checkbox class="pr-4 py-2 darkenblue" [checked]="d.isSelected" 
                (change)="dayTimingChange(d,$event,i)">
                     {{ d.label }}
                </mat-checkbox>                        
              </div>
        </div>

    </div>
    <div class="row pdt w-100">
        <div class="col-md-4 col-sm-12 col-xs-12 slot-time">
            <p class="mb-0 darkenblue">Start time</p>
            <mat-form-field >
                <mat-label>00 hrs</mat-label>
                <mat-select [disabled]="select24x7" [value]="master_work_timings_dup[0]['hrs']" (selectionChange)="setStartTimeSetting($event,'hrs')">
                    <mat-option value='{{d}}' *ngFor="let d of hoursStartTimeHr" [value]="d">{{d}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="ml-3">
                <mat-label>00 mins</mat-label>
                <mat-select [disabled]="select24x7" [value]="master_work_timings_dup[0]['mins']" (selectionChange)="setStartTimeSetting($event,'mins')">
                    <mat-option value='{{d}}' *ngFor="let d of hoursStartTimeMin" [value]="d">{{d}}</mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 slot-time">
            <p class="mb-0 darkenblue">End time</p>
            <mat-form-field>
                <mat-label>00 hrs</mat-label>
                <mat-select [disabled]="select24x7" [value]="master_work_timings_dup[1]['hrs']" (selectionChange)="setEndTimeSetting($event,'hrs')">
                    <mat-option [value]="d" *ngFor="let d of hoursEndTimeHr">{{d}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="ml-3">
                <mat-label>00 mins</mat-label>
                <mat-select [disabled]="select24x7" [value]="master_work_timings_dup[1]['mins']" (selectionChange)="setEndTimeSetting($event,'mins')">
                    <mat-option [value]="d" *ngFor="let d of hoursEndTimeMin">{{d}}</mat-option>
                </mat-select>
            </mat-form-field>

        </div>
    </div>
</div>



<div class="row" *ngIf="showSlotPreview && !showEditTimings">
    <div class="w-100 pt-2"></div>
    <div class="col-md-6 col-sm-12 col-xs-12 py-2">
        <span style="color: #455c6e;font-size: 14px;"><b>Date Range:</b>&nbsp;&nbsp;{{reg_date_value}}
            [{{slot_date_difference}}
            days]</span>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 py-2">
        <span style="color: #455c6e;font-size: 14px;"><b>No.of Slots per day:</b>&nbsp;&nbsp;{{slots_per_day}}</span>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 py-2">
        <span style="color: #455c6e;font-size: 14px;"><b>Working Hours:</b>&nbsp;&nbsp;{{ work_timings[0] }} TO
            {{ work_timings[1] }} [ {{ work_hours_day }} hrs {{ work_minutes_day % 60 }} mins ]</span>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 py-2">
        <span style="color: #455c6e;font-size: 14px;"><b>Max.Duration of each
                slot:</b>&nbsp;&nbsp;{{slot_split_duration}} hours</span>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 py-2">
        <span style="color: #455c6e;font-size: 14px;"><b>Working Days:</b> &nbsp;&nbsp;<span
                *ngFor="let day of slotConfigWeek"> <span *ngIf="day.isSelected">{{ day.label }} </span>
            </span>
        </span>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12 py-2">
        <span style="color: #455c6e;font-size: 14px;"><b>Maximum Agents per
                slot:</b>&nbsp;&nbsp;{{max_agents_perslot}}</span>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 py-4">
        <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                style="box-shadow: none !important;overflow: auto;">
                <ng-container matColumnDef="sno">
                    <mat-header-cell *matHeaderCellDef> S No </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.sno }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.date }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="day">
                    <mat-header-cell *matHeaderCellDef> Day </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.day }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="maxAgents">
                    <mat-header-cell *matHeaderCellDef> Max Agents </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-form-field style="width: 50px;">
                            <input matInput type="number" placeholder="" value="{{element.maxAgents}}" (change)="changeMaxAgents($event,element.sno)">
                        </mat-form-field>
                    </mat-cell>

                </ng-container>
                <ng-container matColumnDef="startTime">
                    <mat-header-cell *matHeaderCellDef> Slot Start Time (IST) </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.startTime }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="endTime">
                    <mat-header-cell *matHeaderCellDef> Slot End Time (IST) </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.endTime }}</mat-cell>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 d-flex ">

        <div class=" p-2 bd-highlight ml-auto"> <button class="btn btn-secondary" (click)="createSlot()">Submit</button></div>
    </div>
</div>
</div>
</div>