import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-call-radiobutton',
  templateUrl: './call-radiobutton.component.html',
  styleUrls: ['./call-radiobutton.component.scss']
})
export class CallRadiobuttonComponent implements OnInit {

  @Input()
  public updateRadio: Function;
  @Input()
  public triggnerInnerRadio: Function;
    @Input()
  public removeSubSection: Function;
  @Input()
  public untitleToggle: Function;
  @Input() selectedLevel: any;
  @Input() selectedIndex: any;
  @Input() selectedChildIndex: any;
  @Input() radioIndex: any;
@Input() checked: any;
  @Input() prevCallFlow:any;
  @Input() triggeredAction:any;

  constructor() { }
  dynamicArray: Array<DynamicGrid> = [];
  newDynamic: any = {};
  ngOnInit() {
    this.newDynamic = "YES";
    this.dynamicArray.push(this.newDynamic);
    if(this.selectedLevel == 2 && this.prevCallFlow[ this.selectedIndex]['child'][this.selectedChildIndex][this.triggeredAction][this.radioIndex] && this.prevCallFlow[ this.selectedIndex]['child'][this.selectedChildIndex][this.triggeredAction][this.radioIndex]['rb_data']){
     // console.log(this.prevCallFlow[ this.selectedIndex]['child'][this.selectedChildIndex][this.triggeredAction][this.radioIndex])
      this.dynamicArray = this.prevCallFlow[ this.selectedIndex]['child'][this.selectedChildIndex][this.triggeredAction][this.radioIndex]['rb_data']
    }else{
    this.updateRadio(this.dynamicArray, this.selectedIndex, this.selectedLevel,this.radioIndex, this.selectedChildIndex)
    }
    

  }
  customTrackBy(index: number, obj: any): any {
    return index;
  }
  addRow() {
    this.newDynamic = this.dynamicArray.length == 1 ? "NO" : this.dynamicArray.length == 2 ? "CALLBACK" : "";
    this.dynamicArray.push(this.newDynamic);
    this.updateRadio(this.dynamicArray, this.selectedIndex, this.selectedLevel,this.radioIndex, this.selectedChildIndex)
    return true;
  }

  deleteRow(index) {
    if (this.dynamicArray.length == 1) {
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      return true;
    }
  }
  actionTypeChange(val: any) {
   // console.log(val);
    this.triggnerInnerRadio(val, this.selectedIndex, this.selectedChildIndex, this.selectedLevel, this.radioIndex);
  }
  modelChange(event, index){
    this.updateRadio(this.dynamicArray, this.selectedIndex, this.selectedLevel, this.radioIndex, this.selectedChildIndex)

  }
  trigerRemoveSubSection(){
   // console.log(this.selectedIndex)
    this.removeSubSection(this.selectedIndex,this.selectedChildIndex, this.selectedLevel,this.radioIndex)
  }
  triggerToggle(event){
    //console.log(this.selectedIndex)
    this.untitleToggle(this.selectedIndex,event, this.selectedLevel,this.selectedChildIndex, this.radioIndex)
  }
}
export class DynamicGrid {
  inputOne: any;
} 