import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import { ApiService } from '../api.service';
import { Utils } from '../utils';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-dashboard-training',
  templateUrl: './dashboard-training.component.html',
  styleUrls: ['./dashboard-training.component.scss']
})
export class DashboardTrainingComponent implements OnInit {
  currentTitle:any="Training";
  chart :any; 
  donutChartrecord:any;
  utils: any;
  _productionActive:any;
  _productionInActive:any;
  _ojtActive:any;
  _ojtInActive:any;
  _trainingActive:any;
  _trainingBatches:any;
  _activeTrainers:any;
   _availTrainers:any;
   api_end_point:any;
   
  constructor(private API: ApiService,private myService: MyserviceService) { 
		this.utils = new Utils();
	}

  ngOnInit() {
     this.getDataOfDashboard();
	 this.api_end_point = this.myService.get_API_End_Point();
  }
    getDataOfDashboard(){
    this.API.getTrainingDashboard(this.api_end_point).subscribe((data:any)=>{
      let _response=data.success;
      if(_response){
        if(data.success["PRODUCTION"]){
        this._productionActive=_response["PRODUCTION"]["Active Agents"];
        this._productionInActive=_response["PRODUCTION"]["Inactive Agents"];
        }
        if(_response["OJT+CERTIFICATION"]){
          this._ojtActive=_response["OJT+CERTIFICATION"]["Active Agents"];
          this._ojtInActive=_response["OJT+CERTIFICATION"]["Inactive Agents"];
          }
          if(_response["TRAINING"]){
            this._trainingActive=_response["TRAINING"]["Active Agents"];
            this._trainingBatches=_response["TRAINING"]["Batches"];
            }
            if(_response["TOTAL TRAINERS"]){
              this._activeTrainers=_response["TOTAL TRAINERS"]["Active Trainers"];
              this._availTrainers=_response["TOTAL TRAINERS"]["Available Trainers"];
              }
                
     this.donutChart();
      }
    }
    )
    
      
  }
   donutChart(){
    let chartdata = new Chart({ 
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },    
      plotOptions: {
        pie: {
          cursor: 'pointer',
          dataLabels: {
            distance:-30, 
            enabled: false          
          },
          showInLegend: false,
          
        }
      },
    
      series: [
        {  states: {
          inactive: {
            opacity: 1
          },
          hover: {
          enabled: false
          }
          },
          name: 'Active',       
          data: [
            {
              name: 'Production',
              y: this._productionActive,
              color:'#E0DAC2'
            },
            {
              name: 'OJT + Certification',
              y: this._ojtActive,
              color:'#F6C054'
            }, {
              name: 'Training',
              y: this._trainingActive,
              color:'#A9BA45'
            }],
          type: 'pie',
          innerSize: '50%',
        }]
    });
    this.donutChartrecord=chartdata;


}
}

