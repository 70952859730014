import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MyserviceService } from '../myservice.service';
import { SocketelsService } from '../socketels.services';
import { RtddataService } from '../rtdData.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CentralDashboardActiveTimePageComponent } from '../central-dashboard-active-time-page/central-dashboard-active-time-page.component'
import { ApiService } from '../api.service';
import { MatDialog } from '@angular/material/dialog';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
	selector: 'app-central-dashboard-agents-page-dialog',
	templateUrl: './central-dashboard-agents-page-dialog.component.html',
	styleUrls: ['./central-dashboard-agents-page-dialog.component.scss']
})
export class CentralDashboardAgentsPageDialogComponent implements OnInit {

	showLoader: boolean = false;
	currentTitle: string = 'BIdashboard';
	selectedButtonToggleVal: any = 'dataTab';

	showHideDataTabPage: boolean = true;
	showHideDialedTabPage: boolean = false;
	showHideContactedTabPage: boolean = false;
	showHideAgentsTabPage: boolean = false;
	showHideActiveTimeTabPage: boolean = false;
	showHideQdeTabPage: boolean = false;
	isASM_selected:boolean = false;
	api_end_point: any;
	asm_details: any;
	asm_groupid: any;
	constructor(private socket: SocketelsService,private rtd: RtddataService,public dialogRef: MatDialogRef<CentralDashboardActiveTimePageComponent>, @Inject(MAT_DIALOG_DATA) public asm_name, private service: MyserviceService, private API: ApiService,private dialog: MatDialog) 
	{
		
	}

	ngOnInit(): void  {
		this.api_end_point = this.service.get_API_End_Point()
		this.showLoader = true;
		this.asm_details = this.asm_name;
		console.log("--1--", JSON.stringify(this.asm_details));
		let obj = {
			id: this.asm_details.id
		}
		this.API.getcmail_usersby_id(obj, this.api_end_point).subscribe(
			(res: any) => {
				// debugger;
				if (res.error) {
					console.log(res.error);
				}
				else {
					//this.asm_groupid = res.group_id;
					let obj = {
						group_id: res.groupid
					}
					this.service.set_asm_groupid(obj.group_id);
					//console.log(res.groupid);
					 this.API.getGroupwiseUser(obj, this.api_end_point).subscribe(
						(res: any) => {
							// debugger;
							if (res.error) {
								console.log(res.error);
							}
							else {
								//console.log(res.success);
								let copy_data = res.success;
								let filtered_data = copy_data.filter((e)=>{
									return (e.role == "user" && e.groupid ==obj.group_id)
								})
								//console.log(filtered_data);
								//this.service.set_asm_groupdata(group_data);
								this.service.set_asm_groupdata(filtered_data);
								this.service.set_asm_groupid(obj.group_id);
								 this.service.groupwise_getSupervisorsData(res.groupid,this.api_end_point).then(() => {
									this.socket.isSocketEnable.subscribe(flag => {
										//console.log('socket enabled', flag);
										if (flag) {
											this.socket.emitUsersrequest();
											this.socket.emitGroupRequest();
											this.showLoader = false;
										}
									})
								})
							}
						})
				}
			})
		
	}
	onToggleButtonValChange(val: string) {

		this.selectedButtonToggleVal = val;
		this.showHideDataTabPage = false;
		this.showHideDialedTabPage = false;
		this.showHideContactedTabPage = false;
		this.showHideAgentsTabPage = false;
		this.showHideActiveTimeTabPage = false;
		this.showHideQdeTabPage = false;
		if (val == 'dataTab') {
			this.showHideDataTabPage = true;
		}
		else if (val == 'dialedTab') {
			this.showHideDialedTabPage = true;
		}
		else if (val == 'contactedTab') {
			this.showHideContactedTabPage = true;
		}
		else if (val == 'agentsTab') {
			this.showHideAgentsTabPage = true;
		}
		else if (val == 'activeTimeTab') {
			this.showHideActiveTimeTabPage = true;
		}
		else if (val == 'qdeTab') {
			this.showHideQdeTabPage = true;
		}
	}
	getCurrentDate() {
		let myDate = new Date();
		let daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Aug', 'Oct', 'Nov', 'Dec'];
		let date = myDate.getDate();
		let month = monthsList[myDate.getMonth()];
		let year = myDate.getFullYear();
		let day = daysList[myDate.getDay()];
		let today = `${date} ${month} ${year}, ${day}`;
		return today;
	}
	closeDialog() {
		this.dialogRef.close("true");
		this.rtd.agentFeed.next({});
		//this.rtd.groupFeed.next({});
		this.service.SUPERVISOROBJ.next(null);
		this.service.remove_previous_groupid();
		//this.service.remove_previous_groupdata();
	}

}
