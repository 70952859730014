<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span  style="position: absolute;top: -36px;right: 15px;z-index: 2;">
            <mat-icon class="close_cs" (click)="closeDialog()">cancel</mat-icon></span>
        </div>
</div>
<div class="row pt-3">
    <div class="col-md-12 col-sm-12 col-xs-12">

        <h2 class="d-head" style="padding-left: 20px;">PREVIEW</h2>
    </div>
    <div  class="col-md-12 col-sm-12 col-xs-12">
      <!-- <div class="vvtree">
        <div class="node"  *ngFor = "let v of   fetchDataFlowData">
          <span>{{v.title}}</span> 
          <small class="iclasCs">{{v.edgeText}}</small>
        </div>
      </div> -->


    <ul class="PRtree">
      <li *ngFor="let v of itemsjsonDataTree;let i=index;" style="border-bottom: 3px solid #f18f1a;margin-top: 20px;">
        <input type="checkbox" checked="checked" id="{{v.id}}" />
        <label class="tree_label" for="{{v.id}}"><b style="color: royalblue;">ID: {{v.id}}</b></label>
        <ul>
          <li>
            <input type="checkbox" checked="checked" id="{{v.id}}_1" />
            <label for="{{v.id}}_1" class="tree_label"><b class="boldy1">experience: </b> {{v.experience}}</label>
            <!-- <ul>
              <li><span class="tree_label">Sub Level 1</span></li>
            
            </ul> -->
          </li>
          

          <li>
            <input type="checkbox" checked="checked" id="{{v.id}}_2" />
            <label for="{{v.id}}_2" class="tree_label"><b  class="boldy1">channel:</b> {{v.channel}}</label>
          </li>


         <li>
            <input type="checkbox" checked="checked" id="{{v.id}}_3" />
            <label for="{{v.id}}_3" class="tree_label"><b  class="boldy1">event:</b> {{v.event}}</label>
          </li>

          <li>
            <input type="checkbox" checked="checked" id="{{v.id}}_4" />
            <label for="{{v.id}}_4" class="tree_label"><b  class="boldy1">touchpoint:</b> {{v.touchpoint}}</label>
          </li>

          <li>
            <input type="checkbox" checked="checked" id="{{v.id}}_5" />
            <label for="{{v.id}}_5" class="tree_label"><b  class="boldy1">next_touchpoint:</b> {{v.next_touchpoint}}</label>
          </li>

          <li>
            <input type="checkbox" checked="checked" id="{{v.id}}_6" />
            <label for="{{v.id}}_6" class="tree_label"><b  class="boldy1">nb_pre_action: </b> {{v.nb_pre_action}}</label>
          </li>

          <li>
            <input type="checkbox" checked="checked" id="{{v.id}}_7" />
            <label for="{{v.id}}_7" class="tree_label"> <b  class="boldy1">nb_post_action: </b> {{v.nb_post_action}}</label>
          </li>



          <li>
            <input type="checkbox" checked="checked" id="{{v.id}}_8" />
            <label for="{{v.id}}_8" class="tree_label"> <b  class="boldy1">action_data: </b> {{v.action_data}}</label>
          </li>

          
          <li>
            <input type="checkbox" checked="checked" id="{{v.id}}_9" />
            <label for="{{v.id}}_9" class="tree_label"> <b  class="boldy1">nb_state_update: </b> {{v.nb_state_update}}</label>
          </li>

          <li>
            <input type="checkbox" checked="checked" id="{{v.id}}_10" />
            <label for="{{v.id}}_10" class="tree_label"> <b  class="boldy1">nb_metric_update: </b> {{v.nb_metric_update}}</label>
          </li>


       
        </ul>
      </li>
      </ul>

    </div>

























<!-- 
    <div style="display:block;justify-content:center;width: 100%; display:none;">
      <nav class="vkk-nav" >
      <ul  >
        <li *ngFor="let v of flowData">
        <a>{{v.title}}</a>
        <ul>
        <li *ngFor="let v1 of v.data">
          <a>{{v1.title}}</a>
          <span class="edgeSpan1">{{v1.edge}}</span>
      
          <ul>
          <li *ngFor="let v2 of v1.data">
            <a>{{v2.title}}</a>
            <span  class="edgeSpan1">{{v2.edge}}</span>
            <ul>
            <li *ngFor="let v3 of v2.data">
              <a>{{v3.title}}</a>
              <span  class="edgeSpan1">{{v3.edge}}</span>
            </li>
          </ul>
          </li>
        </ul>
        </li>
      </ul>
      </li>
      </ul>
      </nav>
      </div> -->



 <div>
   <!-- <ngx-graph
     class="chart-container"
     [view]="[800, 850]"
     [links]="links"
     [nodes]="nodes"
     layout="d3ForceDirected" style="display: none;"
  >
    <ng-template #defsTemplate>
      <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4" orient="auto">
        <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
      </svg:marker>
    </ng-template>

    <ng-template #clusterTemplate let-cluster>
      <svg:g class="node cluster">
        <svg:rect
          rx="5"
          ry="5"
          [attr.width]="cluster.dimension.width"
          [attr.height]="cluster.dimension.height"
          [attr.fill]="cluster.data.color"
        />
      </svg:g>
    </ng-template>

    <ng-template #nodeTemplate let-node>
      <svg:g
        (click)="onNodeClick($event)"
        (dblclick)="onNodeClick($event)"
        class="node"
        ngx-tooltip
        [tooltipPlacement]="'top'"
        [tooltipType]="'tooltip'"
        [tooltipTitle]="node.label"
      >
        <svg:rect
          [attr.width]="node.dimension.width"
          [attr.height]="node.dimension.height"
          [attr.fill]="node.data.color"
        />
        <svg:text alignment-baseline="central" [attr.x]="10" [attr.y]="node.dimension.height / 2">
          {{node.label}}
        </svg:text>
      </svg:g>
    </ng-template>

    <ng-template #linkTemplate let-link>
      <svg:g class="edge">
        <svg:path class="line" stroke-width="1" marker-end="url(#arrow)"></svg:path>
        <svg:text class="edge-label" text-anchor="middle">
          <textPath
            class="text-path"
            [attr.href]="'#' + link.id"
            [style.dominant-baseline]="link.dominantBaseline"
            startOffset="100%"
          >
            {{link.label}}
          </textPath>
        </svg:text>
      </svg:g>
    </ng-template>
  </ngx-graph> -->

 </div>
    </div>
