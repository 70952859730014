<mat-drawer-container autosize>
    <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
        <app-menu  [navigateLink]="currentTitle" ></app-menu>
    </mat-drawer>

<div class="CN-Main sbPad">
    <p class="CN-Tab-head">Shopify Billing</p>
    <div>
        <div class="row sandboxTabList">
            <div class="col-md-12">
                <p>contiinex-shopify-billing works!</p>
            </div>
        </div>

    </div>
</div>
</mat-drawer-container>
<div id="cover-spin" *ngIf="currentLoader"></div>