<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span  style="position: absolute;top: -36px;right: 15px;z-index: 2;cursor: pointer;">
            <img src="../assets/closeicon.svg" width="40px" style="position: fixed;" (click)="closeDialog()"/></span>
        </div>
</div>
<form [formGroup]="createdForm">

<div class="row pdt">
    <div class="col-md-12 col-sm-7 col-xs-12">
        <h2 class="d-head">Auditors Configuration<img src="../assets/underline.svg" class="linebelow"> </h2>
        <div class="rightside" *ngIf="showHidePassingMessage">
            <h4  [style.color]="_color" style="font-weight: bold;font-size: 17px;">{{passingMessage}}</h4>
          
             </div>
        <div>
              <div class="row pdt">
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field  [ngClass]="'inputClr'">
                        <input matInput placeholder="Project Name" formControlName="projectName" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <mat-form-field style="width: 100%;" [ngClass]="'inputClr'">
                        <mat-select placeholder="Select Auditors"  formControlName="auditors" multiple (selectionChange)="auditorChange($event.value)">
                            <mat-option value='{{d.name}}'  *ngFor="let d of auditors">{{d.name}}</mat-option>
                          </mat-select>
                        <!-- <mat-select placeholder="Select Auditors" multiple>
                         <mat-option value="Auditor1">Auditor 1</mat-option>
                         <mat-option value="Auditor2">Auditor 2</mat-option>
                         <mat-option value="Auditor3">Auditor 3</mat-option>
                         <mat-option value="Auditor4">Auditor 4</mat-option>
                       </mat-select> -->
                   </mat-form-field>
                </div>
                      
            </div>
            <!-- <div class="row pdt">
                <div class="col-md-6 col-sm-12 col-xs-12"></div>
                <div class="col-md-6 col-sm-12 col-xs-12 rightside">
                    <button mat-button class="cancelbtn">Cancel</button> &nbsp;&nbsp;
                    <button mat-button class="submitbtn" >Add</button>
                </div>
            </div> -->
            <h6 class="pdt">Payment Mode</h6>
            <div class="row pdt">
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <mat-radio-group formControlName="paymentMode" style="display: inline-flex;font-size: 13px;">
                        <mat-radio-button value="Normal">Normal &nbsp;&nbsp;</mat-radio-button>
                        <mat-radio-button value="Gig Workforce"> Gig Workforce </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <!-- <h6 class="pdt">Mark project as finish</h6> -->
            <div class="row pdt">
                <div class="col-md-4 col-sm-12 col-xs-12">
                <mat-checkbox style="display: inline-flex;font-size: 13px;" [disabled]="submitStatus" formControlName="projectEndDate"><span>End this project</span>
                </mat-checkbox>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="row pdt">
    <div class="col-md-6 col-sm-12 col-xs-12"></div>
    <div class="col-md-6 col-sm-12 col-xs-12 rightside">
        <button mat-button class="cancelbtn" (click)="closeDialog()">Cancel</button> &nbsp;&nbsp;
        <button mat-button class="submitbtn" (click)="updateProject(createdForm)" [disabled]="submitStatus">Update</button>
    </div>
</div>
</form>
