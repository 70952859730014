import { Component, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import * as _ from 'underscore';


import { ServicesService } from '../../dashboard/services.service';


@Component({
  selector: 'app-campaignv2',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, AfterViewInit {

	campaignSummary: any;

	@Output() refreshCampaign:EventEmitter<any> = new EventEmitter()

	constructor(
		private dashService: ServicesService
	) {
		this.campaignSummary = this.dashService.campaignSummary_default();
	 }

	ngOnInit() {
		// debugger;
		this.campaignSummary = this.dashService.campaignSummary_default();
		this.refreshCampaign.emit(null);
		this.dashService.campaignSummaryData.subscribe(
			(d: any) => {
				this.campaignSummary = d;
				// debugger;
			}
		)
	}

	ngAfterViewInit() {
		// debugger;
		this.campaignSummary = this.dashService.campaignSummary_default();
		this.dashService.campaignSummaryData.subscribe(
			(d: any) => {
				this.campaignSummary = d;
				// debugger;
			}
		)
	}

	getAgencyData(obj: any) {
		let loc_obj = {};
		if (obj) {
			let keys = _.keys(obj);
			for (var i = 0; i < 3; i++){
				loc_obj[keys[i]] = obj[keys[i]]
			}
		}
		return loc_obj;
	}

}
