import { Component, OnInit } from '@angular/core';
import { MyserviceService } from '../myservice.service';
import { SocketelsService } from '../socketels.services';
import { RtddataService } from '../rtdData.service';
@Component({
	selector: 'app-badashboard',
	templateUrl: './badashboard.component.html',
	styleUrls: ['./badashboard.component.scss']
})
export class BADashboardComponent implements OnInit {

	showLoader: boolean = false;
	currentTitle: string = 'BIdashboard';
	selectedButtonToggleVal: any = 'dataTab';

	showHideDataTabPage: boolean = true;
	showHideDialedTabPage: boolean = false;
	showHideContactedTabPage: boolean = false;
	showHideAgentsTabPage: boolean = false;
	showHideActiveTimeTabPage: boolean = false;
	showHideQdeTabPage: boolean = false;
	api_end_point:any;
	constructor(private socket: SocketelsService, 
		private service: MyserviceService) {
	}

	ngOnInit() {
		this.showLoader =true;
		this.api_end_point = this.service.get_API_End_Point();
		this.service.getSupervisorsData(this.api_end_point).then(()=>{
			this.socket.isSocketEnable.subscribe(flag => {
			//	console.log('socket enabled', flag);
				if(flag){
					this.socket.emitUsersrequest();
					this.socket.emitGroupRequest();
					this.showLoader =false;
				}
			})
		})
	}
	
	onToggleButtonValChange(val: string) {

		this.selectedButtonToggleVal = val;
		this.showHideDataTabPage = false;
		this.showHideDialedTabPage = false;
		this.showHideContactedTabPage = false;
		this.showHideAgentsTabPage = false;
		this.showHideActiveTimeTabPage = false;
		this.showHideQdeTabPage = false;
		if (val == 'dataTab') {
			this.showHideDataTabPage = true;
		}
		else if (val == 'dialedTab') {
			this.showHideDialedTabPage = true;
		}
		else if (val == 'contactedTab') {
			this.showHideContactedTabPage = true;
		}
		else if (val == 'agentsTab') {
			this.showHideAgentsTabPage = true;
		}
		else if (val == 'activeTimeTab') {
			this.showHideActiveTimeTabPage = true;
		}
		else if (val == 'qdeTab') {
			this.showHideQdeTabPage = true;
		}
	}
	getCurrentDate() {
		let myDate = new Date();
		let daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Aug', 'Oct', 'Nov', 'Dec'];
		let date = myDate.getDate();
		let month = monthsList[myDate.getMonth()];
		let year = myDate.getFullYear();
		let day = daysList[myDate.getDay()];
		let today = `${date} ${month} ${year}, ${day}`;
		return today;
	}
}


