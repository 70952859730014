
  <div class="row  mt-1">
  <div  class="col-md-12 col-sm-12 col-xs-12 text-center" style="color:gray">
      <p><button class="nonecss"> <mat-icon style="font-size: 50px;">delete_sweep</mat-icon></button></p>
      <p>Do you really want to delete ?<br>
        Tittle 1</p>
  </div>
    <div class="col-md-12 col-sm-12 col-xs-12 text-center mt-3">
    
        <button class="prevAdd mt-0 ml-3" style="color:gray !important;border:1px solid gray !important" mat-button (click)="closePopup()">
            Cancel
        </button>

        <button class="prevAdd mt-0 ml-3" style="color:#dd133c !important;border:1px solid #dd133c !important" mat-button (click)="deleteItem('true')">
            <mat-icon>cancel</mat-icon>DELETE
        </button>
        </div>
        </div>