<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span style="position: absolute;top: -36px;right: 15px;z-index: 2;cursor: pointer;">
            <img src="../assets/closeicon.svg" width="40px" style="position: fixed;" (click)="closeDialog()" /></span>
    </div>
</div>
<p class="textAlertMessage"  [ngStyle]="{'color':_color}" style="font-size: 18px;" *ngIf="showHide_messageAlert">{{messageAlert}}</p>
<form [formGroup]="messageForm">

    <div class="row pdt">
        <div class="col-md-7 col-sm-7 col-xs-12">

            <h2 class="d-head">MESSAGE<img src="../assets/underline.svg" class="linebelow"> </h2>
        </div>

    </div>
    <div class="row pdt">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <mat-form-field>
                <input matInput placeholder="Subject" formControlName="subject">
            </mat-form-field>
        </div>
    </div>
    <div class="row pdt">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <mat-form-field>
                <textarea matInput placeholder="Message" formControlName="body"></textarea>
            </mat-form-field>
        </div>

    </div>


    <div class="row pdt">

        <div class="col-md-12 col-sm-12 col-xs-12 rightside">
            <button mat-button class="cancelbtn" (click)="closeDialog()">Cancel</button>
            <button mat-button class="submitbtn" (click)="sendNotification(messageForm)">SEND NOTIFICATION
            </button>
        </div>

    </div>
</form>