
<div class="row" style="padding: 31px 12px 31px 12px;">
    <div class="col-md-12">
        <div class="m-div" style="height:auto">
            <p><span class="s-hd" style="width: 270px;">Availabile/Online Agent to callback</span></p>

            <div class="row" style="height:100px">
                <div class="col-md-6 " style="line-height: 40px;">
                    <mat-checkbox [ngClass]="'cb-WH'" style="color:gray" class="pl-3">Choose Call Back Time</mat-checkbox><br>                  
                          <div class="pl-4 pt-2">
                            <mat-checkbox [ngClass]="'cb-WH'"  style="color:gray" class="pl-3">Morning</mat-checkbox>&nbsp;&nbsp;
                            <mat-checkbox [ngClass]="'cb-WH'"  style="color:gray" class="pl-3">Afternoon</mat-checkbox>&nbsp;&nbsp;
                            <mat-checkbox [ngClass]="'cb-WH'"  style="color:gray" class="pl-3">Evening</mat-checkbox>&nbsp;&nbsp;
                            <mat-checkbox [ngClass]="'cb-WH'"  style="color:gray" class="pl-3">Night</mat-checkbox>&nbsp;&nbsp;
                          </div>
                </div>
            
            </div>
<div style="border-bottom: 2px dashed #8d8d8d;">
&nbsp;
</div>
<br>
            <div class="row">
                <div class="col-md-12 text-right" style="position: relative;
                right: 20px;">
                    <button class="saveSubmit">SAVE</button>
                </div>
            </div>
            <br>

        </div>
    </div>
</div>