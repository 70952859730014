import { Component, OnInit,ElementRef,Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router'
import * as moment from 'moment';
import * as _ from 'underscore';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { filter } from 'rxjs/operators';

import { CampaignDialogComponent } from '../campaign-v2/campaign/campaign-dialog/campaign-dialog.component';
import { CampaignAgentsComponent } from '../campaign-v2/campaign-agents/campaign-agents.component';
import { DashboardAddmoreDialogComponent } from '../dashboard-addmore-dialog/dashboard-addmore-dialog.component';
import { DashboardAddagentComponent } from '../dashboard-addagent/dashboard-addagent.component';
import { ServicesService } from '../dashboard/services.service';
import { ApiService } from '../api.service';
import { MyserviceService } from '../myservice.service';

import { Utils } from '../utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DownloadAttachmentComponent} from '../campaign-v2/download-attachment/download-attachment.component';


export interface campaignTableRow {
	'sl_no': number,
	'campaign_name': string,
	'campaign_status': boolean,
	'campaign_created': string,
	'data_uploaded': number,
	'data_duplicate': number,
	'errors': number,
	'data_added': number,
	'contacted': number,
	'agency_name': string,
	'downloadReports': any
}

@Component({
  selector: 'app-centralcampaign',
  templateUrl: './centralcampaign.component.html',
  styleUrls: ['./centralcampaign.component.scss']
})


export class CentralcampaignComponent implements OnInit  {

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('_searchValue') _searchValue : ElementRef;

	currentTitle: string = 'central-campaign';
	selectAgencies: string = '';
	today = moment().format('DD-MMM-YYYY');
	showCampaign: boolean = true;
	showRecruiter: boolean = false;
	showHiring: boolean = false;
	showLoader: boolean = false;
	isCampbtnDisable: boolean = false;
	user: any = this.myservice.get_loggedin_user();
	current_campaigns = null;
	show_details: boolean = false;
	currentRoute: string = "";
	jsonTemp: string = "";
	months_filter: any = this.utils.getCampaignMonthDropdown();
	selected_month = this.months_filter[0];
	api_end_point:any;
  All_campaign_summary:any;
  List_campaign_name:any;
	displayedColumnsCampaigan: string[] = ['sl_no', 'campaign_name', 'camapign_owner','incallscreen','work_type','campaign_status', 'last_updated', 'campaign_created',
		'data_uploaded', 'agents_count', 'data_duplicate', 'errors', 'data_added', 'data_dialled', 'contacted', 'rechurn','actionViewMore', 'downloadReports']; //'data_source'

	public dataSourceCampaign: any = new MatTableDataSource();

	summary_default = {
		'total': {
			'total': 0,
			'data': 0,
			'called': 0,
			'to_call': 0,
			'data_percent': 100,
			'called_percent': 0,
			'to_call_percent': 0,
      'Total Errors':0,
      'Total Repeat Drops':0
		},
		'active': {
			'total': 0,
			'data': 0,
			'called': 0,
			'to_call': 0,
			'data_percent': 0,
			'called_percent': 0,
			'to_call_percent': 0,
      'Total Errors':0,
      'Total Repeat Drops':0
		},
		'inactive': {
			'total': 0,
			'data': 0,
			'called': 0,
			'to_call': 0,
			'data_percent': 0,
			'called_percent': 0,
			'to_call_percent': 0,
      'Total Errors':0,
      'Total Repeat Drops':0
		},
		'data_source': {
			'others': {
				'total': 0,
				'dialled': 0,
				'data': 0,
				'conversion': '0%',
				'called_percent': '0'
			}
		},
		'channels': {
			"Email": {
				"clicks": 0,
				"throughput": "0.00%"
			},
			"SMS": {
				"clicks": 0,
				"throughput": "0.00%"
			},
			"Whatsapp": {
				"clicks": 0,
				"throughput": "0.00%"
			},
			"Voice Bot": {
				"clicks": 0,
				"throughput": "0.00%"
			},
			"Agent": {
				"clicks": 0,
				"throughput": "0.00%"
			}
		}
	}

	summary = JSON.parse(JSON.stringify(this.summary_default));
	currentCamapigns: any[] = [];
	messageAlert: any = "";
	showHide_messageAlert: boolean = false;
	pageSize: number;
	pageNo: number;
	list_group=[];
	constructor(
		private dialog: MatDialog,
		private dashService: ServicesService,
		private titleService: Title,
		private API: ApiService,
		private utils: Utils,
		private myservice: MyserviceService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private _snackBar: MatSnackBar
	) {
		// console.log('construcor starts');
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				// console.log('subscribe event:', event);
				this.currentRoute = event.url;
				if (this.currentRoute == '/campaignV2/details') {
					this.dashService.getSelectedCamapign().subscribe(d => {
						//console.log('selected campaign data', d);
						if(this.currentRoute == '/central-campaign'){
							this.show_details = false;
							this.router.navigate(['./central-campaign']);
							this.refreshCampaignData();
						}
						else if (!d) {
							this.show_details = false;
							this.router.navigate(['./campaignV2']);
							this.refreshCampaignData();
						} else {
							this.show_details = true;
						}
					})
				}
				if (this.currentRoute == '/campaignV2') {
					this.dashService.SELECTED_CAMPAIGN.next(null);
					this.show_details = false;
				}
				if (this.currentRoute == '/central-campaign') {
					this.dashService.SELECTED_CAMPAIGN.next(null);
					this.show_details = false;
				}
				// console.log(this.currentRoute)
			});
	}

	ngOnInit() {//
		//console.log('dashboard component starts',JSON.stringify(this.user));
		if(this.user.company == 'BANK1-TESTING')
		{
		var managedgroups = JSON.parse(this.user.managedgroups)

		//debugger;
		//console.log('dashboard component starts '+managedgroups);
		var group_data = managedgroups.values;
		//console.log('dashboard component starts '+JSON.stringify(group_data));

		for(var i=0; i<group_data.length; i++)
		{
			if(group_data[i].groupid)
			{
				console.log(group_data[i].groupid);
				this.list_group.push(group_data[i].groupid);
			}
		}
	}
		console.log('-----'+JSON.stringify(this.list_group));
		this.pageSize = 10;
		this.pageNo = 0;
		this.api_end_point = this.myservice.get_API_End_Point();
		this.show_details = false;
		this.titleService.setTitle('Contiinex | Central Campaign Dashboard');
		this.dataSourceCampaign.paginator = this.paginator;
		this.refreshCampaignData();

	}

	change_selectedMonth(e) {
		// console.log('event', e);
		this.selected_month = _.find(this.months_filter, (obj) => {
			return obj.value == e.value;
		})
		console.log('on select change', this.selected_month);
	}

	getJsonKeys(obj) {
		return Object.keys(obj);
	}

	numericThresholdBycolor(val) {
		let color = 'red';
		if (val > 30) {
			color = 'lightgreen';
		}
		return color;
	}

	check_val_type(val) {
		return typeof val;
	}

	createCampaignTable = function (data: any) {
		let arr = [];
		// debugger;
		for (var i = 0; i < data.length; i++) {
			let obj = new Object();
		//	 console.log('printing obj',JSON.stringify(obj))
			let loc_obj = data[i];
			let details = {};
			let d_t_c = 0;
			if (loc_obj.details && loc_obj.details.length) {
				details = JSON.parse(loc_obj.details);
			}
			let agents_list = [];
			if (loc_obj.agents_list) {
				if (typeof loc_obj.agents_list == 'string') {
					let p = JSON.parse(loc_obj.agents_list);
					// console.log('printing agents list', p);
					agents_list = p[this.user.groupid];
				}
			}

			obj['sl_no'] = i + 1;
			obj['index_val'] = i;
			obj['id'] = loc_obj['id'];
			obj['campaign_name'] = loc_obj['campaign'];
			if (loc_obj['status'] === 'assigned') {
				obj['campaign_status'] = !!loc_obj['active_status'];
			} else {
				obj['campaign_status'] = loc_obj['status'];
			}
			obj['camapign_owner'] = loc_obj['name'];
			obj['campaign_created'] = this.utils.format_to(this.utils.utcToLocal(loc_obj['created_at'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'DD-MMM-YYYY');
			obj['last_updated'] = this.utils.format_to(this.utils.utcToLocal(loc_obj['updated_at'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'DD-MMM hh:mm a');
			obj['data_uploaded'] = loc_obj['Total Leads'];
			obj['data_duplicate'] = loc_obj['Total Repeat Drops'];
			obj['errors'] = loc_obj['Total Errors'];
			obj['data_added'] = this.utils.getValue(loc_obj['Total Pushed'], 0);
			d_t_c = obj['data_added'];
			if (loc_obj['data_not_used']) {
				d_t_c = this.utils.getValue(loc_obj['data_not_used'], 0);
			}
			obj['data_to_call'] = this.utils.getValue(d_t_c, 0)
			obj['contacted'] = this.utils.getDifference(obj['data_added'], d_t_c, 0);
			obj['agency_type'] = this.utils.getValue(loc_obj['master_campaign'], '');
			obj['url'] = loc_obj.url;
			obj['created_at'] = loc_obj.created_at;
			obj['agency_name'] = loc_obj.others;
			obj['data_source'] = 'others';
			if (loc_obj.master_campaign && loc_obj.master_campaign.length) {
				obj['data_source'] = loc_obj.master_campaign;
			}
			obj['agents_count'] = 0;
			if (agents_list && agents_list.length) {
				obj['agents_count'] = agents_list.length;
			}
			arr.push(obj)
		}
		// console.log('printing current campaigns',arr)
		// console.log('login user', this.user);
		this.currentCamapigns = arr;
		this.dataSourceCampaign = new MatTableDataSource(this.currentCamapigns);
		this.dataSourceCampaign.paginator = this.paginator;
	}

	// prepareCampaignSummary() {
	// 	// debugger;
	// 	this.dashService.myCampaigns.subscribe(
	// 		(d: any) => {
  //       debugger;
	// 			this.dataSourceCampaign = new MatTableDataSource([]);
	// 			this.dataSourceCampaign.paginator = this.paginator;
	// 			console.log('campaign subscriber', d);
	// 			// debugger;
	// 			let today = moment().format('YYYY-MM-DD');
	// 			let arr = [];
	// 			let total_data = 0;
	// 			if (d.length) {
	// 				// debugger;
	// 				this.createCampaignTable(d);
	// 				this.current_campaigns = d;
	// 				for (var i in d) {
	// 					let loc = d[i];
	// 					let details = JSON.parse(loc.details);
	// 					let d_t_c = 0 // campaign wise data to call
	// 					// console.log('details campaign', details);
	// 					let campaign_data = {
	// 						'isActive': false,
	// 						'data': 0,
	// 						'dialled': 0,
	// 						'data_to_call': 0,
	// 						'master_campaign': 'others',
	// 						'called_percent': '0%',
	// 						'contacted': 0
	// 					};
	// 					if (loc.status == 'assigned') {
	// 						if (loc.active_status && loc.active_status > 0) {
	// 							campaign_data.isActive = true;
	// 						}

	// 						if (loc.master_campaign && loc.master_campaign.length) {
	// 							campaign_data.master_campaign = loc.master_campaign
	// 						}

	// 						if (details && !isNaN(details['Total Pushed'])) {
	// 							campaign_data.data = parseInt(details['Total Pushed']);
	// 							total_data += parseInt(details['Total Pushed']);
	// 							d_t_c = parseInt(details['Total Pushed']);
	// 						}

	// 						if (details && details['data_not_called']) {
	// 							if (details && !isNaN(details['data_not_called'])) {
	// 								d_t_c = parseInt(details['data_not_called'])
	// 							}
	// 						}
	// 						if (loc.contacted) {
	// 							campaign_data.contacted = loc.contacted
	// 						}

	// 						campaign_data.data_to_call = d_t_c;
	// 					}
	// 					arr.push(campaign_data)
	// 				}
	// 			}
	// 			// console.log('arr', arr);
	// 			if (arr.length) {
	// 				let split_by_status = _.groupBy(arr, 'isActive');
	// 				let split_by_master_campaign = _.groupBy(arr, 'master_campaign');
	// 				// console.log('split campaigns', split_by_status);
	// 				// console.log('total data count', total_data);
	// 				this.summary.total = this.getCampaignGroupData(arr);
	// 				this.summary.total.total = arr.length;
	// 				this.summary.total.data_percent = parseInt((this.summary.total.data * 100 / total_data).toFixed(1));
	// 				if (split_by_status.hasOwnProperty('true')) {
	// 					this.summary.active = this.getCampaignGroupData(split_by_status['true']);
	// 					this.summary.active.total = split_by_status['true'].length;
	// 					this.summary.active.data_percent = parseInt((this.summary.active.data * 100 / total_data).toFixed(1));
	// 				}
	// 				if (split_by_status.hasOwnProperty('false')) {
	// 					this.summary.inactive = this.getCampaignGroupData(split_by_status['false']);
	// 					this.summary.inactive.total = split_by_status['false'].length;
	// 					this.summary.inactive.data_percent = parseInt((this.summary.inactive.data * 100 / total_data).toFixed(1));
	// 				}

	// 				if (split_by_master_campaign) {
	// 					// console.log('printing split by master campaign', split_by_master_campaign);
	// 					for (var i in split_by_master_campaign) {
	// 						this.summary.data_source[i] = this.getCampaignGroupData(split_by_master_campaign[i]);
	// 						// this.summary.data_source[i]['data_percent'] = parseInt((this.summary.data_source[i]['data'] * 100 / total_data).toFixed(1));
	// 						this.summary.data_source[i]['called_percent'] = this.utils.getPercentage(this.summary.data_source[i]['contacted'], this.summary.data_source[i]['data']);
	// 					}
	// 					// console.log('printing split by master campaign after', this.summary);
	// 				}
	// 			}
	// 			// console.log('after process', this.summary);
	// 		}
	// 	)
	// }

	// getCampaignGroupData(campaign_arr) {
	// 	let obj = {
	// 		'data': 0,
	// 		'to_call': 0,
	// 		'called': 0,
	// 		'to_call_percent': 0,
	// 		'called_percent': 0,
	// 		'contacted': 0
	// 	}

	// 	for (var i in campaign_arr) {
	// 		obj.data += campaign_arr[i]['data'];
	// 		obj.to_call += campaign_arr[i]['data_to_call'];
	// 		obj.contacted += campaign_arr[i]['contacted'];
	// 	}
	// 	obj.called = obj.data - obj.to_call;
	// 	obj.called_percent = parseInt((obj.called * 100 / obj.data).toFixed(1));
	// 	obj.to_call_percent = parseInt((100 - obj.called_percent).toFixed(1));

	// 	return obj;
	// }



	downloadDataSheet(obj: any) {
		obj.showLoader = true;
		// debugger;
		this.API.downloadReportFromUrl(obj.url, true,this.api_end_point).then(
			() => {
				obj.showLoader = false;
			}
		)
	}

	campaignStatusChange(e, element) {
	//	console.log('curent status', element, e.checked);
		/* console.log('event e', e);
		console.log('curent status', prev_status, e.checked); */
		const prev_status = !e.checked;
		console.log(element);
		element.disabled = true;
		let obj = {};
		if (e.checked) {
			obj['active_list'] = [element.campaign_name];
		} else {
			obj['inactive_list'] = [element.campaign_name]
		}

		this.API.campaignSwitch(obj,this.api_end_point).subscribe((res: any) => {
			if (res.errors) {
			//	alert('Camapign Switch Failed');
				e.checked = prev_status;
				element.disabled = false;

				this.messageAlert = "Camapign Switch Failed";
				this.showHide_messageAlert = true;
				setTimeout(()=>{
					this.showHide_messageAlert = false;
				},6000)

			} else {
				//alert('Campaign Switch Success.');

				this.refreshCampaignData();
				element.disabled = false;
				this.messageAlert = "Campaign Switch Success.";
				this.showHide_messageAlert = true;
				setTimeout(()=>{
					this.showHide_messageAlert = false;
				},6000)
			}
		})
	}

	// downloadCampaignStatusReport(obj: any) {
	// 	// debugger;
	// 	let campaign_data = obj;
	// 	campaign_data.showLoader = true;
	// 	let loc: any = {
	// 		'campaign_id': obj['id'],
	// 		'offset': 330
	// 	}
	// 	if (this.user.id != obj.user_id) {
	// 		var reportSettings = this.myservice.getSettingsWithKeyValue('reports', 'name', 'hideCustomerNumber');
	// 		console.log('report settings', reportSettings);
	// 		if (reportSettings) {
	// 			if (reportSettings.definition == 'true') {
	// 				loc.hcd = true;
	// 			}
	// 		}
	// 	}
	// 	this.API.downloadCampaignStatusReport(loc).subscribe(
	// 		(res: any) => {
	// 			// debugger;
	// 			campaign_data.showLoader = false;
	// 			if (res.url) {
	// 				this.API.downloadReportFromUrl(res.url).then(
	// 					() => {
	// 						//campaign_data.showLoader = false;
	// 					}
	// 				)
	// 			} else {
	// 			//	alert(res.errors)
	// 				this.messageAlert = res.errors;
	// 			this.showHide_messageAlert = true;
	// 			setTimeout(()=>{
	// 				this.showHide_messageAlert = false;
	// 			},6000)
	// 			}
	// 		}, (err) => {
	// 			// debugger;
	// 			campaign_data.showLoader = false;
	// 		}
	// 	)
	// }
	downloadCampaignStatusReport(obj: any) {
		 debugger;
		let campaign_data = obj;
		campaign_data.showLoader = true;
		let loc: any = {
			'campaign_id': obj['id'],
			'offset': 330
		}
		if (this.user.id != obj.user_id) {
			var reportSettings = this.myservice.getSettingsWithKeyValue('reports', 'name', 'hideCustomerNumber');
			//console.log('report settings', reportSettings);
			if (reportSettings) {
				if (reportSettings.definition == 'true') {
					loc.hcd = true;
				}
			}
		}
		if (this.user.company == "BANK1-TESTING" || this.user.company == "SBI-CC") {
            let arr = [17621,11911,11469,45484]
			if( arr.includes(this.user.id) && (obj.incallscreen == "STP" || obj.incallscreen == "MULTICARD"))
			{
					loc.hcd=false;

			}else{
        if(obj.user_id == this.user.id)
        {
          loc.hcd = false;
        }
        else{
          loc.hcd=true;
        }
      }
      // else{
			// 	loc.hcd=true;
			// }
			//console.log(JSON.stringify(loc));

			this.API.downloadSBICampaignStatusReport(loc,this.api_end_point).subscribe(
				(res: any) => {
					// debugger;
					campaign_data.showLoader = false;
					if (res.url) {
						this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
							() => {
								//campaign_data.showLoader = false;
							}
						)
					} else {
						//	alert(res.errors)
						this.messageAlert = res.errors;
						this.showHide_messageAlert = true;
						setTimeout(() => {
							this.showHide_messageAlert = false;
						}, 6000)
					}
				}, (err) => {
					// debugger;
					campaign_data.showLoader = false;
				}
			)
		} else {
			this.API.downloadCampaignStatusReport(loc,this.api_end_point).subscribe(
				(res: any) => {
					// debugger;
					campaign_data.showLoader = false;
					if (res.url) {
						this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
							() => {
								//campaign_data.showLoader = false;
							}
						)
					} else {
						//	alert(res.errors)
						this.messageAlert = res.errors;
						this.showHide_messageAlert = true;
						setTimeout(() => {
							this.showHide_messageAlert = false;
						}, 6000)
					}
				}, (err) => {
					// debugger;
					campaign_data.showLoader = false;
				}
			)
		}
	}

	checkOwnership(obj) {
		if (this.user.id != obj.user_id) {
			return false;
		}
		return true;
	}

	downloadCmail_report(obj: any, unique_data?: boolean) {
    debugger;
		console.log('printing obj', JSON.stringify(obj));
		obj.showLoader = true;
		var hide_number = false;
		if (this.user.id != obj.user_id) {
			var reportSettings = this.myservice.getSettingsWithKeyValue('reports', 'name', 'hideCustomerNumber');
			// console.log('report settings', reportSettings);
			if (reportSettings) {
				if (reportSettings.definition == 'true') {
					hide_number = true;
				}
			}
		}
		let api_obj: any = {
			start_date: obj.created_at,
			end_date: this.utils.localToUTC(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss').toString(),
			campaign_name: obj.campaign_name,
      agents_list: obj.agents_list
		}
		if (unique_data) {
			api_obj.unique_data = unique_data
		}
		if (hide_number) {
			api_obj.hcd = hide_number
		}
		this.API.downloadCmailsReport(api_obj,this.api_end_point).subscribe(
			(res: any) => {
				// debugger;
				obj.showLoader = false;
				if (res.error) {
					//alert(res.error)
					this.messageAlert = res.error;
				this.showHide_messageAlert = true;
				setTimeout(()=>{
					this.showHide_messageAlert = false;
				},6000)
				} else {
					if (res.url && res.url.length) {
						this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
							() => {
								// obj.showLoader = false;
							}
						)
					} else {
						//alert('File URL is missing');
						this.messageAlert ="File URL is missing";
						this.showHide_messageAlert = true;
						setTimeout(()=>{
							this.showHide_messageAlert = false;
						},6000)
					}
				}
			}, (err) => {
				obj.showLoader = false;
				alert('Error in the API call');

			}
		)
	}

	downloadCustomerStatusReport(obj: any) {
		// debugger;
		// console.log('printing obj', obj);
		var hide_number = false;
		if (this.user.id != obj.user_id) {
			var reportSettings = this.myservice.getSettingsWithKeyValue('reports', 'name', 'hideCustomerNumber');
			// console.log('report settings', reportSettings);
			if (reportSettings) {
				if (reportSettings.definition == 'true') {
					hide_number = true;
				}
			}
		}
		// return
		let campaign_data = obj;
		campaign_data.showLoader = true;
		let loc: any = {
			'campaign_id': obj['id'],
			'offset': 330
		}
		if (this.user.id != obj.user_id) {
			var reportSettings = this.myservice.getSettingsWithKeyValue('reports', 'name', 'hideCustomerNumber');
			//console.log('report settings', reportSettings);
			if (reportSettings) {
				if (reportSettings.definition == 'true') {
					loc.hcd = true;
				}
			}
		}
		if (this.user.company == "BANK1-TESTING" || this.user.company == "SBI-CC") {
			if(this.user.id == 17621|| this.user.id == 11911)
			{
				if(obj.incallscreen != "STP")
				{
					loc.hcd=true;
				}
			}else{
				//loc.hcd=true;
			}
		}
		obj.showLoader = true;
		let start_date = obj.created_at;
		let end_date = this.utils.localToUTC(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss').toString();
		let campaign = obj.campaign_name;

		this.API.downloadCustomerStatusReport(this.api_end_point,start_date, end_date, campaign).subscribe(
			(res: any) => {
				// debugger;
				obj.showLoader = false;
				if (res.error) {
					alert(res.error)
				} else {
					if (res.url && res.url.length) {
						this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
							() => {
								// obj.showLoader = false;
							}
						)
					} else {
						alert('File URL is missing')
					}
				}
			}, (err) => {
				obj.showLoader = false;
				alert('Error in the API call')
			}
		)
		// debugger;
	}

	downloadCallStatusReport(obj: any) {
		// debugger;
		obj.showLoader = true;
		let start_date = obj.created_at;
		let end_date = this.utils.localToUTC(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss').toString();
		let campaign = obj.campaign_name;

		this.API.downloadCallStatusReport(start_date, end_date, campaign,this.api_end_point).subscribe(
			(res: any) => {
				obj.showLoader = false;
				// debugger;
				if (res.error) {
					alert(res.error)
				} else {
					if (res.url && res.url.length) {
						this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
							() => {

							}
						)
					} else {
						alert('File URL is missing')
					}
				}
			}, (err) => {
				alert('Error in the API call');
				obj.showLoader = false;
			}
		)
	}

	downloadMasterCampaignStatusreport(obj: any) {
    //debugger;
		this.showLoader= true;
		obj.showLoader= true;
    obj.user_id=this.user.id;
    let hcd ;//= true;
		console.log('Downloading master campaign status report',JSON.stringify(obj));
		let campaign_details={
			master_campaignname : obj.master_campaign,
			incallscreen:obj.incallscreen,
			user_id:this.user.id
			}
            //hcd=true
			  setTimeout(() => {
				obj.showLoader = false;
				this.showLoader= false;
				this._snackBar.open("Request is taking time. Report will send to your mail","close",{
					duration: 3000
				});
			}, 1000);

            if(obj.incallscreen == "STP")
            {
              if(obj.incallscreen == "STP" && obj.user_id == 17621 ||obj.incallscreen == "STP" && obj.user_id == 11469 || obj.incallscreen == "STP" && obj.user_id == 44517||obj.incallscreen == "STP" && obj.user_id == 11911 || obj.incallscreen == "STP" && obj.user_id==45484)
                {
                    hcd= false;
                }
                else{
                    hcd= true;
                }
            }
            else if(obj.incallscreen == "MULTICARD" && obj.user_id == 17621 ||obj.incallscreen == "MULTICARD" && obj.user_id == 11469 || obj.incallscreen == "MULTICARD" && obj.user_id == 36510||obj.incallscreen == "MULTICARD" && obj.user_id == 36037 )
            {
              hcd= false;
            }
            else{
             hcd= true;
            }
            if(obj.incallscreen == "E_APPLY_OPTEDIN" && obj.master_campaign)
            {
              if(this.user.id == 45484 || this.user.id == 36037 || this.user.id == 11469 || this.user.id == 17621 || this.user.id == 36510 || this.user.id == 11911)
              {
                hcd= false;
              }
              else{
                  hcd= true;
              }
            }
            console.log(JSON.stringify(campaign_details));

		this.API.DownloadMasterCampaignReport(campaign_details,this.api_end_point,hcd).subscribe(
			(res: any) => {
				// debugger;
				// if (res.errors) {
				// 	//alert(res.error)
				// 	this._snackBar.open(res.errors,"close");
				// } else {
				// 	if (res.url && res.url.length) {
				// 		this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
				// 			() => {

				// 			}
				// 		)
				// 	} else {
				// 		//alert('File URL is missing')
				// 	}
				// }
			}
		)
	}

	downloadMasterCampaigncallStatusreport(obj: any) {
		//console.log('Downloading master campaign call status report');
    //debugger;
		this.showLoader= true;
		obj.showLoader= true;
    let hcd1;
    obj.user_id=this.user.id;

		var campaign_details={
		master_campaignname : obj.master_campaign,
    incallscreen:obj.incallscreen,
    user_id:this.user.id
		}

		setTimeout(() => {
			obj.showLoader = false;
			this.showLoader= false;
			this._snackBar.open("Request is taking time. Report will send to your mail","close",{
				duration: 3000
			});
		}, 60 * 1000);

    if(obj.incallscreen == "STP")
            {
              if(obj.incallscreen == "STP" && obj.user_id == 17621 ||obj.incallscreen == "STP" && obj.user_id == 11469 || obj.incallscreen == "STP" && obj.user_id == 44517||obj.incallscreen == "STP" && obj.user_id == 11911 || obj.incallscreen == "STP" && obj.user_id==45484)
                {
                  hcd1= false;
                }
                else{
                  hcd1= true;
                }
            }
    else if(obj.incallscreen == "MULTICARD" && obj.user_id == 17621 ||obj.incallscreen == "MULTICARD" && obj.user_id == 11469 || obj.incallscreen == "MULTICARD" && obj.user_id == 36510||obj.incallscreen == "MULTICARD" && obj.user_id == 36037 )
    {
      hcd1= false;
    }
    else{
      hcd1= true;
    }
    if(obj.incallscreen == "E_APPLY_OPTEDIN" && obj.master_campaign)
    {
      if(this.user.id == 45484 || this.user.id == 36037 || this.user.id == 11469 || this.user.id == 17621 || this.user.id == 36510 || this.user.id == 11911)
      {
        hcd1= false;
      }
      else{
        hcd1= true;
      }
    }
    console.log(JSON.stringify(campaign_details));
		this.API.DownloadMasterCallStatusReport(campaign_details,this.api_end_point,hcd1).subscribe(
			(res: any) => {
				obj.showLoader = false;
				// debugger;
				if (res.error) {
					//alert(res.error)
					//this._snackBar.open(res.errors, "close");
				} else {
					//this._snackBar.open(res.errors, "close");
					// if (res.url && res.url.length) {
					// 	this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
					// 		() => {

					// 			this._snackBar.open(res.errors, "close");
					// 		}
					// 	)
					// } else {
					// 	this._snackBar.open(res.errors, "close");
					// }
				}
			}, (err) => {
				//alert('Error in the API call1');
				obj.showLoader = false;
			}
		)

	}


	refreshCampaignData() {
       //debugger;
		// alert('calling from child');
		//debugger;
		//this._searchValue.nativeElement.value = "";
		this.showLoader=true;
		// setTimeout(() => {
		// 	this.showLoader = true;
		// })
		this.dataSourceCampaign = new MatTableDataSource([]);
		this.dataSourceCampaign.paginator = this.paginator;
		this.summary = JSON.parse(JSON.stringify(this.summary_default));
		let start_date = moment(this.selected_month.start_date).format('YYYY-MM-DDTHH:mm:ss').toString();
    let end_date = moment().format('YYYY-MM-DDTHH:mm:ss').toString();
      //moment(, 'DD-MM-YYYY HH:MM:SS');
      if(this.selected_month.end_date)
      {
        end_date = moment().format(this.selected_month.end_date).toString();
        console.log('printing selected date', start_date, this.selected_month.end_date);
      }
      else{
        end_date = moment().format('YYYY-MM-DDTHH:mm:ss').toString();
        console.log('printing selected date', start_date, end_date);
      }

		var reqObj = {
			// "index": 0,
			// "agents_list": this.myservice.getAllIds('user', 'email'),
			start_date,
			end_date,
		}
		reqObj.start_date = start_date;
        //console.log(start_date._i);

		// this.API.getChannelsCampaign(this.api_end_point)
		// 	.subscribe(
		// 		(res: any) => {
		// 			if (res.errors) {
		// 				this._searchValue.nativeElement.value = "";
		// 			} else {
		// 				this.summary.channels = res.success;
		// 				this._searchValue.nativeElement.value = "";
		// 			}
		// 		});
		let customer_data_obj: any = { ...reqObj };
		customer_data_obj.campaign_owners = this.myservice.getAllIds('superadmin', 'id');
		//customer_data_obj.campaign_owners = this.myservice.getAllIds('admin', 'id');
		// customer_data_obj.campaign_owners.push(this.user.id);
    // customer_data_obj.end_date = moment().format('YYYY-MM-DDTHH:mm:ss').toString();

		// this.API.getCentralGenericCustomerData(customer_data_obj,this.api_end_point)
		// 	.subscribe(
		// 		(res: any) => {
		// 			if (res.errors) {

		// 			} else {
		// 				if (res.success) {
		// 					this.summary.total.total = res.success.active.total_assigned + res.success.inactive.total_assigned;
		// 					this.summary.total.data = res.success.active.total_assigned + res.success.inactive.total_assigned;
		// 					this.summary.total.called = res.success.active.data_contacted + res.success.inactive.data_contacted;
		// 					this.summary.total.to_call = res.success.active.data_dialled + res.success.inactive.data_dialled;

		// 					this.summary.total.data_percent = this.summary.total.data != 0 ? parseInt((this.summary.total.data * 100 / this.summary.total.total).toFixed(1)) : 0;
		// 					this.summary.total.called_percent = this.summary.total.called != 0 ? parseInt((this.summary.total.called * 100 / this.summary.total.total).toFixed(1)) : 0;
		// 					this.summary.total.to_call_percent = this.summary.total.to_call != 0 ? parseInt((this.summary.total.to_call * 100 / this.summary.total.total).toFixed(1)) : 0;


		// 					this.summary.active.total = res.success.active.total_assigned;
		// 					this.summary.active.data = res.success.active.total_assigned;
		// 					this.summary.active.called = res.success.active.data_contacted;
		// 					this.summary.active.to_call = res.success.active.data_dialled;

		// 					this.summary.active.data_percent = res.success.active.total_assigned != 0 ? parseInt((this.summary.active.data * 100 / res.success.active.total_assigned).toFixed(1)) : 0;
		// 					this.summary.active.called_percent = this.summary.active.called != 0 ? parseInt((this.summary.active.called * 100 / this.summary.active.total).toFixed(1)) : 0;
		// 					this.summary.active.to_call_percent = this.summary.active.to_call != 0 ? parseInt((this.summary.active.to_call * 100 / this.summary.active.total).toFixed(1)) : 0;

		// 					this.summary.inactive.total = res.success.inactive.total_assigned;
		// 					this.summary.inactive.data = res.success.inactive.total_assigned;
		// 					this.summary.inactive.called = res.success.inactive.data_contacted;
		// 					this.summary.inactive.to_call = res.success.inactive.data_dialled;;
		// 					this.summary.inactive.data_percent = res.success.inactive.total_assigned != 0 ? parseInt((this.summary.inactive.data * 100 / res.success.inactive.total_assigned).toFixed(1)) : 0;
		// 					this.jsonTemp = JSON.stringify(this.summary);
		// 					this.summary.inactive.called_percent = this.summary.inactive.called != 0 ? parseInt((this.summary.inactive.called * 100 / this.summary.inactive.total).toFixed(1)) : 0;
		// 					this.summary.inactive.to_call_percent = this.summary.inactive.to_call != 0 ? parseInt((this.summary.inactive.to_call * 100 / this.summary.inactive.total).toFixed(1)) : 0;

		// 				}
		// 			}
		// 		}, (err) => {
		// 			console.log(err)
		// 		}
		// 	)
		let campaignDetailsObj: any = { ...reqObj };
		let non_users = this.myservice.getAllIds('non-user', 'id');
		non_users.push(this.user.id);
		campaignDetailsObj.campaign_owners = non_users;
	//console.log("--usersettings--"+JSON.stringify(this.user.company_alias));
	if(this.user.company_alias == "BPCL_MANAGER"){
		campaignDetailsObj.campaign_type = 'BPCL'
	}
	else if( this.user.company_alias == "LANDMARK_MANAGER")
	{
		campaignDetailsObj.campaign_type = 'LANDMARK'
	}
	else if(this.user.company_alias == "APOLLO_MANAGER")
	{
		campaignDetailsObj.campaign_type = 'APOLLO'
	}
	else if( this.user.company_alias == "STP_MANAGER")
	{
		campaignDetailsObj.campaign_type = 'STP'
	}
	else if( this.user.company_alias == "MULTICARD_MANAGER"){
		campaignDetailsObj.campaign_type = 'MULTICARD'
	}


		// console.log('printing non users', non_users);
		if(this.user.company == 'SBI-CC' || this.user.company == 'BANK1-TESTING'){
			//this.API.genericCampaignDataDetails(campaignDetailsObj).subscribe(
				//console.log(campaignDetailsObj);
				//return;
				var campaign_list=[];
				//campaignDetailsObj.local_groups = this.list_group.toString();
				//console.log(JSON.stringify(campaignDetailsObj));
        campaignDetailsObj.new_format=true;
				this.API.getcentralCampaignData(campaignDetailsObj,this.api_end_point).subscribe(
				(res: any) => {
					//debugger;
					//this.showLoader = false;
					var camp_assigning = false;
					if(res) {
							//res.reverse();
							let arr = [];
							for (var i = 0; i < res.campaigns.length; i++) {
								let obj: any = {};
								let details = {};
	//							console.log('printing obj',obj)
                  this.All_campaign_summary=res.summary;
                  this.List_campaign_name=res.incallscreen;
    					this.summary.active.total = res['summary']['Total Active Campaigns']['Total Leads'];
							this.summary.active.data = res['summary']['Total Active Campaigns']['Total Pushed'];
							// this.summary.active.called = res['summary']['Total Active Campaigns'].data_contacted;
							// this.summary.active.to_call = res['summary']['Total Active Campaigns'].data_dialled;

							this.summary.active.data_percent = (res['summary']['Total Active Campaigns']['Total Pushed']/res['summary']['Total Active Campaigns']['Total Pushed'])*100;
              //!= 0 ? parseInt((this.summary['Total Active Campaigns'].data * 100 / res['summary']['Total Active Campaigns']['Total Leads']).toFixed(1)) : 0;
							//this.summary.active.called_percent = this.summary.active.called != 0 ? parseInt((this.summary['Total Active Campaigns']['Total called'] * 100 / this.summary['Total Active Campaigns']['Total Pushed']).toFixed(1)) : 0;
							//this.summary.active.to_call_percent = this.summary.active.to_call != 0 ? parseInt((this.summary['Total Active Campaigns']['Total to called'] * 100 / this.summary['Total Active Campaigns']['Total Pushed']).toFixed(1)) : 0;


              this.summary.inactive.total = res['summary']['Total InActive Campaigns']['Total Leads'];
							this.summary.inactive.data = res['summary']['Total InActive Campaigns']['Total Pushed'];
							// this.summary.inactive.called = res['summary']['Total InActive Campaigns'].data_contacted;
							// this.summary.inactive.to_call = res['summary']['Total InActive Campaigns'].data_dialled;

							this.summary.inactive.data_percent = (res['summary']['Total InActive Campaigns']['Total Pushed']/res['summary']['Total InActive Campaigns']['Total Pushed'])*100;
              //!= 0 ? parseInt((this.summary['Total InActive Campaigns'].data * 100 / res['summary']['Total InActive Campaigns']['Total Leads']).toFixed(1)) : 0;
							//this.summary.inactive.called_percent = this.summary.inactive.called != 0 ? parseInt((this.summary['Total InActive Campaigns']['Total called'] * 100 / this.summary['Total InActive Campaigns']['Total Pushed']).toFixed(1)) : 0;
							//this.summary.inactive.to_call_percent = this.summary.inactive.to_call != 0 ? parseInt((this.summary['Total InActive Campaigns']['Total to called'] * 100 / this.summary['Total InActive Campaigns']['Total Pushed']).toFixed(1)) : 0;

              this.summary.total.data = (res['summary']['Total InActive Campaigns']['Total Pushed'] + res['summary']['Total Active Campaigns']['Total Pushed']);
              // this.summary.total.data = parseInt(this.summary.active.data+this.summary.inactive.data);
              // this.summary.total.data_percent = ((this.summary.active.data+this.summary.inactive.data)/(this.summary.active.data+this.summary.inactive.data))*100;


              let loc_obj = res.campaigns[i] as any;
								//console.log('printing obj',loc_obj)
								obj = { ...obj, ...loc_obj }
								if (loc_obj.details && loc_obj.details.length) {
									details = JSON.parse(loc_obj.details);
								}
								if (loc_obj.status == 'assigning') {
									camp_assigning = true;
								}
								let agents_list = [];
								if (loc_obj.agents_list) {
									if (typeof loc_obj.agents_list == 'string') {
										let p = JSON.parse(loc_obj.agents_list);
										// console.log('printing agents list', p);
										agents_list = p[this.user.groupid];
									}
								}

								obj['sl_no'] = i + 1;
								obj['index_val'] = i;
								obj['id'] = loc_obj['id'];
								obj['user_id'] = loc_obj['user_id'];
								obj['campaign_name'] = loc_obj['campaign'];
								obj['rechurn'] = loc_obj['rechurn'];
								if (loc_obj['status'] === 'assigned') {
									obj['campaign_status'] = !!loc_obj['active_status'];
								} else {
									obj['campaign_status'] = loc_obj['status'];
								}
								obj['camapign_owner'] = loc_obj['name'] || "";
								obj['agency_type'] = this.utils.getValue(loc_obj['master_campaign'], '');
								obj['campaign_created'] = this.utils.format_to(this.utils.utcToLocal(loc_obj['created_at'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'DD-MMM-YYYY');
								obj['last_updated'] = this.utils.format_to(this.utils.utcToLocal(loc_obj['updated_at'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'DD-MMM hh:mm a');
								obj['url'] = loc_obj.url;
								obj['created_at'] = loc_obj.created_at;
								if (loc_obj.master_campaign && loc_obj.master_campaign.length) {
									obj['data_source'] = loc_obj.master_campaign;
								} else {
									obj['data_source'] = 'others';
								}
								obj['agents_count'] = 0;
								if (agents_list && agents_list.length) {
									obj['agents_count'] = agents_list.length;
								}
								obj['agents_list'] = loc_obj.agents_list;

                obj['data_uploaded'] = loc_obj['Total Leads'];
                obj['data_added'] = loc_obj['Total Pushed'];
                obj['data_duplicate'] = loc_obj['Total Repeat Drops'];
                obj['errors'] = loc_obj['Total Errors'];
               obj['data_to_call'] =   0; //loc_obj['']
                obj['data_dialled'] = 0;
                obj['contacted'] = 0;

                arr.push(obj)

							}
							this.currentCamapigns = arr;
							//console.log(this.currentCamapigns);
              this.showLoader=false;
							this.dataSourceCampaign = new MatTableDataSource(this.currentCamapigns);
							this.dataSourceCampaign.paginator = this.paginator;
							for (var i = 0; i < res.length; i++) {
								 //console.log(i <= 10)
								if(i >= 10)
								{
									// console.log(res.camapigns_list[i].id)
									break;
								}
								else
								{
									campaign_list.push(res[i].id);
								}

							}
							campaignDetailsObj.campaign_type=campaign_list;
							//console.log(JSON.stringify(campaignDetailsObj));
							//{ campaign_id: res[i].id }
							// this.API.getGenericCampaigns({ campaign_id: campaign_list },this.api_end_point).subscribe(
							// 	(res: any) => {
							// 		// debugger;
							// 		this.showLoader = false;
							// 		if (res.success) {
							// 			var loc_obj = res.success;
							// 			//console.log("---"+JSON.stringify(loc_obj));
							// 			//console.log("-----"+loc_obj.length);
							// 			var childObj = {} as any;
							// 			for (var j = 0; j < loc_obj.length; j++) {
							// 				// console.log(res.camapigns_list[i].id)
							// 				campaign_list.push(loc_obj[j].id);
							// 				const campaignIndex = this.currentCamapigns.findIndex(x => x.id == loc_obj[j].id);
							// 				//console.log(loc_obj[j]['data_contacted']);
							// 				this.currentCamapigns[campaignIndex]['contacted'] = loc_obj[j]['data_contacted'];
							// 				this.currentCamapigns[campaignIndex]['data_uploaded'] = loc_obj[j]['total_pushed'];
							// 				// obj['data_duplicate'] = loc_obj['Total Repeat Drops'];
							// 				// obj['errors'] = loc_obj['Total Errors'];
							// 				this.currentCamapigns[campaignIndex]['data_added'] = loc_obj[j]['total_assigned'];
							// 				this.currentCamapigns[campaignIndex]['data_to_call'] = loc_obj[j]['total_assigned'] - loc_obj[j]['data_contacted'];
							// 				this.currentCamapigns[campaignIndex]['data_uploaded'] = loc_obj[j]['total_pushed'] || 0;
							// 				this.currentCamapigns[campaignIndex]['data_dialled'] = loc_obj[j]['data_dialled'] || 0;
							// 				this.currentCamapigns[campaignIndex]['data_duplicate'] = loc_obj[j]['duplicate'] || 0;
							// 				this.currentCamapigns[campaignIndex]['errors'] = loc_obj[j]['errors'] || 0;
							// 			}

							// 			this.current_campaigns = this.currentCamapigns;
							// 			this.dataSourceCampaign = new MatTableDataSource(this.currentCamapigns);
							// 			this.dataSourceCampaign.paginator = this.paginator;
							// 		}
							// 	}, (err: any) => {
							// 		this.showLoader = false;
							// 	}
							// )

							if (camp_assigning) {
								setTimeout(() => {
									//console.log('calling campaign details again', new Date());
									this.refreshCampaignData();
								}, 60 * 1000);
							}

						}
				}, (err: any) => {
					this.showLoader = false;
				}
			)
		}else{
			this.API.genericCampaignDataDetails(campaignDetailsObj,this.api_end_point).subscribe(
				(res: any) => {
					// debugger;
					this.showLoader = false;
					var camp_assigning = false;
					if (res.camapigns_list) {
						//res.camapigns_list.reverse();
						let arr = [];
						for (var i = 0; i < res.camapigns_list.length; i++) {
							let obj: any = {};
							let details = {};
							// console.log('printing obj',obj)
							let loc_obj = res.camapigns_list[i] as any;
							obj = { ...obj, ...loc_obj }
							if (loc_obj.details && loc_obj.details.length) {
								details = JSON.parse(loc_obj.details);
							}
							if (loc_obj.status == 'assigning') {
								camp_assigning = true;
							}
							let agents_list = [];
							if (loc_obj.agents_list) {
								if (typeof loc_obj.agents_list == 'string') {
									let p = JSON.parse(loc_obj.agents_list);
									// console.log('printing agents list', p);
									agents_list = p[this.user.groupid];
								}
							}

							obj['sl_no'] = i + 1;
							obj['index_val'] = i;
							obj['id'] = loc_obj['id'];
							obj['campaign_name'] = loc_obj['campaign'];
							obj['rechurn'] = loc_obj['rechurn'];
							if (loc_obj['status'] === 'assigned') {
								obj['campaign_status'] = !!loc_obj['active_status'];
							} else {
								obj['campaign_status'] = loc_obj['status'];
							}
							obj['camapign_owner'] = loc_obj['name'] || "";
							obj['agency_type'] = this.utils.getValue(loc_obj['master_campaign'], '');
							obj['campaign_created'] = this.utils.format_to(this.utils.utcToLocal(loc_obj['created_at'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'DD-MMM-YYYY');
							obj['last_updated'] = this.utils.format_to(this.utils.utcToLocal(loc_obj['updated_at'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'DD-MMM hh:mm a');
							obj['url'] = loc_obj.url;
							obj['created_at'] = loc_obj.created_at;
							if (loc_obj.master_campaign && loc_obj.master_campaign.length) {
								obj['data_source'] = loc_obj.master_campaign;
							} else {
								obj['data_source'] = 'others';
							}
							obj['agents_count'] = 0;
							if (agents_list && agents_list.length) {
								obj['agents_count'] = agents_list.length;
							}
							obj['agents_list'] = loc_obj.agents_list;
							arr.push(obj)

						}
						this.currentCamapigns = arr;
						this.dataSourceCampaign = new MatTableDataSource(this.currentCamapigns);
						this.dataSourceCampaign.paginator = this.paginator;
						for (var i = 0; i < res.camapigns_list.length; i++) {
							// console.log(res.camapigns_list[i].id)
							this.API.getGenericCampaigns({ campaign_id: res.camapigns_list[i].id },this.api_end_point).subscribe(
								(res: any) => {
									// debugger;
									this.showLoader = false;
									if (res.success) {
										var loc_obj = res.success;
										var childObj = {} as any;
										const campaignIndex = this.currentCamapigns.findIndex(x => x.id == loc_obj.id);
										this.currentCamapigns[campaignIndex]['contacted'] = loc_obj['data_contacted'];
										this.currentCamapigns[campaignIndex]['data_uploaded'] = loc_obj['total_pushed'];
										// obj['data_duplicate'] = loc_obj['Total Repeat Drops'];
										// obj['errors'] = loc_obj['Total Errors'];
										this.currentCamapigns[campaignIndex]['data_added'] = loc_obj['total_assigned'];
										this.currentCamapigns[campaignIndex]['data_to_call'] = loc_obj['total_assigned'] - loc_obj['data_contacted'];
										this.currentCamapigns[campaignIndex]['data_uploaded'] = loc_obj['total_pushed'] || 0;
										this.currentCamapigns[campaignIndex]['data_dialled'] = loc_obj['data_dialled'] || 0;
										this.currentCamapigns[campaignIndex]['data_duplicate'] = loc_obj['duplicate'] || 0;
										this.currentCamapigns[campaignIndex]['errors'] = loc_obj['errors'] || 0;
										this.current_campaigns = this.currentCamapigns;
										this.dataSourceCampaign = new MatTableDataSource(this.currentCamapigns);
										this.dataSourceCampaign.paginator = this.paginator;
									}
								}, (err: any) => {
									this.showLoader = false;
								}
							)
						}
						if (camp_assigning) {
							setTimeout(() => {
								//console.log('calling campaign details again', new Date());
								this.refreshCampaignData();
							}, 60 * 1000);
						}

					}
				}, (err: any) => {
					this.showLoader = false;
				}
			)
		}
	}

	openCreateCampaign() {
		// console.log('Creating a campaign');
		this.isCampbtnDisable = true;
		var dialogRef = this.dialog.open(CampaignDialogComponent, {
			minWidth: '35%',
			hasBackdrop: true,
			data: ""
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == "true") {
				// alert('true');
				this.refreshCampaignData();
			}
			// console.log('The dialog was closed');
			this.isCampbtnDisable = false;
		});
	}

	openCampaignDetails(obj) {
		this.dashService.SELECTED_CAMPAIGN.next(this.current_campaigns[obj.index_val]);
		this.show_details = true;
		let navigationExtras: NavigationExtras = {
			relativeTo: this.activatedRoute
		}
		//console.log('opening campaign details - route to another', obj, this.current_campaigns[obj.index_val]);

		this.router.navigate(['details'], navigationExtras);


		// var dialogRef = this.dialog.open(CampaignAgentsComponent, {
		// 	width: '60%',
		// 	hasBackdrop: true,
		// 	data: this.current_campaigns[obj.index_val]

		// })
		// dialogRef.afterClosed().subscribe(result => {
		// 	// console.log('This details dialog closed');
		// })
	}

	applyFilter(filterValue: string) {
		//console.log('Search in filter', filterValue);
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSourceCampaign.filter = filterValue;
		// console.log('rec table data', this.recTable.dataSource.data);
	}

	extractDataObject(ref, obj) {
		let arr = [];
		for (var i in ref) {
			if (obj.hasOwnProperty(ref[i])) {
				arr.push(obj[ref[i]])
			} else {
				arr.push('')
			}
		}
		return arr;
	}

	exportExcel(): void {
		// console.log('exporting excel');
		var data_mapper = {
			"name": "Name",
			"phone": "Phone",
			"Education": "Education Qualifications",
			"DOB": "Date of Birth",
			"Setup": "Laptop/Desktop",
			"Connectivity": "Dongle/BroadBand/Wifi/Hotspot",
			"City": "City",
			"State": "State",
			"Language": "English/ Hindi",
			"created_at": "Reg. Date",
			"current_status": "Status",
			"event_1_interview": "L1 Date",
			"event_1_organizer": "L1 Agent",
			"event_1_status": "L1 Status",
			"event_2_interview": "L2 Date",
			"event_2_organizer": "L2 Agent",
			"event_2_status": "L2 Status",
			"event_3_interview": "Client Interview Date",
			"event_3_status": "Client Interview Status",
			"training_start_date": "Training Start",
			"training_end_date": "Training End",
			"trainer_id": "Trainer",
			"master_campaign": "Agency Type",
			"campaign_agency": "Agency Name",
			"campaign_name": "Campaign Name"
		}
	}
	addmore(obj: any) {
		//console.log(this.API._selectClient)
		var data = obj;
		//console.log(JSON.stringify(data));
		/*var obj={
			forNew:"add",
			audit_client_id:this.API._selectClient.id
		}*/
		let _element = {
			"_element": data,
		}
		//'this.dataSource[0]'
		const dialogRef = this.dialog.open(DashboardAddmoreDialogComponent, {
			width: '50%',
			disableClose: true,
			data: _element
		});

		dialogRef.afterClosed().subscribe(result => {
			//console.log(result);
		});
	}

	AssignNow(val) {
		var data = val;
		//console.log(data);

		let uploaded_file = data.url.split('/');
		let file_name = uploaded_file.pop();

		let obj: any = {
			"blobfile": file_name,
			"start_date": data.pushed_date,
			"maxleadsperday": 500,
			"options": "Drop repeated data",
			"campaign_name": data.campaign_name,
			"questionnaire_form": data.questionnaire_form,//vk
			"priority": data.priority,
			"master_campaign": data.master_campaign,
			"groupedagentslist": JSON.parse(data.agents_list)
		}
		/*if (data.priority == 2) {
			obj.dial_ratio = this.pd_dial_ratio;
		}*/
		//console.log(obj);
		this.API.bulkPushAPI(obj,this.api_end_point).subscribe(
			(res: any) => {
				if (res.errors) {
				//	console.log(res.errors);
					this._snackBar.open(res.errors, 'close',{
						duration: 3000
					});
				}

				this.refreshCampaignData();
			//	console.log(res.success);
				//this.closePopup();
				this._snackBar.open('Assign Now', 'close',{
					duration: 3000
				});
				window.location.reload();

			})/**/

	}
	addagent(obj: any) {
		//console.log(this.API._selectClient)
		var data = obj;
		//console.log(JSON.stringify(data));

		// data.dial_mode = 'normal'
		// if (data.priority == 1) {
		// 	data.dial_mode = 'server'
		// }
		// if (data.priority == 2) {
		// 	data.dial_mode = 'predictive'
		// }
		// if (data.priority == 3) {
		// 	data.dial_mode = 'ivr'
		// }
		// if (data.priority == 4) {
		// 	data.dial_mode = 'divr'
		// }
		let obj_topost = {
			'campaign': data.campaign,
			'center_id': data.user_id,
			'agents_list': data.agents_list,
			'priority': data.priority
		}

		//debugger;
		this.API.getCampaignAgentsData(obj_topost).subscribe(
			(res: any) => {
				// debugger;
				if (res.error) {
					alert(res.error)
				}
				if (res.success) {
				//	console.log('printing success', res.success);
					data.agentdata = res.success;

					let _element = {
						"_element": data,
					}
					//'this.dataSource[0]'
					const dialogRef = this.dialog.open(DashboardAddagentComponent, {
						width: '60%',
						disableClose: true,
						data: _element
					});

					dialogRef.afterClosed().subscribe(result => {
						//console.log(result);
					});
				}
			})


	}
	actionViewMore(element){

		let obj = element;
		//console.log(element);
		var data = obj;
		//console.log(JSON.stringify(data));
		obj.campaign_type='central-campaign';

		let obj_topost = {
			'campaign': data.campaign,
			'center_id': [],
			'agents_list': data.agents_list,
			'priority': data.priority
		}
		var findgroupids =JSON.parse(obj.agents_list);
		var arrayofgroupid=Object.values(findgroupids);
		// console.log(JSON.stringify(arrayofgroupid));
		// console.log(JSON.stringify(arrayofgroupid[0]));
		for(var e=0; e<arrayofgroupid.length;e++)
		{
			var data:any;
			data = arrayofgroupid[e];
			//console.log(data);

			obj_topost.center_id.push(data[e].groupid);

			// var checkit = obj_topost.center_id;
			// console.log(checkit.filter(word => word == data[e].groupid));
			// if(checkit.filter(word => word == data[e].groupid))
			// {
			// 	console.log("match already found");
			// }
			// else{

			// }
		}



		//console.log(obj_topost);
		this.showLoader = true;
		this.API.getCampaignAgentsData(obj_topost).subscribe(
			(res: any) => {
				 this.showLoader = true;
				if (res.error) {
					alert(res.error);
					this.showLoader = false;
				}
				if (res.success) {
					//console.log('printing success', res.success);
					data.agentdata = res.success;
					//console.log(res.success);
					obj.campaign_agent_list=res.success;
					obj.pagename ="central-campaign";
					this.myservice.set_campaign_detail(obj);
						this.router.navigateByUrl('/central-campaign/campaignDetails');
						this.showLoader = false;


				}


			})

	}
	createCampaignPage(){
		this.router.navigateByUrl('/central-campaign/campaign');
	}

	downloadAttachment() {
		// console.log('Creating a campaign');
		this.isCampbtnDisable = true;
		var dialogRef = this.dialog.open(DownloadAttachmentComponent, {
			minWidth: '35%',
			hasBackdrop: true,
			data: ""
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result == "true") {
				// alert('true');
				this.refreshCampaignData();
			}
			// console.log('The dialog was closed');
			this.isCampbtnDisable = false;
		});
	}
	pageEvents(event: any) {
		var campaign_list=[];
		//console.log(event.pageIndex);
		//console.log(event.pageSize);
		if(event.pageIndex > this.pageNo) {
		  // Clicked on next button
		  for (var i =(event.pageIndex*10); i < this.currentCamapigns.length; i++) {
			//console.log(i <= 10)
		   if(i >= ((event.pageIndex+1)*10))
		   {
			   // console.log(res.camapigns_list[i].id)
			   break;
		   }
		   else
		   {
			   campaign_list.push(this.currentCamapigns[i].id);
		   }

	   }
	   //{ campaign_id: res[i].id }
	   this.API.getGenericCampaigns({ campaign_id: campaign_list },this.api_end_point).subscribe(
		   (res: any) => {
			   // debugger;
			   this.showLoader = false;
			   if (res.success) {
				   var loc_obj = res.success;
				   //console.log("---"+JSON.stringify(loc_obj));
				   //console.log("-----"+loc_obj.length);
				   var childObj = {} as any;
				   for (var j = 0; j < loc_obj.length; j++) {
					   // console.log(res.camapigns_list[i].id)
					   campaign_list.push(loc_obj[j].id);
					   const campaignIndex = this.currentCamapigns.findIndex(x => x.id == loc_obj[j].id);
					   //console.log(loc_obj[j]['data_contacted']);
					   this.currentCamapigns[campaignIndex]['contacted'] = loc_obj[j]['data_contacted'];
					   this.currentCamapigns[campaignIndex]['data_uploaded'] = loc_obj[j]['total_pushed'];
					   // obj['data_duplicate'] = loc_obj['Total Repeat Drops'];
					   // obj['errors'] = loc_obj['Total Errors'];
					   this.currentCamapigns[campaignIndex]['data_added'] = loc_obj[j]['total_assigned'];
					   this.currentCamapigns[campaignIndex]['data_to_call'] = loc_obj[j]['total_assigned'] - loc_obj[j]['data_contacted'];
					   this.currentCamapigns[campaignIndex]['data_uploaded'] = loc_obj[j]['total_pushed'] || 0;
					   this.currentCamapigns[campaignIndex]['data_dialled'] = loc_obj[j]['data_dialled'] || 0;
					   this.currentCamapigns[campaignIndex]['data_duplicate'] = loc_obj[j]['duplicate'] || 0;
					   this.currentCamapigns[campaignIndex]['errors'] = loc_obj[j]['errors'] || 0;
				   }

				   this.current_campaigns = this.currentCamapigns;
				   this.dataSourceCampaign = new MatTableDataSource(this.currentCamapigns);
				   this.dataSourceCampaign.paginator = this.paginator;
			   }
		   }, (err: any) => {
			   this.showLoader = false;
		   }
	   )
		} else {
		  // Clicked on previous button
		}
		// The code that you want to execute on clicking on next and previous buttons will be written here.
	 }

	 conditonfordisable(campaign)
	 {
        //console.log(campaign.user_id,"---",campaign.incallscreen);
        let arr = [45484,11469,17621,14253,11911];
        //console.log("<",arr.includes(this.user.id) && campaign.incallscreen == "STP");
        //console.log(">",campaign.user_id != this.user.id ? true: false);
    if(arr.includes(this.user.id) && campaign.incallscreen == "STP")
    {
        return false;
    }
    else{
        return campaign.user_id != this.user.id ? true: false
    }

		//  var campaign_data = Number(campaign.this_groupid);
		//  //console.log(campaign_data);
		// // console.log(Number(campaign_data));
		//  //console.log(JSON.stringify(this.list_group));
		//  if(this.list_group.includes(campaign_data))
		//  {
		// 	 return false;
		//  }
	 }
   check_rsmzsm()
   {
    console.log(JSON.stringify(this.user));
    if(this.user.id == 45484 || this.user.id == 36037 || this.user.id == 11469 || this.user.id == 17621 || this.user.id == 36510 || this.user.id == 11911)
      {
        return (true);
      }
      else if(this.user.role == "non-user" || this.user.role == "admin" || this.user.role == "superadmin")
    {
      return (false);
    }else{
      return (false);
    }

   }

}
