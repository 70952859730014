
<!-- <div class="headercontainer"> 
    <div class="div1">
      
        <img src="../assets/web_images/uearn_logo.svg" class="LogoForAuditor"/>
      </div> 
    <div class="div2">

<div class="d-flex flex-row justify-content-end ">
    <div class="AM-uname"><span>{{this.myUser.name}}</span></div>
    <div class="ml-4 m-right mt-1">
        <mat-icon [matMenuTriggerFor]="menu" aria-hidden="false" aria-label="" class="m-logout">account_circle</mat-icon>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>
</div>
</div>
    </div>
</div>  -->
<app-menu [navigateLink]="currentTitle"></app-menu>
<br>
<br>
<div style="margin-top: -3px;">
    <mat-sidenav-container autosize style="min-height: calc(100vh);">
      <mat-sidenav #sidenav class="TR-sidenav" mode="side" opened="true" style="width: 20% !important;">
        <div>
         
        <mat-nav-list *ngFor="let v of tabNameList" style="margin: 0px 0px 0px;padding: 0 5px !important;height: 60px !important;">   
          <mat-list-item  (click)="clickTab(v.name)"  [routerLink]="[v.routerLinkUrl]" [routerLinkActive]="v.active" >
            <div class="d-flex flex-row justify-content-end ">
              <div class="mr-4"> <mat-icon aria-hidden="false" aria-label=""  class="TR-icon" style="color: #AEB8C6;font-size: 30px;">{{v.iconName}}</mat-icon> </div> 
              <p class="ml-1" style="padding-top: 4px;font-size: 18px;"> {{v.name}} </p>
              
            </div>
          </mat-list-item>


        </mat-nav-list>
      </div>
      </mat-sidenav>
  <!--start-->
  <div class="TR-sidenav-content">
    <div *ngIf="showSubHeader">
     <mat-card style="height:75px;">
      <div class="row pdt pico">
        <div class="col-md-3 col-sm-3 col-xs-12">
          <mat-form-field  [ngClass]="'inputClr'" style="position: relative;bottom: 12px;width: 100%;">     
            <mat-select placeholder="Select A Client" [(ngModel)]="clientSelected" (selectionChange)="clientCompanySelect($event.value)">
              <!-- <mat-option value="all">All</mat-option> -->
              <mat-option [value]="d" *ngFor="let d of ClientCompany">{{d.projects}}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <div class="col-md-3 col-sm-3 col-xs-12">
            <mat-icon aria-hidden="false" aria-label="" (click)="addClient()" class="plusdialog">add_circle_outline</mat-icon> 
              </div>
       
          </div>
     </mat-card>
    </div>
  <router-outlet></router-outlet>
  </div>
  <!--end-->
    
    </mat-sidenav-container>
    
    </div>