import { Component, OnInit } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuditorAddClientDialogComponent } from '../auditor-add-client-dialog/auditor-add-client-dialog.component';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-auditor-management',
  templateUrl: './auditor-management.component.html',
  styleUrls: ['./auditor-management.component.scss']
})
export class AuditorManagementComponent implements OnInit {
  currentTitle = 'auditor';
  tabNameList:any;
  ClientCompany:any;
  myUser: any;
  clientSelected: any;
  currentRoute: string = "";
  showSubHeader: boolean = true;
  constructor(private API: ApiService,private router: Router, private dialog: MatDialog, private service: MyserviceService,) { 
    
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
		.subscribe((event: NavigationEnd) => {
			// console.log('subscribe event:', event);
			this.currentRoute = event.url; 
			if(this.currentRoute != '/auditor' && this.currentRoute != '/auditor/auditor-dashboard'){
				this.showSubHeader = false;
      }else{
        this.showSubHeader = true;
      }
      //console.log(this.currentRoute)
		});
    
  }

  ngOnInit() {
     let _pathurl=window.location.pathname;
  if(_pathurl=="/auditor/auditor-dashboard" || _pathurl=="/auditor/team" || _pathurl=="/auditor/auditor-projects" || _pathurl=="/auditor/auditor-auditors" ||_pathurl=="/auditor/auditor-data" || _pathurl=="/auditor/auditor-report" ||_pathurl=="/auditor/auditor-help"){
    this.router.navigateByUrl('/auditor');
  }
    this.tabNameList=[
      {name:'Dashboard',iconName:'leaderboard',routerLinkUrl:'./auditor-dashboard', active:'active'},
      {name:'Team',iconName:'people',routerLinkUrl:'/auditor/team', active:'active'},
      {name:'Audit Form',iconName:'people',routerLinkUrl:'/auditor/audit-forms', active:'active'},
      {name:'Projects',iconName:'view_list',routerLinkUrl:'./auditor-projects', active:'active'},
      {name:'Auditors',iconName:'receipt',routerLinkUrl:'./auditor-auditors', active:'active'},
      {name:'Audit Calls',iconName:'phone',routerLinkUrl:'./auditor-data', active:'active'},
      {name:'Reports',iconName:'assignment',routerLinkUrl:'./auditor-report', active:'active'},
      {name:'Help',iconName:'help',routerLinkUrl:'./auditor-help', active:'active'},
    ];
    this.myUser = this.service.get_loggedin_user();
    this.fetchAuditClients();
  }
  clickTab(name:any){
   // console.log(this.clientSelected)
if(this.clientSelected==null || this.clientSelected==undefined || this.clientSelected=="")
{
  alert("Please you have to select client !");
  this.router.navigateByUrl('/auditor');
  return false;
}
  }

  fetchAuditClients(){
    this.API.getClientsAuditGroup(this.myUser.id).subscribe((response: any) => {
      // this.ClientCompany =response.success;
      this.ClientCompany = response.success
    });
  }



  addClient(){
    const dialogRef = this.dialog.open(AuditorAddClientDialogComponent, {
			width: '50%',
			disableClose:true,
			data: ''
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
     // console.log('The dialog was closed');
      if(result.refresh){
        this.fetchAuditClients();
      }
      
		  });
  }
  clientCompanySelect(company:any){
  this.router.navigateByUrl('/auditor');
this.API.selectAClient(company);

var obj = {} as any;
if(company == "all"){
  obj.all = true;
  obj.production_group = this.myUser.id;
  obj.client_id = "";
  obj.superadmin_id = "";
}else{
  obj.all = false;
  obj.production_group = parseInt(company.production_group);
  obj.client_id = company.id;
  obj.superadmin_id = this.myUser.groupid;

}
//console.log(obj)
this.service.setSelectedAuditClient(obj)
let _pathurl=window.location.pathname;
if(_pathurl=="/auditor/auditor-dashboard"){
  setTimeout(() =>  this.router.navigateByUrl('/auditor/auditor-dashboard'), 100);
}
else{
  this.router.navigateByUrl('/auditor/auditor-dashboard');
}

}
logout(){
    sessionStorage.setItem('access_token',null);
    this.router.navigate(['/home']);
  }
}
