<form [formGroup]="docStatusForm">
	<table class="tbl">
		<tr style="border-bottom: 1px solid #ccc;">
			<td>
				<h5 class="hd5">Update Final Doc Status</h5>
			</td>
			<td style="text-align: right;"> 
				<i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i>
			</td>
	   </tr>
	   <tr>
		<td>
			<p class="mt-2 mb-2 errmsg" [ngStyle]="{'color':statusColor}">{{errorMsg}}</p>
		</td>
		</tr>
	   		<tr>
			<td>Candiate Id:</td>
			<td>{{data.applicant_id}}</td>
		</tr>
	   <tr>
		   <td>Candiate Name:</td>
		   <td>{{data.applicant_name}}</td>
	   </tr>
	   <tr>
			<td>Candiate Email:</td>
			<td>{{data.applicant_email}}</td>
		</tr>
		<tr>
			<td>Document List</td>
			<td></td>
		</tr>
		<tr>
			<td colspan="2">
				<ul>
					<li *ngIf="data.aadhar">Aadhar: <a href="{{ data.aadhar.url}}" target="_blank">{{ data.aadhar.file_name }}</a></li>
					<li *ngIf="data.pan">PAN: <a href="{{ data.pan.url }}" target="_blank">{{ data.pan.file_name }}</a></li>
					<li *ngIf="data.pass_book">Bank Passbook: <a href="{{ data.pass_book.url }}" target="_blank">{{ data.pass_book.file_name }}</a></li>
					<li *ngIf="data.service">Service Agreement: <a href="{{ data.service.url }}" target="_blank">{{ data.service.file_name }}</a></li>
					<li *ngIf="data.education">Service Agreement: <a href="{{ data.education_certificate.url }}" target="_blank">{{ data.education_certificate.file_name }}</a></li>
					<li *ngIf="data.HSC">HSC: <a href="{{ data.HSC.url }}" target="_blank">{{ data.HSC.file_name }}</a></li>
					<li *ngIf="data.SSC">SSC: <a href="{{ data.SSC.url }}" target="_blank">{{ data.SSC.file_name }}</a></li>
					<li *ngIf="data.typing_certificate">Typing: <a href="{{ data.typing_certificate.url }}" target="_blank">{{ data.typing_certificate.file_name }}</a></li>
					<li *ngIf="data.GST">GST: <a href="{{ data.GST.url }}" target="_blank">{{ data.GST.file_name }}</a></li>
					<li *ngIf="data.ITR">ITR: <a href="{{ data.ITR.url }}" target="_blank">{{ data.ITR.file_name }}</a></li>
				</ul>
			</td>
		</tr>
		<tr>
			<td><span>Document Status*</span></td>
			<td>
			 <mat-form-field>
				 <mat-select formControlName="doc_status">
					 <mat-option value="Verified">Verified</mat-option>
					 <mat-option value="In Progress">In Progress</mat-option>
					 <mat-option value="Rejected">Rejected</mat-option>
					 <mat-option value="Not Proper">Resubmit docs</mat-option>
				 </mat-select>
			 </mat-form-field>
			</td>
		</tr>
		<tr>
			<td><span>Remarks*</span></td>
			<td>
				<mat-form-field>
					<textarea matInput placeholder="Remarks" formControlName="remarks"></textarea>
				 </mat-form-field>
			</td>
		</tr>
		<tr>
			<td><span>Download Attachment:</span></td>
			<td><button  mat-button (click)="downloadFileAttachment()">Download All Attachment</button></td>
		</tr>
		<tr>
			<td>
				&nbsp;
			</td>
			<td class="actionBTN">
				<button mat-raised-button (click)="resetForm()">Clear</button><!--[disabled]="!docStatusForm.valid"-->
				<button  mat-button   (click)="uploadFinalStauts()" style="margin-left: 10px;">Submit</button>
			</td>
		</tr>
	</table>
</form>