<mat-table [dataSource]="dataSource" matSort>

  <ng-container *ngFor="let d of dataSource" matColumnDef="d.$key">
    <!-- <mat-header-cell > Agent Id </mat-header-cell> -->
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ columns[d.$key] }}</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element[d.$key] } </mat-cell>
	</ng-container>
	
    <mat-header-row *matHeaderRowDef=columns></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"></mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
