import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public viewData, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  closeDialog() {
		this.dialogRef.close('false');
	}

  submitbtn() {
    this.dialogRef.close('true');
    this._snackBar.open("Call answered","close", {
      duration: 4000,
    });  }

}
