import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ServicesService } from '../../dashboard/services.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Utils } from '../../utils';
import { MyserviceService } from '../../myservice.service';
import * as _ from 'underscore';
import { ApiService } from 'src/app/api.service';
import { CampaignDetailsAgentDialogComponent } from '../campaign-details-agent-dialog/campaign-details-agent-dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
	selector: 'app-campaign-details',
	templateUrl: './campaign-details.component.html',
	styleUrls: ['./campaign-details.component.scss']
})
export class CampaignDetailsComponent implements OnInit {
	messageAlert:any="";
	showHide_messageAlert:boolean=false;

	input_obj = null;
	campaign_start_at: any = '';
	last_refreshed_at: any = '';
	agent_details: any = [];
	login_user = this.myService.get_loggedin_user();
	agent_table_arr = [];
	campaign_newAgents = [];
	selected_all: boolean = false;
	currentLoader:boolean=false;
	agent_table_columns: string[] = ['sl_no', 'name', 'email', 'campaign_status', 'data', 'dialled', 'contacted', 'followup'];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	public agentDataSource: any = new MatTableDataSource();
	_getStatus:any;
	statusColor:any;
	api_end_point:any;
	constructor(private router:Router, private activatedRoute: ActivatedRoute, private dashService: ServicesService,private utils: Utils,private myService: MyserviceService,private API: ApiService,private dialog: MatDialog) {
	//	console.log('started campaign details component');
		this.dashService.getSelectedCamapign().subscribe(d => {
			//console.log('selected campaign received', d);
			//debugger;
			this.input_obj = d;
		})
		this.campaign_start_at = this.utils.utcToLocal(this.input_obj['created_at'], 'YYYY-MM-DDTHH:mm:ss', 'lll');
		this.last_refreshed_at = this.utils.utcToLocal(this.input_obj['updated_at'], 'YYYY-MM-DDTHH:mm:ss', 'lll');
		let t = JSON.parse(this.input_obj.agents_list);
		this.agent_details = t[this.login_user.groupid];
	 }

	ngOnInit() {
		this.api_end_point = this.myService.get_API_End_Point();
	//	console.log('snapshot', this.activatedRoute.snapshot);
		this.getAgentDataDetails();
		this.agentDataSource.paginator = this.paginator;		
	}
	getAgentDataDetails() {
		this.showHide_messageAlert=false;
		this.currentLoader=true;
		let obj = {
			'campaign': this.input_obj['campaign'],
			'center_id': this.login_user.groupid,
			'priority': this.input_obj.priority,
			'agents_list': this.input_obj.agents_list
		}
		this.API.getCampaignAgentsData(obj).subscribe((d: any) => {
			if (d && d.success) {
				this.currentLoader=false;
				let s = d.success;
				let p = _.flatten(_.values(s));
				this.agent_table_arr = p;
				this.agentDataSource.data = p;
				this.agentDataSource.paginator = this.paginator;
				let q = d.team;
				for (var i in q) {
					if (!s.hasOwnProperty(q[i]['email'])) {
						q[i]['add_selected'] = false;
						this.campaign_newAgents.push(q[i])
					}
				}
			}
			else if(d.errors){
			//	alert("Agents not found. So, data is not there.");
				this.messageAlert="Agents not found. So, data is not there.";
				this.showHide_messageAlert=true;
				this.currentLoader=false;
			}
		
			
		})
	}
	updateAllSelected() {
		this.selected_all = this.campaign_newAgents != null && this.campaign_newAgents.every(t => t.add_selected);
	}
	someSelected(): boolean {
		if (this.campaign_newAgents == null) {
			return false;
		}
		return this.campaign_newAgents.filter(t => t.add_selected).length > 0 && !this.selected_all;
	}
	setAll(select: boolean) {
		this.selected_all = select;
		if (this.campaign_newAgents == null) {
			return;
		}
		this.campaign_newAgents.forEach(t => t.add_selected = select);
	}
	addAgents_tocampaign() {
		//console.log('printing agents list', this.campaign_newAgents);
		let obj = {
            "agents_list": [],
            "campaign_name": this.input_obj['campaign'],
            "priority": this.input_obj['priority']
		}
		this.campaign_newAgents.forEach(t => {
			if (t.add_selected)
				obj.agents_list.push(t.email)
		})
		if (obj.agents_list.length) {
			this.API.addAgentstoCampaign(obj,this.api_end_point).subscribe((d: any) => {
				if (d.success) {
					alert('Agent add successfully');
				} else if(d.errors) {
					alert(d.errors)
				} else {
					alert('Unknown error');
				}
			})
		} else {
			alert('Select atlease one agent');
		}
	}

	backmenu() {
	//	console.log('going back to screen');
		this.router.navigate(['.'], {relativeTo: this.activatedRoute.parent})
	}

	getValueByParams(obj, key) {
		if (obj && obj.hasOwnProperty(key)) {
			return obj[key];
		}
		return '';
	}
	get_dial_priority() {
		switch (this.input_obj.priority) {
			case 1:
				return 'Server Dial';
				break;
			case 2:
				return 'Predictive Dial with dial_ratio ' + (this.input_obj.dial_ratio || 1);
				break;
			default:
				return 'Normal Dial'
				break;
		}
	}
	submit_agent_status() {
		//console.log('printing existing agents', this.agentDataSource.data);
		let all_selected = true;
		let prev_status = null;
		let t = { active_list: [this.input_obj['campaign']], inactive_list: [this.input_obj['campaign']], active_agents_list: [], inactive_agents_list: [] };
		for (var i in this.agentDataSource.data) {
			if (prev_status === null) {
				prev_status = this.agentDataSource.data[i]['campaign_status'];
			}
			if (prev_status != this.agentDataSource.data[i]['campaign_status']) {
				all_selected = false;
				break;
			}
		}

		if (all_selected) {
			// Trigger full campaign turnoff/turnon switch
			let obj = {};
			if (prev_status) {
				obj['active_list'] = [this.input_obj['campaign']];
			} else {
				obj['inactive_list'] = [this.input_obj['campaign']]
			}
	
			this.API.campaignSwitch(obj,this.api_end_point).subscribe((res: any) => {
				if (res.errors) {
					alert('Camapign Switch Failed');
				} else {
					alert('Camapign Switch success.');
				}
			})
		} else {
			// Trigger each agent turn off/ turn on switch
			for (var i in this.agentDataSource.data) {
				if (this.agentDataSource.data[i]['campaign_status']) {
					t.active_agents_list.push(this.agentDataSource.data[i]['email']);
				} else {
					t.inactive_agents_list.push(this.agentDataSource.data[i]['email']);
				}
			}

			let active_obj = {
				'active_list': t.active_list,
				'active_agents_list': t.active_agents_list
			}
			let inactive_obj = {
				'inactive_list': t.inactive_list,
				'inactive_agents_list': t.inactive_agents_list
			}


			this.API.updateAgentStatus(active_obj,this.api_end_point).subscribe((d) => {
				//console.log('Active list updated');
				this.API.updateAgentStatus(inactive_obj,this.api_end_point).subscribe((d) => {
				//	console.log('Inactive List updated');
					alert('Agents status updated successfully');
				})
			})
		}
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.agentDataSource.filter = filterValue;
	  }
	  addAgentCampaign(){
		  let _inputObject={
			  "_data":this.input_obj,
			  "lastRefreshDate":this.last_refreshed_at
		  }
		  let dialogRef = this.dialog.open(CampaignDetailsAgentDialogComponent, {
			width: '46%',
			hasBackdrop: false,
			data: _inputObject

		})
		dialogRef.afterClosed().subscribe(result => {
			// console.log('This details dialog closed');
			//debugger;
			if(result==true){
this.getAgentDataDetails();
			}
		})
	  }
	  getStatusActiveInactive(){
		  if(this.input_obj.campaign_status==true){
		   this._getStatus="Active";
		   this.statusColor='Green';
		   return this._getStatus;
		  }
		  else if(this.input_obj.campaign_status==false)
		  {
			this._getStatus="Inactive";
			this.statusColor='Red';
			return this._getStatus;
		  }
	  }
}
