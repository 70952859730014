import { Component, OnInit, ViewChild, Input } from '@angular/core';
import * as moment from 'moment';
import { Chart } from 'angular-highcharts';


import { Utils } from '../../utils';
import { MatTableDataSource } from '@angular/material/table';
import { ServicesService } from '../../dashboard/services.service';
import { MyserviceService } from '../../myservice.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit {

	currentTitle = 'pmdashboard';
	currentLoader = false;
	today = moment().format('DD-MMM-YYYY');
	utils = new Utils();
	@Input() startDateFilter: any;
	@Input() endDateFilter: any;
	
	startDate: any;
	endDate: any;
	recruiterStatusChart: any;
	recruiterStatusChartData: any = {
		offline: 0,
		offline_perc: 0,
		active: 0,
		active_perc: 0,
		inActive: 0,
		inActive_perc: 0,
		onBreak: 0,
		onBreak_perc: 0,
	};

	_summary = function () {
		return {
			'data': {
				'data_to_call': 0,
				'available': 0,
				'followup':0
			},
			'dialled': {
				'total': 0,
				'unique': 0
			},
			'contacted': {
				'total': 0,
				'unique': 0
			},
			'forms': {
				'total': 0
			}
		}
	}
	_tableData = function () {
		return {
		}
	}
	
	_tableDataL1 = function () {
		return {
			'name': '',
			'called': '',
			'selected': '',
			'rejected': '',
		}
	}
	_tableDataL2 = function () {
		return {
			'name': '',
			'called': '',
			'selected': '',
			'rejected': '',
		}
	}
	table_data_l1: any = [];
	table_data_l2: any = [];

	public dataSourceL1 = new MatTableDataSource(this.table_data_l1);
	public dataSourceL2 = new MatTableDataSource(this.table_data_l2);

	summary = this._summary();
	campaignSummary:any;
	api_end_point:any;
	displayedColumnsL1 = [
		'name',
			'called',
			'selected',
			'rejected',
	];
	displayedColumnsL2 = [
		'name',
			'called',
			'selected',
			'accepted',
	];
	constructor(
		private dashService: ServicesService,
		private userService: MyserviceService
	) { }

	ngOnInit() {
		this.api_end_point = this.userService.get_API_End_Point();
		this.refreshData();
		this.dashService.campaignSummaryData.subscribe(
			(d: any) => {
				
				this.campaignSummary = d;

				// debugger;
			}
		)
	}

	ngOnChanges() {
		this.startDate = this.startDateFilter;
		this.endDate = this.endDateFilter;
		
	  }

	refreshData() {
		// console.log('Table data refreshing');
		this.dataSourceL1 = new MatTableDataSource(this.table_data_l1);
		this.currentLoader = true;
		let obj:any = {};
		
		obj.start_date = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDate);
		obj.end_date = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDate);
		obj.agents_list = this.userService.getAllIds('user', 'id');
		// debugger;
		if (!(obj.agents_list && obj.agents_list.length)) {
			alert('Agents data not found');
			return;
		}
		// console.log('app-agents', this.startDateFilter, this.endDateFilter);
		// console.log('post object', obj);
		this.dashService.getPMDashData(obj,this.api_end_point).then((resolve) => {
			// console.log('API Called successfully');
			this.currentLoader = false;
			this.prepareTableData()
		}, (err) => {
				alert(err);
				this.currentLoader = false;
		})
	}

	prepareTableData() {
		this.currentLoader = true;
		this.summary = this._summary();
		let data = null;
		let arr = [];
		let arrL1 = [];
		let arrL2 = [];
		var active = 0, onBreak = 0, offline = 0, inActive = 0;
		this.dashService.pm_dash_data.subscribe((d) => data = d);
		// console.log('printing data', data);
		if (data.hasOwnProperty('pd_count_group')) {
			this.summary.data.data_to_call = data['pd_count_group']
		}
		if (data.hasOwnProperty('extra_data')) {
			let e = data['extra_data'];
			if (e.hasOwnProperty('total_calls')) {
				this.summary.dialled.total = e['total_calls'];
			}
			if (e.hasOwnProperty('unique_dialled')) {
				this.summary.dialled.unique = e['unique_dialled'];
			}
		}
		if (data.hasOwnProperty('success')) {
			let d = data['success'];
			// console.log('success data', JSON.parse(JSON.stringify(d)));
			// debugger;
			for (var i in d) {
				let loc = d[i];
				 //console.log('printing loc', loc['status_color']);
				let obj = this._tableData();
				obj['user_id'] = i;
				obj['name'] = loc['name'];
				obj['dialled'] = loc['dialled'];
				obj['unique_dialled'] = loc['unique_dialled'];
				if (!isNaN(loc['connected'])) {
					obj['contacted'] = loc['connected'];
					this.summary.contacted.total += obj['contacted'];
				}
				if (!isNaN(loc['unique_connected'])) {
					obj['unique_contacted'] = loc['unique_connected'];
					this.summary.contacted.unique += obj['unique_contacted'];	
				}
				if (!isNaN(loc['forms_filled'])) {
					obj['forms'] = loc['forms_filled'];
					this.summary.forms.total += obj['forms'];
				}
				obj['audit_score'] = loc['avg_score'];
				obj['ctc_score'] = loc['ctc_score'];
				obj['status_color'] = this.getStatusColor(loc['last_call']);
				
				if(loc['level'] == 'Level 1' && arrL1.length < 6){
					let objL1 = this._tableDataL1();
					objL1['name'] = loc['name'];
					objL1['called'] = loc['connected'];
					objL1['selected'] = loc['l1_select'];
					objL1['rejected'] = loc['l1_reject'];
					arrL1.push(objL1);
				}
				if(loc['level'] == 'Level 2' && arrL2.length < 6){
					let objL2 = this._tableDataL2();
					objL2['name'] = loc['name'];
					objL2['called'] = loc['connected'];
					objL2['selected'] = loc['l2_select'];
					objL2['rejected'] = loc['l2_reject'];
					arrL2.push(objL2);
				}
				/*if(loc['status_color'] == 'Green'){
					active = active + 1;
					obj['status_color'] = '#808080';
					//obj['status_color'] = '#064183';
				}*/
				if(loc['status_color'] == 'Green')
				{
					active = active + 1;
					obj['status_color'] = '#B0B524';
					//obj['status_color'] = '#FFA500';
				}
				if(loc['status_color'] == 'Light Green'){
					onBreak = onBreak + 1;
					obj['status_color']= '#F74F1D';
					//obj['status_color'] = '#13b18b';
				}
				if(loc['status_color'] == 'Yellow'){
					offline = offline + 1;
					obj['status_color'] = '#FEB42B';
					//obj['status_color'] = '#ff6619';
				}
				if(loc['status_color'] == 'Red'){
					inActive = inActive +1;
					obj['status_color'] = '#AAAAAA';
				}
				if (loc.hasOwnProperty('rnr')) {
					obj['rnr'] = loc['rnr'];
				}
				if (loc.hasOwnProperty('forms')) {
					obj['forms'] = loc['forms'];
				}
				if (loc.hasOwnProperty('login_time')) {
					obj['login_time'] = loc['login_time'];
				}
				if (loc.hasOwnProperty('break_time')) {
					obj['break_time'] = loc['break_time'];
				}
				if (loc.hasOwnProperty('spoken_time')) {
					obj['spoken_time'] = loc['spoken_time'];
				}
				if (loc.hasOwnProperty('idle_time')) {
					obj['idle_time'] = loc['idle_time'];
				}
				if (loc.hasOwnProperty('wait_time')) {
					obj['wait_time'] = loc['wait_time'];
				}
				if (loc.hasOwnProperty('wrap_time')) {
					obj['wrap_time'] = loc['wrap_time'];
				}
				arr.push(obj);
			}
		}
		// console.log('table data', arr);
		this.dashService.setAgentsTableData(arr);
		this.table_data_l1 = arrL1;
		this.table_data_l2 = arrL2;

		this.recruiterStatusChartData.offline = offline;
		this.recruiterStatusChartData.offline_perc = this.calculatePercBy(offline, arr.length);
		this.recruiterStatusChartData.inActive = inActive;
		this.recruiterStatusChartData.inActive_perc = this.calculatePercBy(inActive, arr.length);
		this.recruiterStatusChartData.active = active;
		this.recruiterStatusChartData.active_perc = this.calculatePercBy(active, arr.length);
		this.recruiterStatusChartData.onBreak = onBreak;
		this.recruiterStatusChartData.onBreak_perc = this.calculatePercBy(onBreak, arr.length);

		
		this.dataSourceL1 = new MatTableDataSource(this.table_data_l1);
		this.dataSourceL2 = new MatTableDataSource(this.table_data_l2);
		this.preparePieChart();

		this.currentLoader = false;
	}

	calculatePercBy(num1,num2){
		if(num1 == 0 && num2 == 0){
			return "0.0";
		}
		var value: any = (num1/num2)*100;
		value = value.toFixed(1);
		return value
	}
	getStatusColor(val:any) {
		let color = '#DEE1E6';
		try {
			if (val) {
				let duration = this.utils.getDuration(
					val,
					this.utils.getUtc('YYYY-MM-DDTHH:mm:ss'),
					'YYYY-MM-DDTHH:mm:ss',
					'm'
				)
				// debugger;
				if (duration) {
					if ((duration >= 0) && (duration < 5)) {
						color = '#33EB1A';
					}
					if ((duration >= 5) && (duration < 30)) {
						color = '#C9B248';
					}
					if (duration > 30) {
						color = '#FF0000';
					}
				}
			} 
			return color;
		} catch (e) {
			return color;
		}
	}
	preparePieChart(){
		let chartdata = new Chart({
			title: {
				text: ''
			},
			credits: {
				enabled: false
			},
			plotOptions: {
				pie: {
					cursor: 'pointer',
					dataLabels: {
						distance: -30,
						enabled: true,
						color: 'white',
						style: {
							fontWeight: '500',
						}
					},
					showInLegend: false,
					borderWidth: 5,
				},
			},

			series: [
				{
					states: {
						inactive: {
							opacity: 1
						},
						hover: {
							enabled: false
						}
					},
					name: 'Active',
					data: [
						
						{
							name: Math.round(this.recruiterStatusChartData.active_perc),
							y: Number(this.recruiterStatusChartData.active_perc), color: '#B0B524'
						}, {
							name: Math.round(this.recruiterStatusChartData.offline_perc),
							y: Number(this.recruiterStatusChartData.offline_perc), color: '#FEB42B'
						},
						{
							name: Math.round(this.recruiterStatusChartData.onBreak_perc),
							y: Number(this.recruiterStatusChartData.onBreak_perc), color: '#F74F1D'
						}
						,
						{
							name: Math.round(this.recruiterStatusChartData.inActive_perc),
							y: Number(this.recruiterStatusChartData.inActive_perc), color: '#AAAAAA'
						}
					],
					type: 'pie',
					
					innerSize: '40%',
				}]
		});

		this.recruiterStatusChart = chartdata;
	}
}
