import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-auditor-dashboard',
  templateUrl: './auditor-dashboard.component.html',
  styleUrls: ['./auditor-dashboard.component.scss']
})
export class AuditorDashboardComponent implements OnInit {
  displayedColumns = ['name', 'project','AuditId', 'availability']; //
  dataSource:any = new MatTableDataSource();
  myUser: any;
  dashboardSummary: any = {
    project_count: 0,
    auditor_count: 0,
    audited_count: 0,
    training_count: 0,
  };
  auditors: any[] = [];
  constructor(private API: ApiService, private service: MyserviceService,) { }

  ngOnInit() {
   
    

    this.myUser = this.service.get_loggedin_user();
    let params = this.service.auditClientGetRequestParam();
    this.API.getAuditDashboard(params).subscribe((response: any) => {
      if(response && response.data){
		 // console.log(response.data);
        this.dashboardSummary = response.data.summary;
        for(const auditor of response.data.auditors){
          let obj = {
            name: auditor.name,
            availability: auditor.project_name ? 'Not Available' : 'Available',
            project: auditor.project_name || "NA",
			auditId:auditor.audit_client_id
          }
          this.auditors.push(obj);
        }
        this.dataSource = new MatTableDataSource(this.auditors);
      this.dataSource.filterPredicate = function(data, filter: string): boolean {
        return data.name.toString().toLowerCase().includes(filter) || data.project.toString().toLowerCase().includes(filter) || data.availability.toString().toLowerCase().includes(filter)
      };
      }
     
     
      });
   
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  getAvailiabilityColor(val) {
		let obj = {
			'color': 'Inherit'
		}
		switch (val) {
			case 'Available':
				obj.color = 'green';
				break;
		
		}
		return obj;
	}
}

