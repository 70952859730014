<div class="row">
	<div class="col-md-12">
		<mat-card style="margin: 0 10px;box-shadow: 0px 0px 4px 0px #dfdfdf;">
			<div class="row">
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon1.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1">{{totalData | localstring}}</span>    <!---{{totalData$ | async | localstring}} </span>-->
								<span class="nameLabel1">Available Data</span>
							</td>
						</tr>
					</table>
				</div>
			</div>

		</mat-card>
	</div>
</div>
<div class="row ">
	<div class="col-md-12">
		<mat-card style="margin: 0 12px;" class="mt-3">
			<h4 class="titlehead thd1 mb-4">ASM</h4>


			<table matSort (matSortChange)="sortData($event)" class="vTableV">
				<tr>
					<th mat-sort-header="name">NAME</th>
					<th mat-sort-header="totalDataAvailable">TOTAL DATA AVAILABLE</th>
					<th mat-sort-header="nationalCampaign">NATIONAL CAMPAIGN</th>
					<th mat-sort-header="localCampaign">LOCAL CAMPAIGN</th>
					<th mat-sort-header="referenceCustomers">REFERENCE CUSTOMERS</th>
					<th mat-sort-header="manualDialed">MANUAL DIALED</th>
				</tr>
				<tbody *ngFor="let d of sortedData | async;let i = index;let first=first;">
					<tr>
						<td colspan="6" style="color: #000;font-weight: bold;">{{d.title}}</td>
					</tr>
					<tr *ngFor="let v of d.rData;let j = index;let first=first;">
						<td>{{v.name}}</td>
						<td>{{v.totalDataAvailable}}</td>
						<td>{{v.nationalCampaign}}</td>
						<td>{{v.localCampaign}}</td>
						<td>{{v.referenceCustomers}}</td>
						<td>{{v.manualDialed}}</td>
					</tr>
					<tr style="background: #f9f9f9;">
						<td><b>Total</b></td>
						<td>{{getTotalDataAvailable(d)}}</td>
						<td>{{getNationalCampaign(d)}}</td>
						<td>{{getLocalCampaign(d)}}</td>
						<td>{{getReferenceCustomers(d)}}</td>
						<td>{{getManualDialed(d)}}</td>

					</tr>
				</tbody>
				<tfoot>
					<tr style="background: #f3f3f3;">
						<td><b>All Total</b></td>
						<td>{{getAllTotalDataAvailable()}}</td>
						<td>{{getAllNationalCampaign()}}</td>
						<td>{{getAllLocalCampaign()}}</td>
						<td>{{getAllReferenceCustomers()}}</td>
						<td>{{getAllManualDialed()}}</td>
					</tr>
				</tfoot>
			</table>


		</mat-card>
	</div>
</div>