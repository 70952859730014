import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';

import * as io from 'socket.io-client';

import { MyserviceService } from './myservice.service';
import { RtddataService } from './rtdData.service';


@Injectable({
	providedIn: 'root'
})

export class SocketelsService {
	user: any;
	serverUrl: any = 'https://smarter-biz.com';
	socket: any;
	isSocketEnable: BehaviorSubject<boolean> = new BehaviorSubject(false);
	rtd_constants = ['rtd_talktime_count', 'rtd_signedin_count', 'rtd_qderej_count', 'rtd_qdegen_count', 'rtd_qdeapprove_count', 'rtd_planned_centers_count', 'rtd_panrej_count', 'rtd_pangen_count', 'rtd_panapprove_count', 'rtd_dnd_count', 'rtd_dialled_count', 'rtd_data_pushed_count', 'rtd_data_consumed_count', 'rtd_contacted_count', 'rtd_allagents_count', 'rtd_active_centers', 'rtd_agentsalive_count', 'rtd_agentlastacttime_value', 'rtd_manual_consumed_count', 'rtd_idletime_count', 'rtd_wraptime_count', 'rtd_waittime_count', 'rtd_spokentime_count', 'rtd_activetime_count', 'rtd_national_campaign_data', 'rtd_local_campaign_data', 'rtd_ref_customer_count', 'rtd_followup_todo', 'rtd_wrong_number_dialed_count', 'rtd_rnr_count', 'rtd_unique_customer_contacted_count', 'rtd_new_data_dialled_count', 'rtd_flp_data_dialled_count', 'rtd_followup_data_count', 'rtd_local_data_pushed_count', 'rtd_national_data_pushed_count', 'rtd_pd_data_pushed_count', 'rtd_pd_national_data_pushed_count', 'rtd_rnr_data_count', 'rtd_rnr_data_count_WFH'];
	constructor(private myService: MyserviceService, private rtd: RtddataService) {
		this.user = this.myService.get_loggedin_user();
		console.log('logedinuser', this.user);

		let path = "/socket.io/";
		if (this.user.company == "BANK1-TESTING") {
			path = path + "bank1-testing/"
		}
		this.socket = io(this.serverUrl, { path: path });
		this.socket.on('connect', (skt) => {
			console.log('socket connection estableshed', skt);
			this.isSocketEnable.next(true);
		})

		this.socket.on('rtd_register_ack', (regid, uid, data) => {
			//console.log('ack received register user', regid, uid, data);
			console.log(JSON.stringify(data));
			this.formUserObject(data);
		})

		this.socket.on('rtd_register_groups_ack', (regid, uid, data) => {
			//console.log('register groups ack',regid,uid,data)
			this.formGroupObject(data);
		})
	}

	formUserObject(dataArr) {
		let arr = [];
		dataArr.forEach(data => {
			arr.push({
				...data.datajson,
				...data.otherinfo,
				id: data.uid
			})
		});
		console.log('formatted arr', arr);
		this.rtd.updateAgentFeed(arr);
	}

	formGroupObject(dataArr) {
		let arr = [];
		dataArr.forEach(data => {
			arr.push(Object.values(data)[0])
		});
		//console.log('formatted group data arr', arr);
		this.rtd.updateGroupFeed(arr);
	}

	rtd_keyObject() {
		var obj = {};
		for (var i in this.rtd_constants) {
			if (/*($scope.rtd_constants[i] != 'rtd_data_pushed_count') && */(this.rtd_constants[i] != 'rtd_allagents_count')) {
				obj[this.rtd_constants[i]] = 0;
			}
		}
		return obj;
	}

	emitUsersrequest() {
		//let particular_group = this.myService.get_asm_groupid()
		//let myTeam = this.myService.get_asm_groupdata()
		 let myTeam =this.myService.get_asm_groupdata();
		let groupid = this.myService.get_asm_groupid();
		//console.log(JSON.stringify(myTeam));
		//this.rtd.isCentral.next(false);
		if (myTeam) {
			//console.log('my selected group team',myTeam);
			var reg = {
				req_feeds: [],
				group_refresh: 0, //Set this if you want group level refresh. Generally not required
				persist: true,//This registration will be stored in the backend and will persist each time to login
				feed_type: 'agentwise' //This will indicate the output feed type. Currently only this is supported. So hard code
			};
			//console.log('myTeam', myTeam);

			myTeam.forEach((agent) => {
				let obj: any = {};
				obj.level = 1;
				obj.refresh = -1;
				obj.keylist = this.rtd_keyObject();
				obj.preload_epoch = 'today';
				obj.uid = agent.id || agent.user_id;
				reg.req_feeds.push(obj);
			})
			//console.log('printing reg', reg);
			this.socket.emit('rtd_register', 'web', groupid, this.user.company, reg);
		}
		else {

			myTeam = this.myService.getAllIds('user');
			var reg = {
				req_feeds: [],
				group_refresh: 0, //Set this if you want group level refresh. Generally not required
				persist: true,//This registration will be stored in the backend and will persist each time to login
				feed_type: 'agentwise' //This will indicate the output feed type. Currently only this is supported. So hard code
			};
			//console.log('myTeam', myTeam);

			myTeam.forEach((agent) => {
				let obj: any = {};
				obj.level = 1;
				obj.refresh = -1;
				obj.keylist = this.rtd_keyObject();
				obj.preload_epoch = 'today';
				obj.uid = agent.id || agent.user_id;
				reg.req_feeds.push(obj);
			})
			console.log('printing reg', reg);
			this.socket.emit('rtd_register', 'web', this.user.id, this.user.company, reg);
		}
	}

	emitGroupRequest(groupids?: number[] | string[]) {

		let myTeam =this.myService.get_asm_groupdata();
		let groupid = this.myService.get_asm_groupid();
		if (myTeam) {
			if (!groupids) {
				groupids = [groupid];
			}
			var reg = {
				req_feeds: [],
				group_refresh: 0, //Set this if you want group level refresh. Generally not required
				persist: true,//This registration will be stored in the backend and will persist each time to login
				feed_type: 'agentwise' //This will indicate the output feed type. Currently only this is supported. So hard code
			};

			groupids.forEach(groupid => {
				reg.req_feeds.push({
					"groupid": groupid,
					"level": 1,
					"refresh": -1,
					"keylist": this.rtd_keyObject()
				})
			})
			//console.log('reg group', reg);
			this.socket.emit('rtd_register_groups', 'web', groupid, this.user.company, reg);
		}
		else{
				if (!groupids) {
					groupids = [this.user.groupid];
				}
				var reg = {
					req_feeds: [],
					group_refresh: 0, //Set this if you want group level refresh. Generally not required
					persist: true,//This registration will be stored in the backend and will persist each time to login
					feed_type: 'agentwise' //This will indicate the output feed type. Currently only this is supported. So hard code
				};
	
				groupids.forEach(groupid => {
					reg.req_feeds.push({
						"groupid": groupid,
						"level": 1,
						"refresh": -1,
						"keylist": this.rtd_keyObject()
					})
				})
				//console.log('reg group', reg);
				this.socket.emit('rtd_register_groups', 'web', this.user.id, this.user.company, reg);
			
		}
	}

	central_group_request(arr) {
		if (arr && arr.length) {
			let groupids = _.pluck(arr, 'groupid');
			//console.log('req groupids', groupids);
			this.rtd.isCentral.next(true); // This notifies the socket request is for Central hierarchy
			this.emitGroupRequest(groupids);
		}
	}
}