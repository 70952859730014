
<h3 class="sBiz-title">SmarterBiz</h3><br>
<div class="text-center">
    <p  class="hdtext"><span> On-Boarding Process</span></p>
    <p class="LText">Boost your operational efficiency with realtime<br>
        customer journey orchestration across channels.</p>
   
</div>

<div>
<mat-card class="m-card">
    <div class="row bgText">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
            DASHBOARDS & REPORTS
        </div>
    </div>


<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Web Portal reports </span>
        <br>
       <mat-checkbox class="mcbx">Customer Status report</mat-checkbox>
       <mat-checkbox class="mcbx">Call Status report</mat-checkbox>
       <mat-checkbox class="mcbx">Campaign status report</mat-checkbox>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Customized report (If required)</span>
        <p style="font-size: 10px;word-spacing: normal;word-break: break-all;">Attach the required format</p>
     
        <div class="upload-btn-wrapper">
            <button class="filebtn"><i class="fa fa-upload"></i>Add File</button>
            <input type="file" id="fileID" />
          </div>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">OUTBOUND DASHBOARD(Attach if any changes required)</span>
        <p style="font-size: 10px;word-spacing: normal;word-break: break-all;">sample dashboard video :<br>
            https://drive.google.com/file/d/1cTRsCnyBpaRZsddz6hYF6LE57LzAp6Yr/view?usp=sharing
        </p>
       
        <div class="upload-btn-wrapper">
            <button class="filebtn"><i class="fa fa-upload"></i>Add File</button>
            <input type="file" id="fileID" />
          </div>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">INBOUND DASHBOARD (Attach if any changes required)</span>
        <p style="font-size: 10px;word-spacing: normal;word-break: break-all;">sample dashboard video :<br>
            https://drive.google.com/file/d/1cTRsCnyBpaRZsddz6hYF6LE57LzAp6Yr/view?usp=sharing
        </p>
     
        <div class="upload-btn-wrapper">
            <button class="filebtn"><i class="fa fa-upload"></i>Add File</button>
            <input type="file" id="fileID" />
          </div>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Access to Reports</span>
       <br>
        <mat-radio-group class="rd-gp">
            <mat-radio-button value="1">All who have web access</mat-radio-button>
            <mat-radio-button value="2">Only the Head</mat-radio-button>
            <mat-radio-button value="3">Other
                <mat-form-field class="otherWd">
                    <input matInput/>
                    </mat-form-field>
            </mat-radio-button>
          </mat-radio-group>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Access to Data visibility in Reports</span>
       <br>
        <mat-radio-group class="rd-gp">
            <mat-radio-button value="1">All who have web access</mat-radio-button>
            <mat-radio-button value="2">Only the Head</mat-radio-button>
            <mat-radio-button value="3">Other
                <mat-form-field class="otherWd">
                    <input matInput/>
                    </mat-form-field>
            </mat-radio-button>
          </mat-radio-group>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Data upload fields</span>
        <br>
        <span style="color:gray;font-size:11px">Add required columns if required</span><br>
        
<table class="tablecs" border="1">
    <thead>
      <tr>
        <th>Column Name</th>
        <th>Description</th>        
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>CUSTOMER NAME (Optional)</td>
        <td>Name of the customer</td>
      </tr>
      <tr>
        <td style="background:red;">CUSTOMER NUMBER (Cumpulsory)</td>
        <td>Number of the customer</td>
      </tr>
      <tr>
        <td>CUSTOMER COMPANY NAME (Optional)</td>
        <td>Company Name of the customer</td>
      </tr>
        <tr>
        <td>NOTES (Optional)</td>
        <td>Notes</td>
      </tr>
         <tr>
        <td>NEXT FOLLOW UP START DATE (Optional)</td>
        <td></td>
      </tr>
        <tr>
        <td>NEXT FOLLOW UP END DATE (Optional)</td>
        <td></td>
      </tr>
          <tr>
        <td>NEXT FOLLOW UP START TIME (Optional)</td>
        <td>Follow Up Start Time of the next</td>
      </tr>
        <tr>
        <td>NEXT FOLLOW UP END TIME (Optional)</td>
        <td></td>
      </tr>
        <tr>
        <td>SUB STAGE 1 (Optional)</td>
        <td>Stage 1 of the sub</td>
      </tr>
        <tr>
        <td>SUB STAGE 2 (Optional)</td>
        <td>Stage 2 of the sub</td>
      </tr>
         <tr>
        <td>Miscellaneous Data (Key Value Information) (Optional)</td>
        <td>
The details to be shown to agents on call in a particular format</td>
      </tr>
 <tr>
        <td>Default Disposition (Optional)</td>
        <td></td>
      </tr>
       <tr>
        <td>Default Registered Agent Email Id to whom this customer should be assigned (Optional)</td>
        <td>Agents email id id needed</td>
      </tr>
      
    </tbody>
  </table>
  <br>
        <mat-form-field style="width: 100%;">
            <input matInput placeholder="Answer"/>
            </mat-form-field>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Timer Values</span>
        <p style="font-size: 10px;word-spacing: normal;word-break: break-all;">Existing calculation :<br>
            https://drive.google.com/file/d/1cTRsCnyBpaRZsddz6hYF6LE57LzAp6Yr/view?usp=sharing
        </p>
       
        <div class="upload-btn-wrapper">
            <button class="filebtn"><i class="fa fa-upload"></i>Add File</button>
            <input type="file" id="fileID" />
          </div>
    </div>
</div>
<br><br>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
       <button class="onboard-submit"  (click)="onNextBtn()">NEXT</button>
    </div>
</div>
<br><br>
</mat-card>
</div> 

