<p class="closeP"><span (click)="closeinfo()" title="Close"><mat-icon>close</mat-icon></span></p>
<div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="details-hd"><b>Details</b></span>
        </div>
    </div>
    <div *ngIf="showHideWhatsapp">
    <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
             <span class="vd-head">Created Date</span>
             <!-- <span class="vd-data">{{elementData.created_at | date:'dd/MM/yyyy'}} </span>
             <span class="vd-data" *ngIf="!elementData.sent_at"> --  </span> -->
             <span class="vd-data">{{convertDTime(elementData.created_at) || '--' }} </span>
               </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">Sent At </span>
             <!-- <span class="vd-data">{{ elementData.sent_at | date:'h:mm:ss a' }} </span>
             <span class="vd-data" *ngIf="!elementData.sent_at"> --  </span> -->

             <span class="vd-data">{{convertDTime(elementData.sent_at) || '--' }} </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">Delivered At </span>
             <!-- <span class="vd-data" *ngIf="elementData.delivered_at"> {{elementData.delivered_at | date:'h:mm:ss a'}}  </span>
             <span class="vd-data" *ngIf="!elementData.delivered_at"> --  </span> -->
             <span class="vd-data">{{convertDTime(elementData.delivered_at) || '--' }} </span>
             </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head"> Read At </span>
                <!-- <span class="vd-data" *ngIf="elementData.read_at"> {{elementData.read_at | date:'h:mm:ss a'}}  </span>
                <span class="vd-data" *ngIf="!elementData.read_at"> --  </span> -->
                <span class="vd-data">{{convertDTime(elementData.read_at) || '--' }} </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">Status </span>
             <span class="vd-data"> {{elementData.status || '--' }}  </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">Type </span>
             <span class="vd-data"> {{elementData.content_type}}  </span>
            </div>

        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">Sender </span>
             <span class="vd-data"> {{elementData.sender_phone || '--' }} </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">Recipient </span>
             <span class="vd-data">  {{elementData.receiver_phone || '--' }}   </span>
             </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">Customer </span>
             <span class="vd-data">  {{elementData.customer || '--' }}   </span>
             </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head"> Project </span>
                <span class="vd-data"> {{elementData.project || '--' }}  </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head"> Template Name </span>
                <span class="vd-data"> {{elementData.template_name || '--' }}  </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head"> Schedule Date </span>
                <span class="vd-data"> {{convertDTime(elementData.scheduled_at) || '--' }}  </span>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="elementData.status=='FAILED'">
                <span  class="vd-head"> Failed Reason </span>
                <span class="vd-data"> {{elementData.message_id || '--' }}  </span>
            </div>
            </div>


        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <span  class="vd-head">Message</span>
                <p class="vd-msg">
                <span class="vd-data" style="word-break: break-all;" [innerHTML]="elementData.content">
                    </span>
                    </p>
            </div>
        </div>
    </div>

    <div *ngIf="showHideIVR">
        <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
             <span class="vd-head"> Date</span>
             <span class="vd-data">{{elementData.start_time | date:'dd/MM/yyyy'}} </span>
             <span class="vd-data" *ngIf="!elementData.start_time"> --  </span>
               </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">Sender </span>
             <span class="vd-data"> {{elementData.cli_number || '--' }} </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">Recipient </span>
             <span class="vd-data">  {{elementData.customer_number || '--' }}   </span>
             </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head"> Start At </span>
                <span class="vd-data"> {{elementData.start_time | date:'h:mm:ss a'}}  </span>
                <span class="vd-data" *ngIf="!elementData.start_time"> --  </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">End At </span>
             <span class="vd-data"> {{elementData.end_time | date:'h:mm:ss a'}}  </span>
             <span class="vd-data" *ngIf="!elementData.end_time"> --  </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">Duration </span>
             <span class="vd-data"> {{elementData.call_duration || '--' }}  </span>
            </div>

        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head"> Project </span>
                <span class="vd-data"> {{elementData.project || '--' }}  </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">Campaign </span>
             <span class="vd-data" style="word-break: break-word;"> {{elementData.campaign || '--' }}  </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head">Audit score </span>
             <span class="vd-data"> {{elementData.audit_score || '--' }}  </span>
            </div>


        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head"> DTMF </span>
                <span class="vd-data"> {{elementData.dtmf_digit || '--' }}  </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head"> Customer </span>
                <span class="vd-data"> {{elementData.customer || '--' }}  </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head"> Event </span>
                <span class="vd-data"> {{getEventName(elementData.event_type)}}  </span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head"> Hangup cause </span>
                <span class="vd-data"> {{elementData.hangup_cause || '--' }}  </span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <span  class="vd-head"> Call price </span>
                <span class="vd-data"> {{elementData.call_price || '--' }}  </span>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <span  class="vd-head">Audio Preview</span>
                <span class="vd-data" *ngIf="elementData.call_url == null"> -- </span>
                <p>
                <span class="vd-data"  id="callurl" >
                    </span>
                    </p>
            </div>
        </div>
    </div>









    </div>
