<body>
    <div>
        <form [formGroup]="createdForm" class="modal-content">
            <div class="containercs">
                <img src="../assets/web_images/uearn_logo.svg" style="width: 120px;
                margin-left: -20px;"/>
                <h4 class="head4">Login</h4>
                  <input class="txtbx" type="email" placeholder="Email" required  formControlName="email" autofocus>
                  <input class="txtbx" type="password" placeholder="Password" required  formControlName="password">          
                  <button class="loginbtn" type="submit" (click)="signBtn(createdForm)">Login</button> 
                  <p class="pb"><span class="pcss">Lost your password? </span> <span class="pcss1" (click)="forgotPassword()">Click here to recover. </span></p>
                  <p class="pb"><span class="pcss"> Don't have an account?</span><span class="pcss1"><a href="https://play.google.com/store/apps/details?id=smarter.uearn.money&hl=en" target="_blank"> Click Here</a></span> </p>      
                </div>
        </form>
    </div>  
</body>
<div id="cover-spin" *ngIf="showLoader"></div>
