<div>
    <div class="row ml-0 mr-0 mt-3">
        <div class="col-md-3 col-lg-3 col-sm-12">
            <mat-card class="mcard">
                <h2 class="h-lvl">My Life Time Earnings</h2>
                <circle-progress style="position: relative;top: -40px; "
                [percent]="_totalBalance"
                [radius]="60"
                [outerStrokeWidth]="8"
                [innerStrokeWidth]="8"
                [outerStrokeColor]="'#c200b2'"
                [innerStrokeColor]="'#a4aec1'"
                [animation]="true"
                 [space]= "-8"
                 [outerStrokeGradientStopColor]= "'#53a9ff'"
                 [backgroundStrokeWidth]="32"
                 [showInnerStroke]=true
                 [title]= "_totalBalance"
                 [animateTitle]=false
                 [units]="''"
                 [showSubtitle]=true
                 [subtitle]="'Total'"
                  
              ></circle-progress>

            </mat-card>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12">
            <mat-card class="mcard">
                <h2 class="h-lvl">Summary - {{_monthName}}</h2>
                <table class="wd-100" style="line-height: 30px;">
                    <tr>
                        <td><span class="L2-hd">Opening Balance</span> </td>
                        <td><span class="Ldata">{{_openingBalance}}  </span> </td>
                    </tr>
                    <tr>
                        <td><span class="L2-hd">Earned</span> </td>
                        <td><span class="Ldata">{{_earned}} </span> </td>
                    </tr>
                    <tr>
                        <td><span class="L2-hd">Bonus</span> </td>
                        <td><span class="Ldata">{{_bonus}} </span> </td>
                    </tr>
                    <tr>
                        <td><span class="L2-hd">Closing Balance</span> </td>
                        <td><span class="Ldata">{{_closingBalance}}  </span> </td>
                    </tr>
                </table>
            </mat-card>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12">
            <mat-card class="mcard">
                <h2 class="h-lvl">Current Project</h2>
                <p class="clientname">{{_currentProjectName}}</p>
            </mat-card>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-12">
            <mat-card class="mcard">
                <h2 class="h-lvl">Attendance</h2>
                <table class="wd-100" style="line-height: 30px;">
                    <tr>
                        <td colspan="2"><span class="L2-hd">No.of days worked</span> </td>
                    </tr>
                    <tr>
                        <td><span class="L2-hd">
<mat-progress-bar [ngClass]="'mpBar'" mode="determinate" value="{{_noOfWorkingDays}}" style="height: 12px;border-radius:20px;"></mat-progress-bar>
                            </span> </td>
                        <td class="text-right"><span class="Ldata">{{workingDays}}</span> </td>
                    </tr>
                    <tr>
                        <td><span class="L2-hd">Date of joined</span> </td>
                        <td class="text-right"><span class="Ldata">{{_doj}}</span> </td>
                    </tr>
                </table>
            </mat-card>
        </div>
    </div>
    <mat-card class="mt-3" style="margin-left: 15px;margin-right: 15px;padding: 0px;">
        <div class="row ml-0 mr-0 mt-3">
            <div class="col-md-7 col-lg-7 col-sm-12">
                <p class="L-head pt-3" style="font-size: 22px;">Passbook</p>
            </div>
            <div class="col-md-2 col-lg-2 col-sm-12">
                <mat-form-field style="width:95%">
                    <mat-label>From date</mat-label>
                    <input matInput [matDatepicker]="frompicker" [(ngModel)]="fromDate"  (dateChange)="onDate()"  [max]="todayDate" (click)="frompicker.open()" readonly>
                    <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
                    <mat-datepicker #frompicker></mat-datepicker>
                  </mat-form-field>
            </div>
            <div class="col-md-2 col-lg-2 col-sm-12">
                <mat-form-field  style="width:95%">
                    <mat-label>To date</mat-label>
                    <input matInput [matDatepicker]="topicker"  [(ngModel)]="toDate"   (dateChange)="onDate()"  [max]="todayDate" (click)="topicker.open()" readonly>
                    <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                    <mat-datepicker #topicker></mat-datepicker>
                  </mat-form-field>
            </div>
            <div class="col-md-1 col-lg-1 col-sm-12">
    <button mat-button class="gobtn" style="position: relative;top: 15px;" (click)="goBtn()">Go</button>
            </div>
        </div>
    </mat-card>

</div>

<div class="mt-1" style="margin-left: 15px;margin-right: 15px;">
    <table mat-table [dataSource]="dataSource">
    
        <ng-container matColumnDef="brand">
          <th mat-header-cell *matHeaderCellDef>Project </th>
          <td mat-cell *matCellDef="let element">{{element.brand}} </td>
        </ng-container>
        
        <ng-container matColumnDef="day">
          <th mat-header-cell *matHeaderCellDef>Date </th>
          <td mat-cell *matCellDef="let element"> {{element.day}} </td>
        </ng-container>
        
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef> Time </th>
          <td mat-cell *matCellDef="let element"> {{element.time}} </td>
        </ng-container>
        
        <ng-container matColumnDef="callEarnings">
          <th mat-header-cell *matHeaderCellDef> Call Earnings</th>
          <td mat-cell *matCellDef="let element"> {{element.callEarnings}} </td>
        </ng-container>
        
        <ng-container matColumnDef="uearned">
            <th mat-header-cell *matHeaderCellDef> Amount</th>
            <td mat-cell *matCellDef="let element"> {{element.uearned}} </td>
          </ng-container>
       
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
<br><br>