<app-new-contiinex-menu-page></app-new-contiinex-menu-page>
<div class="v-mainDivCO">
    <div>
        <div class="row animation-One2x vk-sec">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <p class="text-center mb-4"><img src="../assets/contiinex/image/subProductComEnable.png" class="comEngLogo" /></p>
                <h1 class="v-textH text-center v-BannerTitle">
                    Next Gen call center automation platform that enables<br>
                    you to manage employee life cycle in a single platform
                </h1>
                <h3 class="v-txt1 mb-2 v-enable-sub-title">Unified Contact Center Automation Platform Next Gen call center automation technology that enables you to manage a complete employee life cycle in a single platform. Ensures you save on OpEx & helps you focus on increasing on operational agility, adhering Compliance.</h3>
                <p>
                    <span class="v-book-demo" title="BOOK A DEMO" (click)="bookDemoBtn()">BOOK A DEMO</span>
                </p>
            </div>
        </div>

        <div class="row">

            <div class="col-md-12 col-sm-12 col-xs-12 mb-3"> 
                <div class="mt-0 enableBanner1" >
                    <div class="enableBanner1-One"> </div> 
                    <div class="cee-Sec-div">
                        <img src="../assets/contiinex/image/enable-flex-banner.png" style="width: 100%;" />
                    </div> 
                    <div class="enableBanner1-Two mb-4">
                        <table class="text-left vTableY">   
                            <tr><td>    <div class="mb-1" style="line-height: 1.67;letter-spacing: 0.45px;">
                                <span class="vytitle">Flexible Call center automation platform</span> 
                            </div></td></tr>                      
                            <tr>
                                 <td><span class="vk-cee-desc" style="margin:10px 5px;line-height: 1.67;letter-spacing: 0.45px;">  Comnex Enable is the most flexible Call center automation platform you will ever find!
                                    Use it either as modular product to support your call center operations or choose the complete comprehensive, easy to use suit to omit redundancy & promote resource efficiency.
                                    </span></td></tr>    
                                
                        </table>
                    </div> 
                    <div class="enableBanner1-One"> </div> 
                    </div>
            </div>



            <div class="col-md-12 col-sm-12 col-xs-12 mb-3"> 
            <div style="background-color: #f8f8f8;padding: 55px 5px;">
                <div class="col-md-12 col-sm-12 col-xs-12 mb-3"> 
                    <p class="v-p1 mb-0">What’s does it do?</p>
                    <p>
                        <span   class="vk-cee-desc text-center" style="margin:2px 5px;line-height: 1.67;letter-spacing: 0.05px;">It does everything that you will ever need, to run a contact center operation. Managing <br>employee life cycle in snap of a finger!
                        </span>
                    </p>       
                 </div>
                <div class="row" style="align-items: center;">
                    <div class="col-md-1 col-sm-12 col-xs-12"></div>
                    <div class="col-md-10 col-sm-12 col-xs-12">
                        <img src="../assets/contiinex/image/comnexEnablePartImg1.png" style="width: 100%;"/>
                    </div>
                    <div class="col-md-1 col-sm-12 col-xs-12"></div>
                </div>
                <div  class="col-md-12 col-sm-12 col-xs-12 mb-3">
                    <br>
                    <p class="mt-4 text-center"><span class="v-view-demo" title="Know More" (click)="openKnowMore()">KNOW MORE
                    </span>
                    </p>
                    <br>
                </div>
                <div  class="col-md-12 col-sm-12 col-xs-12 mb-3 mt-4">
                         <p class="text-center mb-1"> <img src="../assets/contiinex/image/imageOneText.png" class="countText"/></p>
                         <p class="text-center mb-1"> <img src="../assets/contiinex/image/iconTextLogoImg1.png" class="countTextLogo"/></p>
                         <p class="text-center ttlx">AUTOMATED HIRING</p>
                         <p class="text-center ttlx2 mb-1"> To Drive hiring at scaleHiring automation to deliver scale <br>
                                hiring, retention, employee engagement</p>
                                <p class="text-center ttlx3">Automated Registration, Selection & Hiring</p>


                         <div class="autoDiv1" >
                           
                            <div> <img src="../assets/contiinex/image/automatedHiring1.png"> </div>
                            <div> <img src="../assets/contiinex/image/automatedHiring2.png"> </div>
                            <div> <img src="../assets/contiinex/image/automatedHiring3.png"> </div>
                            <div> <img src="../assets/contiinex/image/automatedHiring4.png"> </div>
                            <div> <img src="../assets/contiinex/image/automatedHiring5.png"> </div>
                            <div> <img src="../assets/contiinex/image/automatedHiring6.png"> </div>
                            <div> <img src="../assets/contiinex/image/automatedHiring7.png"> </div>
                          
                            
                         </div>
                         <p class="text-center mb-1"> <img src="../assets/contiinex/image/imageTwoText.png" class="countText"/></p>
                         <p class="text-center mb-1"> <img src="../assets/contiinex/image/iconTextLogoImg2.png" class="countTextLogo"/></p>
                         <p class="text-center ttlx">AUTOMATED ONBOARDING MODULE</p>
                         <p class="text-center ttlx2 mb-1"> Automated onboarding module KYC </p>
                         <p class="text-center ttlx3"> E-Onboarding</p>

                         <p class="text-center"> <img src="../assets/contiinex/image/eBordingImg.png" class="enbordingImgCs"/></p>



                         <div>
                         <div class="mainRowXyDiv">
                            <div class="rowXy">                                
                                <div> <img src="../assets/contiinex/image/AOMImg1.png" style="width:180px"/> </div>
                                <div>
                                   <p class="ttlx">App Download</p>
                                   <p class="ttlx2"> Agent App for e-Onboarding </p>
                               </div>
                               <div class="forWebMenuOX"><img src="../assets/contiinex/image/AomArrow1.png" style="width:120px"/> </div>                            
                            </div>



                            <div class="rowXy vSection2">
                                <div> &nbsp;</div>
                                <div> &nbsp;</div>
                                <div>
                                   <img src="../assets/contiinex/image/AOMImg2.png" style="width:180px"/>
                                </div>
                                <div>
                                   <p class="ttlx">Biometric Profiling</p>
                                   <p class="ttlx2 mb-0"> Voice Biometric </p>
                                   <p class="ttlx2 mb-0">Facial Biometric </p>
                                
                               </div>
                            </div>
                            
                            <div class="rowXy vSection3 forWebMenuOX">
                                <div> &nbsp;</div>
                                <div> &nbsp;</div>                               
                                <div>
                                 <p>  <img src="../assets/contiinex/image/AomArrow2.png" style="width:180px"/></p>
                               </div>
                            </div>

                            <div class="rowXy vSection4">                                
                                <div> <img src="../assets/contiinex/image/AOMImg3.png" style="width:180px"/> </div>
                                <div>
                                    <p class="ttlx">Document Upload</p>
                                    <p class="ttlx2 mb-0">Document upload, government ID proofs (Manual input & scan & upload</p>
                                    <p class="ttlx2 mb-0">Education documents </p>
                                    <p class="ttlx2 mb-0">Thirdparty assessment certificates(Typing test) </p>
                                   
                               </div>                                                         
                            </div>


                            <div class="rowXy vSection5 forWebMenuOX">
                                <div> &nbsp;</div>
                                <div> &nbsp;</div>                               
                                <div>
                                    <p> <img src="../assets/contiinex/image/AomArrow3.png" style="width:180px"/> </p>
                               </div>
                               <div> &nbsp;</div> 
                            </div>


                            <div class="rowXy vSection6">
                                <div> &nbsp;</div>
                                <div class="forMobileMenuOX">
                                    <img src="../assets/contiinex/image/AOMImg4.png" style="width:180px"/>
                                 </div>
                                <div>
                                    <p class="ttlx">Documents Verification</p>
                                    <p class="ttlx2 mb-0">Manual Verification by recrutier </p>
                                    <p class="ttlx2 mb-0">Automated KYC through backend integration </p>                                  
                                
                               </div>
                               <div class="forWebMenuOX">
                                <img src="../assets/contiinex/image/AOMImg4.png" style="width:180px"/>
                             </div>
                            </div>
                         
                            <div class="rowXy vSection7 forWebMenuOX">
                                <div> &nbsp;</div>
                                <div> &nbsp;</div>                               
                                <div>
                                    <p>  <img src="../assets/contiinex/image/AomArrow4.png" style="width:180px"/></p>
                               </div>
                               <div> &nbsp;</div> 
                            </div>
                         

                            <div class="rowXy vSection8">                                
                                <div> <img src="../assets/contiinex/image/AOMImg5.png" style="width:180px"/> </div>
                                <div>
                                    <p class="ttlx">Agreement Accceptance</p>
                                    <p class="ttlx2 mb-0">e - signing agreement (standard agreement)</p>
                                    <p class="ttlx2 mb-0">Offer Acceptance</p>
                                    <p class="ttlx2 mb-0">Agreement can be configured basis the requirement </p>
                                   
                               </div>                                                         
                            </div>


                        </div>



                         <p class="text-center mb-1"> <img src="../assets/contiinex/image/imageThreeText.png" class="countText"/></p>
                         <p class="text-center mb-1"> <img src="../assets/contiinex/image/iconTextLogoImg3.png" class="countTextLogo" style="width:20px;"/></p>
                         <p class="text-center ttlx">MOBILE-BASED TRAINING MODULE</p>
                         <p class="text-center ttlx2 mb-1"> Remote training module along with assessments, audit & content sharing </p>
                         <p class="text-center ttlx3">Remote Training</p>
                         <div class="autoDiv2" >
                           
                            <div> <img src="../assets/contiinex/image/remot1.png"> <p class="text-center ttlx">Batch Management</p> </div>
                            <div> <img src="../assets/contiinex/image/remot2.png"> <p class="text-center ttlx">Interactive<br> Auto-Video Training</p> </div>
                            <div> <img src="../assets/contiinex/image/remot3.png"> <p class="text-center ttlx">Assessments &<br> Certification</p> </div>
                            <div> <img src="../assets/contiinex/image/remot4.png"> <p class="text-center ttlx">Mock Calls And OJT</p> </div>
                            <div> <img src="../assets/contiinex/image/remot5.png">  <p class="text-center ttlx">Easy Integration With<br> Native Apps</p></div>
                            <div> <img src="../assets/contiinex/image/remot6.png">  <p class="text-center ttlx">Analytics & Dashboard</p></div> 
                         </div>

                         <p class="text-center mb-1"> <img src="../assets/contiinex/image/imageFourText.png" class="countText"/></p>
                         <p class="text-center mb-1"> <img src="../assets/contiinex/image/iconTextLogoImg4.png" class="countTextLogo"/></p>
                         <p class="text-center ttlx">PROCESS DELIVERY MODULE</p>                      
                         <p class="text-center ttlx2 mb-1"> Cloud base Predictive dialer and Automatic call distributor </p>
                         <p class="text-center ttlx3">Remote Process Delivery -Cloud - Based, Desktop-Based, Mobile - Based</p>
                         <p class="pdm1">PRODUCTON DASHBOARD & REPORTS</p>
                          
                         <div class="pdmDiv1">
                             <table>
                                 <tr> 
                                     <td class="vtdW"><mat-icon class="vDot1">noise_control_off</mat-icon><img src="../assets/contiinex/image/arrowDownLong.png" class="pdmArrowImg"/> </td>
                                     <td class="forWebMenuOX"><img src="../assets/contiinex/image/pdrImg1.png" class="pdrImg"/> </td>
                                     <td>
                                         <div class="forMobileMenuOX"><img src="../assets/contiinex/image/pdrImg1.png" class="pdrImg"/></div>
                                         <div class="ttlx ml-3"> Work Force Management</div>
                                    <ul class="pdmUl1">
                                        <li> Agent slot selection </li>
                                        <li> Slot allotment by program manager </li>
                                    </ul>
                                    </td>
                                    </tr>
                                    <tr><td colspan="3"><br></td></tr>
                                    <tr> 
                                        <td class="vtdW"><mat-icon class="vDot1">noise_control_off</mat-icon><img src="../assets/contiinex/image/arrowDownLong.png" class="pdmArrowImg"/>  </td>
                                        <td  class="forWebMenuOX"><img src="../assets/contiinex/image/pdrImg2.png" class="pdrImg"/> </td>
                                        <td> 
                                            <div  class="forMobileMenuOX"><img src="../assets/contiinex/image/pdrImg2.png" class="pdrImg"/></div>
                                            <div class="ttlx ml-3"> Manage Team</div>
                                       <ul class="pdmUl1">
                                           <li>By Campaign </li>
                                   </ul>
                                       </td>
                                       </tr>
                                       <tr><td colspan="3"><br></td></tr>
                                       <tr> 
                                        <td class="vtdW"><mat-icon class="vDot1">noise_control_off</mat-icon><img src="../assets/contiinex/image/arrowDownLong.png" class="pdmArrowImg"/>  </td>
                                        <td class="forWebMenuOX"><img src="../assets/contiinex/image/pdrImg3.png" class="pdrImg"/> </td>
                                        <td>
                                            <div  class="forMobileMenuOX"><img src="../assets/contiinex/image/pdrImg3.png" class="pdrImg"/></div>                                            
                                            <div class="ttlx ml-3">Run Campaign</div>
                                       <!-- <ul class="pdmUl1">
                                           <li></li>
                                          </ul> -->
                                       </td>
                                       </tr>
                                       <tr><td colspan="3"><br></td></tr>
                                       <tr> 
                                        <td class="vtdW"><mat-icon class="vDot1">noise_control_off</mat-icon><img src="../assets/contiinex/image/arrowDownLong.png" class="pdmArrowImg"/>  </td>
                                        <td class="forWebMenuOX"><img src="../assets/contiinex/image/pdrImg4.png" class="pdrImg"/> </td>
                                        <td> 
                                            <div  class="forMobileMenuOX"><img src="../assets/contiinex/image/pdrImg4.png" class="pdrImg"/> </div>
                                            <div class="ttlx ml-3">OB - Predictive dialer <br> IB - Auto Call Distributor</div>
                                      <!-- <ul class="pdmUl1">
                                           <li></li>
                                          </ul> -->
                                       </td>
                                       </tr>
                                       <tr><td colspan="3"><br></td></tr>
                                       <tr> 
                                        <td class="vtdW"><mat-icon class="vDot1">noise_control_off</mat-icon><img src="../assets/contiinex/image/arrowDownLong.png" class="pdmArrowImg"/>  </td>
                                        <td class="forWebMenuOX"><img src="../assets/contiinex/image/pdrImg5.png" class="pdrImg"/> </td>
                                        <td> 
                                            <div  class="forMobileMenuOX"><img src="../assets/contiinex/image/pdrImg5.png" class="pdrImg"/></div>
                                            <div class="ttlx ml-3">Customized Rules & Policies</div>
                                      <!-- <ul class="pdmUl1">
                                           <li></li>
                                          </ul> -->
                                       </td>
                                       </tr>
                                       <tr><td colspan="3"><br></td></tr>
                                       <tr> 
                                        <td class="vtdW"><mat-icon class="vDot1">noise_control_off</mat-icon><img src="../assets/contiinex/image/arrowDownLong.png" class="pdmArrowImg"/>  </td>
                                        <td class="forWebMenuOX"><img src="../assets/contiinex/image/pdrImg6.png" class="pdrImg"/> </td>
                                        <td> 
                                            <div  class="forMobileMenuOX"><img src="../assets/contiinex/image/pdrImg6.png" class="pdrImg"/></div>
                                            <div class="ttlx ml-3">Customized Rules & Policies</div>
                                       <ul class="pdmUl1">
                                           <li>Call Barge </li>
                                           <li>Conference </li>
                                           <li>Whisper </li>
                                   </ul>
                                       </td>
                                       </tr>
                                       <tr><td colspan="3"><br></td></tr>
                                       <tr> 
                                        <td class="vtdW"><mat-icon class="vDot1">noise_control_off</mat-icon><img src="../assets/contiinex/image/arrowDownLong.png" class="pdmArrowImg" style="visibility: hidden;"/>  </td>
                                        <td class="forWebMenuOX"><img src="../assets/contiinex/image/pdrImg7.png" class="pdrImg"/> </td>
                                        <td>
                                            <div  class="forMobileMenuOX"><img src="../assets/contiinex/image/pdrImg7.png" class="pdrImg"/> </div>
                                            <div class="ttlx ml-3">Customizable<br> Agent App With Dispositions</div>
                                      <!-- <ul class="pdmUl1">
                                           <li></li>
                                          </ul> -->
                                       </td>
                                       </tr>
                             </table>
                         </div>
<p class="pdm2Text">Comnex Enable improves customer experience, scheduling, and operational efficiency by providing insights on staffing levels, allows employees to be connected to their mentors with an easy supervision features. 
    By the understanding of staffing needs, Its now easier to manage contact centers, improve their efficiency and optimse of operations
    </p>
                       
                        
                </div>
            </div>
             </div>
  
    

              </div>


  
        <div class="col-md-12 col-sm-12 col-xs-12">
            <app-new-contiinex-footer-section-page></app-new-contiinex-footer-section-page>
        </div>
   


</div>
</div>
</div>
