import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router'
import * as moment from 'moment';
import * as _ from 'underscore';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ApiService } from '../api.service';
import { RtddataService } from '../rtdData.service';
import { MyserviceService } from '../myservice.service';
import { SocketelsService } from '../socketels.services';

@Component({
	selector: 'app-central-dashboard',
	templateUrl: './central-dashboard.component.html',
	styleUrls: ['./central-dashboard.component.scss']
})
export class CentralDashboardComponent implements OnInit, OnDestroy {

	showLoader: boolean = false;
	currentTitle: string = 'central-dashboard';
	selectedButtonToggleVal: any = 'dataTab';

	private subscription: Subscription = new Subscription();

	showHideDataTabPage: boolean = true;
	showHideDialedTabPage: boolean = false;
	showHideContactedTabPage: boolean = false;
	showHideAgentsTabPage: boolean = false;
	showHideActiveTimeTabPage: boolean = false;
	showHideQdeTabPage: boolean = false;
	api_end_point:any;
	constructor(private API: ApiService,
		 private rtd: RtddataService, 
		 private service: MyserviceService,
		 private socket: SocketelsService) { }

	ngOnInit() {
		this.showLoader=true;
		this.api_end_point = this.service.get_API_End_Point();
		this.get_relationmap();
	}

	get_relationmap(){
		this.API.getUserRelationCentral(this.api_end_point).subscribe((resp: any) => {
			if(resp.success){
				//console.log('resp.success', resp.success);
				this.service.setCentralTeamRelation(resp.success.data);
				this.requestGroupSocket();
			}else{
				alert(`error - ${resp.errors}`)
			}
		})
	}

	requestGroupSocket(){
		this.subscription.add(
			this.socket.isSocketEnable.subscribe(flag => {
				//console.log('socket enabled', flag);
				if(flag){
					this.service.getCentralTeamRelation().subscribe(obj => {
						//console.log('subscription resceived', obj);
						this.socket.central_group_request(obj);
						this.service.setCentralRoles();
						this.service.initddlSelection();
						this.showLoader=false;
					})
				}
			})
		)
	}

	onToggleButtonValChange(val: string) {

		this.selectedButtonToggleVal = val;
		this.showHideDataTabPage = false;
		this.showHideDialedTabPage = false;
		this.showHideContactedTabPage = false;
		this.showHideAgentsTabPage = false;
		this.showHideActiveTimeTabPage = false;
		this.showHideQdeTabPage = false;
		if (val == 'dataTab') {
			this.showHideDataTabPage = true;
		}
		else if (val == 'dialedTab') {
			this.showHideDialedTabPage = true;
		}
		else if (val == 'contactedTab') {
			this.showHideContactedTabPage = true;
		}
		else if (val == 'agentsTab') {
			this.showHideAgentsTabPage = true;
		}
		else if (val == 'activeTimeTab') {
			this.showHideActiveTimeTabPage = true;
		}
		else if (val == 'qdeTab') {
			this.showHideQdeTabPage = true;
		}
	}
	getCurrentDate() {
		let myDate = new Date();
		let daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Aug', 'Oct', 'Nov', 'Dec'];
		let date = myDate.getDate();
		let month = monthsList[myDate.getMonth()];
		let year = myDate.getFullYear();
		let day = daysList[myDate.getDay()];
		let today = `${date} ${month} ${year}, ${day}`;
		return today;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}


