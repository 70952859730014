<div class="row">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <span style="position: absolute;top: -36px;right: 15px;z-index: 2;cursor: pointer;">
      <img src="../assets/closeicon.svg" width="40px" style="position: fixed;" (click)="closeDialog()" /></span>
  </div>
</div>
<div class="mainDialogDiv">
  <div class="row">
    <div class="col-md-12">
      <h1 class="hOne">Upload Excel file</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="upload-btn-wrapper" style="width: 105px;">
        <button class="btnbrowse">Select file</button>
        <input type="file" #fileUpload class="filecs" id="fileID" (change)="changeFile($event)" />
      </div>
	  <span>{{file?.name}}</span>
    </div>
    <div class="col-md-6 text-right">
      <button class="submit-btn-ui" (click)="saveFile()"> Submit</button>
    </div>
  </div>
</div>
