import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-demo-portal-dashboard',
  templateUrl: './demo-portal-dashboard.component.html',
  styleUrls: ['./demo-portal-dashboard.component.scss']
})
export class DemoPortalDashboardComponent implements OnInit {
  showHideDashboard:boolean=true;
  showHideCrcCall:boolean=false;

  showHideOBDCall:boolean=false;
  showHideDOBDCall:boolean=false;
  showHideSMS:boolean=false;
  showHideWhatsapp:boolean=false;
  showHideSipCall:boolean=false;
  showHideEmail:boolean=false;
  constructor(public router:Router) { }

  ngOnInit(): void {
  }
//   crcCall(){
//     this.showHideDashboard=false;
//     this.showHideCrcCall=true;
// //this.router.navigateByUrl('/demo-portal/crc-call');
//   }
  clickPortalMenu(val:any){
    this.showHideOBDCall=false;
    this.showHideDOBDCall=false;
    this.showHideSMS=false;
    this.showHideWhatsapp=false;
    this.showHideCrcCall=false;
    this.showHideDashboard=false;
    this.showHideSipCall=false;
    this.showHideEmail=false;
    if(val=="crcCall"){
      this.showHideCrcCall=true;
    }
    else if(val=="obd"){
      this.showHideOBDCall=true;
    }
    else if(val=="dobd"){
      this.showHideDOBDCall=true;
    }
    else if(val=="sms"){
      this.showHideSMS=true;
    }
    else if(val=="whatsapp"){
      this.showHideWhatsapp=true;
    }
    else if(val=="sip"){
      this.showHideSipCall=true;
    }
    else if(val=="email"){
    
      this.showHideEmail=true;
    }
    else{
      this.showHideDashboard=true;
    }
  

  }
}
