import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-onboarding-process-page7',
  templateUrl: './onboarding-process-page7.component.html',
  styleUrls: ['./onboarding-process-page7.component.scss']
})
export class OnboardingProcessPage7Component implements OnInit {

  constructor(private router:Router) { 
    window.scroll(0,0);
  }

  ngOnInit(): void {
  }
  saveBtn(){
   // this.router.navigateByUrl('/onboarding_process_page7')
   alert("ok done");
   window.scroll(0,0);
   this.router.navigateByUrl('/onboarding')
      }
}
