<div>
  
    <div style="position: sticky;top: -17px;background: #fff;z-index: 2;margin: 0;padding-top: 21px;">
<div class="row" >
    <div class="col-md-10 col-sm-10 col-xs-10"  >
        <h3>Feedback</h3>
        </div>
        <div class="col-md-2 col-sm-2 col-xs-2" >
            <span  style="position: absolute;top: -26px;right: 15px;z-index: 2;cursor: pointer;" (click)="closeDialog()">
            <mat-icon style="background: red;color: white;border-radius: 50%;font-size: 18px;padding: 3px;">close</mat-icon></span>
        </div>
</div>
<br>
<div class="row">
    <div class="col-md-12">
        <mat-accordion>          
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <b>View Guidelines </b>  
                </mat-panel-title>
              </mat-expansion-panel-header>
              <table class="guidlineTable" border="1" >
                <tr><td> <b> Parameters</b></td><td> <b>Description of desired behaviors </b></td><td> <b> Description of undesired behaviors</b></td></tr>
                  <tr><td colspan="3"><b>Comprehension & Comprehensibility</b></td></tr>
                <tr>
                  <td>  </td>
                  <td> Fully understands everything the speaker says (repetition / rephrasing not required / does not get confused) </td>
                  <td> Dead air or long pauses, asks for repetition, provides an answer not related to the question, paraphrases incorrectly, </td>  
                  </tr>
                  <tr>
                  <td>  </td>
                  <td> Speaks clearly and is understood by the listener. Enunciation is clear, MTI  does not affect the ability to be understood </td>
                  <td> Strong MTI  , Regional Accent, Pronunciation errors affecting comprehensibility </td>  
                  </tr>
                  
                  <tr><td colspan="3"><b>Clarity</b></td></tr>
                <tr>
                  <td><b>Clarity of thought</b>  </td>
                  <td> Speaks openly and confidently (with conviction) Thoughts are clear and concise with a logical flow. </td>
                  <td> Illogical answers, inability to connect thoughts, does not answer to the point. </td>  
                  </tr>
                  <tr>
                  <td> <b>Sentence Construction</b> </td>
                  <td>Is able to construct sentences during a conversation with proper prepositions, articles, tenses and subject-verb agreement. </td>
                  <td>Incorrect word order  and phrases, not pausing where required, use of foghorns(fillers), uses regionally influenced terms and phrases </td>  
                  </tr>
                  
                  <tr>
                  <td><b>Voice Quality</b>  </td>
                  <td> Pace/ Pitch/ Tone / Projection is appropriate </td>
                  <td>Speaking too fast, inappropriate pitch (too high / too low), speech impediments affecting quality (ex – lisp / stammering) </td>  
                  </tr>
                  <tr>
                  <td> <b>Responds well</b> </td>
                  <td>Prompt & appropriate responses (shows understanding & empathy) Is able to express his / herself by using appropriate language and word choice </td>
                  <td>Inability to express him / herself, draws a blank, inability to answer promptly, does not empathize, Stuttering, displays nervousness, is unsure of answers, mumbles. </td>  
                  </tr>
                  
                  
                    <tr><td colspan="3"><b>Others</b></td></tr>
                <tr>
                  <td><b>Listening Skills</b>  </td>
                  <td> Listens attentively (pays attention & focuses on what the speaker says / repetition not required), Is not distracted and is focused </td>
                  <td> Poor listening abilities, poor in acknowledging, does not paraphrase or use questions to clarify before answering, interrupts and talks over the speaker.  </td>  
                  </tr>
                  <tr>
                  <td> <b>Attitude</b> </td>
                  <td>Is polite, energetic, displays positive energy. Does not interrupt. </td>
                  <td>Pessimistic, sarcastic, carefree attitude, blaming, unenthusiastic, demanding, display of frustration and irritability, sounds / appears uncomfortable, dull, disinterested, sounds / appears bored, not focused  </td>  
                  </tr>
                  
                 <tr><td colspan="3"><b>Written Skills</b></td></tr>
                    <tr>
                  <td> <b>Punctuation</b> </td>
                  <td>Correct use of punctuation</td>
                  <td>Incorrect use of punctuation</td>  
                  </tr>
                     <tr>
                  <td> <b>Prepositions</b> </td>
                  <td>Correct usage of words placed before (or close to) nouns and pronouns to denote relationship </td>
                  <td>Incorrect usage of words placed before (or close to) nouns and pronouns to denote relationship</td>  
                  </tr>
                     <tr>
                  <td> <b>Articles</b> </td>
                  <td>Correct usage of definite or indefinite articles (an, a, the, some)</td>
                  <td>Incorrect usage of definite or indefinite articles (an, a, the, some)</td>  
                  </tr>
                  
                      <tr>
                  <td> <b>Tenses</b> </td>
                  <td>Use of appropriate tenses - past, present, future (simple tenses wherever possible)</td>
                  <td>Use of inappropriate tenses - past, present, future (simple tenses wherever possible)</td>  
                  </tr>
                  
                      <tr>
                  <td> <b>Subject-verb agreement</b> </td>
                  <td>Matching the subject with verb (whether  singular / plural)</td>
                  <td>Mismatching the subject with verb (whether  singular / plural)</td>  
                  </tr>
                     <tr>
                  <td> <b>Syntax / structure; Short and simple sentences</b> </td>
                  <td>Use of appropriate sentence construction - States 1 idea per sentence</td>
                  <td>Use of inappropriate sentence construction -  States more than one idea within a sentence</td>  
                  </tr>
                    <tr>
                  <td> <b>Logical flow of thought</b> </td>
                  <td>Written thoughts are clear and concise with a logical flow.</td>
                  <td>Written thoughts are disconnected without any logical flow.</td>  
                  </tr>
                </table>
                
            </mat-expansion-panel>
          </mat-accordion>
    </div>

</div>

</div>
<br>
    <form  [formGroup]="createdForm">
        <div class="row">
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Candidate Name" autocomplete="off" formControlName="candidateName">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Recruiter Name" autocomplete="off" formControlName="recruiterName">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="DOB" autocomplete="off" disabled formControlName="dobDate">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput [matDatepicker]="dateOnepicker" placeholder="Date" readonly
                        (click)="dateOnepicker.open()" formControlName="dateOne">
                    <mat-datepicker-toggle matSuffix [for]="dateOnepicker"></mat-datepicker-toggle>
                    <mat-datepicker #dateOnepicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-select placeholder="Process" formControlName="process">
                        <!-- <mat-option value="process1">Process 1</mat-option>
                        <mat-option value="process2">Process 2</mat-option> -->
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-select placeholder="Source" formControlName="source">
                        <!-- <mat-option value="source1">Source 1</mat-option>
                        <mat-option value="source2">Source 2</mat-option> -->
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <hr>
        <b>Cover the following with the candidate, indicating any issues:</b><br><br>
        <div class="row">
            <div class="col-md-6" style="font-size: 0.8em;">
                Comfortable with working in a Voice / Non Voice process
            </div>
            <div class="col-md-2">
                <mat-radio-group formControlName="voiceNonvoiceRadio">
                    <mat-radio-button value="yes">Yes &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Comments" autocomplete="off" formControlName="voiceNonvoiceComment">
                </mat-form-field>
            </div>
        </div><br>

        <div class="row">
            <div class="col-md-6" style="font-size: 0.8em;">
                Flexible to work in any shift (incl. night shift)
            </div>
            <div class="col-md-2">
                <mat-radio-group formControlName="shiftRadio">
                    <mat-radio-button value="yes">Yes &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Comments" autocomplete="off" formControlName="shiftComment">
                </mat-form-field>
            </div>
        </div><br>


        <div class="row">
            <div class="col-md-6" style="font-size: 0.8em;">
                Open to any weekly offs
            </div>
            <div class="col-md-2">
                <mat-radio-group formControlName="wklyOff">
                    <mat-radio-button value="yes">Yes &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Comments" autocomplete="off" formControlName="wklyOffComment">
                </mat-form-field>
            </div>
        </div><br>



        <div class="row">
            <div class="col-md-6" style="font-size: 0.8em;">
                Open to relocation / Preferred work location
            </div>
            <div class="col-md-2">
                <mat-radio-group formControlName="workLocation">
                    <mat-radio-button value="yes">Yes &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Comments" autocomplete="off" formControlName="workLocationComments">
                </mat-form-field>
            </div>
        </div><br>

        <div class="row">
            <div class="col-md-6" style="font-size: 0.8em;">
                Comfortable with computers / typing
            </div>
            <div class="col-md-2">
                <mat-radio-group formControlName="typing">
                    <mat-radio-button value="yes">Yes &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Comments" autocomplete="off" formControlName="typingComments">
                </mat-form-field>
            </div>
        </div><br>
        <hr>
        <b>COMPREHENSION</b><br><br>

        <div class="row">
            <div class="col-md-8" style="font-size: 0.8em;">
                Fully understands everything the speaker says (repetition / rephrasing not required / is not confused by
                the speaker)
            </div>
            <div class="col-md-4">
                <mat-radio-group formControlName="speakersays">
                    <mat-radio-button value="yes">Poor &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="yes">Average &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">Good</mat-radio-button>
                </mat-radio-group>
            </div>

        </div><br>

        <div class="row">
            <div class="col-md-8" style="font-size: 0.8em;">
                Speaks clearly and is understood by the listener (Enunciation / MTI)
            </div>
            <div class="col-md-4">
                <mat-radio-group formControlName="speakerListner">
                    <mat-radio-button value="yes">Poor &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="yes">Average &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">Good</mat-radio-button>
                </mat-radio-group>
            </div>

        </div><br>
        <hr>
        <b>CLARITY</b><br><br>

        <div class="row">
            <div class="col-md-8" style="font-size: 0.8em;">
                Sentence Construction - syntax /grammar / Indian English
            </div>
            <div class="col-md-4">
                <mat-radio-group formControlName="sentence">
                    <mat-radio-button value="yes">Poor &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="yes">Average &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">Good</mat-radio-button>
                </mat-radio-group>
            </div>

        </div><br>
        <div class="row">
            <div class="col-md-8" style="font-size: 0.8em;">
                Clarity of thought (presents thoughts clearly and logically)
            </div>
            <div class="col-md-4">
                <mat-radio-group formControlName="thought">
                    <mat-radio-button value="yes">Poor &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="yes">Average &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">Good</mat-radio-button>
                </mat-radio-group>
            </div>

        </div><br>
        <div class="row">
            <div class="col-md-8" style="font-size: 0.8em;">
                Responds well - prompt & appropriate responses (shows understanding & empathy) Is able to express him /
                herself by using appropriate language and word choice
            </div>
            <div class="col-md-4">
                <mat-radio-group formControlName="promotResponse">
                    <mat-radio-button value="yes">Poor &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="yes">Average &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">Good</mat-radio-button>
                </mat-radio-group>
            </div>

        </div><br>
        <div class="row">
            <div class="col-md-8" style="font-size: 0.8em;">
                Voice quality – pitch / tone / projection / pace (Trainable)
            </div>
            <div class="col-md-4">
                <mat-radio-group formControlName="voiceQuality">
                    <mat-radio-button value="yes">Poor &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="yes">Average &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">Good</mat-radio-button>
                </mat-radio-group>
            </div>

        </div><br>
        <hr>
        <b> OTHERS</b><br><br>
        <div class="row">
            <div class="col-md-8" style="font-size: 0.8em;">
                <h5>CONFIDENCE:</h5> *Speaks openly and confidently (Trainable)
            </div>
            <div class="col-md-4">
                <mat-radio-group formControlName="confidence">
                    <mat-radio-button value="yes">Poor &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="yes">Average &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">Good</mat-radio-button>
                </mat-radio-group>
            </div>

        </div><br>
        <div class="row">
            <div class="col-md-8" style="font-size: 0.8em;">
                <h5>LISTENING SKILLS:</h5> Listens attentively (pays attention & focuses on what the speaker says /
                repetition not required)
            </div>
            <div class="col-md-4">
                <mat-radio-group formControlName="listeningSkills">
                    <mat-radio-button value="yes">Poor &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="yes">Average &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">Good</mat-radio-button>
                </mat-radio-group>
            </div>

        </div><br>
        <div class="row">
            <div class="col-md-8" style="font-size: 0.8em;">
                <h5>ATTITUDE:</h5> Polite, enthusiastic, positive (does not interrupt, uses appropriate tone & language,
                displays positive energy)
            </div>
            <div class="col-md-4">
                <mat-radio-group formControlName="attitude">
                    <mat-radio-button value="yes">Poor &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="yes">Average &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">Good</mat-radio-button>
                </mat-radio-group>
            </div>

        </div><br>
        <div class="row">
            <div class="col-md-8" style="font-size: 0.8em;">
                <h5> WRITTEN SKILLS:</h5> Correct use of punctuation, prepositions, articles, tenses, subject-verb
                agreement, sentence structure, short and simple sentences, logical flow of thought.
            </div>
            <div class="col-md-4">
                <mat-radio-group formControlName="writtenSkills">
                    <mat-radio-button value="yes">Poor &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="yes">Average &nbsp;&nbsp;</mat-radio-button>
                    <mat-radio-button value="no">Good</mat-radio-button>
                </mat-radio-group>
            </div>

        </div><br>
        <hr>
        <b> INSTRUCTIONS</b><br><br>
        <div class="row">
            <div class="col-md-6" style="font-size: 0.8em;">
                <b>If candidate has moved 2 to 3 jobs within a short span please ensure to ask for valid reasons </b><br>
                <textarea formControlName="candidateMove" rows="5" style="resize: none;width:100%;"> </textarea>
            </div>
            <div class="col-md-6" style="font-size: 0.8em;">
                <b>Additional Comments </b><br><br>
                <textarea formControlName="additionalComments" rows="5" style="resize: none;width:100%;"> </textarea>
            </div>
        </div><br>
        <div class="row">
          
        </div><br>
        <div class="row">
            <div class="col-md-6" style="font-size: 0.8em;">
                <b>Strengths </b><br><br>
                <textarea formControlName="strength" rows="5" style="resize: none;width:100%;"> </textarea>
            </div>
            <div class="col-md-6" style="font-size: 0.8em;">
                <b>Areas of Improvement </b><br><br>
                <textarea formControlName="areaImprovement" rows="5" style="resize: none;width:100%;"> </textarea>
            </div>
        </div><br>
        <div class="row">     
            
            <div class="col-md-12 col-sm-12 col-xs-12 text-right" *ngIf="!(viewData && viewData.feedback_form)">
                <p *ngIf="showHideMessage" style="font-weight: bold;" [ngStyle]="{'color':msgColor}">{{messageDisplay}}</p>
                <button mat-button class="cancelbtn" style="color: #e91e63 !important;border: solid 1px #e91e63 !important;"  (click)="cancelClear()">Cancel</button>
                <button mat-button class="submitbtn" style="border: solid 1px #e91e63 !important;background-color: #e91e63 !important;" (click)="saveFeedback(createdForm)" >Save</button>
            </div>
          
            </div>










    </form>
</div>