import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MyserviceService } from 'src/app/myservice.service';

@Component({
	selector: 'app-payment-dashboard-approval-edit-dialog',
	templateUrl: './payment-dashboard-approval-edit-dialog.component.html',
	styleUrls: ['./payment-dashboard-approval-edit-dialog.component.scss']
})
export class PaymentDashboardApprovalEditDialogComponent implements OnInit {
	_viewData: any;
	creditDebitVal: any = "CREDIT";
	amountVal: any = "";
	_color: any = "";
	showHidePassingMessage: boolean = false;
	passingMessage: any = "";
	messageValue: any = ""
	currentLoader: boolean = false;
	api_end_point:any;
	constructor(public dialogRef: MatDialogRef<PaymentDashboardApprovalEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewData, private API: ApiService,private _snackBar: MatSnackBar,private myService: MyserviceService) { }


	ngOnInit() {
		this.api_end_point = this.myService.get_API_End_Point();
		this._viewData = this.viewData;
	}
	closeDialog() {
		this.dialogRef.close();
	}
	radioChangeDebit(val: any) {
		this.creditDebitVal = val;
	}

	updateBtn() {
		this.passingMessage = "";
		this._color = '';
		this.showHidePassingMessage = false;
		
		if (this.amountVal == "" || this.amountVal == null || this.amountVal == "0") {
			this.passingMessage = "* Please enter amount";
			this._color = 'red';
			this.showHidePassingMessage = true;
			setTimeout(() => {
				this.passingMessage = "";
				this._color = '';
				this.showHidePassingMessage = false;
			}, 5000);
			return;
		}
		if(this.creditDebitVal == "DEBIT" && this._viewData.current_balance < this.amountVal)
		{
			this._snackBar.open("Amount should be less then Current Balance", "close");
		}
		let _obj = {
			"for_user": this._viewData.user_id,
			"type": this.creditDebitVal,//"DEBIT",
			"amount": this.amountVal,// "19.32",
			"message": this.messageValue
		}
		//debugger;
		this.API.paymentAddReaxationAmount(_obj,this.api_end_point).subscribe((data: any) => {
			let _response = data;
			if (_response.success) {
				this.passingMessage = "Amount updated successfully.";
				this._color = 'green';
				this.amountVal = "0";
				this.messageValue = "";
				this.showHidePassingMessage = true;
				this.passingMessage = "";
				this._color = '';
				this.showHidePassingMessage = false;
				setTimeout(() => {
					this.dialogRef.close(data.success);
				}, 1000);
				
			}
		});
	}
	cancelBtn() {
		this.amountVal = "0";
		this.messageValue = "";
	}
	decimalFilter(event: any) {
		const reg = /^-?\d*(\.\d{0,2})?$/;
		let input = event.target.value + String.fromCharCode(event.charCode);
	 
		if (!reg.test(input)) {
			event.preventDefault();
		}
	 }
}
