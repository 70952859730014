<ngb-carousel #slide [showNavigationArrows]="false" [showNavigationIndicators]="true">
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<div class="row">
				<div class="col-sm-5 col-md-5 col-lg-5">
					<div class="youtube_video">
					<iframe width="440px" height="300px" src="https://www.youtube.com/embed/4fVBb5jZR_w" frameborder="0" allowfullscreen></iframe>
					</div>
				</div>
				<div class="col-sm-7 col-md-7 col-lg-7">
				<div class="review_text_video">
					"After an incident that paralyze me for life, Uearn has helped me with a perfect opportunity I was looking for and helped me gain my confidence back. Uearn has given me a platform to be financially Independent."
				</div>
				<div class="customer_details_video">
					Sushanta Das,<br>
					Uearn Customer Experience Expert,<br>
					Ranaghat, West Bengal.
				</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<div class="row">
				<div class="col-sm-5 col-md-5 col-lg-5">
					<div class="youtube_video">
					<iframe width="440px" height="300px" src="https://www.youtube.com/embed/qJBOrugKAc8" frameborder="0" allowfullscreen></iframe>
					</div>
				</div>
				<div class="col-sm-7 col-md-7 col-lg-7">
				<div class="review_text_video">
					"Being a homemaker and a mother is a full time job, but I still wanted to be financially independent and support my family, and Uearn gave me the platform were I can work on my own flexible timings, with managing and balancing my personal and professional life."
				</div>
				<div class="customer_details_video">
					Baisakhi Das,<br>
					Uearn Customer Experience Expert,<br>
					Bangalore.
				</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<div class="row">
				<div class="col-sm-5 col-md-5 col-lg-5">
					<div class="youtube_video">
						<iframe width="440px" height="300px" src="https://www.youtube.com/embed/bgh2pDio9NE" frameborder="0" allowfullscreen></iframe>
					</div>
				</div>
				<div class="col-sm-7 col-md-7 col-lg-7">
				<div class="review_text_video">
					"I work from the comfort of my home and I am doing the job I like the most."
				</div>
				<div class="customer_details_video">
					Swagata Basak,<br>
					Uearn Trainer,<br>
					Mangalore.
				</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<div class="row">
				<div class="col-sm-5 col-md-5 col-lg-5">
					<div class="youtube_video">
						<iframe width="440px" height="300px" src="https://www.youtube.com/embed/7_pC0XEqrb4" frameborder="0" allowfullscreen></iframe>
					</div>
				</div>
				<div class="col-sm-7 col-md-7 col-lg-7">
				<div class="review_text_video">
					"Uearn has helped me to earn conveniently while boosting my self confidence and communication."
				</div>
				<div class="customer_details_video">
					Manmeet Kaur,<br>
					2nd year B.com student & Uearn Customer Experience Expert,<br>
					Dehradun, Uttarakhand.
				</div>
				</div>
			</div>
		</div>
	</ng-template>
</ngb-carousel>