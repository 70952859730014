<div class="scrollingDiv">
	<table class="tbl full_width">
		<tr style="border-bottom: 1px solid #ccc;">
			<td>
				<h5 class="hd5">{{input_data['campaign']}} - Campaign Details</h5>
			</td>
			<td style="text-align: right;">
				<i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i>
			</td>
		</tr>
		<tr>
			<th>Campaign Name</th>
			<td>{{input_data['campaign']}}</td>
		</tr>
		<tr>
			<th>Owner</th>
			<td>{{ input_data['name'] }}</td>
		</tr>
		<tr>
			<th>Started</th>
			<td>{{ campaign_start_at }}</td>
		</tr>
		<tr>
			<th>Dial Mode</th>
			<td>{{ get_dial_priority() }}</td>
		</tr>
		<tr>
			<th>Last Refreshed</th>
			<td>{{ last_refreshed_at }}</td>
		</tr>
		<tr *ngIf="campaign_newAgents.length && input_data.active_status">
			<td colspan="2">
				<mat-expansion-panel hideToggle>
					<mat-expansion-panel-header>
						<mat-panel-title>
							Add agents
						</mat-panel-title>
						<mat-panel-description>
							Click to add more agents to the campaign - {{ input_data['Campaign Name'] }}
						</mat-panel-description>
					</mat-expansion-panel-header>
					<section class="add-agent-section">
						<span class="add-agent-list-section">
							<mat-checkbox class="add-agent-margin" [checked]="selected_all"
								[indeterminate]="someSelected()" (change)="setAll($event.checked)">
								Select All
							</mat-checkbox>
						</span>
						<span class="add-agent-list-section">
							<ul>
								<li *ngFor="let agent of campaign_newAgents">
									<mat-checkbox [(ngModel)]="agent.add_selected"
										(ngModelChange)="updateAllSelected()">
										{{agent.name}}
									</mat-checkbox>
								</li>
							</ul>
						</span>
					</section>
					<button *ngIf="someSelected() || selected_all" mat-stroked-button (click)="addAgents_tocampaign()">Add Agents</button>
				</mat-expansion-panel>
			</td>
		</tr>
		<tr>
			<td colspan="2">
				<mat-table [dataSource]="agentDataSource">
					<ng-container matColumnDef="sl_no">
						<mat-header-cell *matHeaderCellDef> SNo </mat-header-cell>
						<mat-cell *matCellDef="let element; let  i= index"> {{i + 1}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="name">
						<mat-header-cell *matHeaderCellDef> Agent Name </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="email">
						<mat-header-cell *matHeaderCellDef> Agent Email </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="active_status">
						<mat-header-cell *matHeaderCellDef> Agent Status </mat-header-cell>
						<mat-cell *matCellDef="let element">
							<mat-slide-toggle [(ngModel)]="element.active_status"
								[disabled]="!input_data.active_status"></mat-slide-toggle>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="data">
						<mat-header-cell *matHeaderCellDef> Agent Data </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.data}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="dialled">
						<mat-header-cell *matHeaderCellDef> Data Dialled </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.dialled}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="contacted">
						<mat-header-cell *matHeaderCellDef> Data Contacted </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.contacted}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="followup">
						<mat-header-cell *matHeaderCellDef> Followups </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.followup}} </mat-cell>
					</ng-container>
					<mat-header-row *matHeaderRowDef=agent_table_columns></mat-header-row>
					<mat-row *matRowDef="let row; columns: agent_table_columns"></mat-row>
				</mat-table>
				<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
			</td>
		</tr>
		<tr *ngIf="agentDataSource.data.length && input_data.active_status">
			<td></td>
			<td class="submit_changes">
				<button mat-stroked-button (click)="submit_agent_status()">Submit Changes</button>
			</td>
		</tr>
	</table>
</div>

<div id="cover-spin" *ngIf="currentLoader"></div>