<div>
    <div class="container pdt">
    <div class="row">
        <div class="col-md-3 col-sm-12 col-xs-12 mbtm">
    <mat-card>
    <div class="dHd dash-projects">
        PROJECTS
    </div>
    <p class="ptop">
    <span class="TR-desc pro">{{this.dashboardSummary.project_count}}</span>   
    </p>
    </mat-card>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12 mbtm">
    <mat-card>
        <div class="dHd dash-auditors">
             AUDITORS
        </div>
        <p class="ptop">
            <span class="TR-desc audtrs">{{this.dashboardSummary.auditor_count}}</span>
            </p>
    </mat-card>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12 mbtm">
            <mat-card>
        <div class="dHd dash-training">
            AUDITOR TRAINING
        </div>
        <p class="ptop">
            <span class="TR-desc tra">{{this.dashboardSummary.training_count}}</span>
            </p>
            </mat-card>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12 mbtm">
            <mat-card>
        <div class="dHd dash-audited">
             AUDITED
        </div>
        <p class="ptop">
            <span class="TR-desc audi">{{this.dashboardSummary.audited_count}}</span>
            </p>
            </mat-card>
        </div>
    </div>
    <div class="row pdt">
        <div class="col-md-12 col-sm-12 col-xs-12">
         <mat-card>
             <h2 class="d-head">AUDITOR AVAILABILITY </h2>
             <img src="../assets/underline.svg"
                class="linebelow"> 

             <div  class="searchAdjust">
                <mat-form-field style="font-size: 12px;"  [ngClass]="'inputClr'">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    <i class="fa fa-search search-icon" matSuffix></i>
                  </mat-form-field>
            </div>
            <br>
            <div class="scrollTable">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
				
				
			<ng-container matColumnDef="AuditId">
				<th mat-header-cell *matHeaderCellDef> AuditorId </th>
				<td mat-cell *matCellDef="let element"> {{element.auditId}} </td>
			</ng-container><!--auditId-->		

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name (Auditor)</th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <!-- <ng-container matColumnDef="rating">
              <th mat-header-cell *matHeaderCellDef> Rating </th>
              <td mat-cell *matCellDef="let element" style="padding-top: 10px;">
                <div id="bloc1"> {{element.rating | number:'1.1-1'}}</div>  
                <div id="bloc2"><star-rating-comp [starType]="'icon'" [size]="'medium'" [readOnly]="'true'" [rating]="element.rating==null?0:element.rating"></star-rating-comp></div>
                <div id="bloc3" >/ 100</div>
              </td>
            </ng-container> -->
            <ng-container matColumnDef="project">
                <th mat-header-cell *matHeaderCellDef>Project </th>
                <td mat-cell *matCellDef="let element">{{element.project}}
                   </td>
              </ng-container>
			
			  <ng-container matColumnDef="availability">
              <th mat-header-cell *matHeaderCellDef> Availability </th>
              <td mat-cell *matCellDef="let element" [ngStyle]="getAvailiabilityColor(element.availability)"> {{element.availability}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            </div>
            

        </mat-card>
        </div>
      
    </div>
  

    </div>
    <br>
    <br>
    </div>