import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-call-rating-seekbar',
  templateUrl: './call-rating-seekbar.component.html',
  styleUrls: ['./call-rating-seekbar.component.scss']
})
export class CallRatingSeekbarComponent implements OnInit {

    @Input()
  public removeSubSection: Function;
  @Input()
  public untitleToggle: Function;
  @Input() selectedIndex: string;
@Input() selectedChildIndex: string;
  @Input() selectedLevel: any;
  @Input() radioIndex: any;
@Input() checked: any;
  constructor() { }

  ngOnInit(): void {
    //console.log("hii")
  }
  answerType(val: any) {
    //console.log(val);
    if(val=="FT")
    {
      
    }
    else{
     
    }
  }
  actionType(val: any){
    //console.log(val);
  }
  trigerRemoveSubSection(){
    //console.log(this.selectedIndex)
    this.removeSubSection(this.selectedIndex,this.selectedChildIndex, this.selectedLevel,this.radioIndex)
  }
  triggerToggle(event){
   // console.log(this.selectedIndex)
    this.untitleToggle(this.selectedIndex,event, this.selectedLevel,this.selectedChildIndex, this.radioIndex)
  }
}
