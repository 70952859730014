
<mat-card class="pCard">
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}"> Date </th>
      <td mat-cell *matCellDef="let element"> {{element.created_at | date:'dd-MMM-yyyy'}} </td>
    </ng-container>
     <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Date</th>
    </ng-container>
    <ng-container matColumnDef="base_price_type">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Type</th>
      <td mat-cell *matCellDef="let element"> {{element.base_price_type}} </td>
    </ng-container>
    <ng-container matColumnDef="base_price_val">
      <th mat-header-cell *matHeaderCellDef> Rate </th>
      <td mat-cell *matCellDef="let element"> {{currency_code| currencySymbol}}{{element.base_price_val}} </td>
    </ng-container>
    <ng-container matColumnDef="surge_price_type">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let element"> {{element.surge_price_type}} </td>
    </ng-container>
    <ng-container matColumnDef="surge_price_threshold">
      <th mat-header-cell *matHeaderCellDef> Minimum Value</th>
      <td mat-cell *matCellDef="let element"> {{element.surge_price_threshold}} </td>
    </ng-container>
      <ng-container matColumnDef="surge_price_val">
      <th mat-header-cell *matHeaderCellDef> Rate </th>
      <td mat-cell *matCellDef="let element"> {{currency_code| currencySymbol}}{{element.surge_price_val}} </td>
    </ng-container>
  
    <ng-container matColumnDef="header-row-second-group">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">Base Price </th>
    </ng-container>
    <ng-container matColumnDef="header-row-third-group">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"> Surge Price </th>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="['Date', 'header-row-second-group','header-row-third-group']"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</mat-card>
  