//import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as Excel from 'exceljs';
import { MyserviceService } from '../../../myservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimeslotSettingComponent } from '../../timeslot-setting.component';
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
	selector: 'app-contact-rm-upload-dailog',
	templateUrl: './contact-rm-upload-dailog.component.html',
	styleUrls: ['./contact-rm-upload-dailog.component.scss']
})
export class ContactRmUploadDailogComponent implements OnInit {

	createdForm: FormGroup;
	currentFileobj: any = {};
	showHideUploadMsg: any = "";
	@ViewChild('fileUpload')
	fileUploadInput: ElementRef;
	issettingComponent: boolean = false;
	isresponse_success: boolean = false;
	myUser: any;
	fileUploadStatus: any = "";
	responseobj: any = {};
	downloadfile = "https://dev.youearn.in/api/v1/attachments/Contacts_Upload_Sample.xlsx";
	displayedColumns: string[] = ['Uploaded_Contacts', 'Updated_Contacts', 'Failed_Contacts', 'Deleted_Contacts'];
	api_end_point:any;
	constructor(public router: Router, private formbuilder: FormBuilder, private myService: MyserviceService, private API: ApiService, public dialogRef: MatDialogRef<TimeslotSettingComponent>, @Inject(MAT_DIALOG_DATA) public viewuser, private _snackBar: MatSnackBar) {
		this.createdForm = this.formbuilder.group({
			"fileSource": [''],
			"file": [''],
		})
	}
	ngOnInit(): void {
		this.myUser = this.myService.get_loggedin_user();
		this.api_end_point = this.myService.get_API_End_Point();
	}

	download_file() {

		let url = "/api/v1/attachments/Contacts_Upload_Sample.xlsx";
		console.log("https://dev.youearn.in/api/v1/attachments/Contacts_Upload_Sample.xlsx?access_token=" + this.myUser.access_token);
		//FileSaver.saveAs(url);
		this.API.downloadReportFromUrl(url, true,this.api_end_point).then(() => {
			console.log('file download completed');
		});
		/* let content_type = 'application/octet-stream';
		let file_name = url.split('/').pop()
		//let url = window.URL.createObjectURL(blob);
		var anchor = document.createElement("a");
		anchor.download = file_name;
		anchor.href = url;

		anchor.click(); */
	//	debugger;
	}

	changeFile(event) {
		this.currentFileobj = {};
		this.showHideUploadMsg = "";
		let filePath = event.target.files[0].name;
		let allowedExtensions = /(\.xlsx)$/i;
		if (!allowedExtensions.exec(filePath)) {
			//alert('Please upload file having extensions .pdf/.doc/.docx/.xls/.xlsx only.');
			
			setTimeout(() => {
				this._snackBar.open("Please upload file having extensions .xlsx only.", "close");
			}, 3000)
			return false;
		}
		// const isValid = this.validate(event.target.files[0]);
		const file = event.target.files[0]
		const reader = new FileReader()
		const workbook = new Excel.Workbook();
		var rowCount = 0;
		reader.readAsArrayBuffer(file)
		reader.onload = () => {
			const buffer: any = reader.result;
			workbook.xlsx.load(buffer).then(workbook => {
			//	console.log(workbook, 'workbook instance')

				workbook.eachSheet((sheet, id) => {

					sheet.eachRow((row, rowIndex) => {
						rowCount = rowCount + 1;
						console.log(row.values, rowIndex)
					})
				})
				if (rowCount < 2) {
					this.currentFileobj = {};
					this.showHideUploadMsg = "Data empty for uploaded file";
					this.createdForm.reset();
					this.fileUploadInput.nativeElement.value = "";
					setTimeout(() => {
						this.showHideUploadMsg = "";
					}, 3000);

				} else {
					this.currentFileobj = event.target.files[0];
					const [upload_file] = event.target.files;
					this.createdForm.patchValue({
						fileSource: upload_file
					});
				}

			})
		}

	}

	submitCandidate(createdForm: any) {
		let createexcel: any = [];
		
		if (createdForm.get('fileSource').value == "") {
			this._snackBar.open("please select Excel", "close",{
				duration: 7000
			  });
			return;
		}
		let _fileDetails = createdForm.get('fileSource').value;
		if (_fileDetails == "") {
			this._snackBar.open("please select Excel","close",{
				duration: 7000
			  });
			return;
		}

		let formData = new FormData();
		var query = ``;
		formData.append('attachment', _fileDetails, _fileDetails.name);
		this.fileUploadStatus = 'Uploading...'
		//console.log(formData);
		this.API.uploadAnyFile(formData,this.api_end_point).subscribe(
			(res: any) => {
				//console.log(res);
				this.fileUploadStatus = ""
				//  debugger;
				if (res.status == "success") {
					console.log(res.status);
					let file_link = res.url;
					this.API.uploadContacts(file_link,this.api_end_point).subscribe(
						(res: any) => {
							//console.log(res.success)
							//this._snackBar.open("success", "close");
						})
				} if (res.url) {

				//	console.log(res.url);
					//let link = {file_link:res.url+"?access_token="+this.myUser.access_token};
					let link = { file_link: res.url };
					this.API.uploadContacts(link,this.api_end_point).subscribe(
						(res: any) => {
						//	console.log(res.status)
							this.isresponse_success = true;
							
							this.responseobj.Uploaded_Contacts = res.Uploaded_Contacts.length;
							this.responseobj.Updated_Contacts = res.Updated_Contacts.length;
							this.responseobj.Failed_Contacts = res.Failed_Contacts.length;
							this.responseobj.Deleted_Contacts = res.Deleted_Contacts.length;
							//debugger;
							if (res.Uploaded_Contacts ) {
								//createexcel=res.Uploaded_Contacts;
								res.Uploaded_Contacts.forEach(d => {
									//createexcel.push(Object.values(d));
									createexcel.push(d);
								 });
							}
							if (res.Updated_Contacts ) {
								//createexcel=res.Updated_Contacts;
								res.Updated_Contacts.forEach(d => {
									createexcel.push(d);
									//createexcel.push(Object.values(d));
								 });
							}
							if (res.Failed_Contacts) {
								res.Failed_Contacts.forEach(d => {
									//createexcel.push(Object.values(d));
									createexcel.push(d);
								 });
							//	console.log(createexcel);
							}
							if (res.Deleted_Contacts) {
								res.Deleted_Contacts.forEach(d => {
									//createexcel.push(Object.values(d));
									createexcel.push(d);
								 });
							}

							let _data_mapper = {
								"Customer ID": "Customer ID",
								"Customer Name": "Customer Name",
								"Customer Contact": "Customer Contact",
								"Customer Email ID": "Customer Email ID",
								"RM Email": "RM Email",
								"RM Name": "RM Name",
								"RM Number": "RM Number",
								"Action":"Action",
								"Status":"Status"
							}
							
							//console.log(JSON.stringify(createexcel));

							let data = createexcel;
							let headers = Object.values(_data_mapper);
							if (data && data.length) {
								let workbook = new Workbook();
								let worksheet = workbook.addWorksheet('Customer Data');
								worksheet.addRow(headers);
								data.forEach(d => {
									let row = worksheet.addRow(this.extractDataObject(Object.keys(_data_mapper), d));
										console.log('printing row', row);
								})
								//debugger;
		
								workbook.xlsx.writeBuffer().then((data) => {
									let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
									fs.saveAs(blob, 'Customer_upload_details.xlsx');
								})
							}
							this._snackBar.open(res.success, "close",{
								duration: 7000
							  });
							setTimeout(() => {
								//this.showHideMessage=false;
								this.closeDialog();
								//this._snackBar.open(res.errors, "close");
								
							}, 10000)
							if (res.errors) {
								this.isresponse_success = false;
								
							}
						})
				}


			})
	}

	closeDialog() {
		this.createdForm.reset();
		this.dialogRef.close();
	}
	extractDataObject(ref, obj) {
		let arr = [];
		for (var i in ref) {
			if (obj.hasOwnProperty(ref[i])) {
				console.log(obj[ref[i]]);
				arr.push(obj[ref[i]])
			} else {
				arr.push('')
			}
		}
		return arr;
	}

}
