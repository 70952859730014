import {Injectable} from '@angular/core';
import { element } from 'protractor';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';

import { MyserviceService } from './myservice.service';

@Injectable({
	providedIn: 'root'
})

export class RtddataService{
	agentFeed = new BehaviorSubject<any>({});
	groupFeed = new BehaviorSubject<any>({});
	supervisorFeed = new BehaviorSubject({});
	zsmData = new BehaviorSubject({});
	rsmData = new BehaviorSubject({});
	asmData = new BehaviorSubject({});
	isCentral = new BehaviorSubject(false);

	constructor(private services: MyserviceService){
		
	}

	updateAgentFeed(dataArr){
		//console.log("--1-----",JSON.stringify(dataArr));
		//this.agentFeed.next(null);
		let currentAgentFeed = this.agentFeed.getValue();
		// console.log(JSON.stringify(currentAgentFeed));
		let supervisorObj = this.services.SUPERVISOROBJ.getValue();
		let group_id = this.services.get_asm_groupid();
		//console.log(JSON.stringify(currentAgentFeed));
		//console.log(JSON.stringify(supervisorObj));
		dataArr.forEach(data => {
			//console.log(JSON.stringify(data));
			if(data.hasOwnProperty(currentAgentFeed.id)){
				
				for(let key in data){
					currentAgentFeed[data.id][key] = data[key];
				}
			}else{
				//console.log('--1---',JSON.stringify(data));
				currentAgentFeed[data.id] = data;
			}
			if(supervisorObj && supervisorObj.hasOwnProperty(data.id)){
				currentAgentFeed[data.id]['tl_name'] = supervisorObj[data.id]['name'];
				currentAgentFeed[data.id]['tl_id'] = supervisorObj[data.id]['user_id'];
				currentAgentFeed[data.id]['tl_email'] = supervisorObj[data.id]['email'];
			}
		});
			this.agentFeed.next(currentAgentFeed);			

	}

	updateGroupFeed(dataArr){
		//console.log('input object group', dataArr)
		let currentGroupFeed = this.groupFeed.getValue();

		dataArr.forEach(data => {
			if(currentGroupFeed.hasOwnProperty(data.groupid)){
				for(let key in data){
					//console.log(data[key]);
					currentGroupFeed[data.groupid][key] = data[key];
				}
			}else{
				
				currentGroupFeed[data.groupid] = data;
			}
		});
		//this.groupFeed.next(null);
			this.groupFeed.next(currentGroupFeed);			
		//this.groupFeed.next(currentGroupFeed)
		//console.log('is central', this.isCentral.getValue())
		if(this.isCentral.getValue()){
			this.updateCentralhierarchyData();
		}else{
			this.updateCentralhierarchyData();
		}
	}

	async updateCentralhierarchyData(){
		const groupData = this.groupFeed.getValue();
		//console.log('groupData final', groupData);
		this.services.set_bidashboard(groupData);
		let zsm_obj = await this.groupDataByRole('zsm');
		let rsm_obj = await this.groupDataByRole('rsm');
		let asm_obj = await this.groupDataByRole('asm');
		//console.log('zsm_obj', JSON.stringify(zsm_obj));
		// console.log('rsm_obj', rsm_obj);
		// console.log('asm_obj', asm_obj);
		this.zsmData.next(zsm_obj);
		this.rsmData.next(rsm_obj);
		this.asmData.next(asm_obj);
	}

	async groupDataByRole(role: string){
		let finalObj = {};
		const relation = this.services.CENTRAL_TEAM_RELATION.getValue();
	//	console.log('relation', relation);
		let idStr;
		switch(role){
			case 'zsm':
			case 'ZSM':
				idStr = 'zsm_id';
				break;
			case 'rsm':
			case 'RSM':
				idStr = 'rsm_id';
				break;
			case 'asm':
			case 'ASM':
				idStr = 'asm_id';
				break;
		}
		let groupData = _.groupBy(relation, idStr);
		
		Object.keys(groupData).forEach(async (id) => {
			finalObj[id] = await this.sumGroupValue(groupData[id]);
		});
		//console.log('finalobj-1', JSON.stringify(finalObj));
		return finalObj;
	}

	async sumGroupValue(groupObj){
		//console.log('input groupobj', groupObj.length, groupObj);
		const groupData = this.groupFeed.getValue();
		let obj = {};
		try{
			groupObj.forEach(group => {
				let group_data = groupData[group.groupid];
				//console.log('group_data', group_data)
				// obj = {...group_data};
				//console.log('after groupdata', obj);
				let exception_keys = ['branch','groupid', 'state', 'superadmin', 'zone'];
				for(let key in group_data){
				//	console.log('key in group data', key);
					if(exception_keys.indexOf(key) < 0){
						if(!isNaN(group_data[key])){
							if(obj.hasOwnProperty(key)){
								obj[key] += group_data[key];
							}else{
								obj[key] = group_data[key]
							}
						}else{
							obj[key] = 0
						}
					}
				//	console.log('after loop', key, obj[key]);
				}
			});
		//	console.log('after addition', obj);
		}catch(e){
			console.log('error', e);
		}
		return obj;
	}
}