import { Component, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ApiService } from 'src/app/api.service';
import * as highcharts from 'highcharts';
import * as Highcharts from 'highcharts';
import {MyserviceService} from "../../myservice.service";

@Component({
  selector: 'app-contiinex-sms-dashboard',
  templateUrl: './contiinex-sms-dashboard.component.html',
  styleUrls: ['./contiinex-sms-dashboard.component.scss']
})
export class ContiinexSmsDashboardComponent implements OnInit {

 sentDeliveredChart:any;
  donutChartrecord:any;
  ChannelNameList:any=[];
  category_xaxis:any[];
  sms_total_today:number;
  customer_name:string;
  project_name:string;
  selected_time_range:string;
  sms_total_yesterday:number;
  default_sent_axis:any=[];
  default_delivered_axis:any=[];
  selectedIndex: number = 0;
  sms_today:number[] = [0,0,0,0];
  sms_yesterday:number[] = [0,0,0,0];
  api_end_point:any;
  constructor(private API:  ApiService, private _service:MyserviceService) {
    window.scrollTo(0,0);
   }

   ngOnInit() {
   // console.log('ProjectOneComponent - ngOnInit called');
    this.api_end_point = this._service.get_API_End_Point();
    this.default_sent_axis = [];
    this.default_delivered_axis = [];

    this.ChannelNameList=[{name:'All Channels'},{name:'SMS'}];

    if (this._service && this._service.get_loggedin_user() && this._service.get_loggedin_user().email) {
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      this.project_name = String(this._service.get_current_project()).toLowerCase();
    }

    this.selected_time_range = this._service.get_current_time_range();

    this.API.getSmsDashboard(this.customer_name,this.project_name,this.selected_time_range,this.api_end_point).subscribe((data: any) => {
      if(data.errors){
        console.log('getSmsDashboard failed',data.errors);
      } else {
       // console.log('getSmsDashboard success',data);

        this.category_xaxis = data.xaxis;

        if (data.counts && data.counts['today']) {
          if (data.counts['today']['SENT']) {
            this.sms_today[0] = data.counts['today']['SENT']
          }
          if (data.counts['today']['DELIVERED']) {
            this.sms_today[1] = data.counts['today']['DELIVERED']
          }
          if (data.counts['today']['READ']) {
            this.sms_today[2] = data.counts['today']['READ']
          }
          if (data.counts['today']['FAILED']) {
            this.sms_today[3] = data.counts['today']['FAILED']
          }
        }

        if (data.counts && data.counts['yesterday']) {
          if (data.counts['yesterday']['SENT']) {
            this.sms_yesterday[0] = data.counts['yesterday']['SENT']
          }
          if (data.counts['yesterday']['DELIVERED']) {
            this.sms_yesterday[1] = data.counts['yesterday']['DELIVERED']
          }
          if (data.counts['yesterday']['READ']) {
            this.sms_yesterday[2] = data.counts['yesterday']['READ']
          }
          if (data.counts['yesterday']['FAILED']) {
            this.sms_yesterday[3] = data.counts['yesterday']['FAILED']
          }
        }

        for (var i in this.category_xaxis) {
          this.default_sent_axis[i] = 0;
          this.default_delivered_axis[i] = 0;
        }

        for (var i in data.sent_axis) {
          this.default_sent_axis[this.category_xaxis.indexOf(i)] = data.sent_axis[i];
        }

        for (var i in data.delivered_axis) {
          this.default_delivered_axis[this.category_xaxis.indexOf(i)] = data.delivered_axis[i];
        }

        this.sms_total_today = this.sms_today.reduce((a, b) => a+b, 0);
        this.sms_total_yesterday = this.sms_yesterday.reduce((a, b) => a+b, 0);

       // console.log(this.default_sent_axis,this.default_delivered_axis);
      }

      // this.ChannelNameList=[{name:'WhatsApp'},{name:'Outbound IVR'},{name:'SMS'},{name:'Inbound Call'},{name:'Outbound Call'},{name:'Inbound Email'},{name:'Outbound Email'}];
      this.getSentVsDelivered();
      this.donutChart();
    });

  }
  getSentVsDelivered(){

    this.sentDeliveredChart = new Chart({
      chart: {
        type: 'line',
        height: 300
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },

      exporting: {
        enabled: false
      },
      xAxis: {
        categories: this.category_xaxis,
        gridLineWidth: 1,
       minorGridLineWidth: 1
      },
      yAxis: {
        title:null,
        gridLineWidth: 1,
        minorGridLineWidth: 1,

      },
      legend: {
        reversed: false,
        verticalAlign: "top",
        align: "right",
        borderWidth: 1,
      },
      plotOptions: {
        line: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },

      },
      series: [

        {
          type:undefined,
          name: "Sent",
          data: this.default_sent_axis,
          color: "#f1a627"
        },
        {
          type:undefined,
          name: "Delivered",
          data: this.default_delivered_axis,
          color: "#ed3a31"
        }
      ]
    });


}
donutChart(){
  let chartdata = new Chart({

      title: {
        text: '<strong>'+this.sms_today[0]+'</strong><br>Sent',
        align: 'center',
        verticalAlign: 'middle',
        y: 18,

      },

    credits: {
      enabled: false
    },

    exporting: {
      enabled: false
    },
    plotOptions: {
      pie: {

        cursor: 'pointer',
        dataLabels: {
          distance:-30,
          enabled: false
        },
        showInLegend: false,

      }
    },

    series: [
      {


        states: {
        inactive: {
          opacity: 1
        },
        hover: {
        enabled: false
        }
        },
        name: 'Active',
        data: [
          {
            borderColor:'gray',
            name: 'Yesterday',
            y: this.sms_total_yesterday,
            color:'white'//'#e6e6e6'
          },
          {
            borderColor:'black',
            name: 'Today',
            y: this.sms_total_today,
            color:'#eeeeee'//'black'
          },],
        type: 'pie',
        innerSize: '80%',
      }]
  });
  this.donutChartrecord=chartdata;


}
}
