import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, Validators, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import * as Excel from 'exceljs';
import * as XLSX from 'xlsx';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contiinex-create-campaign-page',
  templateUrl: './contiinex-create-campaign-page.component.html',
  styleUrls: ['./contiinex-create-campaign-page.component.scss']
})
export class ContiinexCreateCampaignPageComponent implements OnInit {
  showLoader = false;
  currentFile: any;
  currentFileobj: any = {};
  current_time = moment().format('HH:mm');


  myUser: any;

  campaignForm: FormGroup;
  customer_name: any;
  businessType: any;
  _msgTemplateData: any = [];
  _templateNameListDDl: any = [];
  ResData: any = [];
  _bodyViewText: any = "";
  isShowBodyTempl: boolean = false;
  isShowEyeOn: boolean = false;
  isShowEyeOff: boolean = false;
  stringTemplate2: any = "";
  templateWithLanguageValue: any = "";

  yourBearerToken: any = "";
  bearer_token: any = "";
  wa_number: any = "";
  _documentExcelUrl: any = "";
  allComponnetList: any = [];
  _agentID: any = "";

  _footerData: any = [];
  isShowOnTemplate: boolean = false;
  _headerDataText: any = "";
  headerChangeTextWithBraces: any = "";

  _imageFormatText: any = "";
  _videoFormatText: any = "";
  _documentFormatText: any = "";

  btnArrayList: any = [];
  filteredList3:any=[];

  enterUrlImageModel:any="";
  modelGroupImageRadio:any="selectImageUploadRadio";
  modelGroupDocumentRadio:any="selectDocumentUploadRadio";
  modelGroupVideoRadio:any="selectVideoUploadRadio";

  imageLinkDisabled1:boolean=false;
  imageLinkDisabled2:boolean=true;
  videoLinkDisabled1:boolean=false;
  videoLinkDisabled2:boolean=true;
  documentLinkDisabled1:boolean=false;
  documentLinkDisabled2:boolean=true;

  selectRadioValModel:any="now";
  isShowForDatePick:boolean=false;
  scheduledDate:any="";

   _today :any=  new Date();
  minDateAllow:any =this._today;//  new Date(this._today.setDate(this._today.getDate() + 1));

  api_end_point:any;
	currentTitle = 'contiinex-campaign';
  currentUrl:any;
  constructor(
    private API: ApiService,
    public dialogRef: MatDialogRef<ContiinexCreateCampaignPageComponent>,
    private service: MyserviceService,
    private formbuilder: FormBuilder, private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.api_end_point = this.service.get_API_End_Point();
    this.campaignForm = this.formbuilder.group({
      "campaignName": ['', Validators.required],
      "templateName": ['', Validators.required],
      "enterUrlImageModel":[''],
      "enterUrlDocumentModel":[''],
      "enterUrlVideoModel":[''],
      "file": ['', Validators.required],
      "dateSet":[''],
      "timeSet":['']
    })
    this.myUser = this.service.get_loggedin_user();
    if (this.service.get_loggedin_user() && this.service.get_loggedin_user().company) {
      this.customer_name = String(this.service.get_loggedin_user().company).toLowerCase();
      this.businessType = String(this.service.get_loggedin_user().businessType);
    }
    if (this.service.get_loggedin_user()) {
      this.bearer_token = String(this.service.get_loggedin_user().bearer_token);
      this.yourBearerToken = "Bearer " + this.bearer_token;
      this.wa_number = String(this.service.get_loggedin_user().phone); //You should trim the '+' sign before using
      this._agentID = this.service.get_loggedin_user().agentid;
    }
    this.getMsgTemplateData();
  }

  getMsgTemplateData() {
    this.API.getWabaTemplates(this.customer_name,this.api_end_point).subscribe((res: any) => {
      if (res && res.data) {
        this.ResData = res.data.filter((v) => { return v.status == "APPROVED"; })
        // this.ResData = res.data;
        this._msgTemplateData = [];
        for (let i in res.data) {
          var record = {};
          record['name'] = res.data[i].name;
          record['language'] = res.data[i].language;
          this._msgTemplateData.push(record['name'] + '-' + record['language']);
        }
      } else {
        this._msgTemplateData = []
      }
      if (this._msgTemplateData.length > 0) {
        this._templateNameListDDl = this._msgTemplateData;
        this.filteredList3 = this._templateNameListDDl.slice();


      }
    });
    document.getElementById('resultdivTemplate1').style.display = "none";
    document.getElementById('resultdivTemplate2').style.display = "none";
  }


  changeTemplateDDL(val: any) {
    this.templateWithLanguageValue = val;
 
    this.isShowEyeOn = true;
    this.isShowEyeOff = false;
    let _filterBodyTempalte = [];
    let _bodyTemplte = [];
    let _footerData1 = [];
    this._footerData = [];

    let _buttonsData1 = [];
    this.isShowOnTemplate = false;

    this.headerChangeTextWithBraces = "";

    let _headerData1 = [];
    this.isShowHeaderEyeOn = false;
    this.isShowHeaderEyeOff = false;

    this._imageFormatText = "";
    this._videoFormatText = "";
    this._documentFormatText = "";

    this.hdrVideoUrl = "";
    this.hdrVideoUrl = "";
    this._documentUrl = "";

    this.btnArrayList = [];
    if (val == "" || val == undefined) {
      this.isShowBodyTempl = false;
      this.isShowEyeOn = false;
      this.isShowEyeOff = false;
      document.getElementById('resultdivTemplate1').style.display = "none";
      document.getElementById('resultdivTemplate2').style.display = "none";
    }
    else {

      let _splitData = val.split('-');
      let _templateName = _splitData[0];
      let _lang = _splitData[1];
      _filterBodyTempalte = this.ResData.filter((v) => {
        return ((v.name == _templateName) && (v.language == _lang))
      })

      this.allComponnetList = _filterBodyTempalte[0].components;

      if (_filterBodyTempalte[0].components) {
        this.isShowOnTemplate = true;

        _headerData1 = this.allComponnetList.filter((v) => { return v.type == "HEADER" });

        if (_headerData1.length > 0 && _headerData1[0].format == "TEXT" && _headerData1[0].example && _headerData1[0].example.header_text.length > 0) {
          this._headerDataText = "";
          let _headerDataTextWithBraces = "";
          this._headerDataText = _headerData1[0].text;
          _headerDataTextWithBraces = _headerData1[0].example.header_text[0];
          this.headerChangeTextWithBraces = this._headerDataText.replace("{{1}}", _headerDataTextWithBraces);
          this.isShowHeaderEyeOn = true;
          this.isShowHeaderEyeOff = false;
        }
        if (_headerData1.length > 0 && _headerData1[0].format == "TEXT" && !_headerData1[0].example) {
          this._headerDataText = "";
          this._headerDataText = _headerData1[0].text;
          this.headerChangeTextWithBraces = this._headerDataText;
          this.isShowHeaderEyeOn = true;
          this.isShowHeaderEyeOff = false;
        }
        if (_headerData1.length > 0 && _headerData1[0].format == "IMAGE" && _headerData1[0].example && _headerData1[0].example.header_handle.length > 0) {
          let _headerDataImage = _headerData1[0].example.header_handle[0];
          this._imageFormatText = _headerData1[0].format;
        }
        if (_headerData1.length > 0 && _headerData1[0].format == "VIDEO" && _headerData1[0].example && _headerData1[0].example.header_handle.length > 0) {
          let _headerDataVideo = _headerData1[0].example.header_handle[0];
          this._videoFormatText = _headerData1[0].format;
        }
        if (_headerData1.length > 0 && _headerData1[0].format == "DOCUMENT") {
          let _headerDataDocumnet = _headerData1[0].example.header_handle[0];
          this._documentFormatText = _headerData1[0].format;
        }
        _bodyTemplte = _filterBodyTempalte[0].components.filter((v) => {
          return v.type == "BODY"
        })

        _footerData1 = this.allComponnetList.filter((v) => { return v.type == "FOOTER" });
        if (_footerData1.length > 0) {
          this._footerData = _footerData1[0].text;
        }

        //start button code
        _buttonsData1 = this.allComponnetList.filter((v) => { return v.type == "BUTTONS" });
        this.btnArrayList = [];
        if (_buttonsData1[0] && _buttonsData1[0].type == "BUTTONS" && _buttonsData1[0].buttons.length > 0) {
          for (let i = 0; i < _buttonsData1[0].buttons.length; i++) {
            if (_buttonsData1[0].buttons[i].type == "QUICK_REPLY") {
              this.btnArrayList.push(_buttonsData1[0].buttons[i].text)
            }
            if (_buttonsData1[0].buttons[i].type == "PHONE_NUMBER") {
              this.btnArrayList.push(_buttonsData1[0].buttons[i].text)
            }
            if (_buttonsData1[0].buttons[i].type == "URL") {
              this.btnArrayList.push(_buttonsData1[0].buttons[i].text)
            }
          }
          // console.log( this.btnArrayList);
        }
        //end button code

      }
      if (_bodyTemplte) {
        this._bodyViewText = _bodyTemplte[0].text.replace(new RegExp("\n", "g"), "<br>");
        this.stringTemplate2 = _bodyTemplte[0].text.replace(new RegExp("\n", "g"), "<br>");
        this.isShowBodyTempl = true;

        let _bodyExampleHandle = [];
        if (_bodyTemplte[0].type == 'BODY' && _bodyTemplte[0].text != '' && _bodyTemplte[0].example != '') {
          if (_bodyTemplte[0].example) {
            _bodyExampleHandle = _bodyTemplte[0].example.body_text[0];
            if (_bodyExampleHandle.length > 0) {
              for (let i = 0; i < _bodyExampleHandle.length; i++) {
                this.stringTemplate2 = (this.stringTemplate2).replace(`{{${i + 1}}}`, ' ' + _bodyExampleHandle[i] + ' ');
              }
            }
          }
        }
        document.getElementById('resultdivTemplate1').style.display = "block";
        document.getElementById('resultdivTemplate2').style.display = "none";
        (document.getElementById('resultdivTemplate1') as HTMLInputElement).innerHTML = this._bodyViewText;
      }
    }
  }


  submit_Camapign(campaignForm: FormGroup) {
    this.scheduledDate="";
   let _dateSet= this.campaignForm.controls.dateSet.value;
    let _timeSet=this.campaignForm.controls.timeSet.value;  
    let _split1 = this.templateWithLanguageValue.split('-');
    let agentId = this._agentID;
   // debugger;
    if (this._imageFormatText == 'IMAGE') {
      if(this.modelGroupImageRadio=="selectImageUploadRadio"){
        if (this.hdrImageUrl == "") {
          this._snackBar.open("Please select image in template preview.", 'close', {
            duration: 5000,
          });
          return;
        }
      }
      else{  
        let _enterUrlImageModel=this.campaignForm.controls.enterUrlImageModel.value;
       
        if(/^https?:\/\/.+\.(jpg|jpeg|png)$/.test(_enterUrlImageModel)==false)
     
        {
          this._snackBar.open("Please enter valid image url http/https and .png/.jpg/.jpeg format.", 'close', {
            duration: 5000,
          });
          return;
        }
        

        if (this.hdrImageUrl == "" && _enterUrlImageModel=="") {
          this._snackBar.open("Please enter image url in template preview.", 'close', {
            duration: 5000,
          });
          return;
        }
        else{
          this.hdrImageUrl="";
          this.hdrImageUrl=_enterUrlImageModel;
        }
      }
   

    }
   
    if (this._videoFormatText == 'VIDEO') {     

      if(this.modelGroupVideoRadio=="selectVideoUploadRadio"){
        if (this.hdrVideoUrl == "") {
          this._snackBar.open("Please select video in template preview.", 'close', {
            duration: 5000,
          });
          return;
        }
      }
      else{  
        let _enterUrlVideoModel=this.campaignForm.controls.enterUrlVideoModel.value; 
        if(/^https?:\/\/.+\.(mp4)$/.test(_enterUrlVideoModel)==false)
        {
          this._snackBar.open("Please enter valid video url http/https and  .mp4 format.", 'close', {
            duration: 5000,
          });
          return;
        }    
        if (this.hdrVideoUrl == "" && _enterUrlVideoModel=="") {
          this._snackBar.open("Please enter video url in template preview.", 'close', {
            duration: 5000,
          });
          return;
        }
        else{
          this.hdrVideoUrl="";
          this.hdrVideoUrl=_enterUrlVideoModel;
        }
      }



    }
    if (this._documentFormatText == 'DOCUMENT') {
  

      if(this.modelGroupDocumentRadio=="selectDocumentUploadRadio"){
        if (this._documentUrl == "") {
          this._snackBar.open("Please select document in template preview.", 'close', {
            duration: 5000,
          });
          return;
        }
      }
      else{  
        let _enterUrlDocumentModel=this.campaignForm.controls.enterUrlDocumentModel.value;   
        if(/^https?:\/\/.+\.(pdf)$/.test(_enterUrlDocumentModel)==false)
        {
          this._snackBar.open("Please enter valid document url http/https and  .pdf format.", 'close', {
            duration: 5000,
          });
          return;
        } 
        if (this._documentUrl == "" && _enterUrlDocumentModel=="") {
          this._snackBar.open("Please enter document url in template preview.", 'close', {
            duration: 5000,
          });
          return;
        }
        else{
          this._documentUrl="";
          this._documentUrl=_enterUrlDocumentModel;
        }
      }

    }
  
if(this.selectRadioValModel == "pickTheDate"){
if(_dateSet=="" || _timeSet==""){
  this._snackBar.open("Please Select Both Date and Time.", 'close', {
    duration: 5000,
  });
  return;
}
else{ 
  let _checkDAteTime=""; 
  let _time1 = ("0"+(_timeSet.getUTCHours())).slice(-2) + ":" + ("0"+(_timeSet.getUTCMinutes())).slice(-2) +":"+("0" + _timeSet.getUTCSeconds()).slice(-2);
     _checkDAteTime=moment(_dateSet).format('YYYY-MM-DD') +" "+ _time1;    
     this.scheduledDate=_checkDAteTime;//moment(_dateSet).format('YYYY-MM-DD') +" "+ moment(_timeSet).format('hh:mm:ss')  ;
}
}

    let _modelObj = {};
    let mediaUrl = "";
    if (this.hdrImageUrl != "") {
      mediaUrl = this.hdrImageUrl;
    }
    if (this.hdrVideoUrl != "") {
      mediaUrl = this.hdrVideoUrl;
    }
    if (this._documentUrl != "") {
      mediaUrl = this._documentUrl;
    }

    if (this._imageFormatText == 'IMAGE' || this._videoFormatText == 'VIDEO') {
      if(this.selectRadioValModel == "pickTheDate"){
        _modelObj =
        {
          "wa_number": (this.wa_number).replace('+', ''),
          "campaign_name": campaignForm.controls.campaignName.value,
          "template_name": _split1[0],
          "language_code": _split1[1],
          "file_link": this._documentExcelUrl,
          "media_url": mediaUrl,
          "scheduled_at":this.scheduledDate
        }
      }
      else{
      _modelObj =
      {
        "wa_number": (this.wa_number).replace('+', ''),
        "campaign_name": campaignForm.controls.campaignName.value,
        "template_name": _split1[0],
        "language_code": _split1[1],
        "file_link": this._documentExcelUrl,
        "media_url": mediaUrl
      }
    }
    }
    else if (this._documentFormatText == 'DOCUMENT') {
      if(this.selectRadioValModel == "pickTheDate"){
        _modelObj =
        {
          "wa_number": (this.wa_number).replace('+', ''),
          "campaign_name": campaignForm.controls.campaignName.value,
          "template_name": _split1[0],
          "language_code": _split1[1],
          "file_link": this._documentExcelUrl,
          "media_url": mediaUrl,
          "document_type": 'PDF',
          "filename":this.filenameDocumentContainer,
          "scheduled_at":this.scheduledDate
  
        }
      }
      else{
      _modelObj =
      {
        "wa_number": (this.wa_number).replace('+', ''),
        "campaign_name": campaignForm.controls.campaignName.value,
        "template_name": _split1[0],
        "language_code": _split1[1],
        "file_link": this._documentExcelUrl,
        "media_url": mediaUrl,
        "document_type": 'PDF',
        "filename":this.filenameDocumentContainer

      }
    }
    }
    else {
      if(this.selectRadioValModel == "pickTheDate"){
        _modelObj =
        {
          "wa_number": (this.wa_number).replace('+', ''),
          "campaign_name": campaignForm.controls.campaignName.value,
          "template_name": _split1[0],
          "language_code": _split1[1],
          "file_link": this._documentExcelUrl,
          "scheduled_at":this.scheduledDate
        }
      }
      else{
      _modelObj =
      {
        "wa_number": (this.wa_number).replace('+', ''),
        "campaign_name": campaignForm.controls.campaignName.value,
        "template_name": _split1[0],
        "language_code": _split1[1],
        "file_link": this._documentExcelUrl
      }
    }
    }

    this.API.postCampaignCreateDetails(_modelObj, this.yourBearerToken, agentId,this.api_end_point).subscribe((response: any) => {

      if (response.status == "failure") {
        this._snackBar.open(response.message, 'close', {
          duration: 5000,
        });
      }
      else if (response.status == "success") {
        this.campaignForm.reset();
        this.dialogRef.close('true');
  
        this._snackBar.open(response.message, 'close', {
          duration: 5000,
        });
      }
    });
  }



  changeFile(event) {
    this.currentFileobj = {};
    let fileInput = (<HTMLInputElement>document.getElementById("testfile1")).value;
    var idxDot = fileInput.lastIndexOf(".") + 1;
    var extFile = fileInput.substring(idxDot, fileInput.length).toLowerCase();
    if (extFile == "xlsx" || extFile == "xls") {
      if (event.target.files && event.target.files.length) {
        this.currentFileobj = event.target.files[0];
      }
      let filenameDocumentContainer = fileInput.split('\\').pop();
      let reader;
      let input = event.target;
      if (input.files && input.files[0]) {
        reader = new FileReader();
        reader.onload = function (e) { }
        reader.readAsDataURL(input.files[0]);
      }
      let formData = new FormData();
      formData.append('attachment', event.target.files[0], event.target.files[0].name);
      this.API.campaignCreateUploadDocumentExcel(formData,this.api_end_point).subscribe(
        (res: any) => {
          this._documentExcelUrl = res.url;
        }, (err) => {
          console.log(err)
        }
      )
    }
    else {
      this._snackBar.open("Only excel files are allowed!", 'close', {
        duration: 5000,
      });
      return;
    }
  }

  closePopup() {
    this.campaignForm.reset();
    this.dialogRef.close();
  }
  omit_special_char(event) {
    let k;
    k = event.charCode;  // k = event.keyCode; (Both can be used)
    if (k == 95) {
      return true;
    }
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  hidePreviousDates = (d: Date | null): boolean => {
    const day = (d || new Date());
    return moment(day).isSameOrAfter(new Date(), 'day');
  }

  download_file() {
    let _exData = this.build_whatsapp_campaign_sample_excel_data(this.allComponnetList);
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(_exData);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(workBook, 'SampleExcelTemplate.xlsx');
  }

  previewTemplateClick(val: any) {
    if (val == 'on') {
      this.isShowEyeOn = false;
      this.isShowEyeOff = true;
      document.getElementById('resultdivTemplate1').style.display = "none";
      document.getElementById('resultdivTemplate2').style.display = "block";
      (document.getElementById('resultdivTemplate2') as HTMLInputElement).innerHTML = this.stringTemplate2;
    }
    else {
      this.isShowEyeOn = true;
      this.isShowEyeOff = false;
      document.getElementById('resultdivTemplate1').style.display = "block";
      document.getElementById('resultdivTemplate2').style.display = "none";
      (document.getElementById('resultdivTemplate2') as HTMLInputElement).innerHTML = this._bodyViewText;
    }
  }

  build_whatsapp_campaign_sample_excel_data(template_data) {
    if (!template_data || !Array.isArray(template_data) || !template_data.length) {
      return null;
    }
    let final_response = [
      {
        "Phone Number": "91xxxxxxxxxx"
      }
    ];
    for (let component of template_data) {
      if (component.type == "HEADER" && component.format == "TEXT") {
        if (component.text && component.text.length && component.text.indexOf("{{1}}") != -1) {
          final_response[0]["Header Text"] = "xxxxxxxxxxxxxxx";
        }
      }
      if (component.type == "BODY") {
        if (component.text && component.text.length && component.text.indexOf("{{1}}") != -1) {
          let i = 1;
          while (component.text.indexOf("{{" + i + "}}") != -1) {
            final_response[0]["Variable " + i] = "xxxxxxxxxxxxxxx";
            i++;
          }
        }
      }
      if (component.type == "BUTTONS") {
        if (component.buttons && Array.isArray(component.buttons) && component.buttons.length) {
          for (let button of component.buttons) {
            if (button.type == "URL" && button.url.indexOf("{{1}}") != -1) {
              final_response[0]["Button URL Param"] = "xxxxxxxxxxxxxxx";
            }
          }
        }
      }
    }
    return final_response;
  }
  _headerOnOff: any = "on";
  isShowHeaderEyeOn: boolean = false;
  isShowHeaderEyeOff: boolean = false;

  headerVisibiltyClick(val) {

    this._headerOnOff = val;
    this.isShowHeaderEyeOn = false;
    this.isShowHeaderEyeOff = false;
    if (val == "on") {
      this.isShowHeaderEyeOff = true;
    }
    else if (val == "off") {
      this.isShowHeaderEyeOn = true;
    }
  }

  fileImageNameContainer: any = "";
  hdrImageUrl: any = "";
  getImageFile(event: any, _files: FileList) {
    let fileInput = (<HTMLInputElement>document.getElementById("testfileImg")).value;
    var idxDot = fileInput.lastIndexOf(".") + 1;
    var extFile = fileInput.substring(idxDot, fileInput.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {

      this.fileImageNameContainer = fileInput.split('\\').pop();
      let reader;
      let input = event.target;
      if (input.files && input.files[0]) {
        reader = new FileReader();
        reader.onload = function (e) {
          // (<HTMLInputElement>document.getElementById("previewIMg")).setAttribute('src', e.target.result);
        }
        reader.readAsDataURL(input.files[0]);
        this.hdrImageUrl = "";
      }

      let formData = new FormData();
      formData.append('image', event.target.files[0], event.target.files[0].name);
    
      this.API.createMsgTemplateUploadImage(formData,this.api_end_point).subscribe(
        (res: any) => {
          if (res.errors) {
            this._snackBar.open(res.errors, 'close', {
              duration: 5000,
            });
          }
          else {
            this.hdrImageUrl = res.url;
              this.modelGroupImageRadio="selectImageUploadRadio";
          }
        }, (err) => {
          console.log(err)
        }
      )
    }
    else {
   
      this._snackBar.open("Only jpg/jpeg and png files are allowed!", 'close', {
        duration: 5000,
      });
     
      this.fileImageNameContainer="";
      (<HTMLInputElement>document.getElementById("testfileImg")).value="";
      setTimeout(()=>{
        this.modelGroupImageRadio="selectImageUploadRadio";
      },100);
      return;
    }

  }

  hdrVideoUrl: any = "";
  filenameContainerVideo: any = "";
  getVideoFile(event: any, _files: FileList) {

    let fileInput = (<HTMLInputElement>document.getElementById("testfile2")).value;
    var idxDot = fileInput.lastIndexOf(".") + 1;
    var extFile = fileInput.substring(idxDot, fileInput.length).toLowerCase();
    if (extFile == "mp4") {
      this.filenameContainerVideo = fileInput.split('\\').pop();
      let reader;
      let input = event.target;
      if (input.files && input.files[0]) {
        reader = new FileReader();
        reader.onload = function (e) {
          // (document.getElementById("vidSelectedId") as HTMLIFrameElement).src = URL.createObjectURL(event.target.files[0]);
        }
        reader.readAsDataURL(input.files[0]);
        this.hdrVideoUrl = "";
      }
      let formData = new FormData();
      formData.append('image', event.target.files[0], event.target.files[0].name);

      this.API.createMsgTemplateUploadVideo(formData,this.api_end_point).subscribe(
        (res: any) => {
          if (res.errors) {
            this._snackBar.open(res.errors, 'close', {
              duration: 5000,
            });
          }
          else {
            this.hdrVideoUrl = res.url;
            this.modelGroupVideoRadio="selectVideoUploadRadio";

          }

        }, (err) => {
          console.log(err)
        }
      )
    }
    else {
      this._snackBar.open("Only mp4 files are allowed!", 'close', {
        duration: 5000,
      });
      this.filenameContainerVideo="";
      (<HTMLInputElement>document.getElementById("testfile2")).value="";
      setTimeout(()=>{
        this.modelGroupVideoRadio="selectVideoUploadRadio";
      },100);
      return;
    }
  }
  filenameDocumentContainer: any = "";
  _documentUrl: any = "";
  getDocumentFile(event: any) {
    let fileInput = (<HTMLInputElement>document.getElementById("testfile3")).value;
    var idxDot = fileInput.lastIndexOf(".") + 1;
    var extFile = fileInput.substring(idxDot, fileInput.length).toLowerCase();
    if (extFile == "pdf") {//|| extFile=="docx" || extFile=="doc"
      this.filenameDocumentContainer = fileInput.split('\\').pop();
      let reader;
      let input = event.target;
      if (input.files && input.files[0]) {
        reader = new FileReader();
        reader.onload = function (e) {
          // (<HTMLInputElement>document.getElementById("video1")).setAttribute('src', e.target.result);
        }
        reader.readAsDataURL(input.files[0]);
      }
      let formData = new FormData();
      formData.append('image', event.target.files[0], event.target.files[0].name);
      this.API.createMsgTemplateUploadDocument(formData,this.api_end_point).subscribe(
        (res: any) => {
          this._documentUrl = res.url;
          this.modelGroupDocumentRadio="selectDocumentUploadRadio";
        }, (err) => {
          console.log(err)
        }
      )
    }
    else {
      this._snackBar.open("Only pdf files are allowed!", 'close', {
        duration: 5000,
      });
      this.filenameDocumentContainer="";
      (<HTMLInputElement>document.getElementById("testfile3")).value="";
      setTimeout(()=>{
        this.modelGroupDocumentRadio="selectDocumentUploadRadio";
      },100);
      return;
    }
  }

 
  radioChangeImage(val:any){
    this.modelGroupImageRadio=val;
    if(val=="selectImageUploadRadio"){
      this.imageLinkDisabled1=false;
      this.imageLinkDisabled2=true;
      this.campaignForm.controls.enterUrlImageModel.setValue('');
    }
    else if(val=="enterImageUrlRadio"){
      this.imageLinkDisabled1=true;
      this.imageLinkDisabled2=false;
      this.fileImageNameContainer="";
      (<HTMLInputElement>document.getElementById("testfileImg")).value="";
     
    }

  }

  radioChangeDocument(val:any){
    this.modelGroupDocumentRadio=val;
    if(val=="selectDocumentUploadRadio"){
      this.documentLinkDisabled1=false;
      this.documentLinkDisabled2=true;
      this.campaignForm.controls.enterUrlDocumentModel.setValue('');
    }
    else if(val=="enterDocumentUrlRadio"){
      this.documentLinkDisabled1=true;
      this.documentLinkDisabled2=false;
      this.filenameDocumentContainer="";
      (<HTMLInputElement>document.getElementById("testfile3")).value="";
     
    }
  }

  radioChangeVideo(val:any){
    this.modelGroupVideoRadio=val;
    if(val=="selectVideoUploadRadio"){
      this.videoLinkDisabled1=false;
      this.videoLinkDisabled2=true;
      this.campaignForm.controls.enterUrlVideoModel.setValue('');
    }
    else if(val=="enterVideoUrlRadio"){
      this.videoLinkDisabled1=true;
      this.videoLinkDisabled2=false;
      this.filenameContainerVideo="";
      (<HTMLInputElement>document.getElementById("testfile2")).value="";
     
    }
  }
  radioChangeDate(val:any){
    this.selectRadioValModel=val;
    if(val=="now"){
this.isShowForDatePick=false;
this.campaignForm.controls.dateSet.setValue('');
this.campaignForm.controls.timeSet.setValue('');
    }
    else{
      this.isShowForDatePick=true;
    }

  }
}
