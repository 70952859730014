<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">
     <mat-card>
         <h2 class="d-head">PROJECTS<img src="../assets/underline.svg"
            class="linebelow"> </h2>

<div class="searchFix">
    <mat-form-field style="font-size: 12px;">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH">
        <i class="fa fa-search search-icon" matSuffix style="position: relative;
        top: -2px;"></i>
      </mat-form-field>
     
</div>
<br>
<div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

<ng-container matColumnDef="project_name">
  <th mat-header-cell *matHeaderCellDef>Project </th>
  <td mat-cell *matCellDef="let element">{{element.project_name}} </td>
</ng-container>

<ng-container matColumnDef="status">
  <th mat-header-cell *matHeaderCellDef> Status </th>
  <td mat-cell *matCellDef="let element"> {{element.status}} </td>
</ng-container>

<ng-container matColumnDef="cx_touch_point">
  <th mat-header-cell *matHeaderCellDef> CX-Type </th>
  <td mat-cell *matCellDef="let element" > {{element.cx_touch_point}} </td>
</ng-container>

<ng-container matColumnDef="created_at">
  <th mat-header-cell *matHeaderCellDef> Start At </th>
  <td mat-cell *matCellDef="let element" > {{element.created_at | date:'dd/MM/yyyy'}} </td>
</ng-container>

<ng-container matColumnDef="end_at">
    <th mat-header-cell *matHeaderCellDef> End At </th>
    <td mat-cell *matCellDef="let element"> {{element.end_at | date:'dd/MM/yyyy'}} </td>
  </ng-container>

<ng-container matColumnDef="pushType">
    <th mat-header-cell *matHeaderCellDef> Push Type </th>
    <td mat-cell *matCellDef="let element"> {{element.pushType}} </td>
  </ng-container>
  
<ng-container matColumnDef="total_calls">
    <th mat-header-cell *matHeaderCellDef> Data</th>
    <td mat-cell *matCellDef="let element"> {{element.total_calls}} </td>
  </ng-container>
  
<ng-container matColumnDef="audited_calls">
    <th mat-header-cell *matHeaderCellDef> Audited </th>
    <td mat-cell *matCellDef="let element"> {{element.audited_calls}} </td>
  </ng-container>

  <ng-container matColumnDef="auditors">
    <th mat-header-cell *matHeaderCellDef> Auditors </th>
    <td mat-cell *matCellDef="let element"> 
      <span class="detailsCss"  (click)="viewAuditors(element)">View Details</span>
        <!-- <mat-icon class="CR" aria-hidden="false" aria-label="" (click)="viewAuditors(element)">visibility</mat-icon> -->
      
     </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> 
          <mat-icon class="CR AMclr"  aria-hidden="false" aria-label="" (click)="editAuditors(element)">edit</mat-icon> </td>
  </ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
</table>
<mat-paginator [ngClass]="'auditorPage'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
            
</mat-card>
</div>
</div>

