import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { ApiService } from '../../api.service';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as Excel from 'exceljs';
import { MyserviceService } from '../../myservice.service';

export interface PeriodicElement {
  name: string;
  phoneno: number;
  email: string;
}

@Component({
  selector: 'app-batch-create-upload-dialog',
  templateUrl: './batch-create-upload-dialog.component.html',
  styleUrls: ['./batch-create-upload-dialog.component.scss']
})
export class BatchCreateUploadDialogComponent implements OnInit {

  displayedColumns: string[] = ['name', 'email', 'phone'];
  dataSource: any = new MatTableDataSource();
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  myUser: any;
  fileUploadStatus:any="";
  createdForm:FormGroup;
  currentFileobj: any = {};
  showduplicatedata: boolean = false;
  isRecruiterComponent: boolean = false;
  showHideUploadMsg: any = "";
  clientConfig: any;
  @ViewChild('fileUpload')
  fileUploadInput: ElementRef;
  api_end_point:any;
  showHideErrorFile:boolean=false;

  constructor(public router:Router,private formbuilder:FormBuilder,private API: ApiService,private dialog: MatDialog,public dialogRef: MatDialogRef<BatchCreateUploadDialogComponent>,private service: MyserviceService,) {
    this.createdForm = this.formbuilder.group({
      "fileSource": [''],
      "file": [''],
      })
   }

  ngOnInit(): void {
	this.api_end_point = this.service.get_API_End_Point();
    this.myUser = this.service.get_loggedin_user();
    if(window.location.pathname.includes('recruiter')){
      this.isRecruiterComponent = true;
      this.API.getClientConfigByRecruitment(this.myUser.groupid,this.api_end_point).subscribe((data: any) => {
        if(data.errors){
          return;
        }
        if(data.success){
          this.clientConfig = data.success;
          // this.selectedClientId = {
          //   rec_id:data.success.recruitment_groupid, group_id: data.success.groupid,trainer_id: data.success.trainer_groupid, name:data.success.name
          // }
        }
      })
    }
    
  }

  changeFile(event) {
		this.currentFileobj = {};
    this.showHideUploadMsg="";
		let filePath = event.target.files[0].name;
		let allowedExtensions = /(\.xlsx)$/i;
		if(!allowedExtensions.exec(filePath)){
			//alert('Please upload file having extensions .pdf/.doc/.docx/.xls/.xlsx only.');
			this.showHideUploadMsg="Please upload file having extensions .xlsx only.";
      setTimeout(()=>{
        this.showHideUploadMsg = "";
      },3000)
			return false;
		}
    // const isValid = this.validate(event.target.files[0]);
    const file = event.target.files[0]
    const reader = new FileReader()
    const workbook = new Excel.Workbook();
    var rowCount = 0;
    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      const buffer: any = reader.result;
      workbook.xlsx.load(buffer).then(workbook => {
       // console.log(workbook, 'workbook instance')

        workbook.eachSheet((sheet, id) => {
          
          sheet.eachRow((row, rowIndex) => {
            rowCount = rowCount + 1;
           // console.log(row.values, rowIndex)
          })
        })
        if(rowCount < 2){
          this.currentFileobj = {};
          this.showHideUploadMsg="Data empty for uploaded file";
          this.createdForm.reset();
          this.fileUploadInput.nativeElement.value = "";
          setTimeout(()=>{
            this.showHideUploadMsg = "";
          },3000);
          
        }else{
          this.currentFileobj = event.target.files[0];
          const [upload_file] = event.target.files;
          this.createdForm.patchValue({
            fileSource: upload_file
          });
        }
        
      })
    }
    
  }

  async validate(file){
    
  
  }

  

  submitCandidate(createdForm:any){
    this.showHideErrorFile=false;
    let _fileDetails = createdForm.get('fileSource').value;
    if(_fileDetails=="")
    {
      this.showHideErrorFile=true;
      setTimeout(()=>{
        this.showHideErrorFile=false;
      },8000)
      return;
    }
  
    let formData = new FormData();
    var query = ``;
    formData.append('leads', _fileDetails, _fileDetails.name);
    this.fileUploadStatus = 'Uploading...'
  
    if(this.isRecruiterComponent){
      query = `?production_group=${this.myUser.groupid}&company_name=${this.myUser.company}&prodcution_group_id=${this.clientConfig.recruitment_groupid}`
      this.API.recruitmentNewCandidateUpload(formData, query, "",this.api_end_point).subscribe(
        (res:any) => {
          this.fileUploadStatus = ""
         // debugger;
          if(res.success){
            
            //console.log(JSON.stringify(res.success));
            if(res.success.data && res.success.data.length){
              this.fileUploadStatus = 'Candidates uploaded successfully';
            }
            
         //   console.log("---2-"+JSON.stringify(res.success.error));
            //this.duplicatetable = res.success.error;
            if(res.success.error && res.success.error.length){
              this.showduplicatedata=true;
              this.dataSource =new MatTableDataSource(res.success.error);
            }else{
               setTimeout(()=>{
                this.fileUploadStatus = '';
                this.closeDialog(true);
              },2000)
            }
            
           // this.fetchAgents();
           
          }
          
         
          
        }, (err) => {
          console.log(err)
        })
    }else{
      this.API.trainingNewCandidateUpload(formData, query, "",this.api_end_point).subscribe(
        (res:any) => {
          this.fileUploadStatus = ""
        //  debugger;
          if(res.success){
            
            //console.log(JSON.stringify(res.success));
            if(res.success.data && res.success.data.length){
              this.fileUploadStatus = 'Candidates uploaded successfully';
            }
            
           // console.log("---2-"+JSON.stringify(res.success.error));
            //this.duplicatetable = res.success.error;
            if(res.success.error && res.success.error.length){
              this.showduplicatedata=true;
              this.dataSource =new MatTableDataSource(res.success.error);
            }else{
               setTimeout(()=>{
                this.fileUploadStatus = '';
                this.closeDialog(true);
              },2000)
            }
            
           // this.fetchAgents();
           
          }
          
         
          
        }, (err) => {
          console.log(err)
        })
    }
    
    }
    closeDialog(refresh: boolean = false) {
      this.dialogRef.close(refresh);
    }
    download_file(){
      this.API.getTrainingAddCandidatesTemplate(this.api_end_point).subscribe(
        (res:HttpResponse<any>) => {
          let content_type = res.headers.get("content-type");
          let file_data = res.headers.get("content-disposition");
          let p = file_data.split(';')[1];
          let file_name = p.slice(p.indexOf('"') + 1, p.length - 1);
          let body = res.body;
          let blob: any = new Blob([body], { type: content_type });
          let url = window.URL.createObjectURL(blob);
          var anchor = document.createElement("a");
          anchor.download = new Date().getTime()+'_'+file_name;
          anchor.href = url;
          // debugger;
          anchor.click();
        });
    }
  
}
