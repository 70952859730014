<app-new-contiinex-menu-page></app-new-contiinex-menu-page>
<div class="v-mainDivCO">
    <div>
        <div class="row animation-One2x vk-sec">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <p class="text-center mb-4">
                    <!-- <img src="../assets/contiinex/image/subProductComEng.png" class="comEngLogo" /> -->
                    <img src="../assets/contiinex/image/ComnixLogo.png" class="v-LblHd1-Img" />
                    <span class="v-LblHd1 pl-1"> Unified Communication as a Service </span>
                </p>
                <h1 class="v-textH text-center v-BannerTitle">
                    <!-- Proactive -->
                     Customer engagement through <br> Conversational AI over WhatsApp Bot, IVR & Dialler </h1>
                <h3 class="v-txt1 mb-2">Conversational AI with WhatsApp Bot, IVR & Dialler all working individually yet
                    together!</h3>
                <p>
                    <span class="v-book-demo" title="BOOK A DEMO" (click)="bookDemoBtn()">BOOK A DEMO</span>
                </p>
            </div>
        </div>

        <div class="row bTp1">
            <div class="col-md-12 col-sm-12 col-xs-12 v-bannerDivY"
                style="background-image: url('../assets/contiinex/image/comEngBannerNewImg1.png');">
                <div class="bannerCE1">
                    <div>
                        <p class="pt-3"><img src="../assets/contiinex/image/doneCheckIcon.png" style="width:40px;" />
                        </p>
                        <span>Ready To Use<br> Process Flows</span>
                    </div>
                    <div>
                        <p class="pt-3"><img src="../assets/contiinex/image/doneCheckIcon.png" style="width:40px;" />
                        </p>
                        <span>Omni <br> channel</span>
                    </div>
                    <div>
                        <p class="pt-3"><img src="../assets/contiinex/image/doneCheckIcon.png" style="width:40px;" />
                        </p>
                        <span> Build your flow in <br> less than a minute </span>
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12 hxOne">
                <h1 class="v-textH text-center">Engage With Your Audience Through <br>
                    Any Of Your Social Media Assets</h1>
                <p class="text-center mt-3 mb-2">
                    <img src="../assets/contiinex/image/boyGirlImg.png" class="hxImgOne" />
                </p>
                <p> <span class="vkX-desc" style="line-height: 1.67;letter-spacing: 0.45px;text-align: center;">
                        Take your Website a step ahead with an ability to establish dialogues with your website visitors
                        or engage with your audience
                        through any of your social media assets.
                        An ability to seamlessly connect with your audience at the right time through the
                        right channel drives real-time engagement and enhances lead creation opportunities.</span></p>
            </div>


            <div class="col-md-12 col-sm-12 col-xs-12 mb-3 mt-4">
                <div class="mt-0 vOneBaner">
                    <div class="cSec3"> <img src="../assets/contiinex/image/pathArrowDown.png" class="pathDown" /></div>
                    <div class="cee-Sec-div">
                        <img src="../assets/contiinex/image/robotSleep.png" style="width: 100%;" />
                    </div>
                    <div class="cee-Sec-div-Two mb-4">
                        <table class="text-left ceeTable3">
                            <tr>
                                <td>
                                    <h1 class="v-textH" style="color:#ff6c00;padding:0 23px">Fuelled With Conversational
                                        <br> AI Bots To Engage </h1>
                                </td>
                            </tr>
                            <tr>
                                <td><span class="vkX-desc" style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;">
                                        Assist your audience anytime autonomously through a widget integrated with smart
                                        dialler, get a perfect blend of automation and workforce to ensure a continuum
                                        at every stage of your customer’s journey.
                                    </span></td>
                            </tr>
                        </table>
                    </div>
                    <div class="cSec3"> </div>
                </div>
            </div>


            <div class="col-md-12 col-sm-12 col-xs-12 mt-4 ">
                <p class="text-center mt-4"><img src="../assets/contiinex/image/wpPowerlogoImg.png"
                        style="width:40px" /></p>
                <h1 class="v-textH text-center v-BannerTitle"> Powered by WhatsApp’s Reach and ease! </h1>
                <p> <span class="vkX-desc" style="line-height: 1.67;letter-spacing: 0.45px;text-align: center;">
                        Set up your bot within minutes, choose from Pre-built templates along with a range of CRM, LMS,
                        App integration options. Run campaigns with minimum supervision that is advanced to improve
                        real-time engagements.</span></p>
            </div>



            <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
                <div class="row vs1">
                    <div class="col-md col-sm-1 col-xs-12"></div>
                    <div class="col-md-4 col-sm-5 col-xs-12">
                        <img src="../assets/contiinex/image/waveIvImg.png" style="width: 100%;" />
                    </div>
                    <div class="col-md-5 col-sm-5 col-xs-12 vs2">
                        <h1 class="v-textH">Future Proof With Visual IVR’s </h1>
                        <p class="vkX-desc" style="line-height: 1.67;letter-spacing: 0.45px;padding: 0;margin: 10px 0;">
                            Web Widget with Automated workflows can contextualize and transition the Voice IVR. This
                            combination has unleashed its power to renovate the rapidly changing customer experience
                            industry.<br><br>

                            Enable support capabilities to your mobile app that extend far beyond what is available
                            today rounding out the self-service story of the app
                        </p>
                    </div>
                    <div class="col-md col-sm-1 col-xs-12"></div>
                </div>
            </div>



            <div class="col-md-12 col-sm-12 col-xs-12 mb-3" style="background:#fafafa">
                <div class="row vs3">
                    <div class="col-md col-sm-1 col-xs-12"></div>
                    <div class="col-md-4 col-sm-5 col-xs-12 forMobileMenuOX">
                        <img src="../assets/contiinex/image/smartDialerImg1.png" style="width: 100%;" />
                    </div>
                    <div class="col-md-5 col-sm-5 col-xs-12">
                        <h1 class="v-textH">Smart Dialer </h1>
                        <p class="vkX-desc"
                            style="line-height: 1.5;letter-spacing: 0.45px;padding: 10px 5px;;margin: 4px 0;">
                            Future Proof With Visual IVR’s Fuelled With Conversational AI Bots To Engage Assist your
                            audience anytime autonomously through a widget integrated with smart dialler, get a perfect
                            blend of automation and workforce to ensure a continuum at every stage of your customer’s
                            journey. Web Widget with Automated workflows can contextualize and transition the Voice IVR.
                            This combination has unleashed its power to renovate the rapidly changing customer
                            experience industry. Enable support capabilities to your mobile app that extend far beyond
                            what is available today rounding out the self-service story of the app VISUAL I V R ’ s
                            Contiinex Smart dialler can help your business engage with customers whether for inbound
                            service calls or outbound sales calls. With its easy integrations, it can allow processes to
                            seamlessly transition from Automation to workforce at any given time.
                        </p>
                    </div>
                    <div class="col-md-4 col-sm-5 col-xs-12 forWebMenuOX">
                        <img src="../assets/contiinex/image/smartDialerImg1.png" style="width: 100%;" />
                    </div>

                    <div class="col-md col-sm-1 col-xs-12"></div>
                </div>
                <div class="row vs4">
                    <div class="col-md col-sm-1 col-xs-12"></div>
                    <div class="col-md-4 col-sm-5 col-xs-12">
                        <img src="../assets/contiinex/image/smartDialerImg2.png" style="width: 100%;" />
                    </div>
                    <div class="col-md-5 col-sm-5 col-xs-12">

                        <p class="mb-1" style="line-height: 1.67;letter-spacing: 0.45px;">
                            <span class="vytitle">Cloud-based, Desktop & Mobile ready</span>
                        </p>
                        <p class="vkX-desc"
                            style="line-height: 1.5;letter-spacing: 0.45px;padding: 2px 2px;margin: 4px 0;">
                            Smart Dialler is completely cloud-based and is both desktop and mobile-ready to enable easy
                            transition of a hybrid agent profile whether to work from office or home. It complies to all
                            regulatory requirements as per the law of the land and can be easily plugged in a contact
                            center environment for both inbound and outbound processes.
                        </p>
                        <p class="mb-1 mt-4" style="line-height: 1.67;letter-spacing: 0.45px;">
                            <span class="vytitle">Automatic Call Distribution</span>
                        </p>
                        <p class="vkX-desc"
                            style="line-height: 1.5;letter-spacing: 0.45px;padding: 2px 2px;margin: 4px 0;">
                            For Inbound, the dialler comes with an inbuilt Call distributor with high level of logic for
                            call distribution with easy integration with an IVR system.
                        </p>
                        <p class="mb-1 mt-4" style="line-height: 1.67;letter-spacing: 0.45px;">
                            <span class="vytitle">Predictive Dialer </span>
                        </p>
                        <p class="vkX-desc"
                            style="line-height: 1.5;letter-spacing: 0.45px;padding: 2px 2px;margin: 4px 0;">
                            For outbound it is enabled with a predictive dialler that reduces the agent wait time
                            considerably.
                            The higher version of Contiinex Smart Dialler comes with an inbuilt agent assist that can
                            real time assist an agent on best answers to give to their customers while being on call.
                        </p>
                    </div>
                    <div class="col-md col-sm-1 col-xs-12"></div>
                </div>
                <br>
            </div>




            <div class="col-md-12 col-sm-12 col-xs-12 mt-4 ">
                <h1 class="v-textH text-center v-BannerTitle mt-4">
                    Benifits
                </h1>
                <div style="display:flex;flex-wrap: wrap;justify-content: center;min-height: 440px;">
                    <div>
                        <div class="benifit_circle1">
                            <span>
                                <mat-icon class="benefitIconX1">check</mat-icon>
                            </span><br> Purely Opex based with 0 Capex investment. Pay as you go basis per seat per
                            month.
                        </div>
                    </div>
                    <div>
                        <div class="benifit_circle2">
                            <span>
                                <mat-icon class="benefitIconX2">check</mat-icon>
                            </span> <br>
                            Easy to integrate with automation tools like WhatsApp/Chat bot or a Visual IVR to create a
                            continuum of a process from automation to workforce. This ensures there is no breakage of
                            customer engagement.
                        </div>
                    </div>
                    <div>
                        <div class="benifit_circle3">
                            <span>
                                <mat-icon class="benefitIconX3">check</mat-icon>
                            </span> <br>
                            With inhouse agent assist, it can enable your agent to be highly productive with lowest
                            training time, leading to high customer satisfaction.
                        </div>
                    </div>

                </div>
            </div>

            <div class="row vs5">
                <div class="col-md-2 col-sm-12 col-xs-12"></div>
                <div class="col-md-5 col-sm-12 col-xs-12 forMobileMenuOX">
                    <img src="../assets/contiinex/image/benefitBnrImg.png" style="width: 100%;" />
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12">

                    <table class="benefitTable">
                        <tr>
                            <td><img src="../assets/contiinex/image/v-benefit-logo-1.png" class="bxImgWd" /> </td>
                            <td><span class="bfitText">Sell to your interested customer </span></td>
                        </tr>
                        <tr>
                            <td><br></td>
                        </tr>
                        <tr>
                            <td><img src="../assets/contiinex/image/v-benefit-logo-2.png" class="bxImgWd" /> </td>
                            <td> <span class="bfitText">Get your customers interested & buy </span></td>
                        </tr>
                        <tr>
                            <td><br></td>
                        </tr>
                        <tr>
                            <td><img src="../assets/contiinex/image/v-benefit-logo-3.png" class="bxImgWd" /> </td>
                            <td> <span class="bfitText"> Send Reminders, set proactive prompts</span></td>
                        </tr>
                        <tr>
                            <td><br></td>
                        </tr>
                        <tr>
                            <td><img src="../assets/contiinex/image/v-benefit-logo-4.png" class="bxImgWd" /> </td>
                            <td> <span class="bfitText">Make payments, drive collections </span></td>
                        </tr>
                        <tr>
                            <td><br></td>
                        </tr>
                        <tr>
                            <td><img src="../assets/contiinex/image/v-benefit-logo-5.png" class="bxImgWd" /> </td>
                            <td> <span class="bfitText"> Enable customers with self-serve 24/7</span></td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-5 col-sm-12 col-xs-12 forWebMenuOX">
                    <img src="../assets/contiinex/image/benefitBnrImg.png" style="width: 100%;" />
                </div>

                <div class="col-md col-sm-12 col-xs-12"></div>
            </div>


            <div class="col-md-12 col-sm-12 col-xs-12 mb-4">
                <h1 class="v-textH text-center v-BannerTitle">
                    Impact
                </h1>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12  ">
                <div class="row vs6">
                    <div class="col-md-2 col-sm-12 col-xs-12"></div>
                    <div class="col-md-4 col-sm-12 col-xs-12">
                        <img src="../assets/contiinex/image/benefitBnrImg2.png" style="width: 100%;" />
                    </div>
                    <div class="col-md col-sm-12 col-xs-12">

                        <table class="benefitTable">
                            <tr>
                                <td><img src="../assets/contiinex/image/v-benefit-logo-6.png" class="bxImgWd" /> </td>
                                <td><span class="bfitText">Reduce Cost of operations by 40% </span></td>
                            </tr>
                            <tr>
                                <td><br></td>
                            </tr>
                            <tr>
                                <td><img src="../assets/contiinex/image/v-benefit-logo-7.png" class="bxImgWd" /> </td>
                                <td> <span class="bfitText">Reduce RTO’s & Failed Deliveries 20% </span></td>
                            </tr>
                            <tr>
                                <td><br></td>
                            </tr>
                            <tr>
                                <td><img src="../assets/contiinex/image/v-benefit-logo-8.png" class="bxImgWd" /> </td>
                                <td> <span class="bfitText">Increase Sales by 30% and upsell revenue by 22%</span></td>
                            </tr>
                            <tr>
                                <td><br></td>
                            </tr>
                            <tr>
                                <td><img src="../assets/contiinex/image/v-benefit-logo-9.png" class="bxImgWd" /> </td>
                                <td> <span class="bfitText">Reduce the number of steps a customer takes to reach you and
                                        number of minutes they spend to get what they need </span></td>
                            </tr>
                            <tr>
                                <td><br></td>
                            </tr>
                            <tr>
                                <td><img src="../assets/contiinex/image/v-benefit-logo-10.png" class="bxImgWd" /> </td>
                                <td> <span class="bfitText">Increase in customer value at every stage of the customer
                                        journey by being available for your customer 24/7</span></td>
                            </tr>
                        </table>
                    </div>


                    <div class="col-md-2 col-sm-12 col-xs-12"></div>
                </div>
                <br> <br>

            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 mt-4 mb-4" style="border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;padding: 60px 0;box-shadow: 0px 10px 21px 0 #f0f0f0;">
                <h1 class="v-textH text-center v-BannerTitle mt-4">
                    Features
                </h1>
                <div class="row vs7">
                    <div class="col-md col-sm-1 col-xs-12"></div>
                    <div class="col-md-4 col-sm-5 col-xs-12">
                        <img src="../assets/contiinex/image/featureImg.png" class="vs7Img" />
                    </div>
                    <div class="col-md-5 col-sm-5 col-xs-12">

                        <p class="mb-1" style="line-height: 1.67;letter-spacing: 0.45px;">
                            <span class="vytitle" style="color:#0c68a2">Simplified Omnichannel Orchestration</span>
                        </p>
                        <p class="vkX-desc mb-4"
                            style="line-height: 1.5;letter-spacing: 0.45px;padding: 2px 2px;margin: 4px 0;">
                            All the communication channel in a single unified continuum. Email, WhatsApp, SMS, IVR Bot
                            and Dialer.
                        </p>
                        <br>
                        <p class="mb-1 mt-4" style="line-height: 1.67;letter-spacing: 0.45px;">
                            <span class="vytitle" style="color:#0c68a2">Seamless Process Flow Builder:</span>
                        </p>
                        <p class="vkX-desc mb-4"
                            style="line-height: 1.5;letter-spacing: 0.45px;padding: 2px 2px;margin: 4px 0;">
                            Plug & play with easy process flow plugins available that are tried and tested by industry
                            experts or simply
                            build your own flow in less than a minute. Available across multiple industries & varied use
                            cases.
                        </p>
                        <br>
                        <p class="mb-1 mt-4" style="line-height: 1.67;letter-spacing: 0.45px;">
                            <span class="vytitle" style="color:#0c68a2">Integrations </span>
                        </p>
                        <p class="vkX-desc"
                            style="line-height: 1.5;letter-spacing: 0.45px;padding: 2px 2px;margin: 4px 0;">
                            Choose & leverage from already available, wide range of 3rd party tools and platforms such
                            as
                            CRM’s, LMS, Process Flows or integrate with your native systems to deliver seamless user
                            experience.
                        </p>
                    </div>
                    <div class="col-md col-sm-1 col-xs-12"></div>
                </div>
            </div>



            <!--start-->
            <div class="col-md-12 col-sm-12 col-xs-12  ">

                <br>
                <p class="v-hd2 mt-4 vk-our-client" style="font-weight: bold;">Our Clients</p>
                <div class="v-box">
                    <div><img src="../assets/contiinex/image/v-sky.png" style="width: 125px;" /></div>
                    <div><img src="../assets/contiinex/image/v-conneqt.png" style="width: 100px;" /></div>
                    <div><img src="../assets/contiinex/image/v-shopify.png" style="width: 125px;" /></div>
                    <div><img src="../assets/contiinex/image/v-rabbit.png" style="width: 75px;" /></div>
                    <div><img src="../assets/contiinex/image/v-cliq.png"
                            style="width: 112px; height: 38px;position: relative;top: 20px;" /></div>

                    <div>
						<img src="https://dev.contiinex.com/assets/contiinex/image/eshipz.png" style="width: 125px;" />
					
					</div>
                    <div><img src="../assets/contiinex/image/kimball.png"
                            style="width: 100%; height: 25px;position: relative;top: 30px;" /></div>
                    <div><img src="../assets/contiinex/image/experian.png"
                            style="width: 122px;height: 50px;position: relative;top: 15px;" /></div>
                    <div><img src="../assets/contiinex/image/clickpost.png" style="width: 125px;" /></div>
                    <div><img src="../assets/contiinex/image/shiprocket.png" style="width: 125px;" /></div>

                    <div><img src="../assets/contiinex/image/unicom.png"
                            style="width: 70px;height: 64px;position: relative;top: 5px;" /></div>
                    <div><img src="../assets/contiinex/image/magento.png"
                            style="width: 110px;height: 30px;position: relative;top: 22px;" /></div>
                    <div><img src="../assets/contiinex/image/3iInfo.png" class="i3InfotekImg" /></div>
                    <!--<div><img src="../assets/image/arise.png" style="width: 102px;position: relative;height: 37px;top: 15px;" /></div>-->
                    <div><img src="../assets/contiinex/image/teamLease.png" style="width: 85px;" /></div>

                    <!-- <div><img src="../assets/image/exl.png" style="width: 95px;height: 60px" /></div> -->
                    <div><img src="../assets/contiinex/image/ethinos.png"
                            style="width: 130px;height: 61px;position: relative;top: 3px;" /></div>
                    <!-- <div><img src="../assets/image/aha.png" style="width: 75px;height: 40px;position: relative;top: 15px;" /></div> -->
                    <div><img src="../assets/contiinex/image/homeocare.png"
                            style="width: auto;height: 65px;position: relative;top: 10px;" /></div>
                    <div><img src="../assets/contiinex/image/motilal.png" style="width: 75px;" /></div>
                    <div><img src="../assets/contiinex/image/bnp.png" style="width: 110px;" /></div>

                </div>
                <br>
                <p class="v-hd2 mb-0" style="font-weight: bold;margin-top: 100px;">Integrations are easy with Contiinex
                </p>
                <h3 class="v-txt2x mb-0 pb-0">
                    Empower your businesses to seamlessly integrate their native systems or support tools with leading
                    digital
                    platforms. </h3>
                <h3 class="v-txt2x"> Contiinex believes in synergy and helps businesses deliver unified and improved
                    customer experience
                    with integrations and automation.
                </h3>

                <div class="row v-mar">
                    <div class="col-md-4 text-center" style="background: #fafafa;">
                        <p class="v-p1 pt-4">CMS’S & LMS </p>
                        <p class="pt-4"><img class="" src="../assets/contiinex/image/v-first.png"
                                style="width: 100%;" /></p>
                    </div>
                    <div class="col-md-4 text-center" style="background: #f5f5f5;">
                        <p class="v-p1  pt-4">E-Commerce </p>
                        <p class="pt-4"><img class="" src="../assets/contiinex/image/v-second.png"
                                style="width: 100%;" /></p>
                    </div>
                    <div class="col-md-4 text-center" style="background: #fafafa;">
                        <p class="v-p1 pt-4">Logistics </p>
                        <p class="pt-4"><img class="" src="../assets/contiinex/image/v-third.png"
                                style="width: 100%;" /></p>
                    </div>
                </div>

            </div>

            <!--end-->
        </div>

        <div class="row pt-4">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <app-new-contiinex-footer-section-page></app-new-contiinex-footer-section-page>
            </div>
        </div>
    </div>
</div>