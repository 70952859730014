import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-contiinex-profile-preview-dialog',
  templateUrl: './contiinex-profile-preview-dialog.component.html',
  styleUrls: ['./contiinex-profile-preview-dialog.component.scss']
})
export class ContiinexProfilePreviewDialogComponent implements OnInit {
  previewData:any;
  constructor(public dialogRef: MatDialogRef<ContiinexProfilePreviewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public viewData) { }

  ngOnInit() {
    //debugger;
    this.previewData=this.viewData.value;
   // console.log(this.previewData)
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
