import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  TemplateRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EndSessionDialogComponent } from '../desktop-calling/end-session-dialog/end-session-dialog.component';
import { AudioDialogComponent } from '../desktop-calling/audio-dialog/audio-dialog.component';
import { AudioFollowupDialogComponent } from '../desktop-calling/audio-followup-dialog/audio-followup-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationReadComponent } from '../desktop-calling/notification-read/notification-read.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { MyserviceService } from 'src/app/myservice.service';
import { Router } from '@angular/router';
import { Utils } from '../utils';
import { SIPConnection } from './SIPConnection';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import * as io from 'socket.io-client';
import { ContactListCampaignContactDialogPageComponent } from '../desktop-calling/contact-list-campaign-contact-dialog-page/contact-list-campaign-contact-dialog-page.component';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
@Component({
  selector: 'app-desktop-calling-new-page',
  templateUrl: './desktop-calling-new-page.component.html',
  styleUrls: ['./desktop-calling-new-page.component.scss'],
})
export class DesktopCallingNewPageComponent implements OnInit {
  private unsubscriber: Subject<void> = new Subject<void>();

  selctedDateDDl: any = 'Today';
  displayedColumnsLeadContacted: string[] = [
    'name',
    'leadSource',
    'status',
    'dateTime',
  ];
  displayedColumnsLeadFulFilled: string[] = [
    'name',
    'lead_source',
    'status',
    'dateandtime',
    'filledform',
    'call',
  ];
  displayedColumnsFollowUp: string[] = [
    'name',
    'leadSource',
    'status',
    'dateTime',
    'recording',
    'call',
  ];
  displayedColumnsFilledForms: string[] = [
    'name',
    'leadSource',
    'dateTime',
    'comment',
  ];

  dataSourceLeadContacted = new MatTableDataSource();
  dataSourceFollowUpToDo = new MatTableDataSource();
  dataSourceFilledForms = new MatTableDataSource();

  @ViewChild('paginatorLeadContacted', { static: true })
  paginatorLeadContacted: MatPaginator;
  @ViewChild('paginatorFollowUp', { static: true })
  paginatorFollowUp: MatPaginator;
  @ViewChild('paginatorFilledForms', { static: true })
  paginatorFilledForms: MatPaginator;
  @ViewChild('paginatorFulfillment', { static: true })
  paginatorFulfillment: MatPaginator;

  _LeadsContactedData: any = [];
  _FollowUpToDoData: any = [];
  _FilledFormsData: any = [];

  showHideStart: boolean = true;
  showHideStop: boolean = false;
  _flagStartStop: any = 'StopFlag';
  showHidePopup: boolean = false;
  showHidePopup1: boolean = false;
  // showHideConnectingPage:boolean=false;
  // forwardDisabled:boolean=false;
  // mergeDisabled:boolean=false;
  // _afterConnectingVariable:any="";
  // showHideDuringDiv:boolean=false;
  // showHideDuringSuccessDiv:boolean=false;
  // showHideAfterCallFormPage:boolean=false;
  // SelectTimeModel:any="SelectTime";
  // typeTab = 'callback1';
  // timeBtnValue: any = '';
  // showHideForFollowUPAfterCall:boolean=true;
  current_url: string = null;
  LeadsSelected: boolean = true;
  FollowupSelected: boolean = false;
  FilledFormsSelected: boolean = false;
  filledjsonObject: any = [];
  showhideQuestionAnwer: boolean = false;
  notificationcount: any = 0;
  notifications: any;
  unreadnotification: any;
  breakoption: any = 'breakoption';
  countoffilleddata: any;
  fromDate: any;
  toDate: any;
  todayDate: Date = new Date();
  selectedFilter = 'TODAY';
  selectedTab = 0;
  leadFilter = 'TODAY';
  mergecalldetails: any;
  selectdate: boolean = true;
  leadsdatefrom: any;
  leadsdateto: any;
  showDuringFormError: boolean = false;
  showHideDailpad: boolean = false;
  showHideStartPage: boolean = true;
  showHideCallingScreen: boolean = false;
  showHideCallStatus: boolean = false;
  showHideCalldialling: boolean = false;
  showHideCallEndingScreen: boolean = false;
  showHideStopPage: boolean = false;
  numInput = '0';
  dialogRef: MatDialogRef<any>;
  server = 'wss://edge.sip.onsip.com';
  options: any;
  $player: HTMLAudioElement;
  transportOptions: any;
  showHideCallPad: boolean = false;
  showHideStartCall: boolean = false;
  showHideStartCallButton: boolean = true;
  showHideConnecting: boolean = false;
  showHide_Connected: boolean = false;
  subscription: Subscription;
  callStatus: any;
  callSession: any;
  allStatus: string[] = ['establishing', 'accepted'];
  callFlowJsonSections: any[] = [];
  user: any = this.myservice.get_loggedin_user();
  sessionStarted: boolean = false;
  currentFileobj: any = {};
  sioPushUrl: any = '';
  questionnaire: any = '';
  socketId: any = '';
  socketStatusBeforeCheck: any = '';
  socket: any = '';
  customerInfo: any = '';
  widgetUser: any = '';
  subscriptionDial: Subscription;
  _countLeadLength: any;
  _countfollowUpLength: any;
  _countfollowUpLengthForPD: any;
  _auditScore: any;

  _nextBooked: any;
  _availableSlots: any;

  counter1: number;
  timerRef;
  timer: any;
  running: boolean = false;
  salesStatusTrigger: boolean = false;
  startText = 'Start';

  dateModel: any;
  timeModel: any;
  callbacktime: any;
  disposition: any;
  salesSts: any;
  selectedRnrReason: any = '';
  rnroptions: any[] = ['Non Contactable'];
  rnrDescription: any;
  sipUser: any;
  sipConnection: any;
  @ViewChild('stream') set playerRef(ref: ElementRef<HTMLAudioElement>) {
    if (ref) {
      this.$player = ref.nativeElement;
    }
  }
  _caller_id: any = '';
  c2cEndPoint: any = '';
  myUser: any;
  isAppAgent: boolean = false;
  call_button_status = -2;
  call_button_status_socket: boolean = false;
  inbound_button_status: boolean = false;

  showHide_Form_before_DuringForm: boolean = true;
  showHide_Form_After_DuringForm: boolean = false;
  typeTab = 'update';
  showHideUpdate: boolean = false;
  showHideCallBack1: boolean = false;
  showHideCallBack2: boolean = false;
  showHideCallRNR: boolean = false;
  timeBtnValue: any = '';
  timeBtnValueTransformed: any = '';
  customernumber: any;
  customerlead_source: any;
  customerstatus: any;
  _talktime: any;
  _logintime: any;
  _breaktime: any;
  _waittime: any;
  _activetime: any;
  _totalcalls: any;
  _contactedData: any;
  displayedColumns = [
    'name',
    'lead_source',
    'status',
    'dateandtime',
    'recording',
    'filledform',
    'call',
  ];
  displayedColumns1 = ['name', 'lead_source', 'dateandtime', 'filledform'];
  displayedColumns2 = [
    'name',
    'lead_source',
    'status',
    'dateandtime',
    'recording',
    'call',
  ];
  dataSource: any = new MatTableDataSource();
  followupSource: any = new MatTableDataSource();
  filledSource: any = new MatTableDataSource();
  leadFulfillmentSource: any = new MatTableDataSource();
  customer_name_entered: string = '';

  firstcallstage: any;
  secondcallstage: any;

  _findteleco: any;
  _contactsAPIResponseStorage: any;
  @ViewChild('TableOnePaginator') tableOnePaginator: MatPaginator;
  @ViewChild('TableTwoPaginator') tableTwoPaginator: MatPaginator;
  @ViewChild('TableThreePaginator') tableThreePaginator: MatPaginator;
  @ViewChild('TableFourPaginator') tableFourPaginator: MatPaginator;

  @ViewChild('filterLeadName') _filterLeadName: ElementRef;
  @ViewChild('filterFollowUpName') _filterFollowUpName: ElementRef;
  @ViewChild('filterFilledName') _filterFilledName: ElementRef;
  @ViewChild('filterFulfilledName') _filterFulfilledName: ElementRef;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.tableOnePaginator;
    this.followupSource.paginator = this.tableThreePaginator;
    this.filledSource.paginator = this.tableTwoPaginator;
    this.leadFulfillmentSource.paginator = this.tableFourPaginator;
  }
  api_end_point: any;
  isStageDial: boolean = false;
  stopDialogCounter: any = 1;
  isTruepredicitive: boolean = false;
  isStayAtHome: boolean = false;

  keypressed: any;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.keypressed = event.keyCode;
    if (this.keypressed == 116 || (event.ctrlKey && this.keypressed == 116)) {
      this._snackBar.open('Press F5 for Refresh, Not Allowed', 'close', {
        duration: 3000,
        verticalPosition: 'top',
      });
      event.preventDefault();
    }
    if (event.ctrlKey && this.keypressed == 82) {
      this._snackBar.open('Press Ctrl + R for Refresh, Not Allowed', 'close', {
        duration: 3000,
        verticalPosition: 'top',
      });
      event.preventDefault();
      event.stopPropagation();
    }
  }
  isShowDateForFollowUp: boolean = false;
  followChooseSelectedDateValue: any;
  _setDialPad: any;
  isCheckProjectTypeSBICC: boolean = true;
  _projectTypeSBICC: any;
  showHideDiallerPage: boolean = false;

  setPredictiveOnOff: any = [];
  minDate1 = new Date();
  showHideStopPageForAllowinBound: boolean = false;
  constructor(
    private _snackBar: MatSnackBar,
    private myservice: MyserviceService,
    private myservicesocket: MyserviceService,
    private API: ApiService,
    private utils: Utils,
    private router: Router,
    private dialog: MatDialog
  ) {
    if (sessionStorage.getItem('sessionStarted') == 'true') {
      // console.log("---1---");
      // this.showHideStopPage = true;
      // this.showHideStartPage = false;
      this.sessionStarted = true;
      this.showHideStartPage = true;
      //this.showHideStartCallButton = true;

      this.showHideStopPage = false;
      this.showHideDailpad = false;
      this.showHideCallStatus = false;
      this.showHideCalldialling = false;
      this.showHideCallEndingScreen = false;
      this.showHideCallPad = false;
      this.showHideDiallerPage = false;
      this.check_dialler_setting();
    }
    if (sessionStorage.getItem('sessionStarted') == 'false') {
      //this.showHideStopPage = false;
      localStorage.setItem('sessionStarted', 'false');
      sessionStorage.setItem('sessionStarted', 'false');
      this.sessionStarted = false;
      this._flagStartStop = 'StartFlag';
      this.showHideStartPage = true;
      this.showHideDiallerPage = false;
      this.check_dialler_setting();
      console.log('--run this script--2');
    }
    this.callStatus = this.myservice.getLastCallStatus();
    if (this.callStatus) {
      //  console.log("homelast12345", this.callStatus);
      if (this.callStatus.customerInfo) {
        this.customerInfo = this.callStatus.customerInfo;
      }
      if (this.callStatus.callFlowJsonSections) {
        this.callFlowJsonSections = this.callStatus.callFlowJsonSections;

        //  console.log(78, this.callFlowJsonSections)
      }

      if (
        (this.callStatus.status == 'establishing' ||
          this.callStatus.status == 'accepted') &&
        !this.callStatus.customerInfo
      ) {
        this.showHideCallingScreen = true;
        this.showHideStartPage = false;
        this.showHideConnecting = true;
        this.showHideCalldialling = true;
        this.showHideCallingScreen = false;

        this.showHideStopPage = false;
        this.currentFileobj = {};
      }

      if (
        this.callStatus.customerInfo &&
        this.callStatus.status != 'end_call' &&
        this.callStatus.status != 'terminated'
      ) {
        //this.startTimer();
        this.showHideCallingScreen = false;
        this.showHideStopPage = false;
        this.showHideConnecting = false;
        this.showHideCalldialling = false;
        this.showHide_Connected = true;
        this.showHideCallStatus = true;
        this.showHideCallPad = false;
      }
      // if(obj.status == 'accepted'){
      //   setTimeout(()=>{
      //     this.showHideConnecting = false;
      //     this.showHide_Connected = true;
      //   },10000)
      // }
      if (this.callStatus.status == 'end_call_agent') {
        this.currentFileobj = {};
        // this.dialogRef.close();
        //this.startTimer();
        this.showHideCallEndingScreen = false;
        this.showHideConnecting = false;
        this.showHideCalldialling = false;
        this.showHide_Connected = false;
        this.showHideCallStatus = false;
        this.showHideCallPad = false;
        console.log('---0---');
        this.showHideStopPage = true;
      }
    } else {
      this.callStatus = '';
    }
    this.subscription = this.myservice.getCallStatus().subscribe((obj) => {
      this.callStatus = obj;

	    // console.log('calling-new-page - status-change',obj);

      if (
        this.callStatus.status == 'terminated' ||
        this.callStatus.status == 'end_call_agent'
      ) {
        if (document.getElementById('codeDialPad') as HTMLFormElement) {
          (document.getElementById('codeDialPad') as HTMLFormElement).value =
            '';
        }
      }
      if (sessionStorage.getItem('sessionStarted') == 'false') {
        //this.showHideStopPage = false;
        localStorage.setItem('sessionStarted', 'false');
        sessionStorage.setItem('sessionStarted', 'false');
        this.sessionStarted = false;
        this._flagStartStop = 'StartFlag';
        this.showHideStartPage = true;
        this.showHideDiallerPage = false;
        this.check_dialler_setting();
        console.log('--run this script--2');
      }
      if (obj) {
        //  console.log("home12345", obj)
        this.callStatus = obj;

        let _checkObjLength = JSON.stringify(this.callStatus);
        let _checkSessionTrueFalse = sessionStorage.getItem('sessionStarted');
        if (this.callStatus.customerInfo) {
          this.customerInfo = this.callStatus.customerInfo;
        }
        if (this.callStatus.callFlowJsonSections) {
          this.callFlowJsonSections = this.callStatus.callFlowJsonSections;

          // console.log(78, this.callFlowJsonSections)
        }

        if (obj.status == 'establishing' && !this.callStatus.customerInfo) {
          this.showHideCallingScreen = true;
          this.showHideStartPage = false;
          this.showHideConnecting = true;
          this.showHideCalldialling = true;
          this.showHideCallingScreen = false;
          this.showHideStopPage = false;
          this.currentFileobj = {};
        }

        if (
          obj.customerInfo &&
          obj.status != 'end_call' &&
          obj.status != 'terminated'
        ) {
          //this.startTimer();
          this.showHideCallingScreen = false;
          this.showHideStopPage = false;
          this.showHideConnecting = false;
          this.showHideCalldialling = false;
          this.showHide_Connected = true;
          this.showHideCallStatus = true;
          this.showHideCallPad = false;
          let getCtoC = localStorage.getItem('c2cFind');
          if (this.isTruepredicitive == true && getCtoC == 'presentCtoC') {
            this.showHideStartPage = true;
            localStorage.removeItem('c2cFind');
          }
          if (obj.call_type == 'manual_dial') {
            this.showHideStartPage = true;
            localStorage.removeItem('c2cFind');
          } else {
            if (this.isTruepredicitive == true) {
              this.showHideStartPage = true;
            }
          }
        }
        if (obj.status == 'end_call_agent') {
          this.currentFileobj = {};
          // this.dialogRef.close();
          //this.startTimer();
          this.showHideCallEndingScreen = false;
          this.showHideConnecting = false;
          this.showHideCalldialling = false;
          this.showHide_Connected = false;
          this.showHideCallStatus = false;
          this.showHideCallPad = false;
          this.showHideStartPage = true;
          this.showHideStopPage = false;
          //console.log("---4---");
          //this.showHideStopPage = true;

          this.showHideStartPage = false; //added vk1
          this.showHideStopPage = true; //added vk1
        }

        if (obj.status == 'terminated') {
          //debugger;
          this.currentFileobj = {};
          // this.dialogRef.close();
          //this.startTimer();
          this.showHideCallEndingScreen = false;
          this.showHideConnecting = false;
          this.showHideCalldialling = false;
          this.showHide_Connected = false;
          this.showHideCallStatus = false;
          this.showHideCallPad = false;
          console.log('---5---');
          this.showHideStopPage = true;
        }
      } else {
        this.callStatus = '';
      }

      if (
        Object.keys(obj).length == 0 &&
        sessionStorage.getItem('nextPreviewDialingPopup') == 'true'
      ) {
        if (document.getElementById('codeDialPad') as HTMLFormElement) {
          (document.getElementById('codeDialPad') as HTMLFormElement).value =
            '';
        }
        this.showHideStopPage = false;
        this.showHideStartPage = true;
        this.sessionStarted = false;
        this.showHideDiallerPage = false;
        this._flagStartStop = 'StopFlag';
        localStorage.setItem('c2cCallPresent', 'availableC2C');
        this.check_dialler_setting();
      }
    });
  }

  display_button_text(){
    if(this.call_button_status === 0){
      return 'Syncing customers'
    }else if(this.call_button_status === -1){
      return 'Please check your VPN connection'
    }else if(this.call_button_status === -2){
      return 'Please wait while we set up your connection.'
    }
    // else if(this.call_button_status === -3){
    //   return 'Please check socket connection.'
    // }
    return ''
  }

  cancelUpdateBtn() {
    localStorage.removeItem('c2cCallPresent');
    this.showHidePopup = false;
    this.myservice.clearCallStatus();
  }
  clearBtn() {
    this.customer_name_entered = '';
  }

  startpredictive() {
	this.API.remoteDialStart(this.user.id, this.api_end_point).subscribe(
		(res: any) => {
		  //	console.log(res)
		  this.sessionStarted = true;

		  if (this.isStayAtHome) {
			//this.showHidePopup=false;
			localStorage.removeItem('noExitC2CInStartBtn');
		  } else {
			//this.showHidePopup=true;
			console.log('---12---');
			//this.showHideStopPage = true;
			//this.showHideStartPage = false;
			this.showHideStopPage = false;
			this.showHideStartPage = true;
			localStorage.removeItem('noDialPad');
			localStorage.setItem('noDialPad', 'NoDial');

			localStorage.removeItem('noExitC2CInStartBtn');
			localStorage.setItem('noExitC2CInStartBtn', 'c2cNotThere');
			setTimeout(() => {
			  this.startCallLoading();
			}, 100);
		  }
		},
		(err) => {
		  console.log(err);
		}
	  );
  }

  ngOnInit() {
    //this.myservice.DESKTOP_CALL_BUTTON_STATUS.next(1)
    this.myservice.desktop_call_button_status.subscribe((val) => {
      this.call_button_status = val;
      console.log('CallButtonStatus value ::: ', val);
    });
    this.myservicesocket.desktop_call_button_status_socket.subscribe((val) => {
      this.call_button_status_socket = val;
      console.log('CallButtonStatus_socket value ::: ', val);
    });
    this.showHideDiallerPage = false;
    history.pushState(null, '');
    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, '');
        //alert("NO back from home");
        this._snackBar.open('Back button not allow from browser', 'close', {
          duration: 3000,
          verticalPosition: 'top',
        });
        return false;
      });

    this.api_end_point = this.myservice.get_API_End_Point();
    this.callSession = this.myservice.getLastCallSession();
    this.myservice.getCallSession().subscribe((obj) => {
      //  console.log("krunal10010", obj)
	  console.log('calling-new-page call-session',obj);

	  if (obj.nextaction && (obj.nextaction =='start-pd')) {
		var updateObjSession = this.myservice.getLastCallSession();
		updateObjSession.nextaction = 'pd-started';
        this.myservice.updateCallSession(updateObjSession);
		this.startpredictive();
	  }

      if (obj.salesTriggered) {
        var updateObjSession = this.myservice.getLastCallSession();
        delete updateObjSession.salesTriggered;
        this.myservice.updateCallSession(updateObjSession);
        setTimeout(() => {
          this.getHomeData();
          //this.getDashboardList();
          this.followupData();
          this.getContacted();
        }, 2000);
        this.getHomeData();
        //this.getDashboardList();
        this.followupData();
      }
    });
    this.myUser = this.myservice.get_loggedin_user();
    // console.log(this.myUser.access_token);
    if (!this.myUser.sip_id) {
      this.myUser.sip_id = 'krunal@smarterbizkrunal.onsip.com';
      this.isAppAgent = true;
    }
    if (!this.user.sip_id) {
      this.user.sip_id = 'krunal@smarterbizkrunal.onsip.com';
      this.isAppAgent = true;
    }

    if (this.myUser.sip_id) {
      this.API.getSipUserByPhone(
        this.myUser.sip_id,
        this.api_end_point
      ).subscribe(
        (res: any) => {
          const sipUser = res.success;
          if (sipUser && sipUser.sip_user && sipUser.sip_user.length) {
            this.sipUser = sipUser;
            this.sipUser.user = this.user;
            this.sipUser.player = this.$player;
          }
          console.log('Desktop calling Sip Details:', res);
        },
        (err) => {
          console.log('SIP Details Fetch Error:', err);
        }
      );
    }

    // var interval_id = window.setInterval(() => { }, 99999);
    // for (var i = 0; i < interval_id; i++) {
    // 	window.clearInterval(i);
    // }
    // this.myservice.clearCallStatus();
    //debugger;
    let stateSettings: any = this.myservice.get_stage_settings();

    let _inNumberOneDefinition = stateSettings.cloudtelephonyout.filter((v) => {
      return v.name == 'innumber1';
    });
    this._caller_id = _inNumberOneDefinition[0].definition;
    this._findteleco = stateSettings.cloudtelephonyout.filter((v) => {
      return v.name == 'telco_operator';
    });
    // console.log(this._caller_id);
    console.log(this._findteleco);
    console.log(this._findteleco[0].definition);
    if (stateSettings && stateSettings.extraoptions) {
      for (let opt of stateSettings.extraoptions) {
        if (opt.name == 'c2cendpoint') {
          // console.log(opt);
          this.c2cEndPoint = opt.definition;
        }
        if (opt.name == 'biometric' && opt.definition == 'true') {
          if (this.user.isVisonEnabled != true) {
            this.router.navigate(['/agentPluginPC/face']);
            return;
          }
        }
        if (opt.name == 'siopushurl') {
          this.sioPushUrl = opt.definition;
          //console.log(72, this.sioPushUrl);
          //this.socket = io(this.sioPushUrl);
        }
        if (opt.name == 'rnrfeedback') {
          let rnrObject = JSON.parse(opt.definition);
          this.rnroptions = this.getParsed(rnrObject.rnr_options);
        }
      }
    }
    // this.initSocket();
    this.getHomeData();
    //this.getDashboardList();
    this.getAgentSlot();
    this.getContacted();
    this.getNotification();
    this.followupData();

    this.getDcTableData();
    if (document.getElementById('codeDialPad') as HTMLFormElement) {
      (document.getElementById('codeDialPad') as HTMLFormElement).value = '';
    }
    //debugger;
    this.isCheckProjectTypeSBICC = true;
    let stateSettingsChat: any = this.myservice.get_stage_settings();
    let _checkProjectTypeSBICC = stateSettingsChat.extraoptions.filter((v) => {
      return v.name == 'project_type';
    });
    if (_checkProjectTypeSBICC && _checkProjectTypeSBICC.length == 1) {
      this._projectTypeSBICC = _checkProjectTypeSBICC[0].definition;
      if (_checkProjectTypeSBICC[0].definition == 'SBICC') {
        this.isCheckProjectTypeSBICC = false;
      } else {
        this.isCheckProjectTypeSBICC = true;
      }
    }
    let _setFilterDial = stateSettingsChat.extraoptions.filter((v) => {
      return v.name == 'unscheduledcall';
    });
    // debugger;
    if (_setFilterDial && _setFilterDial.length > 0) {
      this.isStageDial = false;
      if (_setFilterDial[0].definition == 'off') {
        this.isStageDial = false;
      } else {
        this.isStageDial = true;
        this.showHideDiallerPage = false;
      }
      console.log(_setFilterDial);
    }
    // else{
    //   this.isStageDial = true;
    // }

    //added by Praveen start
    //start added by praveen
    // let _setDialPad = stateSettingsChat.extraoptions.filter(v=>{return v.name=='manualdial'});
    // if(_setDialPad && _setDialPad.length > 0){
    //   this.isStageDial = false;
    //   if(_setDialPad[0].definition == "off"){
    // 	this.isStageDial = false;
    //   }else{
    // 	this.isStageDial = true;
    //   }

    //   console.log(_setDialPad);
    // }else{
    //   this.isStageDial = true;
    // }
    //end added by praveen
    //added by Praveen end

    this.setPredictiveOnOff = [];
    let _setFilterTruepredictive = stateSettingsChat.extraoptions.filter(
      (v) => {
        return v.name == 'truepredictive';
      }
    );
    this.setPredictiveOnOff = _setFilterTruepredictive;

    if (_setFilterTruepredictive && _setFilterTruepredictive.length > 0) {
      this.isTruepredicitive = false;
      if (_setFilterTruepredictive[0].definition == 'off') {
        this.isTruepredicitive = false;
      } else {
        this.isTruepredicitive = true;
      }

      console.log(_setFilterTruepredictive);
    } else {
      this.isTruepredicitive = false;
    }

    //debugger;
    let _setFilterStayHome = stateSettingsChat.extraoptions.filter((v) => {
      return v.name == 'stayathome';
    });
    if (_setFilterStayHome && _setFilterStayHome.length > 0) {
      this.isStayAtHome = false;
      if (_setFilterStayHome[0].definition == 'off') {
        this.isStayAtHome = false;
      } else {
        this.isStayAtHome = true;
      }

      console.log(_setFilterStayHome);
    } else {
      this.isStayAtHome = false;
    }

    let stateSettings1: any = this.myservice.get_stage_settings();
    // let _checkAllowinBound = stateSettings1.extraoptions.filter(v => { return v.name == 'allowinbound' });
    // if (_checkAllowinBound && _checkAllowinBound.length > 0 && _checkAllowinBound[0].definition == "on") {
    // this.isStageDial = false;
    // }
    // else{
    //   this.isStageDial = true;
    // }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();

    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  checkCustomerName() {
    const callSession1 = this.myservice.getLastCallSession();
    if (
      callSession1 &&
      (callSession1.internet_speed == 0 ||
        callSession1.internet_speed == undefined)
    ) {
      alert('Please check your network or wifi connection.');
      return;
    }
    const callSession = this.myservice.getLastCallSession();
    // if (callSession.sessionStarted == undefined || callSession.sessionStarted == null || callSession.sessionStarted == false) {
    //   alert("Please Press Start for Calling");
    //   return;
    // }
    if (
      callSession &&
      callSession.internet_speed &&
      callSession.internet_speed < 2
    ) {
      alert('Internet speed slow try later');
      return;
    }
    if (callSession && callSession.internet_speed == 0) {
      alert('No Internet Network Connection available');
      return;
    }
    if (callSession && callSession.internet_speed == undefined) {
      alert('No Internet Network Connection available');
      return;
    }
    if (this._findteleco[0].definition == 'KNOWLARITY') {
      if (
        callSession &&
        !callSession.isAppAgent &&
        callSession.sip_conn != true
      ) {
        alert('Sip connection not available try later');
        return;
      }
    } else if (this._findteleco[0].definition == 'Twilio') {
    } else if (this._findteleco[0].definition == 'TCL') {
    } else if (this._findteleco[0].definition == 'TTS') {
    } else if (this._findteleco[0].definition == 'ASTERISK') {
      if (document.getElementById('codeDialPad') as HTMLFormElement) {
        let inputval = (
          document.getElementById('codeDialPad') as HTMLFormElement
        ).value;
        if (inputval == '0') {
          console.log('Disconnecting the SIP Call.');
          this.sipConnection.stop().then(
            () => {
              console.log('SIP Connection User agent stopped');
            },
            (error) => {
              console.log('SIP Connection Start Error : ', error);
            }
          );
        } else {
          console.log('Triggering SIP Outgoing call..', this.sipUser, inputval);
          this.sipConnection = SIPConnection.sipOutgoingCall(
            this.sipUser,
            inputval
          );
        }
      }
      return true;
    }

    let _numLength = (document.getElementById('codeDialPad') as HTMLFormElement)
      .value.length;
    if (_numLength < 8) {
      alert('Enter proper number');
      return false;
    }
    if (document.getElementById('codeDialPad') as HTMLFormElement) {
      let obj = { name: '', number: '', user_id: '' };
      obj.number =
        '+91' +
        (document.getElementById('codeDialPad') as HTMLFormElement).value;
      obj.user_id = this.user.id;

      console.log(JSON.stringify(obj));
      // openDialogDialerDialog(templateRef: TemplateRef<any>) {
      //   this.dialog.open(this.dialerDialog);
      // }
      this.dialog.closeAll();

      this.API.check_customer_details(obj, this.api_end_point).subscribe(
        (res: any) => {
          if (res.success) {
            let data_rec = res.success;

            let call_obj = {
              dialing: 1,
              dialName: data_rec.name,
              dialNum: data_rec.number,
            };
            this.firstcallstage = call_obj;

            this.customer_name_entered = data_rec.name;
            // console.log("-2-",JSON.stringify(call_obj));
            //  this.myservice.updateCallStatus(call_obj);
            let updateObj = {
              dialing: 2,
              dialNum: data_rec.number,
              lastDialledName: data_rec.name,
              dialName: data_rec.name,
              status: 'establishing',
              customerInfo: {
                customer_name: data_rec.name,
                customer_number: data_rec.number,
                // 'updated_at': obj.updated_at,
                customkvs: data_rec.customkvs,
                //'transactionid': obj.transactionid,
                id: data_rec.id,
                checkC2C: 'c2cCallThere',
                dial_mode: 'direct_dial',
              },
              call_type: '',
            };
            updateObj.call_type = 'manual_dial';
            //this.myservice.updateCallStatus(updateObj);
            this.secondcallstage = updateObj;

            console.log('-3-', JSON.stringify(updateObj));
            //this.for_customer_details(res.success);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
    this.showHidePopup = true;
  }

  for_customer_details() {
    debugger;
    // console.log(JSON.stringify(data_rec));
    // //data_rec.name="chaitanya";
    // let call_obj ={
    //   dialing:1,
    //   dialName:data_rec.name,
    //   dialNum:data_rec.number
    // }
    // this.customer_name_entered = data_rec.number;
    console.log('-2-', JSON.stringify(this.firstcallstage));
    this.firstcallstage.dialName = this.customer_name_entered;
    this.firstcallstage.call_type = 'manual_dial';

    this.myservice.updateCallStatus(this.firstcallstage);
    this.secondcallstage['customerInfo'].customer_name =
      this.customer_name_entered;
    console.log('-3-', JSON.stringify(this.secondcallstage));
    this.myservice.updateCallStatus(this.secondcallstage);
    let updateObjSession = {
      sessionStarted: true,
      internet_speed: 2,
    };
    updateObjSession.sessionStarted = true;
    this.myservice.updateCallSession(updateObjSession);
    //this.callCustomer(data_rec.number,call_obj,'');
  }

  SelectFilledForm() {
    this.LeadsSelected = false;
    this.FilledFormsSelected = true;
    this.FollowupSelected = false;
  }

  callCustomer(number, customer_details, type: any) {
    localStorage.removeItem('noDialPad');
    localStorage.setItem('c2cCallPresent', 'availableC2C');
    localStorage.setItem('c2cFind', 'presentCtoC');
    var callSession = this.myservice.getLastCallSession();
    if (this.isStageDial) {
      //this.showHidePopup=false;
      if (type == 'NoNeedStartForC2C' || type == 'availableC2C') {
      } else {
        if (
          callSession.sessionStarted == undefined ||
          callSession.sessionStarted == null ||
          callSession.sessionStarted == false
        ) {
          alert('Please Press Start for Calling');
          return;
        }
      }
    } else {
      //this.showHidePopup=true;
    }
    if (
      callSession &&
      (!callSession.internet_speed || callSession.internet_speed < 2)
    ) {
      alert('Internet speed slow try later');
      return;
    }
    if (callSession && callSession.internet_speed == undefined) {
      alert('No Internet Network Connection available');
      return;
    }
    if (!this.call_button_status_socket) {
      this._snackBar.open(
        'Please check socket connection to make calls',
        'Close',
        {
          duration: 3000,
        }
      );
      return;
    }
    if (this.call_button_status == -1) {
      this._snackBar.open(
        'Please enable VPN connection to make calls',
        'Close',
        {
          duration: 3000,
        }
      );
      return;
    }
    
    // if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
    // 	alert("Sip connection not available try later")
    // 	return;
    // }
    if (this._findteleco[0].definition == 'KNOWLARITY') {
      if (
        callSession &&
        !callSession.isAppAgent &&
        callSession.sip_conn != true
      ) {
        alert('Sip connection not available try later');
        return;
      }
    } else if (this._findteleco[0].definition == 'Twilio') {
    } else if (this._findteleco[0].definition == 'TCL') {
    } else if (this._findteleco[0].definition == 'TTS') {
    }
    //console.log("dd-debug", this.callStatus, number);
    // setTimeout(() => {
    //   this._snackBar.open("please wait connecting", "close", {
    //     duration: 5000,
    //   });
    // }, 2000);
    var updateObj = this.callStatus;
    console.log(JSON.stringify(updateObj));
    console.log(JSON.stringify(customer_details));
    //debugger;
    //if (updateObj.status == undefined) {

    updateObj.dialNum = number;
    // updateObj.dialName = this.customer_name_entered;
    updateObj.dialing = 1;
    if (customer_details.lead_source != null) {
      updateObj.call_type = 'preview_dial';
    } else {
      updateObj.call_type = 'manual_dial';
    }
    //console.log("dd-debug20", updateObj)
    this.myservice.updateCallStatus(updateObj);
    updateObj = this.callStatus;
    updateObj.dialing = 2;
    if (customer_details.lead_source != null) {
      updateObj.call_type = 'preview_dial';
    } else {
      updateObj.call_type = 'manual_dial';
    }
    updateObj.promotional_call = customer_details.is_promo;
    let _typeSoftSSL='';
    if(customer_details.campaign_type=="STP" || customer_details.campaign_type=="E_APPLY"){
     _typeSoftSSL='SSL';
    } else {
      _typeSoftSSL = 'SSL';
    }
    if (type == 'NoNeedStartForC2C') {
      console.log('CallCustomer :::: ', customer_details);
      updateObj.customerInfo = {
        customer_name: customer_details.name,
        customer_number: customer_details.number,
        // 'updated_at': obj.updated_at,
        customkvs: customer_details.customkvs,
        //'transactionid': obj.transactionid,
        id: customer_details.id,
        checkC2C: 'c2cCallThere',
        dial_mode: 'direct_dial',
        campignType: _typeSoftSSL,
        feedback: customer_details.feedback_json
          ? customer_details.feedback_json
          : '',
        stage: customer_details.stage ? customer_details.stage : '',
      };
    } else {
      updateObj.customerInfo = {
        customer_name: customer_details.name,
        customer_number: customer_details.number,
        // 'updated_at': obj.updated_at,
        customkvs: customer_details.customkvs,
        //'transactionid': obj.transactionid,
        id: customer_details.id,
        checkC2C: 'c2cCallThere',
        dial_mode: 'direct_dial',
        campignType: _typeSoftSSL,
        feedback: customer_details.feedback ? customer_details.feedback : '',
        stage: customer_details.stage ? customer_details.stage : '',
      };
    }

    //'establishing';
    updateObj.status = 'establishing';
    console.log('dd-debug21', updateObj);
    this.myservice.updateCallStatus(updateObj);
    //}
  }
  endCallFirst(endSession: boolean = false, triggerRnr: boolean = false): void {
    this.showHideStartPage = false;
    this.showHideCallingScreen = false;
    this.showHideDailpad = false;
    this.showHideCallStatus = false;
    this.showHide_Connected = false;
    this.showHideCalldialling = false;
    this.showHideConnecting = false;
    this.showHideCallEndingScreen = false;
    if (!this.callStatus.showDuringCallForm) {
      this.showHide_Form_After_DuringForm = true;
    }
    console.log('---7---');
    this.showHideStopPage = true;
    this.salesStatusTrigger = true;
    var QA = [];
    for (let section of this.callFlowJsonSections) {
      var obj1: any = [];
      for (let child of section.child) {
        var temp1: any = { 'Q:': child.q, 'A:': child.answer || '' };
        if (child.action_type) {
          temp1['action_type:'] = child.action_type;
        }

        obj1.push(JSON.stringify(temp1));
        if (child[child.answer]) {
          for (let child2 of child[child.answer]) {
            var temp2: any = { 'Q:': child2.q, 'A:': child2.answer || '' };
            if (child2.action_type) {
              temp2['action_type:'] = child2.action_type;
            }
            obj1.push(JSON.stringify(temp2));
            if (child2[child2.answer]) {
              for (let child3 of child2[child2.answer]) {
                var temp3: any = { 'Q:': child3.q, 'A:': child3.answer || '' };
                if (child3.action_type) {
                  temp3['action_type:'] = child3.action_type;
                }
                obj1.push(JSON.stringify(temp3));
              }
            }
          }
        }
      }
      QA = obj1;
    }
    //console.log(QA);
    let call_Session = this.myservice.getLastCallSession();
    this.salesSts.contact_person.name = call_Session.dialName;

    let updateObj = this.callStatus;
    updateObj.status = 'end_call_agent';
    if (this.callFlowJsonSections) {
      updateObj.callFlowJsonSections = this.callFlowJsonSections;
      updateObj.QA = QA;
    }
    const currentTime = moment().utc();
    let salesSts = {
      user_id: this.user.id,
      contact_person: {
        number: '',
        name: '',
      },
      description: '',
      order_potential: '',
      status: 'NEW DATA',
      lat: '22.0858725',
      long: '82.1879959',
      start_time: this.callStatus.callStart || currentTime,
      substatus1: '',
      substatus2: '',
      machine_status: '',
      wrapup: 5000,
      lead_source: '',
      appointment_id: '',
      call_end_time: this.callStatus.callEnd || currentTime,
      customkvs: '',
    } as any;
    if (salesSts.start_time != salesSts.call_end_time) {
      salesSts.wrapup = salesSts.call_end_time - salesSts.start_time;
    }
    if (this.callStatus.customerInfo) {
      salesSts.contact_person.number =
        this.callStatus.customerInfo.customer_number;
      salesSts.contact_person.name = this.callStatus.customerInfo.customer_name;
      salesSts.transactionid = this.callStatus.customerInfo.transactionid;
    } else if (this.callStatus.lastDialled) {
      salesSts.contact_person.number = this.callStatus.lastDialled;
    }
    if (this.callStatus.callFlowJsonSections) {
      let finalObj = {
        experience: this.callStatus.QA,
      };
      salesSts.extranotes = JSON.stringify(finalObj);
    }

    //console.log("this.salesSts : ", this);
    this.salesSts = salesSts;
    if (this.isAppAgent && updateObj.status == 'end_call_agent') {
      let obj = {
        number: this.customerInfo.customer_number,
        transactionid: this.salesSts.transactionid,
        user_id: this.myUser.id,
        disconnect_type: 'End Call',
        source: 'Agent disconnected',
      };

      this.API.callDisconnect(obj, this.user.server_url).subscribe(
        (res: any) => {},
        (err) => {
          console.log(err);
        }
      );

      this.showHide_Connected = false;
      this.showHideCallStatus = false;
      this.showHideConnecting = false;
      this.showHideCalldialling = false;
      //console.log("dd-debug23", updateObj)
      this.myservice.updateCallStatus(updateObj);
    } else {
      this.showHide_Connected = false;
      this.showHideCallStatus = false;
      this.showHideConnecting = false;
      this.showHideCalldialling = false;
      //	console.log("dd-debug24", updateObj)
      this.myservice.updateCallStatus(updateObj);
    }
    if (endSession) {
      this.endSession();
    }
    console.log('---8---');
    this.showHideStopPage = true;
    //console.log("line509", this.customerInfo)
    if (triggerRnr || !this.customerInfo) {
      this.typeTab = 'rnr';
      this.clickHighlight('rnr');
      // this.endCall();
      this.showHide_Form_before_DuringForm = false;
      this.showHide_Form_After_DuringForm = true;
      delete updateObj.showDuringCallForm;
      //console.log("dd-debug25", updateObj)
      this.myservice.updateCallStatus(updateObj);
    }
  }
  getOjectStringify(obj) {
    return JSON.stringify(obj);
  }
  endCall() {
    if (this.typeTab == 'rnr') {
      if (!this.rnrDescription || !this.selectedRnrReason) {
        this.showDuringFormError = true;
        setTimeout(() => {
          this.showDuringFormError = false;
        }, 3000);
        return;
      }
      var obj1 = { Q: 'Reason for RNR', A: this.selectedRnrReason };
      var obj2 = { Q: 'Description for RNR', A: this.rnrDescription };
      var finalObj = [];
      finalObj.push(JSON.stringify(obj1));
      finalObj.push(JSON.stringify(obj2));
      this.salesSts.extranotes = JSON.stringify(finalObj);
    }
    if (this.typeTab == 'callback1') {
      if (!this.disposition) {
        this.showDuringFormError = true;
        setTimeout(() => {
          this.showDuringFormError = false;
        }, 3000);
        return;
      }
    }

    console.log(this.callStatus.status);
    if (
      this.callStatus.status != 'end_call_agent' &&
      this.callStatus.status != 'terminated'
    ) {
      alert('Please end call first');
      return;
    }
    this.showHide_Form_before_DuringForm = true;
    this.showHide_Form_After_DuringForm = false;
    var typeTab = this.typeTab;
    var phoneNumber = this.customerInfo.customer_number;
    var customerName = this.customerInfo;
    // var date = moment(this.dateModel).format('DD/MM/YYYY');
    // var Time1 = moment(this.timeModel).format('HH:mm:ss');

    // var dateTime = moment(date + ' ' + this.timeModel, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.000Z');
    // this.callbacktime = dateTime;

    //this.showHideStopPage = true;
    //this.showHideStartPage = false;
    this.showHideCallingScreen = false;
    this.showHideDailpad = false;
    this.showHideCallStatus = false;
    this.showHideCalldialling = false;
    this.showHideCallEndingScreen = false;
    if (this.typeTab == 'rnr') {
      this.salesSts.status = 'ALL RNR';
      this.salesSts.substatus1 = '(ACP)';
    }
    if (this.typeTab == 'callback1') {
      this.salesSts.start_time = moment(
        this.timeBtnValueTransformed || this.callbacktime
      ).utc();
      this.salesSts.created_at = moment().utc();
      this.salesSts.status = 'NEW DATA';
      this.salesSts.substatus1 = '(ACP)';
      this.salesSts.substatus2 = 'CALLBACK';
    }
    if (this.typeTab == 'callback2') {
      this.salesSts.start_time = moment().utc();
      this.salesSts.status = 'NEW DATA(REDIAL)';
    }
    let callSession = this.myservice.getLastCallSession();
    this.salesSts.contact_person.name = callSession.dialName;
    console.log(JSON.stringify(this.salesSts));
    //debugger;
    this.API.salesStatus(this.salesSts, this.user.server_url).subscribe(
      (res: any) => {
        this.salesStatusTrigger = false;
        this.salesSts = '';

        this.customerInfo = '';
        this.showHideCallRNR = false;
        //console.log("SalesStatus Response : ", res);
        // SIPConnection.reCheckConnection();
        this.leadFilter = 'TODAY';
        this.selectedFilter = 'TODAY';
        this.getContacted();
        this.restFm();
        if (typeTab == 'callback2' && phoneNumber) {
          this.callCustomer(phoneNumber, customerName, '');
        }
        this.myservice.clearCallStatus();
      },
      (err) => {
        this.myservice.clearCallStatus();
        this.salesStatusTrigger = false;
        this.salesSts = '';
        this.showHideCallRNR = false;
        this.customerInfo = '';
        // SIPConnection.reCheckConnection();
        this.leadFilter = 'TODAY';
        this.selectedFilter = 'TODAY';
        this.getContacted();
        this.restFm();
        if (typeTab == 'callback2' && phoneNumber) {
          this.callCustomer(phoneNumber, customerName, '');
        }
        console.log(err);
      }
    );
    setTimeout(() => {
      this.salesStatusTrigger = false;
      this.salesSts = '';
      this.showHideCallRNR = false;
      this.customerInfo = '';
      // SIPConnection.reCheckConnection();
      this.leadFilter = 'TODAY';
      this.selectedFilter = 'TODAY';
      this.getContacted();
      this.restFm();
      this.myservice.clearCallStatus();
    }, 3000);

    this.typeTab = 'update';
    this.showHide_Connected = false;
    this.showHideCallStatus = false;
    this.showHideConnecting = false;
    this.showHideCalldialling = false;
    this.showHideCallPad = false;
    this.showHideStartPage = true;
    this.showHideStopPage = false;
    this.myservice.clearCallStatus();
    // this.customerInfo = "";
    this.widgetUser = '';
    this.timeBtnValueTransformed = '';
    this.callbacktime = '';
    // var interval_id = window.setInterval(() => { }, 99999);
    // for (var i = 0; i < interval_id; i++) {
    // 	window.clearInterval(i);
    // }
  }

  selectRnrReason(value) {
    this.selectedRnrReason = value;
  }

  removeNum() {
    let _num = (document.getElementById('codeDialPad') as HTMLFormElement)
      .value;
    _num = _num.substring(0, _num.length - 1);
    (document.getElementById('codeDialPad') as HTMLFormElement).value = _num;
    this.myservice.clearCallStatus();
    this.showHideStartCallButton = true;
    this.showHideCallPad = false;
  }
  clickNumBtn(num: any) {
    let _numLength = (document.getElementById('codeDialPad') as HTMLFormElement)
      .value.length;
    if (_numLength == 10) {
      (document.getElementById('codeDialPad') as HTMLFormElement).value;
      return false;
    }
    (document.getElementById('codeDialPad') as HTMLFormElement).value =
      (document.getElementById('codeDialPad') as HTMLFormElement).value +
      num.toString();
    //console.log((document.getElementById('codeDialPad') as HTMLFormElement).value);

    var updateObj = this.callStatus;
    updateObj.dialNum = (
      document.getElementById('codeDialPad') as HTMLFormElement
    ).value;
    updateObj.dialed = false;
    //console.log("dd-debug26", updateObj)
    this.myservice.updateCallStatus(updateObj);
  }

  startCallLoading() {
    const callSession = this.myservice.getLastCallSession();
    if (
      callSession.sessionStarted == undefined ||
      callSession.sessionStarted == null ||
      callSession.sessionStarted == false
    ) {
      alert('Please Press Start for Calling');
      return;
    }
    if (
      callSession &&
      callSession.internet_speed &&
      callSession.internet_speed < 2
    ) {
      alert('Internet speed slow try later');
      return;
    }
    if (callSession && callSession.internet_speed == undefined) {
      alert('No Internet Network Connection available');
      return;
    }
    if (this._findteleco[0].definition == 'KNOWLARITY') {
      if (
        callSession &&
        !callSession.isAppAgent &&
        callSession.sip_conn != true
      ) {
        alert('Sip connection not available try later');
        return;
      }
    } else if (this._findteleco[0].definition == 'Twilio') {
    } else if (this._findteleco[0].definition == 'TCL') {
    } else if (this._findteleco[0].definition == 'TTS') {
    }
    // if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
    // 	alert("Sip connection not available try later");
    // 	return;
    // }
    //localStorage.setItem("c2cCallPresent", "availableC2C");
    //this.showHideCallPad = true;

    this.showHideStartCallButton = false;
    this.showHideStartCall = false;

    if (true) {
      this.showHideDailpad = false;
      this.showHideStartPage = false;
      console.log('---9---');
      this.showHideStopPage = true;
      this.showHideConnecting = false;
      this.showHideCalldialling = false;
      this.showHide_Connected = false;
      this.showHideCallStatus = false;
      this.showHideCallPad = false;
    } else {
      this.showHideDailpad = true;
      this.showHideStartPage = false;
      this.showHideStopPage = false;
      // this.showHideDailpad = false;
      this.showHideConnecting = false;
      this.showHideCalldialling = false;
      this.showHide_Connected = false;
      this.showHideCallStatus = false;
      this.showHideCallPad = false;
    }
    // setTimeout( () => {
    //    this.showHideConnecting=false;
    //    this.showHideStartCallButton=false;
    //    this.showHideStartCall=false;

    //   //  setTimeout( () => {
    //   //  this.showHide_Connected=true;
    //   //  this.showHideConnecting=false;
    //   //  this.showHideStartCallButton=false;
    //   //  this.showHideStartCall=false;
    //   // }, 8000 )

    //   }, 6000 )
    var updateObj = this.callStatus;
    // if (this.callStatus && this.callStatus.dialing && this.callStatus.dialing == 1) {
    // 	this.showHideStopPage = false;
    // 	this.showHideStartPage = false;
    // 	this.showHideStopPage = true;
    // 	updateObj.dialing = 2;
    // 	console.log("dd-debug27", updateObj)
    // 	this.myservice.updateCallStatus(updateObj);
    // }

    if (updateObj.status == undefined) {
      //updateObj.dialNum = (document.getElementById('codeDialPad') as HTMLFormElement).value;
      if (document.getElementById('codeDialPad') as HTMLFormElement) {
        updateObj.dialNum = (
          document.getElementById('codeDialPad') as HTMLFormElement
        ).value;
      } else {
        updateObj.dialNum = '';
      }

      updateObj.dialing = 1;
      this.myservice.updateCallStatus(updateObj);
      updateObj = this.callStatus;
      updateObj.dialing = 2;
      if (this.customer_name_entered) {
        updateObj.dialName = this.customer_name_entered;
      }
      this.myservice.updateCallStatus(updateObj);
      this.customer_name_entered = '';
      this.showHidePopup = false;
    }
  }

  startCallLoadingUpdate() {
    this.cancelUpdateBtn();
    const callSession = this.myservice.getLastCallSession();
    // if (callSession.sessionStarted == undefined || callSession.sessionStarted == null || callSession.sessionStarted == false) {
    //   alert("Please Press Start for Calling");
    //   return;
    // }
    if (
      callSession &&
      callSession.internet_speed &&
      callSession.internet_speed < 2
    ) {
      alert('Internet speed slow try later');
      return;
    }
    if (callSession && callSession.internet_speed == undefined) {
      alert('No Internet Network Connection available');
      return;
    }
    if (this._findteleco[0].definition == 'KNOWLARITY') {
      if (
        callSession &&
        !callSession.isAppAgent &&
        callSession.sip_conn != true
      ) {
        alert('Sip connection not available try later');
        return;
      }
    } else if (this._findteleco[0].definition == 'Twilio') {
    } else if (this._findteleco[0].definition == 'TCL') {
    } else if (this._findteleco[0].definition == 'TTS') {
    }
    // if (callSession && !callSession.isAppAgent && callSession.sip_conn != true) {
    // 	alert("Sip connection not available try later");
    // 	return;
    // }
    localStorage.setItem('c2cCallPresent', 'availableC2C');
    //this.showHideCallPad = true;

    this.showHideStartCallButton = false;
    this.showHideStartCall = false;

    if (true) {
      this.showHideDailpad = false;
      this.showHideStartPage = false;
      console.log('---9---');
      this.showHideStopPage = true;
      this.showHideConnecting = false;
      this.showHideCalldialling = false;
      this.showHide_Connected = false;
      this.showHideCallStatus = false;
      this.showHideCallPad = false;
    } else {
      this.showHideDailpad = true;
      this.showHideStartPage = false;
      this.showHideStopPage = false;
      // this.showHideDailpad = false;
      this.showHideConnecting = false;
      this.showHideCalldialling = false;
      this.showHide_Connected = false;
      this.showHideCallStatus = false;
      this.showHideCallPad = false;
    }
    //debugger;

    console.log('-2-', JSON.stringify(this.firstcallstage));
    this.firstcallstage.dialName = this.customer_name_entered;
    this.myservice.updateCallStatus(this.firstcallstage);
    this.secondcallstage['customerInfo'].customer_name =
      this.customer_name_entered;
    console.log('-3-', JSON.stringify(this.secondcallstage));
    this.myservice.updateCallStatus(this.secondcallstage);
    let updateObjSession = {
      sessionStarted: true,
      internet_speed: 2,
    };

    updateObjSession.sessionStarted = true;

    var updateObj = this.callStatus;
    //'establishing';
    updateObj.status = 'establishing';
    console.log('dd-debug21', updateObj);
    this.myservice.updateCallStatus(updateObj);

    if (updateObj.status == undefined) {
      //updateObj.dialNum = (document.getElementById('codeDialPad') as HTMLFormElement).value;
      if (document.getElementById('codeDialPad') as HTMLFormElement) {
        updateObj.dialNum = (
          document.getElementById('codeDialPad') as HTMLFormElement
        ).value;
      } else {
        updateObj.dialNum = '';
      }
      //this.for_customer_details();
      this.myservice.updateCallSession(updateObjSession);

      let manualObj = {
        user_id: this.user.id,
        id_group: this.user.groupid,
        number: '+91' + updateObj.dialNum,
        name: this.customer_name_entered,
        call_type: 'Manual_dial',
      };

      this.API.TTS_c2c_API(manualObj, this.api_end_point).subscribe(
        (res: any) => {
          console.log(res);
          let _response = {};
          if (res && res.success) {
            _response = res.success;
            this.myservice.updateCallStatus(updateObj);
            this.customer_name_entered = '';
            this.showHidePopup = false;
          } else {
          }
        }
      );
    } else {
      let manualObj = {
        user_id: this.user.id,
        id_group: this.user.groupid,
        number: updateObj.dialNum,
        name: this.customer_name_entered,
        call_type: 'Manual_dial',
      };

      this.API.TTS_c2c_API(manualObj, this.api_end_point).subscribe(
        (res: any) => {
          console.log(res);
          let _response = {};
          if (res && res.success) {
            _response = res.success;
            this.customer_name_entered = '';
            this.showHidePopup = false;
          } else {
          }
        }
      );
    }
  }

  resetDialer() {
    this.showHideDailpad = false;
    this.showHideStartPage = true;
    console.log('---10---');
    this.showHideStopPage = false;
    this.showHideConnecting = false;
    this.showHideCalldialling = false;
    this.showHide_Connected = false;
    this.showHideCallStatus = false;
    this.showHideCallPad = false;
    var updateObj = this.callStatus;
    delete updateObj.dialing;
    //console.log("dd-debug29", updateObj)
    this.myservice.updateCallStatus(updateObj);
  }

  mddValueChange(event, index, childIndex, mddIndex) {
    //	console.log(event.checked, index, childIndex, mddIndex)
    if (event.checked) {
      var dd_data = this.callFlowJsonSections[index].child[childIndex].cb_data;
      var total = this.callFlowJsonSections[index].child[childIndex].answer
        ? this.callFlowJsonSections[index].child[childIndex].answer
        : '';
      if (total == '') {
        total = total + dd_data[mddIndex];
      } else {
        total = total + ',' + dd_data[mddIndex];
      }
      this.callFlowJsonSections[index].child[childIndex].answer = total;
      //	console.log(this.callFlowJsonSections[index].child[childIndex].answer)
    } else {
      var array = this.callFlowJsonSections[index].child[childIndex].answer
        ? this.callFlowJsonSections[index].child[childIndex].answer.split(',')
        : [];
      //	console.log(mddIndex, array)
      var findIndex = 0;
      for (var i = 0; i < array.length; i++) {
        if (
          array[i] ==
          this.callFlowJsonSections[index].child[childIndex].cb_data[mddIndex]
        ) {
          findIndex = i;
          array.splice(findIndex, 1);
          this.callFlowJsonSections[index].child[childIndex].answer =
            array.toString();
          break;
        }
      }
      //console.log(array)
    }
    var arrayPrev = this.callFlowJsonSections[index].child[childIndex].answer
      ? this.callFlowJsonSections[index].child[childIndex].answer.split(',')
      : [];
    var arrayDD = this.callFlowJsonSections[index].child[childIndex].cb_data;
    var finalArray = [];
    for (var j = 0; j < arrayDD.length; j++) {
      for (var k = 0; k < arrayPrev.length; k++) {
        if (arrayDD[j] == arrayPrev[k]) {
          finalArray.push(arrayDD[j]);
        }
      }
    }
    //console.log(finalArray);
    this.callFlowJsonSections[index].child[childIndex].answer =
      finalArray.toString();
    var updateObj = this.callStatus;
    if (this.callFlowJsonSections) {
      updateObj.callFlowJsonSections = this.callFlowJsonSections;
    }
    //console.log("dd-debug30", updateObj)
    this.myservice.updateCallStatus(updateObj);
  }
  change_date(event: MatDatepickerInputEvent<Date>, index, childIndex) {
    //console.log(this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value), index, childIndex);
    this.callFlowJsonSections[index].child[childIndex].answer = event.value;
  }

  change_time(event: any, index, childIndex) {
    //console.log(event.target.value, index, childIndex);
    var dateAdded = this.callFlowJsonSections[index].child[childIndex].answer;
    // dateAdded = moment(dateAdded);
    var timeAdded = event.target.value;
    var splitDate = timeAdded.split(':');
    var finalDate = moment(dateAdded)
      .add(Number(splitDate[0]), 'hours')
      .add(Number(splitDate[1]), 'minutes');
    //console.log(finalDate.format());
  }

  changeFile(event, index) {
    this.currentFileobj = {};

    if (event.target.files && event.target.files.length) {
      //console.log(event.target.files);
      this.currentFileobj = event.target.files[0];
      let formData = new FormData();
      formData.append(
        'image',
        event.target.files[0],
        event.target.files[0].name
      );
      this.API.uploadAnyFile(formData, this.api_end_point).subscribe(
        (res: any) => {
          this.callFlowJsonSections[0].child[index].answer = res.url;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  changeFileLevel2(event, index1, index2, index3, answer1, answer2) {
    this.currentFileobj = {};

    if (event.target.files && event.target.files.length) {
      //	console.log(event.target.files);
      this.currentFileobj = event.target.files[0];
      let formData = new FormData();
      formData.append(
        'image',
        event.target.files[0],
        event.target.files[0].name
      );
      this.API.uploadAnyFile(formData, this.api_end_point).subscribe(
        (res: any) => {
          this.callFlowJsonSections[0].child[index1].answer1[index2].answer2[
            index3
          ].answer = res.url;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  initSocket() {
    this.socket.on('connect', () => {
      //console.log(this.socket);
      this.socketId = this.socket.id;

      this.getHomeData();
      //this.getDashboardList();
      this.followupData();
      this.getAgentSlot();
      this.getContacted();
      this.getNotification();

      this.socket.emit('registerme', this.user.id);
      this.socket.on('registerme-ack', (msg: any) => {
        // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
        console.log('socket-registerme-', msg);
      });
      this.socket.on('pushnotif', (type: any, message: any) => {
        // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
        //	console.log("pushnotif", message)
        return;
        if (type == 'system alert') {
          this.showHideConnecting = false;
          this.showHideCalldialling = false;
          this.customerInfo = JSON.parse(message).data;
          var updateObj = this.callStatus;
          updateObj.customerInfo = this.customerInfo;
          this.myservice.updateCallStatus(updateObj);
          var interval_id = window.setInterval(() => {}, 99999);
          for (var i = 0; i < interval_id; i++) {
            window.clearInterval(i);
          }
          console.log(this.customerInfo);
          this.customerlead_source = this.customerInfo.lead_source;

          this.customerstatus = this.customerInfo.status;
          if (!this.isAppAgent && updateObj.dialNum == undefined) {
            this.fetchUser(this.customerInfo.customer_number);
          }
          //   setInterval(() => {
          // 	  if (!this.isAppAgent && updateObj.dialNum == undefined){
          // 		  this.fetchUser(this.customerInfo.customer_number)
          // 	  }

          //   }, 3000)
          this.startTimer();
        }

        this.socket.emit(
          'pushnotif-ack',
          this.user.if,
          JSON.parse(message).data,
          'Connecting'
        );

        console.log('socket-pushnotif-', type, message);
      });
      this.socket.on('isalive', (message: any) => {
        // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);

        this.socket.emit('isalive-ack', this.user.id);
      });
      this.socket.on('message', (message: any) => {
        // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);

        console.log('socket-', message);
      });
    });
    //	console.log(this.socket.id)

    this.socket.on('TEST_CONNECTION', (msg: any) => {
      // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
      this.socketStatusBeforeCheck = msg.status;
      //	console.log(this.socketStatusBeforeCheck);
    });
    this.socket.on('questionnaire', (questionnaireMsg: any) => {
      // console.log("TEST_CONNECTION : ",msg,$rootScope.userActiveStatus,msg.status);
      //	console.log(questionnaireMsg);
    });
  }

  fetchUser(phone) {
    this.API.getWidgetUserByPhone(phone, this.api_end_point).subscribe(
      (data: any) => {
        let _response = data;
        if (_response && _response.success) {
          var updateObj = this.callStatus;

          if (!this.widgetUser) {
            this.widgetUser = _response.success;
            updateObj.widgetUser = this.widgetUser;
            //console.log("dd-debug31", updateObj)
            this.myservice.updateCallStatus(updateObj);
          } else if (
            _response.success.external_url != this.widgetUser.external_url
          ) {
            this.counter = 0;
            this.widgetUser.external_url = _response.success.external_url;
            updateObj.widgetUser = this.widgetUser;
            //console.log("dd-debug32", updateObj)
            this.myservice.updateCallStatus(updateObj);
          }
        } else {
        }
      }
    );
  }

  onInvite(invitation) {
    //	console.log("call came")
    invitation.accept();
  }

  getTimerCountsData() {
    let start_date = this.utils.localToUTC(
      moment().subtract(0, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      'YYYY-MM-DDTHH:mm:ss',
      'YYYY-MM-DDTHH:mm:ss'
    );
    let end_date = this.utils.localToUTC(
      moment().subtract(0, 'days').endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      'YYYY-MM-DDTHH:mm:ss',
      'YYYY-MM-DDTHH:mm:ss'
    );
    const userid = String(this.user.id);
    let timer_count_obj: any = {
      start_date: start_date,
      end_date: end_date,
      users_list: [this.user.id],
    };

    this.API.getGenericTimersData(
      timer_count_obj,
      this.api_end_point
    ).subscribe((res: any) => {
      console.log('printing res', res);

      if (res && res.success && res.success[userid]) {
        let _talktime = res.success[userid]['activity']['spoken_time'];
        let _logintime = res.success[userid]['activity']['login_time'];
        let _breaktime = res.success[userid]['activity']['break_time'];
        let _waittime = res.success[userid]['activity']['wait_time'];
        let _activetime = res.success[userid]['activity']['active_time'];
        this._logintime = this.utils.convertSectoHMS(_logintime);
        this._talktime = this.utils.convertSectoHMS(_talktime);
        this._breaktime = this.utils.convertSectoHMS(_breaktime);
        this._activetime = this.utils.convertSectoHMS(_activetime);
        this._waittime = this.utils.convertSectoHMS(_waittime);
      }
    });
  }

  getHomeData() {
    let _today: any = new Date();
    let _startDate = new Date(
      _today.setDate(_today.getDate() - 30)
    ).toISOString();
    let _endDate = new Date(
      _today.setDate(_today.getDate() + 30)
    ).toISOString();
    let today1 = moment().subtract(0, 'days').startOf('day').toString();
    let today2 = moment().subtract(0, 'days').endOf('day').toString();
    let userid = this.user.id;
    let obj = {
      userid: userid,
      start_time: this.fromDate,
      end_time: this.toDate,
      agents_list: userid,
    };
    if (this.fromDate == undefined && this.toDate == undefined) {
      obj.start_time = moment(today1).format('YYYY-MM-DD HH:mm:ss');
      obj.end_time = moment(today2).format('YYYY-MM-DD HH:mm:ss');
    }
    this.API.agentPluginHome(obj, this.api_end_point).subscribe((data: any) => {
      let _response = data;
      if (_response) {
        //console.log(_response);
        let _talktime = _response.talk_time;
        let _logintime = _response.loggedin_time;
        let _breaktime = _response.break_time;
        let _activetime = _response.active_time;
        let _totalcalls = _response.total_calls;
        console.log('agentPlugin', JSON.stringify(_response.list));
        //------filledSource----
        this.filledSource = _response.list;
        //	console.log(_response.list.length);
        this.countoffilleddata = _response.list.length;
        //this.filledSource.paginator = this.tableTwoPaginator;
        this._auditScore = _response.audited_score;
        // this._talktime = _talktime.split(" ");
        //console.log(this._talktime);
        // this._logintime = _logintime.split(" ");
        //console.log(this._logintime);
        // this._breaktime = _breaktime.split(" ");;
        // this._activetime = _activetime.split(" ");
        //this._totalcalls;
      }
    });
    this.getTimerCountsData();
  }
  getDashboardList() {
    if (this.fromDate != '' && this.toDate != '') {
      let _today: any = new Date();
      let today1 = moment().subtract(0, 'days').startOf('day').toString();
      let today2 = moment().subtract(0, 'days').endOf('day').toString();
      //	console.log("--daybeforeyesterday---" + today1 + "--" + today2);
      // this.fromDate = moment(today1).format('YYYY-MM-DD HH:mm:ss');
      // this.toDate = moment(today2).format('YYYY-MM-DD HH:mm:ss');
      //console.log("--daybeforeyesterday-1---" + this.fromDate + "--" + this.toDate);
      let userid = this.user.id;
      let obj = {
        userid: userid,
        start_time: this.fromDate,
        end_time: this.toDate,
        skip: 0,
        calendar_type: 'smartercalendar',
        top: 100,
      };
      if (this.fromDate == undefined && this.toDate == undefined) {
        obj.start_time = moment(today1).format('YYYY-MM-DD HH:mm:ss');
        obj.end_time = moment(today2).format('YYYY-MM-DD HH:mm:ss');
      }
      this.API.agentPluginDashboardList(obj, this.api_end_point).subscribe(
        (data: any) => {
          //debugger;
          let _response = data.event;
          //console.log("null", _response);
          //console.log("length", _response.length);
          // let _aa = _response.filter(v => {
          //   console.log("--", v.stage, "--", v.status);
          //   return (v.stage == "FOLLOW-UP" && v.status == "FOLLOW-UP")
          //   //return (v.responsestatus == "accepted" && v.subject== "CALLBACK CUSTOMER")
          // })
          let _aa = _response.filter((v) => {
            return v.responsestatus == 'accepted' && v.flpcount[0] > 0;
          });
          //console.log(_aa.length);
          // console.log(JSON.stringify(_aa));
          let unique_data = _aa.filter(
            (thing, index, self) =>
              index === self.findIndex((t) => t.id === thing.id)
          );
          this._countfollowUpLength = _aa.length;
          this.followupSource = new MatTableDataSource(_aa);
          this.followupSource.paginator = this.tableThreePaginator;
          if (this.tableThreePaginator) {
            this.tableThreePaginator.length = this.followupSource.data.length;
          }
        }
      );
    }
  }

  followupData() {
    localStorage.setItem('sessionStarted', 'false');
    sessionStorage.setItem('sessionStarted', 'false');
    this.sessionStarted = false;
    this._flagStartStop = 'StartFlag';
    this.showHideStartPage = true;
    this.showHideDiallerPage = false;
    let obj = {
      user_id: this.user.id,
    } as any;
    this.API.getContactsWhtasapp(obj, this.api_end_point).subscribe(
      (res: any) => {
        this._contactsAPIResponseStorage = res;
        //debugger;
        if (res.success) {
          let ELEMENT_DATA = res.success;
          console.log(ELEMENT_DATA[0]);

          let rows = [];
          ELEMENT_DATA.forEach((element) => {
            let row1 = element.customer_details;
            row1.forEach((e) => rows.push(e));
          });

          let _today: any = new Date();
          let _startDate = moment()
            .subtract(0, 'days')
            .startOf('day')
            .toString();
          let _endDate = moment().subtract(0, 'days').endOf('day').toString();
          // debugger;
          //if (this.selectedFilter == "TODAY") {
          let fromDate1 = moment(_startDate).format('YYYY-MM-DD HH:mm:ss');
          let toDate1 = moment(_endDate).format('YYYY-MM-DD HH:mm:ss');
          //console.log("Today :::: ",this.fromDate)
          //console.log("Today :::: ",this.toDate)
          //console.log("--today---" + this.fromDate + "--" + this.toDate);
          //this.followupdatatable();
          //this.followupDataForTableData();
          //}

          let DATA = rows;

          let cus_details = DATA;
          let filtered_data1 = cus_details.filter((v) => {
            //debugger
            if (v.stage == 'FOLLOW-UP') {
              if (v.stage == 'FOLLOW-UP') {
                // let start_date = v.start_date;
                let date1 = moment().format('YYYY-MM-DDTHH:mm:ss');
                let date2 = moment(v.start_date, 'YYYY-MM-DDTHH:mm:ss');
                console.log(date1, date2);
                console.log(date2.diff(date1, 'days'));
                var days = date2.diff(date1, 'days');

                //Date format changes for contacts API by praveen
                let date = moment(v.start_date).format('YYYY-MM-DD');
                //let time = String(campaign.controls.time.value + ':00');
                //let full_time = String(date).concat('T', time.toString()).toString();
                //console.log("time :: ",time);
                //console.log("final time :: ",moment(String(date).concat('T', time.toString()).toString()+" current time : "+ moment().format('YYYY-MM-DDTHH:mm')));
                //console.log("Followup data ::: ",v);
                //console.log("Followup data ::: ",moment(String(date).toString()), moment().format('YYYY-MM-DDTHH:mm'));
                console.log(
                  'Followup data ::: ',
                  moment(String(fromDate1).toString()),
                  ' ::::: ',
                  moment(String(toDate1).toString())
                );
                //if(moment(String(date).toString()).isBefore(moment().format('YYYY-MM-DDTHH:mm'))){
                if (moment(v.start_date).isBetween(fromDate1, toDate1)) {
                  return v;
                }
              }
            }
          });


          let filtered_data1_followupCount = cus_details.filter((v) => {
            //debugger
            //if ((v.stage == "FOLLOW-UP" && v.flpcount == 1 && v.responsestatus != "completed") || (v.rnrcount > 0 && v.responsestatus == "accepted")) {
              if (v.stage == "FOLLOW-UP" && v.responsestatus == "accepted") {
                 //let start_date_convert = moment.utc(v.start_date);
                let date1 = moment().format('YYYY-MM-DDTHH:mm:ss');
                let date2 = moment(v.start_date, 'YYYY-MM-DDTHH:mm:ss');
                // console.log(date1, date2);
                // console.log(date2.diff(date1, "days"));
                var days = date2.diff(date1, 'days')

                //Date format changes for contacts API by praveen
                let date = moment(v.start_date).format('YYYY-MM-DDTHH:mm:ss');
                //let time = String(campaign.controls.time.value + ':00');
                //let full_time = String(date).concat('T', time.toString()).toString();
                //console.log("time :: ",time);
                //console.log("final time :: ",moment(String(date).concat('T', time.toString()).toString()+" current time : "+ moment().format('YYYY-MM-DDTHH:mm')));
            
                if(moment(String(date).toString()).isBefore(moment().format('YYYY-MM-DDTHH:mm'))){
                  return (v);
                }else{
                  return null;
                }

                // if (days < 0) {
                //   return (v);
                // } else if (days == 0) {
                //   let a_time = moment(v.start_date).format('HH:mm');
                //   let b_time = moment().format('HH:mm');
                //   let a_seconds = moment.duration(a_time).asSeconds();
                //   let b_seconds = moment.duration(b_time).asSeconds();
                //   console.log(a_seconds, '\n', b_seconds);

                //   return (a_seconds < b_seconds ? v : null)
                // }
                // else {
                // }
              }
            //}
          })


          this._countfollowUpLengthForPD = filtered_data1_followupCount.length;
          console.log('Followup data ::: ', filtered_data1);
          this._countfollowUpLength = filtered_data1.length;
          this.followupSource = new MatTableDataSource(filtered_data1);
          this.followupSource.paginator = this.tableThreePaginator;
          if (this.tableThreePaginator) {
            this.tableThreePaginator.length = this.followupSource.data.length;
          }
        } else if (res.errors) {
        }
      }
    );
  }
  getAgentSlot() {
    let userid = this.user.id;
    let obj = {
      userid: userid,
    };
    this.API.agentPluginGetAgentSlot(obj, this.api_end_point).subscribe(
      (data: any) => {
        let _response = data.success.data;
        if (_response) {
          for (let day of _response) {
            for (let slot of day.rows) {
              if (slot.status == 'Available' && !this._availableSlots) {
                this._availableSlots = day.date + ' ' + slot.time;
              }
              if (slot.status == 'Booked' && !this._nextBooked) {
                this._nextBooked = day.date + ' ' + slot.time;
              }
            }
          }

          let _workSlotArray = _response;
        }
      }
    );
  }
  getContacted() {
    // debugger;
    if (this.leadsdatefrom != '' && this.leadsdateto != '') {
      let _today: any = new Date();
      let today1 = moment().subtract(0, 'days').startOf('day').toString();
      let today2 = moment().subtract(0, 'days').endOf('day').toString();
      //console.log("--daybeforeyesterday---" + today1 + "--" + today2);
      this.leadsdatefrom = moment(today1).format('YYYY-MM-DD HH:mm:ss');
      this.leadsdateto = moment(today2).format('YYYY-MM-DD HH:mm:ss');
      //console.log("--daybeforeyesterday-1---" + this.leadsdatefrom + "--" + this.leadsdateto);
      let userid = this.user.id;
      let obj = {
        userid: userid,
        start_time: this.leadsdatefrom,
        end_time: this.leadsdateto,
      };
      this.API.agentPluginContacted(obj, this.api_end_point).subscribe(
        (data: any) => {
          //debugger;
          let _response = _.cloneDeep(data.success);
          if (_response) {
            let ELEMENT_DATA = _.cloneDeep(_response);
            // console.log('element data', JSON.stringify(ELEMENT_DATA))
            let _contactedData = ELEMENT_DATA.filter((v) => {
              return v.event_type == 'call';
            });

            this.dataSource = new MatTableDataSource(_contactedData.reverse());
            this.dataSource.paginator = this.tableOnePaginator;

            this._contactedData = _contactedData.length;

            this._countLeadLength = this.API.leadsConnected(
              _contactedData.length
            );
            this.refreshLeadsFulfillmentData(_response);
          }
        }
      );
    }
  }
  refreshLeadsFulfillmentData(_response) {
    let finalFulfillmentData = [];
    let _leadFulfillmentData = _response.filter((v) => {
      return v.stage == 'FULL-FILLED' || v.stage == 'NOT-COMPLETED';
    });
    // console.log('fulfillment data', JSON.stringify(_leadFulfillmentData));
    _leadFulfillmentData = _.groupBy(_leadFulfillmentData, 'number');
    // console.log('fulfillment data', _leadFulfillmentData);

    for (var i in _leadFulfillmentData) {
      finalFulfillmentData.push(_leadFulfillmentData[i][0]);
    }

    this.leadFulfillmentSource = new MatTableDataSource(
      finalFulfillmentData.reverse()
    );
    this.leadFulfillmentSource.paginator = this.tableFourPaginator;
  }
  getNotification() {
    let userid = this.user.id;
    let obj = {
      userid: userid,
    };
    this.API.getDesktopNotification(obj, this.api_end_point).subscribe(
      (data: any) => {
        //console.log("Notification-- ", data);
        let _response = data.success;
        //console.log("length", _response.length);
        let _aa = _response.filter((v) => {
          return v.status == 'UNREAD';
        });
        //console.log(_aa.length);
        this.notificationcount = _aa.length;
        this.unreadnotification = _response;
        this.notifications = _response;
      }
    );
  }
  btnNotification() {
    this.notifications = this.unreadnotification.filter((v) => {
      return v.status == 'UNREAD';
    });
    this.notificationcount = 0;
  }
  startCallDial() {
    // const dialogRef = this.dialog.open(StartDialDialogComponent, {
    //   width: '30%',
    //   height: '90%',
    //   position: { right: '20px', bottom: '0' },
    //   data: ''
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
    // this.myservice.updateCallStatus({dialing: true});
  }
  counter: any = 0;
  transformUrl(url: string) {
    // if(this.counter==0){
    //   this.counter++;
    // console.log(this._sanitizer.bypassSecurityTrustResourceUrl(url))
    // return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    // }
  }

  startSession() {
    // debugger;
    sessionStorage.removeItem('setInStartForTruePredectiveON');
    const callSession1 = this.myservice.getLastCallSession();

	console.log('desktop-calling-new startSession', callSession1);

    if (
      callSession1 &&
      (callSession1.internet_speed == 0 ||
        callSession1.internet_speed == undefined)
    ) {
      alert('Please check your network or wifi connection.');
      return;
    }
    //start for allowinbound on off in if else condition
    let stateSettings1: any = this.myservice.get_stage_settings();
    let _checkAllowinBound = stateSettings1.extraoptions.filter((v) => {
      return v.name == 'allowinbound';
    });
    if (
      _checkAllowinBound &&
      _checkAllowinBound.length > 0 &&
      _checkAllowinBound[0].definition == 'on'
    ) {
      // alert();
      this.showHideStopPageForAllowinBound = true;
      this.showHideStartPage = false;
      this.inbound_button_status = true;
      this.sessionStarted = true;
    } else {
      this.showHideStopPageForAllowinBound = false;
      this.showHideStartPage = true;
      if (this._projectTypeSBICC == 'SBICC') {
        if (
          this.setPredictiveOnOff.length == 0 ||
          (this.setPredictiveOnOff &&
            this.setPredictiveOnOff.length > 0 &&
            this.setPredictiveOnOff[0].definition == 'off')
        ) {
          sessionStorage.setItem('nextPreviewDialingPopup', 'true');
        } else {
          sessionStorage.setItem('setInStartForTruePredectiveON', 'true');
          console.log("_countfollowUpLengthForPD ::: ",this._countfollowUpLengthForPD);
          if(this._countfollowUpLengthForPD > 0){
            sessionStorage.setItem('setInStartForTruePredectiveON', 'false');
            sessionStorage.setItem('nextPreviewDialingPopup', 'true');
          }else{
            sessionStorage.removeItem('nextPreviewDialingPopup');
            sessionStorage.setItem('setInStartForTruePredectiveON', 'true');
          }
          
        }

        this.check_dialler_setting();

        localStorage.removeItem('c2cCallPresent');
        localStorage.removeItem('noDialPad');
        if (document.getElementById('codeDialPad') as HTMLFormElement) {
          (document.getElementById('codeDialPad') as HTMLFormElement).value =
            '';
        }
        var updateObjSession = this.myservice.getLastCallSession();

        // this._snackBar.open("No data found", 'close', {
        //   duration: 3000,
        // });

        // setTimeout(() => {
        //   this.showHideStartPage = true;
        // }, 3000);
        this.showHideStartPage = true;
        //dialler enbale setting
        this.showHideStopPage = true;
        this.showHideDailpad = false;
        this.showHideCallStatus = false;
        this.showHideCalldialling = false;
        this.showHideCallEndingScreen = false;

        updateObjSession.sessionStarted = true;
        this.callSession = updateObjSession;
        this.myservice.updateCallSession(updateObjSession);
        // sessionStorage.setItem('sessionStarted', 'true');
        console.log('---11---');
        this.showHideDailpad = false;
        this.showHideCallStatus = false;
        this.showHideCalldialling = false;
        this.showHideCallEndingScreen = false;
        this.check_dialler_setting();
        this.showHideStartPage = true;
        this.myservice.DESKTOP_CALL_BUTTON_STATUS.next(1);
      } else {
        //this.isStageDial= false;

        localStorage.removeItem('c2cCallPresent');
        localStorage.removeItem('noDialPad');
        if (document.getElementById('codeDialPad') as HTMLFormElement) {
          (document.getElementById('codeDialPad') as HTMLFormElement).value =
            '';
        }
        var updateObjSession = this.myservice.getLastCallSession();
        // if (updateObjSession && (!updateObjSession.internet_speed || updateObjSession.internet_speed < 10)) {
        // 	alert("Please check your Wifi connection / Internet speed slow ,try later");
        // 	return;
        // }
        // if (updateObjSession && !updateObjSession.isAppAgent && updateObjSession.sip_conn != true) {
        // 	alert("Sip connection not available try later")
        // 	return;
        // }
        if (this._findteleco[0].definition == 'KNOWLARITY') {
          if (
            updateObjSession &&
            !updateObjSession.isAppAgent &&
            updateObjSession.sip_conn != true
          ) {
            alert('Sip connection not available try later');
            return;
          }
        } else if (this._findteleco[0].definition == 'Twilio') {
        } else if (this._findteleco[0].definition == 'TCL') {
        } else if (this._findteleco[0].definition == 'TTS') {
        }
        updateObjSession.sessionStarted = true;
        this.callSession = updateObjSession;
        this.myservice.updateCallSession(updateObjSession);
        //sessionStorage.setItem('sessionStarted', 'true');
        console.log('---11---');
        // this.showHideStopPage = true;
        // this.showHideStartPage = false;
        this.showHideStopPage = false;
        this.showHideStartPage = true;

        this.showHideDailpad = false;
        this.showHideCallStatus = false;
        this.showHideCalldialling = false;
        this.showHideCallEndingScreen = false;

        this.check_dialler_setting();
      }
      if (document.getElementById('code') as HTMLFormElement) {
        let _num = (document.getElementById('code') as HTMLFormElement).value;
        _num = _num.substring(0, _num.length - 1);
        (document.getElementById('code') as HTMLFormElement).value = _num;
      }
      this.myservice.clearCallStatus();
      this.showHideStartCallButton = true;
      this.showHideCallPad = false;
      localStorage.removeItem('noDialPad');
      console.log("_countfollowUpLength 1 ::: ",this._countfollowUpLengthForPD);
      if (this.setPredictiveOnOff[0].definition == 'on' && (this._countfollowUpLengthForPD == 0 || this._countfollowUpLengthForPD == undefined)){
        console.log("_countfollowUpLength 2 ::: ");
		console.log('Starting RemoteDialStart',this.isTruepredicitive);

        this.API.remoteDialStart(this.user.id, this.api_end_point).subscribe(
          (res: any) => {
            //	console.log(res)
            this.sessionStarted = true;
            //debugger;
            if (this.isTruepredicitive == true) {
              let _data1 = res;
              this.sessionStarted = true;
            }
            if (this.isStayAtHome) {
              //this.showHidePopup=false;
              localStorage.removeItem('noExitC2CInStartBtn');
            } else {
              //this.showHidePopup=true;
              console.log('---12---');
              //this.showHideStopPage = true;
              //this.showHideStartPage = false;
              this.showHideStopPage = false;
              this.showHideStartPage = true;
              localStorage.removeItem('noDialPad');
              localStorage.setItem('noDialPad', 'NoDial');
  
              localStorage.removeItem('noExitC2CInStartBtn');
              localStorage.setItem('noExitC2CInStartBtn', 'c2cNotThere');
			  this.startCallLoading();
            //   setTimeout(() => {
            //     this.startCallLoading();
            //   }, 100);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
      
      this.getHomeData();
    }
  }

  endSession() {
    //debugger;
    let updateObjSession = this.myservice.getLastCallSession();

    this.showHideDiallerPage = false;
    localStorage.removeItem('c2cCallPresent');
    this.check_dialler_setting();
    this.showHideStopPage = true;
    this.showHideStartPage = true;
    this.showHideDailpad = false;
    this.showHideCallStatus = false;
    this.showHideCalldialling = false;
    this.showHideCallEndingScreen = false;
    if (document.getElementById('code') as HTMLFormElement) {
      let _num = (document.getElementById('code') as HTMLFormElement).value;
      _num = _num.substring(0, _num.length - 1);
      (document.getElementById('code') as HTMLFormElement).value = _num;
    }

    this.myservice.clearCallStatus();
    this.showHideStartCallButton = true;
    this.showHideCallPad = false;

    if (updateObjSession && updateObjSession.internet_speed == 0) {
      this.sessionStarted = false;
      this.showHideStartPage = true;
      //this.showHideStopPage = true;

      sessionStorage.setItem('sessionStarted', 'false');
      if (document.getElementById('codeDialPad') as HTMLFormElement) {
        (document.getElementById('codeDialPad') as HTMLFormElement).value = '';
      }

      return;
    }
    this.API.settingsAPI(this.api_end_point).subscribe((data: any) => {
      //console.log(data);
      //console.log(data.success.extraoptions);
      let setting = data.success.extraoptions;
      let stopsetting = setting.filter((v) => {
        // console.log(v);
        return v.name == 'remoteautodialling';
      });
      //	console.log(stopsetting[0].definition);
      if (stopsetting[0].definition == 'onsolicit') {
        if (this.stopDialogCounter == 1) {
          this.stopDialogCounter++;
          this.endSessionCall();
        }
      } else {
        this.API.remoteDialStop(
          this.user.id,
          this.api_end_point,
          this.breakoption
        ).subscribe(
          (res: any) => {
            //console.log(res)
            this.sessionStarted = false;
            // this.removeNum();
            this.myservice.clearCallStatus();
            var updateObjSession = this.myservice.getLastCallSession();
            updateObjSession.sessionStarted = false;
            this.callSession = updateObjSession;
            this.myservice.updateCallSession(updateObjSession);
            sessionStorage.setItem('sessionStarted', 'false');
            this.showHideStartCallButton = true;
            this.isStageDial = true;
          },
          (err) => {
            console.log(err);
          }
        );
      }
    });
  }

  getParsed(data) {
    return JSON.parse(data.replace(/'/g, '"'));
  }

  checkNextTrigger(event, nextAction) {
    //console.log(event, nextAction);
    if (nextAction && nextAction.post_url) {
      const currentTime = moment().utc();
      var salesSts = {
        user_id: this.user.id,
        contact_person: {
          number: this.customerInfo.customer_number,
        },
        description: '',
        order_potential: '',
        status: 'NEW DATA',
        lat: '22.0858725',
        long: '82.1879959',
        start_time: this.callStatus.callStart || currentTime,
        substatus1: '',
        substatus2: '',
        machine_status: '',
        wrapup: 5000,
        lead_source: '',
        appointment_id: '',
        call_end_time: this.callStatus.callEnd || currentTime,
        customkvs: '',
      } as any;
      if (salesSts.start_time != salesSts.call_end_time) {
        salesSts.wrapup = salesSts.call_end_time - salesSts.start_time;
      }
      if (this.callFlowJsonSections) {
        //console.log(this.callFlowJsonSections)
        var whatsAppNo;
        var QA = [];
        for (let section of this.callFlowJsonSections) {
          var obj1: any = [];
          for (let child of section.child) {
            var temp1: any = { 'Q:': child.q, 'A:': child.answer || '' };
            if (child.at == 'FN') {
              whatsAppNo = child.answer;
              temp1['A:'] = ('' + whatsAppNo).includes('+91')
                ? '' + whatsAppNo
                : '+91' + whatsAppNo;
            }
            if (child.at == 'DATE' && child.answer) {
              temp1['A:'] = moment(child.answer).format('YYYY-MM-DD');
            }
            if (child.action_type) {
              temp1['action_type:'] = child.action_type;
            }

            obj1.push(JSON.stringify(temp1));
            if (child[child.answer]) {
              for (let child2 of child[child.answer]) {
                var temp2: any = { 'Q:': child2.q, 'A:': child2.answer || '' };
                if (child2.action_type) {
                  temp2['action_type:'] = child2.action_type;
                }
                if (child2.at == 'DATE' && child2.answer) {
                  temp2['A:'] = moment(child2.answer).format('YYYY-MM-DD');
                }
                obj1.push(JSON.stringify(temp2));
                if (child2[child2.answer]) {
                  for (let child3 of child2[child2.answer]) {
                    var temp3: any = {
                      'Q:': child3.q,
                      'A:': child3.answer || '',
                    };
                    if (child3.action_type) {
                      temp3['action_type:'] = child3.action_type;
                    }
                    if (child3.at == 'DATE' && child3.answer) {
                      temp3['A:'] = moment(child3.answer).format('YYYY-MM-DD');
                    }
                    obj1.push(JSON.stringify(temp3));
                  }

                  obj1.push(JSON.stringify(temp3));
                }
              }
            }
          }
          QA = obj1;
        }
        var finalObj = {
          experience: QA,
        };
        salesSts.contact_person.number = ('' + whatsAppNo).includes('+91')
          ? '' + whatsAppNo
          : '+91' + whatsAppNo;
        salesSts.extranotes = JSON.stringify(finalObj);
      }

      this.API.customActionTrigger(
        salesSts,
        this.user.server_url,
        nextAction.post_url
      ).subscribe(
        (res: any) => {},
        (err) => {
          console.log(err);
        }
      );
    }
  }
  backtostartbutton() {
    this.showHideCallingScreen = false;
    this.showHideStartPage = true;
    this.showHideDailpad = false;
    this.showHideDailpad = false;
    this.showHideCallStatus = false;
    this.showHideCalldialling = false;
    this.showHideCallEndingScreen = false;
    this.showHideStopPage = false;
  }

  start() {
    //this.showHideCallingScreen=true;
    console.log('---13---');
    this.showHideStopPage = true;
    this.showHideStartPage = false;
    this.showHideDailpad = false;
    this.showHideDailpad = false;
    this.showHideCallStatus = false;
    this.showHideCalldialling = false;
    this.showHideCallEndingScreen = false;

    /*this.API.remoteDialStart(this.user.id, this.user.server_url).subscribe(
        (res: any) => {
          console.log(res)
          this.sessionStarted = true;
        this.showHideStartPage = true;
        }, (err) => {
          console.log(err)
        }
      )*/
  }

  toggle3() {
    this.showHideCalldialling = false;
    this.showHideCallStatus = true;
    this.showHideStopPage = false;
  }

  rejectcall() {
    this.showHideStartPage = false;
    this.showHideDailpad = false;
    this.showHideDailpad = true;
    this.showHideCallingScreen = false;
    this.showHideCallStatus = false;
    this.showHideCalldialling = false;
    this.showHideCallEndingScreen = false;
    this.showHideStopPage = false;
  }

  toggle5() {
    this.showHideCallStatus = false;
    this.showHideCallEndingScreen = true;
    this.showHideStopPage = false;
  }

  toggle6() {
    this.showHideCallEndingScreen = false;
    this.showHideStartPage = true;
    this.showHideStopPage = false;
  }

  startcall() {
    this.showHideCalldialling = true;
    this.showHideCallingScreen = false;
    this.showHideStopPage = false;
    this.showHideStartPage = false;
    this.showHideDailpad = false;
    this.showHideDailpad = false;
    this.showHideCallStatus = false;

    this.showHideCallEndingScreen = false;
  }
  answercall() {
    this.showHideCalldialling = true;
    this.showHideStartPage = false;
    this.showHideDailpad = false;
    this.showHideDailpad = false;
    this.showHideCallingScreen = false;
    this.showHideCallStatus = false;
    this.showHideCallEndingScreen = false;
    this.showHideStopPage = false;
    this.running = !this.running;
    if (this.running) {
      this.startText = 'Stop';
      const startTime = Date.now() - (this.counter || 0);
      this.timerRef = setInterval(() => {
        this.counter = Date.now() - startTime;
        this.timer = moment.utc(this.counter).format('mm:ss');
      });
    }
  }

  toggle8() {
    this.showHideStartPage = true;
    this.showHideCallEndingScreen = false;
    this.showHideStopPage = false;
  }

  startTimer() {
    this.running = !this.running;
    //console.log("startTimer", this.running)
    if (true) {
      clearInterval(this.timerRef);
      this.timer = '';
      this.counter1 = 0;
      this.startText = 'Stop';
      const startTime = Date.now() - (this.counter1 || 0);
      //	console.log("startTimer2", startTime)

      this.timerRef = setInterval(() => {
        this.counter1 = Date.now() - startTime;
        //	console.log("startTimer2", startTime)
        this.timer = moment.utc(this.counter1).format('mm:ss');
        //console.log(this.timer);
      }, 1000);
    } else {
      this.startText = 'Resume';
      clearInterval(this.timerRef);
    }
  }

  clearTimer() {
    this.running = false;
    this.startText = 'Start';
    this.counter = undefined;
    clearInterval(this.timerRef);
  }

  clearDuringCallForm() {
    let updateObj = this.callStatus || {};
    let stateSettings: any = this.myservice.get_stage_settings();
    if (stateSettings && stateSettings.extraoptions) {
      for (let opt of stateSettings.extraoptions) {
        if (opt.name == 'questionnaire') {
          let questions = JSON.parse(opt.definition);
          this.questionnaire = questions;
          this.callFlowJsonSections = [
            {
              title: '',
              description: '',
              mandatory: 'n',
              child: this.questionnaire,
            },
          ];
          // var updateObj = this.callStatus || {};
          updateObj.callFlowJsonSections = this.callFlowJsonSections;
          // this.myservice.updateCallStatus(updateObj);
          //console.log(78, this.questionnaire);
        }
      }
    }
  }


  restFm() {
    // this.showHide_Form_before_DuringForm = true;
    // this.showHide_Form_After_DuringForm = false;
    this.rnrDescription = '';
    this.selectedRnrReason = '';
    this.timeBtnValue = '';
    this.timeBtnValueTransformed = '';
    this.timeModel = '';
    this.dateModel = '';
    this.disposition = '';
  }
  duringFormSubmit() {
    //console.log(this.callFlowJsonSections)
    var acceptSubmit = true;
    for (let section of this.callFlowJsonSections) {
      for (let child of section.child) {
        if (
          child.mandatory == 'y' &&
          (child.answer == undefined || child.answer == '')
        ) {
          acceptSubmit = false;
        }

        if (child[child.answer]) {
          for (let child2 of child[child.answer]) {
            if (
              child2.mandatory == 'y' &&
              (child2.answer == undefined || child2.answer == '')
            ) {
              acceptSubmit = false;
            }
            if (child2[child2.answer]) {
              for (let child3 of child2[child2.answer]) {
                if (
                  child3.mandatory == 'y' &&
                  (child3.answer == undefined || child3.answer == '')
                ) {
                  acceptSubmit = false;
                }
              }
            }
          }
        }
      }
    }

    if (acceptSubmit == false) {
      this.showDuringFormError = true;
      setTimeout(() => {
        this.showDuringFormError = false;
      }, 3000);
      return;
    }

    this.showHide_Form_before_DuringForm = false;
    this.showHide_Form_After_DuringForm = true;
    var updateObj = this.callStatus;
    delete updateObj.showDuringCallForm;
    //console.log("dd-debug33", updateObj)
    this.myservice.updateCallStatus(updateObj);
  }
  formSubmitBtn() {
    this.showHide_Form_before_DuringForm = false;
    this.showHide_Form_After_DuringForm = false;
    // console.log(this.dateModel);
    // console.log(this.timeModel);

    var date = moment(this.dateModel).format('DD/MM/YYYY');
    console.log(date);
    var Time1 = moment(this.timeModel).format('HH:mm:ss');
    //console.log(Time1);

    // conversion
    var dateTime = moment(
      date + ' ' + this.timeModel,
      'DD/MM/YYYY HH:mm'
    ).format('YYYY-MM-DDTHH:mm:ss.000Z');
    //console.log(dateTime);
    this.callbacktime = dateTime;

    //console.log(this.callbacktime);
  }
  clickHighlight(type: any) {
    this.showHideUpdate = false;
    this.showHideCallBack1 = false;
    this.showHideCallBack2 = false;
    this.showHideCallRNR = false;
    this.typeTab = type;
    if (type == 'update') {
      this.showHideUpdate = true;
    }
    if (type == 'callback1') {
      this.showHideCallBack1 = true;
    }
    if (type == 'callback2') {
      this.showHideCallBack2 = true;
    }
    if (type == 'rnr') {
      this.showHideCallRNR = true;

      var obj1 = { Q: 'Reason for RNR', A: this.selectedRnrReason };
      var obj2 = { Q: 'Description for RNR', A: this.rnrDescription };
      var finalObj = [];
      finalObj.push(JSON.stringify(obj1));
      finalObj.push(JSON.stringify(obj2));
      this.salesSts.extranotes = JSON.stringify(finalObj);
    }
  }
  isActive(tabType: any): boolean {
    return this.typeTab === tabType;
  }

  clickTimeHighlight(timeVal: any) {
    let now = moment().format('YYYY-MM-DD HH:mm:ss');
    //console.log(now);
    this.timeBtnValue = timeVal;
    //console.log(timeVal);
    this.timeBtnValueTransformed = moment()
      .add(timeVal, 'minute')
      .format('YYYY-MM-DD HH:mm:ss');
    moment.utc().format();
    //console.log(this.timeBtnValueTransformed);
  }
  isTimeActive(timeVal: any): boolean {
    return this.timeBtnValue === timeVal;
  }

  getCustomerInfo() {
    const transactionid = this.callStatus.transactionid
      ? this.callStatus.transactionid
      : 'XXXXYYYY';
    this.API.getInfoCustomer(
      this.user.server_url,
      this.myUser.id,
      this.customerInfo.customer_number,
      transactionid
    ).subscribe((data: any) => {
      let _response = data;
      if (
        _response &&
        _response.success &&
        _response.success != 'No Connected Customer' &&
        _response.success != 'Not a valid connected customer' &&
        _response.info
      ) {
        this.showHideConnecting = false;
        this.showHideCalldialling = false;
        var updateObj = this.callStatus;

        this.customerInfo = {
          lead_source: _response.info.lead_source,
          status: _response.info.lead_status,
          customer_number: _response.info.number,
          name: _response.info.name,
          customer_name: _response.info.name,
        };
        updateObj.customerInfo = this.customerInfo;
        updateObj.showDuringCallForm = true;
        this.customerlead_source = this.customerInfo.lead_source;

        this.customerstatus = this.customerInfo.status;

        if (!this.widgetUser) {
          this.widgetUser = {
            lead_source: _response.info.lead_source,
            status: _response.info.lead_status,
            customer_number: _response.info.number,
            name: _response.info.name,
            customer_name: _response.info.name,
          };
          updateObj.widgetUser = this.widgetUser;
          //console.log("dd-debug34", updateObj)
          this.myservice.updateCallStatus(updateObj);
        }
        var interval_id = window.setInterval(() => {}, 99999);
        // for (var i = 0; i < interval_id; i++) {
        // 	window.clearInterval(i);
        // }
        // this.customerlead_source = this.customerInfo.lead_source;

        this.customerstatus = this.customerInfo.status;
        if (!this.isAppAgent && updateObj.dialNum == undefined) {
          this.fetchUser(this.customerInfo.customer_number);
        }
      } else if (_response.success == 'No Connected Customer') {
        setTimeout(() => {
          this._snackBar.open('No Connected Customer', 'close', {
            duration: 5000,
          });
        }, 2000);
      }
    });
  }

  endSessionCall() {
    //debugger;
    //console.log(this.myUser);
    var dialogRef = this.dialog.open(EndSessionDialogComponent, {
      width: '40%',
      disableClose: true,
      data: this.myUser,
    });
    dialogRef.afterClosed().subscribe((result) => {
      //debugger;
      //console.log(result);
      this.getHomeData();
      if (result == 'true') {
        this.sessionStarted = false;
        // this.removeNum();
        this.myservice.clearCallStatus();
        var updateObjSession = this.myservice.getLastCallSession();
        updateObjSession.sessionStarted = false;
        this.callSession = updateObjSession;
        this.myservice.updateCallSession(updateObjSession);
        sessionStorage.setItem('sessionStarted', 'false');
        this.showHideStopPage = false;
        this.showHideStartPage = true;
        this.showHideDailpad = false;
        this.showHideCallStatus = false;
        this.showHideCalldialling = false;
        this.stopDialogCounter = 1;
        this.showHideDiallerPage = false;
        this.check_dialler_setting();
        localStorage.removeItem('noExitC2CInStartBtn');
      } //if(result =="false")
      else {
        console.log('---14---');
        //this.showHideStopPage = true;
        this.showHideStartPage = true;
        this.showHideDailpad = false;
        this.showHideCallStatus = false;
        this.showHideCalldialling = false;
        this.stopDialogCounter = 1;
        this.check_dialler_setting();
        this.showHideDiallerPage = false;
      }
      // else {
      //   console.log("---14---");
      //   this.showHideStopPage = true;
      //   this.showHideStartPage = false;
      //   this.showHideDailpad = false;
      //   this.showHideCallStatus = false;
      //   this.showHideCalldialling = false;
      //   this.stopDialogCounter = 1;
      //   this.check_dialler_setting();
      //   this.showHideDiallerPage =true;
      // }
    });
  }
  reset_aftercall() {}
  submit_aftercall() {}

  last3call(item) {
    var today = moment().format('DD/MM/YYYY');
    var noofdays;
    // console.log(item);
    // console.log(item.number);
    let userid = this.user.id;
    let afterslipt = item.number.split('+');
    //console.log(afterslipt);
    let numner = '%2b' + afterslipt[1];
    //
    let obj = {
      userid: userid,
      number: numner,
      count: 3,
    };
    this.API.getLastThreeCallRecording(obj, this.api_end_point).subscribe(
      (data: any) => {
        // console.log(data.length);
        // console.log(data.success);
        if (data.length == 0) {
          //this._snackBar.open("No Data found", "Close");
          let last3call = 'No Data found';
          return last3call;
        } else {
          let obj = [];
          //console.log(data);

          for (let audio in data) {
            // console.log(data[audio]);
            // console.log(data[audio].url);
            var dateofvisit = moment(data[audio].call_start_time);
            if (dateofvisit.diff(dateofvisit, 'days') == 0) {
              //console.log(dateofvisit.diff(dateofvisit, 'days'));
              obj.push({
                value: data[audio].url,
                viewValue: moment(data[audio].call_start_time).format(
                  'DD-MM-YYYY'
                ),
              });
            } else if (dateofvisit.diff(dateofvisit, 'days') == 1) {
              //console.log(dateofvisit.diff(dateofvisit, 'days'));
              obj.push({
                value: data[audio].url,
                viewValue: moment(data[audio].call_start_time).format(
                  'DD-MM-YYYY'
                ),
              });
            } else {
              obj.push({
                value: data[audio].url,
                viewValue: moment(data[audio].call_start_time).format(
                  'DD-MM-YYYY'
                ),
              });
            }
          }
          for (let v in obj) {
            obj[v].value =
              obj[v].value + `&access_token=${this.myUser.access_token}`;
          }
          //console.log(obj);
          return obj;
        }
      }
    );
  }

  selectCall(calldetail) {
    //debugger;
    let urlaudio = calldetail.url + `&access_token=${this.myUser.access_token}`;
    this.dialog
      .open(AudioDialogComponent, {
        width: '400px',
        disableClose: true,
        data: {
          url: urlaudio,
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        console.log(`Dialog result: ${JSON.stringify(result)}`);
      });
  }

  SelectLeads() {
    this.LeadsSelected = true;
    this.FilledFormsSelected = false;
    this.FollowupSelected = false;
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(this.dataSource.data);
      this.dataSource.paginator = this.tableOnePaginator;
      // this.tableOnePaginator.length = this.dataSource.data.length;
    }, 500);
  }

  SelectFollowup() {
    this.selectdate = true;
    this.selectedFilter = 'TODAY';
    this.FilledFormsSelected = false;
    this.FollowupSelected = true;
    this.LeadsSelected = false;
    setTimeout(() => {
      this.followupSource = new MatTableDataSource(this.followupSource.data);
      this.followupSource.paginator = this.tableThreePaginator;
      // this.tableThreePaginator.length = this.followupSource.data.length;
    }, 500);
  }

  filledRowRecord(item) {
    // debugger;
    //console.log(item);
    this.filledjsonObject = [];
    let data = item; //JSON.parse(item.feedback);
    var feedbackdata = item;
    //console.log(feedbackdata);

    for (let item in feedbackdata.comment) {
      //console.log(feedbackdata.comment[item])
      let question = feedbackdata.comment[item];
      //console.log(Object.values(question));
      var question_ans = Object.keys(question).map(function (e) {
        return question[e];
      });
      //console.log(question_ans);
      var obj = {
        q: question_ans[0],
        a: question_ans[1],
        action: '',
      };
      if (question_ans[2]) {
        obj.action = question_ans[2];
      }
      this.filledjsonObject.push(obj);
      //console.log(this.filledjsonObject);
    }
    //console.log(this.filledjsonObject);
    if (data == null) {
      this._snackBar.open('No Feedback found', 'close', {
        duration: 5000,
      });
    }
    this.showhideQuestionAnwer = true;
  }

  readnotification(readingdata) {
    let rowdata = readingdata;
    //console.log(rowdata);
    this.dialog
      .open(NotificationReadComponent, {
        width: '500px',
        disableClose: true,
        data: {
          obj: rowdata,
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        //console.log(result);
        if (result == 'true') {
          let obj = {
            user_id: this.user.id,
            postNotifications: [
              {
                id: rowdata.id,
                status: 'READ',
              },
            ],
          };

          //	console.log(obj);
          this.API.readNotification(obj).subscribe((data: any) => {
            //	console.log(data);
            this.getNotification();
          });
        }
      });
  }
  goBtn() {
    //console.log("---" + this.fromDate);
    let followupdate = moment(this.fromDate).format('YYYY-MM-DD HH:mm:ss');
    //console.log(followupdate);
    let todaydate = moment().format('YYYY-MM-DD HH:mm:ss');
    if (followupdate > todaydate) {
      //console.log("date is greater than today date");
      this._snackBar.open('Please enter before current date', 'close', {
        duration: 5000,
      });
    } else {
      let pastdate1 = moment(followupdate).startOf('day').toString();
      let pastdate2 = moment(followupdate).endOf('day').toString();
      //	console.log("--yesterday---" + pastdate1 + "--" + pastdate2);
      this.fromDate = moment(pastdate1).format('YYYY-MM-DD HH:mm:ss');
      this.toDate = moment(pastdate2).format('YYYY-MM-DD HH:mm:ss');
      //console.log("--yesterday-1---" + this.fromDate + "--" + this.toDate);
      //this.followupdatatable();
      this.followupDataForTableData();
    }
  }
  onDate() {
    if (this.fromDate > this.toDate) {
      let _todate = this.toDate;
      this.fromDate = _todate;
    }
  }
  FollowFilter(val: any) {
    this.selectedFilter = val;
    this.FollowdateTable();
  }
  FollowdateTable() {
    // console.log('table refreshed');
    let _today: any = new Date();
    let _startDate = moment().subtract(0, 'days').startOf('day').toString();
    let _endDate = moment().subtract(0, 'days').endOf('day').toString();
    // debugger;
    if (this.selectedFilter == 'TODAY') {
      this.fromDate = moment(_startDate).format('YYYY-MM-DD HH:mm:ss');
      this.toDate = moment(_endDate).format('YYYY-MM-DD HH:mm:ss');
      //console.log("Today :::: ",this.fromDate)
      //console.log("Today :::: ",this.toDate)
      //console.log("--today---" + this.fromDate + "--" + this.toDate);
      //this.followupdatatable();
      this.followupDataForTableData();
    } else if (this.selectedFilter == 'YESTERDAY') {
      let yesterday1 = moment().subtract(1, 'days').startOf('day').toString();
      let yesterday2 = moment().subtract(1, 'days').endOf('day').toString();
      //console.log("--yesterday---" + yesterday1 + "--" + yesterday2);
      this.fromDate = moment(yesterday1).format('YYYY-MM-DD HH:mm:ss');
      this.toDate = moment(yesterday2).format('YYYY-MM-DD HH:mm:ss');
      //console.log("--yesterday-1---" + this.fromDate + "--" + this.toDate);
      //this.followupdatatable();
      this.followupDataForTableData();
    } else if (this.selectedFilter == 'ALL_IN_PAST') {
      let yesterday1 = moment().subtract(30, 'days').startOf('day').toString();
      let yesterday2 = moment().subtract(0, 'days').endOf('day').toString();
      //console.log("--ALL_IN_PAST---" + yesterday1 + "--" + yesterday2);
      this.fromDate = moment(yesterday1).format('YYYY-MM-DD HH:mm:ss');
      this.toDate = moment(yesterday2).format('YYYY-MM-DD HH:mm:ss');
      //console.log("--ALL_IN_PAST-1---" + this.fromDate + "--" + this.toDate);
      // this.followupdatatable();
      this.followupDataForTableData();
      // this._snackBar.open("please select future date and time", "close", {
      //   duration: 3000,
      // });
    } else if (this.selectedFilter == 'ALL_IN_FUTURE') {
      let yesterday2 = moment().add(30, 'days').startOf('day').toString();
      let yesterday1 = moment().subtract(0, 'days').endOf('day').toString();
      //console.log("--ALL_IN_PAST---" + yesterday1 + "--" + yesterday2);
      this.fromDate = moment(yesterday1).format('YYYY-MM-DD HH:mm:ss');
      this.toDate = moment(yesterday2).format('YYYY-MM-DD HH:mm:ss');
      //console.log("--ALL_IN_PAST-1---" + this.fromDate + "--" + this.toDate);
      //this.followupdatatable();
      this.followupDataForTableData();
    } else if (this.selectedFilter == 'CHOOSE_A_DATE') {
      this.selectdate = false;
      //this.followupdatatable();
      //console.log(this.followChooseSelectedDateValue);
      let _selDate1 = moment(this.followChooseSelectedDateValue)
        .subtract(0, 'days')
        .startOf('day')
        .toString();
      let _selDate2 = moment(this.followChooseSelectedDateValue)
        .subtract(0, 'days')
        .endOf('day')
        .toString();
      this.fromDate = moment(_selDate1).format('YYYY-MM-DD HH:mm:ss');
      this.toDate = moment(_selDate2).format('YYYY-MM-DD HH:mm:ss');
      //this.followupdatatable();
      this.followupDataForTableData();
    }
    this.leadsdatefrom = this.fromDate;
    this.leadsdateto = this.toDate;
  }
  followupdatatable() {
    let userid = this.user.id;
    let obj = {
      userid: userid,
      start_time: this.fromDate,
      end_time: this.toDate,
      skip: 0,
      calendar_type: 'smartercalendar',
      top: 100,
    };
    this.API.agentPluginDashboardList(obj, this.api_end_point).subscribe(
      (data: any) => {
        let _response = data.event;
        //	console.log("null", _response);
        //console.log("length", _response.length);
        let _aa = _response.filter((v) => {
          return v.responsestatus == 'accepted' && v.flpcount[0] > 0;
        });
        // console.log("----",JSON.stringify(_response));
        // console.log("--follow--up---",JSON.stringify(_aa));
        this._countfollowUpLength = _aa.length;
        if (this._countfollowUpLength == 0) {
          this._snackBar.open('No Follow Up Found', 'close', {
            duration: 3000,
          });
        }
        this.followupSource = new MatTableDataSource(_aa);
        this.followupSource.paginator = this.tableThreePaginator;
        if (this.tableThreePaginator) {
          this.tableThreePaginator.length = this.followupSource.data.length;
        }
        if (this._countfollowUpLength == 0) {
          this._snackBar.open('No Follow Up Found', 'close', {
            duration: 3000,
          });
        }
      }
    );
  }

  followupDataForTableData() {
    let obj = {
      user_id: this.user.id,
    } as any;
    //this.API.getContactsWhtasapp(obj, this.api_end_point).subscribe((res: any) => {
    //this._contactsAPIResponseStorage
    //debugger;
    if (this._contactsAPIResponseStorage.success) {
      let ELEMENT_DATA = this._contactsAPIResponseStorage.success;
      console.log(ELEMENT_DATA[0]);

      let rows = [];
      ELEMENT_DATA.forEach((element) => {
        let row1 = element.customer_details;
        row1.forEach((e) => rows.push(e));
      });

      let DATA = rows;

      let cus_details = DATA;
      let filtered_data1 = cus_details.filter((v) => {
        //debugger
        if (v.stage == 'FOLLOW-UP') {
          if (v.stage == 'FOLLOW-UP') {
            // let start_date = v.start_date;
            let date1 = moment().format('YYYY-MM-DDTHH:mm:ss');
            let date2 = moment(v.start_date, 'YYYY-MM-DDTHH:mm:ss');
            console.log(date1, date2);
            console.log(date2.diff(date1, 'days'));
            var days = date2.diff(date1, 'days');

            //Date format changes for contacts API by praveen
            let date = moment(v.start_date).format('YYYY-MM-DDTHH:mm:ss');
            //let time = String(campaign.controls.time.value + ':00');
            //let full_time = String(date).concat('T', time.toString()).toString();
            //console.log("time :: ",time);
            //console.log("final time :: ",moment(String(date).concat('T', time.toString()).toString()+" current time : "+ moment().format('YYYY-MM-DDTHH:mm')));
            console.log('Followup data ::: ', v);
            console.log(
              'Followup data ::: ',
              moment(String(this.fromDate).toString()),
              ' ::::: ',
              moment(String(this.toDate).toString())
            );
            //if(moment(String(date).toString()).isBefore(moment().format('YYYY-MM-DDTHH:mm'))){
            if (moment(v.start_date).isBetween(this.fromDate, this.toDate)) {
              return v;
            }
          }
        }
      });
      console.log('Followup data ::: ', filtered_data1);
      this._countfollowUpLength = filtered_data1.length;
      this.followupSource = new MatTableDataSource(filtered_data1);
      this.followupSource.paginator = this.tableThreePaginator;
      if (this.tableThreePaginator) {
        this.tableThreePaginator.length = this.followupSource.data.length;
      }
      if (this._countfollowUpLength == 0) {
        this._snackBar.open('No Follow Up Found', 'close', {
          duration: 3000,
        });
      }
    } else if (this._contactsAPIResponseStorage.errors) {
    }
    //})
  }
  LeadFilter(val: any) {
    //debugger;
    console.log('selected tab', this.selectedTab);
    this.isShowDateForFollowUp = false;
    this.leadFilter = val;
    this.selectedFilter = val;

    if (this.selectedTab == 0 || this.selectedTab == 3) {
      this.LeaddateTable();
    }
    if (this.selectedTab == 1) {
      if (val == 'CHOOSE_A_DATE') {
        this.isShowDateForFollowUp = true;
      } else {
        this.FollowdateTable();
      }
    }
    if (this.selectedTab == 2) {
      this.getHomeData();
    }
  }
  LeaddateTable() {
    // console.log('table refreshed');
    let _today: any = new Date();
    let _startDate = moment().subtract(0, 'days').startOf('day').toString();
    let _endDate = moment().subtract(0, 'days').endOf('day').toString();
    // debugger;
    if (this.leadFilter == 'TODAY') {
      this.leadsdatefrom = moment(_startDate).format('YYYY-MM-DD HH:mm:ss');
      this.leadsdateto = moment(_endDate).format('YYYY-MM-DD HH:mm:ss');
      //console.log("--today---" + this.leadsdatefrom + "--" + this.leadsdateto);
      this.Leadsdetailstable();
    } else if (this.leadFilter == 'YESTERDAY') {
      let yesterday1 = moment().subtract(1, 'days').startOf('day').toString();
      let yesterday2 = moment().subtract(1, 'days').endOf('day').toString();
      //console.log("--yesterday---" + yesterday1 + "--" + yesterday2);
      this.leadsdatefrom = moment(yesterday1).format('YYYY-MM-DD HH:mm:ss');
      this.leadsdateto = moment(yesterday2).format('YYYY-MM-DD HH:mm:ss');
      //console.log("--yesterday-1---" + this.leadsdatefrom + "--" + this.leadsdateto);
      this.Leadsdetailstable();
    } else if (this.leadFilter == 'ALL_IN_PAST') {
      let yesterday1 = moment().subtract(30, 'days').startOf('day').toString();
      let yesterday2 = moment().subtract(1, 'days').endOf('day').toString();
      //console.log("--ALL_IN_PAST---" + yesterday1 + "--" + yesterday2);
      this.leadsdatefrom = moment(yesterday1).format('YYYY-MM-DD HH:mm:ss');
      this.leadsdateto = moment(yesterday2).format('YYYY-MM-DD HH:mm:ss');
      //console.log("--ALL_IN_PAST-1---" + this.leadsdatefrom + "--" + this.leadsdateto);
      this.Leadsdetailstable();
    }
    this.fromDate = this.leadsdatefrom;
    this.toDate = this.leadsdateto;
  }
  Leadsdetailstable() {
    let userid = this.user.id;
    let obj = {
      userid: userid,
      start_time: this.leadsdatefrom,
      end_time: this.leadsdateto,
    };
    this.API.agentPluginContacted(obj, this.api_end_point).subscribe(
      (data: any) => {
        let _response = data.success;
        if (_response) {
          let ELEMENT_DATA = _response;
          let _contactedData = ELEMENT_DATA.filter((v) => {
            return v.event_type == 'call';
          });
          this.dataSource = new MatTableDataSource(_contactedData.reverse());
          this.dataSource.paginator = this.tableOnePaginator;
          this._contactedData = _contactedData.length;
          this._countLeadLength = this.API.leadsConnected(
            _contactedData.length
          );

          if (this._countLeadLength == 0) {
            this._snackBar.open('No Leads Found', 'close', {
              duration: 5000,
            });
          }
          this.refreshLeadsFulfillmentData(_response);
        }
      }
    );
  }
  mergingcall() {
    // debugger;
    //console.log(this.callStatus.customerInfo.transactionid);
    let obj = {
      user_id: this.user.id,
      agent_number: this.user.phone,
      customer_number: this.customerInfo.customer_number,
      transactionid: this.callStatus.customerInfo.transactionid,
      caller_id: this._caller_id,
    };
    //console.log(obj);
    this.API.callmerge(obj, this.user.server_url).subscribe((data: any) => {
      if (data.success) {
        setTimeout(() => {
          this._snackBar.open('call merging', 'close', {
            duration: 5000,
          });
        }, 1000);
      }
    });
  }
  transferingcall() {
    //	console.log(this.callStatus.customerInfo.transactionid);
    let obj = {
      user_id: this.user.id,
      agent_number: this.user.phone,
      customer_number: this.customerInfo.customer_number,
      transactionid: this.callStatus.customerInfo.transactionid,
      caller_id: this._caller_id,
    };
    //	console.log(obj);
    this.API.calltransfer(obj, this.user.server_url).subscribe((data: any) => {
      if (data.success) {
        setTimeout(() => {
          this._snackBar.open('call transfering', 'close', {
            duration: 5000,
          });
        }, 1000);
      }
    });
  }

  getDcTableData() {
    this._LeadsContactedData = [
      {
        name: 'Raj Kumar',
        leadSource: 'Outgoing Call',
        status: 'NEW DATA',
        dateTime: '04-Jan-2022 3:39 PM',
      },
      {
        name: 'Raju Kumar',
        leadSource: 'Outgoing Call',
        status: 'NEW DATA',
        dateTime: '04-Jan-2022 3:39 PM',
      },
      {
        name: 'Raj Kumar',
        leadSource: 'Outgoing Call',
        status: 'NEW DATA',
        dateTime: '04-Jan-2022 3:39 PM',
      },
      {
        name: 'Raj Kumar',
        leadSource: 'Outgoing Call',
        status: 'NEW DATA',
        dateTime: '04-Jan-2022 3:39 PM',
      },
      {
        name: 'Raj Kumar',
        leadSource: 'Outgoing Call',
        status: 'NEW DATA',
        dateTime: '04-Jan-2022 3:39 PM',
      },
      {
        name: 'Raju Kumar',
        leadSource: 'Outgoing Call',
        status: 'NEW DATA',
        dateTime: '04-Jan-2022 3:39 PM',
      },
    ];
    this.dataSourceLeadContacted = new MatTableDataSource(
      this._LeadsContactedData
    );
    this.dataSourceLeadContacted.paginator = this.paginatorLeadContacted;

    this._FollowUpToDoData = [
      {
        name: 'Raj Kumar',
        leadSource: 'Outgoing Call',
        status: 'NEW DATA',
        dateTime: '04-Jan-2022 3:39 PM',
        recording: '',
        call: '',
      },
      {
        name: 'Raju Kumar',
        leadSource: 'Outgoing Call',
        status: 'NEW DATA',
        dateTime: '04-Jan-2022 3:39 PM',
        recording: '',
        call: '',
      },
      {
        name: 'Raj Kumar',
        leadSource: 'Outgoing Call',
        status: 'NEW DATA',
        dateTime: '04-Jan-2022 3:39 PM',
        recording: '',
        call: '',
      },
      {
        name: 'Raj Kumar',
        leadSource: 'Outgoing Call',
        status: 'NEW DATA',
        dateTime: '04-Jan-2022 3:39 PM',
        recording: '',
        call: '',
      },
      {
        name: 'Raj Kumar',
        leadSource: 'Outgoing Call',
        status: 'NEW DATA',
        dateTime: '04-Jan-2022 3:39 PM',
        recording: '',
        call: '',
      },
      {
        name: 'Raju Kumar',
        leadSource: 'Outgoing Call',
        status: 'NEW DATA',
        dateTime: '04-Jan-2022 3:39 PM',
        recording: '',
        call: '',
      },
    ];
    this.dataSourceFollowUpToDo = new MatTableDataSource(
      this._FollowUpToDoData
    );
    this.dataSourceFollowUpToDo.paginator = this.paginatorFollowUp;

    this._FilledFormsData = [
      {
        name: 'Raj Kumar',
        leadSource: 'Outgoing Call',
        dateTime: '04-Jan-2022 3:39 PM',
        comment: 'NEW DATA',
      },
      {
        name: 'Raju Kumar',
        leadSource: 'Outgoing Call',
        dateTime: '04-Jan-2022 3:39 PM',
        comment: 'NEW DATA',
      },
      {
        name: 'Raj Kumar',
        leadSource: 'Outgoing Call',
        dateTime: '04-Jan-2022 3:39 PM',
        comment: 'NEW DATA',
      },
      {
        name: 'Raj Kumar',
        leadSource: 'Outgoing Call',
        dateTime: '04-Jan-2022 3:39 PM',
        comment: 'NEW DATA',
      },
      {
        name: 'Raj Kumar',
        leadSource: 'Outgoing Call',
        dateTime: '04-Jan-2022 3:39 PM',
        comment: 'NEW DATA',
      },
      {
        name: 'Raju Kumar',
        leadSource: 'Outgoing Call',
        dateTime: '04-Jan-2022 3:39 PM',
        comment: 'NEW DATA',
      },
    ];

    this.dataSourceFilledForms = new MatTableDataSource(this._FilledFormsData);
    this.dataSourceFilledForms.paginator = this.paginatorFilledForms;
  }
  startBtnCall() {
    this.showHideStart = false;
    this.showHideStop = true;
    this._flagStartStop = 'StartFlag';
    (document.getElementById('codeDialPad') as HTMLFormElement).value = '';
  }
  stopBtnCall() {
    this.showHideStart = true;
    this.showHideStop = false;
    this._flagStartStop = 'StopFlag';
    (document.getElementById('codeDialPad') as HTMLFormElement).value = '';
  }

  contactNameDetails(data: any) {
    //debugger;
    // console.log("---", JSON.stringify(data));
    if (data.feedback_json) {
      data.feedback = data.feedback_json;
    }
    const dialogRef = this.dialog.open(
      ContactListCampaignContactDialogPageComponent,
      {
        width: '70%',
        disableClose: true,
        data: data,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'true') {
        // let _ele = {
        //   Name: data.name,
        //   Number: data.number,
        //   call_status: data.call_status,
        //   updated_at: data.updated_at,
        //   customkvs: data.customkvs,
        //   transactionid: data.transactionid,
        //   checkC2C: "c2cCallThere"
        // }
        // this.API.sendRowContactList(_ele);
        // // this.NoRequiredStartBtn = "NoNeedStartForC2C";
        // // this.CallChatPhoneClick('phone', _ele);
      } else if (result == 'false') {
        //alert("close");
        // this.NoRequiredStartBtn = "";
      }
    });
  }

  onTabChanged(event: any) {
    this.tableOnePaginator.pageIndex = 0;
    this.tableTwoPaginator.pageIndex = 0;
    this.tableThreePaginator.pageIndex = 0;
    this.tableOnePaginator.pageSize = 5;
    this.tableTwoPaginator.pageSize = 5;
    this.tableThreePaginator.pageSize = 5;

    this._filterLeadName.nativeElement.value = '';
    this._filterFollowUpName.nativeElement.value = '';
    this._filterFilledName.nativeElement.value = '';
    this._filterFulfilledName.nativeElement.value = '';

    this.selectedTab = event.index;

    this.isShowDateForFollowUp = false;
    // if(event.index==0){

    // 	this.getContacted();
    // }
    // if(event.index==1){
    // 	this.getDashboardList();
    // }
    // if(event.index==2){
    // 	this.getHomeData();
    // }
    //debugger;
    if (
      (this.selectedTab == 0 || this.selectedTab == 2) &&
      (this.selectedFilter == 'ALL_IN_FUTURE' ||
        this.selectedFilter == 'CHOOSE_A_DATE')
    ) {
      this.selectedFilter = 'TODAY';
    }

    this.LeadFilter(this.selectedFilter);
    // this.leadFilter = 'TODAY';
    // if(this.leadFilter == 'TODAY'){
    // 	setTimeout(()=>{
    // 		this.LeadFilter(this.leadFilter) ;
    // 	},100)

    // }
  }
  handleDateChange(event: any) {
    this.followChooseSelectedDateValue = event.value;
    if (this.selectedFilter == 'CHOOSE_A_DATE') {
      this.FollowdateTable();
    }
  }
  applyFilterLeadContacted(filterValue: any) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  applyFilterFollowUp(filterValue: any) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.followupSource.filter = filterValue;
  }
  applyFilterFilledSource(filterValue: any) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.filledSource.filter = filterValue;
  }
  applyFilterFulfillmentSource(filterValue: any) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.leadFulfillmentSource.filter = filterValue;
  }
  closeQABtn() {
    this.showhideQuestionAnwer = false;
  }
  phoneInputPress(event: any) {
    let _val;

    if (document.getElementById('codeDialPad') as HTMLFormElement) {
      _val = (document.getElementById('codeDialPad') as HTMLFormElement).value;
    }
    if (_val && _val.length >= 10) {
      event.preventDefault();
    }
    return event.charCode >= 48 && event.charCode <= 57;
  }
  onlyNumAlphabetValidation(event) {
    let inputValue = event.charCode;
    if (
      !(inputValue >= 65 && inputValue <= 121) &&
      inputValue != 32 &&
      inputValue != 0 &&
      !(inputValue >= 48 && inputValue <= 57)
    ) {
      event.preventDefault();
    }
  }
  pasteTextPhone(event: ClipboardEvent) {
    event.preventDefault();
  }
  check_dialler_setting() {
    let stateSettingsChat: any = this.myservice.get_stage_settings();
    let _setFilterDial = stateSettingsChat.extraoptions.filter((v) => {
      return v.name == 'unscheduledcall';
    });
    if (_setFilterDial && _setFilterDial.length > 0) {
      this.isStageDial = false;
      if (_setFilterDial[0].definition == 'off') {
        this.isStageDial = false;
      } else {
        this.isStageDial = true;
        //this.showHideStartPage=true;
        //dialler enbale setting
        //this.showHideStopPage = true;
        this.showHideStartPage = true;
        this.showHideDiallerPage = false;
      }
      console.log(_setFilterDial);
    }
  }
  dialleron_setting() {
    //sessionStorage.setItem('sessionStarted', 'true');
    console.log('---11---');
    // this.showHideStopPage = true;
    // this.showHideStartPage = false;
    this.showHideStopPage = false;
    this.showHideStartPage = true;
    this.showHideDailpad = false;
    this.showHideCallStatus = false;
    this.showHideCalldialling = false;
    this.showHideCallEndingScreen = false;
    if (document.getElementById('code') as HTMLFormElement) {
      let _num = (document.getElementById('code') as HTMLFormElement).value;
      _num = _num.substring(0, _num.length - 1);
      (document.getElementById('code') as HTMLFormElement).value = _num;
    }
    this.myservice.clearCallStatus();
    this.showHideStartCallButton = true;
    this.showHideCallPad = false;
    localStorage.removeItem('noDialPad');
	console.log('Starting RemoteDialStart',this.isTruepredicitive);
    this.API.remoteDialStart(this.user.id, this.api_end_point).subscribe(
      (res: any) => {
        //	console.log(res)
        this.sessionStarted = true;
        //debugger;
        if (this.isStayAtHome) {
          //this.showHidePopup=false;
          localStorage.removeItem('noExitC2CInStartBtn');
        } else {
          //this.showHidePopup=true;
          console.log('---12---');
          //this.showHideStopPage = true;
          this.showHideStartPage = true;
          localStorage.removeItem('noDialPad');
          localStorage.setItem('noDialPad', 'NoDial');

          localStorage.removeItem('noExitC2CInStartBtn');
          localStorage.setItem('noExitC2CInStartBtn', 'c2cNotThere');
          setTimeout(() => {
            this.startCallLoading();
          }, 100);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  endSessionForAllowinBound() {
    //debugger;
    let updateObjSession = this.myservice.getLastCallSession();
    this.showHideDiallerPage = false;
    localStorage.removeItem('c2cCallPresent');
    this.check_dialler_setting();
    this.showHideStopPage = true;
    this.showHideStartPage = true;
    this.showHideDailpad = false;
    this.showHideCallStatus = false;
    this.showHideCalldialling = false;
    this.showHideCallEndingScreen = false;
    if (document.getElementById('code') as HTMLFormElement) {
      let _num = (document.getElementById('code') as HTMLFormElement).value;
      _num = _num.substring(0, _num.length - 1);
      (document.getElementById('code') as HTMLFormElement).value = _num;
    }

    this.myservice.clearCallStatus();
    this.showHideStartCallButton = true;
    this.showHideCallPad = false;

    if (updateObjSession && updateObjSession.internet_speed == 0) {
      this.sessionStarted = false;
      this.showHideStartPage = true;
      //this.showHideStopPage = true;

      sessionStorage.setItem('sessionStarted', 'false');
      if (document.getElementById('codeDialPad') as HTMLFormElement) {
        (document.getElementById('codeDialPad') as HTMLFormElement).value = '';
      }

      return;
    }
    this.showHideStartPage = false;
    this.isStageDial = false;
    this.API.settingsAPI(this.api_end_point).subscribe((data: any) => {
      //console.log(data);
      //console.log(data.success.extraoptions);
      let setting = data.success.extraoptions;
      let stopsetting = setting.filter((v) => {
        // console.log(v);
        return v.name == 'remoteautodialling';
      });
      //	console.log(stopsetting[0].definition);
      if (stopsetting[0].definition == 'onsolicit') {
        if (this.stopDialogCounter == 1) {
          this.stopDialogCounter++;
          this.endSessionCallForallowInbound();
        }
      } else {
        this.API.remoteDialStop(
          this.user.id,
          this.api_end_point,
          this.breakoption
        ).subscribe(
          (res: any) => {
            //console.log(res)
            this.sessionStarted = false;
            // this.removeNum();
            this.myservice.clearCallStatus();
            var updateObjSession = this.myservice.getLastCallSession();
            updateObjSession.sessionStarted = false;
            this.callSession = updateObjSession;
            this.myservice.updateCallSession(updateObjSession);
            sessionStorage.setItem('sessionStarted', 'false');
            this.showHideStartCallButton = true;
            this.isStageDial = true;
          },
          (err) => {
            console.log(err);
          }
        );
      }
    });
  }
  endSessionCallForallowInbound() {
    //debugger;
    //console.log(this.myUser);
    var dialogRef = this.dialog.open(EndSessionDialogComponent, {
      width: '40%',
      disableClose: true,
      data: this.myUser,
    });
    dialogRef.afterClosed().subscribe((result) => {
      //debugger;
      //console.log(result);
      this.getHomeData();
      if (result == 'true') {
        this.sessionStarted = false;
        // this.removeNum();
        this.myservice.clearCallStatus();
        var updateObjSession = this.myservice.getLastCallSession();
        updateObjSession.sessionStarted = false;
        this.callSession = updateObjSession;
        this.myservice.updateCallSession(updateObjSession);
        sessionStorage.setItem('sessionStarted', 'false');
        this.showHideStopPage = false;
        this.showHideStartPage = true;
        this.showHideDailpad = false;
        this.showHideCallStatus = false;
        this.showHideCalldialling = false;
        this.stopDialogCounter = 1;
        this.showHideDiallerPage = false;
        this.check_dialler_setting();
        localStorage.removeItem('noExitC2CInStartBtn');

        this.isStageDial = false;
        this.showHideStopPageForAllowinBound = false;
        this.inbound_button_status = false;
      } //if(result =="false")
      else {
        console.log('---14---');
        //this.showHideStopPage = true;
        this.showHideStartPage = true;
        this.showHideDailpad = false;
        this.showHideCallStatus = false;
        this.showHideCalldialling = false;
        this.stopDialogCounter = 1;
        this.check_dialler_setting();
        this.showHideDiallerPage = false;

        this.showHideStartPage = false;

        this.isStageDial = false;
      }
      // else {
      //   console.log("---14---");
      //   this.showHideStopPage = true;
      //   this.showHideStartPage = false;
      //   this.showHideDailpad = false;
      //   this.showHideCallStatus = false;
      //   this.showHideCalldialling = false;
      //   this.stopDialogCounter = 1;
      //   this.check_dialler_setting();
      //   this.showHideDiallerPage =true;
      // }
    });
  }

  @ViewChild('dialerDialog', { static: true }) dialerDialog: TemplateRef<any>;
  openDialogDialerDialog() {
    this.dialog.open(this.dialerDialog, { width: '400px' });
  }

  start_button_disabled():boolean{
    // console.log('start button status --', this.call_button_status, this.call_button_status_socket, this.sessionStarted, this.showHideStopPageForAllowinBound)
    return this.call_button_status <= 0 || !this.call_button_status_socket
  }
}
