<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
    <mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
        <!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
    </mat-drawer>

<div style="text-align: right;position: sticky;top:0;margin-top: 10px;"><i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closeDialog()"></i></div>
    <div class="gig-sidenav-content">
        <div style="background-color: #f6f6f6 !important;background: #f6f6f6 !important;margin: 15px 10px 10px 10px;">
            <div class="row">
                <div class="col-md-4">
                    <h4 class="titlehead thd1">BI Dashboard</h4>
                </div>
				<div class="col-md-4">
					<span class="titlehead thd1">{{asm_details.name}}</span>
				</div>
				<div class="col-md-4">
					<span class="titlehead thd1">{{getCurrentDate()}}</span>
				</div>
            </div>
            <div class="row bADashboardTabList">
                <div class="col-md-9" style="padding: 0px 3.5px;">
                    <mat-button-toggle-group class="dashBAcs" #group="matButtonToggleGroup"
                        [value]="selectedButtonToggleVal" (change)="onToggleButtonValChange(group.value)">
                        <mat-button-toggle value="dataTab">Data</mat-button-toggle>
                        <mat-button-toggle value="dialedTab">Dialed</mat-button-toggle>
                        <mat-button-toggle value="contactedTab">Contacted</mat-button-toggle>
                        <mat-button-toggle value="agentsTab">Agents</mat-button-toggle>
                        <mat-button-toggle value="activeTimeTab">Active Time</mat-button-toggle>
                        <mat-button-toggle value="qdeTab" style="border-right: solid 1px #0000001f;">QDE
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12 cn-dat"></div>
            </div>

            <div class="row" style="float: left;width: 1300px;overflow-y: auto;height: 400px;">
                <div class="col-md-12" *ngIf="showHideDataTabPage">
                    <app-badashboard-data-page></app-badashboard-data-page>
                </div>
                <div class="col-md-12" *ngIf="showHideDialedTabPage">
                    <app-badashboard-dialed-page></app-badashboard-dialed-page>
                </div>
                <div class="col-md-12" *ngIf="showHideContactedTabPage">
                    <app-badashboard-contacted-page></app-badashboard-contacted-page>
                </div>
                <div class="col-md-12" *ngIf="showHideAgentsTabPage">
                    <app-badashboard-agents-page></app-badashboard-agents-page>
                </div>
                <div class="col-md-12" *ngIf="showHideActiveTimeTabPage">
                    <app-badashboard-active-time-page></app-badashboard-active-time-page>
                </div>
                <div class="col-md-12" *ngIf="showHideQdeTabPage">
                    <app-badashboard-qde-page></app-badashboard-qde-page>
                </div>
            </div>







        </div>
    </div>
    <div id="cover-spin" *ngIf="showLoader"></div>
</mat-drawer-container>