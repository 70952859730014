<form [formGroup]="createdForm">
  <mat-card class="p-0" style="border-radius: 4px;">

    <div class="row">
      <div class="col-md col-sm-12"> </div>
      <div class="col-md-8 col-sm-12">
        <div class="p-4">
          <p class="msCreateHeading pt-3">{{titleCreateEdit}}</p>
          <br>
          <p class="msgerrorcs" [ngStyle]="{'color':msgColor}" *ngIf="showHideMsg">{{showHideErrorMsg}}</p>

          <!--         
      
        <div  class="ms-mtAdjust">
          <span class="msgCreateLabel">Type </span>
          <span class="msgCreateSubLabel"> Choose the type of template you’ll use</span>
          <div  class="input-label-group">
            <mat-select class="mulSel" required  formControlName="type">
              <mat-option value="option1"> Option 1</mat-option>
              <mat-option value="option2"> Option 2</mat-option> 
            </mat-select>
           </div>
        </div>
        
     -->
          <div class="ms-mtAdjust">
            <span class="msgCreateLabel">Template Name </span>
            <span class="msgCreateSubLabel"> Give your message template a name.</span>
            <div class="input-label-group mtp12">
              <input matInput autocomplete="off" required formControlName="templatename" maxlength="512" #boxName
                (keyup)="countKeyUPTemplateName(boxName)" (keypress)="removeSpecialChar($event)" style="text-transform: lowercase;"  (keydown.enter)="$event.preventDefault()" [readonly]="isDisabledForEdit" [ngClass]="isDisabledForEdit == true ? 'myClass1x' : 'myClass2x'" >
              <span class="vkSuffix" [ngClass]="isDisabledForEdit == true ? 'myClass1x' : 'myClass2x'">{{nameTemplateCount}}/512</span>
            </div>
          </div>
          <div class="ms-mtAdjust">
            <span class="msgCreateLabel">Language </span>
            <span class="msgCreateSubLabel"> Choose which languages your message template will be sent in. You can
              delete or add more languages later.</span>
            <div class="input-label-group">
              <mat-select class="mulSel" required formControlName="language" style="padding: 1em 0.65em;" [disabled]="isDisabledForEdit" [ngClass]="isDisabledForEdit == true ? 'myClass1x' : 'myClass2x'">
                <mat-option [value]="v.code" *ngFor="let v of _languagesList">{{v.name}}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="ms-mtAdjust">
            <span class="msgCreateLabel">Category </span>
            <span class="msgCreateSubLabel"> Choose what type of message template you want to create.</span>
            <!-- <div class="input-label-group">
              <mat-select class="mulSel" required formControlName="category" style="padding: 1em 0.65em;" 
              [disabled]="isDisabledForEdit" [ngClass]="isDisabledForEdit == true ? 'myClass1x' : 'myClass2x'">
                <mat-option [value]="v.key" *ngFor="let v of _categoryList">{{v.name}}</mat-option>
              </mat-select>
            </div> -->



<div>
  <mat-button-toggle-group  class="catBTG mt-4"  formControlName="category"    [disabled]="isDisabledForEdit" [ngClass]="isDisabledForEdit == true ? 'myClass1x' : 'myClass2x'"
   (change)="onCategoryChange($event.value)" style="background-color: #fff !important;">
    <mat-button-toggle [value]="v.key" *ngFor="let v of _categoryList"><mat-icon>{{v.icon}} </mat-icon> {{v.name}}</mat-button-toggle>   
  </mat-button-toggle-group>
</div>



          </div>

          <div class="ms-mtAdjust">
            <span class="msgCreateLabel">Header <span class="vk-opt"> Optional </span></span>
            <span class="msgCreateSubLabel"> Add a title or choose a media type</span>
            <div>
              <mat-radio-group [(value)]="headerOptionValue" (change)="radioChangeHeader($event.value)">
                <mat-radio-button value="" class="mr-3">None</mat-radio-button>
                <mat-radio-button value="TEXT">Text</mat-radio-button>
                <mat-radio-button value="IMAGE" class="ml-3">Image</mat-radio-button>
                <mat-radio-button value="VIDEO" class="ml-3">Video</mat-radio-button>
                <mat-radio-button value="DOCUMENT" class="ml-3">Document</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="input-label-group mtp12" *ngIf="showHideHeaderText">
              <input matInput autocomplete="off" required [(ngModel)]="_headerAddVariable" formControlName="headerTitle"
                maxlength="60" #box1 (keyup)="countKeyUPHeader(box1,$event)" (keydown.enter)="$event.preventDefault()" (paste)="pasteHdrText(box1, $event)">
              <span class="vkSuffix">{{headerCount}}/60</span>

            </div>
            <div *ngIf="showHideHeaderImage">

              
              <span class="btn-file1">
                Choose Jpg or Png<input type="file" id="testfile1" (change)="getImageFile($event,$event.target.files)">
            </span>

              <img [src]="hdrImageUrl" id="previewIMg" class="mt-2"
                style="width: 170px;  border: 2px solid black;  border-radius: 4px;" />
                <p> <span class="msgCreateSubLabel">{{filenameContainer || 'No Image Selected'}}</span></p>
            </div>

            <div *ngIf="showHideHeaderVideo" class="mt-4">
              <span class="btn-file1 mb-4">
                Choose Video Mp4<input type="file" id="testfile2" (change)="getVideoFile($event,$event.target.files)">
            </span>
              <video *ngIf="filenameContainerVideo !=''" id="vidSelectedId" controls style="width: 300px;border-radius: 4px;border: 2px solid #ccc; padding: 1px;">
                <source [src]="hdrVideoUrl" id="video1" type="video/mp4">
              </video>
              <p *ngIf="!forModifyVideoUrl"> <span class="msgCreateSubLabel">{{filenameContainerVideo  || 'No Video Selected'}}</span></p>
              
            </div>
            <div *ngIf="forModifyVideoUrl">   
              <iframe width="200" height="200"  [src]="hdrVideoUrl" frameborder="0" allowfullscreen></iframe>
            </div>


            <div *ngIf="showHideHeaderDocument">              
              <span class="btn-file1" style="width: 250px;">
                Choose PDF file only<input type="file" id="testfile3" (change)="getDocumentFile($event)">
            </span>
                <p class="mb-1"> <span class="msgCreateSubLabel">{{filenameDocumentContainer  || 'No Document Selected'}}</span></p>
                <!-- <embed [src]="_documentUrl" type="application/pdf"   height="300px" width="100%" class="responsive"> -->
                  <a [href]="_documentUrl" target="_blank" *ngIf="_documentUrl !=''" style="color: #ff5e2d;font-weight: 600;font-size: 14px;">Download Pdf</a>
            </div>


            <p class="text-right ms-add-variable m-0" *ngIf="showHideHeaderText">
              <mat-icon style="vertical-align: middle;cursor: pointer;">add</mat-icon><span
                (click)="headerAddVariable()" class="ml-2" style="cursor: pointer;">Add Variable </span>
            </p>
          </div>


          <div  *ngIf="isShowHeaderVariableText">
            <table style="width:100%">
              <tr>
                <td> 
                  <div class="input-label-group mtp12 mb-4">
                    <input matInput autocomplete="off" [(ngModel)]="_headerAddVarValue" formControlName="headerAddVarValue"
                      maxlength="100" placeholder="Enter Add Variable Value">                   
                  </div>        
                </td>
                <td style="width:50px;" class="text-right">
                  <span><mat-icon class="v-del-hdr-var" (click)="delHeaderTB()">delete</mat-icon></span>
                </td>
              </tr>
            </table>
          </div>

     




          <div class="ms-mtAdjust">
            <span class="msgCreateLabel">Body</span>
            <span class="msgCreateSubLabel"> Enter the text for your message in the language you’ve selected.</span>

			<input class="mr-2 biuCls" type="button" value="Bold" (click)="formatText ('b');" />
			<input class="mr-2 biuCls" type="button" value="Italic" (click)="formatText ('i');" />
			<input class="mr-2 biuCls" type="button" value="Underline" (click)="formatText ('u');" />
			<!-- <input class="mr-2" type="button" value="None" (click)="formatText ('Not');" /> -->


            <div class="input-label-group mtp12">
              <span class="vkSuffix" style="right: 30px;top: 23px;left:unset;">{{bodyCount}}/1024</span>
              <textarea matInput id="tAreaID" autocomplete="off" #boxBody (keyup)="countKeyUPBody(boxBody,$event)"
                style="resize: none;padding-top: 20px;" rows="8" required formControlName="bodyMessage" maxlength="1024"
                [(ngModel)]="_bodyAddVariable" (paste)="pasteBody($event)" (keydown)="bodyKeyDown(boxBody,$event)"></textarea>

            </div>
            <p class="mb-1" style="color: gray;font-size: 13px;">Characters {{bodyCount}}/1024</p>
            <p class="text-right ms-add-variable m-0" *ngIf="bodyFirstTimeAdd">
              <mat-icon style="vertical-align: middle;cursor: pointer;position: relative;bottom: 18px;">add</mat-icon>
              <span (click)="bodyAddVariable(1,'Nopaste')" class="ml-2"
                style="cursor: pointer;position: relative;bottom: 18px;">Add Variable </span>
            </p>

          </div>




          <div>
            <table style="width:100%">
              <tr *ngFor="let element of bodyformArray.controls;let i=index;let last=last;">
                <td *ngFor="let c of displayedFields">
                  <div class="input-label-group mtp12 mb-4">
					<!-- (change)="vkChangeTB(i,element.get(c))" -->
                    <input matInput autocomplete="off" maxlength="100" [formControl]="element.get(c)" (change)="changeBodyValTxtBox(i,element,element.get(c))"
                      placeholder="Enter Add Body Variable Value {{i+1}} ">
                  </div>
                </td>
                <td>
                  <mat-icon class="bdelIcn1" (click)="deleteBodyRow(i)">delete</mat-icon>
                </td>
                <td >
                  <div *ngIf="last" class="text-right ms-add-variable m-0" >
                    <mat-icon style="vertical-align: middle;cursor: pointer;position: relative;bottom: 18px;">add</mat-icon>
                    <span (click)="bodyAddVariable(i+2,'Nopaste')" class="ml-2"
                      style="cursor: pointer;position: relative;bottom: 18px;">Add Variable </span>
                  </div>
                </td>
              </tr>
            </table>
          </div>


          <div class="ms-mtAdjust">
            <span class="msgCreateLabel">Footer <span class="vk-opt"> Optional </span></span>
            <span class="msgCreateSubLabel"> Add a title or choose a media type
            </span>
            <div class="input-label-group mtp12">
              <input matInput autocomplete="off" required [(ngModel)]="_footerAddVariable" formControlName="footerTitle"
                maxlength="60" #box2 (keyup)="countKeyUPFooter(box2)" (keydown.enter)="$event.preventDefault()">
              <span class="vkSuffix">{{footerCount}}/60</span>
            </div>
            <p class="text-right ms-add-variable m-0" style="display: none;">
              <mat-icon style="vertical-align: middle;cursor: pointer;">add</mat-icon><span
                (click)="footerAddVariable()" class="ml-2" style="cursor: pointer;">Add Variable </span>
            </p>
          </div>

          <div class="ms-mtAdjust">
            <span class="msgCreateLabel">Buttons <span class="vk-opt"> Optional </span></span>
            <span class="msgCreateSubLabel"> Create up to 2 buttons that let customers respond to your message or take
              action
            </span>
            <div class="input-label-group">
              <mat-select class="mulSel " required formControlName="buttonTitle" [(ngModel)]="quickCallModelDDl"
                (selectionChange)="onChangeButton($event.value)" style="padding: 1em 0.65em;">
                <mat-option>None</mat-option>
                <mat-option value="callToAction"> Call To Action</mat-option>
                <mat-option value="quickReply"> Quick Reply</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">

              <div class="VkActionBtn" *ngIf="showHideButtonDiv">
                <div class="ms-mtAdjust">
                  <p class="text-right"> <span (click)="addClick()" class="addRowcs">Add</span> </p>
                  <div class="row v-part1" *ngIf="ShowHide_For_CallPhone">
                    <div class="col-md-3">
                      <label class="mLabel"> Action TYpe</label>
                      <!-- <mat-select class="mulSel1 " style="border: solid 1.5px #cdcdcd;" formControlName="actionTypeControl" (selectionChange)="changeActionBtn($event.value)">
      <mat-option value="callPhone"> Call Phone Number</mat-option>
      <mat-option value="visiteWebsite"> Visit Website</mat-option> 
    </mat-select> -->
                      <div class="input-label-group" style="margin-top: -4px;">
                        <input matInput autocomplete="off" formControlName="CallPhoneModel" style="padding: 0.4em 0.6em;font-size: 14px !important;
      height: 30px;background-color: #f5f5f5 !important;cursor: not-allowed;" readonly>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label class="mLabel">Button Text</label>
                      <div class="input-label-group" style="margin-top: -4px;">
                        <input matInput maxlength="20" autocomplete="off" formControlName="btnActionCallModel"
                          #boxBtnTextCall (keyup)="countKeyUPBtnTextCall(boxBtnTextCall)" style="padding: 0.4em 0.6em;">
                        <span class="vkSuffix1">{{btnTextCountCall}}/20</span>
                      </div>

                    </div>
                    <div class="col-md-2">
                      <label class="mLabel">Country</label>
                      <mat-select class="mulSel1 " style="border: solid 1.5px #cdcdcd;" formControlName="stdCodeModel">
                        <mat-option value="+91"> +91</mat-option>
                        <mat-option value="+1">+1</mat-option>
                      </mat-select>
                    </div>
                    <div class="col-md-3">
                      <label class="mLabel">Phone No.</label>
                      <div class="input-label-group" style="margin-top: -4px;">
                        <input matInput autocomplete="off" maxlength="10" formControlName="btnActionphoneModel"
                          #boxPhone1 (keyup)="countKeyUPPhone(boxPhone1)" style="padding: 0.4em 0.6em;">
                        <span class="vkSuffix1">{{phoneCount}}/15</span>

                      </div>
                    </div>
                    <div class="col-md-1"><span (click)="deleteRow(0)"><br>
                        <mat-icon style="position: relative;top: 8px;color: gray;cursor: pointer;">
                          remove_circle_outline</mat-icon>
                      </span></div>
                  </div>




                  <div class="row v-part1" *ngIf="ShowHide_For_Visit_Website">
                    <div class="col-md-3">
                      <label class="mLabel"> Action TYpe</label>
                      <div class="input-label-group" style="margin-top: -4px;">
                        <input matInput autocomplete="off" formControlName="visitWebsiteModel"
                          style="padding: 0.4em 0.6em;font-size: 14px !important;height: 30px;background-color: #f5f5f5 !important;cursor: not-allowed;"
                          readonly>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label class="mLabel">Button Text</label>
                      <div class="input-label-group" style="margin-top: -4px;">
                        <input matInput maxlength="20" autocomplete="off" formControlName="btnActionVisitModel"
                          #boxBtnTextVisit (keyup)="countKeyUPBtnTextVisit(boxBtnTextVisit)"
                          style="padding: 0.4em 0.6em;">
                        <span class="vkSuffix1">{{btnTextCountVisit}}/20</span>
                      </div>

                    </div>
                    <div class="col-md-2">
                      <label class="mLabel">URL Type</label>
                      <mat-select class="mulSel1 " style="border: solid 1.5px #cdcdcd;"
                        formControlName="btnActionUrlModel" (selectionChange)="urlTypeChange($event.value)">
                        <mat-option value="static"> Static</mat-option>
                        <mat-option value="dynamic"> Dynamic</mat-option>
                      </mat-select>
                    </div>
                    <div class="col-md-3">
                      <label class="mLabel">Website</label>
                      <div class="input-label-group" style="margin-top: -4px;">
                        <input matInput autocomplete="off" maxlength="300" style="padding: 0.4em 0.6em;"
                          [(ngModel)]="_webAddVariable" formControlName="btnActionWebsiteModel" #boxWeb (keyup)="countKeyUPWebsite(boxWeb,$event)" (paste)="pasteWebText(boxWeb, $event)">

                      </div>
                      <p class="text-right m-0 avx1" *ngIf="isShowForDynamic">
                        <mat-icon style="vertical-align: middle;cursor: pointer;">add</mat-icon>
                        <span class="ml-2" style="cursor: pointer;" (click)="websiteAddVariable()">Add Variable </span>
                      </p>

                   
                      <div *ngIf="isShowWebVariableText">
                      <table style="width:100%">
                        <tr>
                          <td> 
                            <div class="input-label-group">
                              <input matInput autocomplete="off" [(ngModel)]="_webAddVarValue" style="width: 135px;" 
                                formControlName="webAddVarValue" maxlength="100" placeholder="Enter Add Variable">
                            </div>


                          </td>
                          <td style="width:50px;" class="text-right">
                            <span><mat-icon class="v-del-hdr-var" style="top: 14px;" (click)="delWebTB()">delete</mat-icon></span>
                          </td>
                        </tr>
                      </table>
                      </div>

                    </div>
                    <div class="col-md-1"><span (click)="deleteRow(1)"><br>
                        <mat-icon style="position: relative;top: 8px;color: gray;cursor: pointer;">
                          remove_circle_outline</mat-icon>
                      </span></div>
                  </div>


                </div>
              </div>
              <div class="VkActionBtn" *ngIf="showHideButtonDiv2">
                <div class="ms-mtAdjust">
                  <p class="text-right"> <span (click)="addClickQuick()" class="addRowcs">Add</span> </p>
                  <div class="row v-part1" *ngFor="let v of rowRecordQuick; let i = index;let first=first">
                    <div class="col-md-5">
                      <label class="mLabel">Button Text</label>
                      <div class="input-label-group" style="margin-top: -4px;">
                        <input matInput maxlength="20" autocomplete="off" (change)="chaneButtonEvent(boxBtnTextQuick,i,v)"
                          #boxBtnTextQuick (keyup)="countKeyUPBtnTextQuick(boxBtnTextQuick,i+1)" [value]="v.first1"
                          style="padding: 0.4em 0.6em;" >
                        <span class="vkSuffix1" *ngIf="i+1==1">{{btnTextCountQuick1}}/20</span>
                        <span class="vkSuffix1" *ngIf="i+1==2">{{btnTextCountQuick2}}/20</span>
                        <span class="vkSuffix1" *ngIf="i+1==3">{{btnTextCountQuick3}}/20</span>
                      </div>
                    </div>
                    <div class="col-md-1" *ngIf="!first"><span (click)="deleteQuickRow(i)"><br>
                        <mat-icon style="position: relative;top: 8px;color: gray;cursor: pointer;">
                          remove_circle_outline</mat-icon>
                      </span></div>
                  </div>
                </div>


              </div>
            </div>


            <div class="text-right" *ngIf="!itemEdit">
              <button class="btn-create-ms" (click)="submitBtn(createdForm,'forCreate')">Create</button>
            </div>
            <div class="text-right mt-2" *ngIf="itemEdit">
              <button class="btn-create-ms" (click)="submitBtn(createdForm,'forModify')">Update</button>
            </div>

          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12 mt-4">

        <div class="containerOne" style="z-index: 999999;">
          <div class="ms-centeredText ms-scrolly">
            <div>
              <mat-card class="msCardy">

                <div  *ngIf="isShowEyeOn"> <p class="vk-header-title">{{_headerPreview1}}</p></div>
                <div  *ngIf="isShowEyeOff"> <p class="vk-header-title"> {{_headerPreview2}}
                  <!-- [ {{_headerAddVarValue}} ] -->
                </p></div>


                <p *ngIf="showHideHeaderImage" class="text-center">
                  <mat-icon style="color: #ff6c00;">image</mat-icon><br>
                  <img [src]="_headerPreviewImageUrl" *ngIf="_headerPreviewImageUrl !=''"
                    style="width: 100px;border: 2px solid #ccc;border-radius: 4px;" /></p>
                <p *ngIf="showHideHeaderVideo" class="text-center">
                  <mat-icon style="color: #ff6c00;">video_library</mat-icon><br>
                  <video *ngIf="filenameContainerVideo !=''" id="preVidId" controls style="width: 180px;border-radius: 4px;border: 2px solid #ccc; padding: 1px;" >
                    <source [src]="_headerPreviewVideoUrl" type="video/mp4">
                  </video>                 
                </p>

              <div *ngIf="forModifyVideoUrl">
                <iframe width="200" height="200"  [src]="hdrVideoUrl" frameborder="0" allowfullscreen></iframe>
              </div>




                <p *ngIf="showHideHeaderDocument" class="text-center"> 
                   <mat-icon style="vertical-align: text-top;margin-right: 8px;color: #ff6c00;">picture_as_pdf</mat-icon><span>{{filenameDocumentContainer}} </span>
                  </p>
                <!-- <p style="font-family: poppins;font-size:12.5px;">{{_bodyMsgPreview}}</p> -->

                <p style="font-family: Poppins;font-size:12.5px;" id="resultBodyTemplate1"></p>     
   
                <p style="font-family: Poppins;font-size:12.5px;" id="resultBodyTemplate2"></p>


                <p class="vk-footer-title">{{_footerPreview}}</p>
              </mat-card>
              <p class="mt-2" *ngIf="showHideButtonDiv">
                <button *ngIf="ShowHide_For_CallPhone" class="button-vk-cs" style="margin: 5px 0;">
                  <mat-icon style="vertical-align: middle;" class="mr-2">call</mat-icon>{{phoneButtonValueForPreview}}
                </button>
                <button *ngIf="ShowHide_For_Visit_Website" class="button-vk-cs" style="margin: 5px 0;">
                  <a href="https://www.contiinex.com/" target="_blank" style="color: #3096fc;">
                    <mat-icon style="vertical-align: middle;" class="mr-2">call_to_action</mat-icon>
                    {{visitWebValueForPreview}}
                  </a>
                </button>
              </p>
              <p class="mt-2" *ngIf="showHideButtonDiv2"><button *ngFor="let b of listQuickButtonModel;let i=index;"
                  class="button-vk-cs" style="margin: 5px 0;">{{b.text}}</button></p>
            </div>
          </div>
          <img class="img-responsive pImgWidth" src="../assets/contiinex/image/ms-preview-img.png" alt="Preview" />

          <p class="text-center mb-1" style="position: fixed;bottom: -9px;" *ngIf="isShowEyeOn"><mat-icon class="eyeIcon1" (click)="eyeVisibilty('on')">visibility</mat-icon></p>
          <p class="text-center mb-1" style="position: fixed;bottom: -9px;" *ngIf="isShowEyeOff"><mat-icon class="eyeIcon1" (click)="eyeVisibilty('off')">visibility_off</mat-icon></p>
        </div>

      </div>
      <div class="col-md col-sm-12"> </div>
    </div>

  </mat-card>
</form>

<div id="cover-spin" *ngIf="currentLoader"></div>