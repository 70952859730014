import { Pipe, PipeTransform } from '@angular/core';
import { MyserviceService } from './myservice.service';

@Pipe({ name: 'localstring' })
export class LocalStringPipe implements PipeTransform {
	transform(value: any, ...args: any[]) {
		if (!value) {
			value = 0;
		}
		return Number(value).toLocaleString('en-IN')
	}
}

@Pipe({ name: 'hms' })
export class HmsStringPipe implements PipeTransform {
	transform(value: any, ...args: any[]) {
		if (!isNaN(value)) {
			var sec_num = parseInt(value, 10); // don't forget the second param
			var hours: any = Math.floor(sec_num / 3600);
			var minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
			var seconds: any = sec_num - (hours * 3600) - (minutes * 60);

			if (hours < 10) { hours = "0" + hours; }
			if (minutes < 10) { minutes = "0" + minutes; }
			if (seconds < 10) { seconds = "0" + seconds; }
			return hours + ':' + minutes + ':' + seconds;
		}
		return '00:00:00'
	}
}

@Pipe({ name: 'mask_number' })
export class MaskPhoneNumber implements PipeTransform {
	constructor(private myservice:MyserviceService){
		
	}
	transform(value: string, defaults:any, ...args: any[]) {
		console.log('pipe', value, defaults);
		try {
			if(!value || !value.length){
				return defaults ? defaults : value;
			}
			const hcd = this.myservice.getSettings_by_type_name('extraoptions', 'hcd')
			if(hcd){
				if(hcd.definition == 'true'){
					return value.slice(0, -6) + 'XXXXXX';
				}
			}
			return value;
		} catch (e) {
			return value;
		}
	}
}