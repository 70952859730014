import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';

import { ApiService } from '../../api.service';
import {MyserviceService} from '../../myservice.service'
@Component({
  selector: 'app-client-status',
  templateUrl: './client-status.component.html',
  styleUrls: ['./client-status.component.scss']
})
export class ClientStatusComponent implements OnInit {
	clientForm: FormGroup = new FormGroup({
		'upload_file': new FormControl(''),
		'file_source': new FormControl('')
	})
	currentFileName:any="";
	passingMessage: any;
	_color: any = "green"
	api_end_point:any;
	constructor(
		public dialogRef: MatDialogRef<ClientStatusComponent>,
		private API: ApiService,
		private myservice: MyserviceService) { }

	ngOnInit() {
		this.api_end_point = this.myservice.get_API_End_Point();
	}
	closePopup() {
		this.clientForm.reset();
		this.dialogRef.close();
		this.passingMessage = null;
	}

	changeFile(event) {
		//debugger;	
		if (event.target.files && event.target.files.length) {
			//console.log(event.target.files)
			const [upload_file] = event.target.files;
			//console.log('upoad file', upload_file)
			this.clientForm.patchValue({
				file_source: upload_file
			});
			this.currentFileName = event.target.files[0].name
		  }
	}

	download_file() {
		this.API.getClientUploadTemplate(this.api_end_point).subscribe(
			(res:HttpResponse<any>) => {
			//	debugger;
				// console.log('printing res', res);
				let content_type = res.headers.get("content-type");
				let file_data = res.headers.get("content-disposition");
				let p = file_data.split(';')[1];
				let file_name = p.slice(p.indexOf('"') + 1, p.length - 1);
				let body = res.body;
				let blob: any = new Blob([body], { type: content_type });
				let url = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.download = file_name;
				anchor.href = url;
				debugger;
				anchor.click();
			},
			(err) => {
			//	debugger;
				//console.log('printing errors',err)
				alert('Error in downloading the file')
			}
		)
	}
	submit() {
		//console.log('client form', this.clientForm);
		if(this.clientForm.get('upload_file').value == "")
		{
			 this.passingMessage = "Please Select the File";
			 this._color = "red";
			 setTimeout(()=>{
				this.passingMessage = "";
				this._color = "green";
			},2000);
		}else{
			let bulk_file = this.clientForm.get('file_source').value;
			let formData = new FormData();
			formData.append('attachment', bulk_file, bulk_file.name);
			this.API.clientStatusUploadAPI(formData,this.api_end_point).subscribe((res: any) => { 
				if (res.errors) {
					// alert(res.errors)
					this.passingMessage = res.errors;
					this._color = "red";
				} else {
					// alert(res.success)
					this.passingMessage = res.success;
					this._color = "green";
				}
				setTimeout(()=>{
					this.closePopup();
					this.passingMessage = "";
					this._color = "green";
				},2000);
				
			}, (err) => { 
					alert('Error in the API')
			});
		}
		
	}
}
