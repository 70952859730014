import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../api.service';
import { MyserviceService } from 'src/app/myservice.service';

@Component({
  selector: 'app-payment-dashboard-history',
  templateUrl: './payment-dashboard-history.component.html',
  styleUrls: ['./payment-dashboard-history.component.scss']
})
export class PaymentDashboardHistoryComponent implements OnInit {
  displayedColumns: string[] = ['created_at', 'base_price_type', 'base_price_val','surge_price_type','surge_price_threshold','surge_price_val'];
 dataSource:any = new MatTableDataSource();
 table_data:any=[];
 currency_code:any;
 api_end_point:any;
 
 @ViewChild(MatPaginator,{static: true}) paginator: MatPaginator;
  constructor(private API : ApiService,private myService: MyserviceService) { }

  ngOnInit() {
    // this.table_data = [
    //   {date: '12/10/2021', type1: 'Per Call',price1:'100', type2: '100', minVal: '111',price2:'222'}
    // ];
    this.getTableData();
	this.api_end_point = this.myService.get_API_End_Point();
  }
  getTableData(){
    let obj="";
	this.API.settingsAPI(this.api_end_point).subscribe((data: any) => {
		if (data.errors) {
		//	console.log(data.errors);
			return;
		}
		if (data.success) {
		//	console.log(data.success);
			var setting = data.success;

			for (const currencysetting of setting.payment) {
				if (currencysetting.name == "currency_code") {
				//	console.log("currency Symbol is ",currencysetting.definition);
				this.currency_code= currencysetting.definition;
				} 
			}
		}
	})
  this.API.getPaymentHistory(obj,this.api_end_point).subscribe((data: any) => {
    //debugger;
    let _response = data.success;
    if (_response) {
      this.table_data=_response;
      this.dataSource = new MatTableDataSource(this.table_data);
      this.dataSource.paginator = this.paginator;
    }
  });
}

}



