import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-onboarding-process-page5',
  templateUrl: './onboarding-process-page5.component.html',
  styleUrls: ['./onboarding-process-page5.component.scss']
})
export class OnboardingProcessPage5Component implements OnInit {

  constructor(private router:Router) { 
    window.scroll(0,0);
  }


  ngOnInit(): void {
  }
  onNextBtn(){
    this.router.navigateByUrl('/onboarding_process_page6')
      }
}
