import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'underscore';

import { ServicesService }  from '../services.service';

@Component({
	selector: 'app-attribute-table',
	templateUrl: './attribute-table.component.html',
	styleUrls: ['./attribute-table.component.scss']
})
export class AttributeTableComponent implements OnInit {

	@Input() tableDatatype: any;

	dataSource = [];
	table_data_columns = null;
	displayed_columns = [];

	constructor(private service: ServicesService) { 
		// console.log('On construct', this.tableDatatype);
	}

	ngOnInit(): void {
		// console.log('onInit', this.tableDatatype);
	}

	ngAfterViewInit(): void{
		setTimeout(() => {
			this.dataSource = this.service.table_data_map[this.tableDatatype]['table_data'];
			this.table_data_columns = this.service.table_data_map[this.tableDatatype]['column_map'];
			this.displayed_columns = _.pluck(this.table_data_columns, 'key');
		}, 0);
		// console.log('After view init', this.dataSource);
	}

}
