import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import{HttpClient, HttpHeaders,HttpResponse }from '@angular/common/http';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MyserviceService } from 'src/app/myservice.service';

@Component({
  selector: 'app-contiinex-setting-template-edit-dialog',
  templateUrl: './contiinex-setting-template-edit-dialog.component.html',
  styleUrls: ['./contiinex-setting-template-edit-dialog.component.scss']
})
export class ContiinexSettingTemplateEditDialogComponent implements OnInit {

  createdForm:FormGroup;
 

  project:any;
  customer_name:any;
  business_process:any;
  businessType:any;
  groupid:any;

  constructor(public dialogRef: MatDialogRef<ContiinexSettingTemplateEditDialogComponent>, private router: Router, private _service:MyserviceService,
    private http:HttpClient,private fb:FormBuilder,private API: ApiService,private _snackBar: MatSnackBar,	@Inject(MAT_DIALOG_DATA) public viewEditData)
     {

      this.createdForm=fb.group({
        notificationEvent:['',[Validators.required]],
        courierPartnerAccount:['',Validators.required],
        templateName:['',Validators.required],
        templateType:['',Validators.required],
        callBackURL:['',Validators.required],
        languageCode:['',Validators.required],
        params:['',Validators.required]
      })
    }

  ngOnInit() {
    if (this._service.get_loggedin_user() && this._service.get_loggedin_user().company) {
      this.customer_name = String(this._service.get_loggedin_user().company);
      this.project = String(this._service.get_loggedin_user().project);
      this.business_process = String(this._service.get_loggedin_user().business_process);
      this.businessType = String(this._service.get_loggedin_user().businessType);
      this.groupid = this._service.get_loggedin_user().groupid;
    }
    // if(this.viewEditData){
    //   //this.createdForm.controls['notificationEvent'].patchValue(this.viewEditData.name);  
    // }
    // else{     
    // }
  }
  submitBtn(createdForm:any){
    let _notificationEvent=createdForm.controls.notificationEvent.value;
    let _courierPartnerAccount=createdForm.controls.courierPartnerAccount.value;
    let _templateName=createdForm.controls.templateName.value;
    let _templateType=createdForm.controls.templateType.value;
    let _callBackURL=createdForm.controls.callBackURL.value;
    let _languageCode=createdForm.controls.languageCode.value;
    let _params=createdForm.controls.params.value;
    if(_notificationEvent=="" ||_notificationEvent==null ){
      this._snackBar.open("Please Select Notification Event", 'close', {duration: 5000,});     
      return false;
    }
    if(_courierPartnerAccount=="" ||_courierPartnerAccount==null ){
      this._snackBar.open("Please Select Courier Partner Account", 'close', {duration: 5000,});     
      return false;
    }
  
  let _obj ;

     _obj = {
      "notification_event": _notificationEvent,
      "courier_partner_account": _courierPartnerAccount,
      "template_name":  _templateName,
      "template_type":_templateType,
      "call_back_url": _callBackURL,
      "language_code":  _languageCode,
      "params":_params,
      "groupid": this.groupid,
    };

    this.dialogRef.close('true');
    // this.API.editSaveTempalateConfiguration(_obj).subscribe((res: any) => {
    //   if(res.errors){
    //     this._snackBar.open(res.errors, 'close', {
    //       duration: 5000,
    //     });

    //     return;
    //   }
    //   else {
    //     this._snackBar.open("Succesfully Done..!!", 'close', {
    //       duration: 5000,
    //     });     
    //     this.createdForm.reset();
     
    //   }
    // });



  }
  closePopup(){
    this.dialogRef.close('false')
  }



}

