<div class="mt-2">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">

      <!-- <div class="row mlr-0">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-card class="m-height">
                        <p class="text-right mb-0"><span>
                                <img src="../assets/seticon.svg">
                            </span> </p>
                        <p><span class="d-label"> {{_countLeadLength}}</span>
                            <span class="d-h-label">Leads Contacted  </span>
                        </p>
                    </mat-card>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-card class="m-height">
                        <p class="text-right  mb-0"><span>
                                <img src="../assets/tickicon.svg">
                            </span> </p>
                        <p><span class="d-label">{{_countfollowUpLength}} </span>
                            <span class="d-h-label">Follow - ups to do </span>
                        </p>
                    </mat-card>
                </div>
            </div> -->
            <!-- <div class="row mlr-0">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-card class="m-height">
                        <p class="text-right mb-0"><span>
                            <img src="../assets/auditscore.svg"/>
                            </span> </p>
                        <p><span class="d-label" style="font-size: 34px;"> {{_auditScore}}</span>
                            <span class="d-h-label">Audit Score </span>
                        </p>
                    </mat-card>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-card class="m-height">
                        <p class="text-right  mb-0"><span>
                            <img src="../assets/Icontimer.svg"/>
                            </span> </p>
                        <p><span class="d-label" style="font-size: 34px;">{{_auditTime}} </span>
                            <span class="d-h-label">Audit Time </span>
                        </p>
                    </mat-card>
                </div>
            </div> -->





      
            <div class="row">
                <div class="col-lg-{{colWd}} col-md-{{colWd}} col-sm-12">
            <div *ngIf="callStatus && callStatus.widgetUser && callStatus.customerInfo && callStatus.customerInfo.customer_number == this.callStatus.widgetUser.phone" class="text-right" style="line-height: 48px;">
                <a href={{widgetUser.external_url}} target="_blank" rel="noopener noreferrer" style="margin: 10px;"><span class="d-h-label" style="font-weight: 500;color: #000;">Customer On Page</span></a>
                <span *ngIf="colWd==9"><img src="../assets/web_images/expandOpen.svg" style="width: 34px;cursor: pointer;margin-bottom: 6px;" (click)="expandOpen()"/></span>
                    <span *ngIf="colWd==12"><img src="../assets/web_images/expandClose.svg" style="width: 34px;cursor: pointer;margin-bottom: 6px;"  (click)="expandClose()"/></span>
            </div>
                    <mat-card style="width: 100%;overflow: auto hidden;height: calc(100vh - 160px);" *ngIf="callStatus && callStatus.widgetUser && callStatus.customerInfo && callStatus.customerInfo.customer_number == this.callStatus.widgetUser.phone">
                        <!-- <img src="../assets/crm.png" class="wd-100"/> -->
                       
                        <iframe width="100%" height="100%"
                            [src]="transformUrl(widgetUser.external_url)"></iframe>

                    </mat-card>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12" *ngIf="colWd !=12">
                    <mat-card class="mb-2">
                        <span class="L-head hdCus" style="color: #000000;">Customer Info(CRM/ERP/WIDGET) </span>
                        <br/>
                        <!-- <img src="../assets/crm.png" class="wd-100" /> -->
                        <ul class="bulletcss" style="margin-top: 10px" *ngIf="callStatus && callStatus.widgetUser && callStatus.customerInfo && callStatus.customerInfo.customer_number == this.callStatus.widgetUser.phone">
                            <li>Name - {{callStatus.widgetUser.name}}</li>
                            <li *ngIf="callStatus.widgetUser.product">Product - {{callStatus.widgetUser.product}}</li>
                            <li *ngIf="callStatus.widgetUser.state">State - {{callStatus.widgetUser.state}}</li>
                            <li *ngIf="callStatus.widgetUser.message">Message - {{callStatus.widgetUser.message}}</li>
                         </ul>

                    </mat-card>
                    </div>
            </div>

        </div>
        <!-- <div class="col-lg-6 col-md-6 col-sm-12" >
            <div class="row mlr-0">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <mat-card class="m-height" style="padding: 50px;" *ngIf="!callStatus">
                        <table class="wd-100">
                            <tr>
                                <td >

                                    <span class="st-call-btn" (click)="startSession()">START SESSION</span>
                                </td>
                                <td>

                                    <span class="st-call-btn" (click)="startCallDial()">START CALL</span>
                                </td>
                                <td>
                                    <img src="../assets/dialpadIcon.svg" />
                                </td>
                            </tr>
                        </table>
                    </mat-card>
                </div>

            </div>
            <div class="row mlr-0" style="display: none;">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <mat-card >
                       <app-start-dial-dialog ></app-start-dial-dialog>
                    </mat-card>
                </div>
            </div>



            <div class="row mlr-0">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <mat-card>
                        <table class="wd-100">
                            <tr>
                                <td><span class="L-head">Project Overview </span> </td>
                            </tr>
                        </table>
                    </mat-card>
                </div>
            </div>


        </div> -->
    </div>
</div>
<audio #stream id="remoteAudio" controls style="display: contents;">
    <p>Your browser doesn't support HTML5 audio.</p>
</audio>