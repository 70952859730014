import { Component, OnInit,ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import {Router} from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { MyserviceService } from 'src/app/myservice.service';
import * as moment from 'moment';

@Component({
  selector: 'app-earnings-agent-plugin',
  templateUrl: './earnings-agent-plugin.component.html',
  styleUrls: ['./earnings-agent-plugin.component.scss']
})
export class EarningsAgentPluginComponent implements OnInit {

   displayedColumns = ['brand', 'day', 'time', 'callEarnings','uearned'];
  dataSource:any = new MatTableDataSource();  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  earningDetails:any;

  _totalBalance:any;
  _openingBalance:any ;
  _earned:any;
  _bonus:any;
  _closingBalance:any

  _noOfWorkingDays:any;
  workingDays:any;
  _doj:any;
  _currentProjectName:any;
  _monthName:any;
  todayDate:Date = new Date();
  fromDate:any;
  toDate:any;
  api_end_point:any
  constructor(private dialog: MatDialog,public router:Router, private API: ApiService, private myservice: MyserviceService
    ) { 
    }
    user: any = this.myservice.get_loggedin_user();
  ngOnInit() {  
	this.api_end_point = this.myservice.get_API_End_Point();
    this.getUearnedMonthPassbook();
    this.getUearnPassbookLifetime();
    let _today: any = new Date();
    let _startDate =new Date(_today.setDate(_today.getDate() - 30)).toISOString();
    let _endDate = new Date(_today.setDate(_today.getDate() + 30)).toISOString() ;
    this.getUearnDayWisePassbook(_startDate,_endDate);
   }

  getUearnedMonthPassbook(){
    let _today: any = new Date();
    let _startDate = _today.toISOString();
    let obj = {
      "start_time": _startDate
    }
    this.API.agentPluginGetUearnedMonthPassbook(obj,this.api_end_point).subscribe((data: any) => {
      let _response = data.success;
      if (_response) {

      }
    });
  }
  getUearnPassbookLifetime(){
    let userid = this.user.id;
    let obj = {
      "userid": userid
    }
    this.API.agentPluginGetUearnPassbookLifetime(obj,this.api_end_point).subscribe((data: any) => {
      let _response = data.success;
      if (_response) {
     this.earningDetails=_response;
     this._noOfWorkingDays=this.earningDetails.agent.wd.working_days;
     this.workingDays=this.earningDetails.agent.wd.value;
     this._doj=moment((this.earningDetails.agent.doj.value).substring(0,10)).format('DD MMM yyyy');
      }
    });
  }
  getUearnDayWisePassbook(_startDate,_endDate){
    let userid = this.user.id;
    let obj = {
      "userid": userid,
      "start_time": _startDate,
      "end_time": _endDate,
      "agents_list":userid,
      "company":''
    }
    this.API.agentPluginGetUearnDayWisePassbook(obj,this.api_end_point).subscribe((data: any) => {
      let _response = data;
      if(data.errors=="No Data"){
        alert(data.errors);
        this._totalBalance=0;   
        this._openingBalance	=0;
        this._earned	=0 ;
        this._bonus	=0;
        this._closingBalance=0;      
        this._currentProjectName="--";
        let ELEMENT_DATA=[];
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
return;
      }
      if (_response) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
                           ];
          const d = new Date();
          this._monthName= monthNames[d.getMonth()];      
          let _summary=data.summary;   
          this._totalBalance=_summary.total_balance;   
           this._openingBalance	=_summary.opening_balance;
           this._earned	=_summary.earnings ;
           this._bonus	=_summary.bonus ;
           this._closingBalance=_summary.closing_balance;      
           this._currentProjectName=this.earningDetails.projects.list[0].name;

      }
      let ELEMENT_DATA=data.success;
      this.dataSource = new MatTableDataSource(ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    });
  }
  goBtn(){
let sDate=this.fromDate.toISOString();
let eDate=this.toDate.toISOString();
this.getUearnDayWisePassbook(sDate,eDate);

  }
  onDate() {
      if(this.fromDate > this.toDate){
        let _todate=this.toDate;
        this.fromDate=_todate;
      }
  }
}

