<mat-drawer-container autosize>
<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
	<app-menu  [navigateLink]="currentTitle" ></app-menu>
</mat-drawer>
<app-logout></app-logout>
<div class="CN-Main">
    <p class="CN-Tab-head">
      <!-- Profile Settings -->
      WhatsApp Businesss Account Settings
    </p>
    <div>
        <div class="row pSetTabList">
            <div class="col-md-12" style="padding: 0px 3.5px;">
                <mat-button-toggle-group class="dashPropSetcs" #group="matButtonToggleGroup"
                    [value]="selectedButtonToggleVal" (change)="onToggleButtonValChange(group.value)">
                    <mat-button-toggle value="account">WhatsApp Account</mat-button-toggle>
                    <mat-button-toggle value="profile">Business Profile</mat-button-toggle>
                    <mat-button-toggle value="configure">Dev Config</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div *ngIf="showHideForAccountTab">
            <mat-card class="propSetCard">
                <div class="row">
                    <div class="col-md-6" *ngFor="let v of psSettingData">
                        <div class="psDiv1" [ngStyle]="{'border': '1.5px solid' + v.borderColor,'background-color':v.bColor}">
                          <span class="spx2">{{v.title}}</span>
                          <span class="spx1">{{!v.desc?'Not Available':v.desc}}</span>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div *ngIf="showHideForProfileTab">

            <mat-card class="propSetCard propSetCard1">
                <form [formGroup]="createdForm">
                <div style="text-align: right;position: relative;right: 6px;">

                    <button class="psEdit mt-0 ml-3" *ngIf="showHideForCancel" mat-button (click)="cancelBtn()" style="width: 75px;">Cancel </button>
                    <button class="psEdit mt-0 ml-3" *ngIf="showHideForEdit" mat-button (click)="editBtn(createdForm)">EDIT </button>
                    <button class="psSave mt-0 ml-3" *ngIf="showHideForSave" mat-butto [ngClass]=" !createdForm.valid ? 'cls11' : 'psSave'" [disabled]="createdForm.invalid" (click)="saveProfileSetting(createdForm)">SAVE</button>
                    <button class="psEdit mt-0 ml-3" style="color: #b48507 !important;border: 1px solid #b48507 !important;width: 85px;" mat-button (click)="previewBtn(createdForm)">PREVIEW </button>
                  </div>




                <h3 class="psTitle">Business Profile</h3>
                <div class="row mt-4 mb-4" style="margin-top: 2.5rem !important;">
                    <div class="col-md-6">
                        <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': psProfileData.businessNameRO==true}">
                            <mat-label>Company Name</mat-label>
                            <input autofocus matInput placeholder="Give your company name here"  formControlName="businessName" autocomplete="off" [readonly]="psProfileData.businessNameRO">
                          </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': psProfileData.displayNameRO==true}">
                            <mat-label>Display Name</mat-label>
                            <input matInput placeholder="Give a short display name"  formControlName="displayName" autocomplete="off" [readonly]="psProfileData.displayNameRO">
                          </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': isDisabled==true}">
                          <mat-label>Company Description</mat-label>
                            <textarea matInput style="resize: none;height:100px" placeholder="Give a description of your company (max 256 characters)" formControlName="businessDescription" autocomplete="off" [readonly]="isDisabled"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': isDisabled==true}">
                            <mat-label>Company Website</mat-label>
                            <textarea matInput style="resize: none;height:100px" placeholder="Give your Company Website link(max 256 characters)" formControlName="websiteURL" autocomplete="off" [readonly]="isDisabled"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': psProfileData.businessManagerIDRO==true}">
                            <mat-label>FB Business ID</mat-label>
                            <input matInput placeholder="Give your FB Business Manager ID" formControlName="businessManagerID" autocomplete="off" [readonly]="psProfileData.businessManagerIDRO">
                          </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': psProfileData.waNumberRO==true}">
                          <mat-label>Business Phone Number</mat-label>
                          <input matInput placeholder="Business Phone" maxlength="15" formControlName="waNumber" autocomplete="off" [readonly]="psProfileData.waNumberRO" (keypress)="onlyNumberWithPlusValidation($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 mb-4 mt-4">










                      <table>
                        <tr>
                          <td>
                            <span style="display: block;font-size: 14px;color: #939393;line-height: 38px;">Company Logo (Square 350 x 350 pixels)</span>
                                    <span class="imgDivx1"> <img [src]="_imgRender" id="preview"
                                                                 style="max-width: 160px;
                                            height: auto;
                                            border-radius: 10px;
                                            min-width: 100px;
                                            min-height: 100px;"></span>
                          </td>
                          <td *ngIf="showHideForSave"  class="forWeb1">
                              <br><br>
                          <label class="labelxx" for="test">
                            <button class="btnUploadxx">UPLOAD LOGO</button>
                            <input type="file" id="testfile" class="filexx" formControlName="uploadFileImg"
                                   (change)="uploadImageChange($event,$event.target.files)" >
                          </label>
                            <span class="spanxx">(Jpg, Gif or PNG)</span>
                            <span class="spanxx">{{filenameContainer}}</span>
                          </td>
                        </tr>
                        <tr class="forMob1">
                            <td *ngIf="showHideForSave" colspan="2">
                                <label class="labelxx" for="test">
                                  <button class="btnUploadxx">UPLOAD LOGO</button>
                                  <input type="file" id="testfile" class="filexx" formControlName="uploadFileImg"
                                         (change)="uploadImageChange($event,$event.target.files)" >
                                </label>
                                  <span class="spanxx">(Jpg, Gif or PNG)</span>
                                  <span class="spanxx">{{filenameContainer}}</span>
                                </td>
                        </tr>
                      </table>









                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': isDisabled==true}">
                          <mat-label>Support Email</mat-label>
                          <input matInput placeholder="Support Email (max 128 characters)" formControlName="alertEmail" autocomplete="off" [readonly]="isDisabled">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': isDisabled==true}">
                          <mat-label>Industry Vertical</mat-label>
                          <mat-select placeholder="Business Vertical" formControlName="businessType" [readonly]="isDisabled">
                            <mat-option value="ADVERTISING">ADVERTISING</mat-option>
                            <mat-option value="AUTOMOTIVE">AUTOMOTIVE</mat-option>
                            <mat-option value="CONSUMER_PACKAGED_GOODS">CONSUMER_PACKAGED_GOODS</mat-option>
                            <mat-option value="ECOMMERCE">ECOMMERCE</mat-option>
                            <mat-option value="EDUCATION">EDUCATION</mat-option>
                            <mat-option value="ENTERTAINMENT_AND_MEDIA">ENTERTAINMENT_AND_MEDIA</mat-option>
                            <mat-option value="FINANCIAL_SERVICES">FINANCIAL_SERVICES</mat-option>
                            <mat-option value="GAMING">GAMING</mat-option>
                            <mat-option value="GOVERNMENT_AND_POLITICS">GOVERNMENT_AND_POLITICS</mat-option>
                            <mat-option value="MARKETING">MARKETING</mat-option>
                            <mat-option value="ORGANIZATIONS_AND_ASSOCIATIONS">ORGANIZATIONS_AND_ASSOCIATIONS</mat-option>
                            <mat-option value="PROFESSIONAL_SERVICES">PROFESSIONAL_SERVICES</mat-option>
                            <mat-option value="RETAIL">RETAIL</mat-option>
                            <mat-option value="TECHNOLOGY">TECHNOLOGY</mat-option>
                            <mat-option value="TELECOM">TELECOM</mat-option>
                            <mat-option value="RETAIL">RETAIL</mat-option>
                            <mat-option value="OTHER">OTHER</mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <h3 class="psTitle">Company Details</h3>
                <div class="row mt-4 mb-4" style="margin-top: 2.5rem !important;">
                    <div class="col-md-6">
                        <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': isDisabled==true}">
                            <mat-label>Company Details</mat-label>
                            <textarea matInput style="resize: none;height:60px" placeholder="About Company (max 139 characters)" formControlName="companyDescription" autocomplete="off" [readonly]="isDisabled"></textarea>
                          </mat-form-field>
                    </div>
                    <div class="col-md-6">
                       <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': isDisabled==true}">
                         <mat-label>Company Address</mat-label>
                         <textarea matInput style="resize: none;height:60px" placeholder="Company Address (max 256 characters)" formControlName="companyAddress" autocomplete="off" [readonly]="isDisabled"></textarea>
                       </mat-form-field>
                    </div>
                </div>
                </form>
            </mat-card>
        </div>
        <div *ngIf="showHideForConfigureTab">
        <mat-card class="propSetCard propSetCard1">
          <form [formGroup]="createdForm">
            <h3 class="psTitle">WhatsApp Configurations</h3>
            <div class="row mt-4 mb-4" style="margin-top: 2.5rem !important;">
              <div class="col-md-6">
                <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': true}">
                  <mat-label>Your Webhook to receive message status</mat-label>
                  <textarea matInput style="resize: none;height:60px" placeholder="Message Status Callback" formControlName="statusCB" autocomplete="off" [readonly]="true"></textarea>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': true}">
                  <mat-label>Your Webhook to receive incoming messages</mat-label>
                  <textarea matInput style="resize: none;height:60px" placeholder="Message Received Callback" formControlName="receivedCB" autocomplete="off" [readonly]="true"></textarea>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field class="wdhx" [ngClass]="{'edityDisabled': true}">
                  <mat-label>Your Bearer Token</mat-label>
                  <textarea matInput style="resize: none;height:100px" placeholder="API Key" formControlName="bearerToken" [readonly]="true"></textarea>
                </mat-form-field>
              </div>
            </div>
          </form>
        </mat-card>
      </div>
    </div>
</div>
</mat-drawer-container>
<div id="cover-spin" *ngIf="currentLoader"></div>
