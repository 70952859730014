import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import{HttpClient, HttpHeaders,HttpResponse }from '@angular/common/http';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import {MyserviceService}from '../../myservice.service';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-login-agent',
  templateUrl: './login-agent.component.html',
  styleUrls: ['./login-agent.component.scss']
})
export class LoginAgentComponent implements OnInit {
  createdForm:FormGroup;
  API_End_Point:any;
  constructor(
    private router: Router,
    private http:HttpClient,
    private fb:FormBuilder,
    private _service:MyserviceService,
    private API: ApiService) { 

    this.createdForm=fb.group({
      email:['',[Validators.required,Validators.email]],
      password:['',[Validators.required,Validators.maxLength(50)]]
    })
  }

  ngOnInit(): void {
    window.scroll({top:0});
  }
  createAccount(){
this.router.navigateByUrl('/register')
  }

	loginBtn(createdForm: any) {
   

    let _email=createdForm.controls.email.value;
    let _password=createdForm.controls.password.value;
    let formData={
      "email": _email,
      "password": _password,
      "login_method": "smb"
	  }
    this.API.loginAPIApp(formData)
    .subscribe(
      (res) => {
        let _error=   res["errors"];
        if(_error=="Invalid Credentials" || _error=="User does not exist"){
         // alert("Invalid Credentials,User does not exist");
          alert(_error);
          return false;
        }
        else{           
			let access_token = res['access_token'];
			let landing_page = 'dashboard';
          if(access_token){
            this._service.set_access_token(access_token);
            this._service.set_loggedin_user(res);
			this.API_End_Point = res['platform_details']['API_End_Point'];
            this.API.teamAPI(this.API_End_Point)
            .subscribe(
              (res) => {
                  this._service.set_my_team(res);
                  this.API.settingsAPI(this.API_End_Point).subscribe(
                    (res:any) => {
						  if (res.success) {
							this._service.set_stage_settings(res.success);
							  if (res.success.web) {
								  for (var i in res.success.web) {
									  if (res.success.web[i]['name'] == 'landing_page') {
										  landing_page = res.success.web[i]['definition'];
									  }
								  }
							  }
						  } else {
							  alert('Error in fetching settings');
							  this._service.clearAllItems();
							  this.router.navigate(['/home']);
							  return;
						  }						  
						  this.API.getFullTeamData(this.API_End_Point).subscribe(
							  (res:any) => {
								  if (res.success) {
									  this._service.set_Full_team(res.success);
                    this.router.navigate(['/'+landing_page]); 
                    this.router.navigateByUrl('/agentPlugin');//vk
								  } else {
									  alert(res.error)
								  }
							  }, (err) => {
								  alert('Error in the full team fetch')
							}
						)	 
                    },
                    (err) => {
                      alert('Error in the settings api')
                    }
                  )
              },
              (err) => {
                // debugger;
                alert('Error in the api')
              }
            )
          }else{
            alert('Unathorized access. Contact tech support')
          }
        }
      },
      (err) => {
        alert("User does not exist")
      });
    return;       
    }
  
}

