<div class="container-fluid p-0">
    <div class="row no-gutters">
        <div class="col-md-12">
            <img src="../assets/web_images/macbook-4255890.png" class="img-fluid"
                style="min-height: 400px; object-fit: cover" alt="Responsive image" />
            <a href="https://uearn.ai"><img src="../assets/web_images/uearn_logo.svg" class="imgLog hiring-logo" /></a>
        </div>
    </div>
</div>
<div class="container">
    <div class="row no-gutters">
        <div class="col-md-12">
            <div class="card first-card mb-3">
                <div class="card-body">

                    <h2 class="card-title">
                        UEARN Hiring | Customer Support Jobs | Work From Home | Flexible
                        Timings
                    </h2>
                    <div class="mb-4">
                        <p class="card-text">
                            Job Role: Customer Support (Voice Process, Outbound)
                        </p>
                        <p class="card-text">
                            <small class="text-muted">Timings: Flexible (Minimum 6 hours, anytime between 9am & 9pm)
                                Monthly Income: Rs. 15,000 - 18,000 (Approx., Rs. 100 per hour *
                                No of hours worked in a month) Training Period Stipend: Rs. 3500
                                (12 days of training, only certified candidates get the
                                Stipend)</small>
                        </p>
                    </div>
                    <div class="mb-4">
                        <p class="card-text">
                            Mandatory Requirements:
                        </p>
                        <p class="card-text">
                            <small class="text-muted">• Minimum qualification is Graduation (should hold a valid full
                                time Graduation certificate)<br /> • English & Hindi languages (proficiency in both
                                languages is a must)<br /> • Must have a Laptop or a Computer (good working
                                condition)<br /> • Android mobile phone (preferably a latest version)<br /> • High speed
                                internet connectivity (preferably Broadband/Wifi) <br />• A silent room to work <br />•
                                Fresher or Experienced, any field welcome, should be 21+</small>
                        </p>
                    </div>
                    <div class="mb-4">
                        <p class="card-text">
                            Selection Process:
                        </p>
                        <p class="card-text">
                            <small class="text-muted">• 2 rounds of telephonic interviews<br /> • Selection of
                                candidates<br /> • Post Selection, all candidates must complete a training course before
                                final on-boarding</small>
                        </p>
                    </div>
                    <div class="mb-4">
                        <p class="card-text">
                            Training Period & Details:
                        </p>
                        <p class="card-text">
                            <small class="text-muted"> • Average 4 hours per day <br />• 12 days of basic training + 7
                                days of on-job training (OJT)<br /> • Successful completion of training will get you a
                                Certification and land you into operations</small>
                        </p>
                    </div>
                    <div class="mb-4">
                        <p class="card-text">
                            Operations & Details:
                        </p>
                        <p class="card-text">
                            <small class="text-muted"> • Once on the job, you start earning - Rs.100/per hour <br />• An
                                active hour includes: Dial + Talk + Disposition + Wrap <br />• If you log-in for 6 hours
                                per day, you get = Rs. 100 * 6 hours = Rs. 600/day <br />• If you work for 25 days in a
                                month, you get = Rs. 600/day * 25 days = Rs. 15,000/month</small>
                        </p>
                        <p class="card-text pt-3">
                            <small class="text-muted"> Please note:<br /> There is no restriction in the number of hours
                                you can log-in per day or the number of days you can work in a month, hence your earning
                                potential goes up for more log-in hours and more working days per month.</small></p>

                    </div>
                    <div class="mb-4">
                        <p class="card-text">
                            On-Job Requirements:
                        </p>
                        <p class="card-text">
                            <small class="text-muted"> • Respond promptly to customer inquiries<br /> • Resolve customer
                                complaints<br /> • Know the products inside and out so that you can answer queries and
                                resolve complaints <br />• Process orders, forms, applications, and requests <br />•
                                Keep records of customer interactions, transactions, comments and complaints <br />•
                                Maintain positive, empathetic and a professional attitude towards customers at all times
                                <br />• Ensure customer satisfaction and provide professional customer support</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid p-0 mt-2">
    <div class="d-flex flex-column align-items-center justify-content-center p-4 bd-highlight" style="background: #703A96;
    
    color: #fff;
">
        <h4>APPLY HERE</h4>
        <img src="../assets/web_images/next.svg" class="apply-img" />
    </div>

</div>

<form [formGroup]="hiringForm">
    <div class="container">
        <div class="row no-gutters">
            <div class="col-md-12">
                <div class="card form-card my-4">
                    <div class="card-body">
                        <p class="card-text my-3">
                            How did you get to know about this Job Opportunity? *
                        </p>
                        <div class="form-row">
                            <div class="col-md-5 col-sm-12">


                                <!-- <label for="selectSource">Choose</label> -->
                                <select class="custom-select mr-sm-2 " formControlName="source" id="selectSource"
                                    required [ngClass]="{ 'is-invalid': submitted && f.source.errors }">
                                    <option value="facebook">Facebook</option>
                                    <option value="linkedin">Linkedin</option>
                                    <option value="forward_from_friend">Forward from friend</option>
                                    <option value="sms">SMS</option>
                                    <option value="others">Others</option>
                                </select>
                                <div class="invalid-feedback">
                                    Select source
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card form-card mb-4">
                    <div class="card-body">
                        <div class="form-row mb-2">
                            <div class="col-md-5 col-sm-12">
                                <p class="card-text my-3">
                                    Your Full Name*
                                </p>
                                <input type="text" class="form-control" required formControlName="name"
                                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }" id="inputName" />
                                    <div class="invalid-feedback">
                                        Enter full name
                                    </div>
                            </div>

                            <div class="col-md-5 offset-md-2 col-sm-12">
                                <p class="card-text my-3">
                                    Contact No.(10 digit only)*
                                </p>
                                <input type="text" maxlength="10" class="form-control" required formControlName="phone"
                                    [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" id="inputNumber" />
                                    <div class="invalid-feedback">
                                        Enter 10 digits mobile number
                                    </div>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="col-md-5 col-sm-12">
                                <p class="card-text my-3">
                                    Whatsapp No.(10 digit only)*
                                </p>
                                <input type="text" maxlength="10" class="form-control" required formControlName="whatsappNo"
                                    [ngClass]="{ 'is-invalid': submitted && f.whatsappNo.errors }" id="inputWhatsappNo" />
                                    <div class="invalid-feedback">
                                        Enter 10 digits mobile number
                                    </div>
                            </div>

                            <div class="col-md-5 offset-md-2 col-sm-12">
                                <p class="card-text my-3">
                                    E-mail id*
                                </p>
                                <input type="email" class="form-control" required formControlName="email"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="inputEmail" />
                                    <div class="invalid-feedback">
                                        Enter correct email
                                    </div>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="col-md-5 col-sm-12">
                                <p class="card-text my-3">
                                    Gender (Male/Female)*
                                </p>
                                <div class="d-flex ">
                                    <div class="form-check custom-control custom-radio">
                                        <input class="form-check-input custom-control-input" type="radio" name="gender"
                                            formControlName="gender" id="radioMale" required value="M"
                                            [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                                        <label class="form-check-label custom-control-label" for="radioMale">
                                            Male
                                        </label></div>
                                    <div class="form-check custom-control custom-radio" style="margin-left: 50px">
                                        <input class="form-check-input custom-control-input" type="radio" name="gender"
                                            formControlName="gender" id="radioFemale" value="F"
                                            [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                                        <label class="form-check-label custom-control-label" for="radioFemale">
                                            Female
                                        </label></div>
                                </div>

                            </div>

                            <div class="col-md-5 offset-md-2 col-sm-12">
                                <p class="card-text my-3">
                                    DOB(Date Of Birth)*
                                </p>
                                <div class="form-group dob">
                                    <div class="input-group">
                                        <input class="form-control" placeholder="dd/mm/yyyy" name="dob" formControlName="dob" [(ngModel)]="dob" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }"
                                        [minDate]="{year: 1960, month: 1, day: 1}" 
                                        [maxDate]="maxDate"
                                            ngbDatepicker #d="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                                type="button"></button>
                                        </div>
                                        <div class="invalid-feedback">
                                            Select date of birth
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="col-md-5 col-sm-12">
                                <p class="card-text my-3">
                                    Current City Name*
                                </p>
                                <input type="text" class="form-control" required formControlName="city"
                                    [ngClass]="{ 'is-invalid': submitted && f.city.errors }" id="inputCity" />
                                    <div class="invalid-feedback">
                                        Enter correct city name
                                    </div>
                            </div>

                            <div class="col-md-5 offset-md-2 col-sm-12">
                                <p class="card-text my-3">
                                    Current State Name*
                                </p>
                                <input type="text" class="form-control" required formControlName="state"
                                    [ngClass]="{ 'is-invalid': submitted && f.state.errors }"  id="inputState" />
                                    <div class="invalid-feedback">
                                        Enter correct state name
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card form-card mb-4">
                    <div class="card-body">
                        <div class="form-row mb-2">
                            <div class="col-md-12 col-sm-12 mb-2">
                                <p class="card-text my-3">
                                    Interested for WFH for minimum 5-6hrs. and above? *
                                </p>
                                <div class="d-flex ">
                                    <div class="form-check custom-control custom-radio">
                                        <input class="form-check-input custom-control-input" type="radio" name="interest" formControlName="interest"
                                            [ngClass]="{ 'is-invalid': submitted && f.interest.errors }" id="radioInterestYes" value="yes">
                                        <label class="form-check-label custom-control-label" for="radioInterestYes">
                                            Yes
                                        </label></div>
                                    <div class="form-check custom-control custom-radio" style="margin-left: 50px">
                                        <input class="form-check-input custom-control-input" type="radio" name="interest" formControlName="interest"
                                            [ngClass]="{ 'is-invalid': submitted && f.interest.errors }" id="radioInterestNo" value="no">
                                        <label class="form-check-label custom-control-label" for="radioInterestNo">
                                            No
                                        </label></div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 mb-2">
                                <p class="card-text my-3">
                                    Graduation Completed? *
                                </p>
                                <div class="d-flex ">
                                    <div class="form-check custom-control custom-radio">
                                        <input class="form-check-input custom-control-input" type="radio" name="graduation" formControlName="graduation"
                                            [ngClass]="{ 'is-invalid': submitted && f.graduation.errors }" id="radioGraduationYes" value="yes">
                                        <label class="form-check-label custom-control-label" for="radioGraduationYes">
                                            Yes
                                        </label></div>
                                    <div class="form-check custom-control custom-radio" style="margin-left: 50px">
                                        <input class="form-check-input custom-control-input" type="radio" name="graduation" formControlName="graduation"
                                            [ngClass]="{ 'is-invalid': submitted && f.graduation.errors }" id="radioGraduationNo" value="no">
                                        <label class="form-check-label custom-control-label" for="radioGraduationNo">
                                            No
                                        </label></div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 mb-2">
                                <p class="card-text my-3">
                                    Do you have Laptop/Computer? *
                                </p>
                                <div class="d-flex ">
                                    <div class="form-check custom-control custom-radio">
                                        <input class="form-check-input custom-control-input" type="radio" name="laptop" formControlName="laptop" [ngClass]="{ 'is-invalid': submitted && f.laptop.errors }" id="radioLaptopYes"
                                            value="yes">
                                        <label class="form-check-label custom-control-label" for="radioLaptopYes">
                                            Yes
                                        </label></div>
                                    <div class="form-check custom-control custom-radio" style="margin-left: 50px">
                                        <input class="form-check-input custom-control-input" type="radio" name="laptop" formControlName="laptop" [ngClass]="{ 'is-invalid': submitted && f.laptop.errors }" id="radioLaptopNo"
                                            value="no">
                                        <label class="form-check-label custom-control-label" for="radioLaptopNo">
                                            No
                                        </label></div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 mb-2">
                                <p class="card-text my-3">
                                    Do you have BroadBand/Wifi (Good internet connectivity)? *
                                </p>
                                <div class="d-flex ">
                                    <div class="form-check custom-control custom-radio">
                                        <input class="form-check-input custom-control-input" type="radio" name="internet" formControlName="internet"
                                            [ngClass]="{ 'is-invalid': submitted && f.internet.errors }" id="radioInternetYes" value="yes">
                                        <label class="form-check-label custom-control-label" for="radioInternetYes">
                                            Yes
                                        </label></div>
                                    <div class="form-check custom-control custom-radio" style="margin-left: 50px">
                                        <input class="form-check-input custom-control-input" type="radio" name="internet" formControlName="internet"
                                            [ngClass]="{ 'is-invalid': submitted && f.internet.errors }" id="radioInternetNo" value="no">
                                        <label class="form-check-label custom-control-label" for="radioInternetNo">
                                            No
                                        </label></div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 mb-2">
                                <p class="card-text my-3">
                                    Do you have Android Phone? *
                                </p>
                                <div class="d-flex ">
                                    <div class="form-check custom-control custom-radio">
                                        <input class="form-check-input custom-control-input" type="radio" name="android" formControlName="android" [ngClass]="{ 'is-invalid': submitted && f.android.errors }" id="radioAndroidYes"
                                            value="yes">
                                        <label class="form-check-label custom-control-label" for="radioAndroidYes">
                                            Yes
                                        </label></div>
                                    <div class="form-check custom-control custom-radio" style="margin-left: 50px">
                                        <input class="form-check-input custom-control-input" type="radio" name="android" formControlName="android" [ngClass]="{ 'is-invalid': submitted && f.android.errors }" id="radioAndroidNo"
                                            value="no">
                                        <label class="form-check-label custom-control-label" for="radioAndroidNo">
                                            No
                                        </label></div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 mb-2">
                                <p class="card-text my-3">
                                    Are you proficient in Hindi & English (Both)? *
                                </p>
                                <div class="d-flex ">
                                    <div class="form-check custom-control custom-radio">
                                        <input class="form-check-input custom-control-input" type="radio" name="language" formControlName="language"
                                            [ngClass]="{ 'is-invalid': submitted && f.language.errors }" id="radioLanguageYes" value="yes">
                                        <label class="form-check-label custom-control-label" for="radioLanguageYes">
                                            Yes
                                        </label></div>
                                    <div class="form-check custom-control custom-radio" style="margin-left: 50px">
                                        <input class="form-check-input custom-control-input" type="radio" name="language" formControlName="language"
                                            [ngClass]="{ 'is-invalid': submitted && f.language.errors }" id="radioLanguageNo" value="no">
                                        <label class="form-check-label custom-control-label" for="radioLanguageNo">
                                            No
                                        </label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-lg button-hiring mb-5"
                    (click)="onSubmit(hiringForm)">Submit</button>
            </div>
        </div>
    </div>
</form>
