import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, Validators, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { ApiService } from '../../api.service';
import {MyserviceService} from '../../myservice.service'
@Component({
  selector: 'app-demo-portal-whatsapp',
  templateUrl: './demo-portal-whatsapp.component.html',
  styleUrls: ['./demo-portal-whatsapp.component.scss']
})
export class DemoPortalWhatsappComponent implements OnInit {

  showHideVisible:boolean=true;
  showHideVisibleSuccess:boolean=false;
  createdForm: FormGroup;
  _sms_id:any="";
  _message:any="";
  api_end_point:any;
  
  constructor(public router :Router,private API: ApiService,
		private formbuilder: FormBuilder,private _service:MyserviceService) {
      this.createdForm = this.formbuilder.group({
        "template": [''],
				"telcoPartner": ['TWILIO', Validators.required],
				"phoneNo": ['', Validators.required],
				"message": ['', Validators.required],
        "type": ['WHATSAPP', Validators.required],
				"cli": ['+15512617144', Validators.required]
			  })
     }

  ngOnInit(): void {
	this.api_end_point = this._service.get_API_End_Point();
  }
  backDashboard(){
      this.router.navigateByUrl('/demo-portal');
      setTimeout(()=>{
        this.router.navigateByUrl('/demo-portal/demo-portal-dashboard');
      },100);
    this.showHideVisible=false;
    this.showHideVisibleSuccess=false;
  }
  cancelBtn(){
    this.showHideVisible=true;
    this.showHideVisibleSuccess=false;
    this.createdForm.reset();
    this.createdForm.controls.type.setValue('WHATSAPP');
  }
  submitBtn(createdForm:any){  
    let _telcoPartner=createdForm.controls.telcoPartner.value;
    let _phoneNo=createdForm.controls.phoneNo.value;
    let  _message=createdForm.controls.message.value;
    let _cli=createdForm.controls.cli.value;
    if(_telcoPartner=="" || _phoneNo=="" || _message =="" || _cli==""){
        alert("Please enter all filelds");
        return false;
    }
 
    let _obj={
        "template":createdForm.controls.template.value,
        "telco_partner":createdForm.controls.telcoPartner.value,
        "phone_number":createdForm.controls.phoneNo.value,
        "message":createdForm.controls.message.value,
        "type":createdForm.controls.type.value,
        "cli":createdForm.controls.cli.value
    }
    this.API.whatsappCallApiSave(_obj,this.api_end_point).subscribe((response:any) => {
if(response.status=="success"){
 this._sms_id= response.call_id;
 this._message="Whatsapp Message Sent Successfully";// response.message;
  this.showHideVisible=false;
this.showHideVisibleSuccess=true;
}
else{
  alert(response.status);
}
      });
  }
  okBtn(){
    window.location.reload();
    this.router.navigateByUrl('/demo-portal/demo-portal-dashboard');
    this.showHideVisible=false;
    this.showHideVisibleSuccess=false;
  }
}
