<div class="row ml-0 mr-0" style="overflow:hidden;">
    <div class="col-md-10 col-sm-10">
        <h3 class="h3-call" style="font-size: 19px;text-align: center;">Form Preview</h3>
    </div>
    <div class="col-md-2 col-sm-2">
        <mat-icon class="CR" style="color:red;font-size:28px;" (click)="closeDialog()">cancel_outlined</mat-icon>
    </div>
</div>
<div>
    <div class="tablet">
        <div class="content">
            <h4 class="h4-head">Connected</h4>
            <div style="padding: 12px;">
                <table class="table1">
                    <tr>
                        <td>Rajeev Ranjan</td>
                    </tr>
                    <tr>
                        <td>New data</td>
                    </tr>
                    <tr>
                        <td>Campaign July 06</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                </table>
                <br>
                <table class="table1">
                    <tr>
                        <td>Applicant</td>
                        <td><b>Pradeep Kumar</b></td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td><b>+91-xxxxxxxxx</b></td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td><b>pradeep@gmail.com</b></td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </table>
                <div *ngFor="let section of formView;let $index=index">
                    <p class="P-detail">{{section.title}}</p>
                    
                <div *ngFor="let child of section.child;let $childIndex=index">
                    <p class="p-qn"><b>{{child.q}}</b></p>
                    <p *ngIf="child.at == 'FT'">
                        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                            <input matInput placeholder="Enter">
                        </mat-form-field>
                    </p>
                    <p *ngIf="child.at == 'RT'">
                        <!-- <star-rating-comp [starType]="'icon'" [size]="'large'" [rating]="4"></star-rating-comp> -->

                        <ng-template #t let-fill="fill">
                            <span class="star" [class.full]="fill === 100">
                              <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                            </span>
                          </ng-template>
                          <ngb-rating [max]="5" [rate]="4"  [starTemplate]="t" [readonly]="true"></ngb-rating>

                    </p>
                    <p *ngIf="child.at == 'FN'">
                        <mat-form-field [ngClass]="'call-Flow-input'">
                            <input matInput placeholder="Enter">
                        </mat-form-field>
                     
                    </p>
                    <p *ngIf="child.at == 'NP'">
                        <mat-form-field style="width:35%;" [ngClass]="'call-Flow-input'">
                            <input matInput placeholder="Enter">
                        </mat-form-field>
                        <span style="padding: 0 10px 0 15px"><b>To</b></span>
                        <mat-form-field  style="width:35%;" [ngClass]="'call-Flow-input'">
                            <input matInput placeholder="Enter">
                        </mat-form-field>
                    </p>
                    <p *ngIf="child.at == 'DATE'">
                        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                            <input matInput [matDatepicker]="picker" placeholder="Date" readonly
                                (click)="picker.open()">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </p>
                    <p *ngIf="child.at == 'TIME'">
                        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                            <input matInput placeholder="Time" type="time" min="00:00" max="24:00">
                        </mat-form-field>

                    </p>
                    <p *ngIf="child.at == 'DATETIME'">
                        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                            <input matInput [matDatepicker]="picker" placeholder="Date" readonly
                                (click)="picker.open()">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                            <input matInput placeholder="Time" type="time" min="00:00" max="24:00">
                        </mat-form-field>
                    </p>
                    <p *ngIf="child.at == 'CB'">
                        <mat-checkbox style="font-size: 13px;margin-right: 15px;" *ngFor="let v of child.cb_data">
                            {{v}}</mat-checkbox>
                    </p>
                    <p *ngIf="child.at == 'RB'">
                        <mat-radio-group>
                            <mat-radio-button style="padding-right: 10px;font-size: 13px;" [value]="v"
                                *ngFor="let v of child.rb_data">{{v}}</mat-radio-button>

                        </mat-radio-group>
                    </p>

                    <p *ngIf="child.at == 'DD'">
                        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                            <mat-select placeholder="Select">
                                <mat-option value="v" *ngFor="let v of child.dd_data"> {{v}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </p>
                    <p *ngIf="child.at == 'SDD'">
                        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                            <mat-select placeholder="Select">
                                <input placeholder="search" style="width: 100%;border: none;padding: 8px;">

                                <mat-option value="v" *ngFor="let v of child.sdd_data"> {{v}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </p>
                    <p *ngIf="child.at == 'MSDD'">
                        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                            <mat-select placeholder="Select" multiple>
                                <mat-option value="v" *ngFor="let v of child.msdd_data"> {{v}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </p>
                    <p *ngIf="child.at == 'YN'">
                        <button mat-button color="default">Yes</button> &nbsp;&nbsp;
                        <button mat-button color="default">No</button>
                    </p>
                    <p *ngIf="child.at == 'FLP'">
                        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                            <input matInput placeholder="Enter">
                        </mat-form-field>
                    </p>
                    <p *ngIf="child.at == 'ATTACHMENT'">
                        <span class="upload-btn-wrapper">
                            <button class="btnbrowse">Browse File &nbsp;&nbsp;
                                <img src="../assets/BrowsefileIcon.svg" /></button>
                            <input type="file" #fileUpload class="filecs" />
                        </span>
                    </p>
                    <p *ngIf="child.at == 'BLANK'">
                        <mat-form-field class="wd-100" [ngClass]="'call-Flow-input'">
                            <input matInput placeholder="Enter">
                        </mat-form-field>
                    </p>
                    <p *ngIf="child.at == 'BT'">
                        <button mat-button style="margin: 0 5px 10px 0px;background: #ada8a8 !important;"
                            *ngFor="let v of child.bt_data">{{v}}</button>
                    </p>
                </div>
            </div>
            </div>

        </div>
    </div>
</div>