<mat-card style="margin: 11px;border-radius: 8px;">
    <div class="row" style="padding: 31px 12px 31px 12px;">
        <div class="col-md-6">
            <div class="m-div">
                <p><span class="s-hd">Select Your Channel</span></p>
                  <label class="lb1">Channels/TouchPoints</label>
                  <table class="wd-100">
                      <tr>
                          <td>
                            <mat-form-field [ngClass]="'orchDDlOutline'" style="font-size: 12px;padding: 20px;width: 100%;" appearance="outline">
                                <mat-select placeholder="Select" (selectionChange)="changeChannel($event.value)">
                                    <mat-option value="Email">EMail</mat-option>
                                    <mat-option value="sms">Messaging (SMS)</mat-option>
                                    <mat-option value="Whatsapp">Messaging (WhatsApp)</mat-option>
                                    <mat-option value="VoiceOutbound">Voice Outbound</mat-option>
                                    <mat-option value="VoiceInbound">Voice Inbound </mat-option>
                                    <mat-option value="WebsiteForms">Website Forms</mat-option>
                                    <mat-option value="SocialMedia">Social Media</mat-option>
                                    <mat-option value="VoiceBot">Voice Bot</mat-option>
                                </mat-select>
                              </mat-form-field>
                          </td>
                          <td *ngIf="showHideForEmail">
                            <mat-form-field [ngClass]="'orchDDlOutline'" style="font-size: 12px;padding: 20px;width: 100%;" appearance="outline">
                                <mat-select placeholder="Select">
                                    <mat-option value="1">Email not delivered</mat-option>
                                    <mat-option value="2">Email not opened</mat-option>
                                    <mat-option value="3">Email opened and no CTA</mat-option>
                                    <mat-option value="4">Email CTA success
                                    </mat-option>
                                </mat-select>
                              </mat-form-field>                                
                        </td>                     
                      </tr>
                  </table>
             
                </div>
        </div>
        <div class="col-md-6">
            <div class="m-div">
                <p><span class="s-hd">Select An Action</span></p>
                  
                <p class="radioAction">
                    <mat-radio-group class="rgRadio" (change)="radioChange($event.value)">
                        <mat-radio-button [checked]="true" value="smartBots" style="height: 34px;padding-right: 32px;border-right: 2px dotted #707070;"><span>Smarter Bots </span></mat-radio-button>
                        <mat-radio-button style="padding-left:32px;" value="agents"><span>Agents</span></mat-radio-button>
                      </mat-radio-group>
                </p>
                <div style="padding-top: 10px;"  *ngIf="showHideRadioSmartbot">
                    <mat-form-field [ngClass]="'orchDDlOutline'" style="font-size: 12px;padding: 20px;width: 100%;" appearance="outline" >
                        <mat-select placeholder="Select" [(ngModel)]="smartEmailDDLOption" (selectionChange)="changeCategory($event.value)">
                            <mat-option value="AutomaticReplyEmails">Automatic Reply Emails</mat-option>
                            <mat-option value="AutomaticSMS">Automatic SMS</mat-option>
                            <mat-option value="AutomaticWhatsApp">Automatic WhatsApp</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div style="padding-top: 10px;" *ngIf="showHideRadioAgent"  >
                        <mat-form-field [ngClass]="'orchDDlOutline'" style="font-size: 12px;padding: 20px;width: 100%;" appearance="outline">
                            <mat-select placeholder="Select"  [(ngModel)]="agentEmailDDLOption" (selectionChange)="changeCategory($event.value)">
                                <mat-option value="EmailDeliveredToAgentAndAgentReplies">Email delivered to Agent and Agent replies
                                </mat-option>
                                <mat-option value="AvailableOnlineAgent">Available/Online Agent to callback</mat-option> 
                               
                            </mat-select>
                          </mat-form-field>                                
                        </div>
                </div>
            
    
        </div>
    </div>
   
   
   <div *ngIf="showHideEmailTemplate">
       <app-orchestration-email-template></app-orchestration-email-template>
   </div>
   <div *ngIf="showHideSMSTemplate">
       <app-orchestration-sms-template></app-orchestration-sms-template>
   </div>
   <div *ngIf="showHideWhatsappTemplate">
    <app-orchestration-whatsapp-template></app-orchestration-whatsapp-template>
</div>
  
<div *ngIf="showHideEmailDeliveredToAgentTemplate">
    <app-orchestration-emaildelivered-agent-template></app-orchestration-emaildelivered-agent-template>
</div>
<div *ngIf="showHideAvailableAgentTemplate">
    <app-orchestration-available-agent-template></app-orchestration-available-agent-template>
</div>
</mat-card>
