<!-- This web page is for dashboard for the project call FK_EMP -->

<app-menu [navigateLink]="currentTitle"></app-menu>

<!-- The main table islands start here -->
<table class="maintable">
	<tr>
		<td>
			<div class="containecs">
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Data Dialled - {{ this.summary_object.total_dialled }}</div>
						<div class="card-body">
							<div [chart]="data_dialled_chart"></div>
						</div>
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Employees</div>
						<div class="card-body">
							<table class="tablecs">
								<tr>
									<td>Dialled</td>
									<td>{{ this.summary_object.unique_dialled }}</td>
								</tr>
								<tr>
									<td>Connected</td>
									<td>{{ this.summary_object.unique_contacted }}</td>
								</tr>
								<tr>
									<td>Not Reachable / Switched-off</td>
									<td>{{ this.summary_object.not_reachable }}</td>
								</tr>
								<tr>
									<td>Callback</td>
									<td>{{ this.summary_object.callback }}</td>
								</tr>
								<tr>
									<td>Wrong Number</td>
									<td>{{ this.summary_object.wrong_number }}</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Divisons</div>
						<div class="card-body">
							<div [chart]="total_data_chart"></div>
						</div>
					</div>
				</div>
			</div>
		</td>
	</tr>
</table>
<!-- The main table islands end here  -->

<!-- Divisons tabs start here -->
<div *ngIf="!this.detailed_data_object.isActive" class="inside_container inside_container_border divisions_div">
	<div class="row">
		<div class="col-sm-2 col-md-2">
			<h3>Divisions</h3>
		</div>
		<div class="col-sm-2 col-md-2">
			<mat-form-field >
				<input matInput [matDatepicker]="frompicker" [matDatepickerFilter]="date_range_filter" placeholder="From" readonly
					(click)="frompicker.open()" (dateChange)="change_date('start_date', $event)" [(ngModel)]="START_DATE">
				<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
				<mat-datepicker #frompicker></mat-datepicker>
			</mat-form-field>
		</div>
		<div class="col-sm-2 col-md-2">
			<mat-form-field>
				<input #fromInput matInput [matDatepicker]="topicker" [matDatepickerFilter]="date_range_filter" placeholder="To" readonly (click)="topicker.open()" (dateChange)="change_date('end_date', $event)" [(ngModel)]="END_DATE">
				<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
				<mat-datepicker #topicker></mat-datepicker>
			</mat-form-field>
		</div>
		<div class="col-md-1 col-sm-1">
			<button mat-flat-button color="accent" (click)="refreshData()">Refresh</button>
		</div>
	</div>

	<mat-tab-group animationDuration="0ms">
		<ng-container *ngFor="let tab of tabs_data; index as i">
			<mat-tab [label]="tab['title']">
				<div class="row">
					<div class="col-sm-8 col-md-8 col-lg-8">
						<app-attribute-table [tableDatatype]="tab['data_val']"></app-attribute-table>
					</div>
					<div class="col-sm-4 col-md-4 col-lg-4">
						<app-attribute-chart [chart_division]="tab['data_val']"></app-attribute-chart>
						<button mat-icon-button aria-label="Open in new tab" 
						matTooltip="Clik to see in details" matTooltipPosition="before"
						class="detailed_button" 
						(click)="detailed_section(tab['data_val'], i)">
							<mat-icon>open_in_new</mat-icon>
						  </button>
					</div>
				</div>
			</mat-tab>
		</ng-container>
	</mat-tab-group>
</div>
<!-- Divisons tabs ends here -->
<!-- Detailed div starts here -->
<div *ngIf="this.detailed_data_object.isActive" id="detailed_div" class="inside_container inside_container_border divisions_div">
	<p class="back_btn" (click)="reset_detailed_div()">&lt; Back</p>
	<h4><span >{{ detailed_data_object.tab_details.title }}</span> - Data</h4>
	<!-- <p>Employee Dialled - {{ detailed_data_object.data_obj.unique_dialled }}</p> -->
	<p>Employee Connected - {{ detailed_data_object.data_obj.unique_contact }}</p>
	<div #detailed_table>
		<mat-table [dataSource]="divisonTableData">
			<ng-container matColumnDef="sl_no">
				<mat-header-cell *matHeaderCellDef> Sl.No </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.sl_no}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="customer_name">
				<mat-header-cell *matHeaderCellDef> Employee </mat-header-cell>
				<mat-cell *matCellDef="let element"> <span class="click_customer" (click)="openCustomerDialog(element)">{{element.customer_name}}</span> </mat-cell>
			</ng-container>
			<ng-container matColumnDef="customer_number">
				<mat-header-cell *matHeaderCellDef> Employee Number </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{ element.customer_number}}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="agent_name">
				<mat-header-cell *matHeaderCellDef> Agent </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{ element.agent_name}}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="agent_number">
				<mat-header-cell *matHeaderCellDef> Agent Number </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{ element.agent_number}}</mat-cell>
			</ng-container>
			<ng-container matColumnDef="start_time">
				<mat-header-cell *matHeaderCellDef> Start Time </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.start_time}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="end_time">
				<mat-header-cell *matHeaderCellDef> End Time </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.end_time}} </mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef=divisionTableColoumns></mat-header-row>
			<mat-row *matRowDef="let row; columns: divisionTableColoumns"></mat-row>
		</mat-table>
		<mat-paginator #campaignpaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
	</div>
</div>
<!-- Detailed div ends here -->