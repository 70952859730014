<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">
     <mat-card>
         <h2 class="d-head">Assessment<img src="../assets/underline.svg"
            class="linebelow"> </h2>

<div class="searchFix">
    <mat-form-field style="font-size: 12px;" appearance="outline">
        <input #searchInput matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH ASSESSMENT">
        <i class="fa fa-search search-icon" matSuffix style="position: relative;
        top: -2px;"></i>
      </mat-form-field>
      <button mat-button class="T-createbtn"  (click)="createNewBatch()" style="width: 200px !important;">Upload Assessment </button>
</div>
<br>
<div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >

    <ng-container matColumnDef="name">
		<th mat-header-cell *matHeaderCellDef> Name </th>
		<td mat-cell *matCellDef="let element" class="CR" >{{ element.name }}</td>
	</ng-container>
	<!-- <ng-container matColumnDef="type">
		<th mat-header-cell *matHeaderCellDef> Type </th>
		<td mat-cell *matCellDef="let element" class="CR" >{{ element.type }}</td>
    </ng-container> -->
    
	<ng-container matColumnDef="default">
		<th mat-header-cell *matHeaderCellDef> Default </th>
		<td mat-cell *matCellDef="let element" class="CR" >{{ element.latest == 1 ? 'True' : 'False' }}</td>
	</ng-container>
	<ng-container matColumnDef="passing_score">
		<th mat-header-cell *matHeaderCellDef> Passing Score </th>
		<td mat-cell *matCellDef="let element" class="CR" >{{ element.passing_score }}</td>
    </ng-container>
    <ng-container matColumnDef="timing">
		<th mat-header-cell *matHeaderCellDef> Timing </th>
		<td mat-cell *matCellDef="let element" class="CR" >{{ element.timing }}</td>
    </ng-container>
    <ng-container matColumnDef="total_questions">
		<th mat-header-cell *matHeaderCellDef> Total Questions </th>
		<td mat-cell *matCellDef="let element" class="CR" >{{ element.total_questions }}</td>
    </ng-container>
    <ng-container matColumnDef="total_score">
		<th mat-header-cell *matHeaderCellDef> Total Score </th>
		<td mat-cell *matCellDef="let element" class="CR" >{{ element.total_score }}</td>
    </ng-container>
    <ng-container matColumnDef="total_sections">
		<th mat-header-cell *matHeaderCellDef> Total Sections </th>
		<td mat-cell *matCellDef="let element" class="CR" >{{ element.total_sections }}</td>
	</ng-container>
	<ng-container matColumnDef="created_at">
		<th mat-header-cell *matHeaderCellDef> Created At </th>
		<td mat-cell *matCellDef="let element" class="CR" >{{ element.created_at  | date:'dd/MM/yyyy'}}</td>
    </ng-container>
    <ng-container matColumnDef="download">
		<th mat-header-cell *matHeaderCellDef>Questionaire </th>
		<td mat-cell *matCellDef="let element; let i = index;" class="CR" >
			<!-- <button mat-button (click)="downloadCampaignStatusReport(element)">Download</button>  -->
			<button mat-stroked-button class="download_btn" color="primary" style="color: #fd6c21;;" (click)="downloadQuestionaire(element,i)" >
				Download&nbsp;
				<!-- <i class="fa fa-angle-down"></i> -->
				<!-- <i class="fas fa-sync-alt fa-spin" *ngIf="element.showLoader"></i> -->
			</button>
		<td>
	</ng-container>
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef> EDIT </th>
    <td mat-cell *matCellDef="let element; let i = index;" (click)="openEditBatch( element, i)"> <span class="detailsCss">Edit</span> </td>
  </ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
</table>
<mat-paginator  [ngClass]="'paginator-training'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
            
</mat-card>
</div>
</div>

