import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'underscore';
import { MyserviceService } from 'src/app/myservice.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-dashboard',
  templateUrl: './payment-dashboard.component.html',
  styleUrls: ['./payment-dashboard.component.scss']
})
export class PaymentDashboardComponent implements OnInit {
  currentTitle:any="payment";
  selectedButtonToggleVal: string = 'dashboard';

  showPaymentDashboard:boolean=true;
  showPaymentConfigure:boolean=false;
  showPaymentHistory:boolean=false;
  showPaymentApproval:boolean=false;
  showPaymentEvents:boolean=false;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {    
 
  }



	onToggleButtonValChange(val: string) {
    this.showPaymentDashboard=false;
    this.showPaymentConfigure=false;
    this.showPaymentHistory=false;
    this.showPaymentApproval=false;
    this.showPaymentEvents=false;

    this.selectedButtonToggleVal=val;

		if (val == 'dashboard') {
	    this.showPaymentDashboard=true;
		} 
    else if (val == 'configure') {
	    this.showPaymentConfigure=true;
		} 
    else if (val == 'history') {
	    this.showPaymentHistory=true;
		} 
    else if (val == 'approval') {
	    this.showPaymentApproval=true;
		} 
    else if (val == 'events') {
	    this.showPaymentEvents=true;
		} 
	}
  getCurrentDate(){
    let myDate = new Date();
  let daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Aug', 'Oct', 'Nov', 'Dec'];
  let date = myDate.getDate();
  let month = monthsList[myDate.getMonth()];
  let year = myDate.getFullYear();
  let day = daysList[myDate.getDay()];
  let today = `${date} ${month} ${year}, ${day}`;
  return today;
  }

}
