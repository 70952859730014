import { Component, OnInit,ViewChild } from '@angular/core';
import { ApiService } from '../../api.service';
import {MyserviceService}from '../../myservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ContiinexMessagePreviewDialogComponent } from '../contiinex-message-preview-dialog/contiinex-message-preview-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { Routes, RouterModule, Router,ActivatedRoute, Params  } from '@angular/router';
@Component({
  selector: 'app-contiinex-message-template',
  templateUrl: './contiinex-message-template.component.html',
  styleUrls: ['./contiinex-message-template.component.scss']
})
export class ContiinexMessageTemplateComponent implements OnInit {
  selectedButtonToggleVal: string = 'list';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) tableSort: MatSort;
  displayed_columns = ['id','name', 'template', 'language', 'currentStatus', 'category', 'updatedOn', 'qualityRating','action'];
  dataSource = new MatTableDataSource();
  _msgTemplateData:any=[];

  showHideForList:boolean=true;
  showHideForCreate:boolean=false;
  selectedRow:any;
  customer_name:any;
  businessType:any;
  currentLoader:boolean=false;

  api_end_point:any;
	currentTitle = 'Message-Template';
  currentUrl:any;

  showHidePopupDelete:boolean=false;
_deleteElementData:any=[];
  wa_number: any;
  bearer_token: any;
  yourBearerToken:any;
  deleteMessgae:any="";
  constructor(private API: ApiService,private _service:MyserviceService,private dialog: MatDialog,private _snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.currentUrl = this.router.url;
    if (this._service.get_loggedin_user() && this._service.get_loggedin_user().company) {
      this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
      this.businessType = String(this._service.get_loggedin_user().businessType);
    }
	if (this._service.get_loggedin_user()) {
		this.bearer_token = String(this._service.get_loggedin_user().bearer_token);
		this.wa_number = String(this._service.get_loggedin_user().phone); //You should trim the '+' sign before using
		this.yourBearerToken=this.bearer_token;
	  }
	  if (this._service.get_loggedin_user() && this._service.get_loggedin_user().company) {
		this.customer_name = String(this._service.get_loggedin_user().company).toLowerCase();
	  }
	//   this.API.getWabaProfile(this.customer_name,this.api_end_point).subscribe((res: any) => {
	// 	if (res && res.account && res.profile) {
	// 	  if (res.profile) {
	// 		this.yourBearerToken = res.profile.bearerToken;
	// 	  }
	// 	}
	//   });
    this.getMsgTemplateData();	
  }

  getMsgTemplateData(){
    this.currentLoader=true;
    this.API.getWabaTemplates(this.customer_name,this.api_end_point).subscribe((res: any) => {
      this.currentLoader=false;
      if (res && res.data ) {
        this._msgTemplateData=[];
        for (let i in res.data) {
          var record = {};
          //record['id'] = res.data[i].id;
          record['name'] = res.data[i].name;
          for (let j in res.data[i].components) {
            if (res.data[i].components[j].type && (res.data[i].components[j].type=='BODY') ) {
              record['template'] = res.data[i].components[j];//.text;
            }
            if (res.data[i].components[j].type && (res.data[i].components[j].type=='HEADER') ) {
              record['header'] = res.data[i].components[j];
            }
            if (res.data[i].components[j].type && (res.data[i].components[j].type=='FOOTER') ) {
              record['footer'] = res.data[i].components[j];
            }
            if (res.data[i].components[j].type && (res.data[i].components[j].type=='BUTTONS') ) {
              record['buttons'] = res.data[i].components[j].buttons;
            }
          }
          record['language'] = res.data[i].language;
          record['category'] = res.data[i].category;
          record['currentStatus'] = res.data[i].status;
          record['id'] = res.data[i].id;
          if (res.data[i].quality_score && res.data[i].quality_score.score) {
            record['qualityRating'] = res.data[i].quality_score.score;
          } else {
            record['qualityRating'] = 'UNKNOWN';
          }
          record['updatedOn'] = res.data[i].last_updated_time;
          this._msgTemplateData.push(record);
        }
      } else {
        this._msgTemplateData=[
        ]
      }
      this.dataSource = new MatTableDataSource(this._msgTemplateData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.tableSort;
    });
  }

  onToggleButtonValChange(val:any){
  this.showHideForList=false;
  this.showHideForCreate=false;
  this.selectedRow="";
    if(val=="list"){
      this.showHideForList=true;
      this.getMsgTemplateData();
      this.selectedButtonToggleVal='list';
    }
    else if(val=="create"){
      this.showHideForCreate=true;
      this.selectedButtonToggleVal='create';
    }
    else if(val=="clone"){

    }
    else{

    }
  }
  selectRow(row) {
    this.selectedRow = row;
    }
previewTemplateBtn(row:any){
  this.selectedRow = row;
if(row.currentStatus== "REJECTED")
{
  this.showHideForList=false;
  this.showHideForCreate=true;
  this.selectedButtonToggleVal='create';

}
else{
  this.showHideForList=true;
  this.showHideForCreate=false;
  this.selectedButtonToggleVal='list';
  const dialogRef = this.dialog.open(ContiinexMessagePreviewDialogComponent, {

    height:'92%',
    disableClose: true,
    panelClass:'msDialogCls',
    data: this.selectedRow,
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed', result);
  });
}


}
applyFilter(filterValue: string) {
  filterValue = filterValue.trim();
  filterValue = filterValue.toLowerCase();
  this.dataSource.filter = filterValue;
}
public doShowList(data: any) {
  //debugger
  //console.log('Picked date: ', data);
  this.showHideForList=true;
  this.showHideForCreate=false;
  this.getMsgTemplateData();
  this.selectedButtonToggleVal='list';
}

deleteDialog(element){
	this._deleteElementData=[];
	this._deleteElementData=element;
	this.showHidePopupDelete=true;
}

  closedeletePopup(){
    this.showHidePopupDelete=false;
  }
  cancelDeleteTemplate(){
	this.showHidePopupDelete=false;
}
isShowDeleteMsg:boolean=false;
deleteTemplate(){
	let _obj=this._deleteElementData;
	let _waNumer=this.wa_number.replace(/\+/g, "")
		this.isShowDeleteMsg=false;
		this.currentLoader=true;
		this.deleteMessgae="";
	this.API.deleteCreateMessageTemplate(_obj,_waNumer,this.yourBearerToken,this.api_end_point).subscribe((res: any) => {
		debugger;
		console.log(res);
		if(res.status=="success"){
          this.deleteMessgae=res.data;
		  this.isShowDeleteMsg=true;
		  this.currentLoader=false;
		
		  setTimeout(()=>{
			this.isShowDeleteMsg=false;
			this.showHidePopupDelete=false;
			this.getMsgTemplateData();
		  },4000);
		}
		else{	
           let _msg=res.status + ":" + res.message;
		   this._snackBar.open(_msg, 'close', {
			duration: 5000,
		});
			this.isShowDeleteMsg=false;
			this.currentLoader=false;
		}
	
	});
}
}
