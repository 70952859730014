import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/api.service';
import { StartDialDialogComponent } from '../start-dial-dialog/start-dial-dialog.component';
import { Web,Invitation,
  Inviter,
  InviterOptions,
  Referral,
  Registerer,
  RegistererOptions,
  Session,
  SessionState,
  UserAgent,
  UserAgentOptions,
  InvitationAcceptOptions } from "../../../assets/sip.js";
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { MyserviceService } from 'src/app/myservice.service';
import * as CryptoJS from 'crypto-js';
import * as io from 'socket.io-client';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-agent-plugin',
  templateUrl: './home-agent-plugin.component.html',
  styleUrls: ['./home-agent-plugin.component.scss']
})
export class HomeAgentPluginComponent implements OnInit {

   server = "wss://edge.sip.onsip.com";
   options: any;
   $player: HTMLAudioElement;
   transportOptions: any;
   userAgent: any;
   user: any = this.myservice.get_loggedin_user();
  _nextBooked:any;
 _availableSlots:any;
 subscription: Subscription;
 subscriptionDial: Subscription;
 _countLeadLength:any;
 _countfollowUpLength:any;
 callStatus: any = {};
  allStatus: string[] = ['establishing','accepted'];
  socketId:any="";
  socketStatusBeforeCheck:any="";
  socket:any="";
  customerInfo:any="";
  widgetUser:any="";
  sioPushUrl:any="";
  questionnaire:any="";
  callFlowJsonSections: any[] = [];
  sessionStarted: boolean = false;
  counter:any=0;
  lastUrl:any="";
   @ViewChild('stream') set playerRef(ref: ElementRef<HTMLAudioElement>) {
    this.$player = ref.nativeElement;
  }
  _auditScore:any;
  _auditTime:any;
  constructor(private dialog: MatDialog,private myservice: MyserviceService, private API:ApiService,protected _sanitizer: DomSanitizer,private router: Router) {
    console.log(this.user.sio_url)
    // this.socket = io("http://15.206.118.80:4001");

   }
  getAudioElement(id: string): HTMLAudioElement {
    const el = document.getElementById(id);
    if (!(el instanceof HTMLAudioElement)) {
      throw new Error(`Element "${id}" not found or not an audio element.`);
    }
    return el;
  }

  ngOnInit() {
	this.counter = 0;
    this.callStatus = this.myservice.getLastCallStatus();
	if(this.callStatus && this.callStatus.widgetUser && this.callStatus.widgetUser.external_url){
		if (this.callStatus.customerInfo && this.callStatus.customerInfo.customer_number == this.callStatus.widgetUser.phone){
			this.widgetUser = this.callStatus.widgetUser;
			this.lastUrl = this.widgetUser ? this.widgetUser.external_url : '';
			this.counter = 0;
		}
		
	}

	this.subscription = this.myservice.getCallStatus().subscribe(obj => {
		console.log("Home--",obj)
		console.log(this.lastUrl )
		if (obj) {
			this.callStatus = obj;
			if(obj.widgetUser){
				if(this.lastUrl != obj.widgetUser.external_url){
					this.counter = 0;
					this.lastUrl = obj.widgetUser.external_url
				  }
				this.widgetUser = obj.widgetUser
			}
			if(obj.status && (obj.status == 'end_call_agent')){
				this.widgetUser = '';
				this.callStatus = '';
				this.counter = 0;
			}
		}else{
			this.widgetUser = '';
			this.callStatus = '';
			this.counter = 0;
		}
		
	})
    
    
  }

  transformUrl(url: string){ 
    if(this.counter==0){
      this.counter++;
    console.log(this._sanitizer.bypassSecurityTrustResourceUrl(url))
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
  colWd:any=9;
  expandOpen(){
this.colWd=12;
  }
  expandClose(){
    this.colWd=9;
  }
}

