import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  showLoader:boolean=false;
  email: string = "";
  msg: string = "";
  errorMsg: string = "";
  constructor(private API: ApiService,) { }

  ngOnInit(): void {
  }
  forgotSubmit(){
    this.errorMsg = "";
    if(this.email == ""){
      this.errorMsg = "Email is empty";
      return;
    }
    var emailCheck = this.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    if(emailCheck == null){
      this.errorMsg = "Enter correct email";
      return;
    }
    this.API.passwordReset(this.email).subscribe((res: any) => {
			if (res.errors) {
        this.errorMsg = res.errors;
			}else{
        this.msg = "A email has been sent to you email account, please check your mail...";
      } 
		}, (err) => {
       this.msg = "Something went wrong we couldnt not fetch your details";
    });
  }
}
