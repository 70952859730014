
<br>
<div class="row">
	<div class="col-lg-4 col-md-4"  style="padding-right: 0px;">
		<div class="card">
			<div class="card-header"><span style="color: #626262;"><b>Top L1</b></span>&nbsp; Recruiters</div>
			<div class="card-body">

				<mat-table [dataSource]="dataSourceL1">
					<ng-container matColumnDef="name">
						<mat-header-cell *matHeaderCellDef > Name </mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="called">
						<mat-header-cell *matHeaderCellDef > Called </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{ element.called }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="selected">
						<mat-header-cell *matHeaderCellDef > Selected </mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.selected }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="rejected">
						<mat-header-cell *matHeaderCellDef > Rejected </mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.rejected }}</mat-cell>
					</ng-container>
					<mat-header-row style="min-height: 30px;margin-top: 4px;" *matHeaderRowDef=displayedColumnsL1></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumnsL1"></mat-row>
				</mat-table>
				</div>
				</div>
	</div>
	<div class="col-md-4 col-lg-4"  style="padding-right: 0px;">
		<div class="card">
			<div class="card-header"><span style="color: #626262;"><b>Top L2</b></span>&nbsp; Recruiters</div>
			<div class="card-body">

				<mat-table [dataSource]="dataSourceL2" >
					<ng-container matColumnDef="name">
						<mat-header-cell *matHeaderCellDef > Name </mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="called">
						<mat-header-cell *matHeaderCellDef > Called </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{ element.called }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="selected">
						<mat-header-cell *matHeaderCellDef > Selected </mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.selected }}</mat-cell>
					</ng-container>
					<ng-container matColumnDef="rejected">
						<mat-header-cell *matHeaderCellDef > Rejected </mat-header-cell>
						<mat-cell *matCellDef="let element">{{ element.rejected }}</mat-cell>
					</ng-container>
					<mat-header-row style="min-height: 30px;margin-top: 4px;" *matHeaderRowDef=displayedColumnsL1></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumnsL1"></mat-row>
				</mat-table>
				</div>
				</div>
	</div>
	<div class="col-md-4 col-lg-4">
		<div class="card">
			<div class="card-header"><span style="color: #626262;"><b>Recruiter</b></span>&nbsp; Status</div>
			<div class="card-body pl-0">
				<div class="tile-row-pie">
					<div style="height: 210px; width: 210px;" [chart]="recruiterStatusChart"></div>
					<div>
						<div class="tile-row-pie"><span> <hr class="pie-series" style="background: #B0B524;"></span><span style="padding-left: 5px;"> <span style="color: #B0B524; font-weight: 500; font-size: 20px;">{{this.recruiterStatusChartData.active}}</span> <p class="tile-text mb-1">Active</p> </span></div>
						<div class="tile-row-pie"><span> <hr class="pie-series" style="background: #F74F1D;"></span><span style="padding-left: 5px;"> <span style="color: #F74F1D; font-weight: 500; font-size: 20px;">{{this.recruiterStatusChartData.onBreak}}</span> <p class="tile-text mb-1">On Break</p> </span></div>
						<div class="tile-row-pie"><span> <hr class="pie-series" style="background: #FEB42B;"></span><span style="padding-left: 5px;"> <span style="color: #FEB42B; font-weight: 500; font-size: 20px;">{{this.recruiterStatusChartData.offline}}</span> <p class="tile-text mb-1">Offline</p> </span></div>
						<div class="tile-row-pie"><span> <hr class="pie-series" style="background: #AAAAAA;"></span><span style="padding-left: 5px;"> <span style="color: #AAAAAA; font-weight: 500; font-size: 20px;">{{this.recruiterStatusChartData.inActive}}</span> <p class="tile-text mb-1">In Active</p> </span></div>

					</div>
				</div>   
			</div>                                 
		</div>
	</div>
	</div>