import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-doc-view',
  templateUrl: './doc-view.component.html',
  styleUrls: ['./doc-view.component.scss']
})
export class DocViewComponent implements OnInit {
	showHidelink:boolean = false;
	documentData: any = {
		date: "",
		file_name: "",
		isUploaded: true,
		key: "",
		url: "",
		value: "",
	};
	passbook_details:any;
	passbookShowHide:boolean= false;
	constructor(
		public dailogRef: MatDialogRef<DocViewComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { 	
		//console.log('on load data', this.data);
		this.documentData = this.data;
		let passbook;
		if(typeof this.documentData.value=='string'){
			 passbook = this.documentData.value;
		}
		else{
		 passbook = JSON.parse(this.documentData.value);
		}
		this.passbook_details = passbook;
		//console.log(this.documentData.file_name);
		let filePath = this.documentData.file_name;
			let allowedExtensions = /(\.pdf|\.exec|\.exe|\.doc|\.docs|\.xlsx)$/i;
			//console.log("---"+allowedExtensions.exec(filePath))
			if(allowedExtensions.exec(filePath)){
				this.showHidelink=false;
			}
			else{
				this.showHidelink=true;
			}
		
	}

	ngOnInit() {
		//console.log("--1-",this.passbook_details);
		this.displayPassBook();
		//console.log('On init', this.data)
	}
	closePopup() {
		this.dailogRef.close();
	}
	displayPassBook()
	{
		if(this.documentData.key== "PASS BOOK")
		{
			this.passbookShowHide = true;
		}
		else{
			this.passbookShowHide = false;
		}	
	}
	downloadDoc(url){
		window.open(url)
	}
}
