<div>
    <mat-sidenav-container autosize style="min-height: calc(100vh);">
      <mat-sidenav #sidenav class="TR-sidenav" mode="side" opened="true" style=" background: white !important;" class="">
    
        <div>
          <img src="../assets/web_images/contiinexLogoImg.png" class="logoTag">
  
          <mat-nav-list disableRipple>
            <mat-list-item *ngIf="navItems.includes('home')"  [routerLink]="['./home']" [routerLinkActive]="['active']">
              <p class="wd-100">
                <mat-icon>home</mat-icon><br>HOME
              </p>
            </mat-list-item>
            <mat-list-item *ngIf="navItems.includes('assessment')" [disabled]="getUrlforDisabled()"  [routerLink]="['./assessment']" [routerLinkActive]="['active']">
              <p class="wd-100">
                <mat-icon>subject</mat-icon><br>ASSESSMENT
              </p>
            </mat-list-item>
            <mat-list-item  *ngIf="navItems.includes('workSlot')" [routerLink]="['./work-slot']" [routerLinkActive]="['active']">
              <p class="wd-100">
                <mat-icon>date_range</mat-icon><br>MY WORK SLOTS
              </p>
            </mat-list-item>
            <mat-list-item  *ngIf="navItems.includes('callHistory')" [routerLink]="['./call-history']" [routerLinkActive]="['active']">
              <p class="wd-100">
                <mat-icon>format_list_bulleted</mat-icon><br> CALL HISTORY
              </p>
            </mat-list-item>
            <mat-list-item  *ngIf="navItems.includes('earnings')" [routerLink]="['./earnings']" [routerLinkActive]="['active']">
              <p class="wd-100">
                <mat-icon>attach_money</mat-icon><br>MY EARNINGS
              </p>
            </mat-list-item>
            <mat-list-item  *ngIf="navItems.includes('employee')" [routerLink]="['./profile']" [routerLinkActive]="['active']">
              <p class="wd-100">
                <mat-icon>person</mat-icon><br>EMPLOYEE
              </p>
            </mat-list-item>

            <mat-list-item *ngIf="navItems.includes('contacts')"  [routerLink]="['./contacts']" [routerLinkActive]="['active']">
              <p class="wd-100">
                <mat-icon>contact_phone</mat-icon><br>CONTACTS
              </p>
            </mat-list-item>


            <mat-list-item  *ngIf="navItems.includes('chat')" [routerLink]="['./chat']" [routerLinkActive]="['active']">
              <p class="wd-100">
                <mat-icon>message</mat-icon><br>CHAT
              </p>
            </mat-list-item>
            <mat-list-item  *ngIf="navItems.includes('text')" [routerLink]="['./text']" [routerLinkActive]="['active']">
              <p class="wd-100">
                <mat-icon>textsms</mat-icon><br>TEXT
              </p>
            </mat-list-item>

            <mat-list-item  *ngIf="navItems.includes('whatsapp')"  [routerLink]="['./whatsapp']" [routerLinkActive]="['active']">
              <p class="wd-100">
                <mat-icon>forum</mat-icon><br>WHATSAPP
              </p>
            </mat-list-item>

          </mat-nav-list>
        </div>
      </mat-sidenav>
      <div class="TR-sidenav-content">
        <span class="sidenavForMobile"  (click)="sidenav.toggle()"><mat-icon style="cursor: pointer;color: #e91e63;font-size: 40px;">menu</mat-icon></span>
        <div>
          <mat-card class="hpartHeight">
            <div class="row">
            
              <div class="col-md-1 col-sm-12 text-right">
               <!--<mat-form-field appearance="outline" style="width:100%">
                  <input matInput placeholder="Search">
                  <mat-icon class="icon-search" matSuffix aria-hidden="false" aria-label="">search</mat-icon>
                </mat-form-field>-->
              </div>
              <div class="col-md-3 col-sm-12 " [ngClass]="user.status == 'On Board' || user.status == 'OJT' ? 'dot-Lft' : ''">
                <table class="wd-100" *ngIf="user.status == 'On Board' || user.status == 'OJT'">
                  <tr >
                    <td rowspan="2" class="pr-3">
                      <!-- <img class="h-icon" src="../assets/auditscore.svg"/> -->
                      <img class="h-icon" src="../assets/seticon.svg">
                    </td>
                    <td><span class="h-label">
                      <!-- Audit Score -->
                      Leads Contacted
                    </span></td>
                  </tr>
                  <tr>
                    <td><span class="data-label"  style="font-size: 22px;">
                      {{_countLeadLength}}
                      <!-- {{_auditScore}} -->
                    </span></td>
                  </tr>
                </table>
              </div>
              <div class="col-md-3 col-sm-12" [ngClass]="user.status == 'On Board' || user.status == 'OJT' ? 'dot-Lft' : ''">
                <table class="wd-100" *ngIf="user.status == 'On Board' || user.status == 'OJT'">
                  <tr >
                    <td rowspan="2"  class="pr-3">
                      <!-- <img class="h-icon" src="../assets/Icontimer.svg"/> -->
                      <img class="h-icon" src="../assets/tickicon.svg">
                    </td>
                    <td><span class="h-label">
                      <!-- Audit Time -->
                      Follow - ups to do 
                    </span></td>
                  </tr>
                  <tr>
                    <td><span class="data-label" style="font-size: 22px;">
                      {{_countfollowUpLength}}
                      <!-- {{_auditTime}} -->
                    </span></td>
                  </tr>
                </table>
              </div>
              <div class="col-md-1 col-sm-12 dot-Lft">
                <table class="wd-100 textAlignmnt">
                  <tr>
                    <td>
                      <img class="CR" style="width: 48px;padding-top: 10px;" src="../assets/cameraIco.svg" (click)="clickFace()"/>
                      <!-- <mat-icon class="h-icon CR" aria-hidden="false" (click)="clickFace()" aria-label="">camera</mat-icon> -->
                    </td>
                  </tr>
                </table>
  
              </div>
              <div class="col-md-1 col-sm-12 dot-Lft">
                <table class="wd-100 textAlignmnt">
                  <tr>
                    <td>
                      <span></span>
                      <mat-icon  matBadge="{{notifications.count}}" matBadgeColor="customBadge" matBadgePosition="before"  class="h-icon CR" [matMenuTriggerFor]="menuNotification" aria-hidden="false" aria-label="" style="margin-right: 15px;margin-top: 5px;">notifications_none</mat-icon>
                      <mat-menu  #menuNotification="matMenu" >                      
                        <button  *ngIf="notifications && notifications.count == 0" mat-menu-item>No notification</button>
                        <button *ngFor="let notif of notifications.notif;let $index=index" mat-menu-item>{{notif.title}}-{{notif.message}}</button>
                      </mat-menu>
                    </td>
                  </tr>
                </table>
  
              </div>
              <div class="col-md col-sm-12 dot-Lft">
                <table class="wd-100 textAlignmnt">
                  <tr>
                    <td style="display: flex;justify-content: flex-end;">
                      <span class="h-label nameShorterner"  style="margin-top: 19px; width: 123px; padding-right: 1px; word-wrap: break-word; white-space: normal;font-size: 14px;
                      line-height: 16px;">{{user.name}}</span>
                      <mat-icon class="h-icon CR" [matMenuTriggerFor]="menu" aria-hidden="false" aria-label="" style="margin-right: 15px;margin-top: 5px;">account_circle</mat-icon>
                      <mat-menu #menu="matMenu">                      
                        <button mat-menu-item  (click)="logout()">Logout</button>
                      </mat-menu>
                    </td>
                  </tr>
                </table>
  
              </div>
            </div>
          </mat-card>
        </div>
        <router-outlet></router-outlet>


        <span class="vk-btnChatFixed" (click)="openStartDialPopup()">
          <!-- <mat-icon >phonelink_ring</mat-icon> -->
        <img src="../assets/web_images/callChatIcon.svg" style="width: 33px;cursor: pointer;"/>
        </span>
       <mat-card *ngIf="showHideStartDialPopup" class="mcardStartDial" id="contentCall" [ngClass]="{'mcardStartDialMin': textMaxMin == 'Minimize'}" >
        <div  id="popup-delete" class="vk-popupoverlay">
          <div class="vk-delete-popup">
            <span class="close" (click)="closeStartDialPopup()" style="display:none;"><mat-icon matTooltip="Close">cancel</mat-icon></span>&nbsp;
            <span class="close" *ngIf="textMaxMin == 'MaxMize'" (click)="minimizeStartDialPopup('Minimize')"><mat-icon matTooltip="Minimize">remove_circle</mat-icon></span>&nbsp;&nbsp;
            <span class="close" *ngIf="textMaxMin == 'Minimize'" (click)="maxmizeStartDialPopup('MaxMize')"><mat-icon matTooltip="Maximize">add_circle</mat-icon></span>&nbsp;&nbsp;
           
            <div class="content">
              <app-start-dial-dialog></app-start-dial-dialog>
            </div>
          </div>
        </div>
      </mat-card>


      </div>
    </mat-sidenav-container>
  
  </div>