
<h2 class="d-head pdt">CALENDAR<img src="../assets/underline.svg"
    class="linebelow"> </h2>
<div class="row">
    <div class="col-md-5 col-sm-12 col-xs-12">
        <mat-card style="height: 450px;">


            <mat-form-field>
                <input [min]="todayDate" matInput (dateChange)="handleDateChange($event)"  (focus)="picker.open()" [matDatepicker]="picker" readonly  [formControl]="setdate" >
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker ></mat-datepicker>
              </mat-form-field>


        </mat-card>
        </div>
        <div class="col-md-1 col-sm-12 col-xs-12" >
            <div *ngFor="let i of [1,2,3,4,5,6]">
            <div class="hr-line"><hr></div>
            <div class="hr-line"><hr></div>
            <div class="hr-line"><hr></div>
            <div class="hr5"><hr></div>
        </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
            <mat-card>
                <div class="scrollTable" style="min-height:400px;">
                <h3 class="eventHeading">Today</h3>
            <div class="todayUpcomungEvents" *ngFor="let v of todayData">
                <p class="p1"><span><i class="fa fa-circle dotcolor"></i></span>{{v.message}}</p>
                <p class="p2">({{v.started_on | date: 'MMM dd, yyyy'}} <b>-</b> {{v.ends_on | date: 'MMM dd, yyyy'}}) </p>
            </div>
            <br>
            <h3 class="eventHeading">Upcoming</h3>
            <div class="todayUpcomungEvents"  *ngFor="let v of upcomingData">
                <p class="p1"><span><i class="fa fa-circle dotcolor"></i></span>{{v.message}}</p>
                <p class="p2">({{v.started_on | date: 'MMM dd, yyyy'}} <b>-</b> {{v.ends_on | date: 'MMM dd, yyyy'}})  </p>
            </div>
        </div>
        </mat-card>
        </div>
</div>
<br>
<br>