<div class="row pdt">
    <div class="col-md-12 col-sm-7 col-xs-12">        
        <h2 class="d-head">AUDITORS VIEW<img src="../assets/underline.svg" class="linebelow"> </h2>

        <div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name </th>
              <td mat-cell *matCellDef="let element">{{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email </th>
                <td mat-cell *matCellDef="let element">{{element.email}} </td>
              </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
    </div>
  
</div>
<div class="row pdt"> 
    <div class="col-md-6 col-sm-12 col-xs-12"></div>  
        <div class="col-md-6 col-sm-12 col-xs-12 rightside">
            <button mat-button class="cancelbtn"  (click)="closeDialog()">Cancel</button>
        </div>
      
        </div>
     