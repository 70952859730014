import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { AgentsTableComponent } from '../dashboard/agents/agents-table/agents-table.component';
import { HttpResponse } from '@angular/common/http';
import * as Excel from 'exceljs';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { ServicesService } from '../dashboard/services.service'
import { MyserviceService } from '../myservice.service';
import { Utils } from '../utils';
import { object } from 'underscore';

@Component({
	selector: 'app-recruiter-agent-details-dialog',
	templateUrl: './recruiter-agent-details-dialog.component.html',
	styleUrls: ['./recruiter-agent-details-dialog.component.scss']
})
export class RecruiterAgentDetailsDialogComponent implements OnInit {

	currentLoader: boolean = false;
	createdForm: FormGroup;
	displayedColumns: string[] = ['Name', 'Email', 'Phone', 'Contacted', 'Dialled', 'Transferdatatoagent'];
	toppings = new FormControl();
	//toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

	selectedagent: any;
	selectedagentdetail: any;
	toppingList: string[] = [];
	api_end_point:any;
	constructor(public dialogRef: MatDialogRef<RecruiterAgentDetailsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public _recruiteagent_data: any,
		public formbuilder: FormBuilder,
		private API: ApiService,
		private service: MyserviceService,
		private _snackBar: MatSnackBar,
		private dialog: MatDialog,
		private dashService: ServicesService,
		private myService: MyserviceService,
		private utils: Utils) {

	}

	ngOnInit(): void {
		this.api_end_point = this.myService.get_API_End_Point();
		//console.log(this._recruiteagent_data);
		var agentdata = this._recruiteagent_data;
		this.selectedagent = agentdata;
		//console.log(this.selectedagent);
		//full agent listw with superadmin.
		var data = this.myService.getFullTeam();
		var valuedata = Object.values(data);
		//console.log("--", valuedata);
		for (var i = 0; i < valuedata.length; i++) {
			//console.log(valuedata[i][0].id);
			if (valuedata[i][0].id == agentdata.user_id) {
				this.selectedagentdetail = valuedata[i][0];//console.log(valuedata[i][0]);
			}
			else if (valuedata[i][0].id !== agentdata.user_id && valuedata[i][0].role == 'user') {
				this.toppingList.push(valuedata[i][0]);
			}
		}

		//console.log(this.toppingList);
	}

	closeDialog(refresh) {
		this.createdForm.reset();
		this.dialogRef.close(refresh);
	}
	closePopup() {
		this.dialogRef.close();
	}
	saveButton() {
	//	console.log("selected item", this.toppings.value);
		if (this.toppings.value == null) {
			this._snackBar.open("please select transfer agent", "close");
		}
		else {
			var obj: any = {
				from_list: [],
				to_list: ''
			}
			obj.from_list.push(this.selectedagentdetail.email);
			obj.to_list = this.toppings.value;
		//	console.log("--", obj);
			//var test = JSON.stringify(obj);
			//console.log("--", test);
			this.API.bulkDataTransferAPI(obj,this.api_end_point).subscribe((res: any) => {
				if(res.success){
					//console.log(res.success);
					this._snackBar.open(res.success, "Close");
					this.closePopup();
				}
				if(res.errors)
				{
					this._snackBar.open(res.errors, "Close");
					this.closePopup();
				}
			})
		}
	}


}

