<h4 class="titlehead">Project Manager Dashboard (OUTBOUND)</h4>
<table class="maintable">
	<tr>
		<td>
			<div class="containecs">
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Data</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Data To Call</td><td>{{ summary.data.data_to_call }}</td></tr>
								<tr><td>Available Data</td><td>{{ summary.data.available }}</td></tr>
								<tr><td>Followup Todo</td><td>{{ summary.data.followup }}</td></tr>
							</table> 
						</div>
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Data Dialled</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Total</td><td>{{ summary.dialled.total }}</td></tr>
								<tr><td>Unique</td><td>{{ summary.dialled.unique }}</td></tr>
							</table> 
						</div>
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Data Contacted</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Total</td><td>{{ summary.contacted.total }}</td></tr>
								<tr><td>Unique</td><td>{{ summary.contacted.unique }}</td></tr>
							</table> 
						</div>
					</div>
				</div>
				<div class="colcomn">
					<div class="card">
						<div class="card-header">Forms</div>
						<div class="card-body">
							<table class="tablecs">
								<tr><td>Submitted</td><td>{{ summary.forms.total }}</td></tr>
							</table> 
						</div>
					</div>
				</div>
			</div>
		</td>
	</tr>
</table>
<div class="pDiv">
	<div class="divprintRt">
		<table style="width: 100%;">
			<tr>
				<td>
					<span class="recName">Project Manager</span>
					<span class="datecs">{{ today }}</span>
				</td>
				<td style="text-align: right;position: relative;right: 20px;">
					<span class="searchSpan">
					   <mat-form-field >
						  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
						  <i matSuffix class="fa fa-search searchicon" aria-hidden="true"></i>
					   </mat-form-field>
					</span>
				 </td>
			</tr>
			<tr>
				<td>
					<p>
						<span class="das-pe">Print</span>
						<span class="das-pe">Export</span>
						<span class="das-pe" (click)="refreshData()">Refresh</span>
					</p>
				</td>
			</tr>
		</table>
	</div>
</div>
<!-- Mat Table starts -->
<div class="pDiv">
	<mat-table [dataSource]="dataSource">
		<ng-container matColumnDef="user_id">
            <mat-header-cell *matHeaderCellDef> Agent Id </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.user_id }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Agent Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.name }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="team_lead">
            <mat-header-cell *matHeaderCellDef> Team Lead </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.team_lead }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="manager">
            <mat-header-cell *matHeaderCellDef> Manager </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.manager }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Live Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
				<span class="statusCircle" [ngStyle]="{'background-color': element.status_color }"></span>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="dialled">
            <mat-header-cell *matHeaderCellDef> Dialled </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.dialled }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="unique_dialled">
            <mat-header-cell *matHeaderCellDef> Unique Dialled </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.unique_dialled }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="contacted">
            <mat-header-cell *matHeaderCellDef> Contacted </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.contacted }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="unique_contacted">
            <mat-header-cell *matHeaderCellDef> Unique Contacted </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.unique_contacted }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="rnr">
            <mat-header-cell *matHeaderCellDef> RNR </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.rnr }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="forms">
            <mat-header-cell *matHeaderCellDef> Forms </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.forms }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="audit_score">
            <mat-header-cell *matHeaderCellDef> Audit Score </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.audit_score }}</mat-cell>
		</ng-container>
		<ng-container matColumnDef="ctc_score">
            <mat-header-cell *matHeaderCellDef> CTC Score </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.ctc_score }}</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef=displayedColumns></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
	</mat-table>
	<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
<div id="cover-spin" *ngIf="currentLoader"></div>