
import { Component, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';


import { MyserviceService } from '../myservice.service';
import { Utils } from '../utils';

@Component({
	selector: 'app-dev-test',
	templateUrl: './dev-test.component.html',
	styleUrls: ['./dev-test.component.scss']
})
export class DevTestComponent {

	current_url = null;

	constructor(
		private myservice: MyserviceService,
		private utils: Utils) {

	}

	setUrl(url: string) {
		this.current_url = url;
	}

}
