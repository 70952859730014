<form [formGroup]="campaignForm">
	<table class="tbl">
		<tr style="border-bottom: 1px solid #ccc;">
			<td>
				<h5 class="hd5"  style="color: #fd6c21;">Create Campaign</h5>
			</td>
			<td style="text-align: right;"> 
				<i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i>
			</td>
	   </tr>
	   <tr>
		  <td><span>Campaign Name</span></td>
		  <td>
			 <mat-form-field>
				<input matInput placeholder="Campaign Name" autocomplete="off" formControlName="name" [ngClass]="{'is-invalid': _form.name.errors}" (keypress)="omit_special_char($event)">
				<div *ngIf="_form.name.errors" class="invalid-feedback">
					<div *ngIf="_form.name.errors.required">Campaign name is required</div>
				</div>
			 </mat-form-field>
		  </td>
	   </tr>
	   <tr>
		  <td><span>Campaign Date</span></td>
		  <td>
			 <mat-form-field style="width: 115px;padding-right: 10px;">
				<input matInput [matDatepicker]="picker"  [matDatepickerFilter]="hidePreviousDates"  placeholder="Date" readonly (click)="picker.open()" formControlName="date">
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			 </mat-form-field>
			 <input type="time" placeholder="Time" value="00.00"
				min="00:00" max="24:00" formControlName="time" [ngClass]="{'is-invalid': _form.time.errors}" required>
				<div *ngIf="_form.time.errors" class="invalid-feedback">
					<div *ngIf="_form.time.errors.timeError">{{ _form.time.errors.timeError }}</div>
				</div>
		  </td>
	   </tr>
	   <tr>
		   <td><span>Campaign Type</span></td>
		   <td>
			<mat-form-field>
				<mat-select formControlName="category" [(ngModel)]="campaignType">
					<mat-option value="online_agency">Online Agency</mat-option>
					<mat-option value="offline_agency">Offline Agency</mat-option>
				</mat-select>
			</mat-form-field>
		   </td>
	   </tr>
	   <tr>
		<td><span>Agency</span></td>
		<td>
		 <mat-form-field>
			 <mat-select formControlName="agency">
				<mat-option *ngFor="let agency of agenciesList" [value]="agency">
					{{ agency.name }}
				  </mat-option>
			 </mat-select>
		 </mat-form-field>
		</td>
	</tr>
	<tr>
		<td>Dial Mode</td>
		<td>
			<mat-radio-group aria-labelledby="dial_mode" class="dial-radio-group" formControlName="priority">
				<mat-radio-button class="dial-radio-button" [value]="1">Server</mat-radio-button>
				<mat-radio-button class="dial-radio-button" [value]="2">Predictive</mat-radio-button>
			</mat-radio-group>
		</td>
	</tr>
	<tr>
		<td>Re-Push the data</td>
		<td>
			<mat-checkbox [(ngModel)]="allow_repush" formControlName="reset_push"><span>Reset the applicant status to NEW</span></mat-checkbox>
		</td>
	</tr>
	<tr>
		<td>&nbsp;</td>
		<td> 
			<span class="dnldbtn">
			<button mat-button (click)="download_file()">Download Excel Template
			&nbsp;&nbsp;  <i class="fa fa-file-excel" aria-hidden="true"></i>
			</button>
			</span>
		</td>
	</tr>
	   <tr>
		  <td><span>Data</span><span> (only supports xlsx file)</span></td>
		  <td>
			 <!-- <input type="file" #fileUpload class="filecs" formControlName="file" (change)="changeFile($event)"/> -->
			 <!-- <label for="file">Upload</label> -->
			 <div class="upload-btn-wrapper">
				<button class="btnbrowse">Browse</button>
			 <input type="file" #fileUpload class="filecs" formControlName="file" (change)="changeFile($event)"/>
			  </div>
			  <span>
				<button mat-button [disabled]="!campaignForm.valid" class="upload_btn uploadSave" (click)="submit_Camapign(campaignForm)">Upload compaign </button>
			  </span>
		  </td>
	   </tr>
	   <tr>
		   <td></td>
		   <td style="color: #bababa;">{{currentFileobj.name}}</td>
	   </tr>
	</table>
	<p class="succData">{{ campaignUpload_msg }}</p>
</form>
<div id="cover-spin" *ngIf="showLoader"></div>
