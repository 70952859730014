import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-call-number-picker',
  templateUrl: './call-number-picker.component.html',
  styleUrls: ['./call-number-picker.component.scss']
})
export class CallNumberPickerComponent implements OnInit {

    @Input()
  public removeSubSection: Function;
  @Input()
  public untitleToggle: Function;
  @Input()
  public updateNP: Function;
  @Input() selectedIndex: string;
@Input() selectedChildIndex: string;
  @Input() selectedLevel: any;
  @Input() radioIndex: any;
@Input() checked: any;
  minValue: number = 0;
  maxValue: number = 100;
  constructor() {
    
   }

  ngOnInit(): void {
    this.updateNP({min_value: this.minValue,max_value: this.maxValue}, this.selectedIndex, this.selectedLevel,this.radioIndex, this.selectedChildIndex)
  }

  actionType(val: any){
   // console.log(val);
  }
  
  updateMin(val){
    //console.log(val)
    this.minValue = val;
    this.updateNP({min_value: this.minValue,max_value: this.maxValue}, this.selectedIndex, this.selectedLevel,this.radioIndex, this.selectedChildIndex)
  }

  updateMax(val){
    this.maxValue = val;
    this.updateNP({min_value: this.minValue,max_value: this.maxValue}, this.selectedIndex, this.selectedLevel,this.radioIndex, this.selectedChildIndex)
  }

  trigerRemoveSubSection(){
    //console.log(this.selectedIndex)
    this.removeSubSection(this.selectedIndex,this.selectedChildIndex, this.selectedLevel,this.radioIndex)
  }
  triggerToggle(event){
    //console.log(this.selectedIndex)
    this.untitleToggle(this.selectedIndex,event, this.selectedLevel,this.selectedChildIndex, this.radioIndex)
  }
}
