<app-new-contiinex-menu-page></app-new-contiinex-menu-page>
<div class="v-mainDivCO">
        <div class="row animation-One2x">
            <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="text-center mainPrivacy">
                <p> <span style="font-size: 25px;">Privacy Policy</span>
                </p>

            <div style="margin: 0 14%;  text-align: left;">
            <div>
                <p class="Ttl">Introduction</p>
                <p class="pp-desc">
                    As a responsible organisation, We Smarter Biz Technologies Pvt Ltd, Indiqube-Orion, 24th Main Road, 2nd Sector, HSR Layout, Bengaluru - 560102, respect your need for privacy and agrees to protect in an appropriate manner, any personal and online information of yours that we may receive, handle or store. Our policy and practice regarding the use of your Online Information as well as Personal Information is as set forth below in this Privacy Policy. As a condition to access the website www.smarter-biz.com or use of our SmarterBiz application services, you consent to the terms of the Privacy Policy as it may be updated from time to time. In the event of a material change, we shall notify you via email or by means of a prominent notice on the website. If you have objections to this Privacy Policy, you should not access or use the site or share Personal Information with us.
                </p>
            </div>
            <div>
                <p class="Ttl">Relationship with you:</p>
                <p class="pp-desc">
                    We provide business automation service on a mobile platform and other software applications to our customers to manage their business. You could be a:<br><br>

                    i. General Visitor - who happened to visit our website or who has searched for our website or a prospective customer trying to understand our offerings / policies or a client/prospective client of our customer wanting to know our offerings / policies or a prospective customer creating an account with us. Please be advised that we collect certain Online Information while you access/visit the website www.smarter-biz.com.<br><br>

                    ii. Customer  who has signed up with us to avail our SmarterBiz application services. Please note that any Personal Information of yours or of your clients which you may share with us while you avail our services could be stored and processed in the SmarterBiz application hosted and operated though cloud based services. You shall abide by the laws and regulations of the land in which you are collecting and storing such Personal Information. This shall be governed by this privacy policy as well as the SmarterBiz Application Service Use Agreement which you have entered into.
                    <br><br>
                    iii. Client of our Customer - Please note that there is no direct relationship between you and us and the relationship is only through our customer who inturn is your service provider. Our customer may collect Personal Information from you or you may share certain Personal Information with them, all of which could be stored and processed in the SmarterBiz application hosted and operated though cloud based services. There is a possibility for voice recording of your conversation with your service provider (our customer) to remain in the mobile phone of your service provider for some time. IF YOU PREFER YOUR SERVICE PROVIDER NOT TO RETAIN THE VOICE RECORDING PLEASE INFORM YOUR SERVICE PROVIDER ACCORDINGLY. If you would no longer like to be contacted by your service provider (our customer), please contact them directly. Further, if you seek access, or seek to correct, amend, or delete inaccurate Personal Information provided by you to our customer and which are stored in the data centre you shall direct your query to our customer itself. Our customer may collect your location based information for the purpose of notifying you about nearby leads, prospects, customers and giving your driving directions. We will only share this information with our mapping provider for the sole purpose of providing you this service. You may opt-out of location based services at any time by editing the setting at the device level in which case you may not be able to use all or some of the functionalities of SmarterBiz application.
                    <br><br>
                    All of you recognize and understand that there is no compulsion on you to provide us with your Online Information or Personal Information and any and all of such information provided by you to us is with your full consent, own volition and desire to provide such information. You also understand that we are under no obligation to verify the source from which such information is provided to us, and they are deemed to be provided by you, unless you demonstrate to us within a period of fifteen days from the date of providing of such information to us, to our satisfaction, that the information was provided to us without your free consent.
                </p>
            </div>

            <div>
                <p class="Ttl">What type of information we may collect:</p>
                <p class="pp-desc">
                    Online Information: While you access the website www.smarter-biz.com as a General Visitor we may collect certain information of yours and/or you may be submitting or providing certain information or data regarding yourself which could be used to identify you as a person. Such Online Information includes your domain IP address, Usage Data and Technical Data. If you are registering yourself with the website then your name, gender, telephone number, email address, mailing address, company name, and other personal information could also be collected. You may also be asked to choose a unique username and a password, which will be used solely for the purpose of providing access to your user account. Upon registration you will have the option of choosing a security question and an answer to the security question, which if given, will be used solely for the purpose of resetting your password, if any.<br><br>

                    Usage Data: Your usage details such as time, frequency, duration and pattern of use, features used and the amount of storage used will be recorded by us in order to enhance your experience of our services and to help us provide you the best possible service.<br><br>

                    Technical Information: When you visit the site we may also collect certain technical information about your computer, tablet or mobile phone to facilitate your use of services. This may include the Internet Protocol address, browser type, browser language, referring URL, files accessed, errors generated, time zone, operating system and other visitor details collected in our log files to analyze the trends, administer the website, track visitor’s movements and to improve our website. We may also use such environmental variables to measure traffic patterns on the site. When you download and use our services, we automatically collect information on the type of device you use, operating system version, and the device identifier. We collect your location based information for the purpose of notifying you about nearby leads, prospects, and customers. We will only share this information with our mapping provider for the sole purpose of providing you this service. You may opt-out of location based services at any time by editing the setting at the device level or by contacting us.<br><br>

                    At times we conduct online surveys to better understand the needs and profiles of our visitors. When we conduct a survey, we will try (but are not obligated) to let you know how we will use the information at the time we collect information from you.
                    <br><br>
                    Personal Information: If you are a customer of ours who has entered in to the SmarterBiz Application Service Use Agreement and availing our SmarterBiz application cloud based services, you may be sharing certain personally identifiable information about yourself, your individual employees and/or your clients. This could include information such as Your name and contact details, employee name and contact details, your client name and contact details, contacts in your email and phone account, third party names, email addresses, phone numbers and physical addresses, information on the services / products you provided to your clients, your clients specific needs and wants for approaching you, your telephonic conversation with your client, yours as well as your clients location based information, files, documents, to-do lists, emails, your phone contact list, SMS, other mobile apps data and other mobile data, Financial Information etc. We do not share this Personal Information with anyone for promotional purposes, nor do we utilize it for any purposes not expressly consented to by you.<br><br>

                    Financial Information: In case of payments, we may request credit card or other payment account information, which will be used solely for processing payments. Your financial information will not be stored by us except for the name and address of the card holder, the expiry date and the last four digits of the Credit Card number. Subject to your prior consent and where necessary for processing future payments, your financial information will be stored in encrypted form on secure servers of our reputed Payment Gateway Service Provider.<br><br>

                    We post user testimonials of our customers on the website. These customer testimonials may include names and we acquire permission from our customers prior to posting these on the website. We are not responsible for the personal information our customers may elect to post within their testimonials.
                </p>
            </div>

            <div>
                <p class="Ttl">Use of Online Information:</p>
                <p class="pp-desc">
                    We collect information regarding you in order to record and support your participation in the activities you select. When you subscribe to a service, the information is used to register your rights, if any, to technical support or other benefits that may be made available to registered users. We reserve the right to use such information to improve our services and for such purposes we may disclose such information to our business partners and advisors. If you have submitted your personal identifiable information as part of Online Information, we may also contact you to inform you regarding new services, releases, upcoming events, other marketing purposes and changes in this Privacy Policy. However if you want us to stop further using such information, please email us at privacy@smarter-biz.com.
                    <br><br>
                    We may collect information about the use of the site, such as the types of services used and how many users we receive daily, frequency of each users etc. This information is collected in aggregate form, without identifying any user individually. We may use this aggregate, non-identifying statistical data for statistical analysis, marketing, or similar promotional purposes.
                    <br><br>
                    We store and maintain files, documents, to-do lists, emails, GPS data, your phone contact list, SMS, other mobile apps data, files call records audio files, live records of your meetings and other mobile data stored in your user account at our servers. In order to prevent loss of data due to errors or system failures, we also keep backup copies of data including the contents of your user account. Hence your files and data may remain with us even after deletion or termination of your user account. We assure you that the contents of your user account will not be disclosed to anyone except in circumstances specifically mentioned in this Privacy Policy and Terms of Use.
                    <br><br>
                    We will not sell your data/information, rent it or exchange it with any third party if you are a paid user. However for free users we will use your data to target advertising only. We will share your personal information (shared as part of Online Information) with third parties only in the ways that are described in this Privacy Policy.
                    <br><br>
                    We will use the email or and phone number provided by you in the registration process in order to send a welcome email or welcome SMS, billing and invoice information and news and updates either by email or SMS or both. If you wish to subscribe to our newsletter, we will use your name and email address / phone number to send the newsletter to you. You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails, accessing the email preferences in your account settings page or you can contact us.
                    <br><br>
                    We may adopt industry accepted security mechanisms to secure your information. However except as specifically qualified by applicable law as confidential personal information and your financial information, no other information are considered to be confidential information.
                    <br><br>
                    The SmarterBiz application uses Google Analytics to anonymously track usage data within the application.
                </p>
            </div>

            <div>
                <p class="Ttl">Use of Cookies:</p>
                <p class="pp-desc">
                    We use temporary and permanent cookies to enhance your experience of our site. Temporary cookies will be removed from your computer / device / phone each time you close your browser. By selecting keep me signed-in option, a permanent cookie will be stored in your computer/device and you will not be required to sign-in by providing complete login information each time you return to our site. If you have turned cookies off, you may not be able to use registered areas of the website and the browser. We tie cookie information to your email address / phone number when you elect to remain logged in so as to maintain and recall your preferences within the website. We may from time to time employ the use of pixel tags on the website, email and landing sms pages. Pixel tags such as web beacons are 1x1 images that are embedded onto a web page to document traffic and for website analytics without collecting any personal information.
            <br><br>
                    We may from time to time employ the services of a third party agent to analyze statistical and aggregate data about our website content and user base. This aggregated information is not personally identifiable and is employed in improving website functionality and offerings. To this end, these third parties may employ the use of cookies and, or pixel tags in providing their services. While these third party cookies are not tied to your personal information, we do not have access to these cookies nor are we responsible for them.
                    <br><br>
                    Our advertisers may also assign their own cookies to your browser (if you click on their ads). This is not within our control and hence we are not responsible for the same.
                    <br><br>
                    The use of cookies by our partners, affiliates, tracking utility company, service providers is not covered by our privacy policy. We do not have access or control over these cookies. Our partners, affiliates, tracking utility company, service providers use session ID cookies to make it easier for you to navigate our site.
                </p>
            </div>

            <div>
                <p class="Ttl">Social Networking: </p>

                <p class="pp-desc"> Our website includes social media features, such as the Facebook Like button and widgets, such as the Share. These features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our web site. Your interactions with these features are governed by the privacy policy of the company providing it.
                </p>
                <p class="Ttl">While linking to third party websites: </p>

                <p class="pp-desc">Some pages of our website contain external links. You are advised to verify the privacy practices of such other websites. We are not responsible for the manner of use or misuse of information made available by you at such other websites. We encourage you not to provide personal information in such third party sites, without assuring yourselves of the privacy policy of such other websites.
                </p>
                <p class="Ttl">Blogs and Forums </p>
                <p class="pp-desc">
                We may provide the capacity for users to post information in blogs and forums for sharing information in a public space on the website. Such information will be publicly available to all users of these forums and visitors. Any personal information disclosed within these forums may be used to contact users with unsolicited messages. We encourage users to be cautious in disclosure of personal information in public forums as we are not responsible for the personal information users elect to disclose.
                <br><br>
                Anytime you post on our blog please be aware that you are posting using a third party application and we have no access or control over this information. To request removal of your personal information from our blog, you can either log into the third party application or remove your comment or you can contact the appropriate third party application. Your interaction with these features is governed by the privacy policy of the company providing it.
                </p>
                <p class="Ttl">With whom we may share Online Information: </p>
                <p class="pp-desc">
                Other than those mentioned above we may need to disclose Online Information to our affiliates, service providers and business partners solely for the purpose of providing and promoting our services to you. In such cases we will also ensure that such affiliates, service providers and business partners comply with this Privacy Policy and adopt appropriate confidentiality and security measures. We will obtain your prior specific consent before we share or disclose your personal information (provided in the Online Information) to any person outside our group company for any purpose that is not directly connected with providing and promoting our services to you. We may share generic aggregated demographic information not linked to any personal information regarding visitors and users with our business partners and advertisers. Please be aware that laws in various jurisdictions in which we operate may obligate us to disclose user information and the contents of your user account to the local law enforcement authorities under a legal process or an enforceable government request. In addition, we may also disclose personal information and contents of your user account to law enforcement authorities if such disclosure is determined to be necessary by us in our sole and absolute discretion for protecting the safety of our users, employees, or the general public.
            </p>
                <p class="Ttl">Use of Personal Information: </p>
                <p class="pp-desc">
                You may check all state, federal and local laws before recording telephonic conversation with your clients and or collecting certain personal information from your clients.
                <br><br>
                SmarterBiz acts as a data processor on behalf of our customers with respect to Personal Information collected/stored, and accordingly follows each customers (data owner) instructions with regard to the collection, processing and protection of Personal Information.
                <br><br>
                However the Personal Information collected/stored by the Customer shall only be used to provide services to their clients and for the other purposes specifically mentioned herein. Except as provided herein, we do not share this Personal Information with anyone for promotional purposes, nor do we utilize it for any purposes not expressly consented to by you.
                <br><br>
                With the exception of off-site backup and redundancy infrastructure, SmarterBiz is hosted on Amazon Web Services (AWS), a cloud computing platform with end-to-end security and privacy features, which in other words mean that your Personal Information also will be stored and secured in AWS data centers. Please refer to https://www.aws.amazon.com/security for any specific details regarding AWS security. AWS maintains a list of reports, certifications and independent assessments to ensure excellent data center security. AWS infrastructure is housed in Amazon-controlled data centers throughout the world. The data centers are secured with a variety of physical controls to prevent unauthorized access.
                <br><br>
                We generally dont manually process or access your Personal Information, all of which are done by the SmarterBiz application itself. Our personal access to such information is strictly limited or almost nil.
                <br><br>
                We will not sell your Personal Information, rent it or exchange it with any third party. We will share your Personal Information with third parties only in the ways that are described in this privacy policy.
                <br><br>
                The application will use the email or and phone number provided in order to send a welcome email or welcome SMS, billing and invoice information and news and updates either by email or SMS or both. If you wish to subscribe to a newsletter, we will use your name and email address / phone number to send the newsletter to you. You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails, accessing the email preferences in your account settings page or you can contact us.
                </p>
                <p class="Ttl">How secure is your Information: </p>
                <p class="pp-desc">
                We adopt industry appropriate data collection, storage and processing practices and security measures, as well as physical security measures to protect against unauthorized access, alteration, disclosure or destruction of your Online Information. Access to your name, email address, phone number is restricted to our employees who need to know such information and are bound by confidentiality obligations.
                <br><br>
                When you enter sensitive information, such as a credit card number, GPS location, call recordings on our order forms or use the service, we encrypt the transmission of information using secure socket layer technology (SSL). We follow generally accepted standards to protect the Personal Information submitted to us, both during transmission and once we receive it. However, no method of transmission over the internet, or method of electronic storage, is completely secure. Therefore, we cannot guarantee its absolute security.
                </p>
                <p class="Ttl">Options in Information Use: </p>
                <p class="pp-desc">
                We also provide you with the option of opting out from receiving mails / SMS / SMS with landing page from us; however, you will not be able to use the services completely, receive email notifications of new services, releases, upcoming events and changes to the Privacy Policy should you decide to opt-out of receiving all messages from us. In the event we decide to use your Personal Information for any purpose other than as stated in this Privacy Policy, we will offer you an effective way to opt out of the use of your Personal Information for those other purposes.
                </p>

                <p class="Ttl">Your choice to access, update and remove Personal Information: </p>
                <p class="pp-desc">
                Online Information: We provide users with access to their personal information which are collected while visiting the site. You may correct, update or remove any such personal information either by accessing your user account or by contacting our customer support. Such changes may take time to take effect. We respond to all enquiries within reasonable time.
                <br><br>
                Personal Information: If you are our customer you may chose to access and/or modify your Personal Information (including that of your clients) in the data centre; for which you shall be solely and fully responsible.
                <br><br>
                If you are our customers client and you would like to modify your Personal Information in the data centre, then you may contact your service provider (our customer) directly.
                </p>
                <p class="Ttl">Investigation of Illegal Activity </p>
                <p class="pp-desc">
                We may need to provide access to your Online Information and Personal Information and the contents of your user account to our employees and service providers for the purpose of investigating any suspected illegal activity or potential violation of the terms and conditions. However, we will ensure that such access is in compliance with this Privacy Policy and subject to appropriate confidentiality and security measures.
                </p>
                <p class="Ttl">Enforcement of Privacy Policy </p>
                <p class="pp-desc">
                We make every effort, including periodic reviews to ensure that Online Information and Personal Information provided by you are used in conformity with this Privacy Policy. If you have any concerns regarding our adherence to this Privacy Policy or the manner in which such information is used for the purpose of providing our services, kindly contact us at privacy@smarter-biz.com. We will contact you to address your concerns and we will also co-operate with regulatory authorities in this regard if needed.
                </p>
                <p class="Ttl">Notification of Changes </p>
                <p class="pp-desc">
                Any changes to the Privacy Policy will be posted on our website in order to keep you informed of any changes in nature of information collected, manner of collection, use and sharing of information. If at any point we decide to use Personal Information in a manner different from that stated at the time it was collected, we will notify you by email and provide you with the ability to opt out of these new uses, unless you have opted out from receiving all email notifications from us.
                </p>
                <p class="Ttl">Contact us: </p>
                <p class="pp-desc">
                We will retain your Online Information. Your Personal Information may be retained as long as our customer account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                <br><br>
                If you have any questions or concerns regarding this Privacy Policy, please contact us at privacy@smarter-biz.com.
                </p>



            </div>
            </div>

            </div>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12">
                <app-new-contiinex-footer-section-page></app-new-contiinex-footer-section-page>
            </div>
</div>
</div>
