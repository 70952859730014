<mat-table [dataSource]="dataSourceCampaign">
	<ng-container matColumnDef="sl_no">
	   <mat-header-cell *matHeaderCellDef> SNo </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.sl_no}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="campaign_name">
	   <mat-header-cell *matHeaderCellDef> Campaign Name </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.campaign_name}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="campaign_status">
	   <mat-header-cell *matHeaderCellDef> Campaign Status </mat-header-cell>
	   <mat-cell *matCellDef="let element">
			  <mat-slide-toggle *ngIf="check_val_type(element.campaign_status) == 'boolean'"  [(ngModel)]="element.campaign_status" (change)="campaignStatusChange($event,element)" [disabled]="element.disabled"></mat-slide-toggle>
			  <span  *ngIf="check_val_type(element.campaign_status) == 'string'" style="color:  #bababa;text-transform: capitalize;">{{ element.campaign_status }}</span>
	   </mat-cell>
	</ng-container>
	<ng-container matColumnDef="campaign_created">
	   <mat-header-cell *matHeaderCellDef> Campaign Created </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.campaign_created}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="data_uploaded">
	   <mat-header-cell *matHeaderCellDef> Data Uploaded </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.data_uploaded}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="data_duplicate">
	   <mat-header-cell *matHeaderCellDef> Duplicate </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.data_duplicate}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="errors">
	   <mat-header-cell *matHeaderCellDef> Errors </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.errors}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="data_added">
	   <mat-header-cell *matHeaderCellDef> Data to Call </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.data_added}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="contacted">
	   <mat-header-cell *matHeaderCellDef> Contacted </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.contacted}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="agency_type">
		<mat-header-cell *matHeaderCellDef> Agency Type </mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.agency_type}} </mat-cell>
	 </ng-container>
	<ng-container matColumnDef="agency_name">
	   <mat-header-cell *matHeaderCellDef> Agency Name </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.agency_name}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="downloadReports">
	   <mat-header-cell *matHeaderCellDef>Download Reports </mat-header-cell>
	   <mat-cell *matCellDef="let element"> 
		   <!-- <button mat-button (click)="downloadCampaignStatusReport(element)">Download</button>  -->
			<button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" [disabled]="element.showLoader">
				Download&nbsp;
				<i class="fa fa-angle-down" *ngIf="!element.showLoader"></i>
				<i class="fas fa-sync-alt fa-spin" *ngIf="element.showLoader"></i>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item (click)="downloadCallStatusReport(element)">Call Status Report</button>
				<button mat-menu-item (click)="downloadCustomerStatusReport(element)">Customer Status Report</button>
				<button mat-menu-item (click)="downloadCampaignStatusReport(element)">Campaign Status Report</button>
				<button mat-menu-item (click)="downloadDataSheet(element)">Data Sheet</button>
			</mat-menu>
		</mat-cell>
	</ng-container>
	<mat-header-row *matHeaderRowDef=displayedColumnsCampaigan></mat-header-row>
	<mat-row *matRowDef="let row; columns: displayedColumnsCampaigan"></mat-row>
 </mat-table>
 <mat-paginator #campaignpaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>