<br><br>
      <!--start Gignex-->
      <div class="animation-One">
        <p class="text-center paraAdjust">
            <span class="t2">
                <img src="../assets/image/gignexProductImg.png" alt="Image" style="width: 6em;" />
            </span>
            <br>
            <span class="t4 txtMiddle">
                <h1 class="lbl-ttl" style="font-size: 1.6em;top: 22px;font-weight: 600;">A one-stop solution for Gig Lifecycle Management  </h1> <br><br>
                Gignex is a NexGen technology platform that enables your Gig Workforce ecosystem. It comes with gig workforce lifecycle management from where you can manage your gig workforce from the time you hire to the time you retire them.
            </span>
        </p>
        <br><br>
        <div class="row paraAdjust alignCenter">
            <div class="col-md-2 col-sm-1 col-xs-12"></div>
            <div class="col-md-4 col-sm-5 col-xs-12">
                <img src="../assets/image/gignexImg1.PNG" style="width:100%;" alt="Image"/>
            </div>
            <div class="col-md-4 col-sm-5 col-xs-12">
                <span class="t4"><br>
                    <b class="lbl-ttl">Gig Workforce Through Gignex</b><br><br>
                    You can now drive a very high level of productivity and accountability of a gig workforce through Gignex. It takes onto account the multiple variable that gets played out in the gig ecosystem like that of scale up and down, hourly/daily payment, self-learning, other performance metric and data/agent security elements.
                </span>
            </div>
            <div class="col-md-2 col-sm-1 col-xs-12"></div>
        </div> <br><br>


        <div class="row rowMLRZero paraAdjust alignCenter">
            <div class="col-md-2 col-sm-12 col-xs-12"></div>
            <div class="col-md-5 col-sm-12 col-xs-12 forMobileBpo text-center">
                <img src="../assets/image/comnexMic.png" style="width:250px;" alt="Image"/>
            </div>
            <div class="col-md-5 col-sm-12 col-xs-12" style="padding: 0 50px;">
                <span class="t4">
                    <b class="lbl-ttl"> If you are a BPO or a call centre providing
                        voice/non-voice based services for your client</b><br><br>
                        Gignex can help your agents to work from anyplace through its platform capabilities of cloud-based, mobile-based predictive dialer and auto call distributor

                        This next-generation cloud-based contact centre solution has built-in intelligent dialler and a call distributor. It can enable work from anywhere as a concept and drive your campaign’s success. The dialler and Call Distributor comes with a super App. App can be curated and customized as per the need of the process and also capture customer dispositions. This makes it powerful, effective, accessible and your go-to cloud call centre solution.
                </span>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 forWebBpo">
                <br><br>
                <img src="../assets/image/comnexMic.png" style="width:250px;" alt="Image"/>
            </div>
            <div class="col-md-1 col-sm-12 col-xs-12"></div>
        </div> <br><br>











        <div class="row rowMLRZero paraAdjust" style="background-color: #f5f5f5;padding: 70px 50px;">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <p class="text-center t4 " style="line-height: 35px;">
                    <span>
                        <h2 style="color:#000000">Gignex comes inbuilt with </h2>
                    </span><br>
                    <span> <b class="lbl-ttl" style="font-weight: 500;">Remote Hiring Module </b> <br></span>
                    <span class="txtMiddle"> Covers interview, screening process with process/project relevance and onboarding the selected candidates electronically with basic background check.
                    </span><br><br>
                    <span><b class="lbl-ttl" style="font-weight: 500;">Remote Training module with easy plugin with your LMS </b> <br> </span>
                    <span class="txtMiddle">Easy to use and customisable module covers all the essential element of training: Training, Assessments, Certification, On the job training  and refreshers
                    </span><br><br>
                    <span> <b class="lbl-ttl" style="font-weight: 500;">Remote Process Delivery with real-time dashboard</b> <br></span>
                    <span class="txtMiddle"> Set and run multiple campaigns run them with ease. Drive productivity with real-time dashboard that captures agent’s productivity metrics
                    </span><br><br>
                    <span> <b class="lbl-ttl" style="font-weight: 500;"> Governance and support </b> <br>
                    </span>
                    <span class="txtMiddle"> Empowers supervisors, managers to support and govern agent’s activity on call remotely, through features such as; call barge, call whisper, call merge and transfer </span><br>
                    <br>
                    <span> <b class="lbl-ttl" style="font-weight: 500;">Cloud-based audit module</b>
                        <br></span>
                    <span class="txtMiddle">Quality compliance through customisable quality audit module, monitor agent-customer conversations with a customisable audit framework and enable agents to have access to feedback real-time
                    </span><br>
                    <br>
                    <span> <b class="lbl-ttl" style="font-weight: 500;">Agent time slot scheduler  </b>
                        <br></span>
                    <span class="txtMiddle">Flexibility to schedule basis process and project needs
                    </span><br><br>
                    <span> <b class="lbl-ttl" style="font-weight: 500;">Payment module</b> <br></span>
                    <span class="txtMiddle"> Payment module that auto calculates productivity
                    </span><br><br>
                    <span> <b class="lbl-ttl" style="font-weight: 500;">Agent and Data Security  </b>
                        <br></span>
                    <span class="txtMiddle">Voice and facial biometric authentication and authorisation for agents, number masking for Customer data security
                    </span><br><br>
                </p>
            </div>
        </div>
        <br><br>
        <p class="text-center paraAdjust"> <span class="t2">
                <img src="../assets/image/v-comnexEngageIPic.png" alt="Image" style="width: 2em;" />
            </span>
            <br> <span class="t4 txtMiddle"> <br>
                <b class="lbl-ttl">Gignex </b> <br><br>
                Help businesses, unshackle themselves from the boundaries of geography, language and four walled offices and deliver more with a unified Work from anywhere solution.
            </span>
        </p><br><br>

        <div class="row rowMLRZero paraAdjust alignCenter" style="background-color: #3fb91f;padding: 35px 4px;">
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
            <div class="col-md-5 col-sm-5 col-xs-12">
                <br>
                <img src="../assets/image/gigBg.png" style="width:100%" alt="Image" />
            </div>
            <div class="col-md-5 col-sm-5 col-xs-12">
                <p style="color:#fff;font-size: 0.8em;padding: 0 50px;"><br><br>
                    <span style="font-size:2em">Gignex</span><br><br>
                    It is developed with leveraging years of experience in the gig ecosystem, by our experts with hands on experience in the remote work industry. This power pack platform renders solutions from gig hiring-to-onboarding-to-training-to-remote CX delivery- to-monitoring-to- quality audit module, all in a single unified manner. It  also comes with a bonus of a payment module and work slot features making it a perfect one-stop solution for Gig Workforce Management.
                </p>
            </div>
            <div class="col-md-1 col-sm-1 col-xs-12"></div>
        </div>
        <br><br>

        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <p class="text-center t4 paraAdjust" style="font-size: 0.8em;line-height: 35px;">
                    <span>
                        <h2 style="color:#000000">With Gignex, you can </h2>
                    </span><br>
                    <span class="txtMiddle">
                        <ul style="text-align: initial;display: inline-block;">
                            <li>
                                Enhance Distributed workforce Engagement & Experience
                            </li>
                            <li>
                                Solve scalability issues with Gignex flexibility and cater to high seasonal
                                demands
                            </li>
                            <li>
                                Expand the addressable talent pool, break boundaries of geographies and language
                                save time & cost by investing in a complete agent lifecycle management solution.
                            </li>
                        </ul>
                    </span><br>
                </p>
            </div>
        </div>
    </div>
    <!--end Gignex-->