<app-menu [navigateLink]="currentTitle"></app-menu>
<!-- Call Records dashboard starts -->
<h3 class="titlehead pt-3">My Team Callrecords</h3>
<table class="maintable">
	<tr>
		<td>
			<div class="pDiv" style="margin-top:2%">
				<div class="divprintRt">
				  <div class="row">
					<div class="col-md-12">
					  <span class="recName">
						Agent Calls
					  </span>
					</div>
				  </div>
				  <div class="row pl-4 pt-3">
					<div class="col-md-3">
					  <span>
						<mat-form-field class="wd-100">
						  <input matInput [matDatepicker]="frompicker" [max]="maxDate" placeholder="From" readonly
							(click)="frompicker.open()" (dateChange)="change_date_status('start_date', $event)"
							[(ngModel)]="StartDate">
						  <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
						  <mat-datepicker #frompicker></mat-datepicker>
						</mat-form-field>
					  </span>
					</div>
					<div class="col-md-3"><span>
						<mat-form-field class="wd-100">
						  <input #fromInput matInput [matDatepicker]="topicker" [max]="maxDate" placeholder="To" readonly
							(click)="topicker.open()" (dateChange)="change_date_status('end_date', $event)"
							[(ngModel)]="EndDate">
						  <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
						  <mat-datepicker #topicker></mat-datepicker>
						</mat-form-field>
					  </span>
					</div>
					<div class="col-md-2">
					  <span (click)="refreshData()">
						<button mat-button>Go</button>
					  </span>
					</div>
				  </div>
				  <p>
					<!-- <span class="das-pe">Print</span> -->
					<span class="das-pe" (click)="exportExcel()">Export</span>
					<!-- <span class="das-pe" (click)="refreshCampaignData()">Refresh</span> -->
				  </p>
				</div>
			  </div>
		</td>
	</tr>
	<tr>
		<td>
			<div class="pDiv">
				<app-table-filter [table_data]="dataSource" [filter_columns_obj]="columns_filter"
          (filtered_data)="filtered_data($event)"></app-table-filter>
				<mat-table [dataSource]="dataSource">
					<ng-container matColumnDef="call_id">
					  <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
					  <mat-cell *matCellDef="let element"> {{element.call_id}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="agent_name">
					  <mat-header-cell *matHeaderCellDef> Agent </mat-header-cell>
					  <mat-cell *matCellDef="let element"> {{element.agent_name}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="cust_name">
					  <mat-header-cell *matHeaderCellDef> Customer </mat-header-cell>
					  <mat-cell *matCellDef="let element"> {{ element.cust_name }}
					  </mat-cell>
					</ng-container>
					<ng-container matColumnDef="cust_number">
					  <mat-header-cell *matHeaderCellDef> Number </mat-header-cell>
					  <mat-cell *matCellDef="let element"> {{element.cust_number}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="call_type">
						<mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.call_type}} </mat-cell>
					  </ng-container>
					<ng-container matColumnDef="start_time">
					  <mat-header-cell *matHeaderCellDef> Start </mat-header-cell>
					  <mat-cell *matCellDef="let element" [matTooltip]="element.start_time_full"> {{element.start_time}}
					  </mat-cell>
					</ng-container>
					<ng-container matColumnDef="end_time">
					  <mat-header-cell *matHeaderCellDef> End </mat-header-cell>
					  <mat-cell *matCellDef="let element" [matTooltip]="element.end_time_full"> {{element.end_time}}
					  </mat-cell>
					</ng-container>
					<ng-container matColumnDef="url">
						<mat-header-cell *matHeaderCellDef> Play </mat-header-cell>
						<mat-cell *matCellDef="let element"> 
							<div *ngIf="element.url">
								<mat-icon mat-list-icon class="play_icon" (click)="selectCall(element.url)">play_circle_filled</mat-icon>
							</div>
							<p *ngIf = "!element.url">None</p>
						</mat-cell>
					  </ng-container>
					<mat-header-row *matHeaderRowDef=table_columns_arr></mat-header-row>
					<mat-row *matRowDef="let row; columns: table_columns_arr" [ngClass]="{'highlight_row': current_url && (row.url === current_url)}" (click)="print_row(row)"></mat-row>
				  </mat-table>
				  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
			</div>
		</td>
	</tr>
</table>
<div class="audio_player" *ngIf="current_url">
	<app-audio-player [source_url]="current_url"></app-audio-player>
</div>
<!-- Call Records dashboard ends -->