import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orche-content',
  templateUrl: './orche-content.component.html',
  styleUrls: ['./orche-content.component.scss']
})
export class OrcheContentComponent implements OnInit {
  _Details=['phone','Name','Lead Source','Company','Industry','Create New'];
  selectedItem :any;
  selectedCopyItem:any=[];
  selectedForRemoveItem:any;
  constructor() { }

  ngOnInit(): void {
  }
 

  onClick(item) {
    //debugger;
    this.selectedItem = item; 

  }
  sendToRight(){
    if(this.selectedItem!=null){
    this.selectedCopyItem.push(this.selectedItem);
    this.selectedItem =null;
    }
  }
  onRemoveClick(item){
    //debugger;
this.selectedForRemoveItem=item;
  }
  removeFromRight(){
   // debugger;
    if(this.selectedForRemoveItem!=null){
    var index = this.selectedCopyItem.indexOf(this.selectedForRemoveItem);
if (index !== -1) {
  this.selectedCopyItem.splice(index, 1);
}
    }
  }
}
