import { Component, OnInit,ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import {Router} from '@angular/router';
import { ApiService } from '../api.service';
import { Utils } from '../utils';
import * as moment from 'moment';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.scss']
})
export class BatchListComponent implements OnInit {

   displayedColumns = ['id', 'start_date', 'end_date', 'trainer_name','capacity','duration','status','edit'];
  dataSource:any = new MatTableDataSource();
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  isEditBatch:boolean=false;
  api_end_point:any;
  constructor(private dialog: MatDialog,public router:Router,private API: ApiService,private _snackBar: MatSnackBar,private myService:MyserviceService) { 
    }
  ngOnInit() {
    this.getbatchListData();
	this.api_end_point = this.myService.get_API_End_Point();
   }
 
  getbatchListData(){
    let _today:any=new Date();
    let _startDate = moment(_today).format('yyyy-MM-DD');
   let _endDate=moment( new Date(_today.setDate(_today.getDate() + 30))).format('yyyy-MM-DD');

    this.API.getBatchList(_startDate,_endDate,"Regular",this.api_end_point).subscribe((data:any)=>{
      let _response=data.success;
      if(_response){
        let _filterData=(_response || []).filter(v=> v.status!= "CANCELLED");
       // let _filterData=(_response.filter)(v=> v.status!= "CANCELLED");
        let ELEMENT_DATA=_filterData;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = function(data:any, filter: any): boolean {
          var found = false;
          if(data.id && data.id.toString().toLowerCase().includes(filter)){
            found = true
          }
          if(data.trainer_name && data.trainer_name.toString().toLowerCase().includes(filter)){
            found = true
          }
          return found;
        };
     
      }
    });
  }
  applyFilter(filterValue: string) {
    //console.log(filterValue)
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  createNewBatch(){
    this.API.clearCreateforBtn();
    this.router.navigateByUrl('/training/create_batch');
    
  }
  elementEDIT:any=[];
	openEditBatch(element:any) {
		if(element.status == 'COMPLETED'){
			this._snackBar.open("Batch Completed can't edit", "close");
			return;
		}
  this.elementEDIT=element;
   this.API.editBatchBind(element);
    this.router.navigateByUrl('/training/create_batch');
  }
  batchRowRecord(element){
    this.API.sessionTraingbatchID(element);
    this.router.navigateByUrl('/training/batch_training');
  }
}

