import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {MyserviceService} from "../../myservice.service";
import { Title, Meta } from '@angular/platform-browser';
import { Routes, RouterModule, Router,ActivatedRoute, Params  } from '@angular/router';

@Component({
  selector: 'app-contiinex-sandbox-ui',
  templateUrl: './contiinex-sandbox-ui.component.html',
  styleUrls: ['./contiinex-sandbox-ui.component.scss']
})
export class ContiinexSandboxUiComponent implements OnInit {
  selectedButtonToggleVal: string = 'apiTab';
  myTitle = 'Contiinex | API Sandbox';
  sandBoxUrl:any="https://apidocs.contiinex.com";
  urlSandBox: SafeResourceUrl;
  currentLoader:boolean=false;

  api_end_point:any;
	currentTitle = 'Contiinex-sandbox';
  currentUrl:any;
  constructor(private metaTagService: Meta,private titleService:Title,private _service:MyserviceService,public sanitizer: DomSanitizer,private router: Router) { }

  ngOnInit() {
    this.api_end_point = this._service.get_API_End_Point();
    this.currentUrl = this.router.url;

    this.titleService.setTitle(this.myTitle);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Test Environment to exercise the Contiinex APIs'}
    );
    this.metaTagService.updateTag(
      { property: 'og:description', content: 'Test Environment to exercise the Contiinex APIs'}
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: 'Test Environment to exercise the Contiinex APIs'}
    );

    if (this._service &&
      this._service.get_loggedin_user() &&
      (this._service.get_loggedin_user().businessType == 'WABA') && this._service.get_loggedin_user().agentid) {
      this.currentLoader=true;
      this.urlSandBox= this.sanitizer.bypassSecurityTrustResourceUrl(this.sandBoxUrl);
      setTimeout(()=>{
        this.currentLoader=false;
      },3000)
    }
  }
  onToggleButtonValChange(val:any){
      if(val=="apiTab"){

      }
    }
}
