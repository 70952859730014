import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router, ActivatedRoute } from '@angular/router';
import { MatInput } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Chart } from 'angular-highcharts';

import { ServicesService } from '../../dashboard/services.service';
import { MyserviceService } from '../../myservice.service';
import { Utils } from '../../utils';
import { ApiService } from '../../api.service';
import { AgentActivityComponent } from '../agent-activity/agent-activity.component';
import { SEND_EMAIL_BYJSON } from '../../interface';
import {MatSnackBar} from '@angular/material/snack-bar';

interface Alert {
	type: string;
	message: string;
}


@Component({
	selector: 'app-agent-dash',
	templateUrl: './agent-dash.component.html',
	styleUrls: ['./agent-dash.component.scss']
})
export class AgentDashComponent implements OnInit {

	messageAlert: any = "";
	showHide_messageAlert: boolean = false;
	timer_pulse_rate = 15;
	currentTitle = 'sharekhan';
	maxDate = moment().subtract(1, 'day').toISOString();
	currentLoader = false;
	selectedTabSts = 'call_status';
	alert: Alert = { type: 'success', message: "Refreshed successfully..." };
	alertClosed = true;
	slotStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	isToday: boolean = true;

	myDetails = this.myService.getMyDetails();
	myTeamDetails = this.myService.getOnlyMyTeamIds();

	settings: any = ""
	workHours: any = ""
	dashData: any[] = [];
	selectAll = true;
	color_arr: any = {};
	followupCnt: number = 0;
	followupCntDone: number = 0;
	displayedColumnsCallSummary = [
		'type',
		// 'time',
		'login_time',
		'spoken_time',
		'wrap_time',
		'wait_time',
		'idle_time',
		'break_time',
		'unavailable_time'
	];

	displayedColumnsCallStatus = [
		'user_id',
		'name',
		"action",
		'live_status',
		'status',
		'dialled',
		'unique_dialled',
		'contacted',
		'unique_contacted',
		'rnr',
		'forms',
		'login_time_hms',
		// 'active_time_hms',
		"unavailable_time_hms",
		'idle_time_hms',
		'wait_time_hms',
		'spoken_time_hms',
		'wrap_time_hms',
		'break_time_hms',
		'aht',
		'audit_score',
		'ctc_score',
		'more'
	];
	displayedColumnsCallSlot = [
		'date',
		'time',
		"capacity",
		'booked',
		'waiting',
		'occupancy',
		'status',
		'options',
		'id'
	];
	_summary = function () {
		return {
			'data': {
				'total': 0,
				'total_perc': 0,
				'total_calls': 0,
				'total_calls_perc': 0,
				'data_to_call': 0,
				'data_to_call_perc': 0,
				'available': 0,
				'followup': 0
			},
			'dialled': {
				'total': 0,
				'total_perc': 0,
				'unique': 0,
				'unique_perc': 0,
			},
			'contacted': {
				'total': 0,
				'total_perc': 0,
				'unique': 0,
				'unique_perc': 0
			},
			'forms': {
				'total': 0
			}
		} as any
	}
	_tableDataCallStatus = function () {
		return {
			'user_id': '',
			'name': '',
			'status': '',
			'dialled': 0,
			'unique_dialled': 0,
			'contacted': 0,
			'unique_contacted': 0,
			'rnr': 0,
			'forms': 0,
			'audit_score': 0,
			'ctc_score': 0
		}
	}
	_tableDataCallSlot = function () {
		return {
			'date': "",
			'time': "",
			"capacity": 0,
			'booked': 0,
			'waiting': 0,
			'occupancy': 0,
			'status': "",
			'options': "",
			'id': ""
		}
	}

	agentCallingStatus: any = {
		"login": 0,
		"active": 0,
		"break": 0,
		"on_call": 0,
		"logout": 0,
		"bio_break": 0,
		"lunch_break": 0,
		"coffe_break": 0,
		"team_break": 0,
		"eod_break": 0
	}

	agentStatusChart: any;
	agentStatusChartData: any = {
		calling: 0,
		calling_perc: "0",
		notclick: 0,
		notclick_perc: 0,
		waitinglt10: 0,
		waitinglt10_perc: 0,
		waitinggt10: 0,
		waitinggt10_perc: 0,
		notcalling: 0,
		notcalling_perc: 0,
		loggedout: 0,
		loggedout_perc: 0,
	};
	dashSummary: any = {
		followup: 0,
		followupCntDone: 0,
		followupdone: 0,
		followup_perc: 0,
		followupdone_perc: 0,
		followuptodo: 0,
		followuptodo_perc: 0,
		forms_filled: 0,
		forms_filled_perc: 0,
		forms_not_filled: 0,
		forms_not_filled_perc: 0,
		calls_dialled: 0,
		calls_dialled_perc: 0,
		calls_rnr: 0,
		calls_rnr_perc: 0,
		calls_contacted: 0,
		calls_contacted_perc: 0
	};
	callSummaryData: any[] = [{
		'type': 'Total',
		'time': "00:00:00",
		'spoken_time': "00:00:00",
		'wrap_time': '00:00:00',
		'wait_time': '00:00:00',
		'idle_time': '00:00:00',
		'break_time': '00:00:00',
		'unavailable_time': '00:00:00',
		'login_time': '00:00:00'
	},
	{
		'type': 'Average of 0 Agents',
		'time': "00:00:00",
		'spoken_time': "00:00:00",
		'wrap_time': '00:00:00',
		'wait_time': '00:00:00',
		'idle_time': '00:00:00',

	}]

	selectedAgents: any[] = []
	selectedAgentsLen: number = 0;
	myUser: any;
	summary = this._summary();
	final_table_data = [];
	dataSource: any = new MatTableDataSource();
	dataSourceCallSummary: any = new MatTableDataSource();
	api_end_point:any;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort) tableSort: MatSort;

	@ViewChild(' fromInput', {
		read: MatInput
	}) fromInput: MatInput;

	constructor(
		private dashService: ServicesService,
		private myService: MyserviceService,
		private dialog: MatDialog,
		public router: Router,
		private activateRoute: ActivatedRoute,
		private API: ApiService,
		private utils: Utils,private _snackBar: MatSnackBar) { }


	ngOnInit() {
		// console.log(this.callStartDate, this.callEndDate)
		// this.getCallStatus();
		// this.preparePieChart();

		this.settings = this.myService.get_stage_settings();
		this.workHours = this.settings.workhours;


		this.myUser = this.myService.get_loggedin_user();
		this.refreshData();
		this.api_end_point = this.myService.get_API_End_Point();
	}

	/* ngAfterViewInit() {
		this.dataSource.sort = this.tableSort;
		console.log('after view init');
		this.dataSource.sortingDataAccessor = (item, property) => { 
			console.log('mat sort success', item, property);
			return item[property];
		}
	} */

	prepareCallSummary() {
		// console.log('prepare call summary', this.callSummaryData);
		this.dataSourceCallSummary = new MatTableDataSource(this.callSummaryData);

	}

	convertToPulseRate(agent_obj) {
		let aht = 0;
		if (agent_obj.active_time && !isNaN(agent_obj.active_time)) {
			aht = parseInt(agent_obj.active_time);
			return Math.round(aht / this.timer_pulse_rate);
		}
		return 0;
	}

	refreshDatafortoday() {
		this.messageAlert = "";
		this.isToday = true;
		this.callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
		this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
		this.refreshData();
	}

	refreshData(isHistory?: boolean) {
		if (!this.callStartDate || !this.callEndDate) {
			return;
		}
		// console.log('Table data refreshing');
		this.currentLoader = true;
		let objReq: any = {};
		var not_submit: number = 0, calling: number = 0, wait_l_10: number = 0, wait_g_10: number = 0, on_break: number = 0, log_out: number = 0;
		var my_agents = [];
		var now = moment();
		objReq.start_date = this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		objReq.end_date = this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
		objReq.agents_list = [];

		this.API.getFullTeamData(this.api_end_point).subscribe(
			(res: any) => {
				if (res.success) {
					this.myService.set_Full_team(res.success);

					if (this.selectedAgents.length == 0) {
						var p = this.myService.getAllIds(null, null, true);
						for (const item of p) {
							let agent = { name: item.name, user_id: item.id, checked: true };
							this.selectedAgents.push(agent);
						}
						objReq.agents_list = objReq.agents_list.concat(_.keys(_.groupBy(p,'id')));
						this.selectedAgentsLen = this.selectedAgents.length;
						// console.log('agents list p', p);
						// console.log('selected agents', this.selectedAgents);
						// console.log('req agents', objReq.agents_list);
					} else {
						objReq.agents_list = objReq.agents_list.concat(this.selectedAgents.filter(e => e.checked === true).map(f => f.user_id));
						this.selectedAgentsLen = objReq.agents_list.length;
					}

					my_agents = objReq.agents_list;
					objReq.user_group_id = this.myUser.groupid;
					// console.log('agents list', JSON.stringify(objReq.agents_list))
					if (isHistory) {
						objReq.isHistory = true;
					}
					this.dashService.getPMDashDataOutbound(objReq,this.api_end_point).then((resolve) => {
						this.currentLoader = false;
						this.API.getAllGroupStatus(this.myUser.groupid,this.api_end_point).subscribe(
							(success: any) => {
								if (success && success.length) {
									for (var i in success) {
										var obj = success[i];
										if (my_agents.includes(obj.id)) {
											let user_id = obj['id'];
											if (!this.color_arr.hasOwnProperty(user_id)) {
												this.color_arr[user_id] = {};
											}
											this.color_arr[user_id]['color'] = '#DEE1E6'
											if (obj.app_state && (obj.app_state.indexOf('On Call') > -1)) {
												this.color_arr[user_id]['last_activity'] = "On Call";
												if (obj.hasOwnProperty('Last Activity At')) {
													this.color_arr[user_id]['last_activity_time'] = obj['Last Activity At'];
												}
												if (obj.hasOwnProperty('Last Customer Disconnect At') && (obj['Last Customer Disconnect At'].length > 1)) {
													not_submit++;
													this.color_arr[user_id]['color'] = '#FF1493'
												} else {
													this.color_arr[user_id]['color'] = '#33EB1A'
													calling++;
												}
											}
											if (obj.app_state && (obj.app_state.indexOf('Waiting for call') > -1)) {
												try {
													var rad_start_at = obj['RAD Started At'];
													var time_diff = moment.duration(now.diff(moment(rad_start_at, 'hh:mm:ss A'))).as('minutes');
													//console.log('printing time difference',time_diff);
													if (!isNaN(time_diff)) {
														if (time_diff < 10) {
															wait_l_10++;
															this.color_arr[user_id]['color'] = '#FF8C00'
														} else {
															wait_g_10++;
															this.color_arr[user_id]['color'] = '#87CEFA'
														}
													} else {
														throw "not a number"
													}
												} catch (e) {
													// console.log('Exception received', e);
													this.color_arr[user_id]['color'] = '#87CEFA'
													wait_g_10++;
												}
											}
											if (obj.app_state && (obj.app_state.indexOf('On Break') > -1)) {
												this.color_arr[user_id]['color'] = '#FF0000';
												// this.color_arr[user_id]['last_activity'] = "On Break";
												// if (obj.hasOwnProperty('Last Activity At')) {
												// 	this.color_arr[user_id]['last_activity_time'] = obj['Last Activity At'];
												// }
												on_break++;
											}
											if (obj.app_state && (obj.app_state.indexOf('Logged In') > -1)) {
												this.color_arr[user_id]['color'] = '#FF0000'
												on_break++;
											}
											if (obj.app_state && (obj.app_state.indexOf('Logged Out') > -1)) {
												this.color_arr[user_id]['color'] = '#D3BA80'
												log_out++;
											}
										}

									}

								}
								this.prepareTableData(objReq);
								/* this.agentCallingStatus.calling = calling;
								this.agentCallingStatus.on_break = on_break;
								this.agentCallingStatus.not_submit = not_submit;
								this.agentCallingStatus.wait_l_10 = wait_l_10;
								this.agentCallingStatus.wait_g_10 = wait_g_10;
								this.agentCallingStatus.log_out = log_out;
								this.agentCallingStatus.remaining = this.dashData.length - (
									this.check_number(this.agentCallingStatus.calling) +
									this.check_number(this.agentCallingStatus.not_submit) +
									this.check_number(this.agentCallingStatus.wait_l_10) +
									this.check_number(this.agentCallingStatus.wait_g_10) +
									this.check_number(this.agentCallingStatus.on_break) +
									this.check_number(this.agentCallingStatus.log_out)
								) */
							}, (err) => {
								// console.log(err);
								this.prepareTableData(objReq);
								/* this.agentCallingStatus.calling = calling;
								this.agentCallingStatus.on_break = on_break;
								this.agentCallingStatus.not_submit = not_submit;
								this.agentCallingStatus.wait_l_10 = wait_l_10;
								this.agentCallingStatus.wait_g_10 = wait_g_10;
								this.agentCallingStatus.log_out = log_out;
								this.agentCallingStatus.remaining = this.dashData.length - (
									this.check_number(this.agentCallingStatus.calling) +
									this.check_number(this.agentCallingStatus.not_submit) +
									this.check_number(this.agentCallingStatus.wait_l_10) +
									this.check_number(this.agentCallingStatus.wait_g_10) +
									this.check_number(this.agentCallingStatus.on_break) +
									this.check_number(this.agentCallingStatus.log_out)
								) */
								this.currentLoader = false;
							});
						// this.prepareCallSummary();
					}, (err) => {
						alert(err);
						this.currentLoader = false;
					})


				} else {
					alert(res.error)
				}
			}, (err) => {
				alert('Error in the full team fetch')
			});
	}

	prepareAgentStatusSummary(arr) {
		var obj = {
			"login": 0,
			"active": 0,
			"break": 0,
			"on_call": 0,
			"logout": 0,
			"bio_break": 0,
			"lunch_break": 0,
			"coffe_break": 0,
			"team_break": 0,
			"eod_break": 0
		}
		var p = _.groupBy(arr, 'last_activity');
		for (var i in p) {
			let agents_number = p[i].length;
			switch (i) {
				case 'Active':
					obj['active'] += agents_number;
					obj['login'] += agents_number
					break;
				case 'Break':
					obj['break'] = agents_number;
					obj['login'] += agents_number;
					// console.log('Breaks', p[i]);
					var b = _.groupBy(p[i], 'sub_disposition');
					for (var k in b) {
						var sub_agents = b[k].length;
						switch (k) {
							case 'Bio Break':
								obj.bio_break += sub_agents;
								break;
							case 'Lunch Break':
								obj.lunch_break += sub_agents;
								break;
							case 'Coffee Break':
								obj.coffe_break += sub_agents;
								break;
							case 'Team briefing':
								obj.team_break += sub_agents;
								break;
							case 'End of Day Work':
								obj.eod_break += sub_agents;
								break;
						}
					}
					break;
				case 'Logout':
					obj['logout'] = agents_number;
					obj['login'] += agents_number;
					break;
				case 'Login':
					obj['login'] += agents_number;
					break;
				case 'On Call':
					obj['active'] += agents_number;
					obj['on_call'] += agents_number;
					obj['login'] += agents_number;
					break;
				default:
					break;
			}
		}
		this.agentCallingStatus = obj;
		// console.log('printing agent status summary', this.agentCallingStatus);
	}

	check_number(val) {
		if (!isNaN(val)) {
			return parseInt(val)
		} else {
			return 0;
		}
	}

	agent_params(id, obj) {
		var dashRow = {} as any;
		dashRow.isVisible = true;
		dashRow.user_id = id;
		dashRow.name = (obj && obj.name) ? obj.name : '';
		dashRow.email = (obj && obj.name) ? obj.email : '';
		dashRow.phone = (obj && obj.phone) ? obj.phone : '';
		dashRow.role = (obj && obj.role) ? obj.role : '';
		dashRow.agentid = (obj && obj.agentid) ? obj.agentid : '';
		dashRow.active_campaign = (obj && obj.active_campaign) ? obj.active_campaign : '';
		dashRow.tpd_queues = (obj && obj.tpd_queues) ? obj.tpd_queues : '';
		dashRow.idle_time = (obj && obj.idle_time) ? obj.idle_time : 0;
		dashRow.wait_time = (obj && obj.wait_time) ? obj.wait_time : 0;
		dashRow.wrap_time = (obj && obj.wrap_time) ? obj.wrap_time : 0;
		dashRow.active_time = (obj && obj.active_time) ? obj.active_time : 0;
		dashRow.spoken_time = (obj && obj.spoken_time) ? obj.spoken_time : 0;
		// dashRow.color = check_color(obj.last_call);
		dashRow.dialled = (obj && obj.dialled) ? obj.dialled : 0;
		dashRow.contacted = (obj && obj.contacted) ? obj.contacted : 0;
		dashRow.unique_dialled = (obj && obj.unique_dialled) ? obj.unique_dialled : 0;
		dashRow.unique_connected = (obj && obj.unique_connected) ? obj.unique_connected : 0;
		dashRow.forms_filled = (obj && obj.forms_filled) ? obj.forms_filled : 0;
		dashRow.audited_calls = (obj && obj.audited_calls) ? obj.audited_calls : 0;
		dashRow.avg_score = (obj && obj.avg_score) ? obj.avg_score : 0;
		dashRow.ctc_calls = (obj && obj.ctc_calls) ? obj.ctc_calls : 0;
		dashRow.ctc_score = (obj && obj.ctc_score) ? obj.ctc_score : 0;
		return dashRow;
	}

	summarizeData(arr) {
		let obj = {};

		var add_keys = ['followup', 'dialled', 'contacted', 'unique_dialled', 'call_rnr', 'unique_connected', 'forms_filled', 'idle_time', 'wait_time', 'wrap_time', 'active_time', 'spoken_time', 'unavailable_time', 'break_time', 'login_time', 'audited_calls', 'avg_score', 'ctc_calls', 'ctc_score'];
		for (var i in arr) {
			var loc_obj = arr[i];
			for (var k in loc_obj) {
				if (add_keys.indexOf(k) > -1) {
					if (obj.hasOwnProperty(k)) {
						obj[k] += parseInt(loc_obj[k])
					} else {
						obj[k] = parseInt(loc_obj[k])
					}
				}
			}
		}
		// console.log('printing obj', obj);
		// console.log(obj['idle_time'])
		// console.log(this.gethmsformat(obj['idle_time'], 'clock'))
		this.dashSummary = { ...this.dashSummary, ...obj };
		// this.dashSummary.followup = this.followupCnt;
		// this.dashSummary.followupdone = this.followupCntDone;
		// this.dashSummary.followup_perc = this.calculatePercBy(this.dashSummary.followup, this.summary.data.total);
		// this.dashSummary.followupdone_perc = this.calculatePercBy(this.dashSummary.followupdone, this.summary.data.total);
		this.dashSummary.forms_filled_perc = this.calculatePercBy(this.dashSummary.forms_filled, this.summary.contacted.total);
		this.dashSummary.forms_not_filled = (this.summary.contacted.unique > this.dashSummary.forms_filled) ? this.summary.contacted.unique - this.dashSummary.forms_filled : 0;
		this.dashSummary.forms_not_filled_perc = this.calculatePercBy(this.dashSummary.forms_not_filled, this.summary.contacted.total);

		this.callSummaryData = [{
			'type': 'Total',
			'time': "" + this.gethmsformat(this.dashSummary.active_time, 'clock'),
			'spoken_time': "" + this.gethmsformat(this.dashSummary.spoken_time, 'clock'),
			'wrap_time': "" + this.gethmsformat(this.dashSummary.wrap_time, 'clock'),
			'wait_time': "" + this.gethmsformat(this.dashSummary.wait_time, 'clock'),
			'idle_time': "" + this.gethmsformat(this.dashSummary.idle_time, 'clock'),
			'break_time': "" + this.gethmsformat(this.dashSummary.break_time, 'clock'),
			'unavailable_time': "" + this.gethmsformat(this.dashSummary.unavailable_time, 'clock'),
			'login_time': "" + this.gethmsformat(this.dashSummary.login_time, 'clock'),
		},
		{
			'type': "" + `Average of ${this.dashData.length} Agents`,
			'time': "" + this.gethmsformat(this.getDivision(this.dashSummary.active_time, this.dashData.length, undefined, undefined), 'clock'),
			'spoken_time': this.gethmsformat(this.getDivision(this.dashSummary.spoken_time, this.dashData.length, undefined, undefined), 'clock'),
			'wrap_time': "" + this.gethmsformat(this.getDivision(this.dashSummary.wrap_time, this.dashData.length, undefined, undefined), 'clock'),
			'wait_time': "" + this.gethmsformat(this.getDivision(this.dashSummary.wait_time, this.dashData.length, undefined, undefined), 'clock'),
			'idle_time': "" + this.gethmsformat(this.getDivision(this.dashSummary.idle_time, this.dashData.length, undefined, undefined), 'clock'),
			'break_time': "" + this.gethmsformat(this.getDivision(this.dashSummary.break_time, this.dashData.length, undefined, undefined), 'clock'),
			'unavailable_time': "" + this.gethmsformat(this.getDivision(this.dashSummary.unavailable_time, this.dashData.length, undefined, undefined), 'clock'),
			'login_time': "" + this.gethmsformat(this.getDivision(this.dashSummary.login_time, this.dashData.length, undefined, undefined), 'clock'),
		}];


		// this.dataSourceCallSummary = new MatTableDataSource(this.callSummaryData);
		this.prepareCallSummary();
		// this.preparePieChart();

	}

	getDivision(num, denm, delim: any = undefined, type: any = undefined) {
		if (!num || !denm) {
			return 0;
		}
		if (!denm || (denm == 0)) {
			return 0;
		} else {
			let val = Math.abs(num / denm);
			if (type == 'percentage') {
				return Math.abs(num * 100 / denm).toFixed(0)
			} else {
				if ((delim != undefined) && (delim > -1) && (!isNaN(delim))) {
					return val.toFixed(parseInt(delim));
				} else {
					return val.toFixed(1);
				}
			}
		}
	}

	gethmsformat(sec, mode) {
		if (!sec) {
			return 0;
		}
		var hrs, min, seconds;
		hrs = sec / 3600;
		var temp = sec % 3600;
		min = temp / 60;
		seconds = temp % 60;
		function getDigit(digit) {
			if (!digit) {
				return '00'
			} else {
				digit = Math.floor(digit);
				if (digit > 9) {
					return digit;
				} else {
					return ('0' + digit);
				}
			}
		}
		switch (mode) {
			case 'clock':

				return getDigit(hrs) + ' : ' + getDigit(min) + ' : ' + getDigit(seconds);
				break;
			default:
				return Math.round(hrs) + 'hrs ' + Math.round(min) + 'min ' + Math.round(seconds) + 'sec';
				break;
		}
	}

	prepareTableData(objReq: any) {
		this.currentLoader = true;
		this.summary = this._summary();
		let data = null;
		let arr = [];

		this.dashService.pm_dash_data.subscribe((d) => data = d);
		this.dashData = [];
		if (data.hasOwnProperty('success')) {
			let d = data['success'];

			for (var i in d) {
				let agent_data = this.agent_params(i, d[i]);
				this.dashData.push(agent_data)
				let loc = d[i];
				// console.log('printing loc', loc);
				let obj = this._tableDataCallStatus();
				obj = { ...obj, ...agent_data };
				// console.log('after spread', obj);
				obj['user_id'] = i;
				obj['name'] = loc['name'];
				obj['dialled'] = loc['dialled'] || 0;
				obj['unique_dialled'] = loc['unique_dialled'] || 0;
				obj['rnr'] = loc['rnr'] || 0;
				if (!isNaN(loc['contacted'])) {
					obj['contacted'] = loc['contacted'];
					// this.summary.contacted.total += obj['contacted'];
				}
				if (!isNaN(loc['unique_connected'])) {
					obj['unique_contacted'] = loc['unique_connected'];
					this.summary.contacted.total += obj['unique_contacted'];
					this.summary.contacted.unique += obj['unique_contacted'];
				}
				if (!isNaN(loc['forms_filled'])) {
					obj['forms'] = loc['forms_filled'];
					this.summary.forms.total += obj['forms'];
				}
				obj['audit_score'] = loc['avg_score'];
				obj['ctc_score'] = loc['ctc_score'];
				/* obj['active_time_hms'] = this.gethmsformat(loc['active_time'], 'clock');
				obj['wait_time_hms'] = this.gethmsformat(loc['wait_time'], 'clock');
				obj['spoken_time_hms'] = this.gethmsformat(loc['spoken_time'], 'clock');
				obj['wrap_time_hms'] = this.gethmsformat(loc['wrap_time'], 'clock');
				obj['idle_time_hms'] = this.gethmsformat(loc['idle_time'], 'clock'); */
				//   obj['status_color'] = this.getStatusColor(obj);
				// console.log('arr obj', obj);
				arr.push(obj);
				// this.dashData.push(this.agent_params(i,obj));
			}
		}

		// if (this.summary.data.total == 0) {
		// 	this.summary.data.total = this.summary.data.data_to_call + this.summary.dialled.total;
		// }
		// this.summary.data.total = this.summary.dialled.unique + this.summary.data.data_to_call;
		this.followupCnt = 0;
		this.followupCntDone = 0;
		this.API.getGenericCustomerData(objReq,this.api_end_point)
			.subscribe(
				(res: any) => {
					if (res.errors) {

					} else {
						if (res.success) {
							this.summary.data.total = res.success.active.total_assigned + res.success.inactive.total_assigned;
							this.summary.contacted.total = res.success.active.total_contacted_calls + res.success.inactive.total_contacted_calls;
							this.summary.contacted.unique = res.success.active.data_contacted + res.success.inactive.data_contacted;
							this.summary.dialled.total = res.success.active.data_dialled + res.success.inactive.data_dialled;
							this.summary.dialled.unique = res.success.active.data_dialled + res.success.inactive.data_dialled;
							this.summary.data.data_to_call = this.summary.data.total - this.summary.dialled.total;
							this.summary.data.total_calls = res.success.active.total_calls + res.success.inactive.total_calls;
							var total_calls = this.summary.data.total;
							if (total_calls > 0) {
								this.summary.data.total_perc = '100.0';
							} else {
								this.summary.data.total_perc = '0.0'
							}
							this.summary.data.data_to_call_perc = this.calculatePercBy(this.summary.data.data_to_call, total_calls);
							this.summary.dialled.total_perc = this.calculatePercBy(this.summary.dialled.total, total_calls);
							this.summary.dialled.unique_perc = this.calculatePercBy(this.summary.dialled.unique, total_calls);

							this.summary.contacted.total_perc = this.calculatePercBy(this.summary.contacted.total, this.summary.data.total_calls);
							this.summary.contacted.unique_perc = this.calculatePercBy(this.summary.contacted.unique, total_calls);
							this.followupCnt = res.success.active.total_followups + res.success.inactive.total_followups;
							this.followupCntDone = res.success.active.total_followups_completed + res.success.inactive.total_followups_completed;
							this.dashSummary.followup = this.followupCnt;
							this.dashSummary.followupdone = this.followupCntDone;
							this.dashSummary.followuptodo = this.followupCnt - this.followupCntDone;
							this.dashSummary.followup_perc = this.calculatePercBy(this.dashSummary.followup, this.summary.data.total);
							this.dashSummary.followupdone_perc = this.calculatePercBy(this.dashSummary.followupdone, this.dashSummary.followup);
							this.dashSummary.followuptodo_perc = this.calculatePercBy(this.dashSummary.followuptodo, this.dashSummary.followup);
						}
					}
					/* Calling common timer values through API */
					// console.log('objReq before trigger', objReq);
					let timer_count_obj:any = {
						"start_date": objReq.start_date,
						"end_date": objReq.end_date,
						"users_list": objReq.agents_list
					}
					if (objReq.isHistory) {
						timer_count_obj.isHistory = objReq.isHistory
					}
					this.API.getGenericTimersData(timer_count_obj,this.api_end_point).subscribe((res: any) => {
						if (res.success) {
							// console.log('printing success', res.success);
							// console.log('printing arr', arr);
							for (var i in res.success) {
								var agent_index = _.findLastIndex(arr, { user_id: i });
								var act_status = this.getActivityStatus(res.success[i]);
								// console.log('data', res.success[i]);
								// console.log('printing act status', act_status);
								if (agent_index > -1) {
									arr[agent_index]['wrap_time'] = res.success[i]['activity']['wrap_time'];
									// arr[agent_index]['wait_time'] = (res.success[i]['activity']['call_time'] - res.success[i]['activity']['spoken_time']);
									arr[agent_index]['wait_time'] = res.success[i]['activity']['wait_time'];
									arr[agent_index]['spoken_time'] = res.success[i]['activity']['spoken_time'];
									arr[agent_index]['unavailable_time'] = res.success[i]['activity']['unavailable_time'];
									arr[agent_index]['break_time'] = res.success[i]['activity']['break_time'];
									arr[agent_index]['idle_time'] = res.success[i]['activity']['idle_time'];
									/* if (res.success[i]['activity']['call_gap_time']) {
										arr[agent_index]['idle_time'] = (res.success[i]['activity']['call_gap_time'] - res.success[i]['activity']['break_time']);
										if (arr[agent_index]['idle_time'] < 0) {
											arr[agent_index]['idle_time'] *= -1;
										}
									} */
									// arr[agent_index]['login_time'] = arr[agent_index]['wrap_time'] + arr[agent_index]['wait_time'] + arr[agent_index]['spoken_time'] + arr[agent_index]['break_time'] + arr[agent_index]['idle_time'] + arr[agent_index]['unavailable_time'];
									arr[agent_index]['login_time'] = res.success[i]['activity']['login_time'];
									arr[agent_index]['login_time_hms'] = this.gethmsformat(arr[agent_index]['login_time'], 'clock');
									arr[agent_index]['idle_time_hms'] = this.gethmsformat(arr[agent_index]['idle_time'], 'clock');
									arr[agent_index]['wrap_time_hms'] = this.gethmsformat(arr[agent_index]['wrap_time'], 'clock');
									arr[agent_index]['wait_time_hms'] = this.gethmsformat(arr[agent_index]['wait_time'], 'clock');
									arr[agent_index]['spoken_time_hms'] = this.gethmsformat(arr[agent_index]['spoken_time'], 'clock');
									arr[agent_index]['unavailable_time_hms'] = this.gethmsformat(arr[agent_index]['unavailable_time'], 'clock');
									arr[agent_index]['break_time_hms'] = this.gethmsformat(arr[agent_index]['break_time'], 'clock');
									arr[agent_index]['aht'] = (arr[agent_index]['contacted']) ? Math.floor((arr[agent_index]['spoken_time'] + arr[agent_index]['wrap_time']) / arr[agent_index]['contacted']) : 0;
									// arr[agent_index]['current_status'] = this.getActivityStatus(res.success[i]);
									arr[agent_index]['raw_data'] = res.success[i];
									if (act_status) {
										arr[agent_index]['last_activity'] = act_status.last_activity;
										arr[agent_index]['last_activity_at'] = act_status.last_activity_at;
										arr[agent_index]['duration_now'] = Math.floor(this.utils.convertSectoMin(act_status.duration_now));
										arr[agent_index]['sub_disposition'] = act_status.sub_disposition;
										arr[agent_index]['activity_color'] = act_status.color;
									}
									// console.log('act_status', act_status);
								} else {
									console.log('agent not found', agent_index);
								}
							}
						}
						// console.log('table data final', arr);
						this.summarizeData(arr);
						this.prepareAgentStatusSummary(arr);
						this.final_table_data = arr;
						this.dataSource = new MatTableDataSource(arr);
						this.dataSource.sort = this.tableSort;
						this.dataSource.paginator = this.paginator;
						this.dataSource.filterPredicate = function (data, filter: any): boolean {
							return data.name.toString().toLowerCase().includes(filter) || data.user_id.toString().toLowerCase().includes(filter)
						};
						/* Sorting Accessor starts */
						this.dataSource.sortingDataAccessor = (item, property) => {
							// console.log('sorting', property, item);
							switch (property) {
								case 'live_status':
									return item['duration_now'];
									break;
								case 'status':
									return item['last_activity'];
									break;
								case 'login_time_hms':
									return item['login_time'];
									break;
								case "unavailable_time_hms":
									return item['unavailable_time'];
									break;
								case 'idle_time_hms':
									return item['idle_time'];
									break;
								case 'wait_time_hms':
									return item['wait_time'];
									break;
								case 'spoken_time_hms':
									return item['spoken_time'];
									break;
								case 'wrap_time_hms':
									return item['wait_time'];
									break;
								case 'break_time_hms':
									return item['break_time'];
									break;
								default:
									return item[property];
									break;
							}
						};
						/* Soriting Accessor Ends */
						this.currentLoader = false;
						let post_obj: any = { 'start_date': objReq.start_date, 'end_date': objReq.end_date }
						if (this.myDetails.role == 'non-user') {
							let myTeam = this.myTeamDetails;
							console.log('my team non-user details', myTeam);
							if (myTeam && myTeam.length) {
								post_obj.agents_list = [];
								for (var i in myTeam) {
									if (myTeam[i] && (myTeam[i]['role'] == 'user')) {
										post_obj.agents_list.push(myTeam[i]['id'])
									}
								}
							}
						}
						this.API.genericCallDataDetails(post_obj,this.api_end_point).subscribe((resp: any) => {
							if (resp.success) {
								let call_response = resp.success;
								this.dashSummary.calls_dialled = call_response['calls']['dialled'];
								this.dashSummary.calls_contacted_perc = this.calculatePercBy(this.dashSummary.calls_dialled, this.dashSummary.calls_dialled);
								this.dashSummary.calls_rnr = call_response['calls']['rnr'];
								this.dashSummary.calls_rnr_perc = this.calculatePercBy(this.dashSummary.calls_rnr, this.dashSummary.calls_dialled);
								this.dashSummary.calls_contacted = call_response['calls']['contacted'];
								this.dashSummary.calls_contacted_perc = this.calculatePercBy(this.dashSummary.calls_contacted, this.dashSummary.calls_dialled);
							}
						})
					}, (err) => {

					})
				}, (err) => {
					console.log(err)
				}
			)

	}

	getCallStatus() {
		this.refreshData();
	}

	/* getSlotStatus() {
		this.refreshDataCallSlot()
	} */
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}

	/* addSlot() {
		const dialogRef = this.dialog.open(PmdashboardDailogComponent, {
			width: '70%',
			disableClose: true,
			data: '',
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed', result);
			if (result.refresh) {
				this.refreshDataCallSlot()
			}
		});
	} 
	editSlot(element: any) {
		const dialogRef = this.dialog.open(PmdashboardDailogComponent, {
			width: '50%',
			disableClose: true,
			data: element
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed', result);
			if (result.refresh) {
				this.refreshDataCallSlot()
			}
		});
	}*/
	changeTogleStatus(value) {
		this.selectedTabSts = value;
		this.getCallStatus();
	}

	getCallStatusObj(obj) {
		// console.log('printing call status obj', obj);
		let loc_obj = {
			"color": "Inherit",
			"text": "",
			"time": "",
			"duration": 0
		}
		if (this.color_arr[obj.user_id]) {
			if (this.color_arr[obj.user_id]['last_activity']) {
				loc_obj.text = this.color_arr[obj.user_id]['last_activity'];
			}
			if (this.color_arr[obj.user_id]['last_activity_time']) {
				loc_obj.time = this.color_arr[obj.user_id]['last_activity_time'];
				let p = this.utils.getDuration(moment().format('hh:mm:ss a'), loc_obj.time, 'hh:mm:ss a', 'second');
				if (p) {
					loc_obj.duration = Math.floor(p / 60);
				}
			}
		}
		return loc_obj;
	}

	getStatusColor(obj) {
		// console.log('color', obj, this.color_arr);
		// console.log('selected color', obj, this.color_arr);
		if (!obj.user_id) {
			return '#DEE1E6';
		}
		let user_id = obj.user_id;
		if (typeof user_id != 'string') {
			user_id = user_id.toString();
		}
		if (!this.color_arr.hasOwnProperty(user_id)) {
			return '#DEE1E6';
			// console.log('userid found in the status color', typeof user_id, this.color_arr[user_id])
		}
		return this.color_arr[obj.user_id]['color'];
	}

	change_date_call_status(key: string, event: MatDatepickerInputEvent<Date>) {
		this.isToday = false;
		if (key == 'start_date') {
			this.callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			/* if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			} */
		} else {
			this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this.messageAlert = "Start date should be less than end date *";
				this.showHide_messageAlert = true;
			} else {
				this.showHide_messageAlert = false;
			}

		}
	}
	change_date(key: string, event: MatDatepickerInputEvent<Date>) {

		if (key == 'start_date') {
			this.slotStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.slotStartDate).diff(moment(this.slotEndDate)) >= 0) {
				this.slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
				// alert("Start date should be less than end date");
				return;
			}
		} else {
			this.slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.slotStartDate).diff(moment(this.slotEndDate)) >= 0) {
				this.slotEndDate = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this.messageAlert = "Start date should be less than end date *";
				this.showHide_messageAlert = true;
				return;
			} else {
				this.showHide_messageAlert = false;
			}

		}
	}

	preparePieChart() {
		let chartdata = new Chart({
			title: {
				text: ''
			},
			credits: {
				enabled: false
			},
			plotOptions: {
				pie: {
					cursor: 'pointer',
					dataLabels: {
						distance: -30,
						enabled: true,
						color: 'white',
						style: {
							fontWeight: '500',
						}
					},
					showInLegend: false,
					borderWidth: 5,
				},
			},

			series: [
				{
					states: {
						inactive: {
							opacity: 1
						},
						hover: {
							enabled: false
						}
					},
					name: 'Active',
					data: [

						{
							name: "" + this.dashSummary.forms_filled_perc,
							y: Number(this.dashSummary.forms_filled_perc), color: '#e40f59'
						}, {
							name: "" + this.dashSummary.forms_not_filled_perc,
							y: Number(this.dashSummary.forms_not_filled_perc), color: '#1b7ec7'
						},
					],
					type: 'pie',

					innerSize: '40%',
				}]
		});

		this.agentStatusChart = chartdata;
	}
	calculatePercBy(num1, num2) {
		if (num1 == 0 || num2 == 0) {
			return "0.0";
		}
		var value: any = (num1 / num2) * 100;
		value = value.toFixed(1);
		return value
	}
	selectChildren() {
		if (this.selectedAgents.every(a => a.checked)) {
			this.selectAll = true;
		} else {
			this.selectAll = false;
		}
		this.selectedAgentsLen = this.selectedAgents.filter(e => e.checked === true).length;
	}

	updateCheck() {
		if (this.selectAll === true) {
			this.selectedAgents.map((agent) => {
				agent.checked = true;
			});
			this.selectedAgentsLen = this.selectedAgents.length;
		} else {
			this.selectedAgents.map((agent) => {
				agent.checked = false;
			});
			this.selectedAgentsLen = 0;
		}
	}

	downloadCallStatusReport() {
		var obj: any = {};
		obj.start_date = this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
		if (this.selectedAgents.length == 0) {
			for (const item of this.myService.getAllIds('user')) {
				let agent = { name: item.name, user_id: item.id, checked: true };
				this.selectedAgents.push(agent);
			}
			obj.agents_list = this.myService.getAllIds('user', 'id');
		} else {
			obj.agents_list = this.selectedAgents.filter(e => e.checked === true).map(f => f.user_id);
		}
		this.API.downloadSharekhanCallReport(obj,this.api_end_point).subscribe(
			(res: any) => {
				// debugger;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
						() => {
							// campaign_data.showLoader = false;
						}
					)
				} else {
					alert(res.errors)
				}
			}, (err) => {
				// debugger;
			}
		)
	}

	downloadReportGeneral() {
		var current_date = new Date();
		/*end_date = new Date().toISOString();*/
		var obj: any = {};
		obj.start_date = this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		obj.download = true;
		// obj.offset = current_date.getTimezoneOffset() * -1;
		obj.incsales = 1;
		obj.userset = 'Team';
		if (this.selectedAgents.length == 0) {
			for (const item of this.myService.getAllIds('user')) {
				let agent = { name: item.name, user_id: item.id, checked: true };
				this.selectedAgents.push(agent);
			}
			obj.agents_list = this.myService.getAllIds('user', 'id');
		} else {
			obj.agents_list = this.selectedAgents.filter(e => e.checked === true).map(f => f.user_id);
		}
		this.API.downloadCallStatusOutboundReport(obj,null,this.api_end_point).subscribe(
			(res: any) => {
				// debugger;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
						() => {
							// campaign_data.showLoader = false;
						}
					)
				} else {
					alert(res.errors)
				}
			}, (err) => {
				// debugger;
			}
		)
	}

	downloadAuditReport() {
		this.API.downloadAuditOutboundReport(this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'), this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss'),this.api_end_point).subscribe(
			(res: any) => {
				// debugger;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
						() => {
							// campaign_data.showLoader = false;
						}
					)
				} else {
					alert(res.errors)
				}
			}, (err) => {
				// debugger;
			}
		)
	}

	agentSummaryReport() {
		// console.log('printing agent summary report');
		let table_data = this.dataSource.data;
		// console.log('printing standard columns', table_data);
		let columns = [
			{ "key": "name", "title": "Agent Name" },
			{ "key": "email", "title": "Agent Email" },
			{ "key": "dialled", "title": "Dialled" },
			{ "key": "unique_dialled", "title": "Unique Dialled" },
			{ "key": "contacted", "title": "Contacted" },
			{ "key": "unique_contacted", "title": "Unique Contacted" },
			{ "key": "rnr", "title": "RNR" },
			{ "key": "forms", "title": "Forms" },
			{ "key": "login_time_hms", "title": "Login Hours" },
			{ "key": "unavailable_time_hms", "title": "Unavailable Time" },
			{ "key": "idle_time_hms", "title": "Idle Time" },
			{ "key": "wait_time_hms", "title": "Dial Time" },
			{ "key": "spoken_time_hms", "title": "Talk Time" },
			{ "key": "wrap_time_hms", "title": "Wrap Time" },
			{ "key": "break_time_hms", "title": "Break Time" },
			{ "key": "aht", "title": "AHT" }
		]
		if (table_data && table_data.length) {
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet('Agent Summary');
			let api_arr = [];
			api_arr.push(_.pluck(columns, 'title'))
			worksheet.addRow(_.pluck(columns, 'title'));
			table_data.forEach(element => {
				let arr = [];
				columns.forEach(column => {
					if (element.hasOwnProperty(column.key)) {
						switch (column.key) {
							case 'active_time':
							case 'idle_time':
							case 'wait_time':
							case 'wrap_time':
							case 'spoken_time':
								element[column.key] = this.gethmsformat(element[column.key], 'clock')
								break;
						}
						arr.push(element[column.key])
					} else {
						arr.push('');
					}
				})
				api_arr.push(arr);
				worksheet.addRow(arr);
			});
			workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, 'AgentSummaryReport.xlsx');
				if (api_arr.length) {
					let api_obj: SEND_EMAIL_BYJSON = {
						"title": "AgentSummaryReport.xlsx",
						"data_arr": api_arr
					}
					this.API.sendJSONBYEmail(api_obj,this.api_end_point).subscribe()
				}
			})
		}
	}
	openFeedback() {
		// alert('click open new tabl');
		this.router.navigate(
			['./feedback'],
			{
				relativeTo: this.activateRoute.parent,
				queryParams: {
					start_date: this.callStartDate,
					end_date: this.callEndDate
				}
			});
	}

	openActivity() {
		this.router.navigate(
			['./agent_activity'],
			{
				relativeTo: this.activateRoute.parent,
				/* queryParams: {
					start_date: this.callStartDate,
					end_date: this.callEndDate
				} */
			}
		)
	}

	openDetailActivity(row) {
		// console.log('printing row', row);
		var s = moment(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss').format('ll');
		var e = moment(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss').format('ll');
		var obj = {
			"agent_id": row.user_id,
			"agent_name": row.name,
			"agent_email": row.email,
			"login_time": (row.raw_data.activity.first_login) ? this.utils.utcToLocal(row.raw_data.activity.first_login, 'YYYY-MM-DDTHH:mm:ss', 'LTS') : '',
			"logout_time": (row.raw_data.activity.last_activity && (row.raw_data.activity.last_activity.activity_type == 'logout')) ? this.utils.utcToLocal(row.raw_data.activity.first_login, 'YYYY-MM-DDTHH:mm:ss', 'll') : '',
			"active_hrs": this.gethmsformat(row.raw_data.activity.not_break_time, 'clock'),
			"break_hrs": row.break_time_hms,
			"data": row.raw_data.activity.data,
			"selectedDate": (s === e) ? s : (s + " - " + e),
			"first_act": row.raw_data.activity.first_activity,
			"last_act": row.raw_data.activity.last_activity
		}
		// console.log('obj prepared', obj);
		var dialogRef = this.dialog.open(AgentActivityComponent, {
			width: '60%',
			height:'90%',
			disableClose: true,
			data: obj
		});
	}

	exportAgentActivity() {
		let table_data = this.dataSource.data;
		if (!table_data || !table_data.length) {
			alert('No data available');
			return;
		}
		// console.log('table data', table_data);
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet('Activity Report');
		let api_arr = [];
		let header_arr = ['Agent Name', 'Agent Email', 'Type', 'Activity Date', 'Start Time', 'End Time', 'Duration(sec)', 'Reason'];
		api_arr.push(header_arr);
		worksheet.addRow(header_arr);
		for (var i in table_data) {
			let act_data = table_data[i]['raw_data']['activity']['data'];
			if (act_data && act_data.length) {
				for (var k in act_data) {
					let arr = [];
					arr[0] = table_data[i]['name'] ? table_data[i]['name'] : '';
					arr[1] = table_data[i]['email'] ? table_data[i]['email'] : '';
					arr[2] = act_data[k]['type'];
					arr[3] = this.utils.utcToLocal(act_data[k]['start'], 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD');
					arr[4] = this.utils.utcToLocal(act_data[k]['start'], 'YYYY-MM-DDTHH:mm:ss', 'HH:mm:ss');
					arr[5] = this.utils.utcToLocal(act_data[k]['end'], 'YYYY-MM-DDTHH:mm:ss', 'HH:mm:ss');
					arr[6] = act_data[k]['duration'];
					arr[7] = act_data[k]['reason'];
					api_arr.push(arr);
					worksheet.addRow(arr);
				}
			}
		}
		workbook.xlsx.writeBuffer().then((buff_data) => {
			let blob = new Blob([buff_data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, 'Activity_Report.xlsx');
			if (api_arr.length) {
				let api_obj: SEND_EMAIL_BYJSON = {
					"title": "Activity_Report.xlsx",
					"data_arr": api_arr
				}
				this.API.sendJSONBYEmail(api_obj,this.api_end_point).subscribe()
			}
		})
	}

	getActivityStatus(row) {
		// console.log('Activity status row', row);
		let obj: any = {
			"last_activity": "",
			"last_activity_at": "",
			"duration_now": 0,
			"sub_disposition": "",
			"color": "Inherit"
		};
		try {
			if (row &&
				row.last_call_activity &&
				row.last_call_activity.event_date &&
				row.activity && row.activity.last_activity && 
				row.activity.last_activity.event_date) {
				// if (moment(row.activity.last_activity.event_date).isSameOrAfter(row.last_call_activity.event_date)) {
				if (row.activity.last_activity.duration_now < row.last_call_activity.duration_now) {
					// App status table event is latest
					obj.last_activity_at = this.utils.utcToLocal(row.activity.last_activity.event_date, 'YYYY-MM-DDTHH:mm:ss', 'LTS')
					obj.last_activity = this.myService.renameStatusMessage(row.activity.last_activity.activity_type.trim());
					obj.color = this.myService.getStatusColour(obj.last_activity);
					/* obj.duration_now = Math.floor(this.utils.getDuration(
						this.utils.getUtc('YYYY-MM-DDTHH:mm:ss').toString(),
						row.activity.last_activity.event_date,
						'YYYY-MM-DDTHH:mm:ss',
						'minutes')) */
					obj.duration_now = row.activity.last_activity.duration_now;
					if (obj.duration_now < 0) {
						obj.duration_now = 0;
					}
					if (obj.last_activity == 'Break') {
						var breaks = ['Bio Break', 'Lunch Break', 'Coffee Break', 'Team briefing', 'Team briefing & Quality Feedback', 'End of Day Work', 'Campaign Finished'];
						if (row.activity.last_activity.user_status && breaks.indexOf(row.activity.last_activity.user_status.trim()) > -1) {
							obj.sub_disposition = row.activity.last_activity.user_status.trim();
						}
					}
				} else {
					// Call Status event is latest
					obj.last_activity_at = this.utils.utcToLocal(row.last_call_activity.event_date, 'YYYY-MM-DDTHH:mm:ss', 'LTS')
					obj.last_activity = 'On Call';
					obj.sub_disposition = this.myService.renameCallStatusMessage(row.last_call_activity.activity_type);
					obj.color = this.myService.getStatusColour(obj.last_activity);
					/* obj.duration_now = Math.floor(this.utils.getDuration(
						this.utils.getUtc('YYYY-MM-DDTHH:mm:ss').toString(),
						row.last_call_activity.event_date,
						'YYYY-MM-DDTHH:mm:ss',
						'minutes')); */
					obj.duration_now = row.last_call_activity.duration_now;
					if (obj.duration_now < 0) {
						obj.duration_now = 0;
					}
				}
				// console.log('obj 1', obj);
				return obj;
			} else if (row && row.activity && row.activity.last_activity && row.activity.last_activity.event_date) {
				/* Call activities not found */
				obj.last_activity_at = this.utils.utcToLocal(row.activity.last_activity.event_date, 'YYYY-MM-DDTHH:mm:ss', 'LTS')
				obj.last_activity = this.myService.renameStatusMessage(row.activity.last_activity.activity_type);
				obj.color = this.myService.getStatusColour(obj.last_activity);
				/* obj.duration_now = Math.floor(this.utils.getDuration(
					this.utils.getUtc('YYYY-MM-DDTHH:mm:ss').toString(),
					row.activity.last_activity.event_date,
					'YYYY-MM-DDTHH:mm:ss',
					'minutes')); */
				obj.duration_now = row.activity.last_call_activity.duration_now
				if (obj.duration_now < 0) {
					obj.duration_now = 0
				}
				if (obj.last_activity == 'Break') {
					var breaks = ['Bio Break', 'Lunch Break', 'Coffee Break', 'Team briefing', 'Team briefing & Quality Feedback', 'End of Day Work', 'Campaign Finished'];
					if (row.activity.last_activity.user_status && breaks.indexOf(row.activity.last_activity.user_status.trim()) > -1) {
						obj.sub_disposition = row.activity.last_activity.user_status.trim();
					}
				}
				// console.log('obj 2', obj);
				return obj;
			} else if (row &&
				row.last_call_activity &&
				row.last_call_activity.event_date) {
				/* Only call activities found */
				obj.last_activity_at = this.utils.utcToLocal(row.last_call_activity.event_date, 'YYYY-MM-DDTHH:mm:ss', 'LTS')
				obj.last_activity = 'On Call';
				obj.sub_disposition = this.myService.renameCallStatusMessage(row.last_call_activity.activity_type);
				obj.color = this.myService.getStatusColour(obj.last_activity);
				/* obj.duration_now = Math.floor(this.utils.getDuration(
					this.utils.getUtc('YYYY-MM-DDTHH:mm:ss').toString(),
					row.last_call_activity.event_date,
					'YYYY-MM-DDTHH:mm:ss',
					'minutes')); */
				obj.duration_now = row.last_call_activity.duration_now;
				// console.log('obj 3', obj);
				return obj;
			}
		} catch (e) {
			console.log('Error in last sync time', e);
			// console.log('obj e', obj);
		}
		return obj;
	}

	getCurrentStatus(row) {
		// console.log('prniting current status row', row);
		let p: any = {};
		let activity_sync = null;
		// console.log('last activity time', this.color_arr[row.user_id]);
		if (this.color_arr[row.user_id] && this.color_arr[row.user_id].last_activity_time) {
			activity_sync = this.color_arr[row.user_id]['last_activity_time'];
		}
		if (row && row.raw_data && row.raw_data.activity && row.raw_data.activity.last_activity && row.raw_data.activity.last_activity.activity_type) {
			p.msg = this.myService.renameStatusMessage(row.raw_data.activity.last_activity.activity_type);
			p.duration = Math.floor(this.utils.getDuration(
				this.utils.getUtc('YYYY-MM-DDTHH:mm:ss').toString(),
				row.raw_data.activity.last_activity.event_date,
				'YYYY-MM-DDTHH:mm:ss',
				'minutes'))
			p.colour = this.myService.getStatusColour(p.msg);
			p.sync_time = "";
			if (activity_sync) {
				p.sync_time = activity_sync;
			} else {
				if (moment(row.raw_data.activity.last_activity.event_date).isSame(this.utils.getUtc('YYYY-MM-DDTHH:mm:ss').toString(), 'day')) {
					p.sync_time = this.utils.utcToLocal(row.raw_data.activity.last_activity.event_date, 'YYYY-MM-DDTHH:mm:ss', 'LTS');
				}
			}
		}
		// console.log('p', p);
		return p;
	}

	filterTableDataByActivity(activity: string) {
		//console.log('activity', activity);
		//console.log('printing data', this.dataSource.data);
		let loc_data = JSON.parse(JSON.stringify(this.final_table_data));
		//console.log('printng loc data', loc_data);
		let arr = [];
		loc_data.forEach(element => {
			switch (activity) {
				case 'Active':
				case 'On Call':
					if (element.last_activity == 'On Call' || element.last_activity == 'Active') {
						arr.push(element);
					}
					break;
				case 'Break':
				case 'Login':
				case 'Logout':
					if (element.last_activity == activity) {
						arr.push(element);
					}
					break;
				case 'null':
					if (element.last_activity == '' || (element.last_activity == undefined)){
						arr.push(element);
					}
					break;
				default:
					arr.push(element);
					break;
			}
		});
		// console.log('arr', arr);
		this.dataSource.data = arr;
	}

	resetTableDataByActivityFilter() {
		//console.log('Reset filter');
		this.dataSource.filter = "";
		this.dataSource.data = this.final_table_data;
	}


/* Highlighting the name with break duration color */
	highlight_color(obj) {
		var color = 'Inherit';
		var last_act = obj.last_activity;
		var sub_disp = obj.sub_disposition;
		var dur_now = obj.duration_now;
		switch (last_act) {
			case 'On Call':
			case 'Active':
			case 'Login':
				if (dur_now > 5) {
					color = 'red';
					// console.log('printing highlight active', obj);
				}
				break;
			case 'Break':
				switch (sub_disp) {
					case 'Bio Break':
						if (dur_now > 2) {
							color = 'red';
							// console.log('printing Bio break', obj);
						}
						break;
					case 'Lunch Break':
						if (dur_now > 30) {
							color = 'red';
							// console.log('printing Lunch break', obj);
						}
						break;
					case 'Coffee Break':
						if (dur_now > 15) {
							color = 'red';
							// console.log('printing Coffee break', obj);
						}
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
		return color;
	}
	actionBergewishper(element,typeName: any) {	
		if (typeName == "BI") {
			let obj = {
				"agent_number": element.phone,
				"group_id": this.myUser.groupid,//81580,
				"agent_id": element.user_id,// 83055,
				"add_type": "BARGE"///WHISPER"
			}
			// console.log('printing obj', obj);
			this.API.getBerge(obj,this.api_end_point).subscribe((res: any) => {
				if(res.success){
					//alert(res.alertmessage);
					this._snackBar.open(res.alertmessage,"", {
						panelClass: 'alert-snackbar-class',
						verticalPosition: 'top',
						horizontalPosition: 'center',
						duration: 3000,
					  });
					this.refreshData();
				}
			});
		}
		if (typeName == "BW") {
			let obj = {
				"agent_number":element.phone,// "+919743326277",
				"group_id":this.myUser.groupid,// 81580,
				"agent_id": element.user_id
			}
			this.API.getBergeToWishper(obj,this.api_end_point).subscribe((res: any) => {
				if(res.success){
					//alert(res.alertmessage);
					this._snackBar.open(res.alertmessage,"", {
						panelClass: 'alert-snackbar-class',
						verticalPosition: 'top',
						horizontalPosition: 'center',
						duration: 3000,
					  });
					this.refreshData();
				}
			});
		}
		if (typeName == "WB") {
			let obj = {
				"agent_number":element.phone,
				"group_id":this.myUser.groupid,// 81580,
				"agent_id": element.user_id

			}
			this.API.getWishperToBerge(obj,this.api_end_point).subscribe((res: any) => {
				if(res.success){
					//alert(res.alertmessage);
					this._snackBar.open(res.alertmessage,"", {
						panelClass: 'alert-snackbar-class',
						verticalPosition: 'top',
						horizontalPosition: 'center',
						duration: 3000,
					  });
					this.refreshData();
				}
			});
		}
	}

	getBargeinBtn(element) {
		if ((element.last_activity == 'On Call') && (element.sub_disposition == 'Talking')) {
			return true;
		}
	}
	getBargeToWishperBtn(element) {
		if ((element.last_activity == 'On Call') && (element.sub_disposition == 'Barge')) {
			return true;
		}
	}
	getWishperToBargeBtn(element) {
		if ((element.last_activity == 'On Call') && (element.sub_disposition == 'Whisper')) {
			return true;
		}
	}
}
