<span style="position: relative;
top: -37px;
right: 0px;
float: right;
z-index: 9;
cursor: pointer;">
  <img src="../assets/contiinex/image/closeicon.svg" width="40px" style="position: fixed;" (click)="closeDialog()" /></span>
<div style="position: relative;top: 25px;">

<div style="display: flex;align-items: center;flex-wrap: wrap;">
    <div class="divImgX1"> <img src="../assets/contiinex/image/selectChannelBanner.png" class="mr-3 v-img-side"/></div>
    <div >
        <p class="pHd"><b>Welcome {{customer_name}} </b></p>
        <p class="pHd"><b>Configure Channels and Install App</b></p>
        <p class="pHd2">We have subscribed all of the available channels<br/>If you wish to unsubscribe, you can do it now<br/></p>
        <p class="pHd2">To receive channel alerts, You can scan the QR code and Install the App Now</p>
        <p class="mt-4 vcx" >
            <mat-checkbox class="mr-3 wcc1" [(ngModel)]="waModel">WhatsApp</mat-checkbox>
            <mat-checkbox class="mr-3 wcc1" [(ngModel)]="chatModel">Chat</mat-checkbox>
            <mat-checkbox class="mr-3 wcc1" [(ngModel)]="callModel">Call</mat-checkbox>
            <mat-checkbox class="mr-3 wcc1" [(ngModel)]="textModel">Text Me Back</mat-checkbox>
        </p>
        <p class="mb-0"> <button class="go-Submit" (click)="updateBtn()">CONTINUE</button></p>
        <p> <span class="pSkip" (click)="skipBtn()">Skip</span></p>
    </div>
</div>
<p class="fTitle">*You can always change these settings later. (from Channel Settings Menu)</p>


</div>