
<app-menu [navigateLink]="currentTitle" *ngIf="currentUrl == '/managementTeam'"></app-menu>
<div class="container-fluid main-section-layout" [ngClass]="{'padding-fix': currentUrl != '/managementTeam'}">
    <div class="row">
        <div class="col-md-12">
     <mat-card>

         <div>
                <div class="row">
                    <div class="col-md-5 col-sm-5 col-xs-12"></div>
                    <div class="col-md-4 col-sm-12 col-xs-12 text-right">
                        &nbsp;&nbsp;
                        <button mat-button class="btnAdd" (click)="addForm()">Add Form</button><!--<i class="fa fa-user" aria-hidden="true"></i>-->
                    </div>
                    <!-- <div class="col-md-3 col-sm-12 col-xs-12">
                        <mat-form-field  class="wdh">
                           <input matInput placeholder="Search" (keyup)="applyFilter($event.target.value)"/>
                           <i class="fa fa-search" matSuffix aria-hidden="true"></i>
                        </mat-form-field>
                    </div> -->
                </div> 
            </div>

<br>

<div class="table-responsive">
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="box-shadow: none !important;">

<div >
<ng-container matColumnDef="sno">
  <th mat-header-cell *matHeaderCellDef>S No </th>
  <td mat-cell *matCellDef="let auditdata; let i = index;">{{i+1}} </td>
    </ng-container>

    <ng-container matColumnDef="audit_form_name">
        <th mat-header-cell *matHeaderCellDef class="minWidHdr"> Audit Form Name </th>
        <td mat-cell *matCellDef="let auditdata"> {{auditdata.audit_form_name}} </td>
    </ng-container>

    <ng-container matColumnDef="cx_touch_point">
        <th mat-header-cell *matHeaderCellDef class="minWidHdr">CX Touch Point </th>
        <td mat-cell *matCellDef="let auditdata"> {{auditdata.cx_touch_point}} </td>
    </ng-container>

  <ng-container matColumnDef="normalized_score">
    <th mat-header-cell *matHeaderCellDef class="minWidHdr">Normalized Score </th>
    <td mat-cell *matCellDef="let auditdata"> {{auditdata.normalized_score}} </td>
  </ng-container>

  <ng-container matColumnDef="download">
    <th mat-header-cell *matHeaderCellDef> Form </th>
    <td mat-cell *matCellDef="let element; let i = index;"> <button mat-stroked-button class="download_btn" color="primary" style="color: #fd6c21 !important" (click)="downloadQuestionaire(element,i)" >
        Download&nbsp;
        <!-- <i class="fa fa-angle-down"></i> -->
        <!-- <i class="fas fa-sync-alt fa-spin" *ngIf="element.showLoader"></i> -->
    </button> </td>
    
</ng-container>

    <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef> Options </th>
        <td mat-cell *matCellDef="let auditdata" (click)="editForm(auditdata)">
            <i class="fa fa-edit CR" style="font-size: 18px; color: #fd6c21;"></i>
        </td>
    </ng-container>
</div>
<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>
<mat-paginator [length]="10"
              [pageSize]="5"
              [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
</mat-paginator>
</mat-card></div>
</div>
</div>


