<div class="toolbar1" role="banner">
    <div class="swid">
        <div class="Sleft"><img class="logoOX" alt="Contiinex Logo" title="Contiinex Logo"
                src="../assets/image/contiinexLogoImg.png" (click)="coWeb()"></div>

        <div class="Smain">
            <!--for web menu start-->
            <div class="rowHeader forWebMenuOX">
                <div class="columnHeader" *ngFor="let v of MenuList; let i = index" (click)="setMenuIndex(i,v)">
                    <span [routerLink]="['./']" style="cursor: pointer;" class="menuhover" title="{{v.name}}"
                        [class.activeMenu]="MenuSelectedIndex === i" style="outline: none;">{{v.name}}</span>
                </div>
            </div><br>
            <!--for web menu end-->

            <!------for mobile start-->
            <div class="forMobileMenuOX">
                <span>
                    <button mat-button [matMenuTriggerFor]="menu" style="background: #dd133c !important;">
                        <mat-icon> menu</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" style="z-index: 2;">
                        <div class="menuMobileCs" *ngFor="let v of MenuList; let i = index" (click)="setMenuIndex(i,v)">
                            <span [routerLink]="['./']" class="menuhover" title="{{v.name}}"
                                [class.activeMenu]="MenuSelectedIndex === i" style="outline: none;cursor: pointer;">{{v.name}}</span>
                        </div>
                    </mat-menu>
                </span>
                <span class="btn-signin" title="Login" (click)="LoginBtn()">LOGIN</span>
            </div>
            <!------for mobile end-->
        </div>
        <div class="Sright forWebMenuOX">
            <p><span class="btn-signin" title="Login" (click)="LoginBtn()">LOGIN</span></p>
        </div>
    </div>
</div>
<!---------------------------------------------------------------------------------------------------------------->


<div class="mainDivCO">
    <div class="row topcss animation-One1" id="homeContent">
        <div class="col-md-1 col-sm-1 col-xs-12"></div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <h1 class="textH">
                <!-- End-to-End Process <br>Orchestrator -->
                Customer journey automation
                in a single continuum
            </h1>
            <h3 class="textImg"><img alt="Contiinex" src="../assets/image/contiinex.png"></h3>
            <p class="ptext">
                <!-- Help you in setting up your process of customer engagement through usage of bots and dialer
                in one single continuum. -->
                <!-- Helps you with customer journey automation
in a single continuum -->
             </p>
            <p><span class="view-demo" title="Know More" (click)="openKnowMore()"> KNOW MORE </span></p>
            <br><br>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12">
            <img alt="Banner" class="bnr" src="../assets/image/banner.png">
        </div>
        <div class="col-md-1 col-sm-1 col-xs-12"></div>
    </div>
    <div id="About" class="row aboutContentcs" style="background-image: url('../assets/image/aboutbg.png')">
        <div class="col-md-12 col-sm-12 col-xs-12"><br><br>
            <p class="text-center abt-hd">About</p>
            <p class="text-center"><img alt="Contiinex" src="../assets/image/contiinex-white.PNG" class="continexWhite">
            </p>
            <div class="aboutDesc" id="aboutDescription" style="padding: 15px 15%;">
                <h1 style="padding-bottom: 30px;font-size: 1.5em;font-weight: 600;">Contiinex helps you to orchestrate your end-to-end customer journey with the usage of
                bots and human workforce in one single continuum.</h1>
                With Contiinex you can now manage each of the channels including SMS, WhatsApp, VoiceBot, Chatbot,
                email, and human workforce through dialer, to drive the highest level of automation, process efficiency,
                and reduce the cost of operation substantially, all from a single platform. Contiinex Artificial
                Intelligence engine supports you in recommending how best you can place each of the channels to create
                an awesome customer experience.<br><br>
                Contiinex CXaaS platform is built with the following gears that can be plugged into any third party
                environment or can work seamlessly in harmony with each other to give an exponential customer experience
                for you.
            </div>
        </div>
    </div>
    <br><br>
    <div id="Features">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <p class="text-center pro-con">Contiinex </p>
                <p class="text-center pro-con-p">Products</p><br>
            </div>
        </div>
    </div>
    <br>
    <div class="row" style="position: -webkit-sticky; position: sticky; top: 75px;z-index:100; background-color: white;    padding-bottom: 20px;" >
        <div class="col-md-12 col-sm-12 col-xs-12 text-center flexModify">
            <div class="flex-container">
                <div class="btns1" [class.activeChannel]="selectedIndex === i" *ngFor="let v of NameList; let i = index"
                    (click)="setIndex(i,v)">
                    <p class="productImageCs">
                     <span>  <img [src]="v.imgurl" alt="Image" title="{{v.name}}" /></span> 
                        <span class="logoTextName">{{v.name}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <br><br>
    <div class="row rowMLRZero" style="padding: 25px;" id="productListContent">
        <div class="col-md-12 col-sm-12 col-xs-12 text-left">
            <!--start Comnex-->
            <div *ngIf="showHideComnex" class="animation-One">
                <p class="text-center paraAdjust"> <span class="t2">
                        <img src="../assets/image/comnexProductImg.png" alt="Image" class="comnexProductImg" />
                    </span>
                    <br> <span class="t4 txtMiddle">
                        <h1 class="lbl-ttl" style="font-size: 1.6em;top: 22px;font-weight: 600;">Accelerate your customer’s self-service experience </h1> <br><br>
                        Web Widget and Automated workflow at their best. This combination has unleashed its power to renovate the rapidly changing customer experience industry.<br>
Comnex Widget can be placed on any of your digital assets i.e. Website, an App, LinkedIn page or any of your social pages and can be further automated to form an intuitive communication bridge through multiple channels and call to action
                    </span>
                </p><br><br>
                <!-- <div class="bg-gray alignCenter">
                    <div class="row rowMLRZero paraAdjust">
                        <div class="col-md-2 col-sm-2 col-xs-12"></div>
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <img src="../assets/image/comnexBannerImg1.png" alt="Image" class="comImg1 posAd" />
                        </div>
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <span class="t4">
                                <b class="lbl-ttl"> If you are a D2C e-commerce company
                                    or a marketplace</b><br><br>
                                    Comnex can help you drive faster sales and service for your customers.<br><br>

                                    Comnex comes with an intelligent customizable widget that can be plugged into your websites or any of your Social Media pages and can enable an instant chat/voice Bridge between the customer and your sales/service teams through instant mobile-to-mobile connect.<br><br>

                                    Comnex as a platform can also automate the communication to your customer whether over SMS, Email, WhatsApp, Voice Bot making it a seamless experience for your customer from the time he/she visits your website to the final delivery of service.
                            </span>
                            <br><br>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12"></div>
                    </div>
                </div> -->
                <div class="bg-gray alignCenter">
                    <div class="row rowMLRZero paraAdjust">
                        <div class="col-md-2 col-sm-2 col-xs-2"></div>
                     
                        <div class="col-md-8 col-sm-8 col-xs-8">
                            <div class="text-center">
                                <img src="../assets/image/comnexBannerImg1.png"  alt="Image" class="comImg1 boxImg" />
                            </div>
                            <span class="t4">
                                <b class="lbl-ttl">Sales in a box</b><br><br>
                                    It takes about 20 connects with a prospect for a sales executive to close a deal and untold hours to prepare the right approach for call email or calls.<br><br>

                                    That’s a lot of time spent getting to know your customer and their needs, writing notes, logging data, and following up. And as your prospect and customer list grow, the management becomes more challenging.<br><br>
                                    
                                    At Contiinex we have developed a solution that can help you save a lot of time and manage effectual sales operations across the industries. <br><br>
                                    
                                    Introducing Comnex, customer journey automation for sales. Digital sales automation drives engagement for all the cold leads through Comnex's efficient process flows paired with intelligent Bots, until the cold leads advance to warm.<br><br>
                                    
                                    You can now achieve your sales outcomes by focusing less on operational challenges and inheriting Comnex sales automation with a perfect blend of technology with human workforce. <br><br>
                                    
                                    Sales in a box bundle comes with <br>
                                    <ul>
                                        <li> Cold leads engagement through Comnex omnichannel (WhatsApp, email, AI bots, SMS)</li>
                                        <li> Mobile friendly interface for sales agent</li>
                                        <li> Smart routing for all the leads that are advanced to warm gets directly connected to sales agent </li>
                                        <li>Predictive dialler for agents with a ratio up to 1:8, resulting in exponential increase of the connect rate and conversion percentage  </li>

                                    </ul>
                            </span>
                            <br><br>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-2"></div>
                    </div>
                </div>
                <br><br>

             

                <!-- <div class="bg-gray1" style="padding: 45px;">
                    <div class="row rowMLRZero paraAdjust alignCenter">
                        <div class="col-md-2 col-sm-2 col-xs-12"></div>
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <img src="../assets/image/measure.png" alt="Image" style="width: 100%;" />
                        </div>
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <span class="t4">
                                <b class="lbl-ttl">If you are a digital marketing company</b><br><br>
                                    Comnex can now help you measure your marketing performance parameters by giving you a real-time update on each lead generated through all marketing channels. Comnex can channelise each lead from a centralised lead management system to the sales person of your organisation over their mobile and help connect your sales team with your potential customers.
                            </span>
                            <br><br>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12"></div>
                    </div>
                </div> -->
                <div class="bg-gray1" style="padding: 45px;">
                    <div class="row rowMLRZero paraAdjust alignCenter">
                        <div class="col-md-2 col-sm-2 col-xs-2"></div>
                     
                        <div class="col-md-8 col-sm-8 col-xs-12">
                            <div class="text-center">
                                <img src="../assets/image/measure.png" class="boxImg"  alt="Image" />
                            </div>
                            <span class="t4">
                                <b class="lbl-ttl">Service in a box</b><br><br>
                                  
                                AI has been transforming the new age customer service, making it more convenient and personalised by automating quality resolutions to consumers’ and empowering human workforce to work smarter.<br><br>

                                At Contiinex, We believe that customer success is as important and strategic as sales in the process of building a business. <br><br>
                                
                                Comnex Service in a box provides responses instantly to customers, quicker than any human reducing the first response time to bare minimum. These instant response are available all the time, making time zones irrelevant in the first few interactions with customer service. Human workforce engagement  isn’t required for every scenario, and a chatbot operating 24 hours a day will make an enormous impact on those tenuous initial steps.<br><br>
                                
                                Our conversational AI is not just answers to the customer's questions but can  facilitate routine actions and raise tickets for customer complaints. Making the experience seamless for the customer and delivering first contact resolution without the need for customer to agent connection.<br><br>
                                
                                Service-in-a-box bundle comes with:<br>
                                <ul>
                                    <li> Omnichannel customer experience - WhatsApp, SMS, email, IVR BOT, calls</li>
                                    <li>First contact resolution by intelligent virtual assistant  </li>
                                    <li>Proactive outreach to the customers through customer satisfaction survey and NPS </li>
                                    <li>Built in Automatic Call distributor to ensure the when the customer calls it connects with the available agent in a split second </li>
                                    <li>AI Powered engine empowers Bot to take the right action and provide solution </li>
                                </ul>
                                
                            </span>
                            <br><br>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-2"></div>
                    </div>
                </div>
<br><br>

                <p class="text-center paraAdjust"> <span class="t2">
                        <img src="../assets/image/ComnixLogo.png" alt="Image" style="width: 2em;" />
                    </span>
                    <br> <span class="t4 txtMiddle">
                        <b class="lbl-ttl">Intelligent Customizable Widget </b> <br><br>
                        Comnex comes with an intelligent customizable widget that can be plugged into your websites or any of your Social Media pages and can enable an instant chat/voice Bridge between the customer and your sales/service teams through instant mobile-to-mobile connect.
                    </span>
                </p><br><br>
                <br><br>
                <div class="row rowMLRZero paraAdjust revodiv alignCenter">
                    <div class="col-md-2 col-sm-1 col-xs-12"></div>
                    <div class="col-md-4 col-sm-5 col-xs-12">
                        <br>
                        <p class="revo"> <img src="../assets/image/comImg.png" alt="Image" style="width:100%;" /></p>
                    </div>
                    <div class="col-md-4 col-sm-5 col-xs-12" style="padding:0 45px;">
                        <span class="t4" style="color: #ececec;"><br>
                            <b class="lbl-ttl" style="color: #fff;"> A Revolutionary Button
                                On Your Digital Real-Estate </b><br><br>
                            Comnex strategize omni channel communication with AI and Automation. Defining metrics,
                            building end-to-end workflows and effortlessly managing them, cannot get easier than this.
                        </span><br><br>
                    </div>
                    <div class="col-md-2 col-sm-1 col-xs-12"></div>
                </div> <br><br><br>
                <div class="row paraAdjust alignCenter">
                    <div class="col-md-2 col-sm-12 col-xs-12"></div>
                    <div class="col-md-4 col-sm-12 col-xs-12">
                        <img src="../assets/image/comnexBanner1.png" alt="Image" class="comImg2" />
                    </div>
                    <div class="col-md-4 col-sm-12 col-xs-12">
                        <span class="t4">
                            <b class="lbl-ttl"> Process Flow Builder </b><br><br>
                            <!-- Comnex as a platform can also automate the communication to your customer whether over SMS, Email, WhatsApp, Voice Bot making it a seamless experience for your customer from the time he/she visits your website to the final delivery of service. -->
                            Comnex allows you the flexibility to decide the messaging tool you would want to use whether Voice IVR, WhatsApp, SMS, or Email to auto-communicate with your customers at each stage of your delivery process.<br> Comnex gets integrated into your panel or your CRM and can help you create a seamless experience for your customers through the usage of intelligent bots
                        </span>
                    </div>
                    <div class="col-md-2 col-sm-12 col-xs-12"></div>
                </div> <br><br>




                <!-- <div class="row rowMLRZero bg-gray paraAdjust">
                    <div class="col-md-2 col-sm-12 col-xs-12"></div>
                    <div class="col-md-4 col-sm-12 col-xs-12 forMobileBpo">
                        <img src="../assets/image/cbc.png" style="width:100%;" />
                    </div>
                    <div class="col-md-5 col-sm-12 col-xs-12">
                        <span class="t4">
                            <b class="lbl-ttl">Cloud-based Contact centre solution</b><br><br>
                            This next-generation cloud-based contact centre solution has built -in intelligent dialler and a call distributor. It can enable work from anywhere as a concept and drive your campaign’s success. The dialler and Call Distributor comes with a super App. App can be curated and customized as per the need of the process and also capture customer dispositions. This makes it powerful, effective, accessible and your go-to cloud call centre solution.
                        </span>
                    </div>
                    <div class="col-md-4 col-sm-12 col-xs-12 forWebBpo">
                        <img src="../assets/image/cbc.png" style="width:100%;" />
                    </div>
                    <div class="col-md-2 col-sm-12 col-xs-12"></div>
                </div> <br><br> -->

                            <p class="text-center">
                                <span class="t4">
                                    <b class="lbl-ttl" style="font-size: 2.3em;"> Comnex</b>&nbsp;&nbsp; <b class="lbl-ttl" style="color:#fd6c21">Comes with</b>
                                </span>
                            </p>
                            <br>
                            <div class="vk-container">
                                <div>Cloud Communication</div>
                                <div>Process Flow Builder </div>
                              </div>
                              <div class="vk-container">
                                <div>Chat Bot & Voice Bot</div>
                                <div>Realtime Dashboard & Analytics</div>
                              </div>

                              <p class="text-center paraAdjust">
                            <br> <span class="t4 txtMiddle">

It is purely built on a pay-as-you-go model with no upfront cost. You don’t have to pay any Capex for in-house server setup whatsoever!

                            </span>
                        </p>
            </div>
            <!--end Comnex-->


            <!--start AInex-->
            <div *ngIf="showHideAInex" class="animation-One">
                <p class="text-center paraAdjust"> <span class="t2">
                        <!-- Ainex -->
                        <img src="../assets/image/ainexProductImg.png" alt="Image" style="width: 5em;" />
                    </span>
                    <br>
                    <span class="t4 txtMiddle">
                        <h1 class="lbl-ttl" style="font-size: 1.6em;top: 22px;font-weight: 600;">Speech And Video Analytics </h1> <br><br>
                        With the Contiinex AI powered Speech-to-Text engine, you can now transcribe practically all the languages with an accuracy rate of up to 96% from voice recordings on a real-time basis. The Speech engine comes with the capabilities of working both inside your firewall and online.<br>
                        Experience customer conversation with detail and draw valuable insights to the business. Save your time and cost with AiNex.
                    </span>
                </p>

                <br><br>
                <div class="row paraAdjust alignCenter">
                    <div class="col-md-1 col-sm-1 col-xs-12"></div>
                    <div class="col-md-5 col-sm-5 col-xs-12">
                        <img src="../assets/image/AInexImg1.png" alt="Image" style="width:100%;" />
                    </div>
                    <div class="col-md-5 col-sm-5 col-xs-12">
                        <span class="t4">
                            <b class="lbl-ttl"> Speech-to-text transcription</b><br><br>
                            Unlike the other engines, Ainex Speech-to-text transcribes your data only for your process and does not cross leverage your data for other processes. With deep Speech-To-Text-Speech capabilities, Contiinex has helped customers not just to transcribe their agent-client voice recording but have advanced to identifying keywords and basis this it has the intelligence to auto-trigger multiple events ranging from sending an auto-quotation or raising an auto-service ticket.<br><br>

                            Contiinex Speech-To-Text engine is platform agnostic that you can plug and play into your existing platform. It gives the true meaning to ‘Voice of Customer’, so you can take action basis it!
                        </span>
                    </div>
                    <div class="col-md-1 col-sm-1 col-xs-12"></div>
                </div> <br><br>
                <div class="AinexCsForWeb">
                    <div class="row paraAdjust alignCenter">
                        <div class="col-md-1 col-sm-1 col-xs-12"></div>
                        <div class="col-md-5 col-sm-5 col-xs-12">
                            <span class="t4">
                                <b  class="lbl-ttl">AI based biometric solution with Ainex </b><br><br>
                                Ainex with its Biometric Technology caters to user authorisation and authentication using voice and video analysis. Ainex Biometric intelligence allows you to identify and verify your callers in a jiffy. Ainex uses voice patterns and specific, distinctive facial details to produce unique identification for every individual. It’s Built to increase security, save your time and cost.
  </span>
                            <br><br>
                        </div>
                        <div class="col-md-5 col-sm-5 col-xs-12">
                            <img src="../assets/image/AinexImg2.png" style="width:100%;" alt="Image" />
                        </div>
                        <div class="col-md-1 col-sm-1 col-xs-12"></div>
                    </div>
                </div>
                <div class="row paraAdjust alignCenter AinexCsForMobile">
                    <div class="col-md-1 col-sm-1 col-xs-12"></div>
                    <div class="col-md-5 col-sm-5 col-xs-12">
                        <img src="../assets/image/AinexImg2.png" style="width:100%;" alt="Image" />
                    </div>
                    <div class="col-md-5 col-sm-5 col-xs-12">
                        <span class="t4">
                            <b  class="lbl-ttl">AI based biometric solution with Ainex </b><br><br>
                            Ainex with its Biometric Technology caters to user authorisation and authentication using voice and video analysis. Ainex Biometric intelligence allows you to identify and verify your callers in a jiffy. Ainex uses voice patterns and specific, distinctive facial details to produce unique identification for every individual. It’s Built to increase security, save your time and cost.

                        </span>
                        <br><br>
                    </div>
                    <div class="col-md-1 col-sm-1 col-xs-12"></div>
                </div>
                <div class="row rowMLRZero paraAdjust" style="background-color: #f5f5f5;padding: 70px 0px;">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <p class="text-center t4" style="line-height: 35px;padding: 50px;">
                            <span>
                                <h2 style="color:#000000">Ainex comes with</h2>
                            </span><br>
                            <span> <b class="lbl-ttl" style="font-weight: 500;"> Speech-To-Text-Speech transcription
                                </b> <br></span>
                            <span class="txtMiddle"> It’s a plug and play module to convert live or recoded conversation</span><br><br>
                            <span><b class="lbl-ttl" style="font-weight: 500;"> Voice and Vision signature
                                    authentication and authorisation </b> <br> </span>
                            <span class="txtMiddle">Register, authenticate and verify the caller, speaker or an agent </span><br><br>
                            <span> <b class="lbl-ttl" style="font-weight: 500;"> Automated Audit for call centre </b>
                                <br></span>
                            <span class="txtMiddle"> Delivers speed, accuracy and efficacy to the process </span><br><br>
                            <span> <b class="lbl-ttl" style="font-weight: 500;"> Dictation Assistive Technology </b>
                                <br> </span>
                            <span class="txtMiddle"> Convert your spoken words to text. Super power to write fast! </span>
                        </p>
                    </div>
                </div>

                <br><br>
                <div class="row paraAdjust">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <p class="text-center t4" style="font-size: 0.8em;line-height: 35px;">
                            <span>
                                <h2 style="color:#000000">With AiNex, you will </h2>
                            </span><br>
                            <span>
                                <ul style="text-align: initial;display: inline-block;">
                                    <li>
                                        Deliver great customer experience
                                    </li>
                                    <li>
                                        Make conversations insightful and relevant, improve self-serve option for
                                        customers
                                    </li>
                                    <li>
                                        Save time of human workforce for more critical tasks
                                    </li>
                                    <li>
                                        Cut down on operational cost
                                    </li>
                                </ul>
                            </span><br>
                        </p>
                    </div>
                </div>

            </div>
            <!--end AInex-->

            <!--start Gignex-->
            <div *ngIf="showHideGignex" class="animation-One">
                <p class="text-center paraAdjust">
                    <span class="t2">
                        <img src="../assets/image/gignexProductImg.png" alt="Image" style="width: 6em;" />
                    </span>
                    <br>
                    <span class="t4 txtMiddle">
                        <h1 class="lbl-ttl" style="font-size: 1.6em;top: 22px;font-weight: 600;">A one-stop solution for Gig Lifecycle Management  </h1> <br><br>
                        Gignex is a NexGen technology platform that enables your Gig Workforce ecosystem. It comes with gig workforce lifecycle management from where you can manage your gig workforce from the time you hire to the time you retire them.
                    </span>
                </p>
                <br><br>
                <div class="row paraAdjust alignCenter">
                    <div class="col-md-2 col-sm-1 col-xs-12"></div>
                    <div class="col-md-4 col-sm-5 col-xs-12">
                        <img src="../assets/image/gignexImg1.PNG" style="width:100%;" alt="Image"/>
                    </div>
                    <div class="col-md-4 col-sm-5 col-xs-12">
                        <span class="t4"><br>
                            <b class="lbl-ttl">Gig Workforce Through Gignex</b><br><br>
                            You can now drive a very high level of productivity and accountability of a gig workforce through Gignex. It takes onto account the multiple variable that gets played out in the gig ecosystem like that of scale up and down, hourly/daily payment, self-learning, other performance metric and data/agent security elements.
                        </span>
                    </div>
                    <div class="col-md-2 col-sm-1 col-xs-12"></div>
                </div> <br><br>


                <div class="row rowMLRZero paraAdjust alignCenter">
                    <div class="col-md-2 col-sm-12 col-xs-12"></div>
                    <div class="col-md-5 col-sm-12 col-xs-12 forMobileBpo text-center">
                        <img src="../assets/image/comnexMic.png" style="width:250px;" alt="Image"/>
                    </div>
                    <div class="col-md-5 col-sm-12 col-xs-12" style="padding: 0 50px;">
                        <span class="t4">
                            <b class="lbl-ttl"> If you are a BPO or a call centre providing
                                voice/non-voice based services for your client</b><br><br>
                                Gignex can help your agents to work from anyplace through its platform capabilities of cloud-based, mobile-based predictive dialer and auto call distributor

                                This next-generation cloud-based contact centre solution has built-in intelligent dialler and a call distributor. It can enable work from anywhere as a concept and drive your campaign’s success. The dialler and Call Distributor comes with a super App. App can be curated and customized as per the need of the process and also capture customer dispositions. This makes it powerful, effective, accessible and your go-to cloud call centre solution.
                        </span>
                    </div>
                    <div class="col-md-4 col-sm-12 col-xs-12 forWebBpo">
                        <br><br>
                        <img src="../assets/image/comnexMic.png" style="width:250px;" alt="Image"/>
                    </div>
                    <div class="col-md-1 col-sm-12 col-xs-12"></div>
                </div> <br><br>











                <div class="row rowMLRZero paraAdjust" style="background-color: #f5f5f5;padding: 70px 50px;">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <p class="text-center t4 " style="line-height: 35px;">
                            <span>
                                <h2 style="color:#000000">Gignex comes inbuilt with </h2>
                            </span><br>
                            <span> <b class="lbl-ttl" style="font-weight: 500;">Remote Hiring Module </b> <br></span>
                            <span class="txtMiddle"> Covers interview, screening process with process/project relevance and onboarding the selected candidates electronically with basic background check.
                            </span><br><br>
                            <span><b class="lbl-ttl" style="font-weight: 500;">Remote Training module with easy plugin with your LMS </b> <br> </span>
                            <span class="txtMiddle">Easy to use and customisable module covers all the essential element of training: Training, Assessments, Certification, On the job training  and refreshers
                            </span><br><br>
                            <span> <b class="lbl-ttl" style="font-weight: 500;">Remote Process Delivery with real-time dashboard</b> <br></span>
                            <span class="txtMiddle"> Set and run multiple campaigns run them with ease. Drive productivity with real-time dashboard that captures agent’s productivity metrics
                            </span><br><br>
                            <span> <b class="lbl-ttl" style="font-weight: 500;"> Governance and support </b> <br>
                            </span>
                            <span class="txtMiddle"> Empowers supervisors, managers to support and govern agent’s activity on call remotely, through features such as; call barge, call whisper, call merge and transfer </span><br>
                            <br>
                            <span> <b class="lbl-ttl" style="font-weight: 500;">Cloud-based audit module</b>
                                <br></span>
                            <span class="txtMiddle">Quality compliance through customisable quality audit module, monitor agent-customer conversations with a customisable audit framework and enable agents to have access to feedback real-time
                            </span><br>
                            <br>
                            <span> <b class="lbl-ttl" style="font-weight: 500;">Agent time slot scheduler  </b>
                                <br></span>
                            <span class="txtMiddle">Flexibility to schedule basis process and project needs
                            </span><br><br>
                            <span> <b class="lbl-ttl" style="font-weight: 500;">Payment module</b> <br></span>
                            <span class="txtMiddle"> Payment module that auto calculates productivity
                            </span><br><br>
                            <span> <b class="lbl-ttl" style="font-weight: 500;">Agent and Data Security  </b>
                                <br></span>
                            <span class="txtMiddle">Voice and facial biometric authentication and authorisation for agents, number masking for Customer data security
                            </span><br><br>
                        </p>
                    </div>
                </div>
                <br><br>
                <p class="text-center paraAdjust"> <span class="t2">
                        <img src="../assets/image/v-comnexEngageIPic.png" alt="Image" style="width: 2em;" />
                    </span>
                    <br> <span class="t4 txtMiddle"> <br>
                        <b class="lbl-ttl">Gignex </b> <br><br>
                        Help businesses, unshackle themselves from the boundaries of geography, language and four walled offices and deliver more with a unified Work from anywhere solution.
                    </span>
                </p><br><br>

                <div class="row rowMLRZero paraAdjust alignCenter" style="background-color: #3fb91f;padding: 35px 4px;">
                    <div class="col-md-1 col-sm-1 col-xs-12"></div>
                    <div class="col-md-5 col-sm-5 col-xs-12">
                        <br>
                        <img src="../assets/image/gigBg.png" style="width:100%" alt="Image" />
                    </div>
                    <div class="col-md-5 col-sm-5 col-xs-12">
                        <p style="color:#fff;font-size: 0.8em;padding: 0 50px;"><br><br>
                            <span style="font-size:2em">Gignex</span><br><br>
                            It is developed with leveraging years of experience in the gig ecosystem, by our experts with hands on experience in the remote work industry. This power pack platform renders solutions from gig hiring-to-onboarding-to-training-to-remote CX delivery- to-monitoring-to- quality audit module, all in a single unified manner. It  also comes with a bonus of a payment module and work slot features making it a perfect one-stop solution for Gig Workforce Management.
                        </p>
                    </div>
                    <div class="col-md-1 col-sm-1 col-xs-12"></div>
                </div>
                <br><br>

                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <p class="text-center t4 paraAdjust" style="font-size: 0.8em;line-height: 35px;">
                            <span>
                                <h2 style="color:#000000">With Gignex, you can </h2>
                            </span><br>
                            <span class="txtMiddle">
                                <ul style="text-align: initial;display: inline-block;">
                                    <li>
                                        Enhance Distributed workforce Engagement & Experience
                                    </li>
                                    <li>
                                        Solve scalability issues with Gignex flexibility and cater to high seasonal
                                        demands
                                    </li>
                                    <li>
                                        Expand the addressable talent pool, break boundaries of geographies and language
                                        save time & cost by investing in a complete agent lifecycle management solution.
                                    </li>
                                </ul>
                            </span><br>
                        </p>
                    </div>
                </div>
            </div>
            <!--end Gignex-->
            <br>
        </div>
    </div>


    <div class="row aboutKnowProcs" style="background-image: url('../assets/image/aboutProBg.png')">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <p class="aboutProDesc" style="padding-top: 45px;"><img src="../assets/image/knowmoreabout.png" alt="Image"/></p>
            <p class="text-center"> <span class="view-demo" style="border:none;width: 190px;display: inline-block;margin: 0px;background-color:
    #dd133c" (click)="openKnowMore()"> KNOW MORE </span></p>
        </div>
    </div>
    <div class="row rowMLRZero" style="background-color: #f5f5f5;">
        <div class="col-md-12 text-center">
            <div>
                <br>
                <div class="frow">
                    <div class="fcolumn">
                        <p><img src="../assets/image/Quick.png" style="width:180px;position:relative;right:7px;" alt="Image"></p>
                    </div>
                </div>
                <div class="frow">
                    <div class="fcolumn">
                        <p class="p-ttl">Contiinex</p>
                        <p class="p1" (click)="comnexPage()">Comnex </p>
                        <p class="p1" (click)="gignexPage()">Gignex </p>
                        <p class="p1" (click)="ainexPage()">Ainex</p>
                        <!-- <p class="p1">Dialer & Smarter Auto Call Distribution</p>
                        <p class="p1">Workflow Automation</p>
                        <p class="p1">Contiinex Widget</p>
                        <p class="p1">Contiinex Quality Audit</p> -->
                    </div>

                    <div class="fcolumn">
                        <p class="p-ttl">Solutions</p>
                        <p class="p1" (click)="comnexPage()">WhatsApp</p>
                        <p class="p1" (click)="comnexPage()">Email</p>
                        <p class="p1" (click)="comnexPage()">SMS</p>
                        <p class="p1" (click)="comnexPage()">IVR</p>
                    </div>
                    <div class="fcolumn">
                        <p class="p-ttl">About Us</p>
                        <p class="p1"><a href="https://smarterbiz.ai/smarterbiz-home" target="_blank">SmarterBiz</a>  </p>
                        <p class="p1">Our Success </p>
                        <p class="p1">Our Team </p>
                    </div>
                    <div class="fcolumn">
                        <p class="p-ttl">Contact Us</p>
                        <p class="p1" (click)="contactUsPage()">Contact Us </p>
                        <p class="p1"><a href="https://smarterbiz.ai/smarterbiz-careers" target="_blank">Careers</a> </p>
                        <p><input type="text" class="inputEm" placeholder="Enter your email address"></p>
                        <p class="p1"><button class="subs-btn">SUBSCRIBE TO NEWS LETTER </button> </p>
                    </div>
                </div>
                <br>

                <br><br>
                <p class="text-center">
                    <a href="https://www.facebook.com/Contiinex-110359611200493" target="_blank" class="fcbk"><i class="fa fa-facebook" style="padding: 6px 7px;" aria-hidden="true"></i></a>
                    <a href="https://www.linkedin.com/company/contiinex-com" target="_blank" class="lnkdin"><i class="fa fa-linkedin" style="padding: 6px 7px;" aria-hidden="true"></i></a>
                    <a href="#" target="_blank" class="twtr"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                    <a href="#" target="_blank" class="playVideo"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                </p><br>
                <p class="text-center">
                    <a href="https://smarterbiz.ai/privacy-policy" target="_blank" class="tmPolicy">Terms of Services.</a>
                    <a href="https://smarterbiz.ai/privacy-policy" target="_blank" class="tmPolicy"> Privacy Policy</a>
                </p><br>
            </div>
            <div class=""
                style="padding: 20px;object-fit: contain; color: #ffffff; background-color: #000000;width: 100%;">
                <div>
                    2021 © Smarter Biz Technologies Private Limited. All rights reserved
                </div>
            </div>

        </div>
    </div>
</div>

<!--Open Form for Know more start-->
<div id="popDiv" class="ontop">
<div class="V-chatBoxDiv" *ngIf="showHideFormKnowMore" id="knowMoreFormDIV">
    <div class="V-box-header">
  <span><i class="fa fa-close v-close-chat" (click)="closeForm()" aria-hidden="true"></i></span>

  <div class="v-chat-title">
    <table style="width:100%">
      <tr>
        <td>
          <span><img class="v-chatImg" src="../assets/talkMan.svg" alt="Image"/></span>
          <span><i class="fa fa-commenting-o v-chat-cmnt" aria-hidden="true"></i></span>
        </td>
        <td>
          <span> Support<br><span style="font-size: 0.9em;font-style: italic;">Connect to our representatives </span></span>
        </td>
      </tr>
    </table>
  </div>
    </div>
    <form  [formGroup]="createdForm">
  <div style="padding: 13px 12px;">
  <p *ngIf="showHidePassingMessage" [style.color]="_color" style="font-weight: bold;font-size: 17px;">{{passingMessage}}</p>
  <p> <input type="text" (keypress)="omit_special_char($event)" maxlength="70" placeholder="Name" formControlName="formName"/> </p>
  <p> <input type="text" (keyup)="validateEmail();" maxlength="55" placeholder="Email" formControlName="formEmail"/> </p>
  <p>
    <span>
      <select placeholder="Code" style="width:30%" formControlName="formStd">
        <option value="+91">+91</option>
      </select>
    </span>&nbsp;&nbsp;
    <input type="text" (keypress)="onlyNumberValidation($event)" maxlength="12" class="phNo" placeholder="Phone Number" style="width:65%" formControlName="formPhone"/>
  </p>
  <p> <input type="text" placeholder="Your Message" formControlName="formMessage"/> </p>
  <p class="v-btn"><button (click)="saveForm(createdForm)">Submit <span><i class="fa fa-location-arrow"></i></span></button></p>
  </div>
</form>
  </div>
    
</div>

<!--Open Form for know more End-->


<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div id="cover-spin1" *ngIf="currentLoader"></div>