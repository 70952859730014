<!-- Applying table filter to the columns given as input -->
<mat-form-field class="filter_select" appearance="outline" *ngFor="let column of ref_filter_columns; let i = index;">
    <mat-label style="position: relative;padding-top:3px;padding-bottom:3px;">{{ column.title }}</mat-label>
    <mat-select [formControl]="ref_filter_columns_formControl[column.key]" multiple>
        <input matInput class="search_input_dd" placeholder="search" (keyup)="on_type($event.target.value, column)">
        <mat-option *ngFor="let val of ref_filter_columns_dropDown_select[column.key]" [value]="val">{{val}}</mat-option>
    </mat-select>
</mat-form-field>
<button mat-button (click)="applyFilter()">APPLY</button>
<button mat-button class="action_btn filter_reset_btn" (click)="resetFilter()">RESET</button>
