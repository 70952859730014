import { Component,Inject, Injectable, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { MyserviceService } from '../myservice.service';
import * as _ from 'underscore';

/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  name: string;
  id: string | number;
  role: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  name: string;
  id: string | number;
  level: number;
  role: string;
  expandable: boolean;
}

/**
 * The Json object for to-do list data.
 */
var TREE_DATA = {};

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] { return this.dataChange.value; }

  constructor() {
    this.initialize();
  }

  initialize(input_data?) {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    if(input_data){
      TREE_DATA = input_data
    }
    const data = this.buildFileTree(TREE_DATA, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: object, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.name = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.name = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  /* insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({ name: name } as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  } */
}

/**
 * @title Tree with checkboxes
 */

@Component({
  selector: 'app-generic-agents-tree-view',
  templateUrl: './generic-agents-tree-view.component.html',
  styleUrls: ['./generic-agents-tree-view.component.scss'],
  providers: [ChecklistDatabase]
})
export class GenericAgentsTreeViewComponent implements OnInit {

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  my_team = this.data.team;
  prev_selected_nodes: TodoItemFlatNode[] = this.data.selected_nodes;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  constructor(
    public dialogRef: MatDialogRef<GenericAgentsTreeViewComponent>,
    private database: ChecklistDatabase,
    private myService: MyserviceService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    database.dataChange.subscribe(data => {
      this.dataSource.data = data;
      // console.log('dataSource data', JSON.stringify(this.dataSource.data))
    });
  }

  ngOnInit(): void {
    this.assign_my_team();
  }

  ngAfterContentInit() {
    // console.log('my team', this.my_team)
    this.assign_my_team();
  }

  assign_my_team(){
    if (this.my_team) {
      if(typeof this.my_team === 'object'){
        this.dataSource.data = [this.my_team];
      }else{
        this.dataSource.data = this.my_team;
      }
    }
    // console.log('selected agents', this.data.selected_agents);
    // console.log('data nodes', this.treeControl.dataNodes)
    if(this.data.selected_agents.length){
      const data_nodes = this.treeControl.dataNodes;
      const agents_by_id = _.groupBy(this.data.selected_agents, 'user_id');
      for(var i in data_nodes){
        if(agents_by_id.hasOwnProperty(String(data_nodes[i]['id']))){
          this.checklistSelection.select(data_nodes[i]);
        }
      }
    }
    this.treeControl.expandAll();
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.name === '';

  isValidNode = (_nodeData: TodoItemFlatNode) => {
    if(_nodeData.role == 'user'){
      return true;
    }
    if((_nodeData.role != 'user') && _nodeData.expandable){
      return true;
    }
    return false;
  };

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    // console.log('node', node);
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.id === node.id
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.id = node.id;
    flatNode.role = node.role;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }

  getSelected = () => this.checklistSelection.selected.filter(v => { return v.expandable == false && v.role == 'user' });

  /** Select the category so we can insert the new item. */
  /* addNewItem(node: TodoItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    this.database.insertItem(parentNode!, '');
    this.treeControl.expand(node);
  } */

  /** Save the node to database */
  /* saveNode(node: TodoItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this.database.updateItem(nestedNode!, itemValue);
  } */

  closePopup() {
    this.dialogRef.close();
  }

  apply_selection(){
    const selected_agents = this.getSelected();
    if(selected_agents.length){
      this.dialogRef.close({"selected_nodes": this.getSelected()});
    }else{
      this.snackbar.open("Select atleast one agent.", 'close');
    }
  }
  prnt(node){
    console.log(node)
  }

}
