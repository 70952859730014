import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../api.service';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyserviceService } from '../myservice.service';

@Component({
	selector: 'app-payment-dashboard-approval-uploadfile-dialog',
	templateUrl: './payment-dashboard-approval-uploadfile-dialog.component.html',
	styleUrls: ['./payment-dashboard-approval-uploadfile-dialog.component.scss']
})
export class PaymentDashboardApprovalUploadfileDialogComponent implements OnInit {

	api_end_point:any;
	constructor(public dialogRef: MatDialogRef<PaymentDashboardApprovalUploadfileDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewData, public formbuilder: FormBuilder, private API: ApiService,private _snackBar: MatSnackBar,private myService: MyserviceService) { }

	file: File;

	ngOnInit() {
		this.api_end_point = this.myService.get_API_End_Point();
	}
	closeDialog() {
		this.dialogRef.close();
	}
	saveFile() {
		if (!this.file) {
		//	alert('please upload the valid excel file');
			this._snackBar.open('Please upload excel sheet', 'close', {
				duration: 4000,
			});
			return;
		} else {
			let formData = new FormData();
			formData.append('attachment', this.file);
			this.API.uploadPaymentUpdate(formData,this.api_end_point).subscribe((res: any) => {
				if (res.errors) {
				//	alert(res.errors)
					this._snackBar.open(res.errors, 'close', {
						duration: 4000,
					});
				} else {
					//alert(res.success);
					this._snackBar.open(res.success, 'close', {
						duration: 4000,
					});
					this.dialogRef.close('success');
				}
			})
		}
	}
	changeFile(event) {
		// debugger;
		let filePath = event.target.files[0].name;
		let allowedExtensions = /(\.xls|\.xlsx)$/i;
		if (!allowedExtensions.exec(filePath)) {
			//alert('Please upload file having extensions .xls/.xlsx only.');
			this._snackBar.open('Please upload file having extensions .xls/.xlsx only.', 'close', {
				duration: 4000,
			});
			return false;
		}
		const reader = new FileReader();
		if (event.target.files && event.target.files.length) {
			this.file = event.target.files[0];
			//console.log('file', this.file);
		} else {
			console.log('no files');
		}
	}

}
