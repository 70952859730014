<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu>
	
	
	</mat-drawer>
	<app-logout></app-logout>
	<router-outlet *ngIf="show_details"></router-outlet>
	<div class="row" style="background: #f6f6f6;">

		<div class="col-md-12">

			<div id="main_data" *ngIf="!show_details" style="padding: 20px 22px 50px 26px;">

				<div class="row">
					<div class="col-md-12">
						<h4 class="titlehead"
							style="margin-top: 0%;padding-left: 10px;position: relative;top: -15px;z-index: 9;">Campaigns
							Dashboard</h4>
					</div>
				</div>

				<div class="row">
					<div class="col-md-3" style="padding-right: 0;">
						<div class="card">
							<div class="card-header">All Campaigns</div>
							<div class="card-body">
								<table class="Cam-table">
									<tr>
										<td><span>Data Assigned</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-one'"
												[value]="summary.total.data_percent"></mat-progress-bar>
										</td>
										<td class="tdData1"><span>{{ summary.total.data | number
												}} ({{get_decimal_val(summary.total.data_percent)}} %)</span></td>
									</tr>
									<tr>
										<td><span>Data Dialled</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-two'"
												[value]="summary.total.to_call_percent"></mat-progress-bar>
										</td>
										<td class="tdData2"><span> {{summary.total.to_call | number
												}} ({{get_decimal_val(summary.total.to_call_percent)}} %)</span>
										</td>
									</tr>
									<tr>
										<td><span>Data Contacted</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-three'"
												[value]="summary.total.called_percent"></mat-progress-bar>
										</td>
										<td class="tdData3"><span>
												{{summary.total.called | number}} ({{get_decimal_val(summary.total.called_percent)}} %)</span>
										</td>
									</tr>
								</table>


								<!-- <table class="tablecs">
						<tr><td><div class="show_bar"><div>Data assigned</div><hr class="level1"
										[style.width.%]="summary.total.data_percent" /></div></td>
							<td><div class="show_count">{{summary.total.data_percent}}% <span class="value_count">({{ summary.total.data }})</span></div></td>
						</tr>
						<tr><td><div class="show_bar"><div>Data Dialled</div><hr class="level2"
										[style.width.%]="summary.total.to_call_percent" /></div></td>
							<td><div class="show_count">{{summary.total.to_call_percent}}% <span class="value_count">({{ summary.total.to_call }})</span></div>	</td>
						</tr>
						<tr><td><div class="show_bar"><div>Data Contacted</div><hr class="level3" [style.width.%]="summary.total.called_percent" /></div></td>
							<td><div class="show_count">{{summary.total.called_percent}}% <span class="value_count">({{ summary.total.called }})</span>	</div>
							</td>
						</tr>
					</table> -->
							</div>
						</div>
					</div>
					<div class="col-md-3" style="padding-right: 0;">
						<div class="card">

							<div class="card-header">Active Campaigns
							</div>
							<div class="card-body">
								<table class="Cam-table">
									<tr>
										<td><span>Data Assigned</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-one'"
												[value]="summary.active.data_percent"></mat-progress-bar>
										</td>
										<td class="tdData1"><span> {{
												summary.active.data | number }} ({{get_decimal_val(summary.active.data_percent)}} %)</span></td>
									</tr>
									<tr>
										<td><span>Data Dialled</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-two'"
												[value]="summary.active.to_call_percent"></mat-progress-bar>
										</td>
										<td class="tdData2"><span> {{
												summary.active.to_call | number }} ({{get_decimal_val(summary.active.to_call_percent)}} %)</span>
										</td>
									</tr>
									<tr>
										<td><span>Data Contacted</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-three'"
												[value]="summary.active.called_percent"></mat-progress-bar>
										</td>
										<td class="tdData3"><span>
												{{summary.active.called | number}} ({{get_decimal_val(summary.active.called_percent)}} %)</span>
										</td>
									</tr>
								</table>



								<!-- <table class="tablecs">
						<tr><td><div class="show_bar"><div>Data assigned</div><hr class="level1" [style.width.%]="summary.active.data_percent" /></div></td>
							<td><div class="show_count">{{summary.active.data_percent}}% <span	class="value_count">({{ summary.active.data }})</span></div></td>
						</tr>
						<tr><td><div class="show_bar"><div>Data Dialled</div><hr class="level2"	[style.width.%]="summary.active.to_call_percent" />	</div>	</td>
							<td><div class="show_count">{{summary.active.to_call_percent}}% <span class="value_count">({{ summary.active.to_call }})</span>	</div>	</td>
						</tr>
						<tr><td><div class="show_bar">	<div>Data Contacted</div><hr class="level3"
										[style.width.%]="summary.active.called_percent" /></div></td>
							<td><div class="show_count">{{summary.active.called_percent}}% <span
										class="value_count">({{ summary.active.called }})</span>
								</div>
							</td>
						</tr>
					</table> -->
							</div>
						</div>
					</div>
					<div class="col-md-3" style="padding-right: 0;">
						<div class="card">
							<div class="card-header">Inactive Campaigns
							</div>
							<div class="card-body">

								<table class="Cam-table">
									<tr>
										<td><span>Data Assigned</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-one'"
												[value]="summary.inactive.data_percent"></mat-progress-bar>
										</td>
										<td class="tdData1"><span>
												{{summary.inactive.data | number }} ({{get_decimal_val(summary.inactive.data_percent)}} %)</span>
										</td>
									</tr>
									<tr>
										<td><span>Data Dialled</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-two'"
												[value]="summary.inactive.to_call_percent"></mat-progress-bar>
										</td>
										<td class="tdData2"><span> {{
												summary.inactive.to_call | number
												}} ({{get_decimal_val(summary.inactive.to_call_percent)}} %)</span>
										</td>
									</tr>
									<tr>
										<td><span>Data Contacted</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-three'"
												[value]="summary.inactive.called_percent"></mat-progress-bar>
										</td>
										<td class="tdData3"><span>
												{{summary.inactive.called | number}} ({{get_decimal_val(summary.inactive.called_percent)}} %)</span>
										</td>
									</tr>
								</table>






								<!-- <table class="tablecs">
						<tr><td><div class="show_bar"><div>Data assigned</div>
									<hr class="level1"	[style.width.%]="summary.inactive.data_percent" />	</div>	</td>
							<td><div class="show_count">{{summary.inactive.data_percent}}% <span
										class="value_count">({{ summary.inactive.data }})</span></div>	</td>
						</tr>
						<tr><td><div class="show_bar">
									<div>Data Dialled</div><hr class="level2" [style.width.%]="summary.inactive.to_call_percent" /></div></td>
							<td><div class="show_count">	{{summary.inactive.to_call_percent}}% <span
										class="value_count">({{ summary.inactive.to_call }})</span></div></td>
						</tr>
						<tr><td><div class="show_bar"><div>Data Contacted</div>	<hr class="level3"	[style.width.%]="summary.inactive.called_percent" /></div>	</td>
							<td><div class="show_count">
									{{summary.inactive.called_percent}}% <span
										class="value_count">({{ summary.inactive.called }})</span>
								</div>
							</td>
						</tr>
					</table> -->
							</div>
						</div>
					</div>

					<div class="col-md-3" style="padding-right: 0;">
						<div class="card">
							<div class="card-header">Data Sources
							</div>
							<div class="card-body">

								<table class="Cam-table">
									<tr *ngFor="let campaign of getJsonKeys(summary.data_source)">
										<td><span style="text-transform: capitalize;">{{ campaign }}</span>
											<mat-progress-bar mode="determinate" [ngClass]="'color-one'"
												[value]="summary.data_source[campaign].called_percent"></mat-progress-bar>
										</td>
										<td class="tdData1"><span>
												{{summary.data_source[campaign].data | number }} ({{get_decimal_val(summary.data_source[campaign].called_percent)}} %)</span>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>

					<!-- <div class="col-md-3">
						<div class="card">
							<div class="card-header">Data Sources</div>
							<div class="card-body scroll">
								<table class="tablecs">
									<thead>
										<tr>
											<th>Source</th>
											<th>Data</th>
											<th>Utilized</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let campaign of getJsonKeys(summary.data_source)">
											<td class="data_source_head"><span>{{ campaign }}</span></td>
											<td class="data_source_data">
												{{ summary.data_source[campaign].data | number}}</td>
											<td class="numeric_threshold">
												{{ summary.data_source[campaign].called_percent }} %</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div> -->
				</div>


				<br>
				<mat-card>
					<p class="textAlertMessage" style="font-size: 18px;" *ngIf="showHide_messageAlert">{{messageAlert}}
					</p>
					<div class="row">
						<div class="col-md-12">
							<div class="pDiv1">
								<!-- <div class="divprintRt"> -->
								<div class="div_inline_items">
									<span class="recName" *ngIf="showCampaign" style="top: 0px;">
										Campaigns Summary
									</span>
									<span class="datecs">{{ today }}</span>
									<span class="year_dropdown">
										<mat-form-field appearance="outline" [ngClass]="'selectOutlineCss'">
											<mat-label>Select Month</mat-label>
											<mat-select style="padding-top: 3px;" [value]="selected_month.value"
												(selectionChange)="change_selectedMonth($event)">
												<mat-option *ngFor="let month of months_filter" [value]="month.value">
													{{month.name}}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</span>

									<span class="span_small_margin" (click)="refreshCampaignData()"><button
											mat-button class="action_btn">GO</button></span>

									<span class="span_small_margin" *ngIf="showCampaign">
										<button mat-button (click)="openCreateCampaign()"
											[disabled]="isCampbtnDisable">CREATE
											CAMPAIGN
											</button>
										<!--<button mat-button (click)="createCampaignPage()" [disabled]="isCampbtnDisable">Create
								campaign
								+</button>-->
									</span>
									<span class="span_small_margin" *ngIf="showCampaign">
										<button mat-button class="action_btn" (click)="downloadAttachment()">DOWNLOAD
											<!-- <span class="material-symbols-outlined">
												download
												</span> -->
											<!-- <mat-icon
												class="mr-2 v-back-ico">arrow_downward</mat-icon> --><!--[disabled]="isCampbtnDisable"-->
										</button>
									</span>
									<span class="searchSpan">
										<mat-form-field class="search-inputbox">
											<input matInput #_searchValue
												(keyup.enter)="applyFilter($event.target.value)" placeholder="Search">
											<i matSuffix mat-button class="fa fa-search searchicon" aria-hidden="true"
												(click)="applyFilter(_searchValue.value)"></i>
										</mat-form-field>
									</span>
								</div>
							</div>
							<br>
							<div class="pDiv1 bulk_select_sec" *ngIf="check_visibility_bulk_div">
								<div>
									<button mat-button (click)="bulk_camapign_btn_click()">SELECT CAMPAIGNS</button>
								</div>
								<div class="bulk_select_banner" *ngIf="show_bulk_selection_banner">
									<div class="sub_banner">
										<div class="checkbox_selector">
											<mat-checkbox class="bulk_select_checkbox" [checked]="bulk_all_campaigns_selected" 
											[indeterminate]="bulk_partial_camapigns_selected()" 
											[disabled]="bulk_all_campaigns_check_disabled()"
											 (change)="bulk_campaigns_select_all($event.checked)">
												<span *ngIf="!count_campaigns_selected()">Select All To</span>
												<span *ngIf="count_campaigns_selected() && !bulk_assign_all_selected()">{{ count_campaigns_selected() }} Campaigns Selected To</span>
												<span *ngIf="bulk_assign_all_selected()">All Campaigns Selected To</span>
											</mat-checkbox>
										</div>
										<div class="toggle_slider">
											<mat-slide-toggle [(ngModel)]="bulk_camapigns_switch"
												(change)="reset_bulk_selection_controls()">
											</mat-slide-toggle>
											Turn <span>{{bulk_camapigns_switch ? "ON" : "OFF"}} the Campaigns</span>
										</div>
										<button mat-button 
										(click)="send_bulk_action_campaigns()"
										[disabled]="!bulk_all_campaigns_selected && !bulk_partial_camapigns_selected()">Go</button>
										<mat-icon (click)="close_bulk_camapign_selection_banner()">cancel</mat-icon>
									</div>
								</div>
							</div>
							<br>
							<div class="pDiv1">
								<mat-table [dataSource]="dataSourceCampaign" class="cTable w-100">
									<ng-container matColumnDef="bulk_selected">
										<mat-header-cell *matHeaderCellDef> Select </mat-header-cell>									
										<mat-cell *matCellDef="let element"> 
											<mat-checkbox class="camapign_select_box" [checked]="element.bulk_selected"
												(change)="bulk_camapign_selected(element)"
												[disabled]="check_bulk_campaign_disabled(element)">
											</mat-checkbox>
										</mat-cell>
									</ng-container>
									<ng-container matColumnDef="sl_no">
										<mat-header-cell *matHeaderCellDef> SL.No </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.sl_no}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="campaign_name">
										<mat-header-cell *matHeaderCellDef style="flex:0 0 200px;"> Name
										</mat-header-cell>
										<mat-cell *matCellDef="let element" style="flex:0 0 200px;font-size: 13px;"
											class="campaign_name" (click)="actionViewMore(element)"
											[matTooltip]="element.campaign_name" [matTooltipPosition]="'right'">
											{{element.campaign_name}} </mat-cell>
										<!-- (click)="openCampaignDetails(element)" -->
									</ng-container>
									<ng-container matColumnDef="actionViewMore">
										<mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
										<mat-cell *matCellDef="let element">
											<button mat-button class="btn-view-more action_btn" (click)="actionViewMore(element)">VIEW MORE
											</button>
											<!-- <span class="v-actionViewMore" (click)="actionViewMore(element)">View
												More</span> -->
										</mat-cell>
									</ng-container>
									<ng-container matColumnDef="campaign_owner">
										<mat-header-cell *matHeaderCellDef> Owner </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.campaign_owner}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="work_type">
										<mat-header-cell *matHeaderCellDef> Work Type </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.work_type}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="campaign_status">
										<mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
										<mat-cell *matCellDef="let element">
											<mat-slide-toggle
												*ngIf="check_val_type(element.campaign_status) == 'boolean'"
												[(ngModel)]="element.campaign_status"
												(change)="campaignStatusChange($event,element)"
												[disabled]="campaign_service.disable_campaign_full_access(element)">
												<!-- [disabled]="element.disabled" -->
											</mat-slide-toggle>
											<button mat-button color="accent" style="height: 35px;"
												*ngIf="(check_val_type(element.campaign_status) == 'string') && (element.campaign_status == 'uploaded')"
												(click)="AssignNow(element)" color="accent">
												<p style="font-size:smaller">ASSIGN</p>
											</button>
											<span
												*ngIf="(check_val_type(element.campaign_status) == 'string') && (element.campaign_status == 'assigning')"
												style="color:  #02360f;text-transform: capitalize;">{{
												element.campaign_status }}</span>
										</mat-cell>
									</ng-container>
									<ng-container matColumnDef="campaign_created">
										<mat-header-cell *matHeaderCellDef> Created </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.campaign_created}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="last_updated">
										<mat-header-cell *matHeaderCellDef> Last Refreshed </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.last_updated}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="data_uploaded">
										<mat-header-cell *matHeaderCellDef> Uploaded </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.data_uploaded | number}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="data_duplicate">
										<mat-header-cell *matHeaderCellDef> Duplicate </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.data_duplicate | number}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="errors">
										<mat-header-cell *matHeaderCellDef> Errors </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.errors | number}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="agents_count">
										<mat-header-cell *matHeaderCellDef> Agents </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.agents_count | number}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="data_added">
										<mat-header-cell *matHeaderCellDef> Assigned </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.data_added | number}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="data_dialled">
										<mat-header-cell *matHeaderCellDef> Dialled </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.data_dialled | number}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="contacted">
										<mat-header-cell *matHeaderCellDef> Contacted </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.contacted | number}} </mat-cell>
									</ng-container>
									<ng-container matColumnDef="rechurn">
										<mat-header-cell *matHeaderCellDef> Rechurn </mat-header-cell>
										<mat-cell *matCellDef="let element"> {{element.rechurn | number}} </mat-cell>
									</ng-container>
									<!-- <ng-container matColumnDef="data_source">
						<mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.data_source}} </mat-cell>
					</ng-container> -->
									<ng-container matColumnDef="downloadReports">
										<mat-header-cell *matHeaderCellDef>Reports </mat-header-cell>
										<mat-cell *matCellDef="let element">
											<!-- <button style="white-space: nowrap;
												background: white;
												font-size: 13px;
												border: 1px solid #fd6c21;
												border-radius: 4px;
												padding: 5px;position:relative" class="download_btn" [matMenuTriggerFor]="menu"
												[disabled]="element.showLoader"> -->
											<button mat-button class="no-hover" [matMenuTriggerFor]="menu"
												[disabled]="element.showLoader">
												REPORTS&nbsp;
												<i class="fa fa-angle-down" *ngIf="!element.showLoader"></i>
												<i class="fas fa-sync-alt fa-spin" *ngIf="element.showLoader"></i>
											</button>
											<mat-menu #menu="matMenu">
												<!-- <button mat-menu-item *ngIf="false" matTooltip="" (click)="addmore(element)">Add
													Data</button> -->
												<button mat-menu-item matTooltip="Call Status Report"
													(click)="downloadCallStatusReportPost(element)">Call
													Status Report</button>
												<!-- <button mat-menu-item
													(click)="downloadCustomerStatusReport(element)">Customer Status
													Report</button>
												<button mat-menu-item
													(click)="downloadCustomerStatusReport_withoutcallrecording(element)">Customer
													Status
													Report with out Recording</button> -->
												<button mat-menu-item matTooltip="Campaign Status Report (My Agents)"
													(click)="downloadCampaignStatusReport(element, true)"
													*ngIf="visibility_my_agents_report(element)">Campaign Status
													Report (My Agents)</button>
												<button mat-menu-item
													[matTooltip]="getTextMerge(element.master_campaign, 'Master Cmpaign Status Report (My Agents)')"
													(click)="downloadCampaignStatusReportMasterMYagents(element, true)"
													*ngIf="element.master_campaign && visibility_my_agents_report(element)">{{element.master_campaign}}
													- Master Campaign Status Report (My Agents)</button>
												<button mat-menu-item
													(click)="downloadCallStatusReportMasterMyagents(element)"
													[matTooltip]="getTextMerge(element.master_campaign, 'Master Call Status Report (My Agents)')"
													*ngIf="element.master_campaign && visibility_my_agents_report(element)">{{element.master_campaign}}
													- Master Call Status Report (My Agents)</button>
												<button mat-menu-item matTooltip="Campaign Status Report"
													[disabled]="campaign_service.disable_campaign_full_access(element)"
													(click)="downloadCampaignStatusReport(element)">Campaign Status
													Report</button>
												<button mat-menu-item *ngIf="element.master_campaign"
													[disabled]="campaign_service.disable_campaign_full_access(element)"
													(click)="downloadCampaignStatusReportMaster(element)">{{element.master_campaign}}
													- Master Campaign Status
													Report</button>
												<button mat-menu-item *ngIf="element.master_campaign"
													[matTooltip]="getTextMerge(element.master_campaign, 'Master Call Status Report')"
													[disabled]="campaign_service.disable_campaign_full_access(element)"
													(click)="downloadCallStatusReportMaster(element)">{{element.master_campaign}}
													- Master Call Status
													Report</button>
												<!-- <button mat-menu-iterm *ngIf="element.data_source && (element.data_source !='others')" (click)="downloadMasterCampaignStatusreport(element)">{{element.data_source}} - Master Campaign Status Report</button>
								<button mat-menu-iterm *ngIf="element.data_source && (element.data_source !='others')" (click)="downloadMasterCampaigncallStatusreport(element)">{{element.data_source}} - Master Campaign Call Status Report</button> -->
												<button mat-menu-item *ngIf="false"
													(click)="downloadDataSheet(element)">Data Sheet</button>
											</mat-menu>
										</mat-cell>
									</ng-container>
									<mat-header-row *matHeaderRowDef=displayedColumnsCampaigan></mat-header-row>
									<mat-row *matRowDef="let row; columns: displayedColumnsCampaigan"></mat-row>
								</mat-table>
								<mat-paginator #campaignpaginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons
									(page)="get_details_by_campaigns_loaded($event)">
								</mat-paginator>
								<!-- <mat-paginator #campaignpaginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                                [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
				</mat-paginator> -->
							</div>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
	<div id="cover-spin" *ngIf="showLoader"></div>
</mat-drawer-container>