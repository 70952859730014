import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orchestration-email',
  templateUrl: './orchestration-email.component.html',
  styleUrls: ['./orchestration-email.component.scss']
})
export class OrchestrationEmailComponent implements OnInit {
  channelType:any;
  showHideEmailTemplate:boolean=false;
  showHideRadioSmartbot:boolean=true;
  showHideRadioAgent:boolean=false;

  showHideSMSTemplate:boolean=false;
  showHideWhatsappTemplate:boolean=false;
  showHideEmailDeliveredToAgentTemplate:boolean=false;
  showHideAvailableAgentTemplate:boolean=false;

  showHideForEmail:boolean=false;
  smartEmailDDLOption:any;
  agentEmailDDLOption:any;
  constructor() { }

  ngOnInit(): void {
  }
  changeChannel(val:any){
    //debugger;
    this.smartEmailDDLOption="";
    this.agentEmailDDLOption="";
    this.AllTemplateFalse();
   // console.log(val);
    if(val=="Email"){
      this.channelType=val;
      this.showHideForEmail=true;
    }
    else{
      this.channelType=val;
      this.showHideForEmail=false;
    }
  }
  radioChange(val:any){
    this.AllTemplateFalse();
    if(val=="smartBots"){
      this.showHideRadioSmartbot=true;
      this.showHideRadioAgent=false;
    }
    else if(val=="agents"){
      this.showHideRadioSmartbot=false;
      this.showHideRadioAgent=true;
    }
    else{
      this.showHideRadioSmartbot=false;
      this.showHideRadioAgent=false;
    }
  }
  changeCategory(val:any){
   // debugger;
    if(this.channelType=="Email"){
if(val=="AutomaticReplyEmails"){
  this.showHideEmailTemplate=true;
  this.showHideSMSTemplate=false;
  this.showHideWhatsappTemplate=false;
  this.showHideEmailDeliveredToAgentTemplate=false;
  this.showHideAvailableAgentTemplate=false;

}
else if(val=="AutomaticSMS"){
  this.showHideEmailTemplate=false;
  this.showHideSMSTemplate=true;
  this.showHideWhatsappTemplate=false;
  this.showHideEmailDeliveredToAgentTemplate=false;
  this.showHideAvailableAgentTemplate=false;
}
else if(val=="AutomaticWhatsApp"){
  this.showHideEmailTemplate=false;
  this.showHideSMSTemplate=false;
  this.showHideWhatsappTemplate=true;
  this.showHideEmailDeliveredToAgentTemplate=false;
  this.showHideAvailableAgentTemplate=false;
  
}
else if(val=="EmailDeliveredToAgentAndAgentReplies"){
  this.showHideEmailTemplate=false;
  this.showHideSMSTemplate=false;
  this.showHideWhatsappTemplate=false;
  this.showHideEmailDeliveredToAgentTemplate=true;
  this.showHideAvailableAgentTemplate=false;
}
else if(val=="AvailableOnlineAgent"){
  this.showHideEmailTemplate=false;
  this.showHideSMSTemplate=false;
  this.showHideWhatsappTemplate=false;
  this.showHideEmailDeliveredToAgentTemplate=false;
  this.showHideAvailableAgentTemplate=true;
}
else{
 this.AllTemplateFalse();
}
    }
  }
  AllTemplateFalse(){
    this.showHideEmailTemplate=false;
    this.showHideSMSTemplate=false;
    this.showHideWhatsappTemplate=false;
    this.showHideEmailDeliveredToAgentTemplate=false;
    this.showHideAvailableAgentTemplate=false;
  }
}
