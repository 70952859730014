<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">
     <mat-card>
         <h2 class="d-head">ONGOING BATCHES<img src="../assets/underline.svg"
            class="linebelow"> </h2>

<div class="searchFix">
    <mat-form-field style="font-size: 12px;" appearance="outline">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH A BATCH">
        <i class="fa fa-search search-icon" matSuffix style="position: relative;
        top: -2px;"></i>
      </mat-form-field>
      <button mat-button class="T-createbtn"  (click)="createNewBatch()"><i class="fa fa-plus" style="padding-left: 10px;
        font-size: 16px;"></i>&ensp;CREATE NEW BATCH </button>
</div>
<br>
<div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

<ng-container matColumnDef="id">
  <th mat-header-cell *matHeaderCellDef>BATCH CODE </th>
  <td mat-cell *matCellDef="let element" class="CR" (click)="batchRowRecord(element)">{{element.id}} </td>
</ng-container>

<ng-container matColumnDef="start_date">
  <th mat-header-cell *matHeaderCellDef> BATCH STARTS </th>
  <td mat-cell *matCellDef="let element" class="CR" (click)="batchRowRecord(element)"> {{element.start_date | date:'yyyy-MM-dd'}} </td>
</ng-container>

<ng-container matColumnDef="end_date">
  <th mat-header-cell *matHeaderCellDef> BATCH ENDS </th>
  <td mat-cell *matCellDef="let element" class="CR" (click)="batchRowRecord(element)"> {{element.end_date  | date:'yyyy-MM-dd'}} </td>
</ng-container>

<ng-container matColumnDef="trainer_name">
  <th mat-header-cell *matHeaderCellDef> TRAINER </th>
  <td mat-cell *matCellDef="let element" class="CR" (click)="batchRowRecord(element)"> {{element.trainer_name}} </td>
</ng-container>

<ng-container matColumnDef="capacity">
    <th mat-header-cell *matHeaderCellDef> CAPACITY </th>
    <td mat-cell *matCellDef="let element" class="CR" (click)="batchRowRecord(element)"> {{element.capacity}} </td>
  </ng-container>

<ng-container matColumnDef="duration">
    <th mat-header-cell *matHeaderCellDef> DURATION </th>
    <td mat-cell *matCellDef="let element" class="CR" (click)="batchRowRecord(element)"> {{element.duration}} </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let element" class="CR" (click)="batchRowRecord(element)"> {{element.status}} </td>
  </ng-container>
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef> EDIT </th>
    <td mat-cell *matCellDef="let element" (click)="openEditBatch(element)"> <span class="detailsCss">Edit Batch</span> </td>
  </ng-container>

<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
</table>
<mat-paginator [ngClass]="'paginator-training'"  showFirstLastButtons [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
            
</mat-card>
</div>
</div>

