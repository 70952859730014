import { Component, OnInit, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, Validators, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { BehaviorSubject } from "rxjs";
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as _ from 'underscore';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MyserviceService } from '../../../myservice.service';
import { ServicesService } from '../../../dashboard/services.service';
import { ApiService } from '../../../api.service';
import { Utils } from '../../../utils';
import * as cloneDeep from 'lodash/cloneDeep';
import { MAT_DATE_FORMATS } from '@angular/material/core';

//tree start
//tree end

interface agencyList {
	name: string,
	value: string
}

//tree start
@Injectable()
export class ChecklistDatabase {
	dataChange = new BehaviorSubject<TodoItemNode[]>([]);
	TREE_DATA_XX = {};
	get data(): TodoItemNode[] {
		return this.dataChange.value;
	}
	TREE_DATA: any = [];

	constructor(public API: ApiService, private myService: MyserviceService) {
		this.initialize();
	}

	initialize() {
		let _record: TodoItemNode | any = this.myService.getUserGroupTreeNode();
		if (_record) {
			this.TREE_DATA_XX = _record;
			this.dataChange.next([_record]);
		}
	}
	_getDataTreeXX() {
		return this.TREE_DATA_XX;
	}
	buildFileTree(obj: { [key: string]: any }, level: number): TodoItemNode[] {
		//	debugger;
		return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
			const value = obj[key];
			const node = new TodoItemNode();
			node.name = key;

			if (value != null) {
				if (typeof value === "object") {
					node.children = this.buildFileTree(value, level + 1);
				} else {
					node.name = value;
				}
			}

			return accumulator.concat(node);
		}, []);
	}
}
//tree end

export function timeValidator(control: AbstractControl): { [key: string]: any; } | null {
	var hr = moment().format('HH');
	var min = moment().format('mm');
	if (control.value) {
		var [c_hr, c_min] = control.value.split(':');
		if (parseInt(c_hr) >= parseInt(hr)) {
			if (parseInt(c_min) >= parseInt(min)) {
				return;
			}
		}
		//return { 'timeError': 'Invalid time. Choose curent time of future time' };
	}
}

export const MY_DATE_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	},
};

@Component({
	selector: 'app-campaign-dialog',
	templateUrl: './campaign-dialog.component.html',
	styleUrls: ['./campaign-dialog.component.scss'],
	providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }, ChecklistDatabase]

})
export class CampaignDialogComponent implements OnInit {
	bulkUploadFile = [];
	utils = new Utils();
	showLoader = false;
	allow_repush: boolean = false;
	currentFile: any;
	currentFileobj: any = {};
	current_time = moment().format('HH:mm');
	campaignUpload_msg: string = '';
	campaignType: string = '';
	customMessage: string = '';
	myUser: any;
	pd_dial_ratio: number;
	campaignForm: FormGroup;
	currentSettings = null;
	master_campaign_field = {
		'input': "FT",
		'values': this.campaignType,
		'default_value': this.campaignType
	}
	custom_message_field = {
		'input': "FT",
		'values': this.customMessage,
		'default_value': this.customMessage
	}
	dial_mode: any;
	incallscreen: any;
	incallscreen_atrs = {
		"visibility": false,
		"selected_value": '',
		"pre_values": []
	}
	email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	num_pattern = /^(\+91)?(91)?(\d{10})$/;
	name_pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
	_formlist: any = [];
	showHideAtleastOneAgent: boolean = false;
	campaign_work_mode: any = ["WFO", "WFH"];
	templateddownloadurl: any = "";
	_companyName: any;
	api_end_point: any;
	WFO_agentlist: any = [];
	WFH_agentlist: any = [];
	telco_operator = null;
	campaign_upload_show_only_agents = false;
	agent_selected_option = 'web';
	agent_radio_buttons = [
		{
			name: 'Select From Dropdown',
			value: 'web',
			checked: this.agent_selected_option == 'web',
		},
		{
			name: 'Select From Excel',
			value: 'excel',
			checked: this.agent_selected_option == 'excel',
		}
	]
	constructor(
		private API: ApiService,
		public dialogRef: MatDialogRef<CampaignDialogComponent>,
		private service: MyserviceService,
		private dashService: ServicesService,
		private myservice: MyserviceService,
		private formbuilder: FormBuilder,
		private _database: ChecklistDatabase,
		private _snackBar: MatSnackBar
	) {
		let p: any = this.service.get_stage_settings();
		// console.log('print settings', p);
		if (p && p['web'] && p['web'].length) {
			let name: any = _.groupBy(p['web'], 'name');
			// debugger;
			if (name && name['campaign_type']) {
				try {
					let q: any = JSON.parse(name['campaign_type'][0]['definition']);
					this.master_campaign_field = q['campaign_type'];
					this.master_campaign_field.default_value = q['campaign_type']['values'][0];
				} catch (e) {

				}
			}
			if (name && name['campaign_push']) {
				this.campaign_upload_show_only_agents = false;
				try {
					let values = JSON.parse(name['campaign_push'][0]['definition']);
					if (values && values['agents_view']) {
						if (values['agents_view'] == 'only_agents') {
							this.campaign_upload_show_only_agents = true;
						}
					}
				} catch (e) {

				}
			}
		}

		if (p && p['cloudtelephonyout'] && p['cloudtelephonyout'].length) {
			// console.log('cloud telephony', p['cloudtelephonyout'])
			p['cloudtelephonyout'].forEach(element => {
				// console.log('element',element)
				if (element['name'] == 'telco_operator') {
					this.telco_operator = element['definition']
				}
			});
			// console.log('telco_operator', this.telco_operator)
		}

		this.treeFlattener = new MatTreeFlattener(
			this.transformer,
			this.getLevel,
			this.isExpandable,
			this.getChildren
		);
		this.treeControl = new FlatTreeControl<TodoItemFlatNode>(
			this.getLevel,
			this.isExpandable
		);

		this.dataSourceTree = new MatTreeFlatDataSource(
			this.treeControl,
			this.treeFlattener
		);
		_database.dataChange.subscribe(data => {
			// console.log("--" + JSON.stringify(data));
			for (let i in data) {
				data[i] = data[i];
			}
			// console.log("--" + JSON.stringify(data));
			if (this.campaign_upload_show_only_agents) {
				this.agent_view_set_agents(data);
			} else {
				this.dataSourceTree.data = data;
			}
		});
		//end tree
	}

	ngOnInit() {
		this.api_end_point = this.myservice.get_API_End_Point();
		this.pd_dial_ratio = 1;
		this.campaignForm = this.formbuilder.group({
			"name": ['', Validators.required],
			"date": [new Date(), Validators.required],
			"time": [this.current_time, [Validators.required, timeValidator]],
			"master_campaign": [this.master_campaign_field.default_value],
			"custom_message": [this.custom_message_field.default_value],
			"formidName": [''],
			"screenname": ['', Validators.required],
			"priority": ['Server', Validators.required],
			"file": ['', Validators.required],
			"fileSource": ['', Validators.required],
			"campaign_mode": ['WFO'],
			"agent_selected_option": ['web', Validators.required]
		})
		this.myUser = this.service.get_loggedin_user();

		//debugger;
		this._companyName = this.myUser.company;

		this.campaignUpload_msg = '';
		this.getFormList();
		var Stagesettings_details;
		Stagesettings_details = this.myservice.getSettingsWithKeyValue('web', 'name', 'campaign_push');
		if (Stagesettings_details.definition != null) {
			this.dial_mode = JSON.parse(Stagesettings_details.definition);
			//console.log("-----", this.dial_mode);
		}
		try {
			let temp_incallscreen_values = this.myservice.getSettingsWithKeyValue('web', 'name', 'incallscreen');
			if (temp_incallscreen_values) {
				this.incallscreen_atrs.visibility = true
				this.incallscreen_atrs.pre_values = JSON.parse(temp_incallscreen_values['definition']);
			} else {
				this.incallscreen_atrs.visibility = false
			}
		} catch (e) {
			console.log('Error in the incallscreen values', e)
			this.incallscreen_atrs.visibility = false
		}
		// this.dialcheck_mode();
	}
	get _form() { return this.campaignForm.controls }

	/* Agent view Function starts here */
	agent_view_set_agents(actual_team) {
		const users_only = this.myservice.getAllIds('user').map(agent => {
			return {
				"name": agent.name,
				"id": agent.id,
				"email": agent.email,
				"phone": agent.phone,
				"groupid": agent.groupid,
				"role": agent.role,
				"active_status": agent.active_status,
				"business_process": agent.business_process,
				"active_campaign": agent.active_campaign,
				"teamid": agent.teamid,
			}
		})
		// console.log('Only users', JSON.stringify(users_only))
		var modified_team = cloneDeep(actual_team);
		modified_team[0].children = users_only;
		// console.log('modified team', JSON.stringify(modified_team))
		this.dataSourceTree.data = modified_team
		// console.log('agents list', JSON.stringify(this.dataSourceTree.data))
	}

	/* Agent view Function ends here */

	hidePreviousDates = (d: Date | null): boolean => {
		const day = (d || new Date());
		return moment(day).isSameOrAfter(new Date(), 'day');
	}
	download_file() {
		this.API.bulkTemplateAPI(this.api_end_point).subscribe(
			(res: HttpResponse<any>) => {
				this.templateddownloadurl = res.url;
				window.open(this.templateddownloadurl, '_blank');
				return;
			},
			(err) => {
				this._snackBar.open('Error in downloading the file', 'close', {
					duration: 2000
				});
			}
		)
	}

	changeFile(event) {
		this.currentFileobj = {};

		if (event.target.files && event.target.files.length) {
			this.currentFileobj = event.target.files[0];
			const [upload_file] = event.target.files;
			this.campaignForm.patchValue({
				fileSource: upload_file
			});
		}
	}

	findAgent(agentList, agt) {
		for (const obj of agentList) {
			if (obj.name == agt.item) {
				obj.campaign_status = true;
				return obj
			}

		}
	}

	setDialRatio(val) {
		this.pd_dial_ratio = val;
	}


	submit_Camapign(campaignForm: FormGroup) {
		this.showHideAtleastOneAgent = false;
		const agent_option = campaignForm.get('agent_selected_option').value;
		// console.log('Agent selected option', agent_option)
		var selectedAgents = [];
		var selected_nodes = this.checklistSelection.selected.filter(v => { return v.expandable == false });
		for (var i in selected_nodes) {
			let parentNode = this.flatNodeMap.get(selected_nodes[i]);
			if (parentNode && (parentNode.role === 'user')) {
				// console.log('printng parent node', parentNode);
				selectedAgents.push(parentNode);
			}
		}
		if (campaignForm.get('name').value == "") {
			this._snackBar.open("Please enter campaign name*", "close", {
				duration: 2000
			});
			return;
		}

		if (campaignForm.get('fileSource').value == "") {
			this._snackBar.open("Please attached Data file*", "close", {
				duration: 2000
			});
			return;
		}
		if (campaignForm.get('date').value == "") {
			this._snackBar.open("Please select Date*", "close", {
				duration: 2000
			});
			return;
		}
		if (campaignForm.get('campaign_mode').value == "") {
			this._snackBar.open("Please select campaign_mode", "close", {
				duration: 2000
			});
			return;
		}
		/* if (campaignForm.get('master_campaign').value == "") {

			this._snackBar.open("Please select campaign type*", "close");
			return;
		} */
		if ((campaignForm.get('screenname').value == "") && this.incallscreen_atrs.visibility) {
			this._snackBar.open("Please select Incallscreen*", "close");
			return;
		}
		// console.log('dial ration', this.pd_dial_ratio);
		if ((agent_option == 'web') && (selectedAgents.length == 0)) {
			this._snackBar.open('Select at least one agent', 'close', {
				duration: 2000
			});
			return;
		}
		var dial_ratio: any = this.pd_dial_ratio;
		let campaign = campaignForm;
		let priority;
		//let priority;
		for (var i in this.dial_mode.dial_modes) {
			if ((campaign.controls.priority.value == this.dial_mode.dial_modes[i].name) &&
				this.dial_mode.dial_modes[i].dial_ratio &&
				(this.dial_mode.dial_modes[i].dial_ratio.type == "static")) {
				//console.log(this.dial_mode.dial_modes[i].priority);
				// dial_ratio = this.dial_mode.dial_modes[i].dial_ratio.value;
				priority = this.dial_mode.dial_modes[i].priority;
			} else if (campaign.controls.priority.value == this.dial_mode.dial_modes[i].name) {
				//console.log(this.dial_mode.dial_modes[i].priority);
				// dial_ratio = this.dial_mode.dial_modes[i].priority;
				priority = this.dial_mode.dial_modes[i].priority;
			}

		}
		if (priority == undefined || priority == undefined) {
			this._snackBar.open('Please select Dial mode', 'close', {
				duration: 2000
			});
			return;
		}
		//let campaign = campaignForm;
		let agent_list = {};
		let campaign_name = campaign.controls.name.value;
		let date = moment(campaign.controls.date.value).format('YYYY-MM-DD');
		let time = String(campaign.controls.time.value + ':00');
		let full_time = String(date).concat('T', time.toString()).toString();
		//console.log("time :: ",time);
		//console.log("final time :: ",moment(String(date).concat('T', time.toString()).toString()+" current time : "+ moment().format('YYYY-MM-DDTHH:mm')));

		if (moment(String(date).concat('T', time.toString()).toString()).isBefore(moment().format('YYYY-MM-DDTHH:mm'))) {
			this._snackBar.open('Invalid Date time. Choose curent Date & time or future Date & time', 'close', {
				duration: 5000
			});
			return;
		}
		this.campaignUpload_msg = 'File Data checking started';
		this.campaignUpload_msg = 'File Data checking completed';
		this.campaignUpload_msg = 'Start uploading ...';

		let bulk_file = campaign.get('fileSource').value;
		var campaign_mode = campaignForm.get('campaign_mode').value;
		let master_campaign = campaign.get('master_campaign').value;
		//let custom_message = campaign.get('custom_message').value;
		let custom_message = {"title":"","message":`${campaign.get('custom_message').value}`};

		
		// console.log('master campaign value', master_campaign)
		//console.log(campaign.controls.priority.value);
		// let priority;
		// for (var i in this.dial_mode.dial_modes) {
		// 	if ((campaign.controls.priority.value == this.dial_mode.dial_modes[i].name) &&
		// 		this.dial_mode.dial_modes[i].dial_ratio &&
		// 		(this.dial_mode.dial_modes[i].dial_ratio.type == "static")) {
		// 		//console.log(this.dial_mode.dial_modes[i].priority);
		// 		// dial_ratio = this.dial_mode.dial_modes[i].dial_ratio.value;
		// 		priority = this.dial_mode.dial_modes[i].priority;
		// 	} else if (campaign.controls.priority.value == this.dial_mode.dial_modes[i].name) {
		// 		//console.log(this.dial_mode.dial_modes[i].priority);
		// 		// dial_ratio = this.dial_mode.dial_modes[i].priority;
		// 		priority = this.dial_mode.dial_modes[i].priority;
		// 	}

		// }
		agent_list[this.myUser.groupid] = selectedAgents;

		//let _formID = campaign.controls.formidName.value
		let _fileDetails = campaignForm.get('fileSource').value;
		let _fileName = campaignForm.get('fileSource').value.name;
		let fileInput = document.getElementById('fileID') as HTMLInputElement;
		let filePath = campaignForm.get('fileSource').value.name;
		let incallscreen = campaignForm.get('screenname').value;
		//	console.log("----",incallscreen);
		let allowedExtensions = /(\.xlsx)$/i;

		if (!allowedExtensions.exec(filePath)) {
			this._snackBar.open('Please upload .xlsx extensions file only.', 'Close', {
				duration: 2000
			});
			return;
		}
		//console.log(dial_ratio);
		//console.log(this.pd_dial_ratio);
		var dial_ratio: any = this.pd_dial_ratio;
		// console.log(dial_ratio);
		let formData = new FormData();

		formData.append('leads', bulk_file, bulk_file.name);
		formData.append('user_id', this.myUser.id);
		formData.append('campaign_name', campaign_name);
		// console.log('campaign_mode', campaign_mode);
		formData.append('campaign_mode', campaign_mode);
		formData.append('dial_ratio', dial_ratio);
		formData.append('priority', priority);
		formData.append('pushed_date', full_time);
		if ((agent_option == 'web') && (selectedAgents.length > 0)){
			formData.append('agents_list', JSON.stringify(agent_list));
		}
		if (incallscreen) {
			formData.append('incallscreen', incallscreen);
		}
		if (master_campaign) {
			formData.append('master_campaign', master_campaign);
		}
		// if(custom_message){
		// 	formData.append('alerttext', custom_message);
		// }

		// console.log('---Form data', formData)
		// return
		this.showLoader = true;
		this.API.bulkUploadAPI(formData, this.api_end_point).subscribe(
			(res: any) => {
				this.showLoader = false;
				if (res.errors) {
					//alert(res.errors);
					this._snackBar.open(res.errors, 'close');
					return;
				}
				// console.log('success response', res);
				this.campaignUpload_msg = 'Camapign Upload Finished.'
				let uploaded_file = res.url.split('/');
				let file_name = uploaded_file.pop();

				let obj: any = {
					"campaign_id": res.campaign_id,
					"blobfile": file_name,
					"start_date": full_time,
					"maxleadsperday": 500,
					"options": "Drop repeated data",
					"campaign_name": campaign_name,
					"priority": priority,
					"dial_ratio": dial_ratio,
					"campaign_mode": campaign_mode,
					"incallscreen": incallscreen,
					"master_campaign": master_campaign,
					"alerttext": custom_message,					
				}
				if(res.campaign_id){
					obj.campaign_id = res.campaign_id;
				}
				if (agent_option == 'web'){
					obj["groupedagentslist"] = agent_list
				}
				if (priority == 2) {
					obj.dial_ratio = this.pd_dial_ratio;
				}
				this.campaignUpload_msg = 'Start Assigning the data';
				// this.campaign_obj = obj;
				this.showLoader = false;
				this.utils.addDelay(1000)
				this.bulk_push_api_call(obj)
				// setTimeout(this.bulk_push_api_call, obj, 1000)
				// setTimeout(()=>{this.bulk_push_api_call}, 1000, obj);
			}
			, (err) => {
				this.showLoader = false;
				this._snackBar.open('Unknown error. Please try after some time', 'close');
					return;
				//	console.log(err)
			}
		)
	}

	bulk_push_api_call(obj){
		this.showLoader = true;
		this.API.bulkPushAPI(obj, this.api_end_point).subscribe(
			(res) => {
				// console.log('response', res)
				this.campaignUpload_msg = 'Refreshing the campaign data';
				this.dialogRef.close('true');
				this.campaignForm.reset();
				this.showLoader = false;
			}, (err) => {
				this._snackBar.open(err, 'close');
				//console.log('err', err)
				this.showLoader = false;
			}
		)
	}

	validateExcelSheet() {
		let promise = new Promise((resolve, reject) => {
			// console.log(this.currentFileobj);
			let file_path = this.currentFileobj;
			let mime_type = file_path.name.split('.').pop().toLowerCase();
			if (mime_type != 'xlsx') {
				reject('File format is not correct. Use xlsx file');
				return;
			}
			if (!file_path.name) {
				reject('File not found for validations');
				return;
			}
			// debugger;
			const reader: FileReader = new FileReader();
			reader.readAsBinaryString(file_path);
			reader.onload = (e: any) => {
				// debugger;
				/* create workbook */
				const binarystr: string = e.target.result;
				const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
				// debugger;
				/* selected the first sheet */
				const wsname: string = wb.SheetNames[0];
				const ws: XLSX.WorkSheet = wb.Sheets[wsname];
				// debugger;
				/* save data */
				const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
				// console.log(data); // Data will be logged in array format containing objects
				if (data && data.length) {
					// console.log('data to check', data.length);
					let mandatory_keys = ['ASPIRANT NAME (Compulsory)', 'ASPIRANT NUMBER(Compulsory)', 'EMAIL(Compulsory)'];
					let error = null;
					for (var i = 0; i < data.length && !error; i++) {
						let loc = data[i];
						let loc_line = i + 1;
						for (var k in mandatory_keys) {
							if (!loc.hasOwnProperty(mandatory_keys[k])) {
								error = mandatory_keys[k] + " not found at line number " + loc_line;
								break;
							}
							if (typeof loc[mandatory_keys[k]] != 'string') {
								loc[mandatory_keys[k]] = JSON.stringify(loc[mandatory_keys[k]]);
							}
							loc[mandatory_keys[k]] = loc[mandatory_keys[k]].trim();
							switch (mandatory_keys[k]) {
								case mandatory_keys[0]:
									// Looking for name
									if (!loc[mandatory_keys[k]].length) {
										error = "Name not found at line number " + loc_line;
										break;
									}
									if (this.name_pattern.test(loc[mandatory_keys[0]])) {
										error = "Name is not valid " + loc[mandatory_keys[k]] + " at line number " + loc_line;
										break;
									}
									// debugger;
									break;
								case mandatory_keys[1]:
									if (!this.num_pattern.exec(loc[mandatory_keys[1]])) {
										error = "Number is not valid " + loc[mandatory_keys[k]] + " at line number " + loc_line;
										break;
									}
									// debugger;
									break;
								case mandatory_keys[2]:
									if (!this.email_regex.exec(loc[mandatory_keys[2]])) {
										error = "Email is not valid " + loc[mandatory_keys[k]] + " at line number " + loc_line;
										break;
									}
									// debugger;
									break;
							}
						}
					}
					if (error) {
						reject(error);
						return;
					}
					resolve(null);
				}
			};
			// debugger;
		})
		return promise;
	}

	closePopup() {
		this.campaignForm.reset();
		this.dialogRef.close();
	}
	//start tree
	SelectedCountAgent: any;
	flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
	nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
	selectedParent: TodoItemFlatNode | null = null;
	treeControl: FlatTreeControl<TodoItemFlatNode>;
	treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
	dataSourceTree: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
	checklistSelection = new SelectionModel<TodoItemFlatNode>(
		true /* multiple */
	);
	getLevel = (node: TodoItemFlatNode) => node.level;
	hasLevel = (node: TodoItemFlatNode) => node.level === 2;
	isExpandable = (node: TodoItemFlatNode) => node.expandable;
	getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
	hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
	hasChildAndLevel = (_: number, _nodeData: TodoItemFlatNode) =>
		_nodeData.expandable && _nodeData.level >= 2;
	hasNoContent = (_: number, _nodeData: TodoItemFlatNode) =>
		_nodeData.name === "";
	transformer = (node: TodoItemNode, level: number) => {
		const existingNode = this.nestedNodeMap.get(node);
		const flatNode =
			existingNode && existingNode.name === node.name
				? existingNode
				: new TodoItemFlatNode();
		// console.log('node', node)
		flatNode.disabled = false;
		flatNode.email = node.email
		flatNode.name = node.name;
		flatNode.level = level;
		flatNode.role = node.role;
		flatNode.expandable = !!node.children;
		flatNode.business_process = node.business_process;
		flatNode.active_campaign = node.active_campaign;
		flatNode.teamid = node.teamid
		if (flatNode.role == 'user') {
			if (flatNode.business_process && flatNode.business_process.indexOf('DIP') > -1) {
				flatNode.disabled = true;
				flatNode.name += " [DIP] ";
			}
			if (this.telco_operator == 'TTS') {
				if (!node.teamid) {
					flatNode.disabled = true;
				}
			}
		}
		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);
		return flatNode;
	};

	ngAfterViewInit() {
		this.treeControl.expandAll();
	}

	/** Whether all the descendants of the node are selected. */
	descendantsAllSelected(node: TodoItemFlatNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		// console.log('descendants all selected',node.name, descendants)
		const descAllSelected = descendants.map(child => {
			// console.log('child', child.name, this.checklistSelection.isSelected(child))
			return this.checklistSelection.isSelected(child)
		});
		// console.log('descAllSelected array', node.name, descAllSelected)
		return descAllSelected.every(child => child);
	}

	/** Whether part of the descendants are selected */
	descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		// console.log('descendants', node.name, descendants)
		const result = descendants.some(child => this.checklistSelection.isSelected(child));
		// console.log('partial result', result)
		const all_selected = this.descendantsAllSelected(node);
		// console.log('all selected', all_selected)
		return result && !all_selected;
	}

	todoItemSelectionToggle(node: TodoItemFlatNode): void {
		this.checklistSelection.toggle(node);
		const item_selected = this.checklistSelection.isSelected(node)
		// console.log('item_selected', item_selected)
		const descendants = this.treeControl.getDescendants(node).filter(node => !node.disabled);
		// console.log('descendants', descendants)
		this.checklistSelection.isSelected(node)
			? this.checklistSelection.select(...descendants)
			: this.checklistSelection.deselect(...descendants);
		this.checkAllParentsSelection(node);
		// this.descendantsAllSelected(node);
	}

	todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
		this.checklistSelection.toggle(node);
		this.checkAllParentsSelection(node);
	}

	expand_collapse_all_childs(node: TodoItemFlatNode): void {
		// console.log('node', node)
		if (!this.treeControl.isExpanded(node)) {
			this.treeControl.collapseDescendants(node)
		} else {
			this.treeControl.expandDescendants(node);

		}
	}

	checkAllParentsSelection(node: TodoItemFlatNode): void {
		let parent: TodoItemFlatNode | null = this.getParentNode(node);
		// console.log('parent', parent)
		while (parent) {
			this.checkRootNodeSelection(parent);
			parent = this.getParentNode(parent);
		}
	}

	checkRootNodeSelection(node: TodoItemFlatNode): void {
		// console.log('root node selected', node);
		const nodeSelected = this.checklistSelection.isSelected(node);
		const descendants = this.treeControl.getDescendants(node);
		const descAllSelected_arr = descendants.map(child => this.checklistSelection.isSelected(child));
		const descAllSelected = descAllSelected_arr.every(child => child)
		if (nodeSelected && !descAllSelected) {
			this.checklistSelection.deselect(node);
		} else if (!nodeSelected && descAllSelected) {
			this.checklistSelection.select(node);
		}
	}
	getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
		const currentLevel = this.getLevel(node);
		//console.log(currentLevel);
		if (currentLevel < 1) {
			return null;
		}

		const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

		for (let i = startIndex; i >= 0; i--) {
			const currentNode = this.treeControl.dataNodes[i];

			if (this.getLevel(currentNode) < currentLevel) {
				//console.log(currentNode);
				return currentNode;
			}
		}
		return null;
	}

	getSelected = () => this.checklistSelection.selected.map(f => f.name);
	//end tree
	getSelectedCount() {
		let _countAgent = this.checklistSelection.selected.filter(v => { return v.expandable == false });
		// console.log('printing count Agents', _countAgent);
		this.SelectedCountAgent = _countAgent.length;
		return this.SelectedCountAgent;
	}
	omit_special_char(event) {
		let k;
		k = event.charCode;  // k = event.keyCode; (Both can be used)
		if (k == 95) {
			return true;
		}
		return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
	}
	getFormList() {
		this.API.getCallForms(this.myUser.id, this.api_end_point).subscribe((d: any) => {
			if (d.success) {
				this._formlist = d.success;
			}
		})
	}

	//assign later
	assign_later(campaignForm: FormGroup) {		
		 //debugger;
		this.showHideAtleastOneAgent = false;
		var selectedAgents = [];
		const agent_option = campaignForm.get('agent_selected_option').value;
		var selected_nodes = this.checklistSelection.selected.filter(v => { return v.expandable == false });
		// console.log('printing agents list', agentList);
		for (var i in selected_nodes) {
			let parentNode = this.flatNodeMap.get(selected_nodes[i]);
			if (parentNode && (parentNode.role === 'user')) {
				// console.log('printng parent node', parentNode);
				selectedAgents.push(parentNode);
			}
		}
		
		if (campaignForm.get('name').value == "") {

			this._snackBar.open("Please enter campaign name*", "close", {
				duration: 2000
			});
			return;
		}

		if (campaignForm.get('fileSource').value == "") {

			this._snackBar.open("Please attached Data file*", "close", {
				duration: 2000
			});
			return;
		}
		if (campaignForm.get('date').value == "") {

			this._snackBar.open("Please select Date*", "close", {
				duration: 2000
			});
			return;
		}
		if (campaignForm.get('campaign_mode').value == "") {

			this._snackBar.open("Please select campaign_mode", "close", {
				duration: 2000
			});
			return;
		}
		if ((campaignForm.get('screenname').value == "") && this.incallscreen_atrs.visibility) {
			this._snackBar.open("Please select Form*", "close");
			return;
		}
		if ((agent_option == 'web') && (selectedAgents.length == 0)) {
			this._snackBar.open('Select at least one agent', 'close', {
				duration: 2000
			});
			return;
		}
		var dial_ratio: any = this.pd_dial_ratio;
		let campaign = campaignForm;
		let priority;
		//let priority;
		for (var i in this.dial_mode.dial_modes) {
			if ((campaign.controls.priority.value == this.dial_mode.dial_modes[i].name) &&
				this.dial_mode.dial_modes[i].dial_ratio &&
				(this.dial_mode.dial_modes[i].dial_ratio.type == "static")) {
				//console.log(this.dial_mode.dial_modes[i].priority);
				// dial_ratio = this.dial_mode.dial_modes[i].dial_ratio.value;
				priority = this.dial_mode.dial_modes[i].priority;
			} else if (campaign.controls.priority.value == this.dial_mode.dial_modes[i].name) {
				//console.log(this.dial_mode.dial_modes[i].priority);
				// dial_ratio = this.dial_mode.dial_modes[i].priority;
				priority = this.dial_mode.dial_modes[i].priority;
			}

		}
		if (priority == undefined || priority == undefined) {
			this._snackBar.open('Please select Dial mode', 'close', {
				duration: 2000
			});
			return;
		}
		// console.log('printing selected agents', selectedAgents);
		this.campaignUpload_msg = 'File Data checking started';
		this.campaignUpload_msg = 'File Data checking completed';
		this.campaignUpload_msg = 'Start uploading ...';
		
		let agent_list = {};
		let campaign_name = campaign.controls.name.value;
		let date = moment(campaign.controls.date.value).format('YYYY-MM-DD');
		let time = String(campaign.controls.time.value + ':00');
		let full_time = String(date).concat('T', time.toString()).toString();
		let bulk_file = campaign.get('fileSource').value;
		let work_mode = campaignForm.get('campaign_mode').value;
		let master_campaign = campaign.get('master_campaign').value;
		let custom_message = campaign.get('custom_message').value;
		let incallscreen = campaign.get('screenname').value;
		//let dial_ratio;
		
		
		// for (var i in this.dial_mode.dial_modes) {
		// 	if (campaign.controls.priority.value == this.dial_mode.dial_modes[i].name && 
		// 		this.dial_mode.dial_modes[i].dial_ratio.type == "static") {
		// 		//console.log(this.dial_mode.dial_modes[i].priority);
		// 		dial_ratio = this.dial_mode.dial_modes[i].dial_ratio.value;
		// 		priority = this.dial_mode.dial_modes[i].priority;
		// 	} else if (campaign.controls.priority.value == this.dial_mode.dial_modes[i].name) {
		// 		//console.log(this.dial_mode.dial_modes[i].priority);
		// 		dial_ratio = this.dial_mode.dial_modes[i].priority;
		// 		priority = this.dial_mode.dial_modes[i].priority;
		// 	}

		// }
		agent_list[this.myUser.groupid] = selectedAgents;
		let filePath = campaignForm.get('fileSource').value.name;
		//console.log(campaignForm.get('fileSource').value.name);
		let allowedExtensions = /(\.xlsx)$/i;

		if (!allowedExtensions.exec(filePath)) {
			this._snackBar.open('Please upload .xlsx extensions file only.', 'Close', {
				duration: 2000
			});
			return;
		}
		this.showLoader = true;
		let formData = new FormData();
		formData.append('leads', bulk_file, bulk_file.name);
		formData.append('user_id', this.myUser.id);
		formData.append('campaign_name', campaign_name);
		formData.append('campaign_mode', work_mode);
		formData.append('dial_ratio', dial_ratio);
		formData.append('priority', priority);
		formData.append('pushed_date', full_time);
		//formData.append('incallscreen', incallscreen);
		formData.append('push_calltype', 'promotional');
		if ((agent_option == 'web') && (selectedAgents.length > 0)){
			formData.append('agents_list', JSON.stringify(agent_list));
		}
		if (incallscreen) {
			formData.append('incallscreen', incallscreen);
		}
		if (master_campaign) {
			formData.append('master_campaign', master_campaign);
		}
		formData.append('custom_message', custom_message);
		this.API.bulkUploadAPI(formData, this.api_end_point).subscribe(
			(res: any) => {
				if (res.errors) {
					this._snackBar.open(res.errors, 'close');
					//alert(res.errors);
					return;
				}
				this._snackBar.open('Camapign Upload Finished.', 'close', {
					duration: 2000
				});
				this.dialogRef.close('true');
				this.campaignForm.reset();
				this.showLoader = false;
			})
	}
	conditoncheck(obj) {
		var user = obj;
		if (user.active_campaign != "" && user.role == "user") {
			return true;
		} else {
			return false;
		}
	}
	onChange(evn) {
		// console.log(evn);
		this.filter(evn);
	}
	filter(select_option) {
		let selectoption = select_option.split(' ');
		const clonedTreeLocal = cloneDeep(this.dataSourceTree.data);
		this.recursiveNodeEliminator(clonedTreeLocal, selectoption[1]);
		this.dataSourceTree.data = clonedTreeLocal;
	}
	recursiveNodeEliminator(tree: Array<TodoItemNode>, data_select): boolean {
		for (let index = tree.length - 1; index >= 0; index--) {
			const node = tree[index];
			if (node.children) {
				const parentCanBeEliminated = this.recursiveNodeEliminator(node.children, data_select);
				if (parentCanBeEliminated) {
					if (node.work_option.toLocaleLowerCase() == data_select.toLocaleLowerCase()) {
						tree.splice(index, 1);
					}
				}
			} else {
				// Its a leaf node. No more branches.
				if (node.work_option.toLocaleLowerCase() == data_select.toLocaleLowerCase()) {
					console.log(node);
					tree.splice(index, 1);
				}
			}
		}
		return tree.length === 0;
	}

	beautify_active_campaign(active_campaign?: string) {
		// console.log('active campaign', active_campaign)
		try {
			if (active_campaign) {
				const reg = /['"]/g
				let campaigns = active_campaign.replace("'',", '').replace(reg, '').split(',')
				return campaigns.join(', ')
			}
		} catch (err) {
		}
		return
	}
}
//strat tree
export class TodoItemNode {
	children?: TodoItemNode[];
	name: string;
	id: number | string;
	groupid: number | string;
	role: string;
	business_process: string;
	active_status: any;
	active_campaign: string;
	work_option: string;
	email: string;
	teamid: number | string;
}

export class TodoItemFlatNode {
	name: string;
	level: number;
	expandable: boolean;
	role: string;
	disabled: boolean;
	business_process: string;
	active_campaign: string;
	work_option: string;
	email: string;
	teamid: number | string;
}

