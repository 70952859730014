import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orchestration-sms-template',
  templateUrl: './orchestration-sms-template.component.html',
  styleUrls: ['./orchestration-sms-template.component.scss']
})
export class OrchestrationSmsTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
