
<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">
     <mat-card>
         <h2 class="d-head">AUDITORS<img src="../assets/underline.svg"
            class="linebelow"> </h2>

<div class="searchFix">
    <mat-form-field style="font-size: 12px;">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH">
        <i class="fa fa-search search-icon" matSuffix style="position: relative;
        top: -2px;"></i>
      </mat-form-field>
     
</div>
<br>
<div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Auditors </th>
        <td mat-cell *matCellDef="let element">{{element.name}} </td>
      </ng-container>
          <!-- <ng-container matColumnDef="rating">
              <th mat-header-cell *matHeaderCellDef> Rating </th>
              <td mat-cell *matCellDef="let element">
                <div id="bloc1"> {{element.rating | number:'1.1-1'}}</div>  
                <div id="bloc2"><star-rating-comp [starType]="'icon'" [size]="'medium'" [readOnly]="'true'" [rating]="element.rating==null?0:element.rating"></star-rating-comp></div>
               <div id="bloc3" >/ 100</div> 
              </td>
            </ng-container>-->
<ng-container matColumnDef="project_name">
  <th mat-header-cell *matHeaderCellDef>Project </th>
  <td mat-cell *matCellDef="let element">{{element.project_name || "NA"}} </td>
</ng-container>

<ng-container matColumnDef="cx_touch_point">
  <th mat-header-cell *matHeaderCellDef> CX-Type </th>
  <td mat-cell *matCellDef="let element" > {{element.cx_touch_point || "NA"}} </td>
</ng-container>
<ng-container matColumnDef="totalauditor">
    <th mat-header-cell *matHeaderCellDef> Total Auditor </th>
    <td mat-cell *matCellDef="let element"> {{element.totalauditor}} </td>
  </ng-container>
<ng-container matColumnDef="payment_type">
  <th mat-header-cell *matHeaderCellDef> Payment</th>
  <td mat-cell *matCellDef="let element" > --<!--{{element.payment_type || "NA"}}--> </td>
</ng-container>

<ng-container matColumnDef="earned">
    <th mat-header-cell *matHeaderCellDef>Earned </th>
    <td mat-cell *matCellDef="let element"> --<!--{{element.earned}}--> </td>
  </ng-container>

<ng-container matColumnDef="received">
    <th mat-header-cell *matHeaderCellDef> Received </th>
    <td mat-cell *matCellDef="let element"> --<!--{{element.received}}--> </td>
  </ng-container>
  
<ng-container matColumnDef="balance">
    <th mat-header-cell *matHeaderCellDef> Balance</th>
    <td mat-cell *matCellDef="let element"> --<!--{{element.balance}}--> </td>
  </ng-container>
  


  <ng-container matColumnDef="lastPay">
    <th mat-header-cell *matHeaderCellDef> Last Pay </th>
    <td mat-cell *matCellDef="let element"> --<!--{{element.lastPay}}-->
     </td>
  </ng-container>

  <!-- <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> 
          <mat-icon class="AMclr CR" aria-hidden="false" aria-label="">edit</mat-icon> </td>
  </ng-container> -->

<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
</table>
<mat-paginator  [ngClass]="'auditorPage'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
            
</mat-card>
</div>
</div>

