<div class="v-mainDCDiv desktop-calling-new-page">
  <div class="row mb-4">
    <div class="col-md-9">
      <!-- <div class="mt-3">
                <mat-card class="v-dcCard1">
                    <div class="row">
                        <div class="col-md-4">
                            <table class="vDCTable1">
                                <tr>
                                    <td><img src="../assets/desktop_calling/dcLogo1.png" class="v-dc-imgIcon" /> </td>
                                    <td><span class="v-dcSpan1 vLClr">{{_countLeadLength}} </span> <span
                                            class="v-dcSpan2">Leads
                                            Contacted </span>
                                        
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-4">
                            <table class="vDCTable1">
                                <tr>
                                    <td><img src="../assets/desktop_calling/dcLogo2.png" class="v-dc-imgIcon" /> </td>
                                    <td><span class="v-dcSpan1 vFupClr">{{_countfollowUpLength}} </span> <span
                                            class="v-dcSpan2">Follow -
                                            ups to do </span> </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-4">
                            <table class="vDCTable2">
                                <tr>
                                    <td><img src="../assets/desktop_calling/dcLogo3.png" class="v-dc-imgIcon" /> </td>
                                    <td><span class="v-dcSpan1 vFldFClr">{{countoffilleddata}} </span> <span
                                            class="v-dcSpan2">Filled Forms
                                        </span> </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </mat-card>
            </div> -->
      <mat-card class="v-dcCard1 mt-3 h-100 pt-3">
        <p><span class="v-dcTitle1">Today’s</span></p>
        <div class="row">
          <div class="col-md-3" *ngIf="_logintime && _logintime.length > 0">
            <table class="vDCTable3">
              <tr>
                <td>
                  <span class="v-dcSpan1 vLgTClr" *ngIf="_logintime">
                    {{ _logintime }}</span
                  >
                  <span class="v-dcSpan2">Login Time </span>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-3">
            <table class="vDCTable3">
              <tr>
                <td>
                  <span class="v-dcSpan1 vTkTmClr" *ngIf="_talktime">
                    {{ _talktime }}</span
                  >
                  <span class="v-dcSpan2"> Talk Time</span>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-3">
            <table class="vDCTable3">
              <tr>
                <td>
                  <span class="v-dcSpan1 vBrkTmClr" *ngIf="_waittime"
                    >{{ _waittime }}
                  </span>
                  <span class="v-dcSpan2"> Wait Time</span>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-3">
            <table class="vDCTable2">
              <tr>
                <td>
                  <span class="v-dcSpan1 vActTmClr" *ngIf="_activetime">
                    {{ _activetime }}</span
                  >
                  <span class="v-dcSpan2">Active Time </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </mat-card>
    </div>

    <div class="col-md-3 pl-0">
      <mat-card class="v-dcCard1 mt-3 h-100 pt-3 pb-3 pl-1 pr-1 vMinHtStart">
        <div class="col-md-12">
          <div *ngIf="showHideStartPage">
            <!-- <p class="text-center" *ngIf="!sessionStarted"><img
                                src="../assets/desktop_calling/v-Start-Logo.png" class="vStartLogo1" /></p> -->
            <p class="text-center mb-1">
              <button
                class="v-Start-Dial-Btn m-0"
                (click)="startSession()"
                *ngIf="!sessionStarted && !showHideStopPageForAllowinBound"
                [disabled]="start_button_disabled()"
              >
                <mat-icon class="vCallIcon1"> call</mat-icon>START
              </button>
            </p>
            <p
              class="text-center m-0"
              *ngIf="!sessionStarted && call_button_status == 1 && call_button_status_socket && !showHideStopPageForAllowinBound"
            >
              <span class="v-dcSpan3"> Click Start For Calling</span>
            </p>
            <p class="text-center m-0" *ngIf="!call_button_status_socket">
              <span class="v-dcSpan3_disabled">Required setup not available. Please contact administrator.</span>
            </p>
            <p class="text-center m-0" *ngIf="call_button_status_socket">
              <span class="v-dcSpan3_disabled">{{ display_button_text() }}</span>
            </p>
          </div>
          <!-- <div *ngIf="showHideStopPage">
                        <p *ngIf="sessionStarted" class="text-center"><img src="../assets/desktop_calling/v-Stop-Logo.png"
                                class="vStartLogo1" /></p>
                        <p class="text-center mb-1">
                            <button class="v-Stop-Dial-Btn" (click)="endSession()" *ngIf="sessionStarted">
                                <mat-icon class="vCallIcon1"> stop_circle</mat-icon>STOP
                            </button>
                        </p>
                        <p *ngIf="sessionStarted" class="text-center"> <span class="v-dcSpan3"> Click here to end session</span></p>
                    </div> -->

          <div *ngIf="showHideStopPageForAllowinBound">
            <p class="text-center">
              <!-- <img
                src="../assets/desktop_calling/v-Stop-Logo.png"
                class="vStartLogo1"
              /> -->
            </p>
            <p class="text-center mb-1">
              <button
                class="v-Stop-Dial-Btn"
                (click)="endSessionForAllowinBound()"
              >
                <mat-icon class="vCallIcon1"> stop_circle</mat-icon>STOP
              </button>
            </p>
            <p class="text-center">
              <span class="v-dcSpan3"> Click here to end session</span>
            </p>
          </div>
          <div *ngIf="isStageDial">
            <p class="text-center m-0" (click)="openDialogDialerDialog()">
              <img
                src="../assets/desktop_calling/dcLogo1.png"
                class="v-dc-imgIcon"
              />
            </p>
          </div>
        </div>
      </mat-card>
      <!-- <div *ngIf="isStageDial" class="mt-3">
                <mat-card class="v-dcCard1 vHt1" style="padding: 10px;">


                    <div class="text-center">
                        <table style="width:100%">
                            <tr>
                                <td colspan="3">
                                    <div class="output mb-3" style="height: 70px;">
                                        <input id="codeDialPad" class="numInput"
                                            [disabled]="showHideDiallerPage? true:false" autocomplete="off"
                                            maxlength="10" (keypress)="phoneInputPress($event)"
                                            (paste)="pasteTextPhone($event)">
                                        <span class="v-cnName" *ngIf="!showHideDiallerPage">Contact Number</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td> <button class="v-digit" id="one" (click)="clickNumBtn(1)"
                                        [disabled]="showHideDiallerPage? true:false"> 1</button> </td>
                                <td> <button class="v-digit" id="two" (click)="clickNumBtn(2)"
                                        [disabled]="showHideDiallerPage? true:false"> 2</button></td>
                                <td> <button class="v-digit" id="three" (click)="clickNumBtn(3)"
                                        [disabled]="showHideDiallerPage? true:false"> 3</button> </td>
                            </tr>
                            <tr>
                                <td> <button class="v-digit" id="four" (click)="clickNumBtn(4)"
                                        [disabled]="showHideDiallerPage? true:false"> 4</button> </td>
                                <td> <button class="v-digit" id="five" (click)="clickNumBtn(5)"
                                        [disabled]="showHideDiallerPage? true:false"> 5</button> </td>
                                <td> <button class="v-digit" id="six" (click)="clickNumBtn(6)"
                                        [disabled]="showHideDiallerPage? true:false"> 6</button></td>
                            </tr>
                            <tr>
                                <td> <button class="v-digit" id="seven" (click)="clickNumBtn(7)"
                                        [disabled]="showHideDiallerPage? true:false"> 7</button></td>
                                <td> <button class="v-digit" id="eight" (click)="clickNumBtn(8)"
                                        [disabled]="showHideDiallerPage? true:false"> 8</button></td>
                                <td> <button class="v-digit" id="nine" (click)="clickNumBtn(9)"
                                        [disabled]="showHideDiallerPage? true:false"> 9</button></td>
                            </tr>
                            <tr>
                                <td> <button class="v-digit" [disabled]="showHideDiallerPage? true:false"> <span
                                            style="font-size: 40px;position:relative;top:8px;">* </span></button> </td>
                                <td> <button class="v-digit" (click)="clickNumBtn(0)"
                                        [disabled]="showHideDiallerPage? true:false"> 0</button> </td>
                                <td> <button class="v-digit" [disabled]="showHideDiallerPage? true:false">
                                        #</button> </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>

                                    <mat-icon (click)="checkCustomerName()" class="vDigitCall"
                                        [ngClass]="showHideDiallerPage?'vDigitCall1':'vDigitCall'"
                                        [disabled]="showHideDiallerPage? true:false">call</mat-icon>

                                </td>
                                <td> <button class="v-digit" [disabled]="!showHideStopPage? true:false"
                                        style="border: none;box-shadow: none;">
                                        <img src="../assets/desktop_calling/closeDigitImg.png" class="closeDigit"
                                            (click)="removeNum()" [ngClass]="!showHideStopPage?'cur1':'cur2'" />
                                    </button> </td>
                            </tr>
                        </table>
                    </div>
                </mat-card>
            </div> -->
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <div class="mt-3">
        <mat-card class="dcRowDiv2_date_selector">
          <div class="dcRowDiv2">
            <div class="dc-labelGroup">
              <!-- <label>Select</label> -->
              <p>
                <mat-select
                  (selectionChange)="LeadFilter($event.value)"
                  placeholder="Select"
                  [(value)]="leadFilter"
                  class="ddlLeaddate"
                >
                  <mat-option value="TODAY">Today</mat-option>
                  <mat-option value="YESTERDAY">Yesterday</mat-option>
                  <mat-option value="ALL_IN_PAST">All in Past</mat-option>
                  <mat-option value="ALL_IN_FUTURE" *ngIf="selectedTab == 1"
                    >All in Future</mat-option
                  >
                  <mat-option value="CHOOSE_A_DATE" *ngIf="selectedTab == 1"
                    >Choose A Date</mat-option
                  >
                </mat-select>
              </p>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="mt-3">
        <mat-card class="v-dcCard1 vHt1">
          <div class="dcRow1">
            <div class="dcRowDiv1">
              <!-- <div class="row">
                                <div class="col-md-3">
                                    <table >
                                        <tr>
                                            
                                            <td><span class="v-dcSpan1 vLClr">{{_countLeadLength}} </span> 
                                                
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-3">
                                    <table >
                                        <tr>
                                            
                                            <td><span class="v-dcSpan1 vFupClr">{{_countfollowUpLength}} </span>  </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-2">
                                    <table class="vDCTable2">
                                        <tr>
                                            
                                            <td><span class="v-dcSpan1 vFldFClr">{{countoffilleddata}} </span>  </td>
                                        </tr>
                                    </table>
                                </div>
                            </div> -->
              <mat-tab-group
                [ngClass]="'vDcTab'"
                (selectedTabChange)="onTabChanged($event)"
              >
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="d-flex flex-column">
                      <span class="v-dcSpan1 vLClr"
                        >{{ _countLeadLength }}
                      </span>
                      Leads Contacted
                    </div>
                  </ng-template>
                  <div class="text-right">
                    <mat-form-field>
                      <input
                        matInput
                        (keyup)="applyFilterLeadContacted($event.target.value)"
                        #filterLeadName
                        placeholder="Search"
                      />
                      <i
                        matSuffix
                        class="fa fa-search searchicon"
                        aria-hidden="true"
                      ></i>
                    </mat-form-field>
                  </div>

                  <mat-table [dataSource]="dataSource" class="v-dcMatTable v1x">
                    <!--[dataSource]="dataSource"-->

                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef
                        >Name
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        >{{ element.name }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lead_source">
                      <mat-header-cell *matHeaderCellDef
                        >Lead Source
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        >{{ element.lead_source }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: 0 0 120px"
                      >
                        Status
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 120px"
                      >
                        {{ element.stage }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="dateandtime">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: 0 0 150px"
                      >
                        Date & Time
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 150px"
                        >{{
                          element.start_time
                            | slice : 0 : 10
                            | date : "dd-MMM-yyyy"
                        }}&ensp;{{ element.start_time | date : "HH:mm " }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="recording">
                      <mat-header-cell *matHeaderCellDef style="flex: 0 0 85px"
                        >Recording
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 85px"
                      >
                        <div *ngIf="element.url">
                          <mat-icon
                            mat-list-icon
                            class="play_icon"
                            (click)="selectCall(element)"
                          >
                            play_circle_filled</mat-icon
                          >
                        </div>
                        <p *ngIf="!element.url" style="margin: 0px 0 0 0">
                          None
                        </p>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="filledform">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: 0 0 100px"
                      >
                        Filled Form
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 100px"
                      >
                        <!-- <button mat-button (click)="filledRowRecord(element)">click</button> -->
                        <button
                          mat-button
                          (click)="contactNameDetails(element)"
                        >
                          VIEW
                        </button>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="call">
                      <mat-header-cell *matHeaderCellDef style="flex: 0 0 85px"
                        >Call</mat-header-cell
                      >
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 85px"
                      >
                        <span
                          class="play_icon"
                          [ngClass]="{ callbutton: isActive('element.number') }"
                          (click)="
                            callCustomer(
                              element.number,
                              element,
                              'availableC2C'
                            )
                          "
                          ><i class="fas fa-phone-alt"></i
                        ></span>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row
                      *matHeaderRowDef="displayedColumns"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="let row; columns: displayedColumns"
                    ></mat-row>
                  </mat-table>
                  <mat-paginator
                    #TableOnePaginator
                    [pageSizeOptions]="[5, 10, 25, 100]"
                  >
                  </mat-paginator>
                </mat-tab>

                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="d-flex flex-column">
                      <span class="v-dcSpan1 vFupClr"
                        >{{ _countfollowUpLength }}
                      </span>
                      Follow - ups to do
                    </div>
                  </ng-template>

                  <div class="text-right mt-4">
                    <mat-form-field *ngIf="isShowDateForFollowUp" class="pr-2">
                      <mat-label>Choose a date</mat-label>
                      <input
                        matInput
                        [min]="minDate1"
                        [matDatepicker]="pickerChooseDateFollowUp"
                        (dateChange)="handleDateChange($event)"
                        (click)="pickerChooseDateFollowUp.open()"
                        readonly
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerChooseDateFollowUp"
                      ></mat-datepicker-toggle>
                      <mat-datepicker
                        #pickerChooseDateFollowUp
                      ></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                      <input
                        matInput
                        (keyup)="applyFilterFollowUp($event.target.value)"
                        #filterFollowUpName
                        placeholder="Search"
                      />
                      <i
                        matSuffix
                        class="fa fa-search searchicon"
                        aria-hidden="true"
                      ></i>
                    </mat-form-field>
                  </div>
                  <mat-table
                    [dataSource]="followupSource"
                    class="v-dcMatTable v2x"
                  >
                    <!--[dataSource]="dataSource"-->

                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef style="flex: 0 0 150px"
                        >Name
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 150px"
                        >{{ element.name }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lead_source">
                      <mat-header-cell *matHeaderCellDef style="flex: 0 0 140px"
                        >Lead Source
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 140px"
                      >
                        {{ element.lead_source }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: 0 0 100px"
                      >
                        Status
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 100px"
                      >
                        {{ element.stage }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="dateandtime">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: 0 0 150px"
                      >
                        Date & Time
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 150px"
                      >
                        {{
                          element.start_date
                            | slice : 0 : 10
                            | date : "dd-MMM-yyyy"
                        }}&ensp;{{
                          element.start_date | date : "HH:mm"
                        }}</mat-cell
                      >
                    </ng-container>

                    <ng-container matColumnDef="recording">
                      <mat-header-cell *matHeaderCellDef style="flex: 0 0 85px"
                        >Recording
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 85px"
                      >
                        <div *ngIf="element.url">
                          <mat-icon
                            mat-list-icon
                            class="play_icon"
                            (click)="selectCall(element)"
                          >
                            play_circle_filled</mat-icon
                          >
                        </div>
                        <p *ngIf="!element.url">None</p>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="call">
                      <mat-header-cell *matHeaderCellDef style="flex: 0 0 80px"
                        >Call
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 80px"
                      >
                        <span
                          class="play_icon"
                          [ngClass]="{ callbutton: isActive('element.number') }"
                          (click)="
                            callCustomer(
                              element.number,
                              element,
                              'NoNeedStartForC2C'
                            )
                          "
                          ><i class="fas fa-phone-alt"></i
                        ></span>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row
                      *matHeaderRowDef="displayedColumns2"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="let row; columns: displayedColumns2"
                    ></mat-row>
                  </mat-table>
                  <mat-paginator
                    #TableThreePaginator
                    [pageSizeOptions]="[5, 10, 25, 100]"
                  >
                  </mat-paginator>
                </mat-tab>

                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="d-flex flex-column">
                      <span class="v-dcSpan1 vFldFClr"
                        >{{ countoffilleddata }}
                      </span>
                      Filled Forms
                    </div>
                  </ng-template>

                  <div class="text-right">
                    <mat-form-field>
                      <input
                        matInput
                        (keyup)="applyFilterFilledSource($event.target.value)"
                        #filterFilledName
                        placeholder="Search"
                      />
                      <i
                        matSuffix
                        class="fa fa-search searchicon"
                        aria-hidden="true"
                      ></i>
                    </mat-form-field>
                  </div>
                  <mat-table
                    [dataSource]="filledSource"
                    class="v-dcMatTable v3x"
                  >
                    <!-- Position Column -->
                    <ng-container matColumnDef="name">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: 0 0 220px"
                      >
                        Name
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 220px"
                      >
                        {{ element.name }}
                      </mat-cell>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="lead_source">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: 0 0 225px"
                      >
                        lead_source
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 225px"
                      >
                        {{ element.lead_source }}
                      </mat-cell>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="dateandtime">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: 0 0 150px"
                      >
                        Date&Time
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 150px"
                      >
                        {{
                          element.updated_at
                            | slice : 0 : 10
                            | date : "dd-MMM-yyyy"
                        }}&ensp;{{
                          element.updated_at
                            | date
                              : "h:mm
                                                a"
                        }}</mat-cell
                      >
                    </ng-container>

                    <!-- <ng-container matColumnDef="comment" >
										<mat-header-cell *matHeaderCellDef style="flex: 0 0 100px;"> comment
										</mat-header-cell>
										<mat-cell *matCellDef="let element" style="flex: 0 0 100px;">
											 <button mat-button (click)="filledRowRecord(element)">click</button>
										</mat-cell>
									</ng-container> -->
                    <ng-container matColumnDef="filledform">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: 0 0 100px"
                      >
                        Filled Form
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 100px"
                      >
                        <!-- <button mat-button (click)="filledRowRecord(element)">click</button> -->
                        <button
                          mat-button
                          (click)="contactNameDetails(element)"
                        >
                          VIEW
                        </button>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row
                      *matHeaderRowDef="displayedColumns1"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="let row; columns: displayedColumns1"
                    ></mat-row>
                  </mat-table>
                  <mat-paginator
                    #TableTwoPaginator
                    [pageSizeOptions]="[5, 10, 25, 100]"
                  >
                  </mat-paginator>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="d-flex flex-column">
                      <span class="v-dcSpan1 text-white">0</span>
                      Lead Fulfillment
                    </div>
                  </ng-template>

                  <div class="text-right">
                    <mat-form-field>
                      <input
                        matInput
                        (keyup)="
                          applyFilterFulfillmentSource($event.target.value)
                        "
                        #filterFulfilledName
                        placeholder="Search"
                      />
                      <i
                        matSuffix
                        class="fa fa-search searchicon"
                        aria-hidden="true"
                      ></i>
                    </mat-form-field>
                  </div>

                  <mat-table
                    [dataSource]="leadFulfillmentSource"
                    class="v-dcMatTable v1x"
                  >
                    <!--[dataSource]="dataSource"-->

                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef style="flex: 0 0 180px"
                        >Name
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 180px"
                        >{{ element.name }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lead_source">
                      <mat-header-cell *matHeaderCellDef style="flex: 0 0 180px"
                        >Lead Source
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 180px"
                        >{{ element.lead_source }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: 0 0 180px"
                      >
                        Status
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 180px"
                      >
                        {{ element.stage }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="dateandtime">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: 0 0 180px"
                      >
                        Date & Time
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 180px"
                        >{{
                          element.start_time
                            | slice : 0 : 10
                            | date : "dd-MMM-yyyy"
                        }}&ensp;{{ element.start_time | date : "HH:mm " }}
                      </mat-cell>
                    </ng-container>

                    <!-- <ng-container matColumnDef="recording">
                                            <mat-header-cell *matHeaderCellDef style="flex: 0 0 85px;">Recording
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" style="flex: 0 0 85px;">
                                                <div *ngIf="element.url">
                                                    <mat-icon mat-list-icon class="play_icon"
                                                        (click)="selectCall(element)">
                                                        play_circle_filled</mat-icon>
                                                </div>
                                                <p *ngIf="!element.url" style="margin: 0px 0 0 0;">None</p>
                                            </mat-cell>
                                        </ng-container> -->
                    <ng-container matColumnDef="filledform">
                      <mat-header-cell
                        *matHeaderCellDef
                        style="flex: 0 0 180px"
                      >
                        Filled Form
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 180px"
                      >
                        <!-- <button mat-button (click)="filledRowRecord(element)">click</button> -->
                        <button
                          mat-button
                          (click)="contactNameDetails(element)"
                        >
                          VIEW
                        </button>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="call">
                      <mat-header-cell *matHeaderCellDef style="flex: 0 0 85px"
                        >Call</mat-header-cell
                      >
                      <mat-cell
                        *matCellDef="let element"
                        style="flex: 0 0 85px"
                      >
                        <span
                          class="play_icon"
                          [ngClass]="{ callbutton: isActive('element.number') }"
                          (click)="
                            callCustomer(
                              element.number,
                              element,
                              'availableC2C'
                            )
                          "
                          ><i class="fas fa-phone-alt"></i
                        ></span>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row
                      *matHeaderRowDef="displayedColumnsLeadFulFilled"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="
                        let row;
                        columns: displayedColumnsLeadFulFilled
                      "
                    ></mat-row>
                  </mat-table>
                  <mat-paginator
                    #TableFourPaginator
                    [pageSizeOptions]="[5, 10, 25, 100]"
                  >
                  </mat-paginator>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <br /><br />
</div>
<audio #stream id="remoteAudio" controls style="display: contents">
  <p>Your browser now supports streaming</p>
</audio>
<div class="dc-popupoverlay11" *ngIf="showHidePopup">
  <div class="dc-delete-popup11">
    <p><span class="v-dcTitle1">Update Details</span></p>
    <div>
      <mat-form-field style="width: 100%" [ngClass]="'vUpDet'">
        <input
          matInput
          placeholder="Enter Name"
          class="vUpDetailsInput"
          (keypress)="onlyNumAlphabetValidation($event)"
          [(ngModel)]="customer_name_entered"
          autocomplete="off"
        />
      </mat-form-field>
    </div>
    <p class="text-right mt-3 mb-1">
      <button class="dcClear1" (click)="cancelUpdateBtn()">Cancel</button>
      <button class="dcClear1" (click)="clearBtn()">Clear</button>
      <button class="dcUpdate1" (click)="startCallLoadingUpdate()">
        Update
      </button>
    </p>
  </div>
</div>
<!-- <div class="dc-popupoverlay11" *ngIf="showHidePopup1">
  <div class="dc-delete-popup11">
      <p> <span class="v-dcTitle1">Show Details</span> </p>
      <div>
      <p class="text-right mt-3 mb-1"> Name: {{}} </p>
      <p class="text-right mt-3 mb-1"> Number: {{}} </p>
    </div>
  </div>
</div> -->

<div class="dc-popupoverlay11" *ngIf="showhideQuestionAnwer">
  <div class="dc-delete-popup11" style="margin: 4% auto; width: 40%">
    <div
      class="col-md-12 text-left"
      style="overflow: hidden scroll; height: 400px"
    >
      <div *ngFor="let questionanswer of filledjsonObject">
        <p style="color: black">
          <span
            >Question:&ensp;<b>{{ questionanswer.q }}</b></span
          >
        </p>
        <p style="color: black">
          <span>Answer:&ensp;</span><b>{{ questionanswer.a }}</b>
        </p>
        <br />
      </div>
    </div>
    <p class="text-right mt-4 mb-1">
      <button class="dcClear1" (click)="closeQABtn()">Close</button>
    </p>
  </div>
</div>

<ng-template #dialerDialog>
  <ng-container *ngIf="isStageDial">
    <div class="output mb-3 text-center" style="height: 70px">
      <input
        id="codeDialPad"
        class="numInput"
        [disabled]="showHideDiallerPage ? true : false"
        autocomplete="off"
        maxlength="10"
        (keypress)="phoneInputPress($event)"
        (paste)="pasteTextPhone($event)"
      />
      <span class="v-cnName" *ngIf="!showHideDiallerPage">Contact Number</span>
    </div>

    <div class="dial-pad d-flex flex-wrap justify-content-between gap-2">
      <button
        mat-stroked-button
        class="v-digit"
        id="one"
        (click)="clickNumBtn(1)"
        [disabled]="showHideDiallerPage ? true : false"
      >
        1
      </button>

      <button
        mat-stroked-button
        class="v-digit"
        id="two"
        (click)="clickNumBtn(2)"
        [disabled]="showHideDiallerPage ? true : false"
      >
        2
      </button>

      <button
        mat-stroked-button
        class="v-digit"
        id="three"
        (click)="clickNumBtn(3)"
        [disabled]="showHideDiallerPage ? true : false"
      >
        3
      </button>

      <button
        mat-stroked-button
        class="v-digit"
        id="four"
        (click)="clickNumBtn(4)"
        [disabled]="showHideDiallerPage ? true : false"
      >
        4
      </button>

      <button
        mat-stroked-button
        class="v-digit"
        id="five"
        (click)="clickNumBtn(5)"
        [disabled]="showHideDiallerPage ? true : false"
      >
        5
      </button>

      <button
        mat-stroked-button
        class="v-digit"
        id="six"
        (click)="clickNumBtn(6)"
        [disabled]="showHideDiallerPage ? true : false"
      >
        6
      </button>

      <button
        mat-stroked-button
        class="v-digit"
        id="seven"
        (click)="clickNumBtn(7)"
        [disabled]="showHideDiallerPage ? true : false"
      >
        7
      </button>

      <button
        mat-stroked-button
        class="v-digit"
        id="eight"
        (click)="clickNumBtn(8)"
        [disabled]="showHideDiallerPage ? true : false"
      >
        8
      </button>

      <button
        mat-stroked-button
        class="v-digit"
        id="nine"
        (click)="clickNumBtn(9)"
        [disabled]="showHideDiallerPage ? true : false"
      >
        9
      </button>

      <button
        mat-stroked-button
        class="v-digit"
        [disabled]="showHideDiallerPage ? true : false"
      >
        <span style="font-size: 40px; position: relative; top: 8px">* </span>
      </button>

      <button
        mat-stroked-button
        class="v-digit"
        (click)="clickNumBtn(0)"
        [disabled]="showHideDiallerPage ? true : false"
      >
        0
      </button>

      <button
        mat-stroked-button
        class="v-digit"
        [disabled]="showHideDiallerPage ? true : false"
      >
        #
      </button>

      <button
        class="v-digit ml-auto"
        [disabled]="showHideDiallerPage ? true : false"
        style="border: none; box-shadow: none"
      >
        <mat-icon
            (click)="checkCustomerName()"
            class="vDigitCall m-0"
            [ngClass]="showHideDiallerPage ? 'vDigitCall1' : 'vDigitCall'"
        >
            call
        </mat-icon>
      </button>

      <button
        class="v-digit"
        [disabled]="!showHideStopPage ? true : false"
        style="border: none; box-shadow: none"
      >
        <img
          src="../assets/desktop_calling/closeDigitImg.png"
          class="closeDigit"
          (click)="removeNum()"
          [ngClass]="!showHideStopPage ? 'cur1' : 'cur2'"
        />
      </button>
    </div>
  </ng-container>
</ng-template>
<!--<div class="dc-ConnectingOverLay" *ngIf="showHideConnectingPage">
    <div class="dc-connecting-popup1">
        <div>
            <div style="height:115px;border-bottom:1px solid #ccc;"
                [ngStyle]="{'background': _afterConnectingVariable==='afertConnect' ? '#f2f7f0' : '#fff' }">
                <div class="row p-4">
                    <div class="col-md-4">
                        <div *ngIf="_afterConnectingVariable !='afertConnect'">
                            <p class="v-cn-txt1">Already Connected ?</p>
                            <p> <button class="dcGetInfoBtn" (click)="getInfoBtn()"> Get Info </button></p>
                        </div>

                        <div *ngIf="_afterConnectingVariable==='afertConnect'">
                            <p class="v-cn-txt1" style="color: #000;font-weight: 500;">Speaking With</p>
                            <p class="v-cn-txt1" style="color: #000;font-weight: 500;">No Name</p>
                            <p class="v-cn-txt1" style="color: #000;font-weight: 600;">+91 9620399449</p>
                        </div>





                    </div>
                    <div class="col-md-8">
                        <div class="mt-3 text-right" style="display: flex;flex-wrap: wrap;">
                            <div style="width:140px;">
                                <button matTooltip="Forward" class="dcFwdBtn" [disabled]="forwardDisabled"
                                    [ngClass]="forwardDisabled==true?'disableColour':''" (click)="dcForwardBtn()">
                                    <span><img src="../assets/desktop_calling/dc1-iconForward1.png"
                                            [ngClass]="forwardDisabled==true?'disabledImg1':''" /></span> Forward
                                </button>
                            </div>
                            <div style="width:140px;">
                                <button matTooltip="Merge" class="dcMergeBtn" [disabled]="mergeDisabled"
                                    [ngClass]="mergeDisabled==true?'disableColour':''" (click)="dcMergeBtn()">
                                    <span><img src="../assets/desktop_calling/dc1-iconMerge1.png"
                                            [ngClass]="forwardDisabled==true?'disabledImg1':''" /></span> Merge
                                </button>
                            </div>
                            <div style="width:140px;">
                                <button matTooltip="End" *ngIf="!showHideDuringSuccessDiv" class="dcEndBtn"
                                    (click)="endConnectingBtn()"><span><img
                                            src="../assets/desktop_calling/dc1-iconEnd1.png" /></span> End </button>
                                <button matTooltip="Click End" *ngIf="showHideDuringSuccessDiv" class="dcEndBtn"
                                    (click)="endAfterCallFormPageBtn()"><span><img
                                            src="../assets/desktop_calling/dc1-iconEnd1.png" /></span> End </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cdxDiv1" *ngIf="_afterConnectingVariable !='afertConnect'">
                <p class="text-center"><span class="v-cn-txt1" style="color: #50bafd;">Connecting...</span><br>
                    <span class="v-dcTitle1">Please Wait, Dialled Out</span>
                </p>
            </div>
            <div class="cdxDiv2" *ngIf="_afterConnectingVariable==='afertConnect'">
                <div *ngIf="showHideDuringDiv">
                    <div class="v-dc-duringCallScroll">
                        <h2 class="dcT1">During Call Form</h2>
                         <p style="padding-left: 32px;">
                             <mat-form-field style="width: 300px;">
                                 <mat-label>Which product information you want ?</mat-label>
                                 <mat-select>
                                     <mat-option value="product1">Product 1 </mat-option>
                                     <mat-option value="product2">Product 2 </mat-option>
                                 </mat-select>
                             </mat-form-field>
                         </p>
                         <p style="padding-left: 32px;">
                            <mat-form-field style="width: 300px;">
                                <mat-label>Enter Phone Number</mat-label>
                               <input matInput autocomplete="off">
                            </mat-form-field>
                        </p>
                          <p style="padding-left: 32px;">
                            <span class="v-btnfileX">
                                Browse File <mat-icon class="ml-2" style="vertical-align: text-bottom;">upload_file</mat-icon><input type="file">
                                 </span>
                            </p>

                    </div>
                    <div>
                        <p class="text-right mt-3 mb-2 pt-2" style="border-top: 1px solid #ccc;">
                            <button class="dcClear1" (click)="clearDuringCallBtn()"> Clear </button>
                            <button class="dcUpdate1" (click)="submitDuringCallBtn()"> Submit </button>
                        </p>
                    </div>
                </div>
                <div *ngIf="showHideDuringSuccessDiv">
                    <div class="cdxDiv1">
                        <p class="text-center"><img src="../assets/desktop_calling/dcDuringSuccessImg.png"
                                style="width: 35px;" />
                            <br><span class="v-dcSpan3"> Form submitted successfully, Please end the Call</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>



<div class="dc-AfterCallOverLay" *ngIf="showHideAfterCallFormPage">
    <div class="dc-afterCall-popup1">
        <div>
            <h2 class="dcT2">After Call Form</h2>
            <p class="mt-4 mb-3"> <span class="v-dcTitle1">New Data</span> </p>
            <div class="row">
                <div class="col-md-6">
                    <span class="v-acf1">Customer Name</span>
                    <span class="v-acf2">Rahul Thekkeppat</span>
                </div>
                <div class="col-md-6">
                    <span class="v-acf1">Customer Number</span>
                    <span class="v-acf2">+91 9620399449</span>
                </div>
            </div>



            <div class="v-dcCallDivy mb-4">
                <div class="dc-ufrrBtn1" (click)="clickHighlight('update')"
                    [ngClass]="{ activeDCbtn: isVActive('update') }">
                    <p class="mb-0">
                        <mat-icon class="dcIconx1">done </mat-icon>
                    </p>
                    <p class="dc-Txtx1">UPDATE</p>
                </div>
                <div class="dc-ufrrBtn1" (click)="clickHighlight('callback1')"
                    [ngClass]="{ activeDCbtn: isVActive('callback1') }">
                    <p class="mb-0">
                        <mat-icon class="dcIconx1">date_range </mat-icon>
                    </p>
                    <p class="dc-Txtx1">FOLLOWUP</p>
                </div>

                <div class="dc-ufrrBtn1" (click)="clickHighlight('rnr')"
                    [ngClass]="{ activeDCbtn: isVActive('rnr') }">
                    <p class="mb-0">
                        <mat-icon class="dcIconx1">north_east </mat-icon>
                    </p>
                    <p class="dc-Txtx1">RNR</p>
                </div>

                <div class="dc-ufrrBtn1" (click)="clickHighlight('callback2')"
                    [ngClass]="{ activeDCbtn: isVActive('callback2') }">
                    <p class="mb-0">
                        <mat-icon class="dcIconx1"> call_missed_outgoing</mat-icon>
                    </p>
                    <p class="dc-Txtx1">REDIAL</p>
                </div>

            </div>


                <div class="mt-2" *ngIf="showHideForFollowUPAfterCall">
                    <mat-radio-group [ngClass]="'dcAfterRadio'" [value]="SelectTimeModel">
                    <mat-radio-button value="SelectTime" style="width:50%"> <span class="v-acf1">Select Time </span>

                    <div style="position: absolute;" class="mt-2">
                        <button class="ddl-dd-btn" (click)="clickTimeHighlight('10')"
                        [ngClass]="{ activeTimeBtn: isTimeActive('10') }"> 10 Mins </button>
                    <button class="ddl-dd-btn" (click)="clickTimeHighlight('30')"
                        [ngClass]="{ activeTimeBtn: isTimeActive('30') }"> 30 Mins </button>
                    <button class="ddl-dd-btn" (click)="clickTimeHighlight('60')"
                        [ngClass]="{ activeTimeBtn: isTimeActive('60') }"> 60 Mins </button>
                    </div>

                    </mat-radio-button>
                    <mat-radio-button value="SelectDay" style="width:50%"> <span class="v-acf1">Select Day </span>

                    <div  style="position: absolute;" class="mt-2">
                        <table>
                            <tr>
                                <td>
                                    <mat-form-field appearance="outline" [ngClass]="'ddl-dc-date1'" style="width: 120px;margin-right: 10px;">
                                        <input matInput [matDatepicker]="pickerx" placeholder="Date" readonly (click)="pickerx.open()">
                                        <mat-datepicker-toggle matSuffix [for]="pickerx">
                                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #pickerx></mat-datepicker>
                                    </mat-form-field>
                                </td>
                                <td style="position: absolute;">
                                    <span> <input type="time" id="appt" name="appt" placeholder="Time" class="v-dc-time1"></span>
                                </td>
                            </tr>
                        </table>

                    </div>

                    </mat-radio-button>
                </mat-radio-group>
                </div>

                <p class="text-right mt-4 mb-2 pt-3" style="position: absolute;bottom: 10px;right: 40px;">
                    <button class="dcUpdate1" (click)="submitAfterCallFormBtn()"> Submit </button>
                </p>
        </div>
    </div>
</div> -->
