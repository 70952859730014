
<div class="row">
    <div class="col-md-3 col-sm-12 col-xs-12">
        <mat-card class="CN-Card cardW">
            <p><span class="CN-Tab-head1">Sent </span></p>
            <p class="cn-p CNP1">{{sms_today[0]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
            <hr class="hr1">
            <p class="cn-p CNP2">{{sms_yesterday[0]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
            <div style="display: none;">
            <hr class="hr2">
            <p [hidden]="sms_today[0]!=sms_yesterday[0]">
                      <span>
                          <mat-icon class="upArrow">arrow_upward</mat-icon>
                      </span>
              <span class="upData">100%</span>
              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
            </p>
            <p [hidden]="sms_today[0]==sms_yesterday[0] || sms_today[0]<sms_yesterday[0]">
                      <span>
                          <mat-icon class="upArrow">arrow_upward</mat-icon>
                      </span>
              <span class="upData">{{ ((sms_today[0]-sms_yesterday[0])/sms_yesterday[0]) | percent }} </span>
              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
            </p>
            <p [hidden]="sms_today[0]==sms_yesterday[0] || sms_today[0]>sms_yesterday[0]">
                        <span>
                            <mat-icon class="downArrow">arrow_downward</mat-icon>
                        </span>
              <span class="downData">{{((sms_yesterday[0]-sms_today[0])/sms_yesterday[0]) | percent}} </span>
              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
          </p>
        </div>
        </mat-card>
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12">
        <mat-card class="CN-Card cardW">
            <p><span class="CN-Tab-head1">Delivered </span></p>
            <p class="cn-p CNP3">{{sms_today[1]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
            <hr class="hr1">
            <p class="cn-p CNP4">{{sms_yesterday[1]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
            <div style="display: none;">
            <hr class="hr2">
            <p [hidden]="sms_today[1]!=sms_yesterday[1]">
                    <span>
                        <mat-icon class="upArrow">arrow_upward</mat-icon>
                    </span>
              <span class="upData">100%</span>
              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
            </p>
            <p [hidden]="sms_today[1]==sms_yesterday[1] || sms_today[1]<sms_yesterday[1]">
                    <span>
                        <mat-icon class="upArrow">arrow_upward</mat-icon>
                    </span>
              <span class="upData">{{ ((sms_today[1]-sms_yesterday[1])/sms_yesterday[1]) | percent }} </span>
              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
            </p>
            <p [hidden]="sms_today[1]==sms_yesterday[1] || sms_today[1]>sms_yesterday[1]">
                      <span>
                          <mat-icon class="downArrow">arrow_downward</mat-icon>
                      </span>
              <span class="downData">{{((sms_yesterday[1]-sms_today[1])/sms_yesterday[1]) | percent}} </span>
              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
            </p>
            </div>
        </mat-card>
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12">
        <mat-card class="CN-Card cardW">
            <p><span class="CN-Tab-head1">Read </span></p>
            <p class="cn-p CNP5">{{sms_today[2]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
            <hr class="hr1">
            <p class="cn-p CNP6">{{sms_yesterday[2]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
            <div style="display: none;">
            <hr class="hr2">
            <p [hidden]="sms_today[2]!=sms_yesterday[2]">
                    <span>
                        <mat-icon class="upArrow">arrow_upward</mat-icon>
                    </span>
              <span class="upData">100%</span>
              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
            </p>
            <p [hidden]="sms_today[2]==sms_yesterday[2] || sms_today[2]<sms_yesterday[2]">
                    <span>
                        <mat-icon class="upArrow">arrow_upward</mat-icon>
                    </span>
              <span class="upData">{{ ((sms_today[2]-sms_yesterday[2])/sms_yesterday[2]) | percent }} </span>
              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
            </p>
            <p [hidden]="sms_today[2]==sms_yesterday[2] || sms_today[2]>sms_yesterday[2]">
                      <span>
                          <mat-icon class="downArrow">arrow_downward</mat-icon>
                      </span>
              <span class="downData">{{((sms_yesterday[2]-sms_today[2])/sms_yesterday[2]) | percent}} </span>
              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
            </p>
            </div>
        </mat-card>
    </div>
    <div class="col-md-3 col-sm-12 col-xs-12">
        <mat-card class="CN-Card cardW">
            <p><span class="CN-Tab-head1">Failed </span> </p>
            <p class="cn-p CNP7">{{sms_today[3]}} <br> <span class="cn-sub-title">{{selected_time_range}}</span> </p>
            <hr class="hr1">
            <p class="cn-p CNP8">{{sms_yesterday[3]}} <br> <span class="cn-sub-title"> Yesterday</span> </p>
            <div style="display: none;">
            <hr class="hr2">
            <p [hidden]="sms_today[3]!=sms_yesterday[3]">
                  <span>
                      <mat-icon class="upArrow">arrow_upward</mat-icon>
                  </span>
              <span class="upData">100%</span>
              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Same as yesterday</span>
            </p>
            <p [hidden]="sms_today[3]==sms_yesterday[3] || sms_today[3]<sms_yesterday[3]">
                  <span>
                      <mat-icon class="upArrow">arrow_upward</mat-icon>
                  </span>
              <span class="upData">{{ ((sms_today[3]-sms_yesterday[3])/sms_yesterday[3]) | percent }} </span>
              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Higher than yesterday</span>
            </p>
            <p [hidden]="sms_today[3]==sms_yesterday[3] || sms_today[3]>sms_yesterday[3]">
                    <span>
                        <mat-icon class="downArrow">arrow_downward</mat-icon>
                    </span>
              <span class="downData">{{((sms_yesterday[3]-sms_today[3])/sms_yesterday[3]) | percent}} </span>
              <span class="cn-sub-title" style="font-size: 0.6em !important;"> Less than yesterday</span>
            </p>
            </div>
        </mat-card>
    </div>
</div>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <mat-card class="CN-Card cardW">
            <p><span class="CN-Tab-head1">Sent Vs Delivered</span> </p>
            <div [chart]="sentDeliveredChart"> </div>
        </mat-card>
    </div>
    <!-- <div class="col-md-3 col-sm-12 col-xs-12" >
        <mat-card class="CN-Card cardW">
            <p class="CN-Tab-head1">Today’s Summary</p>
            <div style="min-width: 150px; height: 150px; max-width: 150px;" [chart]="donutChartrecord">
            </div>
            <table style="width:100%">
                <tr>
                    <td class="text-right"><span class="lv1">Today’s Total</span></td>
                    <td><span class="lv2">{{wa_total_today}} </span></td>
                </tr>
                <tr>
                    <td class="text-right"><span class="lv1">Yesterday;s Total</span></td>
                    <td><span class="lv2">{{wa_total_yesterday}} </span></td>
                </tr>
            </table>
        </mat-card>
    </div> -->
</div>

