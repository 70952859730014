import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orchestration',
  templateUrl: './orchestration.component.html',
  styleUrls: ['./orchestration.component.scss']
})
export class OrchestrationComponent implements OnInit {
  currentTitle = 'orchestration';
  constructor() { }

  ngOnInit(): void {
  }

}
