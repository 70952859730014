import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Utils } from '../utils';
import { Router,NavigationEnd   } from '@angular/router';
import { MyserviceService } from '../myservice.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

currentTitle = 'training';
//selectClientView:any;
ClientCompany:any;
selectedAdmin = 'admin';
isVisibleSelectClient:boolean=false;
// _renderClientImage:any;
// _companyName:any;
companySelected:any;
public showPart:boolean = false;
currentRoute: string;
utils: any;
selectedButtonToggleVal: string = 'training';
showhideManagementContent:boolean =false;
api_end_point:any;

constructor(private API: ApiService,public router:Router,private myService:MyserviceService) { 
  this.utils = new Utils();
  router.events.subscribe(value => {
    this.currentRoute = router.url;
});
}

ngOnInit() {
  let _pathurl=window.location.pathname;
  if(_pathurl=="/training/dashboardTraining" || _pathurl=="/training/agents" || _pathurl=="/training/batch" || _pathurl=="/training/trainers" || _pathurl=="/training/onJobTraining" ||_pathurl=="/training/onJobTrainingAgent" ||_pathurl=="/training/certification" ||  _pathurl=="/training/reports" ||  _pathurl=="/training/calandar" || _pathurl=="/training/create_trainer" || _pathurl=="/training/create_trainer" || _pathurl=="/training/create_batch" || _pathurl=="/training/batch_training" || _pathurl=="/training/send-message" || _pathurl=="/training/create_ojt_batch"){
    this.router.navigateByUrl('/training');
  }
  this.api_end_point = this.myService.get_API_End_Point();
 this.API.getClientsName(this.api_end_point).subscribe((response:any)=>{
   // this.ClientCompany =response.success;
    this.ClientCompany= response.success
  });

}
clientCompanySelect(company:any){
  this.router.navigateByUrl('/training');
this.API.selectAClient(company);
let _pathurl=window.location.pathname;
if(_pathurl=="/training/dashboardTraining"){
  setTimeout(() =>  this.router.navigateByUrl('/training/dashboardTraining'), 100);
}
else{
  this.router.navigateByUrl('/training/dashboardTraining');
}

}
clickForEmptySelectCompany(type:string=""){
  if(this.companySelected==null || this.companySelected==undefined || this.companySelected=="")
{
  alert("Please you have to select company !");
  this.router.navigateByUrl('/training');
  return false;
}
if(type=="ojtBatch"){
  this.showPart=true;
  return true;
}
if(type=="ojtAgent"){
  this.showPart=true;
  return true;
}
else{
    this.showPart=false;
    return true;
  }

}
toggleOjt() {
  this.showPart = !this.showPart;
}
logout(){
    sessionStorage.setItem('access_token',null);
    this.router.navigate(['/home']);
  }
  onToggleButtonValChange(val: string) {
		this.selectedButtonToggleVal = val;
		this.showhideManagementContent=false;
		if (val == 'training') {
		  this.router.navigateByUrl('/training');
		} 
    else if (val == 'managementTeam') {
		this.showhideManagementContent=true;
      //this.router.navigateByUrl('/managementTeam');
		}
	}
}
