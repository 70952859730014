<div style="margin-left: 5%;margin-right: 2%;">
    <div class="row">
      <div class="col-md-12 col-sm-7 col-xs-12">
        <h2 class="d-head">Upload Status</h2>
        <h4 *ngIf="showHidePassingMessage" [style.color]="_color" style="color: green;font-weight: bold;font-size: 20px;">
          {{passingMessage}}</h4>
      </div>
    </div>
    <form [formGroup]="createdForm">
      <div class="row pdt">
          <div class="col-md-12 col-sm-12 col-xs-12">
          <span class="dnldbtn">
              <button mat-button (click)="download_file()" >Download Excel Template
              &nbsp;&nbsp;  <i class="fa fa-file-excel" aria-hidden="true"></i>
              </button>
              </span>
          </div>
        <div class="col-md-12 col-sm-12 col-xs-12" >
          <div class="upload-btn-wrapper">
            <button class="btnbrowse">Browse</button>
            <input type="file" #fileUpload class="filecs" formControlName="file" (change)="changeFile($event)" [disabled]="form_submitted" />
          </div>
          <span>
            <button *ngIf="showHideSubmitUpload" mat-button class="upload_btn uploadSave"
              (click)="saveButton(createdForm)" [disabled]="form_submitted">Submit </button>
          </span>
          <span><button mat-button class="upload_btn uploadSave" style="margin-left: 92px;"
              (click)="closeDialog()" [disabled]="form_submitted">Cancel</button></span>
          <div>
            <span style="color: #bababa;">{{currentFileobj.name}}</span>
          </div>
        </div>
   
  </div>
    
  
    </form>
  </div>
  <div id="cover-spin" *ngIf="currentLoader"></div>
  