<section id="mobile_navbar">
    <div class="row">
        <div id="brand_img">
            <a href="/">
                <img src="/assets/uearn_logo.svg" alt="Uearn Money">
            </a>
        </div>
        <div id="playstore_img" style="display: none;">
            <!--<a href="/signin" class="web_signin">SIGN IN</a>-->
            <a  style="display: none;" href="https://play.google.com/store/apps/details?id=smarter.uearn.money&hl=en" target="_blank">
                <img src="/assets/mob_images/google-play.svg" alt="Download">
            </a>
        </div>
    </div>
</section>
<section id="web_navbar" >
    <div class="web_container">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <a href="/" class="web_logo_img">
                    <img src="/assets/uearn_logo.svg" alt="Uearn Money">
                </a>
            </div>
           
            <div class="col-sm-12 col-md-3 col-lg-3" style="color: white;font-size: 14px;position: relative; top: 25px;">
              <span style="margin-right: 6px;"><i class="fa fa-phone-square" aria-hidden="true"></i></span> 
              <span> +91 63669 54899</span>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3 text-center">
                <a [routerLink]="['/signin']" routerLinkActive class="web_signin">Enterprise Login</a>
                <a style="display: none;" href="https://play.google.com/store/apps/details?id=smarter.uearn.money&hl=en" target="_blank" class="top_download">
                    <img src="/assets/mob_images/google-play.svg" alt="Download">
                </a>
            </div>
        </div>
    </div>
</section>