<!-- TL Wise counts html starts -->
<div class="md-12 sm-12 xs-12">
	<mat-card [ngClass]="{'call_summary_height' : tl_sourceCallSummary.data.length > 7 }" style="padding: 0px !important;">
	<mat-table [dataSource]="tl_sourceCallSummary" class="mat-elevation-z8">
		<ng-container matColumnDef="supervisor">
		  <mat-header-cell *matHeaderCellDef>TL </mat-header-cell>
		  <mat-cell *matCellDef="let element" class="CR1" >{{element.supervisor}} </mat-cell>
		</ng-container>
		
		<ng-container matColumnDef="agents">
		  <mat-header-cell *matHeaderCellDef> Agents </mat-header-cell>
		  <mat-cell *matCellDef="let element" class="CR1" > {{element.agents}} </mat-cell>
		</ng-container>
		
		<ng-container matColumnDef="dialled">
		  <mat-header-cell *matHeaderCellDef>Calls Placed</mat-header-cell>
		  <mat-cell *matCellDef="let element" class="CR1" > {{element.dialled}} </mat-cell>
		</ng-container>
		
		<ng-container matColumnDef="unique_dialled">
		  <mat-header-cell *matHeaderCellDef>Unique Calls Placed</mat-header-cell>
		  <mat-cell *matCellDef="let element" class="CR1" > {{element.unique_dialled}} </mat-cell>
		</ng-container>
		
		<ng-container matColumnDef="contacted">
			<mat-header-cell *matHeaderCellDef> Contacted </mat-header-cell>
			<mat-cell *matCellDef="let element" class="CR1" > {{element.contacted}} </mat-cell>
		  </ng-container>
		
		<ng-container matColumnDef="unique_contacted">
			<mat-header-cell *matHeaderCellDef> Unique Contacted </mat-header-cell>
			<mat-cell *matCellDef="let element" class="CR1" > {{element.unique_contacted}} </mat-cell>
		  </ng-container>
		 
		<ng-container matColumnDef="rnr">
			<mat-header-cell *matHeaderCellDef> RNR </mat-header-cell>
			<mat-cell *matCellDef="let element" class="CR1" > {{element.rnr}} </mat-cell>
		</ng-container>
		<ng-container matColumnDef="forms">
			<mat-header-cell *matHeaderCellDef> Forms </mat-header-cell>
			<mat-cell *matCellDef="let element" class="CR1" > {{element.forms}} </mat-cell>
		</ng-container>
		<ng-container matColumnDef="aht_pulse">
			<mat-header-cell *matHeaderCellDef> AHT </mat-header-cell>
			<mat-cell *matCellDef="let element" class="CR1" > {{element.aht_pulse}} </mat-cell>
		</ng-container>
		
		<mat-header-row *matHeaderRowDef="tl_dataColumnsCallSummary;sticky: true"></mat-header-row>
		<mat-row *matRowDef="let row; columns: tl_dataColumnsCallSummary;" ></mat-row>
		</mat-table>
	</mat-card>
</div>
<!-- TL Wise counts html ends -->