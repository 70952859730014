<!-- <app-menu [navigateLink]="currentTitle"></app-menu> -->
<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu>


	</mat-drawer>
	<!-- Feedback Dashboard starts -->
	<br><br>
	<div class="row">
		<table class="maintable">
			<tr>
				<td>
					<button mat-button class="back_btn" (click)="goBack()">BACK</button>
					<!-- <h3 class="titlehead pt-3"><span class="back_link" (click)="goBack()">Back to Dashboard</span></h3> -->
				</td>
			</tr>
			<tr>
				<td>
					<div class="pDiv" style="margin-top:2%">
						<div class="divprintRt">
							<div class="row">
								<div class="col-md-12">
									<span class="recName pl-3">
										<!-- Agent Calls - Feedback -->
										Call Logs
									</span>
								</div>
							</div>
							<div class="row pl-4 pt-3">
								<div class="col-md-3">
									<span>
										<mat-form-field class="wd-100">
											<input matInput [matDatepicker]="frompicker" [max]="maxDate"
												placeholder="From" readonly (click)="frompicker.open()"
												(dateChange)="change_date_status('start_date', $event)"
												[(ngModel)]="StartDate">
											<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
											<mat-datepicker #frompicker></mat-datepicker>
										</mat-form-field>
									</span>
								</div>
								<div class="col-md-3"><span>
										<mat-form-field class="wd-100">
											<input #fromInput matInput [matDatepicker]="topicker" [max]="maxDate"
												placeholder="To" readonly (click)="topicker.open()"
												(dateChange)="change_date_status('end_date', $event)"
												[(ngModel)]="EndDate">
											<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
											<mat-datepicker #topicker></mat-datepicker>
										</mat-form-field>
									</span>
								</div>
								<div class="col-md-2">
									<span (click)="refreshData()">
										<button mat-button>Go</button>
									</span>
								</div>
								<div class="col-md-4">
									<!-- <span class="searchSpan">
						  <mat-form-field>
							<input matInput (keyup)="applySearchFilter($event.target.value)" placeholder="Search">
							<i matSuffix class="fa fa-search searchicon" aria-hidden="true"></i>
						  </mat-form-field>
						</span> -->
								</div>
							</div>
							<p>
								<!-- <span class="das-pe">Print</span> -->
								<span class="das-pe" (click)="exportExcel()">Export</span>
								<span class="das-pe" (click)="exportExcelByGroup()">Export By Group</span>
								<!-- <span class="das-pe" (click)="refreshCampaignData()">Refresh</span> -->
							</p>
						</div>
					</div>
				</td>
			</tr>
			<tr>
				<td>
					<div class="pDiv">
						<div class="row">
							<div class="col-md-12">
								<mat-card>
									<mat-card-header>
										<mat-card-title>Feedback Summary</mat-card-title>
									</mat-card-header>
									<mat-card-content>
										<ng-container *ngIf="summary_table_data.data.length">
											<mat-table [dataSource]="summary_table_data"
												style="border-right: 1px solid #cccccc57;">
												<ng-container *ngFor="let d of summary_table_columns" [matColumnDef]="d">
													<mat-header-cell *matHeaderCellDef> {{getKeyName(d)}} </mat-header-cell>
													<mat-cell *matCellDef="let element" [matTooltip]="element[d]">
														{{limit_string(element[d])}}
													</mat-cell>
												</ng-container>
												<mat-header-row *matHeaderRowDef=summary_table_columns></mat-header-row>
												<mat-row *matRowDef="let row; columns: summary_table_columns"></mat-row>
											</mat-table>
										</ng-container>
										<ng-container *ngIf="!summary_table_data.data.length">
											<p class="p-no-data">No data available</p>
										</ng-container>
									</mat-card-content>
								</mat-card>
								<!-- <div *ngIf="summary_table_data.data.length"
									class="tl_table_view inner_border table_min_height">
									<mat-table [dataSource]="summary_table_data"
										style="border-right: 1px solid #cccccc57;">
										<ng-container *ngFor="let d of summary_table_columns" [matColumnDef]="d">
											<mat-header-cell *matHeaderCellDef> {{getKeyName(d)}} </mat-header-cell>
											<mat-cell *matCellDef="let element" [matTooltip]="element[d]">
												{{limit_string(element[d])}}
											</mat-cell>
										</ng-container>
										<mat-header-row *matHeaderRowDef=summary_table_columns></mat-header-row>
										<mat-row *matRowDef="let row; columns: summary_table_columns"></mat-row>
									</mat-table>
								</div>
								<div *ngIf="!summary_table_data.data.length">
									<p>No data available</p>
								</div> -->
							</div>
							<!-- <div class="col-md-4">
				  <div [chart]="chart"></div>
				</div> -->
						</div>
					</div>
				</td>
			</tr>
			<tr>
				<td>
					<div class="pDiv">
						<mat-card>
							<mat-card-header>
								<mat-card-title>Calls Summary</mat-card-title>
							</mat-card-header>
							<mat-card-content>
								<ng-container>
									<app-table-filter [table_data]="dataSource" [filter_columns_obj]="columns_filter"
										(filtered_data)="filtered_data($event)"></app-table-filter>
									<mat-table class="mtblcs" [dataSource]="dataSource">
										<ng-container matColumnDef="supervisor">
											<mat-header-cell *matHeaderCellDef> TL </mat-header-cell>
											<mat-cell *matCellDef="let element"> {{element.supervisor}} </mat-cell>
										</ng-container>
										<ng-container matColumnDef="u_name">
											<mat-header-cell *matHeaderCellDef> A.Name </mat-header-cell>
											<mat-cell *matCellDef="let element" [matTooltip]="element.u_name">
												{{limit_string(element.u_name)}} </mat-cell>
										</ng-container>
										<ng-container matColumnDef="u_email">
											<mat-header-cell *matHeaderCellDef> A.Email </mat-header-cell>
											<mat-cell *matCellDef="let element" [matTooltip]="element.u_email">
												{{limit_string(element.u_email)}}
											</mat-cell>
										</ng-container>
										<ng-container matColumnDef="cust_name">
											<mat-header-cell *matHeaderCellDef> C.Name </mat-header-cell>
											<mat-cell *matCellDef="let element" [matTooltip]="element.cust_name">
												{{limit_string(element.cust_name)}} </mat-cell>
										</ng-container>
										<ng-container matColumnDef="cust_number">
											<mat-header-cell *matHeaderCellDef> C.Number </mat-header-cell>
											<mat-cell *matCellDef="let element"> {{element.cust_number}} </mat-cell>
										</ng-container>
										<ng-container matColumnDef="start_time">
											<mat-header-cell *matHeaderCellDef> Start </mat-header-cell>
											<mat-cell *matCellDef="let element" [matTooltip]="element.start_time">
												{{limit_string(element.start_time)}}
											</mat-cell>
										</ng-container>
										<ng-container matColumnDef="end_time">
											<mat-header-cell *matHeaderCellDef> End </mat-header-cell>
											<mat-cell *matCellDef="let element" [matTooltip]="element.end_time">
												{{limit_string(element.end_time)}}
											</mat-cell>
										</ng-container>
										<ng-container matColumnDef="event_type">
											<mat-header-cell *matHeaderCellDef> Event </mat-header-cell>
											<mat-cell *matCellDef="let element"> {{renameCallEventName(element.event_type)}}
											</mat-cell>
										</ng-container>
										<ng-container matColumnDef="url">
											<mat-header-cell *matHeaderCellDef> Play </mat-header-cell>
											<mat-cell *matCellDef="let element">
												<span *ngIf="element.url" style="cursor: pointer;" (click)="playCall(element)">
													<mat-icon>play_circle_filled</mat-icon>
												</span>
												<span *ngIf="!element.url">NA</span>
											</mat-cell>
										</ng-container>
										<ng-container *ngFor="let dataMap of additional_columns" [matColumnDef]="dataMap">
											<mat-header-cell *matHeaderCellDef> {{ dataMap }} </mat-header-cell>
											<mat-cell *matCellDef="let element" [matTooltip]="check_table_value(element, dataMap)">
												{{
												limit_string(check_table_value(element, dataMap)) }} </mat-cell>
										</ng-container>
										<mat-header-row *matHeaderRowDef=total_display_columns></mat-header-row>
										<mat-row *matRowDef="let row; columns: total_display_columns"></mat-row>
									</mat-table>
									<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
								</ng-container>						
							</mat-card-content>
						</mat-card>
					</div>
				</td>
			</tr>
			<tr>
				<td>
					<div class="pDiv">
						<div class="row">
							<div class="col-md-12">
								<span class="recName">
									Paste call url below
								</span>
								<mat-form-field class="wd-100 example-form-field">
									<!-- <mat-label>Paste URL</mat-label> -->
									<input matInput type="text" [(ngModel)]="externalUrl"
										(keydown.enter)="loadExternalUrl()">
									<button *ngIf="externalUrl" type="submit" matSuffix mat-icon-button
										aria-label="Search" (click)="loadExternalUrl()">
										<mat-icon>search</mat-icon>
									</button>
								</mat-form-field>
							</div>
						</div>
					</div>
				</td>
			</tr>
		</table>
	</div>

	<!-- Audio player for single record starts -->
	<!-- <app-audio-player *ngIf="selected_url && selected_url.length" class="fixed_audio_player" [source_url]="selected_url"></app-audio-player> -->
	<!-- Audio player for single record ends -->
	<div class="audio_player">
		<app-audio-player [source_url]="selected_url" *ngIf="selected_url"></app-audio-player>
	</div>
	<!-- Feedback Dashboard ends -->
</mat-drawer-container>