<div>
    <form [formGroup]="createdForm">
    <div class="row">
        <div class="col-md-12">
            <span> <b>Edit Template</b> </span>
        </div>
    </div>
   
    <div class="row">
        <div class="col-md-6">
            <p class="mb-1 mt-4"> 
                <label class="lblSopify">Notification Event*</label><br>
                <span class="inputWdSp1">                 
                    <mat-select formControlName="notificationEvent">
                        <mat-option value="option1">Option1</mat-option>
                    </mat-select>
                </span>
            </p>
        </div>
        <div class="col-md-6">
            <p class="mb-1 mt-4"> 
                <label class="lblSopify">Courier Partner Account*</label><br>
                <span class="inputWdSp1">                   
                    <mat-select formControlName="courierPartnerAccount">
                        <mat-option value="option1">Option1</mat-option>
                    </mat-select>
                </span>
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <p class="mb-1 mt-4"> 
                <label class="lblSopify">Template Name</label><br>
                <span class="inputWdSp1">
                    <input matInput  autocomplete="off" maxlength="100" required  formControlName="templateName">
                </span>
            </p>
        </div>
        <div class="col-md-6">
            <p class="mb-1 mt-4"> 
                <label class="lblSopify">Template Type</label><br>
                <span class="inputWdSp1">
                    <input matInput  autocomplete="off" maxlength="100" required  formControlName="templateType">
                </span>
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <p class="mb-1 mt-4"> 
                <label class="lblSopify">Call Back URL</label><br>
                <span class="inputWdSp1">
                    <input matInput  autocomplete="off" maxlength="100" required  formControlName="callBackURL">
                </span>
            </p>
        </div>
        <div class="col-md-6">
            <p class="mb-1 mt-4"> 
                <label class="lblSopify">Language Code</label><br>
                <span class="inputWdSp1">
                    <mat-select formControlName="languageCode">
                        <mat-option value="option1">Option1</mat-option>
                    </mat-select>
                </span>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <p class="mb-1 mt-4"> 
                <label class="lblSopify">Params</label><br>
                <span class="inputWdSp1">
                    <input matInput  autocomplete="off" maxlength="300" required  formControlName="params">
                </span>
                <br>
                <span class="lblSopify" style="color:gray" >Please pass comma separated values in params field</span>
            </p>
        </div>
     
    </div>
    <div class="row mt-4">
        <div class="col-md-12 text-right">
            <button class="prevAdd mt-0 ml-3" style="color:gray !important ;border:1px solid gray !important;background: #fff !important;" mat-button (click)="closePopup()">
                CANCEL
            </button>
    
            <button class="prevAdd mt-0 ml-3"  (click)="submitBtn(createdForm)" mat-button>
             Save
            </button>
        </div>
    </div>
</form>
</div>