<div class="row">
	<div class="col-md-12">
		<mat-card style="margin: 0 10px;box-shadow: 0px 0px 4px 0px #dfdfdf;">
			<div class="row">
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon5.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1">{{get_percent()}}%  <!--{{getAllTotalContacted()}}--></span>
								<span class="nameLabel1">Contacted</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="col-md-3" style="border-right: 2px dotted #a1a0a0;">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon6.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1" style="color:#50bafd;">{{getAllDNC()}}</span>
								<span class="nameLabel1">DNC</span>
							</td>
						</tr>
					</table>
				</div>
				<div class="col-md-3">
					<table>
						<tr>
							<td style="vertical-align: middle;">
								<img src="../assets/web_images/dataBAIcon7.png" class="dataImgXy1" />
							</td>
							<td>
								<span class="datalabel1" style="color:#f3385d;">{{getAllWrongNumber()}}</span>
								<span class="nameLabel1">Wrong Number</span>
							</td>
						</tr>
					</table>
				</div>
			</div>

		</mat-card>
	</div>
</div>
<div class="row ">
	<div class="col-md-12">
		<mat-card style="margin: 0 12px;" class="mt-3">
			<h4 class="titlehead thd1 mb-4">ASM</h4>


			<table matSort (matSortChange)="sortData($event)" class="vTableV">
				<tr>
					<th mat-sort-header="name">NAME</th>
					<th mat-sort-header="connected">CONNECTED %</th>
					<th mat-sort-header="totalContacted">TOTAL CONTACTED</th>
					<th mat-sort-header="dnc">DNC</th>
					<th mat-sort-header="wrongNumber">WRONG NUMBER</th>
					<th mat-sort-header="rnr">RNR</th>
				</tr>
				<tbody *ngFor="let d of sortedData | async;let i = index;let first=first;">
					<tr>
						<td colspan="6" style="color: #000;font-weight: bold;">{{d.title}}</td>
					</tr>
					<tr *ngFor="let v of d.rData;let j = index;let first=first;">
						<td>{{v.name}}</td>
						<td>{{v.connected | number : '1.0-0'}}</td>
						<td>{{v.totalContacted | number : '1.0-0'}}</td>
						<td>{{v.dnc | number : '1.0-0'}}</td>
						<td>{{v.wrongNumber | number : '1.0-0'}}</td>
						<td>{{v.rnr | number : '1.0-0'}}</td>
					</tr>
					<tr style="background: #f9f9f9;">
						<td><b>Total</b></td>
						<td>{{getConnected(d)| number : '1.0-0'}}</td>
						<td>{{getTotalContacted(d)| number : '1.0-0'}}</td>
						<td>{{getDNC(d)| number : '1.0-0'}}</td>
						<td>{{getWrongNumber(d)| number : '1.0-0'}}</td>
						<td>{{getRNR(d)| number : '1.0-0'}}</td>

					</tr>
				</tbody>
				<tfoot>
					<tr style="background: #f3f3f3;">
						<td><b>All Total</b></td>
						<td>{{getAllConnected()| number : '1.0-0'}}</td>
						<td>{{getAllTotalperContacted()| number : '1.0-0'}}</td>
						<td>{{getAllagentDNC()| number : '1.0-0'}}</td>
						<td>{{getAllWrongNumber()| number : '1.0-0'}}</td>
						<td>{{getAllRNR()| number : '1.0-0'}}</td>
					</tr>
				</tfoot>
			</table>


		</mat-card>
	</div>
</div>