
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as _ from 'underscore';
import { MyserviceService } from '../myservice.service';
import { RtddataService } from '../rtdData.service';
import {Utils} from '../utils';
@Component({
	selector: 'app-badashboard-agents-page',
	templateUrl: './badashboard-agents-page.component.html',
	styleUrls: ['./badashboard-agents-page.component.scss']
})
export class BadashboardAgentsPageComponent implements OnInit, OnDestroy {

	_fetchData: any = [];
	sortedData: BehaviorSubject<[]> = new BehaviorSubject([]);
	agentFeed:Subscription;
	groupid:any;
	group_level_data:any;
	groupdata=[];
	util = new Utils();
	
	constructor(private rtd: RtddataService, private myService: MyserviceService) {

	}
	ngOnInit() {
		this.getRtdValues();
	}

	getRtdValues() {
		this.group_level_data = this.myService.get_bidashboard();
		this.groupid = this.myService.get_asm_groupid();
		   console.log(JSON.stringify(this.group_level_data));
		   this.groupdata = Object.values(this.group_level_data);
		this.agentFeed = this.rtd.agentFeed.subscribe(data => {
			//console.log('agent feed', data);
			// let finaldata =[];
			// if(this.groupid)
			// {
			// 	console.log('agent feed', data);
			// 	for(let i in data)
			// 	{
			// 		//console.log(data[i]);
			// 		if(data[i].groupid == this.groupid)
			// 		{
			// 			finaldata.push(data[i]);
			// 		}
			// 	}
			// 	console.log(finaldata);
			// }
			// else{
			// 	console.log('agent feed', data);
				
			// 	for(let i in data)
			// 	{
			// 		//console.log(data[i])
			// 			finaldata.push(data[i]);
					
			// 	}
			// 	console.log(finaldata);
			// }
			//console.log(finaldata);
			let tlbase = _.groupBy(data, 'tl_name');
			console.log('tlbase', tlbase);
			let arr = [];
			for(let tl in tlbase){
				let obj:any = {};
				obj.title = tl;
				obj.rData = [];
				this.groupid = tlbase[tl][0]['groupid'];
				tlbase[tl].forEach((agent:any) => {
					obj.rData.push({
						name: agent.head, 
						totalAgents:agent.users_count, 
						loggedIn:this.util.getPercentage(agent.rtd_signedin_count ,agent.users_count,1),
						totalLoggedIn:agent.rtd_signedin_count, 
						liveIn: this.util.getPercentage(agent.rtd_agentsalive_count ,agent.rtd_allagents_count,1), 
						totalLive: agent.rtd_agentsalive_count,
						dailpercall:this.get_division(agent.rtd_dialled_count ,agent.rtd_signedin_count),
						signedIn: agent.rtd_signedin_count
					})
				})
				arr.push(obj);
			}
			// console.log('after add data', arr);
			this.formTable(arr);
		})
	}

	formTable(arr?){
		this._fetchData = []
		if(arr && arr.length){
			this._fetchData = arr;
		}
		this.sortedData.next(this._fetchData.slice());
	}

	sortData(sort: Sort) {
		const data = this._fetchData.slice();
		if (!sort.active || sort.direction === '') {
			this.sortedData.next(data);
			return;
		}

		let p = data.sort((a: any, b: any) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'name':
					return this.compare(a.name, b.name, isAsc);
				case 'totalAgents':
					return this.compare(a.totalAgents, b.totalAgents, isAsc);
				case 'loggedIn':
					return this.compare(a.loggedIn, b.loggedIn, isAsc);
				case 'totalLoggedIn':
					return this.compare(a.totalLoggedIn, b.totalLoggedIn, isAsc);
				case 'liveIn':
					return this.compare(a.liveIn, b.liveIn, isAsc);
				case 'totalLive':
					return this.compare(a.totalLive, b.totalLive, isAsc);
				default:
					return 0;
			}
		});
		this.sortedData.next(p);
	}
	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	getSummationData(variable){
		let total = 0;
		let dataValue:any = this.sortedData.getValue();
		if(dataValue && dataValue.length){
			for (let j = 0; j < dataValue.length; j++) {
				if(dataValue[j].rData && dataValue[j].rData.length){
					for (let k = 0; k < dataValue[j].rData.length; k++)
						total += Number(dataValue[j].rData[k][variable]);
				}
			}
		}
		return total;
	}

	getTotalAgents(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].totalAgents);
		return total;
	}
	getAllTotalAgents() {
		return this.getSummationData('totalAgents');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].totalAgents);
		}
		return total; */
	}


	getLoggedIn(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
		{
			total += Number(d.rData[k].loggedIn);
		}
		var totaltime = this.util.getDivision(total, this.getSummationofallsignedIn()) 
		return totaltime;
		//return total;
	}
	getAllLoggedIn() {
		var total_all= this.getSummationData('loggedIn');

		var totaltime = this.util.getDivision(total_all, this.getSummationofallsignedIn()) 
		return totaltime;
	}

	getTotalLoggedIn(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].totalLoggedIn);
		return total;
	}
	getAllTotalLoggedIn() {
		return this.getSummationData('totalLoggedIn');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].totalLoggedIn);
		}
		return total; */
	}

	getLiveIn(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].liveIn);
		return total;
	}
	getAllLiveIn() {
		return this.getSummationData('liveIn');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].liveIn);
		}
		return total; */
	}

	getTotalLive(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
			total += Number(d.rData[k].totalLive);
		return total;
	}
	getAllTotalLive() {
		return this.getSummationData('totalLive');
		/* let total = 0;
		for (let j = 0; j < this.sortedData.length; j++) {
			for (let k = 0; k < this.sortedData[j].rData.length; k++)
				total += Number(this.sortedData[j].rData[k].totalLive);
		}
		return total; */
	}

	getTotaldialper_agent(d: any) {
		let total = 0;
		for (let k = 0; k < d.rData.length; k++)
		{
			total += Number(d.rData[k].dailpercall);
		}

		var totaltime = this.util.getDivision(total, this.getSummationofallsignedIn()) 
		return totaltime;
	}
	getAlldailpercall(){
		var total_all =  this.getSummationData('dailpercall');

		var totaltime = this.util.getDivision(total_all, this.getSummationofallsignedIn()) 
		return totaltime;
	}

	  get_percent(num, denm,decimals){
        //console.log('printing input get percent',num,denm,decimals);
        if(denm > 0){
            if(num > 0){
                if(decimals){
                    //console.log('printing decimal',((num/denm)*100).toFixed(decimals));
                    return parseFloat(((num/denm)*100).toFixed(0));
                }else{
                    return parseFloat(((num/denm)*100).toFixed(1));
                }
            }else{
                return (0);
            }
        }else{
            return (0);
        }
    }
	get_division(num,denm){
        if(!num || !denm){
            return 0;
        }
        if(!denm || (denm == 0)){
            return 0;
        }else{
            let val = Math.abs(num/denm);
            // if(type == 'percentage'){
              //  return Math.abs(num*100/denm).toFixed(1)
            // }else{
            //     if((delim != undefined) && (delim > -1) && (!isNaN(delim))){
            //         return val.toFixed(parseInt(delim));
            //     }else{
                     return val.toFixed(1);
            //     }
            }
        }
		getSummationofallsignedIn() {
			var total_signin = 0;
			let dataValue: any = this.sortedData.getValue();
			if (dataValue && dataValue.length) {
				for (let j = 0; j < dataValue.length; j++) {
					if (dataValue[j].rData && dataValue[j].rData.length) {
						//console.log(dataValue[j].rData);
						for (let k = 0; k < dataValue[j].rData.length; k++) {
							//total += Number(d.rData[k].idleTime);
							total_signin += dataValue[j].rData[k].signedIn;
						}
					}
				}
			}
			return total_signin;
		}

	ngOnDestroy() {
		this.agentFeed.unsubscribe();
	}
}


