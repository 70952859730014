<!-- Campaign Dashboard starts -->
<br>

<div class="row">
<div class="col-lg-3 col-md-3" style="padding-right: 0px;"> 
	<div class="card">
		<div class="card-header">Total Campaigns - {{campaignSummary.campaigns.total}}</div>

		<div class="card-body">
			<table class="Cam-table">
				<tr><td><span>Application</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="campaignSummary.summary.total_perc"></mat-progress-bar>
					</td>
					<td class="tdData1"><span>{{campaignSummary.summary.total_perc}}% ({{	campaignSummary.summary.total }})</span></td>
				</tr>
				<tr><td><span>Candidates called</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="campaignSummary.summary.data_called_perc"></mat-progress-bar>
					</td>
					<td class="tdData2"><span>{{campaignSummary.summary.data_called_perc}}% ({{campaignSummary.summary.data_called }})</span>
					</td>
				</tr>
				<tr>
					<td><span>Contacted</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="campaignSummary.summary.data_contacted_perc"></mat-progress-bar>
					</td>
					<td class="tdData3"><span>{{campaignSummary.summary.data_contacted_perc}}% ({{campaignSummary.summary.data_contacted}})</span>
					</td>
				</tr>
				<tr>
					<td><span>Selected</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-four'" [value]="campaignSummary.summary.data_selected_perc"></mat-progress-bar>
					</td>
					<td class="tdData4"><span>{{campaignSummary.summary.data_selected_perc}}% ({{campaignSummary.summary.data_selected}})</span>
					</td>
				</tr>
				<tr>
					<td><span>Rejected</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-five'" [value]="campaignSummary.summary.data_rejected_perc"></mat-progress-bar>
					</td>
					<td class="tdData5"><span>{{campaignSummary.summary.data_rejected_perc}}%	({{campaignSummary.summary.data_rejected}})</span>
				 </td>
				</tr>
			</table>
	
		</div>
	</div>
</div>
<div class="col-lg-3 col-md-3" style="padding-right: 0px;"> 
	<div class="card">
		<div class="card-header">Active Campaigns - {{campaignSummary.campaigns.active}}</div>
		<div class="card-body">
			<table class="Cam-table">
				<tr><td><span>Application</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="campaignSummary.campaigns_active.total_perc"></mat-progress-bar>
					</td>
					<td class="tdData1"><span>{{campaignSummary.campaigns_active.total_perc}}% ({{campaignSummary.campaigns_active.total}})</span></td>
				</tr>
				<tr><td><span>Candidates called</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="campaignSummary.campaigns_active.data_called_perc"></mat-progress-bar>
					</td>
					<td class="tdData2"><span>{{campaignSummary.campaigns_active.data_called_perc}}% ({{campaignSummary.campaigns_active.data_called}})</span>
					</td>
				</tr>
				<tr>
					<td><span>Contacted</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="campaignSummary.campaigns_active.data_contacted_perc"></mat-progress-bar>
					</td>
					<td class="tdData3"><span>{{campaignSummary.campaigns_active.data_contacted_perc}}% ({{campaignSummary.campaigns_active.data_contacted}})</span>
					</td>
				</tr>
				<tr>
					<td><span>Selected</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-four'" [value]="campaignSummary.campaigns_active.data_selected_perc"></mat-progress-bar>
					</td>
					<td class="tdData4"><span>{{campaignSummary.campaigns_active.data_selected_perc}}% ({{campaignSummary.campaigns_active.data_selected}})</span>
					</td>
				</tr>
				<tr>
					<td><span>Rejected</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-five'" [value]="campaignSummary.campaigns_active.data_rejected_perc"></mat-progress-bar>
					</td>
					<td class="tdData5"><span>{{campaignSummary.campaigns_active.data_rejected_perc}}% ({{campaignSummary.campaigns_active.data_rejected}})</span>
				 </td>
				</tr>
			</table>
	
		</div>
	</div>
</div>
<div class="col-lg-3 col-md-3" style="padding-right: 0px;"> 
	<div class="card">
		<div class="card-header">InActive Campaigns - {{campaignSummary.campaigns.inactive}}</div>
		<div class="card-body">
			<table class="Cam-table">
				<tr><td><span>Application</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-one'" [value]="campaignSummary.campaigns_inactive.total_perc"></mat-progress-bar>
					</td>
					<td class="tdData1"><span>{{campaignSummary.campaigns_inactive.total_perc}}% ({{campaignSummary.campaigns_inactive.total}})</span></td>
				</tr>
				<tr><td><span>Candidates called</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-two'" [value]="campaignSummary.campaigns_inactive.data_called_perc"></mat-progress-bar>
					</td>
					<td class="tdData2"><span>{{campaignSummary.campaigns_inactive.data_called_perc}}% ({{campaignSummary.campaigns_inactive.data_called}})</span>
					</td>
				</tr>
				<tr>
					<td><span>Contacted</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-three'" [value]="campaignSummary.campaigns_inactive.data_contacted_perc"></mat-progress-bar>
					</td>
					<td class="tdData3"><span>{{campaignSummary.campaigns_inactive.data_contacted_perc}}% ({{campaignSummary.campaigns_inactive.data_contacted}})</span>
					</td>
				</tr>
				<tr>
					<td><span>Selected</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-four'" [value]="campaignSummary.campaigns_inactive.data_selected_perc"></mat-progress-bar>
					</td>
					<td class="tdData4"><span>{{campaignSummary.campaigns_inactive.data_selected_perc}}% ({{campaignSummary.campaigns_inactive.data_selected}})</span>
					</td>
				</tr>
				<tr>
					<td><span>Rejected</span>
						<mat-progress-bar mode="determinate" [ngClass]="'color-five'" [value]="campaignSummary.campaigns_inactive.data_rejected_perc"></mat-progress-bar>
					</td>
					<td class="tdData5"><span>{{campaignSummary.campaigns_inactive.data_rejected_perc}}% ({{campaignSummary.campaigns_inactive.data_rejected}})</span>
				 </td>
				</tr>
			</table>

		</div>
	</div>
</div>
<div class="col-lg-3 col-md-3"> 
	<div class="card">
		<div class="card-header">Candidates Sources</div>
		<div class="card-body">
			<div class="row pb-1">
				<div class="col-md-12">
					<span class=" "> <span class="tile-title-sources" style="color: #fd6c21;">Convertions</span></span>
				</div>
			</div>
			<div class="row pb-2">
				<div class="col-md-6">
					<span class="tile-title-sources">Source</span>
				</div>
				<div class="col-md-6">
					<span class="tile-title-sources">Applications</span>
				</div>
			</div>
			<div class="row pb-1">
				<div class="col-md-6">
					<span> <span class="tile-title-sources">Vahan</span></span>
				</div>
				<div class="col-md-2 text-center">
					<span class="tile-text-appl">{{campaignSummary.agency_name.Vahan}}</span>
				</div>
				<div class="col-md-4 text-right">
					<span class="tile-text-appl"
						style="color: #4ab686;font-weight: 700;">{{campaignSummary.agency_name.Vahan_Perc}}%</span>
				</div>
			</div>
			<div class="row pb-1">
				<div class="col-md-6">
					<span> <span class="tile-title-sources">Workex</span></span>
				</div>
				<div class="col-md-2 text-center">
					<span class="tile-text-appl">{{campaignSummary.agency_name.Workex}}</span>
				</div>
				<div class="col-md-4 text-right">
					<span class="tile-text-appl"
						style="color: #4ab686;font-weight: 700;">{{campaignSummary.agency_name.Workex_Perc}}%</span>
				</div>
			</div>
			<div class="row pb-1">
				<div class="col-md-6">
					<span> <span class="tile-title-sources">Capricco</span></span>
				</div>
				<div class="col-md-2 text-center">
					<span class="tile-text-appl">{{campaignSummary.agency_name.Capricco}}</span>
				</div>
				<div class="col-md-4 text-right">
					<span class="tile-text-appl"
						style="color: #4ab686;font-weight: 700;">{{campaignSummary.agency_name.Capricco_Perc}}%</span>
				</div>
			</div>
			<div class="row pb-1">
				<div class="col-md-6">
					<span> <span class="tile-title-sources">CII</span></span>
				</div>
				<div class="col-md-2 text-center">
					<span class="tile-text-appl">{{campaignSummary.agency_name.Cii}}</span>
				</div>
				<div class="col-md-4 text-right">
					<span class="tile-text-appl"
						style="color: #4ab686;font-weight: 700;">{{campaignSummary.agency_name.CII_Perc}}%</span>
				</div>
			</div>

			<div class="row pb-1">
				<div class="col-md-6">
					<span> <span class="tile-title-sources">Aspiring Minds</span></span>
				</div>
				<div class="col-md-2 text-center">
					<span class="tile-text-appl">{{campaignSummary.agency_name.AspiringMinds }}</span>
				</div>
				<div class="col-md-4 text-right">
					<span class="tile-text-appl"
						style="color: #4ab686;font-weight: 700;">{{campaignSummary.agency_name.AspiringMinds_Perc
						}}%</span>
				</div>
			</div>

			<div class="row pb-1">
				<div class="col-md-6">
					<span> <span class="tile-title-sources">Others</span></span>
				</div>
				<div class="col-md-2 text-center">
					<span class="tile-text-appl">{{campaignSummary.agency_name.Others}}</span>
				</div>
				<div class="col-md-4 text-right">
					<span class="tile-text-appl"
						style="color: #4ab686;font-weight: 700;">{{campaignSummary.agency_name.Others_Perc}}%</span>
				</div>
			</div>
		</div>
	</div>
</div>
</div>







