<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <mat-card>
            <h2 class="d-head">Calls<img src="../assets/underline.svg" class="linebelow"> </h2>

            <div class="searchFix">
				<span style="margin-right: 20px;">
				
					<mat-form-field style="width:110px">
						<input matInput [matDatepicker]="frompicker" [max]="maxDate" placeholder="From" readonly
							(click)="frompicker.open()" (dateChange)="change_date_call_status('start_date', $event)"
							[(ngModel)]="callStartDate">
						<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
						<mat-datepicker #frompicker></mat-datepicker>
					</mat-form-field>
					<mat-form-field class="ml-3" style="width:110px">
						<input #fromInput matInput [matDatepicker]="topicker" [max]="maxDate" placeholder="To" readonly
							(click)="topicker.open()" (dateChange)="change_date_call_status('end_date', $event)"
							[(ngModel)]="callEndDate">
						<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
						<mat-datepicker #topicker></mat-datepicker>
					</mat-form-field>
				
					<button mat-button class="btnAdd ml-3" (click)="refreshData(true)">Go</button>
					<!--*ngIf="!isToday"-->
					<!-- <button mat-button class="btnAdd ml-3" (click)="refreshDatafortoday()">Today</button> -->
				</span>
				<span> 
					<button mat-button class="submitbtn" (click)="downLoadReport()">Download
					Report</button>&nbsp;&nbsp; 
				</span>
				<span>
					<mat-select [ngClass]="'arrowcsDDL'" class="dropdownDDL" (selectionChange)="changeFilter($event.value)" style="width:150px;" placeholder="Select"  [(value)]="selectedFilter">
						<mat-option value="All">All</mat-option>
						  <mat-option value="Active">Active</mat-option>
						  <mat-option value="Inactive">Inactive</mat-option>
					  </mat-select>
				</span>
				<mat-form-field style="font-size: 12px;" appearance="outline">
					<input matInput #searchInput [(ngModel)]="searchFilter" (keyup)="applyFilter($event.target.value)" placeholder="SEARCH">
					<i class="fa fa-search search-icon" matSuffix style="position: relative;
        top: -2px;"></i>
				</mat-form-field>
            </div>
            <br>
            <div>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                    <ng-container matColumnDef="project_name">
                        <th mat-header-cell *matHeaderCellDef>Project </th>
                        <td mat-cell *matCellDef="let element">{{element.project_name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="call_id">
                        <th mat-header-cell *matHeaderCellDef> Call Id </th>
                        <td mat-cell *matCellDef="let element"> {{element.call_id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="agent_name">
                        <th mat-header-cell *matHeaderCellDef> Agent Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.agent_name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="start_time">
                        <th mat-header-cell *matHeaderCellDef> Start </th>
                        <td mat-cell *matCellDef="let element"> {{element.start_time | date:'dd/MM/yyyy h:mm:ss'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="end_time">
                        <th mat-header-cell *matHeaderCellDef> End </th>
                        <td mat-cell *matCellDef="let element"> {{element.end_time | date:'dd/MM/yyyy h:mm:ss'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="quality_score">
                        <th mat-header-cell *matHeaderCellDef> Quality </th>
                        <td mat-cell *matCellDef="let element"> {{element.quality_score}} </td>
                    </ng-container>

                    <ng-container matColumnDef="audit_status">
                        <th mat-header-cell *matHeaderCellDef> Status</th>
                        <td mat-cell *matCellDef="let element"> {{element.audit_status === 1 ? 'Active' : (element.audit_status === 0 ? 'Inactive' : ' ' )}} 
							<!--{{element.audit_status}}--> </td>
                    </ng-container> 

<!-- 
                    <ng-container matColumnDef="detail">
                        <th mat-header-cell *matHeaderCellDef> View Detail </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="detailsCss" (click)="viewAuditors(element)">View Details</span>

                        </td>
                    </ng-container> -->

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> Recording </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon class="CR AMclr" aria-hidden="false" aria-label=""
                                (click)="openCallUrl(element)">audiotrack</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [ngClass]="'auditorPage'" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                </mat-paginator>
            </div>

        </mat-card>
    </div>
</div>