
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MyserviceService } from '../myservice.service';

@Component({
	selector: 'app-batch-days-training',
	templateUrl: './batch-days-training.component.html',
	styleUrls: ['./batch-days-training.component.scss']
})
export class BatchDaysTrainingComponent implements OnInit {

	displayedColumns = ['user_id', 'name', 'attendance', 'remarks']; //'agent_status',

	attendancelist = [
		{ value: '0', viewValue: 'Present' },
		{ value: '1', viewValue: 'Absent' },
		{ value: '2', viewValue: 'NCNS' },
		{ value: '3', viewValue: 'Moved' },
		{ value: '4', viewValue: 'Failure' },
		{ value: '5', viewValue: 'Termination' },
		{ value: '6', viewValue: 'Resignation' }
	];


	dataSource: any = new MatTableDataSource();
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	attendance: any;
	remarks: any;
	_batch_id: any;
	batchDaysRecord: any;
	_arrayBatch: any = [];
	_updateAttendenseArray: any = [];
	_newArrayattendence: any = [];
	fiterArrayDaywiseRecord: any = [];
	trainingDayCount: any;
	_trainingdate: any;
	_dayCount: any;
	_trainerName: any;
	_shiftFrom: any;
	_shiftTo: any;
	maxDays: any;
	finalarrAttendence: any = [];
	_dataArrayTable: any = [];
	inputLink: any = "";
	_meetingID: any;
	showHideAttendance: boolean = true;
	showHideMeeting: boolean = false;
	showHideMessage: boolean = false;
	passingMessage: string = "";
	_color: string = "green";
	allPresent: boolean = false;
	showHideAttachFiles: boolean = false;
	isdisableUpdate: boolean = false;
	showFileTab: boolean = true;
	currentFileobj: any = {};
	batchSessionFiles;
	batchSessionFilesList: any = [];

	_updateName:any="UPDATE"

	api_end_point:any;

	constructor(private dialog: MatDialog, public router: Router, private API: ApiService, 
		private myService: MyserviceService,private _snackBar: MatSnackBar) { }

	ngOnInit() {
		this._batch_id = this.API.getBatchIDSession();
		this.api_end_point = this.myService.get_API_End_Point();
		this.getTrainingSession(this._batch_id);
		let _meetingUrl = this.API.getmeetingLinkForBatch();
		this.inputLink = _meetingUrl;
	}
	getTrainingSession(id: any) {
		this.API.getBatchTrainingSession(id,this.api_end_point).subscribe((data: any) => {
			if (data.success) {
				let _response = data.success;
				this.batchDaysRecord = _response;
				let maxDays = this.getMax(this.batchDaysRecord, "day_count");
				this.maxDays = maxDays.day_count.toString();
				
				this.getAttendencedata(_response);
				if (this.maxDays) {
					const index = this.batchDaysRecord.findIndex(v => { return v.day_count == Number(this.maxDays) })
					if (this.batchDaysRecord[index].files){
						this.batchSessionFilesList = JSON.parse(this.batchDaysRecord[index].files);
					}
					
				}

			}
		});
	}

	getAttendencedata(_response) {
		//console.log("--correct-API",_response); 
		this.API.getAttendence(_response,this.api_end_point).subscribe((data: any) => {
			let ELEMENT_DATA = data.success;
			ELEMENT_DATA.forEach(function (v) {
				v.attendance = String(v.attendance);
			});
			//let _fiterdayWise:any;
			//_fiterdayWise=ELEMENT_DATA.filter((v)=>{return v.batch_session_id==this.fiterArrayDaywiseRecord[0].batch_session_id});

			//console.log(_fiterdayWise);
			this.dataSource = new MatTableDataSource(ELEMENT_DATA);
			this.dataSource.paginator = this.paginator;


			var currentdate = new Date();

			var date1 = moment(this.dataSource.data[0].agent_session_date);
			var date2 = moment(currentdate);

			// console.log(date1, date2)
			// console.log(date1.date(), date2.date());
			if (date1.date() === date2.date() || date2.date() - date1.date() == 1) {
				this.isdisableUpdate=false;
			} else {
				this.isdisableUpdate=true;
			}
			this.dataSource.filterPredicate = function (data, filter: any): boolean {
				return data.user_id.toString().toLowerCase().includes(filter) || data.name.toString().toLowerCase().includes(filter)
			};

		});
	}
	dayCountChange(val: any) {
		this.changeTogleBatchDays('attendance')
		this.batchSessionFilesList = [];
		this.fiterArrayDaywiseRecord = this.batchDaysRecord.filter(v => { return v.day_count == Number(val) });
		let maxDays = this.getMax(this.batchDaysRecord, "day_count");
		if (Number(val) != Number(maxDays.day_count)){
			this.showFileTab = false;
		}else{
			this.showFileTab = true;
		}
		const index = this.batchDaysRecord.findIndex(v => { return v.day_count == Number(val) })
		if (this.batchDaysRecord[index].files) {
			this.batchSessionFilesList = JSON.parse(this.batchDaysRecord[index].files);
		}
		this.trainingDayCount = this.fiterArrayDaywiseRecord[0].training_days;
		this._trainingdate = this.fiterArrayDaywiseRecord[0].training_date;
		this._dayCount = this.fiterArrayDaywiseRecord[0].day_count;
		this._trainerName = this.fiterArrayDaywiseRecord[0].trainer_name;
		this._shiftFrom = (this.fiterArrayDaywiseRecord[0].batch_time).substring(0, 5);
		this._shiftTo = this.addMinutes(this.fiterArrayDaywiseRecord[0].batch_time, this.fiterArrayDaywiseRecord[0].duration.toString());
		//this.getFilterTablewithSessionId();
		this.getAttendencedata(this.fiterArrayDaywiseRecord);
	}

	changeFile(event) {
		this.currentFileobj = {};
		
		if (event.target.files && event.target.files.length) {
			console.log(event.target.files);
			this.currentFileobj = event.target.files[0];
			
		}
	}

	updateAttachFiles(){
		
		if (!this.currentFileobj.name){
			return;
		}
		this._updateName = "UPDATING...";
		let formData = new FormData();
		formData.append('image', this.currentFileobj, this.currentFileobj.name);
		this.API.uploadAnyFile(formData,this.api_end_point).subscribe(
			(resFile: any) => {
				if (this.batchDaysRecord && this.batchDaysRecord.length > 0) {
					var files = this.batchSessionFilesList;
					if (files == null) {
						files = [resFile.url];
					} else {
						files.push(resFile.url);
					}
					this.batchSessionFiles = files;
					console.log(this.batchSessionFiles)
				}
				this.API.updateTrainingAttachFiles({ batch_id: this._batch_id, training_files: JSON.stringify(this.batchSessionFiles) }).subscribe(
					(res: any) => {
						this._updateName = "UPDATE";
						this.currentFileobj = {};
						this.batchSessionFiles = '';
						const index = this.batchDaysRecord.findIndex(v => { return v.day_count == Number(this.maxDays) })
						this.batchDaysRecord[index].files = res.success[0].files;

					}, (err) => {
						console.log(err)
					}
				)

			}, (err) => {
				console.log(err)
			}
		)
		
	}	

	updateAttendence() {
		console.log();
		//debugger;
		// if(this.isdisableUpdate ==true){
		// 	return;
		// }
		console.log("batch bay records--" + this.batchDaysRecord);
		console.log(this.dataSource.data);
		// console.log();
		// debugger;
		if(this.isdisableUpdate ==true){
			return;
		}
		// console.log("batch bay records--" + this.batchDaysRecord);
		// console.log(this.dataSource.data);
		var currentdate = new Date();
			
		var date1 = moment(this.dataSource.data[0].agent_session_date);
		var date2 = moment(currentdate);

		// console.log(date1, date2)
		// console.log(date1.date(), date2.date());
		if (date1.date() === date2.date() || date2.date() - date1.date() == 1) {
			this._arrayBatch = this.dataSource.data;
			this._updateAttendenseArray = (this._arrayBatch).filter(
				v => (v.attendance == '0' || v.attendance == '1' || v.attendance == 0 || v.attendance == 1 || v.attendance == '2' || v.attendance == '3' || v.attendance == 2 || v.attendance == 3 || v.attendance == '4' || v.attendance == '5' || v.attendance == 4 || v.attendance == 5 || v.attendance == '6' || v.attendance == 6)
			);
			if (this.allPresent) {
				this._updateAttendenseArray = (this._arrayBatch).filter(
					v => v
				);
			}
			for (let i = 0; i < this._updateAttendenseArray.length; i++) {
				this._newArrayattendence.push({
					"agent_session_id": this._updateAttendenseArray[i].agent_session_id,
					"attendance": this.allPresent ? 1 : this._updateAttendenseArray[i].attendance,
					"agent_remarks": this._updateAttendenseArray[i].remarks,
					"user_id": this._updateAttendenseArray[i].user_id
				});
			}

			let _obj = {
				"attendance_data": JSON.parse(JSON.stringify(this._newArrayattendence))
			}

			//console.log(_obj);
			//debugger;
			this.API.updateAttendence(_obj,this.api_end_point).subscribe((data: any) => {
				let _response = data.success;
				if (_response) {
					//  alert("Attendance Data updated Successfully");
					this._color = "green";
					this.passingMessage = "Attendance Data updated Successfully";
					this.getTrainingSession(this._batch_id);
					setTimeout(() => {
						this.passingMessage = "";
					}, 3000)
				}
			});
		}
		else {
			this._snackBar.open("Not able to update Old records", "close");
			//console.log("not able to update it");
		}
	}

	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}
	getStatusColor(val) {
		let obj = {
			'color': 'Inherit'
		}
		switch (val) {
			case 'Active':
				obj.color = 'gray';
				break;
			case 'Inactive':
				obj.color = 'red';
				break;
		}
		return obj;
	}
	getMax(arr, prop) {
		var max;
		for (var i = 0; i < arr.length; i++) {
			if (!max || parseInt(arr[i][prop]) > parseInt(max[prop]))
				max = arr[i];
		}
		return max;
	}

	addMinutes(time, minsToAdd) {
		function D(J) { return (J < 10 ? '0' : '') + J };

		var piece = time.split(':');

		var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

		return D(mins % (24 * 60) / 60 | 0) + ':' + D(mins % 60);
	}
	selectAllPresent(val: any) {
		//console.log(val);
		this.allPresent = true;
	}
	cancelAttendenceBtn() {
		this.router.navigateByUrl('/training/batch')
	}



	meetLink(type: any) {
		if (type == "googleMeet") {
			this._meetingID = "1";
		}
		else if (type == "microsoftMeet") {
			this._meetingID = "2";
		}
		else if (type == "zoomMeet") {
			this._meetingID = "3";
		}
	}
	sendLink() {
		this.showHideMessage = false;
		let _meetingLink = this.inputLink;
		if (_meetingLink == "") {
			this.showHideMessage = true;
			return false;
		}
		let _obj: any = {
			"notification_type": "Batch",
			"notification_subject": "Training Link Batch " + this._batch_id,
			"notification_body": "<p>" + _meetingLink + "</p>",
			"batch_list": [this._batch_id]
		}
		this.API.sendNotifications(_obj,this.api_end_point).subscribe((data: any) => {
			if (data.success) {
				// alert("Meeting Link sent successfully");
				this._color = "green";
				this.passingMessage = "Meeting Link sent successfully";
				setTimeout(() => {
					this.passingMessage = "";
				}, 3000)
			}
			else {
				// alert(data.errors);
				this._color = "red";
				this.passingMessage = data.errors;
				setTimeout(() => {
					this.passingMessage = "";
				}, 3000)
			}
		})

		let _modelObject = {
			"batch_code": Number(this._batch_id),
			"training_location": _meetingLink
		}
		this.API.modifyBatch(_modelObject,this.api_end_point).subscribe((data: any) => {
			if (data.success) {
				// alert("Meeting Link sent successfully");
				this._color = "green";
				this.passingMessage = "Meeting Link sent successfully";
				setTimeout(() => {
					this.passingMessage = "";
				}, 3000)
			}
			else {
				alert(data.errors);
				this._color = "red";
				this.passingMessage = data.errors;
				setTimeout(() => {
					this.passingMessage = "";
				}, 3000)
			}
		})

	}
	changeTogleBatchDays(val: any) {
		if (val == "attendance") {
			this.showHideAttendance = true;
			this.showHideMeeting = false;
			this.showHideAttachFiles = false;
		}
		else if (val == "meeting") {
			this.showHideAttendance = false;
			this.showHideMeeting = true;
			this.showHideAttachFiles = false;
			let _meetingUrl = this.API.getmeetingLinkForBatch();
			this.inputLink = _meetingUrl;
		} else if (val == "attach_files") {
			this.showHideAttendance = false;
			this.showHideMeeting = false;
			this.showHideAttachFiles = true;
		}
	}
	deleteInput() {
		this.inputLink = "";
	}
	valueChange() {
		if (this.inputLink.length > 0) {
			this.showHideMessage = false;
		}
	}
}


// getFilterTablewithSessionId(){
//   debugger; 
//   let finalarrAttendence=[];
// if(this.dataSource.data.length==0){
//   this.getAttendencedata(this.batchDaysRecord);
// }
//   this._dataArrayTable=this.dataSource.data;
//   for (let i = 0; i < this._dataArrayTable.length; i++) {
//       for (let j = 0; j < this.fiterArrayDaywiseRecord.length; j++) {
//         debugger;
//       if (this._dataArrayTable[i].batch_session_id == this.fiterArrayDaywiseRecord[j].batch_session_id) {
//         debugger;
//       this.finalarrAttendence.push(this._dataArrayTable[i]);
//       }
//   }
//   }

//  this.dataSource = new MatTableDataSource(finalarrAttendence);
//   this.dataSource.paginator = this.paginator;
// }