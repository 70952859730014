import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as _ from 'underscore';
import { MyserviceService } from '../myservice.service';
import { RtddataService } from '../rtdData.service';
import * as moment from 'moment';
import { parse } from 'path';
import { Utils } from '../utils';
import { ApiService } from '../api.service';
import { difference } from 'underscore';

@Component({
	selector: 'app-badashboard-active-time-page',
	templateUrl: './badashboard-active-time-page.component.html',
	styleUrls: ['./badashboard-active-time-page.component.scss']
})
export class BadashboardActiveTimePageComponent implements OnInit, OnDestroy {

	_fetchData: any = [];
	agentFeed: Subscription;
	sortedData: BehaviorSubject<[]> = new BehaviorSubject([]);
	util = new Utils();
	private utils = new Utils();
	withouttimeproperty: boolean = false;
	withtimeproperty: boolean = true;
	api_end_point: any;
	callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	id: any;
	groupid: any;
	group_id:any=null;
	constructor(private rtd: RtddataService, private myService: MyserviceService, private API: ApiService) {

	}
	ngOnInit() {
		this.api_end_point = this.myService.get_API_End_Point();
		this.group_id = this.myService.get_asm_groupid();
		//console.log("---",JSON.stringify(this.groupid));
		this.getRtdValues();
		this.id = this.battleInit();
		setInterval(() => {
			this.battleInit();
		}, (1000 * 60 * 5));
	}

	battleInit() {
		let objReq: any = {};
		this.callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
		this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
		objReq.start_date = this.utils.localToUTC(this.callStartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss')
		objReq.end_date = this.utils.localToUTC(this.callEndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
		//console.log("---",JSON.stringify(this.groupid));
		let filtered_data=[];
		if(this.groupid == null || this.group_id == undefined)
		{
			objReq.agents_list = this.myService.getAllIds('user', 'id');
			this.timer_value_by_API(objReq);
		}else{
			let obj = {
				group_id: this.groupid
			}
			this.API.getGroupwiseUser(obj, this.api_end_point).subscribe(
				(res: any) => {
					// debugger;
					if (res.error) {
						console.log(res.error);
					}
					else {
						//console.log(res.success);
						let copy_data = res.success;
						 copy_data.filter((e)=>{
							if(e.role == "user" && e.groupid ==obj.group_id)
							{
								console.log(e.id);
								filtered_data.push(e.id);
								return e.id;
							}
						})
						console.log("---JSON--",JSON.stringify(filtered_data));
						objReq.agents_list = filtered_data;
						this.timer_value_by_API(objReq);
					}
				})
		}
		
	}

	timer_value_by_API (details)
	{
		var no_of_days = 0;
		let timer_count_obj: any = {
			"start_date": details.start_date,
			"end_date": details.end_date,
			"users_list": details.agents_list,
			"id_group":this.groupid
		}
		console.log(JSON.stringify(timer_count_obj))
		this.API.getGenericTimersData1(timer_count_obj, this.api_end_point).subscribe((res: any) => {

			let time = moment().set("hour", 0).set("minute", 0).set("second", 0).format('HH:mm:ss');
			// console.log('printing success', res.success);
			if (res.success) {
				let agent_list = this.sortedData['_value'];

				for (let i in res.success) {

					//console.log('data', res.success[i]);
					for (let j = 0; j < agent_list.length; j++) {
						for (let k = 0; k < this.sortedData['_value'][j].rData.length; k++) {
							let total_seconds = 0;
							let total_minutes = 0;
							let total_hours = 0;
							let total_seconds1 = 0;
							let total_minutes1 = 0;
							let total_hours1 = 0;
							let resultstring = moment().set("hour", 0).set("minute", 0).set("second", 0).format('HH:mm:ss');
							let resultstring1 = moment().set("hour", 0).set("minute", 0).set("second", 0).format('HH:mm:ss');
							let timer_value_wait_time = moment().set("hour", 0).set("minute", 0).set("second", 0).format('HH:mm:ss');
							let timer_value = moment().set("hour", 0).set("minute", 0).set("second", 0).format('HH:mm:ss');
							let seconds = 0;
							let minutes = 0;
							let hours = 0;
							let seconds1 = 0;
							let minutes1 = 0;
							let hours1 = 0;
							//console.log("----", res.success[i].agent.name, "---", this.sortedData['_value'][j].rData[k]);
							if (this.sortedData['_value'][j].rData[k].name == res.success[i].agent.name) {
								if (this.sortedData['_value'][j].rData[k].idleTime == "Invalid date" || this.sortedData['_value'][j].rData[k].idleTime == undefined || this.sortedData['_value'][j].rData[k].idleTime == null || this.sortedData['_value'][j].rData[k].waitTime == "Invalid date" || this.sortedData['_value'][j].rData[k].waitTime == undefined || this.sortedData['_value'][j].rData[k].waitTime == null) {
									this.sortedData['_value'][j].rData[k].idleTime = resultstring;
									this.sortedData['_value'][j].rData[k].waitTime = resultstring1;	
								}	else{
									if (res.success[i].activity.idle_time != 0) {
										//console.log("----", res.success[i].agent.name, "---", this.sortedData['_value'][j].rData[k].name);
										//console.log("--idleTime--", this.sortedData['_value'][j].rData[k].idleTime);
										//console.log("--idleTime1--", res.success[i].activity.idle_time);
										timer_value = moment.utc(res.success[i].activity.idle_time * 1000).format('HH:mm:ss');
										//console.log("---",timer_value);
										timer_value_wait_time = moment.utc(res.success[i].activity.wait_time * 1000).format('HH:mm:ss');
										seconds = parseInt(timer_value.split(':')[2]);
										minutes = parseInt(timer_value.split(':')[1]);
										hours = parseInt(timer_value.split(':')[0]);

										seconds1 = parseInt(timer_value_wait_time.split(':')[2]);
										minutes1 = parseInt(timer_value_wait_time.split(':')[1]);
										hours1 = parseInt(timer_value_wait_time.split(':')[0]);

										//console.log("-active--"+hours+"--"+minutes+"---"+seconds);
										total_hours = total_hours + hours;
										total_minutes = total_minutes + minutes;
										total_seconds = total_seconds + seconds;

										if (total_minutes >= 60) {
											total_hours = total_hours + 1;
											total_minutes = total_minutes - 60;
										}
										if (total_seconds >= 60) {
											total_minutes = total_minutes + 1;
											total_seconds = total_seconds - 60;
										}
										total_hours1 = total_hours1 + hours1;
										total_minutes1 = total_minutes1 + minutes1;
										total_seconds1 = total_seconds1 + seconds1;

										if (total_minutes1 >= 60) {
											total_hours1 = total_hours1 + 1;
											total_minutes1 = total_minutes1 - 60;
										}
										if (total_seconds1 >= 60) {
											total_minutes1 = total_minutes1 + 1;
											total_seconds1 = total_seconds1 - 60;
										}
										//console.log("---",total_hours,"---",total_minutes,"----",total_seconds);
										let time = moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds);
										//console.log(time)
										resultstring = moment(time).format('HH:mm:ss');
										//resultstring = `${total_hours}:${total_minutes}:${total_seconds}`;
										//console.log("--sort--",this.sortedData['_value'][j]);
										let time1 = moment().set("hour", total_hours1).set("minute", total_minutes1).set("second", total_seconds1);
										//console.log(this.sortedData['_value'][j].rData[k].name, "-idleTime--", resultstring);
										//console.log(this.sortedData['_value'][j].rData[k].name, "-waitTime--", resultstring1);
										resultstring1 = moment(time1).format('HH:mm:ss');
										//resultstring1 = `${total_hours1}:${total_minutes1}:${total_seconds1}`;
										let compare_wait_time = (this.sortedData['_value'][j].rData[k].waitTime);
										let a = compare_wait_time.split(':');

										//console.log("--RTD--",(this.convertToSeconds(a[0],a[1],a[2])),"-----------",res.success[i].activity.wait_time)
										if((res.success[i].activity.wait_time) > (this.convertToSeconds(a[0],a[1],a[2])))
										{
											//difference b/w time add or sub in Active time
											//console.log("-1-",)
											if(this.sortedData['_value'][j].rData[k].activeTime == 0)
											{
												this.sortedData['_value'][j].rData[k].activeTime +=res.success[i].activity.wait_time;
											}else{
												let active_time = this.sortedData['_value'][j].rData[k].activeTime ;
												this.sortedData['_value'][j].rData[k].activeTime = (active_time - this.convertToSeconds(a[0],a[1],a[2])) +res.success[i].activity.wait_time ;
											}

										}
										else if((res.success[i].activity.wait_time) < (this.convertToSeconds(a[0],a[1],a[2]))){
											//difference b/w time add or sub in Active time
											//console.log("-1-",)
											if(this.sortedData['_value'][j].rData[k].activeTime == 0)
											{
												this.sortedData['_value'][j].rData[k].activeTime +=res.success[i].activity.wait_time;
											}else{
												let active_time = this.sortedData['_value'][j].rData[k].activeTime ;
												this.sortedData['_value'][j].rData[k].activeTime = (active_time - this.convertToSeconds(a[0],a[1],a[2])) +res.success[i].activity.wait_time ;
											}
										}else{
											//console.log("-2-",);
										}
										this.sortedData['_value'][j].rData[k].idleTime = resultstring;
										this.sortedData['_value'][j].rData[k].waitTime = resultstring1;


									}
								}
							}
						}
					}
				}
			}
		})
	}
	getRtdValues() {
		//this.groupid = this.myService.get_asm_groupid();
		this.agentFeed = this.rtd.agentFeed.subscribe(data => {

			// console.log(finaldata);
			let tlbase = _.groupBy(data, 'tl_name');
			////console.log('tlbase', tlbase);
			let arr = [];
			for (let tl in tlbase) {
				let obj: any = {};
				obj.title = tl;
				obj.rData = [];
				this.groupid = tlbase[tl][0]['groupid'];
				tlbase[tl].forEach((agent: any) => {
					obj.rData.push({
						name: agent.head,
						idleTime: this.get_time_format(parseInt(agent.rtd_idletime_count) / agent.rtd_signedin_count),
						waitTime: this.get_time_format(parseInt(agent.rtd_waittime_count) / agent.rtd_signedin_count),
						spokenTime: this.get_time_format(parseInt(agent.rtd_spokentime_count) / agent.rtd_signedin_count),
						wrapTime: this.get_time_format(parseInt(agent.rtd_wraptime_count) / agent.rtd_signedin_count),
						activeTime: agent.rtd_activetime_count,
						dailpercall: this.get_division(agent.rtd_dialled_count, agent.rtd_signedin_count),
						totalLoggedIn: agent.usercount,
						signedIn: agent.rtd_signedin_count
					})
				})
				arr.push(obj);
			}
			////console.log('after add data', JSON.stringify(arr));
			this.formTable(arr);
		})
	}
	convertToSeconds(hours, minutes, seconds) {
		return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
	  }

	formTable(arr?) {
		this._fetchData = []
		if (arr && arr.length) {
			this._fetchData = arr;
		}
		this.sortedData.next(this._fetchData.slice());
	}

	sortData(sort: Sort) {
		const data = this._fetchData.slice();
		if (!sort.active || sort.direction === '') {
			this.sortedData = data;
			return;
		}

		this.sortedData = data.sort((a: any, b: any) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'name':
					return this.compare(a.name, b.name, isAsc);
				case 'idleTime':
					return this.compare(a.idleTime, b.idleTime, isAsc);
				case 'waitTime':
					return this.compare(a.waitTime, b.waitTime, isAsc);
				case 'spokenTime':
					return this.compare(a.spokenTime, b.spokenTime, isAsc);
				case 'wrapTime':
					return this.compare(a.wrapTime, b.wrapTime, isAsc);
				case 'activeTime':
					return this.compare(a.activeTime, b.activeTime, isAsc);
				default:
					return 0;
			}
		});
	}
	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
	getSummationData(variable) {
		let total = 0;
		var total_seconds = 0;
		var total_minutes = 0
		var total_hours = 0;
		let dataValue: any = this.sortedData.getValue();
		if (dataValue && dataValue.length) {
			for (let j = 0; j < dataValue.length; j++) {
				if (dataValue[j].rData && dataValue[j].rData.length) {
					////console.log(dataValue[j].rData);
					for (let k = 0; k < dataValue[j].rData.length; k++)
						// //console.log(JSON.stringify(dataValue[j].rData[k])+"---");
						total += Number(dataValue[j].rData[k][variable]);
				}
			}
		}
		return total;
	}
	getIdleTime(d: any) {
		var total_seconds = 0;
		var total_minutes = 0
		var total_hours = 0;
		for (let k = 0; k < d.rData.length; k++) {
			//total += Number(d.rData[k].idleTime);
			var seconds = Number(d.rData[k].idleTime.split(':')[2]);
			var minutes = Number(d.rData[k].idleTime.split(':')[1]);
			var hours = Number(d.rData[k].idleTime.split(':')[0]);

			//	//console.log("-IdleTime--"+hours+"--"+minutes+"---"+seconds);
			total_hours = total_hours + hours;
			total_minutes = total_minutes + minutes;
			total_seconds = total_seconds + seconds;

			if (total_minutes >= 60) {
				total_hours = total_hours + 1;
				total_minutes = total_minutes - 60;
			}
			if (total_seconds >= 60) {
				total_minutes = total_minutes + 1;
				total_seconds = total_seconds - 60;
			}
		}
		// //console.log(m)
		//let total_time = moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds);
		var time = moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds);
		////console.log(time)
		return moment(time).format('HH:mm:ss');
		//return `${total_hours}:${total_minutes}:${total_seconds}`;
		//return total_time //moment(total_time).format('HH:mm:ss');
	}

	getAllIdleTime() {
		//return this.getAllDataSummation('idleTime');
		return this.getSummationcommonData('idleTime');

	}


	getWaitTime(d: any) {
		var total_seconds = 0;
		var total_minutes = 0
		var total_hours = 0;
		for (let k = 0; k < d.rData.length; k++) {
			//total += Number(d.rData[k].idleTime);
			var seconds = parseInt(d.rData[k].waitTime.split(':')[2]);
			var minutes = parseInt(d.rData[k].waitTime.split(':')[1]);
			var hours = parseInt(d.rData[k].waitTime.split(':')[0]);

			////console.log("-waittime--"+hours+"--"+minutes+"---"+seconds);
			total_hours = total_hours + hours;
			total_minutes = total_minutes + minutes;
			total_seconds = total_seconds + seconds;

			if (total_minutes >= 60) {
				total_hours = total_hours + 1;
				total_minutes = total_minutes - 60;
			}
			if (total_seconds >= 60) {
				total_minutes = total_minutes + 1;
				total_seconds = total_seconds - 60;
			}

		}
		// //console.log(m)
		let total_time = moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds);
		//return `${total_hours}:${total_minutes}:${total_seconds}`;
		return total_time //moment(total_time).format('HH:mm:ss');
	}
	getAllWaitTime() {
		//return this.getAllDataSummation('waitTime');
		return this.getSummationcommonData('waitTime');
	}

	getSpokenTime(d: any) {
		var total_seconds = 0;
		var total_minutes = 0
		var total_hours = 0;
		var no_of_days = 0;
		for (let k = 0; k < d.rData.length; k++) {
			//total += Number(d.rData[k].idleTime);
			var seconds = parseInt(d.rData[k].spokenTime.split(':')[2]);
			var minutes = parseInt(d.rData[k].spokenTime.split(':')[1]);
			var hours = parseInt(d.rData[k].spokenTime.split(':')[0]);

			////console.log("-Spoken--"+hours+"--"+minutes+"---"+seconds);
			total_hours = total_hours + hours;
			total_minutes = total_minutes + minutes;
			total_seconds = total_seconds + seconds;

			if (total_minutes >= 60) {
				total_hours = total_hours + 1;
				total_minutes = total_minutes - 60;
			}
			if (total_seconds >= 60) {
				total_minutes = total_minutes + 1;
				total_seconds = total_seconds - 60;
			}
			// if (total_hours > 24) {
			// 	no_of_days = no_of_days + Math.round(total_hours / 24);
			// 	total_hours = (total_hours % 24);
			// }
		}
		// //console.log(m)
		if (no_of_days > 0) {
			this.withouttimeproperty = true;
			this.withtimeproperty = false;
			return `${total_hours}:${total_minutes}:${total_seconds}`;

		} else {
			this.withouttimeproperty = false;
			this.withtimeproperty = true;
			//let total_time = moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds);
			var time = moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds);
			//console.log(time)
			return moment(time).format('HH:mm:ss');
			//return `${total_hours}:${total_minutes}:${total_seconds}`;
			//return total_time //moment(total_time).format('HH:mm:ss');
		}
	}

	getAllSpokenTime() {
		//return this.getAllDataSummation('spokenTime');
		return this.getSummationspeakData('spokenTime');
		//return this.getSummationData('spokenTime');
	}

	getWrapTime(d: any) {
		var total_seconds = 0;
		var total_minutes = 0
		var total_hours = 0;
		for (let k = 0; k < d.rData.length; k++) {
			//total += Number(d.rData[k].idleTime);
			var seconds = parseInt(d.rData[k].wrapTime.split(':')[2]);
			var minutes = parseInt(d.rData[k].wrapTime.split(':')[1]);
			var hours = parseInt(d.rData[k].wrapTime.split(':')[0]);

			////console.log("-wraptime--"+hours+"--"+minutes+"---"+seconds);
			total_hours = total_hours + hours;
			total_minutes = total_minutes + minutes;
			total_seconds = total_seconds + seconds;

			if (total_minutes >= 60) {
				total_hours = total_hours + 1;
				total_minutes = total_minutes - 60;
			}
			if (total_seconds >= 60) {
				total_minutes = total_minutes + 1;
				total_seconds = total_seconds - 60;
			}
		}
		// //console.log(m)
		let total_time = moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds);
		//return `${total_hours}:${total_minutes}:${total_seconds}`;
		return total_time //moment(total_time).format('HH:mm:ss');
	}
	getAllWrapTime() {
		// return this.getSummationData('wrapTime');
		return this.getSummationcommonData('wrapTime');
	}

	getSummationcommonData(variable) {
		let total = 0;
		var total_seconds = 0;
		var total_minutes = 0
		var total_hours = 0;
		let dataValue: any = this.sortedData.getValue();
		if (dataValue && dataValue.length) {
			for (let j = 0; j < dataValue.length; j++) {
				if (dataValue[j].rData && dataValue[j].rData.length) {
					////console.log(dataValue[j].rData);
					for (let k = 0; k < dataValue[j].rData.length; k++) {
						//	total += Number(dataValue[j].rData[k][variable]);
						//var seconds = Number(dataValue[j].rData[k][variable]);
						var s = parseInt(dataValue[j].rData[k][variable].split(':')[2]);
						var m = parseInt(dataValue[j].rData[k][variable].split(':')[1]);
						var h = parseInt(dataValue[j].rData[k][variable].split(':')[0]);

						total_hours = total_hours + h;
						total_minutes = total_minutes + m;
						total_seconds = total_seconds + s;

						if (total_minutes > 60) {
							total_hours = total_hours + 1;
							total_minutes = total_minutes - 60;
						}
						if (total_seconds > 60) {
							total_minutes = total_minutes + 1;
							total_seconds = total_seconds - 60;
						}
					}
				}
			}
			// //console.log(JSON.stringify(dataValue[j].rData[k])+"---");
		}
		////console.log("-total-active--" + total_hours + "--" + total_minutes + "---" + total_seconds);
		let total_time = moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds);
		//return `${total_hours}:${total_minutes}:${total_seconds}`;
		return total_time //moment(total_time).format('HH:mm:ss');
	}
	getSummationspeakData(variable) {
		let total = 0;
		var total_seconds = 0;
		var total_minutes = 0
		var total_hours = 0;
		let dataValue: any = this.sortedData.getValue();
		if (dataValue && dataValue.length) {
			for (let j = 0; j < dataValue.length; j++) {
				if (dataValue[j].rData && dataValue[j].rData.length) {
					////console.log(dataValue[j].rData);
					for (let k = 0; k < dataValue[j].rData.length; k++) {
						//	total += Number(dataValue[j].rData[k][variable]);
						//var seconds = Number(dataValue[j].rData[k][variable]);
						var s = parseInt(dataValue[j].rData[k][variable].split(':')[2]);
						var m = parseInt(dataValue[j].rData[k][variable].split(':')[1]);
						var h = parseInt(dataValue[j].rData[k][variable].split(':')[0]);

						total_hours = total_hours + h;
						total_minutes = total_minutes + m;
						total_seconds = total_seconds + s;

						if (total_minutes >= 60) {
							total_hours = total_hours + 1;
							total_minutes = total_minutes - 60;
						}
						if (total_seconds >= 60) {
							total_minutes = total_minutes + 1;
							total_seconds = total_seconds - 60;
						}
					}
				}
			}
			// //console.log(JSON.stringify(dataValue[j].rData[k])+"---");
		}
		return moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds).format('HH:mm:ss');
		//return `${total_hours}:${total_minutes}:${total_seconds}`;
		//moment(total_time).format('HH:mm:ss');
	}

	getActiveTime(d: any) {
		var total_seconds = 0;
		var total_minutes = 0;
		var total_hours = 0;
		var no_of_days = 0;
		let total_time;
		for (let k = 0; k < d.rData.length; k++) {
			//total += Number(d.rData[k].idleTime);
			var timer_value = moment.utc(d.rData[k].activeTime * 1000).format('HH:mm:ss');
			var seconds = parseInt(timer_value.split(':')[2]);
			var minutes = parseInt(timer_value.split(':')[1]);
			var hours = parseInt(timer_value.split(':')[0]);

			//console.log("-active--"+hours+"--"+minutes+"---"+seconds);
			total_hours = total_hours + hours;
			total_minutes = total_minutes + minutes;
			total_seconds = total_seconds + seconds;

			if (total_minutes >= 60) {
				total_hours = total_hours + 1;
				total_minutes = total_minutes - 60;
			}
			if (total_seconds >= 60) {
				total_minutes = total_minutes + 1;
				total_seconds = total_seconds - 60;
			}
		}
		if (no_of_days > 0) {
			this.withouttimeproperty = true;
			this.withtimeproperty = false;
			return moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds).format('HH:mm:ss');
			//return `${total_hours}:${total_minutes}:${total_seconds}`;
			//moment(total_time).format('HH:mm:ss');
			//return `${total_hours}:${total_minutes}:${total_seconds}`;

		} else {
			this.withouttimeproperty = false;
			this.withtimeproperty = true;

			//total_time = moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds);
			return moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds).format('HH:mm:ss');
			//moment(total_time).format('HH:mm:ss');
			//return total_time //moment(total_time).format('HH:mm:ss');
		}

	}

	getAllActiveTime() {
		var total = this.getSummationActiviteData('activeTime');

		////console.log("--total-active--" + total);
		return total;
	}
	getSummationActiviteData(variable) {
		let total = 0;
		var total_seconds = 0;
		var total_minutes = 0
		var total_hours = 0;
		let dataValue: any = this.sortedData.getValue();
		if (dataValue && dataValue.length) {
			for (let j = 0; j < dataValue.length; j++) {
				if (dataValue[j].rData && dataValue[j].rData.length) {
					////console.log(dataValue[j].rData);
					for (let k = 0; k < dataValue[j].rData.length; k++) {
						//console.log("----final----"+dataValue[j].rData[k].activeTime);
						var timer_value = moment.utc(dataValue[j].rData[k][variable] * 1000).format('HH:mm:ss');
						var seconds = parseInt(timer_value.split(':')[2]);
						var minutes = parseInt(timer_value.split(':')[1]);
						var hours = parseInt(timer_value.split(':')[0]);

						//console.log("-active--"+hours+"--"+minutes+"---"+seconds);
						total_hours = total_hours + hours;
						total_minutes = total_minutes + minutes;
						total_seconds = total_seconds + seconds;

						if (total_minutes >= 60) {
							total_hours = total_hours + 1;
							total_minutes = total_minutes - 60;
						}
						if (total_seconds >= 60) {
							total_minutes = total_minutes + 1;
							total_seconds = total_seconds - 60;
						}
					}
				}
			}
			// //console.log(JSON.stringify(dataValue[j].rData[k])+"---");
		}
		var time = moment().set("hour", total_hours).set("minute", total_minutes).set("second", total_seconds);
		//console.log(time)
		return moment(time).format('HH:mm:ss');
		//`${total_hours}:${total_minutes}:${total_seconds}`;
		//return total_time;
	}
	getAllDataSummation(variable) {
		let total = 0;
		var total_seconds = 0;
		var total_minutes = 0
		var total_hours = 0;
		let dataValue: any = this.sortedData.getValue();
		if (dataValue && dataValue.length) {
			for (let j = 0; j < dataValue.length; j++) {
				if (dataValue[j].rData && dataValue[j].rData.length) {
					////console.log(dataValue[j].rData);
					for (let k = 0; k < dataValue[j].rData.length; k++) {
						//	total += Number(dataValue[j].rData[k][variable]);
						var s = parseInt(dataValue[j].rData[k][variable].split(':')[2]);
						var m = parseInt(dataValue[j].rData[k][variable].split(':')[1]);
						var h = parseInt(dataValue[j].rData[k][variable].split(':')[0]);

						total_hours = total_hours + h;
						total_minutes = total_minutes + m;
						total_seconds = total_seconds + s;

						if (total_minutes >= 60) {
							total_hours = total_hours + 1;
							total_minutes = total_minutes - 60;
						}
						if (total_seconds >= 60) {
							total_minutes = total_minutes + 1;
							total_seconds = total_seconds - 60;
						}
					}
				}
			}
			// //console.log(JSON.stringify(dataValue[j].rData[k])+"---");
		}
		////console.log("-total-active--" + total_hours + "--" + total_minutes + "---" + total_seconds);
		return `${total_hours}:${total_minutes}:${total_seconds}`;
	}
	getavgagent() {
		// var allactivetime = moment().format('HH:mm:ss');
		//console.log(this.getAllActiveTime());
		var s = Number(this.getAllActiveTime().split(':')[2]);
		var m = Number(this.getAllActiveTime().split(':')[1]);
		var h = Number(this.getAllActiveTime().split(':')[0]);

		var seconds = (+h) * 60 * 60 + (+m) * 60 + (+s);
		//console.log(seconds + "---" + this.getSummationofallsignedIn())
		var totaltime = this.utils.getDivision(seconds, this.getSummationofallsignedIn())
		totaltime = Math.round(totaltime);
		//console.log("---" + totaltime);
		var hours = Math.floor((totaltime / 3600));
		var minutes = Math.floor((totaltime - (hours * 3600)) / 60);
		var seconds = totaltime - (hours * 3600) - (minutes * 60);

		var time = moment().set("hour", hours).set("minute", minutes).set("second", seconds);
		////console.log(time)
		return moment(time).format('HH:mm:ss');
		//return hours + ":" + minutes + ":" + seconds;

		//console.log("---" + moment(totaltime).format('HH:mm:ss'))
		//return moment(totaltime).format('HH:mm:ss');
		//return allactivetime;
	}
	getdialperagent() {
		var totaldialled = this.getSummationData('dailpercall');
		var totaltime = this.utils.getDivision(totaldialled, this.getAllsignin_agent())
		return totaltime;
	}
	get_division(num, denm) {
		if (!num || !denm) {
			return 0;
		}
		if (!denm || (denm == 0)) {
			return 0;
		} else {
			let val = Math.abs(num / denm);
			// if(type == 'percentage'){
			//  return Math.abs(num*100/denm).toFixed(1)
			// }else{
			//     if((delim != undefined) && (delim > -1) && (!isNaN(delim))){
			//         return val.toFixed(parseInt(delim));
			//     }else{
			return val.toFixed(1);
			//     }
		}
	}
	get_time_format(sec) {
		////console.log('printing seconds',sec);
		if (!sec || isNaN(sec)) {
			// alert("Input data is incorrect!");
			return '00:00:00';
		}
		if (sec <= 0) {
			//alert("The second date occurred earlier than the first one!");
			return '00:00:00';
		}
		var minute = 60, hour = 60 * minute, day = 24 * hour;
		var hours = Math.floor(sec / hour);
		sec -= hours * hour;
		var minutes = Math.floor(sec / minute);
		sec -= minutes * minute;
		var seconds = Math.floor(sec);
		var hours_string, minutes_string, seconds_string

		var time = moment().set("hour", hours).set("minute", minutes).set("second", seconds);
		////console.log(time)
		return moment(time).format('HH:mm:ss');
		//return hours+":"+minutes+":"+seconds;
	}
	getAllsignin_agent() {
		// return this.getSummationData('wrapTime');
		return this.getSummationofallsignedIn();
	}

	getSummationofallsignedIn() {
		var total_signin = 0;
		let dataValue: any = this.sortedData.getValue();
		if (dataValue && dataValue.length) {
			for (let j = 0; j < dataValue.length; j++) {
				if (dataValue[j].rData && dataValue[j].rData.length) {
					////console.log(dataValue[j].rData);
					for (let k = 0; k < dataValue[j].rData.length; k++) {
						//total += Number(d.rData[k].idleTime);
						total_signin += dataValue[j].rData[k].signedIn;
					}
				}
			}
		}
		return total_signin;
	}

	gethmsformat(sec, mode) {
		if (!sec) {
			return 0;
		}
		var hrs, min, seconds;
		hrs = sec / 3600;
		var temp = sec % 3600;
		min = temp / 60;
		seconds = temp % 60;
		function getDigit(digit) {
			if (!digit) {
				return '00'
			} else {
				digit = Math.floor(digit);
				if (digit > 9) {
					return digit;
				} else {
					return ('0' + digit);
				}
			}
		}
		switch (mode) {
			case 'clock':

				return getDigit(hrs) + ' : ' + getDigit(min) + ' : ' + getDigit(seconds);
				break;
			default:
				return Math.round(hrs) + 'hrs ' + Math.round(min) + 'min ' + Math.round(seconds) + 'sec';
				break;
		}
	}

	ngOnDestroy() {
		if (this.id) {
			clearInterval(this.id);
		}
		this.agentFeed.unsubscribe();
	}

}