<div class="v-toolbar" role="banner">
    <div class="v-swid">
        <div class="v-Sleft">
            <img class="v-logoOX forWebMenuOX" alt="Contiinex Logo" title="Contiinex Logo" (click)="logoWbsiteBtn()"
                src="../assets/contiinex/image/contiinexLogoImg.png">
            <span class="forMobileMenuOX">
                <span mat-button [matMenuTriggerFor]="menu" class="matbtnMenucs">
                    <mat-icon style="font-size: 34px;position: relative;bottom: 2px;"> menu</mat-icon>
                </span>
                <img class="v-logoOX" alt="Contiinex Logo" title="Contiinex Logo"
                    src="../assets/contiinex/image/contiinexLogoImg.png" (click)="logoWbsiteBtn()"
                    style="vertical-align: top;position: relative;left: 20px;">
                <mat-menu #menu="matMenu" style="z-index: 2;">
                    <div class="menuMobileCs" style="display: inline-grid;">

						<div class="columnHeader" style="padding-top: 11px;display:none;" (mouseover)="setActiveItemSolution1()"
						(click)="setInActiveItemSoution()" (mouseleave)="removePopupSolution()">
						<span [ngStyle]="{'color': activeItem==='solutions' ? '#dd133c' : '' }">Solutions</span><span>
							<mat-icon class="v-expandMore">expand_more</mat-icon>
						</span>
					</div>


                        <div class="columnHeader" style="padding-top: 11px;" (click)="setInActiveItem('products')"
                            (mouseover)="setActiveItem1()" (mouseleave)="removePopup()">
                            <span [ngStyle]="{'color': activeItem==='products' ? '#dd133c' : '' }">Products</span><span>
                                <mat-icon class="v-expandMore">expand_more</mat-icon>
                            </span>
                        </div>
                        <!-- <div class="columnHeader" style="padding-top: 11px;" (click)="setInActiveItem('solutions')">
                            <span [ngStyle]="{'color': activeItem==='solutions' ? '#dd133c' : '' }">Solutions
                            </span><span>
                                <mat-icon class="v-expandMore">expand_more</mat-icon>
                            </span>
                        </div> -->
                        <div class="columnHeader" (click)="setInActiveItem('company')">
                            <span [ngStyle]="{'color': activeItem==='company' ? '#dd133c' : '' }">Company</span>
                        </div>
                    </div>
                </mat-menu>
            </span>


        </div>

        <div class="v-Smain">
            <!--for web menu start-->
            <div class="forWebMenuOX">

				<div class="columnHeader" style="padding-top: 11px;display:none;" (mouseover)="setActiveItemSolution1()"
				(click)="setInActiveItemSoution()" (mouseleave)="removePopupSolution()">
				<span [ngStyle]="{'color': activeItem==='solutions' ? '#dd133c' : '' }"> Solutions</span>
				<span>
					<mat-icon class="v-expandMore">expand_more</mat-icon>
				</span>
			</div>

                <div class="columnHeader" style="padding-top: 11px;" (mouseover)="setActiveItem1()"
                    (click)="setInActiveItem('products')" (mouseleave)="removePopup()">
                    <span [ngStyle]="{'color': activeItem==='products' ? '#dd133c' : '' }"> Products</span>
                    <span>
                        <mat-icon class="v-expandMore">expand_more</mat-icon>
                    </span>
                </div>
                <!-- <div class="columnHeader" style="padding-top: 11px;" (click)="setInActiveItem('solutions')">
                    <span [ngStyle]="{'color': activeItem==='solutions' ? '#dd133c' : '' }"> Solutions</span>
                    <span>
                        <mat-icon class="v-expandMore">expand_more</mat-icon>
                    </span>
                </div> -->
                <div class="columnHeader" (click)="setInActiveItem('company')">
                    <span [ngStyle]="{'color': activeItem==='company' ? '#dd133c' : '' }">Company</span>
                </div>

            </div><br>
            <!--for web menu end-->

            <!------for mobile start-->
            <div class="forMobileMenuOX">

                <span class="btn-signin loginMob1" title="Login" (click)="LoginBtn()"
                    style="position: relative;bottom: 4px;right: 20px;">LOGIN</span>
            </div>
            <!------for mobile end-->
        </div>
        <div class="v-Sright forWebMenuOX">
            <p><span class="btn-signin" title="Login" (click)="LoginBtn()">LOGIN</span></p>
        </div>
    </div>
</div>

<div class="v-popup1 animation-One1x" id="popupForm" (mouseover)="setActiveItem1()"
    (mouseleave)="setInActiveItem1('productspopup')">

<div class="row forWebDivPopup" style="margin-right: -10px;">
    <div class="col-md-12">
        <p style="color: #9a9a9a;font-family: poppins;font-size: 15px;" class="mb-0 ml-4 pt-3">PRODUCTS</p>
        <p class="v-para1" (click)="openProductSummary('comnexExperience','popup')" (mouseover)="productSubMenu('comnexExperience')">
            <!-- <img src="../assets/contiinex/image/subProductComEx.png" class="subComExpImg1" /><br> -->

            <img src="../assets/contiinex/image/ainexLogo.png" style="width: 32px"/> 
            <span class="v-LblHd2">Quality Assurance Automation</span><br>

        <span>Automate your customer experience with Speech AI-powered by our NLP engine</span>
    </p>
    <p class="v-para1" (click)="openProductSummary('comnexEngage','popup')" (mouseover)="productSubMenu('comnexEngage')">
        <!-- <img src="../assets/contiinex/image/subProductComEng.png" class="subComExpImg2" /><br> -->
        <img src="../assets/contiinex/image/ComnixLogo.png"style="width: 32px"/> 
        <span  class="v-LblHd2 pl-1" style="left: -1px !important;">
            Unified Communication as a Service
           
        </span>


        <span >Proactive customer engagement through Conversational AI with WhatsApp Bot, IVR & Dialler</span>
    </p>
    <!-- <p class="v-para1" (click)="openProductSummary('comnexEnable','popup')" (mouseover)="productSubMenu('comnexEnable')"><img src="../assets/contiinex/image/subProductComEnble.png"  class="subComExpImg3"/><br>
        <span >End to End Agent Life Cycle Automation for Hiring, Onboarding, Training, & Workforce management
        </span>
    </p> -->
    <p class="v-para1 mb-3" (click)="openProductSummary('whatsAppPi','popup')" (mouseover)="productSubMenu('whatsappApi')" style="border-bottom: none"><img
            src="../assets/contiinex/image/subProductWABApi.png"  class="subComExpImg4" style="position: relative;right: 4px;"/><br>
        <span style="left: 3px;">Engage customers at every stage of their journey through WhatsApp Business</span>
    </p>
    </div>
   <!-- <div class="col-md-5" style="background: #f1f2f7;" >
    <p style="color: #9a9a9a;font-family: poppins;font-size: 15px;" class="mb-0 ml-2 pt-3">SUB PRODUCTS</p>
       <div *ngIf="showHideSubMenuComnexEngage">
        <mat-list role="list" class="subMenuPartcs1">
            <mat-list-item role="listitem"> WA & Chat Bot</mat-list-item>
            <mat-list-item role="listitem">Visual IVR</mat-list-item>
            <mat-list-item role="listitem">Video Bot</mat-list-item>
            <mat-list-item role="listitem">Dialer</mat-list-item>
          </mat-list>
       </div>
      
            <div *ngIf="showHideSubMenuComnexExperience">
                <mat-list role="list" class="subMenuPartcs1">
                    <mat-list-item role="listitem">Auto Ticketing</mat-list-item>
                    <mat-list-item role="listitem">Agent Assist</mat-list-item>
                    <mat-list-item role="listitem">Auto Audit</mat-list-item>
                    <mat-list-item role="listitem">Transcription</mat-list-item>
                </mat-list>
            </div>

            <div  *ngIf="showHideSubMenuComnexEnable">
                <mat-list role="list" class="subMenuPartcs1">
                    <mat-list-item role="listitem">Agent Hiring</mat-list-item>
                    <mat-list-item role="listitem">Onboarding</mat-list-item>
                    <mat-list-item role="listitem">Training</mat-list-item>
                    <mat-list-item role="listitem">WorkForce Management</mat-list-item>
                </mat-list>
            </div>
        
    </div> -->
</div>






<div class="row forMobileDivPopup" style="margin-right: -10px;">
    <div class="col-md-12">
        <p style="color: #9a9a9a;font-family: poppins;font-size: 15px;" class="mb-0 ml-4 pt-3">PRODUCTS</p>
        <p class="v-para1" (click)="openProductSummary('comnexExperience','popup')" (mouseover)="productSubMenu('comnexExperience')">
            <!-- <img src="../assets/contiinex/image/subProductComEx.png" class="subComExpImg1" /><br> -->
            <img src="../assets/contiinex/image/ainexLogo.png" style="width: 32px"/> 
            <span class="v-LblHd2">Quality Assurance Automation</span><br>

        <span>Automate your customer experience with Speech AI-powered by our NLP engine</span>
    </p>
    <!-- <div style="background: #f1f2f7;" *ngIf="showHideSubMenuComnexExperience">
        <p style="color: #9a9a9a;font-family: poppins;font-size: 15px;" class="mb-0 ml-2 pt-3">SUB PRODUCTS</p>
            <div >
                <mat-list role="list" class="subMenuPartcs1">
                    <mat-list-item role="listitem">Auto Ticketing</mat-list-item>
                    <mat-list-item role="listitem">Agent Assist</mat-list-item>
                    <mat-list-item role="listitem">Auto Audit</mat-list-item>
                    <mat-list-item role="listitem">Transcription</mat-list-item>
                </mat-list>
            </div>
            </div> -->

    <p class="v-para1" (click)="openProductSummary('comnexEngage','popup')" (mouseover)="productSubMenu('comnexEngage')">
        <!-- <img src="../assets/contiinex/image/subProductComEng.png" class="subComExpImg2" /><br> -->
        <img src="../assets/contiinex/image/ComnixLogo.png"style="width: 32px"/> 
        <span  class="v-LblHd2 pl-1" style="left: -1px !important;">
            Unified Communication as a Service           
        </span>
        <span >Proactive customer engagement through Conversational AI with WhatsApp Bot, IVR & Dialler</span>
    </p>
    <!-- <div style="background: #f1f2f7;" *ngIf="showHideSubMenuComnexEngage">
        <p style="color: #9a9a9a;font-family: poppins;font-size: 15px;" class="mb-0 ml-2 pt-3">SUB PRODUCTS</p>
           <div >
            <mat-list role="list" class="subMenuPartcs1">
                <mat-list-item role="listitem"> WA & Chat Bot</mat-list-item>
                <mat-list-item role="listitem">Visual IVR</mat-list-item>
                <mat-list-item role="listitem">Video Bot</mat-list-item>
                <mat-list-item role="listitem">Dialer</mat-list-item>
              </mat-list>
           </div>
           </div> -->
    <!-- <p class="v-para1" (click)="openProductSummary('comnexEnable','popup')" (mouseover)="productSubMenu('comnexEnable')"><img src="../assets/contiinex/image/subProductComEnble.png"  class="subComExpImg3"/><br>
        <span >End to End Agent Life Cycle Automation for Hiring, Onboarding, Training, & Workforce management
        </span>
    </p> -->
    <!-- <div style="background: #f1f2f7;"  *ngIf="showHideSubMenuComnexEnable">
        <p style="color: #9a9a9a;font-family: poppins;font-size: 15px;" class="mb-0 ml-2 pt-3">SUB PRODUCTS</p>
    <div >
        <mat-list role="list" class="subMenuPartcs1">
            <mat-list-item role="listitem">Agent Hiring</mat-list-item>
            <mat-list-item role="listitem">Onboarding</mat-list-item>
            <mat-list-item role="listitem">Training</mat-list-item>
            <mat-list-item role="listitem">WorkForce Management</mat-list-item>
        </mat-list>
    </div>
</div> -->
    <p class="v-para1 mb-3" (click)="openProductSummary('whatsAppPi','popup')" (mouseover)="productSubMenu('whatsappApi')" style="border-bottom: none"><img
            src="../assets/contiinex/image/subProductWABApi.png"  class="subComExpImg4"/><br>
        <span >Engage customers at every stage of their journey through WhatsApp Business</span>
    </p> 
      
    </div>
    </div>

</div>


<!--start solution popup-->
<div class="v-popup1 animation-One1x" id="popupFormSolution" (mouseover)="setActiveItemSolution1()"
    (mouseleave)="setInActiveItemSolution1()">

<div class="row forWebDivPopup" style="margin-right: -10px;">
    <div class="col-md-12">
        <p style="color: #9a9a9a;font-family: poppins;font-size: 15px;" class="mb-0 ml-4 pt-3">Solutions</p>
        <p class="v-para1" (click)="openProductSummary('comnexExperience','popup')" (mouseover)="productSubMenu('comnexExperience')">
           
            <img src="../assets/contiinex/image/ainexLogo.png" style="width: 32px"/> 
            <span class="v-LblHd2">Overview Solutions</span><br>

        <span>Automate your customer experience with Speech AI-powered by our NLP engine</span>
    </p>

    </div>
   
</div>


<div class="row forMobileDivPopup" style="margin-right: -10px;">
    <div class="col-md-12">
        <p style="color: #9a9a9a;font-family: poppins;font-size: 15px;" class="mb-0 ml-4 pt-3">Solutions</p>
        <p class="v-para1" (click)="openProductSummary('comnexExperience','popup')" (mouseover)="productSubMenu('comnexExperience')">
  
            <img src="../assets/contiinex/image/ainexLogo.png" style="width: 32px"/> 
            <span class="v-LblHd2">Overview</span><br>

        <span>Automate your customer experience with Speech AI-powered by our NLP engine</span>
    </p>
      
    </div>
    </div>



</div>


<!-- end solution popup-->