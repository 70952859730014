<div class="background">
	<!--<mat-drawer-container autosize>
		<mat-drawer #drawer mode="side" opened="true">
			<app-desktop-menu></app-desktop-menu>
		</mat-drawer>-->
	<div class="row allbackground" style="padding: 25px 0px 25px 0px;margin: 130px 0px 0px 0px;min-height: 100vh;">
		<div class="col-md-6">
			<!--<div class="row" *ngIf="showhideLeadDiv">
				<div class="col-md-6 pl-0 pr-0">
					<div>
						<mat-card class="ddCard">
							<p class="mb-1"><b class="dd-head">Leads Contacted</b></p>
							<table style="width:100%">
								<tr>
									<td> <img src="../../../assets/desktop_calling/leads_contacted.svg"
											class="ddImg1" /> </td>
									<td style="text-align: right;padding-right: 5px;"> <b
											style="font-size: 33px;">{{_countLeadLength}}</b></td>
									<td>
										<mat-icon style="color:#2ec114"> trending_up</mat-icon>
									</td>
								</tr>
							</table>
						</mat-card>
					</div>
				</div>
				<div class="col-md-6 pr-0">
					<div>
						<mat-card class="ddCard">
							<p class="mb-1"><b class="dd-head">Follow - ups to do</b></p>
							<table style="width:100%">
								<tr>
									<td> <img src="../../../assets/desktop_calling/follow_ups.svg" class="ddImg1" />
									</td>
									<td style="text-align: right;padding-right: 5px;"> <b
											style="font-size: 33px;">{{_countfollowUpLength}}</b></td>
									<td>
										<mat-icon style="color:red"> trending_down</mat-icon>
									</td>
								</tr>
							</table>
						</mat-card>
					</div>
				</div>
			</div>-->
			<div class="row">
				<div class="col-md-12 pl-0 pr-0 mt-0">
					<div>
						<mat-card class="ddCard" style="min-height: 405px;">
							<p><b class="dd-head">Notifications
							
							</b>
								<span style="float: right;right: 20px;position: relative;cursor: pointer;"
									matBadge={{notificationcount}} matBadgeSize="medium" matBadgeColor="warn"
									matBadgePosition="after" (click)="btnNotification()">
									<mat-icon class="ml-2" style="padding-left: 10px;">notifications</mat-icon>
								</span>
							</p>
							<!--*ngFor="let notificationslist of notifications"-->
							<div class="notification-card">
								<div class="row" *ngFor="let v of notifications">
									<div class="col-md-2">
										<img src="../../../assets/desktop_calling/new_message.svg" />
									</div>
									<div class="col-md-8 text-left" [ngClass]="v.status == 'UNREAD' ? 'unreadcls' : ''">
										<div (click)="readnotification(v)">
											<h6>{{v.title}}</h6>
											<p>{{v.message}}</p>
										</div>
									</div>
									<div class="col-md-2">

									</div>
								</div>
							</div>

						</mat-card>
					</div>
				</div>
			</div>

		</div>
		<div class="col-md-6 pl-0" *ngIf="showHideStartPage">
			<div>
				<mat-card class="ddCard p-0">
					<div class="dvCallcs1" style="font-weight: bold;">
						Please Press Start for Calling.
					</div>
					<div class="dvCallcs2">
						<button class="startBtnx1" (click)="startSession()" *ngIf="!sessionStarted">START</button><br>
						<!--(click)="start()"-->
						<div *ngIf="!showHideCallPad && sessionStarted == true">
							<img src="../../../assets/desktop_calling/dailpad_logo.svg"
								style="margin-left: 340px;height: 70px;width: 100px;" (click)="startCallLoading()">
						</div>
					</div>

				</mat-card>
			</div>
		</div>
		<div class="col-md-6 pl-0" *ngIf="showHideStopPage">
			<div>
				<mat-card class="ddCard p-0">
					<div class="dvCallcs1" style="font-weight: bold;">
						Click here to end session
					</div>
					<div class="dvCallcs2">
						<button class="stopCallBtnx" (click)="endSession()" *ngIf="sessionStarted">
							<mat-icon class="mr-2" style="vertical-align: bottom;">stop</mat-icon>STOP
						</button>
						<!--stop-->
						<div class="text-right" *ngIf="!showHideCallPad && sessionStarted == true">
							<img src="../../../assets/desktop_calling/dailpad_logo.svg"
								style="height: 80px;width: 100px;" (click)="startCallLoading()">
						</div>
					</div>


				</mat-card>
			</div>
		</div>

		<div class="col-md-6 pl-0" *ngIf="showHideConnecting || showHideCalldialling">
			<div>
				<mat-card class="ddCard p-0">
					<div class="Callingscreen">
						Connecting...<br>
						<b>Please wait, dialled out</b>
						<br>
						Already Connected
						<br><br>
						<button mat-raised-button color="primary" (click)="getCustomerInfo()" class="getinfo">Get
							Info</button>
					</div>
					<div class="dvCallcs2">
						<div class="col-md-12">
							<button class="mutePauseBtnx1" style="width: 125px;" disabled>
								<span>
									<mat-icon style="vertical-align: bottom;" class="mr-2">phone_forwarded</mat-icon>
									<span>Forward</span>
								</span>
							</button>
							<button class="mutePauseBtnx1" style="margin-left: 10px; width: 120px;" disabled>
								<span>
									<mat-icon style="vertical-align: bottom;" class="mr-2">call_merge</mat-icon>
									<span>Merge</span>
								</span>
							</button>
							<!--<button class="mutePauseBtnx" style="margin-left: 10px; width: 150px;font-size: 10px;"
								*ngIf="sessionStarted" (click)="endCallFirst(true)">
								<mat-icon style="vertical-align: bottom;" class="mr-2">phone_locked</mat-icon>
								End Session
							</button>-->
						</div>
						<div class="col-md-12" style="margin-top: 15px;">
							<!-- <button class="mutePauseBtnx1" style="width: 100px;" disabled>
								<span>
									<mat-icon style="vertical-align: bottom;" class="mr-2">mic_off</mat-icon>
									<span>Mute</span>
								</span>
							</button>
							<button class="mutePauseBtnx1" style="margin-left: 12px; width: 100px;" disabled>
								<span>
									<mat-icon style="vertical-align: bottom;" class="mr-2">pause</mat-icon>
									<span>Hold</span>
								</span>
							</button> -->
							<button class="endCallBtnx"
								style="margin-left: 12px; border-radius: 35px;width: 120px;height: 48px;"
								(click)="endCallFirst()">
								<span>
									<mat-icon style="vertical-align: bottom;" class="mr-2">call_end</mat-icon><span>
										End</span>
								</span>
							</button>
						</div>


					</div>

				</mat-card>
			</div>
		</div>
		<div class="col-md-6" *ngIf="showHide_Connected || showHideCallStatus">
			<div>
				<mat-card class="ddCard p-0">
					<div class="dvCallcs1" >
						<b *ngIf="callStatus && callStatus.customerInfo">{{
							callStatus.customerInfo.customer_name}}</b><br>
						<b *ngIf="callStatus && callStatus.customerInfo">{{callStatus.customerInfo.customer_number}}</b>
						<br><br>
						<p *ngIf="callStatus && callStatus.customerInfo && callStatus.status && (callStatus.status != 'end_call_agent' && callStatus.status != 'terminated')" style="text-align: center;font-size: 15px;">{{callStatus.timer || ''}}</p>
					</div>
					<div class="dvCallcs2">
						<div class="col-md-12">
							<button class="mutePauseBtnx" style="width: 125px;" (click)="transferingcall()">
								<span>
									<mat-icon style="vertical-align: bottom;" class="mr-2">phone_forwarded</mat-icon>
									<span>Forward</span>
								</span>
							</button>
							<button class="mutePauseBtnx" style="margin-left: 10px;width: 120px;"
								(click)="mergingcall()">
								<span>
									<mat-icon style="vertical-align: bottom;" class="mr-2">call_merge</mat-icon>
									<span>Merge</span>
								</span>
							</button>
							<!-- <button class="mutePauseBtnx" style="margin-left: 10px;width: 150px;font-size: 10px;"
								(click)="endCallFirst()" *ngIf="sessionStarted">
								<mat-icon style="vertical-align: bottom;" class="mr-2">phone_locked</mat-icon>
								End Session
							</button> -->
						</div>
						<div class="col-md-12" style="margin-top: 15px;">
							<!-- <button class="mutePauseBtnx" style="width: 100px;" disabled>
								<span>
									<mat-icon style="vertical-align: bottom;" class="mr-2">mic_off</mat-icon>
									<span>Mute</span>
								</span>
							</button>
							<button class="mutePauseBtnx" style="margin-left: 12px;width: 100px;" disabled>
								<span>
									<mat-icon style="vertical-align: bottom;" class="mr-2">pause</mat-icon>
									<span>Hold</span>
								</span>
							</button> -->
							<button class="endCallBtnx"
								style="margin-left: 12px; border-radius: 35px;width: 120px;height: 48px;"
								(click)="endCallFirst()">
								<span>
									<mat-icon style="vertical-align: bottom;" class="mr-2">call_end</mat-icon><span>
										End</span>
								</span>
							</button>
						</div>
					</div>

				</mat-card>
			</div>
		</div>
		<div class="col-md-6" *ngIf="showHideCallEndingScreen">
			<div>
				<mat-card class="p-0">
					<div class="dvCallcs1">
						<b>Congratulations!</b>
						<br><br>
						You have finished with all of
						your allocated numbers
					</div>
					<div class="dvCallcs2">
						<div class="col-md-12">
							<button class="mutePauseBtnx1" style="width: 125px;" disabled>
								<span>

									<mat-icon style="vertical-align: bottom;" class="mr-2">phone_forwarded</mat-icon>
									<span>Forward</span>
								</span>
							</button>
							<button class="mutePauseBtnx1" style="margin-left: 10px; width: 120px;" disabled>
								<span>

									<mat-icon style="vertical-align: bottom;" class="mr-2">call_merge</mat-icon>

									<span>Merge</span>
								</span>
							</button>
							<button class="mutePauseBtnx" style="margin-left: 10px; width: 150px;"
								(click)="endSessionCall()">
								<span>

									<mat-icon style="vertical-align: bottom;" class="mr-2">phone_locked</mat-icon>
									<span>End Session</span>
								</span>
							</button>
						</div>
						<div class="col-md-12" style="margin-top: 15px;">
							<!-- <button class="mutePauseBtnx1" style="width: 100px;" disabled>
								<span>

									<mat-icon style="vertical-align: bottom;" class="mr-2">mic_off</mat-icon>
									<span>Mute</span>
								</span>
							</button>
							<button class="mutePauseBtnx1" style="margin-left: 12px; width: 100px;" disabled>
								<span>

									<mat-icon style="vertical-align: bottom;" class="mr-2">pause</mat-icon>
									<span>Hold</span>
								</span>
							</button> -->
							<button class="endCallBtnx"
								style="margin-left: 12px; border-radius: 35px;width: 120px;height: 48px;"
								(click)="endCallFirst()">
								<span>
									<mat-icon style="vertical-align: bottom;" class="mr-2">call_end</mat-icon><span>
										End</span>
								</span>
							</button>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
		<div class="col-md-6" *ngIf="showHideCallingScreen">
			<div>
				<mat-card class="p-0">
					<div class="dvCallcs1">
						Please wait while we are connecting..
					</div>
					<div class="dvCallcs2">
						<div class="col-md-12">

							<!-- <button class="mutePauseBtnx" style="margin-left: 10px; width: 150px;" (click)="endSessionCall()">
								<span>
						
									<mat-icon style="vertical-align: bottom;" class="mr-2">phone_locked</mat-icon>
									<span>End Session</span>
								</span>
							</button> -->

							<button class="endCallBtnx"
								style="margin-left: 12px; border-radius: 35px;width: 120px;height: 48px;"
								(click)="endCallFirst(false,true)">
								<span>
									<mat-icon style="vertical-align: bottom;" class="mr-2">call_end</mat-icon><span>
										End</span>
								</span>
							</button>
						</div>
					</div>
				</mat-card>
			</div>
		</div>
		<div class="col-md-6 pl-0" *ngIf="showHideDailpad">
			<div>
				<mat-card class="ddCard p-0" style="    padding: 2px 50px !important">

					<div class="text-center">
						<table style="width:100%">
							<tr>
								<td>
									<div class="output">
										<!--<input id="code" type="text" class="numInput" value="0" [(ngModel)]="numInput" readonly>-->
										<input id="code" class="numInput" readonly style="margin-left: 105px;">
										<!-- <div class="numInput">{{ numInput }}</div> -->
									</div>
								</td>
								<td>
									<img src="../../../assets/desktop_calling/Icon open-delete.svg"
										(click)="removeNum()">
								</td>
							</tr>
						</table>

						<div class="row1">
							<div class="digit" id="one" (click)="clickNumBtn(1)">1</div>
							<div class="digit" id="two" (click)="clickNumBtn(2)">2
								<div class="sub">ABC</div>
							</div>
							<div class="digit" id="three" (click)="clickNumBtn(3)">3
								<div class="sub">DEF</div>
							</div>
						</div>
						<div class="row1">
							<div class="digit" id="four" (click)="clickNumBtn(4)">4
								<div class="sub">GHI</div>
							</div>
							<div class="digit" id="five" (click)="clickNumBtn(5)">5
								<div class="sub">JKL</div>
							</div>
							<div class="digit" (click)="clickNumBtn(6)">6
								<div class="sub">MNO</div>
							</div>
						</div>
						<div class="row1">
							<div class="digit" (click)="clickNumBtn(7)">7
								<div class="sub">PQRS</div>
							</div>
							<div class="digit" (click)="clickNumBtn(8)">8
								<div class="sub">TUV</div>
							</div>
							<div class="digit" (click)="clickNumBtn(9)">9
								<div class="sub">WXYZ</div>
							</div>
						</div>
						<div class="row1">
							<div class="digit">*
							</div>
							<div class="digit" (click)="clickNumBtn(0)">0
								<div class="sub">+</div>
							</div>
							<div class="digit">#
							</div>
						</div>
					</div>
					<div class="text-center">
						<button class="startBtnx1 ml-4" (click)="startCallLoading()">
							<mat-icon class="mr-2" style="vertical-align: bottom;">call</mat-icon>Dial
						</button>
						<button class="stopCallBtnx ml-2" (click)="endSession()">
							<mat-icon class="mr-2" style="vertical-align: bottom;">stop</mat-icon>Stop
						</button>
						<img src="../../../assets/desktop_calling/dailpad_logo.svg"
							style="margin-left: 5px;height: 70px;width: 60px;" (click)="resetDialer()">
						<!--(click)="calldialler()"-->
					</div>
				</mat-card>
			</div>
		</div>
		<div class="col-md-12 mt-3" *ngIf="showHide_Connected || showHideCallStatus || salesStatusTrigger">

			<div class="queryDiv" style="text-align: -webkit-center;">
				<h3 style=" text-align: left;font-size: 23px;border-bottom: 1px solid #c6c6c6;padding-bottom: 10px;">
					During Call Form</h3>
				<!-- <div *ngIf="showHide_Form_before_DuringForm"> -->
				<div *ngIf="callStatus.showDuringCallForm">
					<table class="text-center" style="width: 100%;">
						<tr *ngIf="myUser.groupid != 82719 && user.groupid != 25461">
							<td *ngFor="let section of callFlowJsonSections;let $index=index"
								style="padding-left: 0px;padding-top: 15px;">
								<div class="row" *ngFor="let child of section.child;let $childIndex=index">
									<div class="col-md-5">
										<mat-form-field class="wd-100" *ngIf="child.at == 'FT'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput placeholder="" [(ngModel)]="child.answer">
										</mat-form-field>

										<div *ngIf="child.at == 'RT'">
											{{child.q}}<span *ngIf="child.mandatory">*</span>
											<!-- <star-rating-comp style="float: left;" [starType]="'icon'" [size]="'large'" [rating]="4"></star-rating-comp> -->



											<ng-template #t let-fill="fill">
												<span class="star" [class.full]="fill === 100">
													<span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
												</span>
											</ng-template>
											<ngb-rating [max]="5" [(rate)]="child.answer" [starTemplate]="t">
											</ngb-rating>







										</div>

										<mat-form-field class="wd-100" *ngIf="child.at == 'FN'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput placeholder="" type="number" [(ngModel)]="child.answer">
										</mat-form-field>

										<mat-form-field class="wd-100" *ngIf="child.at == 'NP'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput type="number" placeholder="Min Value"
												[(ngModel)]="child.answer">
										</mat-form-field>

										<mat-form-field class="wd-100" *ngIf="child.at == 'NP'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput placeholder="Max Value" type="number"
												[(ngModel)]="child.answer">
										</mat-form-field>

										<mat-form-field class="wd-100" *ngIf="child.at == 'DATE'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput [matDatepicker]="picker" placeholder="Date" readonly
												(click)="picker.open()">
											<mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 25px;">
											</mat-datepicker-toggle>
											<mat-datepicker #picker></mat-datepicker>
										</mat-form-field>

										<div *ngIf="child.at == 'YN'">
											{{child.q}}<span *ngIf="child.mandatory">*</span><br>
											<button mat-button class="savbtn">Yes</button>
										</div>

										<div *ngIf="child.at == 'YN'">
											{{child.q}}<span *ngIf="child.mandatory">*</span><br>
											<button mat-button class="savbtn">No</button>
										</div>

										<div *ngIf="child.at == 'ATTACHMENT'" class="text-left mb-2">
											{{child.q}}<span *ngIf="child.mandatory">*</span>
											<div class="upload-btn-wrapper" style="top: 10px;">
												<button class="btnbrowse">Browse File &nbsp;&nbsp; <img
														src="../assets/BrowsefileIcon.svg" /></button>
												<input type="file" #fileUpload class="filecs"
													(change)="changeFile($event,$childIndex)" />
												<span
													style="color: #bababa;margin-left: 15px">{{currentFileobj.name}}</span>
											</div>
										</div>

										<mat-form-field class="wd-100" *ngIf="child.at == 'BLANK'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput placeholder="Your booking is successful">
										</mat-form-field>


										<div *ngIf="child.at == 'CB'" style="font-size: 12px;color: gray;">
											<span>{{child.q}}<span *ngIf="child.mandatory">*</span></span><br>
											<div *ngFor="let c of getParsed(child.cb_data); let $mdd_index=index"
												class="mt-1" style="color:gray;display: inline-block;flex-wrap: wrap;">
												<mat-checkbox
													(change)="mddValueChange($event, $index, $childIndex, $mdd_index)"
													class="pl-2">{{c}}</mat-checkbox>
											</div>
										</div>

										<div *ngIf="child.at == 'RB'" style="font-size: 12px;color: gray;">
											<span>{{child.q}}<span *ngIf="child.mandatory">*</span></span><br>
											<mat-radio-group aria-labelledby="radio{{$childIndex}}"
												[(ngModel)]="child.answer" style="    position: relative;top: 10px;">
												<mat-radio-button value="{{d}}" [ngClass]="'radioPresent'"
													*ngFor="let d of getParsed(child.rb_data)"
													style="padding-right: 12px;">{{d}}
												</mat-radio-button>

											</mat-radio-group>
											<br />
											<div *ngIf="child.answer == 'Enquiry'">
												<div *ngFor="let rbchild of child.Enquiry;let $rbchildIndex=index"
													style="margin-top: 10px; margin-left: 10px">
													<span>{{rbchild.q}}<span *ngIf="rbchild.mandatory">*</span></span><br>
													<mat-radio-group aria-labelledby="radio{{$childIndex}}"
														[(ngModel)]="rbchild.answer" class="ml-3">

														<mat-radio-button value="{{d1}}" [ngClass]="'radioPresent'"
															*ngFor="let d1 of getParsed(rbchild.dd_data)"
															style="padding-right: 12px;">
															{{d1}}</mat-radio-button>

													</mat-radio-group>
													<br />
													<!-- Testing -->
													<div *ngIf="rbchild.answer == 'Communications'">
														<div *ngFor="let rbchild2 of rbchild.Communications;let $rbchild2Index=index"
															style="margin-top: 10px; margin-left: 20px">
															<!-- <span>{{rbchild2.q}}<span *ngIf="child.mandatory">*</span></span><br> -->
															<mat-form-field class="wd-100" *ngIf="rbchild2.at == 'SDD'">
																<mat-label>{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span></mat-label>
																<!-- <input matInput placeholder="Search" > <span *ngIf="rbchild2.mandatory">*</span>  -->
																<mat-select placeholder="{{rbchild2.q}}{{rbchild2.mandatory ? '*' : ''}}" [(ngModel)]="rbchild2.answer">
																	
																	<mat-option [value]="d"
																		*ngFor="let d of getParsed(rbchild2.sdd_data)">
																		{{d}}</mat-option>
																</mat-select>
															</mat-form-field>

															<mat-form-field class="wd-100"
																*ngIf="rbchild2.at == 'MSDD'">
																<mat-label>{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span></mat-label>
																<mat-select multiple [(ngModel)]="rbchild2.answer"
																	(selectionChange)="checkNextTrigger($event, rbchild2.next_action)">
																	<mat-option [value]="d"
																		*ngFor="let d of getParsed(rbchild2.msdd_data)">
																		{{d}}</mat-option>
																</mat-select>
															</mat-form-field>
															<mat-form-field class="wd-100" *ngIf="rbchild2.at == 'FN'">
																<mat-label>{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span></mat-label>
																<input matInput placeholder="" type="number" -
																	[(ngModel)]="rbchild2.answer">
															</mat-form-field>
															<div *ngIf="rbchild2.at == 'ATTACHMENT'">
																{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span>
																<div class="upload-btn-wrapper">
																	<button class="btnbrowse">Browse File &nbsp;&nbsp;
																		<img
																			src="../assets/BrowsefileIcon.svg" /></button>
																	<input type="file" #fileUpload class="filecs"
																		(change)="changeFileLevel2($event,$childIndex,$rbchild2Index,$rbchildIndex,child.answer,rbchild.answer)" />
																	<span
																		style="color: #bababa;margin-left: 15px">{{currentFileobj.name}}</span>
																</div>
															</div>
															<mat-form-field class="wd-100"
																*ngIf="rbchild2.at == 'DATETIME'">
																<mat-label>{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span></mat-label>
																<input matInput [matDatepicker]="dpicker"
																	[(ngModel)]="rbchild2.answer" placeholder="Date"
																	readonly (click)="dpicker.open()">
																<mat-datepicker-toggle matSuffix [for]="dpicker"
																	style="font-size: 25px;">
																</mat-datepicker-toggle>
																<mat-datepicker #dpicker></mat-datepicker>
															</mat-form-field>
															<div *ngIf="rbchild2.at == 'CB'"
																style="font-size: 12px;color: gray;">
																<span>{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span></span><br>
																<div *ngFor="let c of getParsed(rbchild2.cb_data); let $mdd_index=index"
																	class="mt-1"
																	style="color:gray;display: inline-block;flex-wrap: wrap;">
																	<mat-checkbox
																		(change)="mddValueChange($event, $index, $childIndex, $mdd_index)"
																		class="pl-2">{{c}}
																	</mat-checkbox>
																</div>
															</div>
															<div *ngIf="rbchild2.at == 'YN'">
																<span>{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span></span><br>
																<mat-radio-group
																	aria-labelledby="radio{{$rbchild2Index}}"
																	[(ngModel)]="rbchild2.answer" class="ml-3">

																	<mat-radio-button value="Yes"
																		[ngClass]="'radioPresent'"
																		style="padding-right: 12px;">Yes
																	</mat-radio-button>
																	<mat-radio-button value="No"
																		[ngClass]="'radioPresent'"
																		style="padding-right: 12px;">No
																	</mat-radio-button>

																</mat-radio-group>
															</div>

															<!-- <div *ngIf="rbchild2.at == 'YN'">
														{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span><br>
														<button mat-button class="savbtn">No</button>
													</div> -->
															<div *ngIf="rbchild2.at == 'RT'">
																{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span>
																<br />
																<ng-template #t let-fill="fill">
																	<span class="star" [class.full]="fill === 100">
																		<span class="half"
																			[style.width.%]="fill">&#9733;</span>&#9733;
																	</span>
																</ng-template>
																<ngb-rating [max]="5" [(rate)]="rbchild2.answer"
																	[starTemplate]="t">
																</ngb-rating>
															</div>
														</div>
													</div>
													<div *ngIf="rbchild.answer == 'YES'">
														<div *ngFor="let rbchild2 of rbchild.YES"
															style="margin-top: 10px; margin-left: 20px">
															<span>{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span></span><br>
															<mat-radio-group aria-labelledby="radio{{$childIndex}}"
																[(ngModel)]="rbchild2.answer" class="ml-3">

																<mat-radio-button value="{{d2}}"
																	[ngClass]="'radioPresent'"
																	*ngFor="let d2 of getParsed(rbchild2.rb_data)"
																	style="padding-right: 12px;">{{d2}}
																</mat-radio-button>

															</mat-radio-group>
														</div>
													</div>
													<div *ngIf="rbchild.answer == 'NO'">
														<div *ngFor="let rbchild2 of rbchild.NO"
															style="margin-top: 10px; margin-left: 20px">
															<span>{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span></span><br>
															<mat-radio-group aria-labelledby="radio{{$childIndex}}"
																*ngIf="rbchild2.at == 'RB'"
																[(ngModel)]="rbchild2.answer" class="ml-3">

																<mat-radio-button value="{{d2}}"
																	[ngClass]="'radioPresent'"
																	*ngFor="let d2 of getParsed(rbchild2.rb_data)"
																	style="padding-right: 12px;">{{d2}}
																</mat-radio-button>

															</mat-radio-group>
															<mat-form-field class="wd-100" *ngIf="rbchild2.at == 'FT'">
																<mat-label>{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span></mat-label>
																<input matInput placeholder=""
																	[(ngModel)]="rbchild2.answer">
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>

											<div *ngIf="child.answer == 'NO'">
												<div *ngFor="let rbchild of child.NO"
													style="margin-top: 10px; margin-left: 10px">
													<span>{{rbchild.q}}<span *ngIf="rbchild.mandatory">*</span></span><br>
													<mat-radio-group aria-labelledby="radio{{$childIndex}}"
														[(ngModel)]="rbchild.answer" class="ml-3">

														<mat-radio-button value="{{d1}}" [ngClass]="'radioPresent'"
															*ngFor="let d1 of getParsed(rbchild.rb_data)"
															style="padding-right: 12px;">
															{{d1}}</mat-radio-button>

													</mat-radio-group>
													<br />
													<div *ngIf="rbchild.answer == 'NO'">
														<div *ngFor="let rbchild2 of rbchild.NO"
															style="margin-top: 10px; margin-left: 20px">
															<span>{{rbchild2.q}}<span *ngIf="rbchild2.mandatory">*</span></span><br>
															<mat-radio-group aria-labelledby="radio{{$childIndex}}"
																[(ngModel)]="rbchild2.answer" class="ml-3">

																<mat-radio-button value="{{d2}}"
																	[ngClass]="'radioPresent'"
																	*ngFor="let d2 of getParsed(rbchild2.rb_data)"
																	style="padding-right: 12px;">{{d2}}
																</mat-radio-button>

															</mat-radio-group>
														</div>
													</div>
												</div>
											</div>




										</div>

										<mat-form-field class="wd-100" *ngIf="child.at == 'SDD'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<!-- <input matInput placeholder="Search" >   -->
											<mat-select placeholder="{{child.q}}{{child.mandatory ? '*' : ''}}" [(ngModel)]="child.answer">
												<mat-option [value]="d" *ngFor="let d of getParsed(child.sdd_data)">
													{{d}}</mat-option>
											</mat-select>
										</mat-form-field>

										<mat-form-field class="wd-100" *ngIf="child.at == 'MSDD'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<mat-select multiple [(ngModel)]="child.answer">
												<mat-option [value]="d" *ngFor="let d of getParsed(child.msdd_data)">
													{{d}}</mat-option>
											</mat-select>
										</mat-form-field>

										<mat-form-field class="wd-100" *ngIf="child.at == 'TIME'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput placeholder="Time" type="time" min="00:00" max="24:00"
												required style="font-size: 15px !important;">
										</mat-form-field>

										<mat-form-field class="wd-100" *ngIf="child.at == 'DATETIME'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput [matDatepicker]="dpicker"
												(dateChange)="change_date($event, $index, $childIndex)"
												placeholder="Date" readonly (click)="dpicker.open()">
											<mat-datepicker-toggle matSuffix [for]="dpicker" style="font-size: 25px;">
											</mat-datepicker-toggle>
											<mat-datepicker #dpicker></mat-datepicker>
										</mat-form-field>

										<mat-form-field class="wd-100" *ngIf="child.at == 'DATETIME'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput placeholder="Time" type="time"
												(change)="change_time($event, $index, $childIndex)" min="00:00"
												max="24:00" required style="font-size: 15px !important;">
										</mat-form-field>

										<mat-form-field class="wd-100" *ngIf="child.at == 'DD'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<mat-select [(ngModel)]="child.answer">
												<mat-option value="{{d}}" *ngFor="let d of getParsed(child.dd_data)">
													{{d}}</mat-option>
											</mat-select>
										</mat-form-field>

										<mat-form-field class="wd-100" *ngIf="child.at == 'FLP'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput placeholder="">
										</mat-form-field>


										<div *ngIf="child.at == 'BT'">
											{{child.q}}<span *ngIf="child.mandatory">*</span><br>
											<button mat-button class="savbtn">Button</button>
										</div>
									</div>

								</div>
							</td>
						</tr>

						<tr *ngIf="myUser.groupid == 82719">
							<td *ngFor="let section of callFlowJsonSections;let $index=index"
								style="padding-left: 0px;padding-top: 15px;">
								<div class="row" *ngFor="let child of section.child;let $childIndex=index">
									<div class="col-md-5">
										<mat-form-field class="wd-100" *ngIf="child.at == 'FT'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput placeholder="" [(ngModel)]="child.answer">
										</mat-form-field>



										<mat-form-field class="wd-100" *ngIf="child.at == 'FN'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput placeholder="" type="number" [(ngModel)]="child.answer">
										</mat-form-field>





										<mat-form-field class="wd-100" *ngIf="child.at == 'DD'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<mat-select [(ngModel)]="child.answer">
												<mat-option value="{{d}}" *ngFor="let d of getParsed(child.dd_data)">
													{{d}}</mat-option>
											</mat-select>
										</mat-form-field>

										<br />



										<span *ngIf="child.decision == 'y' && child.answer">

											<div class="row"
												*ngFor="let child2 of child[child.answer];let $childIndex2=index">
												<mat-form-field class="wd-100" *ngIf="child2.at == 'BLANK'">
													<mat-label>{{child2.q}}<span *ngIf="child2.mandatory">*</span></mat-label>
													<input matInput placeholder="" [(ngModel)]="child2.answer">
												</mat-form-field>
												<mat-form-field class="wd-100" *ngIf="child2.at == 'FT'">
													<mat-label>{{child2.q}}<span *ngIf="child2.mandatory">*</span></mat-label>
													<input matInput placeholder="" [(ngModel)]="child2.answer">
												</mat-form-field>
												<mat-form-field class="wd-100" *ngIf="child2.at == 'DATE'">
													<mat-label>{{child2.q}}<span *ngIf="child2.mandatory">*</span></mat-label>
													<input matInput [matDatepicker]="picker" placeholder="Date" readonly
														(click)="picker.open()" [(ngModel)]="child2.answer">
													<mat-datepicker-toggle matSuffix [for]="picker"
														style="font-size: 25px;">
													</mat-datepicker-toggle>
													<mat-datepicker #picker></mat-datepicker>
												</mat-form-field>
												<mat-form-field class="wd-100" *ngIf="child2.at == 'MSDD'">
													<mat-label>{{child2.q}}<span *ngIf="child2.mandatory">*</span></mat-label>
													<mat-select multiple [(ngModel)]="child2.answer"
														(selectionChange)="checkNextTrigger($event, child2.next_action)">
														<mat-option [value]="d"
															*ngFor="let d of getParsed(child2.msdd_data)">{{d}}
														</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
										</span>


									</div>

								</div>
							</td>
						</tr>

						<tr *ngIf="myUser.groupid == 25461">
							<td *ngFor="let section of callFlowJsonSections;let $index=index" style="padding-left: 0px;padding-top: 15px;">
								<div class="row" *ngFor="let child of section.child;let $childIndex=index">
									<div class="col-md-5">
										<mat-form-field class="wd-100" *ngIf="child.at == 'FT'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput placeholder="" [(ngModel)]="child.answer">
										</mat-form-field>
										
										
										
										<mat-form-field class="wd-100" *ngIf="child.at == 'FN'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<input matInput placeholder="" type="number" [(ngModel)]="child.answer">
										</mat-form-field>

										<mat-form-field class="wd-100" *ngIf="child.at == 'DD'">
											<mat-label>{{child.q}}<span *ngIf="child.mandatory">*</span></mat-label>
											<mat-select [(ngModel)]="child.answer">
												<mat-option value="{{d}}" *ngFor="let d of getParsed(child.dd_data)">
													{{d}}</mat-option>
											</mat-select>
										</mat-form-field>
										
										<br />
										
										
										
										<span *ngIf="child.decision == 'y' && child.answer">
										
											<div class="row" *ngFor="let child2 of child[child.answer];let $childIndex2=index">
												<mat-form-field class="wd-100" *ngIf="child2.at == 'BLANK'">
													<mat-label>{{child2.q}}<span *ngIf="child2.mandatory">*</span></mat-label>
													<input matInput placeholder="" [(ngModel)]="child2.answer">
												</mat-form-field>
												<mat-form-field class="wd-100" *ngIf="child2.at == 'FT'">
													<mat-label>{{child2.q}}<span *ngIf="child2.mandatory">*</span></mat-label>
													<input matInput placeholder="" [(ngModel)]="child2.answer">
												</mat-form-field>
												<mat-form-field class="wd-100" *ngIf="child2.at == 'DATE'">
													<mat-label>{{child2.q}}<span *ngIf="child2.mandatory">*</span></mat-label>
													<input matInput [matDatepicker]="picker" placeholder="Date" readonly (click)="picker.open()"
														[(ngModel)]="child2.answer">
													<mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 25px;">
													</mat-datepicker-toggle>
													<mat-datepicker #picker></mat-datepicker>
												</mat-form-field>
												<mat-form-field class="wd-100" *ngIf="child2.at == 'MSDD'">
													<mat-label>{{child2.q}}<span *ngIf="child2.mandatory">*</span></mat-label>
													<mat-select multiple [(ngModel)]="child2.answer"
														(selectionChange)="checkNextTrigger($event, child2.next_action)">
														<mat-option [value]="d" *ngFor="let d of getParsed(child2.msdd_data)">{{d}}
														</mat-option>
													</mat-select>
												</mat-form-field>
											</div>
										</span>
										
									</div>
								</div>
							</td>
						</tr>
						<!-- <tr><td><br></td></tr>
									<tr><td><p class="call">NEXT</p></td></tr> -->
					</table>
					<div style="text-align: center;">
						<p style="color:red" *ngIf="showDuringFormError">Please fill all details</p>
						<button class="vk-form-clear" (click)="clearDuringCallForm()">Clear </button>
						<button class="vk-form-submit" (click)="duringFormSubmit()"> Submit</button>
					</div>
				</div>
				<div *ngIf="showHide_Form_After_DuringForm" class="mt-4">
					<div class="row">
						<div class="col-md-5">
							<p class="text-left" *ngIf="customerInfo"> <b>{{customerInfo.customerlead_source}}</b><br>
								<b>{{customerInfo.status}}</b><br>
								<br>
							</p>
							<table border="1" class="ssTable1" *ngIf="customerInfo">
								<tr>
									<td style="background-color: #f2f2f2;"> <b style="font-weight: 500;"> Customer
											Name</b></td>
									<td>
										{{ customerInfo.customer_name }}</td>
								</tr>
								<tr>
									<td style="background-color: #f2f2f2;"> <b style="font-weight: 500;">Account
											Number</b></td>
									<td>
										{{ customerInfo.customer_number }}</td>
								</tr>
								<tr>
									<td style="background-color: #f2f2f2;"><b style="font-weight: 500;">Profession</b>
									</td>
									<td>NA </td>
								</tr>
							</table>
							<br>
							<div style="display: flex;">
								<div *ngIf="typeTab && typeTab != 'rnr'" class="ddBtn1" (click)="clickHighlight('update')"
									[ngClass]="{ activeDDbtn: isActive('update') }">
									<p class="mb-0">
										<mat-icon class="ddIconx1">done </mat-icon>
									</p>
									<p class="ddTxt1">UPDATE</p>
								</div>
								<div class="ddBtn1" *ngIf="typeTab && typeTab != 'rnr'" (click)="clickHighlight('callback1')"
									[ngClass]="{ activeDDbtn: isActive('callback1') }">
									<p class="mb-0">
										<mat-icon class="ddIconx1">date_range </mat-icon>
									</p>
									<p class="ddTxt1">Follow Up</p>
								</div>

								<div *ngIf="!customerInfo" class="ddBtn1" (click)="clickHighlight('rnr')"
									[ngClass]="{ activeDDbtn: isActive('rnr') }">
									<p class="mb-0">
										<mat-icon class="ddIconx1">call_missed_outgoing </mat-icon>
									</p>
									<p class="ddTxt1">RNR</p>
								</div>

								<div class="ddBtn1" *ngIf="typeTab && typeTab != 'rnr'" (click)="clickHighlight('callback2')"
									[ngClass]="{ activeDDbtn: isActive('callback2') }">
									<p class="mb-0">
										<mat-icon class="ddIconx1">north_east </mat-icon>
									</p>
									<p class="ddTxt1">REDIAL</p>
								</div>

							</div>
						</div>
						<div class="col-md"> </div>
						<div class="col-md-1">
							<div class="vl"></div>
						</div>
						<div class="col-md-5">

							<div *ngIf="showHideCallBack1">
								<div class="text-left">
									<mat-form-field appearance="outline"  [ngClass]="'ddl-dd'"
										style="width: 250px;margin-right: 15px;">
										<mat-select placeholder="Select" [(ngModel)]="disposition">
											<mat-option value="">Select</mat-option>
											<mat-option value="follow_up">Follow Up</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="text-left">
									<label>Select Time</label>
								</div>
								<div class="text-left">
									<button class="ddl-dd-btn" (click)="clickTimeHighlight('10')"
										[ngClass]="{ activeTimeBtn: isTimeActive('10') }"> 10 Mins </button>
									<button class="ddl-dd-btn" (click)="clickTimeHighlight('30')"
										[ngClass]="{ activeTimeBtn: isTimeActive('30') }"> 30 Mins </button>
									<button class="ddl-dd-btn" (click)="clickTimeHighlight('60')"
										[ngClass]="{ activeTimeBtn: isTimeActive('60') }"> 60 Mins </button>

									<br>
									<table>
										<tr>
											<td colspan="2" class="text-right">OR</td>
										</tr>
										<tr>
											<td>
												<label>Date</label> <br>
												<mat-form-field appearance="outline" [ngClass]="'ddl-dd'"
													style="width: 150px;margin-right: 15px;">
													<input matInput [matDatepicker]="pickerx" placeholder="Date"
														[(ngModel)]="dateModel" readonly (click)="pickerx.open()">
													<mat-datepicker-toggle matSuffix [for]="pickerx">
														<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
													</mat-datepicker-toggle>
													<mat-datepicker #pickerx></mat-datepicker>
												</mat-form-field>
											</td>
											<td style="position: absolute;">
												<label>Time</label><br>
												<span> <input type="time" id="appt" [(ngModel)]="timeModel" name="appt"
														style="border: 1px solid #ccc;border-radius: 4px;padding: 6px;outline: none;color: gray;"></span>
											</td>
										</tr>
									</table>


								</div>
								<p style="color:red" *ngIf="showDuringFormError">Please fill mandatory details</p>
							</div>

							<div *ngIf="showHideCallRNR">
								<div class="text-left">
									<mat-form-field appearance="outline" [ngClass]="'ddl-dd'"
										style="width: 300px;margin-right: 15px;">
										<mat-select [ngClass]="'arrowcsDDL'" class="dropdownDDL" (selectionChange)="selectRnrReason($event.value)"
											placeholder="Select" [(value)]="selectedRnrReason">
											<mat-option value="{{r}}" *ngFor="let r of rnroptions">{{r}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<!-- <div class="text-left">
			<label>Description</label>			
	   </div> -->
								<div class="text-left">
									<mat-form-field appearance="outline" [ngClass]="'ddl-dd'"
										style="width: 300px;margin-right: 15px;">
										<textarea matInput placeholder="Description" [(ngModel)]="rnrDescription"
											rows="5" style="resize: none;"></textarea>
									</mat-form-field>
								</div>
								<p class="text-left" style="font-family: poppins;font-size: 16px;width: 70%;">Please Click <span
										style="color:#3fb90d">Submit</span> to complete
									this call and move on to the next call.</p>
									<p style="color:red" *ngIf="showDuringFormError">Please fill mandatory details</p>
							</div>
						</div>



					</div>
					<div style="text-align: center;" class="mt-3">
						<button class="vk-form-clear" *ngIf="typeTab == 'rnr' || typeTab == 'callback1'" (click)="restFm()">Reset </button>
						<button class="vk-form-submit" (click)="endCall()"> Submit</button>
					</div>
				</div>
			</div>
		</div>
		<div>
			<mat-card class="mt-3">
				<div class="row">

					<div class="row mt-4">
						<!--<div class="col-md-1"></div>-->
						<div class="col-md-4">
							<div>
								<mat-card class="ddCard" (click)="SelectLeads()"
									[ngClass]="{'green' : LeadsSelected, 'red': !LeadsSelected}">
									<p class="mb-1"><b class="dd-head">Leads Contacted</b></p>
									<table style="width:100%">
										<tr>
											<td> <img src="../../../assets/desktop_calling/leads_contacted.svg"
													class="ddImg1" /> </td>
											<td style="text-align: right;padding-right: 5px;"> <b
													style="font-size: 33px;">{{_countLeadLength}}</b></td>
											<td>
												<mat-icon style="color:#2ec114"> trending_up</mat-icon>
											</td>
										</tr>
									</table>
								</mat-card>
							</div>
						</div>
						<div class="col-md-4">
							<div>
								<mat-card class="ddCard" (click)="SelectFollowup()"
									[ngClass]="{'green' : FollowupSelected, 'red': !FollowupSelected}">
									<p class="mb-1"><b class="dd-head">Follow - ups to do</b></p>
									<table style="width:100%">
										<tr>
											<td> <img src="../../../assets/desktop_calling/follow_ups.svg"
													class="ddImg1" />
											</td>
											<td style="text-align: right;padding-right: 5px;"> <b
													style="font-size: 33px;">{{_countfollowUpLength}}</b></td>
											<td>
												<mat-icon style="color:red"> trending_down</mat-icon>
											</td>
										</tr>
									</table>
								</mat-card>
							</div>
						</div>
						<!--<div class="col-md-3">
							<div>
								<mat-card class="ddCard">
									<p class="mb-1"><b class="dd-head">Contacted Calls</b></p>
									<table style="width:100%">
										<tr>
											<td> <img src="../../../assets/desktop_calling/leads_contacted.svg"
													class="ddImg1" /> </td>
											<td style="text-align: right;padding-right: 5px;"> <b
													style="font-size: 33px;">{{_contactedData}}</b></td>
											<td>
												<mat-icon style="color:#2ec114"> trending_up</mat-icon>
											</td>
										</tr>
									</table>
								</mat-card>
							</div>
						</div>-->
						<div class="col-md-4">
							<div>
								<mat-card class="ddCard" (click)="SelectFilledForm()"
									[ngClass]="{'green' : FilledFormsSelected, 'red': !FilledFormsSelected}">
									<p class="mb-1"><b class="dd-head">Filled Forms</b></p>
									<table style="width:100%">
										<tr>
											<td> <img src="../../../assets/desktop_calling/follow_ups.svg"
													class="ddImg1" />
											</td>
											<td style="text-align: right;padding-right: 5px;"> <b
													style="font-size: 33px;">{{countoffilleddata}}</b></td>
											<td>
												<mat-icon style="color:red"> trending_down</mat-icon>
											</td>
										</tr>
									</table>
								</mat-card>
							</div>
						</div>
						<!-- <div class="col-md-1"></div> -->
					</div>
					<div class="row mt-4" *ngIf="LeadsSelected">
						<div class="col-md-4">
							<table class="table" style="border-collapse: collapse;margin-top: 55px;border: 1px solid #ccc"
								*ngIf="_logintime && _logintime.length > 0">
								<thead style="background:#f2f2f2;">
									<tr>
										<th>Time</th>
										<th>Hrs</th>
										<th>Mins</th>
										<th>Secs</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Login</td>
										<td>{{_logintime[0]}}</td>
										<td>{{_logintime[1]}}</td>
										<td>{{_logintime[2]}}</td>
									</tr>
									<tr>
										<td>Talk</td>
										<td>{{_talktime[0]}}</td>
										<td>{{_talktime[1]}}</td>
										<td>{{_talktime[2]}}</td>
									</tr>
									<tr>
										<td>Break</td>
										<td>{{_breaktime[0]}}</td>
										<td>{{_breaktime[1]}}</td>
										<td>{{_breaktime[2]}}</td>
									</tr>
									<tr>
										<td>Active</td>
										<td>{{_activetime[0]}}</td>
										<td>{{_activetime[1]}}</td>
										<td>{{_activetime[2]}}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-md-8">
							<div class="col-md-12" style="left: 230px;">
								<mat-form-field appearance="fill">
									<mat-label>Select Date</mat-label>
									<mat-select [ngClass]="'arrowcsDDL'"
										class="dropdownDDL" (selectionChange)="LeadFilter($event.value)"
										placeholder="Select" [(value)]="leadFilter">
										<mat-option value="TODAY">TODAY</mat-option>
										<mat-option value="YESTERDAY">YESTERDAY</mat-option>
										<mat-option value="ALL_IN_PAST">ALL IN PAST</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-12 outerDiv" style="margin-top: -14px;">
							<mat-table [dataSource]="dataSource" class="table vertical-scrollable tableheader1"
								style="border-collapse: collapse;background:#f2f2f2;font-size:14px;border: 1px solid #ccc">
								<!--[dataSource]="dataSource"-->

								<ng-container matColumnDef="name">
									<mat-header-cell *matHeaderCellDef style="flex: 0 0 220px;">Name </mat-header-cell>
									<mat-cell *matCellDef="let element" style="flex: 0 0 220px;">{{element.name}}
									</mat-cell>
								</ng-container>

								<ng-container matColumnDef="lead_source">
									<mat-header-cell *matHeaderCellDef style="flex: 0 0 250px;">Lead Source
									</mat-header-cell>
									<mat-cell *matCellDef="let element" style="flex: 0 0 250px;">{{element.lead_source}}
									</mat-cell>
								</ng-container>

								<ng-container matColumnDef="status">
									<mat-header-cell *matHeaderCellDef style="flex: 0 0 100px;"> Status
									</mat-header-cell>
									<mat-cell *matCellDef="let element" style="flex: 0 0 100px;"> {{element.stage}}
									</mat-cell>
								</ng-container>

								<ng-container matColumnDef="dateandtime">
									<mat-header-cell *matHeaderCellDef style="flex: 0 0 150px;"> Date & Time
									</mat-header-cell>
									<mat-cell *matCellDef="let element" style="flex: 0 0 150px;">{{element.start_time|
										slice:0:10 | date:'dd-MMM-yyyy'}}&ensp;{{element.start_time | date:'h:mm a'}}
									</mat-cell>
								</ng-container>

								<ng-container matColumnDef="recording">
									<mat-header-cell *matHeaderCellDef style="flex: 0 0 69px;">Recording
									</mat-header-cell>
									<mat-cell *matCellDef="let element" style="flex: 0 0 69px;">
										<div *ngIf="element.url">
											<mat-icon mat-list-icon class="play_icon" (click)="selectCall(element)">
												play_circle_filled</mat-icon>
										</div>
										<p *ngIf="!element.url">None</p>
									</mat-cell>
								</ng-container>
								<ng-container matColumnDef="call">
									<mat-header-cell *matHeaderCellDef>Call</mat-header-cell>
									<mat-cell *matCellDef="let element">
										<span class="play_icon" [ngClass]="{ callbutton: isActive('element.number')}"
											(click)="callCustomer(element.number)"><i
												class="fas fa-phone-alt"></i></span>
									</mat-cell>
								</ng-container>

								<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
								<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
							</mat-table>
							<mat-paginator #TableOnePaginator [pageSizeOptions]="[5, 10, 25, 100]">
							</mat-paginator>
							<!--<div class="audio_player" *ngIf="current_url">
									<app-audio-player [source_url]="current_url"></app-audio-player>
								</div>-->
							</div>
						</div>
					</div>
					<br>
					<div class="row mt-4" *ngIf="FollowupSelected">
						<div class="col-md-4">
							<table class="table"
								style="border-collapse: collapse;margin-top: 55px;border: 1px solid #ccc"
								*ngIf="_logintime && _logintime.length > 0">
								<thead style="background:#f2f2f2;">
									<tr>
										<th>Time</th>
										<th>Hrs</th>
										<th>Mins</th>
										<th>Secs</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Login</td>
										<td>{{_logintime[0]}}</td>
										<td>{{_logintime[1]}}</td>
										<td>{{_logintime[2]}}</td>
									</tr>
									<tr>
										<td>Talk</td>
										<td>{{_talktime[0]}}</td>
										<td>{{_talktime[1]}}</td>
										<td>{{_talktime[2]}}</td>
									</tr>
									<tr>
										<td>Break</td>
										<td>{{_breaktime[0]}}</td>
										<td>{{_breaktime[1]}}</td>
										<td>{{_breaktime[2]}}</td>
									</tr>
									<tr>
										<td>Active</td>
										<td>{{_activetime[0]}}</td>
										<td>{{_activetime[1]}}</td>
										<td>{{_activetime[2]}}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-md-8 ">
							<div class="col-md-12">
								 <div class="col-md-6" style="width: 100%;">
									<!--<span>
										<mat-form-field >
											<mat-label>From date</mat-label>
											<input matInput [matDatepicker]="frompicker" [(ngModel)]="fromDate"  (dateChange)="onDate()"  [max]="todayDate" (click)="frompicker.open()" readonly>
											<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
											<mat-datepicker #frompicker></mat-datepicker>
										</mat-form-field>
									</span>&ensp;&ensp;
									<span>
										<mat-form-field  >
											<mat-label>To date</mat-label>
											<input matInput [matDatepicker]="topicker"  [(ngModel)]="toDate"   (dateChange)="onDate()"  [max]="todayDate" (click)="topicker.open()" readonly>
											<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
											<mat-datepicker #topicker></mat-datepicker>
										</mat-form-field>
									</span>
									&ensp;&ensp;
										<button mat-button style="min-width: 43px;" class="gobtn"(click)="goBtn()">Go</button>
									&ensp;&ensp; -->
								</div>
								<div class="col-md-6" style="left: 330px;">
										<mat-select *ngIf="selectdate; else customdate" [ngClass]="'arrowcsDDL'"
											class="dropdownDDL" (selectionChange)="FollowFilter($event.value)"
											placeholder="Select" [(value)]="selectedFilter">
											<mat-option value="TODAY">TODAY</mat-option>
											<mat-option value="YESTERDAY">YESTERDAY</mat-option>
											<mat-option value="ALL_IN_PAST">ALL IN PAST</mat-option>
											<mat-option value="ALL_IN_FUTURE">ALL IN FUTURE</mat-option>
											<mat-option value="CHOOSE_A_DATE">CHOOSE A DATE</mat-option>
										</mat-select>
										<ng-template #customdate>
											<mat-form-field appearance="fill" style="margin-bottom: -20px;">
												<mat-label>Choose a date</mat-label>
												<input matInput [(ngModel)]="fromDate" [matDatepicker]="picker">
												<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
												<mat-datepicker #picker></mat-datepicker>
											</mat-form-field>
											&ensp;&ensp;
											<button mat-button style="min-width: 43px;margin-left: 2px;" class="gobtn"
												(click)="goBtn()">Go</button>
										</ng-template>
								</div>
							</div>
							<div class="col-md-12 outerDiv">
								<mat-table [dataSource]="followupSource" class="table tableheader2"
									style="border-collapse: collapse;background:#f2f2f2;font-size:14px;border: 1px solid #ccc;">
									<!--[dataSource]="dataSource"-->

									<ng-container matColumnDef="name">
										<mat-header-cell *matHeaderCellDef style="flex: 0 0 150px;">Name
										</mat-header-cell>
										<mat-cell *matCellDef="let element" style="flex: 0 0 150px;">{{element.name}}
										</mat-cell>
									</ng-container>

									<ng-container matColumnDef="lead_source">
										<mat-header-cell *matHeaderCellDef style="flex: 0 0 250px;">Lead Source
										</mat-header-cell>
										<mat-cell *matCellDef="let element" style="flex: 0 0 250px;">
											{{element.lead_source}}
										</mat-cell>
									</ng-container>

									<ng-container matColumnDef="status">
										<mat-header-cell *matHeaderCellDef style="flex: 0 0 100px;"> Status
										</mat-header-cell>
										<mat-cell *matCellDef="let element" style="flex: 0 0 100px;"> {{element.stage}}
										</mat-cell>
									</ng-container>

									<ng-container matColumnDef="dateandtime">
										<mat-header-cell *matHeaderCellDef style="flex: 0 0 150px;"> Date & Time
										</mat-header-cell>
										<mat-cell *matCellDef="let element" style="flex: 0 0 150px;">
											{{element.start_date|
											slice:0:10 | date:'dd-MMM-yyyy'}}&ensp;{{element.start_date | date:'h:mm
											a'}}</mat-cell>
									</ng-container>

									<ng-container matColumnDef="recording">
										<mat-header-cell *matHeaderCellDef style="flex: 0 0 69px;">Recording
										</mat-header-cell>
										<mat-cell *matCellDef="let element" style="flex: 0 0 69px;">
											<div *ngIf="element.url">
												<mat-icon mat-list-icon class="play_icon" (click)="selectCall(element)">
													play_circle_filled</mat-icon>
											</div>
											<p *ngIf="!element.url">
												None
											</p>
										</mat-cell>
									</ng-container>
									<ng-container matColumnDef="call">
										<mat-header-cell *matHeaderCellDef style="flex: 0 0 70px;">Call
										</mat-header-cell>
										<mat-cell *matCellDef="let element" style="flex: 0 0 28px;">
											<span class="play_icon"
												[ngClass]="{ callbutton: isActive('element.number') }"
												(click)="callCustomer(element.number)"><i
													class="fas fa-phone-alt"></i></span>
										</mat-cell>
									</ng-container>

									<mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
									<mat-row *matRowDef="let row; columns: displayedColumns2;"></mat-row>
								</mat-table>
								<mat-paginator #TableThreePaginator [pageSizeOptions]="[5, 10, 25, 100]">
								</mat-paginator>
								<!--<div class="audio_player" *ngIf="current_url">
									<app-audio-player [source_url]="current_url"></app-audio-player>
								</div>-->
							</div>
						</div>
					</div>
					<div class="row mt-4" *ngIf="FilledFormsSelected">
						<div class="col-md-8">
							<div class="row">

								<div class="mat-elevation-z8 outerDiv">
									<mat-table [dataSource]="filledSource" class="table tableheader3"
										style="border-collapse: collapse;background:#f2f2f2;font-size:14px;">

										<!-- Position Column -->
										<ng-container matColumnDef="name">
											<mat-header-cell *matHeaderCellDef style="flex: 0 0 220px;"> Name.
											</mat-header-cell>
											<mat-cell *matCellDef="let element" style="flex: 0 0 220px;">
												{{element.name}} </mat-cell>
										</ng-container>

										<!-- Name Column -->
										<ng-container matColumnDef="lead_source">
											<mat-header-cell *matHeaderCellDef style="flex: 0 0 225px;"> lead_source
											</mat-header-cell>
											<mat-cell *matCellDef="let element" style="flex: 0 0 225px;">
												{{element.lead_source}} </mat-cell>
										</ng-container>

										<!-- Weight Column -->
										<ng-container matColumnDef="dateandtime">
											<mat-header-cell *matHeaderCellDef style="flex: 0 0 150px;"> Date&Time
											</mat-header-cell>
											<mat-cell *matCellDef="let element" style="flex: 0 0 150px;">
												{{element.updated_at|
												slice:0:10 | date:'dd-MMM-yyyy'}}&ensp;{{element.updated_at | date:'h:mm
												a'}}</mat-cell>
										</ng-container>

										<ng-container matColumnDef="comment">
											<mat-header-cell *matHeaderCellDef> comment
											</mat-header-cell>
											<mat-cell *matCellDef="let element">
												<button mat-button
													(click)="filledRowRecord(element)">click</button>
											</mat-cell>
										</ng-container>

										<mat-header-row *matHeaderRowDef="displayedColumns1"></mat-header-row>
										<mat-row *matRowDef="let row; columns: displayedColumns1;"></mat-row>
									</mat-table>
									<mat-paginator #TableTwoPaginator [pageSizeOptions]="[5, 10, 25, 100]">
									</mat-paginator>
								</div>
							</div>
						</div>
						<div class="col-md-4 text-left " *ngIf="showhideQuestionAnwer"
							style="overflow: hidden scroll;height: calc(100vh- 500px);">
							<div *ngFor="let questionanswer of filledjsonObject">
								<p style="color: black;"><span>Question:&ensp;<b>{{questionanswer.q}}</b></span></p>
								<p style="color: black;"><span>Answer:&ensp;</span><b>{{questionanswer.a}}</b></p><br>
							</div>
						</div>
					</div>

					<br>
				</div>
			</mat-card>
		</div>
		<div class="cc-title-container" *ngIf="false">
			<mat-card class="mt-3">
				<h4 class="ml-3">Title</h4>
				<hr>
				<div class="row text-left">
					<div class="col-md-4">

						<table>
							<tr>
								<td class="vl-td"><i class="fa fa-circle" aria-hidden="true" style="color:#3fb91f"></i>
								</td>
								<td><span class="cc-lvl">Congratulations! Your booking is
										successfull</span>
									<span>
										<mat-form-field class="cc-wd-100">
											<input matInput placeholder="blank" autocomplete="off"
												style="padding: 4px;">
										</mat-form-field>
									</span>
								</td>
							</tr>

							<tr>
								<td class="vl-td"><i class="fa fa-circle" aria-hidden="true" style="color:#3fb91f"></i>
								</td>
								<td><span class="cc-lvl"> What could we do to make your
										experience better?</span>
									<span>
										<mat-form-field class="cc-wd-100">
											<input matInput placeholder="Free Text" autocomplete="off"
												style="padding: 4px;">
										</mat-form-field>
									</span>
								</td>
							</tr>
							<tr>
								<td class="vl-td"><i class="fa fa-circle" aria-hidden="true" style="color:#3fb91f"></i>
								</td>
								<td><span class="cc-lvl"> What could we do to make your
										experience better?</span>
									<span>
										<mat-form-field class="cc-wd-100">
											<input matInput placeholder="Free Text" autocomplete="off"
												style="padding: 4px;">
										</mat-form-field>
									</span>
								</td>
							</tr>
							<tr>
								<td class="vl-td"><i class="fa fa-circle" aria-hidden="true" style="color:#3fb91f"></i>
								</td>
								<td><span class="cc-lvl"> Enter your pincode</span>
									<span>
										<mat-form-field class="cc-wd-100">
											<input matInput placeholder="Pincode" autocomplete="off"
												style="padding: 4px;">
										</mat-form-field>
									</span>
								</td>
							</tr>
						</table>
					</div>
					<div class="col-md-4">

						<table>
							<tr>
								<td class="vl-td"><i class="fa fa-circle" aria-hidden="true" style="color:#3fb91f"></i>
								</td>
								<td><span class="cc-lvl">How has your fueling experience been
										at the HP outlets?</span>
									<span>

										<mat-radio-group class="cc-wd-100 ml-2" aria-label="Select an option">
											<mat-radio-button value="1">Option 1 </mat-radio-button>
											<mat-radio-button value="2">Option 2 </mat-radio-button>
											<mat-radio-button value="3">Option 3 </mat-radio-button>
											<mat-radio-button value="4">Option 4 </mat-radio-button>
										</mat-radio-group>

									</span>
								</td>
							</tr>

							<tr>
								<td class="vl-td"><i class="fa fa-circle" aria-hidden="true" style="color:#3fb91f"></i>
								</td>
								<td><span class="cc-lvl">What mode of payment do you use to
										pay for fuel?</span>
									<span>
										<mat-form-field class="cc-wd-100">
											<select matNativeControl required>
												<option value="good">Good</option>
												<option value="verygood">Very Good</option>
												<option value="average">Average</option>
												<option value="excellent">excellent</option>
											</select>
										</mat-form-field>
									</span>
								</td>
							</tr>
							<tr>
								<td class="vl-td"><i class="fa fa-circle" aria-hidden="true" style="color:#3fb91f"></i>
								</td>
								<td><span class="cc-lvl"> Which state you are from?</span>
									<span>
										<mat-form-field class="cc-wd-100">
											<input matInput placeholder="Free Text" autocomplete="off"
												style="padding: 4px;">
										</mat-form-field>
									</span>
								</td>
							</tr>
							<tr>
								<td class="vl-td"><i class="fa fa-circle" aria-hidden="true" style="color:#3fb91f"></i>
								</td>
								<td><span class="cc-lvl">Select languages you are best at</span>
									<span>
										<mat-form-field class="cc-wd-100">
											<select matNativeControl required>
												<option value="good">Good</option>
												<option value="verygood">Very Good</option>
												<option value="average">Average</option>
												<option value="excellent">excellent</option>
											</select>
										</mat-form-field>
									</span>
								</td>
							</tr>
						</table>
					</div>

					<div class="col-md-4">

						<table>
							<tr>
								<td class="vl-td"><i class="fa fa-circle" aria-hidden="true" style="color:#3fb91f"></i>
								</td>
								<td><span class="cc-lvl">Would you be interested if we could
										offer you 4.25% value back as a loyalty
										benefit for your monthly fuel spends?</span>
									<span>
										<mat-form-field class="cc-wd-100">
											<input matInput placeholder="blank" autocomplete="off"
												style="padding: 4px;">
										</mat-form-field>
									</span>
								</td>
							</tr>

							<tr>
								<td class="vl-td"><i class="fa fa-circle" aria-hidden="true" style="color:#3fb91f"></i>
								</td>
								<td><span class="cc-lvl"> Document(s) required</span>
									<span>
										<mat-form-field class="cc-wd-100">
											<select matNativeControl required>
												<option value="good">Good</option>
												<option value="verygood">Very Good</option>
												<option value="average">Average</option>
												<option value="excellent">excellent</option>
											</select>
										</mat-form-field>
									</span>
								</td>
							</tr>
						</table>
					</div>

				</div>
				<div class="row mt-4">
					<div class="col-md-12 col-sm-12 col-xs-12 text-center">
						<button mat-button class="cancelbtn"
							style="color: #3fb90d !important;border: solid 1px #3fb90d !important;height: 40px;width:120px;">Reset</button>
						<button mat-button class="submitbtn ml-2"
							style="border: solid 1px #3fb90d !important;background-color: #3fb90d !important;height: 40px;width:120px;">Submit</button>
					</div>

				</div>
			</mat-card>
		</div>
		<audio #stream id="remoteAudio" controls style="display: contents;">
			<p>Your browser doesn't support HTML5 audio.</p>
		</audio>
	</div>
</div>