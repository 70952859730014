import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-call-dropdown',
  templateUrl: './call-dropdown.component.html',
  styleUrls: ['./call-dropdown.component.scss']
})
export class CallDropdownComponent implements OnInit {

  @Input()
  public updateDropDown: Function;
    @Input()
  public removeSubSection: Function;
  @Input()
  public untitleToggle: Function;
  @Input() selectedIndex: string;
@Input() selectedChildIndex: string;
  @Input() selectedLevel: any;
  @Input() radioIndex: any;
@Input() checked: any;

  constructor() { }
  dynamicArray: any[] = [];
  newDynamic: any = {};
  ngOnInit() {
    this.newDynamic = "option 1";
    this.dynamicArray.push(this.newDynamic);
    this.updateDropDown(this.dynamicArray, this.selectedIndex, this.selectedLevel,this.radioIndex,this.selectedChildIndex)

  }
  customTrackBy(index: number, obj: any): any {
    return index;
  }
  

  addRow() {
    this.newDynamic =  "option "+(this.dynamicArray.length + 1);
    this.dynamicArray.push(this.newDynamic);
    this.updateDropDown(this.dynamicArray, this.selectedIndex, this.selectedLevel,this.radioIndex,this.selectedChildIndex)
    return true;
  }

  deleteRow(index) {
    if (this.dynamicArray.length == 1) {
      return false;
    } else {
      this.dynamicArray.splice(index, 1);
      return true;
    }
  }

  actionType(val: any) {
    //console.log(val);
  }

  modelChange(event, index){
    this.updateDropDown(this.dynamicArray, this.selectedIndex, this.selectedLevel,this.radioIndex,this.selectedChildIndex)

  }
  trigerRemoveSubSection(){
    //console.log(this.selectedIndex)
    this.removeSubSection(this.selectedIndex,this.selectedChildIndex, this.selectedLevel,this.radioIndex)
  }
  triggerToggle(event){
    //console.log(this.selectedIndex)
    this.untitleToggle(this.selectedIndex,event, this.selectedLevel,this.selectedChildIndex, this.radioIndex)
  }
}
export class DynamicGrid {
  inputOne: any;
} 