<div style="margin-left: 5%;margin-right: 2%;">
  <div class="row">
    <div class="col-md-12 col-sm-7 col-xs-12">
      <h2 class="d-head"> {{profileMode}}</h2>
      <h4 *ngIf="showHidePassingMessage" [style.color]="_color" style="color: green;font-weight: bold;font-size: 20px;">
        {{passingMessage}}</h4>
    </div>
  </div>
  <form [formGroup]="createdForm">
    <div class="row pdt">
		<div class="col-md-12 col-sm-12 col-xs-12"
        *ngIf="this.viewManagementTeamData && this.viewManagementTeamData.upload">
		<span class="dnldbtn">
			<button mat-button (click)="download_file()" >DOWNLOAD EXCEL TEMPlATE
			&nbsp;&nbsp;  <i class="fa fa-file-excel" aria-hidden="true"></i>
			</button>
			</span>
		</div>
      <div class="col-md-12 col-sm-12 col-xs-12"
        *ngIf="this.viewManagementTeamData && this.viewManagementTeamData.upload">
        <div class="upload-btn-wrapper">
          <button class="btnbrowse">BROWSE</button>
          <input type="file" #fileUpload class="filecs" formControlName="file" (change)="changeFile($event)" />
        </div>
        <span>
          <button *ngIf="showHideSubmitUpload" mat-button class="upload_btn uploadSave"
            (click)="saveButton(createdForm)">SUBMIT </button>
        </span>
        <span><button mat-button class="upload_btn uploadSave" style="margin-left: 92px;"
            (click)="closeDialog()">CANCEL</button></span>
        <div>
          <span style="color: #bababa;">{{currentFileobj.name}}</span>
        </div>
      </div>
      <div *ngIf="this.viewManagementTeamData && !this.viewManagementTeamData.upload"
        class="col-md-12 col-sm-12 col-xs-12">
        <mat-form-field class="wdh">
          <input matInput placeholder="Name*" formControlName="name" (keypress)="omit_special_char($event)">
        </mat-form-field>
      </div>

      <div *ngIf="this.viewManagementTeamData && !this.viewManagementTeamData.upload"
        class="col-md-12 col-sm-12 col-xs-12">
        <mat-form-field class="wdh">
          <input matInput placeholder="Email*" formControlName="email" (change)="validateEmail();">
        </mat-form-field>
      </div>



      <div *ngIf="this.viewManagementTeamData && !this.viewManagementTeamData.upload"
        class="col-md-12 col-sm-12 col-xs-12">
        <table style="width: 100%;">
          <tr>
            <td style="width: 40%;max-width: 120px;">
              <mat-form-field class="wdh" style="width: 85% !important;">
                <mat-select placeholder="Country Code" formControlName="countryCode">
                  <mat-option value="+91">+91</mat-option>
                  <mat-option value="+1">+1</mat-option>
                  <mat-option value="+966">+966</mat-option>
                  <mat-option value="+971">+971</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="wdh">
                <input matInput placeholder="Enter 10 Digit Mobile No*" maxlength="10" (change)="validateMobilePhone();"
                  formControlName="mobileNo" (keypress)="onlyNumberValidation($event)" maxlength="10">
                <!-- <small>Enter 10 digit mobile number only.</small> -->
              </mat-form-field>
            </td>
          </tr>
        </table>


      </div>

      <div *ngIf="this.viewManagementTeamData && !this.viewManagementTeamData.upload"
        class="col-md-12 col-sm-12 col-xs-12">
        <table style="width: 100%;">
          <tr>
            <td style="width: 40%;max-width: 120px;">
              <mat-form-field class="wdh" style="width: 85% !important;">
                <mat-select placeholder="Profile" formControlName="jobProfile">
                  <mat-option value="Sales Representative">Sales Representative</mat-option>
                  <mat-option value="Service Executive">Service Executive</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field style="width: 100%;" [ngClass]="'inputClr'">
                <mat-select placeholder="Select Region"  formControlName="address2" multiple>
                  <mat-option  value='{{d.key}}' *ngFor="let d of region[createdForm.controls.countryCode.value]">{{d.name}}
                  </mat-option>
                </mat-select>
                <!-- <mat-select placeholder="Select Auditors" multiple>
                         <mat-option value="Auditor1">Auditor 1</mat-option>
                         <mat-option value="Auditor2">Auditor 2</mat-option>
                         <mat-option value="Auditor3">Auditor 3</mat-option>
                         <mat-option value="Auditor4">Auditor 4</mat-option>
                       </mat-select> -->
              </mat-form-field>
              <!-- <mat-form-field  class="wdh" style="width: 85% !important;">
                        <mat-select placeholder="Select region" multiple  formControlName="agents">
                            <mat-option value='{{d.id}}' *ngFor="let d of agentsDDL">{{d.email}}</mat-option>
                          </mat-select>
                    </mat-form-field> -->
            </td>
          </tr>
        </table>


      </div>
    </div>

    <div class="row pdt" *ngIf="this.viewManagementTeamData && !this.viewManagementTeamData.upload">

      <div class="col-md-12 col-sm-12 col-xs-12">
        <table style="width: 100%;">
          <tr>
            <td style="width: 40%;max-width: 120px;" *ngIf="showHideProfileStatus">
              <mat-form-field class="wdh" style="width: 85% !important;">
                <!-- <input matInput placeholder="Role" formControlName="role"  [readonly]="!is_edit"> -->
                <mat-select placeholder="Role" formControlName="role">
                  <mat-option [value]="a.role" *ngFor="let a of _role">{{a.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="wdh" style="width: 100%;">
                <mat-select placeholder="Secondary Executive" formControlName="secExec">
                  <mat-option value="">None</mat-option>
                  <mat-option [value]="ag.id" *ngFor="let ag of secondaryAgents">{{ag.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
        </table>

      </div>


      <div class="col-md-12 col-sm-12 col-xs-12">
        <mat-form-field class="wdh">
          <input matInput *ngIf="showHideAdd" placeholder="Report to" [(ngModel)]="meName" formControlName="reportTo"
            readonly>

          <mat-select *ngIf="!showHideAdd" placeholder="Report to" [(ngModel)]="_userid" formControlName="reportTo">
            <mat-option [value]="a.id" *ngFor="let a of dropDownReportTo">{{a.name}}</mat-option>
          </mat-select>



        </mat-form-field>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="showHideProfileStatus">
        <mat-form-field class="wdh">
          <mat-select placeholder="Profile Status" formControlName="profileStatus">
            <mat-option [value]="v" *ngFor="let v of _profileStatus">{{v}}</mat-option>

          </mat-select>
        </mat-form-field>
      </div>
	  <!-- <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="showHideProfileStatus">
        <mat-form-field class="wdh">
          <mat-select placeholder="work_option" formControlName="work_option">
            <mat-option [value]="v" *ngFor="let v of work_option">{{v}}</mat-option>

          </mat-select>
        </mat-form-field>
      </div>-->
    </div> 
    <div class="row">
	<div class="col-md-12 col-sm-12 col-xs-12" *ngIf="showHideProfileStatus">
        <mat-form-field class="wdh">
          <mat-select placeholder="Work Option" formControlName="work_option">
            <mat-option [value]="v" *ngFor="let v of work_option">{{v}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
</div>
    <div class="row pdt" *ngIf="this.viewManagementTeamData && !this.viewManagementTeamData.upload">
      <div class="col-md-12 col-sm-12 col-xs-12 rightside">
        <button mat-button class="action_btn" (click)="closeDialog()">CANCEL</button>&nbsp;&nbsp;&nbsp;
        <span *ngIf="!showHideProfileStatus">
          <button mat-button class="action_btn"
            (click)="clearField()">CLEAR</button>&nbsp;&nbsp;&nbsp;
        </span>
        <span *ngIf="showHideProfileStatus">
          <button mat-button class="action_btn" (click)="deleteUserFromGroup()">DELETE</button>&nbsp;&nbsp;&nbsp;
        </span>
        <button mat-button (click)="saveButton(createdForm)" >SAVE</button>
        <!-- #3fb91f -->
      </div>

    </div>

  </form>
</div>
<div id="cover-spin" *ngIf="currentLoader"></div>
