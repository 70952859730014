import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
	FormGroup,
	FormControl,
	FormBuilder,
	Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MyserviceService } from '../myservice.service';
import { Utils } from '../utils';
//import * as moment from 'moment';
import  * as moment from 'moment-timezone';
import { Moment } from 'moment';

import { ApiService } from '../api.service';
import {MatSnackBar} from '@angular/material/snack-bar';



@Component({
	selector: 'app-pmdashboard-dailog',
	templateUrl: './pmdashboard-dailog.component.html',
	styleUrls: ['./pmdashboard-dailog.component.scss'],
})
export class PmdashboardDailogComponent implements OnInit {
	slotForm: FormGroup;
	slotEditForm: FormGroup;
	profileMode: any;
	settings: any = '';
	workHours: any = '';

	slotConfigWeek: any = [
	];
	slotConfigWeekDup: any = [];
	master_work_timings: any = [
		{
			name: 'Start Time',
			short_name: 'starttime',
			utc_time: '',
			ist_time: '',
			stage: '8',
			hrs: '',
			mins: '',
		},
		{
			name: 'End Time',
			short_name: 'endtime',
			utc_time: '',
			ist_time: '',
			stage: '9',
			hrs: '',
			mins: '',
		},
	];
	displayedColumns = [
		'sno',
		'date',
		'day',
		'maxAgents',
		'startTime',
		'endTime',
	];
	utils = new Utils();

	dataSource: any = new MatTableDataSource();
	todayDate = new Date();
	final_slot_reg_arr: any = '';
	slot_date_difference: any = '';
	slots_per_day: number = 0;
	work_hours_day: number = 0;
	work_minutes_day: number = 0;
	work_timings: any = '';
	showSlotPreview: boolean = false;
	showEditTimings: boolean = false;
	dialogTitle: string = 'Configuring Slots';
	slot_reg_start: any = '';
	slot_reg_end: any = '';
	reg_date_value: any = '';
	slot_split_duration: any = '';
	max_agents_perslot: any = '';
	select24x7: boolean = false;
	hoursStartTimeHr: number[] = [];
	hoursStartTimeMin: number[] = [];
	hoursEndTimeHr: number[] = [];
	hoursEndTimeMin: number[] = [];
	master_work_timings_dup: any = [
		{
			name: 'Start Time',
			short_name: 'starttime',
			utc_time: '',
			ist_time: '',
			stage: '8',
			hrs: '09',
			mins: '00',
		},
		{
			name: 'End Time',
			short_name: 'endtime',
			utc_time: '',
			ist_time: '',
			stage: '9',
			hrs: '18',
			mins: '00',
		},
	];
	showEditSlot = false;
	editedData: any = {}
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	messageAlert: any = "";
	showHide_messageAlert: boolean = false;
	showHideSlotChanged:boolean = false;
	api_end_point:any;
	constructor(
		public dialogRef: MatDialogRef<PmdashboardDailogComponent>,
		@Inject(MAT_DIALOG_DATA) public viewSlotData,
		public formbuilder: FormBuilder,
		private myService: MyserviceService,
		private API: ApiService,
		private _snackBar: MatSnackBar
	) {
		this.slotForm = this.formbuilder.group({
			maxAgents: ['', [Validators.required, Validators.pattern("[0-9 ]{1,10}")]],
			slotDuration: ['', [Validators.required, Validators.pattern("[0-9 ]{1,10}"), , Validators.maxLength(2)]],
			slotCreateStartDate: ['', Validators.required],
			slotCreateEndDate: ['', Validators.required],
		});
		// this.getSlotPreview();
	}

	ngOnInit() {
		this.api_end_point = this.myService.get_API_End_Point();
		if (this.viewSlotData) {
			this.dialogTitle = 'Edit slot';
			this.showEditSlot = true;
		//	console.log(this.viewSlotData)
			this.slotEditForm = this.formbuilder.group({
				maxAgents: [this.viewSlotData.capacity],
			});
		} else {
			this.dialogTitle = 'Configuring Slots';
			this.prepareSlotConfig();
			//edit timings config
			for (var i: number = 0; i < 24; i++) {
				this.hoursStartTimeHr.push(i);
			}
			for (var i: number = 0; i < 60; i++) {
				this.hoursStartTimeMin.push(i);
			}
			this.getCustomCount(this.master_work_timings[0], 'hrs');
			this.getCustomCount(this.master_work_timings[0], 'mins');
			this.onFormChanges();
		}
		// this.getSlotPreview();

	}

	prepareSlotConfig() {
		this.settings = this.myService.get_stage_settings();
		this.workHours = this.settings.workhours;
		this.slotConfigWeek = []
		this.slotConfigWeekDup = []
		var start_time, end_time;
	//	console.log(this.workHours)
		this.workHours.forEach((day, index) => {
			if (day['name'] == 'starttime') {
				if(day['definition'] != 'Invalid date'){
					start_time = moment.utc(day['definition'], 'HH:mm').tz("Asia/Kolkata");
				}else{
					var	timeStr = '09:00';
					start_time = moment(timeStr, 'HH:mm');
				}
				this.master_work_timings[0]['stage'] = day['stage'];
				this.master_work_timings[0]['utc_time'] = day['definition'];
				this.master_work_timings[0]['ist_time'] = start_time.format('HH:mm');
				this.master_work_timings[0]['hrs'] = parseInt(start_time.format('HH'));
				this.master_work_timings[0]['mins'] = parseInt(start_time.format('mm'));
				this.master_work_timings_dup[0]['stage'] = day['stage'];
				this.master_work_timings_dup[0]['utc_time'] = day['definition'];
				this.master_work_timings_dup[0]['ist_time'] = start_time.format(
					'HH:mm'
				);
				this.master_work_timings_dup[0]['hrs'] = parseInt(
					start_time.format('HH')
				);
				this.master_work_timings_dup[0]['mins'] = parseInt(
					start_time.format('mm')
				);
			} 
			else if (day['name'] == 'endtime') {
				if(day['definition'] != 'Invalid date'){
					end_time = moment.utc(day['definition'], 'HH:mm').tz("Asia/Kolkata");
				}else{
					var	timeStr1 = '18:00';
					end_time = moment(timeStr1,'HH:mm');
				}
				this.master_work_timings[1]['stage'] = day['stage'];
				this.master_work_timings[1]['utc_time'] = day['definition'];
				this.master_work_timings[1]['ist_time'] = end_time.format('HH:mm');
				this.master_work_timings[1]['hrs'] = parseInt(end_time.format('HH'));
				this.master_work_timings[1]['mins'] = parseInt(end_time.format('mm'));
				this.master_work_timings_dup[1]['stage'] = day['stage'];
				this.master_work_timings_dup[1]['utc_time'] = day['definition'];
				this.master_work_timings_dup[1]['ist_time'] = end_time.format('HH:mm');
				this.master_work_timings_dup[1]['hrs'] = parseInt(
					end_time.format('HH')
				);
				this.master_work_timings_dup[1]['mins'] = parseInt(
					end_time.format('mm')
				);
			} else {
				var obj = {
					label: day.name,
					isSelected: false,
					value: day['definition'],
					definition: day['definition'],
					stage: day['stage'],
				};
				if (day['definition'] == 'on') {
					obj.isSelected = true;
				}
			//	console.log("krunal")
				//console.log(obj)
				this.slotConfigWeek.push(obj);
				this.slotConfigWeekDup.push(obj);
			}
		});
		this.work_timings = [start_time.format('HH:mm'), end_time.format('HH:mm')];
		
		var temp_start = moment(this.work_timings[0], 'HH:mm');
		var temp_end = moment(this.work_timings[1], 'HH:mm');
		var work_minutes = temp_end.diff(temp_start, 'm');
	//	console.log('printing work minutes', temp_start, temp_end, work_minutes);
		//debugger;
		if (work_minutes) {
			this.work_hours_day = Math.floor(work_minutes / 60);
			this.work_minutes_day = work_minutes;
		}
		//console.log(this.slotConfigWeek)
	}

	getSlotPreview() {
		let ELEMENT_DATA = [];

		(this.final_slot_reg_arr || []).forEach((day, index) => {
			const obj = {
				sno: index + 1,
				date: day.date_with_format,
				day: day.day_name,
				maxAgents: day.max_agents,
				startTime: day.start_date_hm,
				endTime: day.end_date_hm,
			};
			ELEMENT_DATA.push(obj);
		});

		this.dataSource = new MatTableDataSource(ELEMENT_DATA);
		this.dataSource.paginator = this.paginator;
	}

	slot_object_perday(slot_start_date) {
		var s = 0;
		var moment_day = slot_start_date.clone();
		const slot_duration_mins = this.slotForm.value.slotDuration * 60;
		s = Math.floor(this.work_minutes_day / slot_duration_mins);
		if ((this.work_minutes_day % slot_duration_mins) > 0) {
			s++;
		}
		this.slots_per_day = s;
		// debugger;
		this.slot_split_duration = this.slotForm.value.slotDuration;
		this.max_agents_perslot = this.slotForm.value.maxAgents;
		var start_time = this.work_timings[0];
		var end_time = this.work_timings[1];
		var slot_start = moment_day.clone().format('YYYY-MM-DD') + ' ' + start_time;
		var temp_date = slot_start;
		var temp_date_obj = moment(slot_start, 'YYYY-MM-DD HH:mm');
		var end_time_obj: any = moment_day.clone().format('YYYY-MM-DD') + ' ' + end_time;
		end_time_obj = moment(end_time_obj, 'YYYY-MM-DD HH:mm');
		var arr = [];
		for (var i = 0; i < s; i++) {
			var differnce_time = end_time_obj.diff(temp_date_obj, 'm');
			// debugger;
			if (differnce_time > slot_duration_mins) {
				temp_date_obj = moment(slot_start, 'YYYY-MM-DD HH:mm').add(
					this.slotForm.value.slotDuration,
					'h'
				);
				temp_date = temp_date_obj.format('YYYY-MM-DD HH:mm');
				var obj2 = {
					start_time: slot_start,
					end_time: temp_date,
					start_time_hm: moment(slot_start, 'YYYY-MM-DD HH:mm').format('HH:mm'),
					end_time_hm: moment(temp_date, 'YYYY-MM-DD HH:mm').format('HH:mm'),
				};
				slot_start = temp_date;
				// console.log('printing slot obj', obj2);
				arr.push(obj2);
			} else {
				var obj1 = {
					start_time: slot_start,
					end_time: end_time_obj.format('YYYY-MM-DD HH:mm'),
					start_time_hm: moment(slot_start, 'YYYY-MM-DD HH:mm').format(
						'HH:mm'
					),
					end_time_hm: moment(end_time_obj, 'YYYY-MM-DD HH:mm').format(
						'HH:mm'
					),
				};
				arr.push(obj1);
			}
		}
		return arr;
	}

	time_splitter() {
		//console.log(this.slotForm);
		if (this.slotForm.invalid) {
			return;
		}
		if (this.slotForm.value.slotCreateStartDate == "" || this.slotForm.value.slotCreateEndDate == "") {
			this._snackBar.open('Select date', 'close');
			//alert('Select date');
			return;
		}
		if (this.slotForm.value.maxAgent == "" || this.slotForm.value.slotDuration == "") {
			//alert('All fields are mandatory');
			this._snackBar.open('All fields are mandatory', 'close');
			return;
		}
		var start_date = moment(this.slotForm.value.slotCreateStartDate);
		var end_date = moment(this.slotForm.value.slotCreateEndDate);
		this.slot_reg_start = start_date;
		this.slot_reg_end = end_date;
		this.reg_date_value =
			start_date.format('YYYY-MM-DD') + ' - ' + end_date.format('YYYY-MM-DD');
		var temp_date = start_date;
		var date_difference = end_date.diff(start_date, 'd');
		date_difference += 1;
		this.slot_date_difference = date_difference;
		var day_name = moment(temp_date).format('ddd');
		var arr = [];
		if (date_difference) {
			for (var i = 0; i < date_difference; i++) {
				day_name = moment(temp_date).format('ddd');
				const dayObj = this.slotConfigWeek.find(
					(e) => e.label == day_name.toUpperCase()
				);
				if (dayObj) {
					//console.log('Going inside the loop',day_name);
					var slot_data = this.slot_object_perday(start_date);
					//console.log('After slot data receive',slot_data);
					for (var k in slot_data) {
						var obj: any = {};
						obj.date = start_date;
						obj.day_name = day_name;
						obj.date_with_format = start_date.format('YYYY-MM-DD');
						obj.start_date_ist = slot_data[k].start_time;
						obj.end_date_ist = slot_data[k].end_time;
						obj.start_date_hm = slot_data[k]['start_time_hm'];
						obj.end_date_hm = slot_data[k]['end_time_hm'];
						obj.start_date_utc = moment(
							obj.start_date_ist,
							'YYYY-MM-DD hh:mm A'
						)
							.subtract({ hours: 5, minutes: 30 })
							.format('YYYY-MM-DDTHH:mm:ss');
						obj.end_date_utc = moment(obj.end_date_ist, 'YYYY-MM-DD hh:mm A')
							.subtract({ hours: 5, minutes: 30 })
							.format('YYYY-MM-DDTHH:mm:ss');
						obj.max_agents = this.slotForm.value.maxAgents;
						//console.log('printing date obj',obj)
						arr.push(obj);
					}
				} else {
					console.log('Date is not in selected days range', day_name);
				}
				temp_date = start_date.add(1, 'd');
				start_date = temp_date;
				//console.log('Changed date', start_date);
				//console.log(arr);
				this.final_slot_reg_arr = arr;
				this.showSlotPreview = true;
				this.dialogTitle = 'Slot Schedule Preview';
				this.getSlotPreview();
			}
		}

	}

	createSlot() {
		const postData: any = {
			bulk_slot: this.final_slot_reg_arr
		}
		this.API.bulkSlotRegister(postData,this.api_end_point).subscribe((data: any) => {

			if (data.errors) {
				this._snackBar.open(data.errors, 'close');
				//alert(data.errors);
			} else {
				this._snackBar.open("Slot created successfully", 'close');
				//alert("Slot created successfully");
				this.dialogRef.close({ refresh: true });
			}
		});
	}

	changeMaxAgents(event, index) {
		//console.log(event.target.value);
		//console.log(index);
		//console.log(this.final_slot_reg_arr[index - 1]);
		this.final_slot_reg_arr[index - 1].max_agents = event.target.value;
	}
	switchEditTimings() {
		this.showSlotPreview = false;
		this.showEditTimings = true;
	}
	closeDialog() {
		this.select24x7 = false;
		this.dialogRef.close({});
	}
	toggle24x7(event) {
		//console.log(event.checked);
		//console.log(this.slotConfigWeek);
		this.select24x7 = event.checked;
		var toggle_status = this.select24x7;
		if (toggle_status) {
			var start_time = moment().startOf('d');
			var start_time_utc = moment()
				.startOf('d')
				.subtract({ hours: 5, minutes: 30 });
			var end_time = moment().endOf('d');
			var end_time_utc = moment()
				.endOf('d')
				.subtract({ hours: 5, minutes: 30 });
			//console.log('times ',start_time,end_time,start_time_utc,end_time_utc)
			this.master_work_timings_dup = [
				{
					name: 'Start Time',
					short_name: 'starttime',
					utc_time: start_time_utc.format('HH:mm'),
					ist_time: start_time.format('HH:mm'),
					hrs: parseInt(start_time.format('HH')),
					mins: parseInt(start_time.format('mm')),
					stage: 8,
				},
				{
					name: 'End Time',
					short_name: 'endtime',
					utc_time: end_time_utc.format('HH:mm'),
					ist_time: end_time.format('HH:mm'),
					hrs: parseInt(end_time.format('HH')),
					mins: parseInt(end_time.format('mm')),
					stage: 9,
				},
			];
			this.slotConfigWeek.forEach((itm, index) => {
				itm.isSelected = toggle_status;
			});
			//console.log("Hii")
		} else {
			this.master_work_timings_dup = this.master_work_timings;
			this.slotConfigWeek = this.slotConfigWeekDup;
			//console.log("hii2")
		}
		//console.log(this.slotConfigWeek);
	}
	getCustomCount(start_arr, type) {
		//console.log(start_arr);
		var arr = [];
		var start_in_mins = 0;
		start_in_mins += parseInt(start_arr['hrs']) * 60;
		start_in_mins += parseInt(start_arr['mins']);
		//console.log('after start mins',start_in_mins)
		var end_in_mins = start_in_mins + 1;
		var end_hrs = Math.floor(end_in_mins / 60);
		var end_mins = end_in_mins % 60;
		//console.log('printing end hours and mins',end_in_mins,end_hrs,end_mins);
		switch (type) {
			case 'hrs':
				for (var i = end_hrs; i < 24; i++) {
					arr.push(i);
				}
				this.hoursEndTimeHr = arr;
				break;
			case 'mins':
				//console.log(this.master_work_timings_dup[1]['hrs']);
				//console.log(end_hrs);
				if (parseInt(this.master_work_timings_dup[1]['hrs']) > end_hrs) {
					for (var i = 0; i < 60; i++) {
						arr.push(i);
					}
				} else {
					for (var i = end_mins; i < 60; i++) {
						arr.push(i);
					}
				}
				this.hoursEndTimeMin = arr;
				break;
		}

		return arr;
	}
	setStartTimeSetting(event, type) {
	//	console.log(event, type);
		if (type == 'hrs') {
			this.master_work_timings_dup[0]['hrs'] = event.value;
			this.getCustomCount(this.master_work_timings_dup[0], 'hrs');
		}
		if (type == 'mins') {
			this.master_work_timings_dup[0]['mins'] = event.value;
			this.getCustomCount(this.master_work_timings_dup[0], 'mins');
		}
	}

	setEndTimeSetting(event, type) {
		//console.log(event, type);
		//console.log(this.master_work_timings);
		if (type == 'hrs') {
			this.master_work_timings_dup[1]['hrs'] = event.value;
			this.getCustomCount(this.master_work_timings[0], 'mins');
		}
		if (type == 'mins') {
			this.master_work_timings_dup[1]['mins'] = event.value;
		}
	}

	saveCustomStages() {
		this.showHideSlotChanged = false;
		var obj: any = {
			type: 'workhours',
			option: 'type_resetall',
			values: [],
		};
		if (this.master_work_timings_dup[0]) {
			var loc_obj = {
				name: 'starttime',
				definition: '',
				stage: this.master_work_timings_dup[0]['stage'],
			};
			var s =
				this.master_work_timings_dup[0]['hrs'] +
				':' +
				this.master_work_timings_dup[0]['mins'];
			loc_obj.definition = moment(s, 'HH:mm')
				.subtract({ hours: 5, minutes: 30 })
				.format('HH:mm');
			obj.values.push(loc_obj);
		}
		if (this.master_work_timings_dup[1]) {
			var loc_obj = {
				name: 'endtime',
				definition: '',
				stage: this.master_work_timings_dup[1]['stage'],
			};
			var s =
				this.master_work_timings_dup[1]['hrs'] +
				':' +
				this.master_work_timings_dup[1]['mins'];
			loc_obj.definition = moment(s, 'HH:mm')
				.subtract({ hours: 5, minutes: 30 })
				.format('HH:mm');
			obj.values.push(loc_obj);
		}
		//console.log(obj);
		//console.log(this.slotConfigWeek);
		for (var i in this.slotConfigWeek) {

			var loc_obj_2: any = {};
			loc_obj_2.name = this.slotConfigWeek[i]['label'];
			loc_obj_2.definition = 'off';
			loc_obj_2.stage = this.slotConfigWeek[i]['stage'];
			if (this.slotConfigWeek[i]['isSelected']) {
				loc_obj_2.definition = 'on';
			}
			obj.values.push(loc_obj_2);

		}
		//console.log(obj);
		this.API.updateCustomStages(obj,this.api_end_point).subscribe((data: any) => {
			if (data.errors) {
				this._snackBar.open(data.errors, 'close');
				//alert(data.errors);
			} else {
				//alert('Slot config changed');
				this.showHideSlotChanged = true;
				setTimeout(()=>{
					this.showHideSlotChanged = false;
				},6000)
				setTimeout(()=>{
				this.showEditTimings = false;
				this.select24x7 = false;
				this.myService.update_stage_settings_work_hours(data.values);
				this.prepareSlotConfig();
			},1000)
			}
		});
	}
	dayTimingChange(obj, event, index) {
	//	console.log(obj, event);
		//console.log(this.workHours);
		this.slotConfigWeek[index].isSelected = event.checked;
		this.slotConfigWeek[index].definition = event.checked ? 'on' : 'off';
		this.workHours[index].definition = event.checked ? 'on' : 'off';
	}

	editSlot() {
		var obj: any = {
			new_max_limit: this.slotEditForm.value.maxAgents,
			slot_id: this.viewSlotData.id
		}
		this.API.editSlot(obj,this.api_end_point).subscribe((data: any) => {
			if (data.errors) {
				this._snackBar.open(data.errors, 'close');
				//alert(data.errors);
			} else {
				this._snackBar.open("Slot updated successfully... ", 'close');
				//alert("Slot updated successfully... ");
				this.dialogRef.close({ refresh: true });
			}
		});
	}

	onFormChanges(): void {
		this.slotForm.valueChanges.subscribe(val => {

			if (val.slotCreateStartDate && val.slotCreateEndDate) {
				const _startBatch = moment(val.slotCreateStartDate);
				const _endBatch = moment(val.slotCreateEndDate);
				//console.log(moment(val.slotCreateStartDate).diff(moment(val.slotCreateEndDate)))
				if (_startBatch.diff(_endBatch) > 0) {
					//  alert("Start date should be less than end date")
					this.messageAlert = "Start date should be less than end date *";
					this.showHide_messageAlert = true;
					this.slotForm.controls['slotCreateEndDate'].reset()
				}
				else {
					this.showHide_messageAlert = false;
				}
			}


		});
	}
	validateNegative(event: any) {
		if (event.key == "-" || event.key == ".") {
			event.preventDefault();
			return false;
		}
	}
	maxInput() {
		let _max = this.slotForm.controls['slotDuration'].value;
		if (_max > 24) {
			this.slotForm.controls['slotDuration'].patchValue(24);
			return false;
		}
		else if (_max == "") {
			this.slotForm.controls['slotDuration'].patchValue(1);
		}
		else if (_max == 0) {
			this.slotForm.controls['slotDuration'].patchValue(1);
		}
	}
}
