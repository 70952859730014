import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { PmdashboardDailogComponent } from '../pmdashboard-dailog/pmdashboard-dailog.component';
import { ServicesService } from '../dashboard/services.service';
import { MyserviceService } from '../myservice.service';
import { Utils } from '../utils';
import * as moment from 'moment';
import { Chart } from 'angular-highcharts';
import { MatInput} from '@angular/material/input';
import { MatSort } from '@angular/material/sort';

interface Alert {
	type: string;
	message: string;
}

@Component({
	selector: 'app-pmdash',
	templateUrl: './pmdash.component.html',
	styleUrls: ['./pmdash.component.scss'],
})
export class PmdashComponent implements OnInit {
	messageAlert:any="";
	showHide_messageAlert:boolean=false;

	currentTitle = 'pmdash';
	maxDate = new Date();
	currentLoader = false;
	selectedTabSts = 'call_status';
	alert: Alert = { type: 'success', message: "Refreshed successfully..." };
	alertClosed = true;
	slotStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	settings: any = ""
	workHours: any = ""
	dashData: any[] = [];
	selectAll = true;
	color_arr: any = {};
	followupCnt: number = 0;
	followupCntDone: number = 0;
	displayedColumnsCallSummary = [
		'type',
		'time',
		'spoken_time',
		'wrap_time',
		'wait_time',
		'idle_time',
	];

	displayedColumnsCallStatus = [
		'user_id',
		'name',
		'status',
		'dialled',
		'unique_dialled',
		'contacted',
		'unique_contacted',
		'rnr',
		'forms',
		'active_time',
		'idle_time',
		'wait_time',
		'wrap_time',
		'audit_score',
		'ctc_score',
	];
	displayedColumnsCallSlot = [
		'date',
		'time',
		"capacity",
		'booked',
		'waiting',
		'occupancy',
		'status',
		'options',
		'id'
	];
	_summary = function () {
		return {
			'data': {
				'total': 0,
				'total_perc': 0,
				'total_calls': 0,
				'total_calls_perc': 0,
				'data_to_call': 0,
				'data_to_call_perc': 0,
				'available': 0,
				'followup': 0
			},
			'dialled': {
				'total': 0,
				'total_perc': 0,
				'unique': 0,
				'unique_perc': 0,
			},
			'contacted': {
				'total': 0,
				'total_perc': 0,
				'unique': 0,
				'unique_perc': 0
			},
			'forms': {
				'total': 0
			}
		} as any
	}
	_tableDataCallStatus = function () {
		return {
			'user_id': '',
			'name': '',
			'status': '',
			'dialled': 0,
			'unique_dialled': 0,
			'contacted': 0,
			'unique_contacted': 0,
			'rnr': 0,
			'forms': 0,
			'audit_score': 0,
			'ctc_score': 0
		}
	}
	_tableDataCallSlot = function () {
		return {
			'date': "",
			'time': "",
			"capacity": 0,
			'booked': 0,
			'waiting': 0,
			'occupancy': 0,
			'status': "",
			'options': "",
			'id': ""
		}
	}

	agentCallingStatus: any = {
		calling: 0,
		on_break: 0,
		not_submit: 0,
		wait_l_10: 0,
		wait_g_10: 0,
		log_out: 0,
		remaining: 0
	}

	agentStatusChart: any;
	agentStatusChartData: any = {
		calling: 0,
		calling_perc: "0",
		notclick: 0,
		notclick_perc: 0,
		waitinglt10: 0,
		waitinglt10_perc: 0,
		waitinggt10: 0,
		waitinggt10_perc: 0,
		notcalling: 0,
		notcalling_perc: 0,
		loggedout: 0,
		loggedout_perc: 0,
	};
	dashSummary: any = {
		followup: 0,
		followupCntDone: 0,
		followupdone:0,
		followup_perc: 0,
		followupdone_perc: 0,
		followuptodo:0,
		followuptodo_perc:0,
		forms_filled: 0,
		forms_filled_perc: 0,
		forms_not_filled: 0,
		forms_not_filled_perc: 0,
		calls_dialled: 0,
		calls_dialled_perc:0,
		calls_rnr: 0,
		calls_rnr_perc:0,
		calls_contacted: 0,
		calls_contacted_perc:0
	};
	callSummaryData: any[] = [{
		'type': 'Total',
		'time': "00:00:00",
		'spoken_time': "00:00:00",
		'wrap_time': '00:00:00',
		'wait_time': '00:00:00',
		'idle_time': '00:00:00'
	},
	{
		'type': 'Average of 0 Agents',
		'time': "00:00:00",
		'spoken_time': "00:00:00",
		'wrap_time': '00:00:00',
		'wait_time': '00:00:00',
		'idle_time': '00:00:00'
	}]

	selectedAgents: any[] = []
	selectedAgentsLen: number = 0;
	myUser: any;
	summary = this._summary();
	dataSource: any = new MatTableDataSource();
	dataSourceCallSummary: any = new MatTableDataSource();
	api_end_point:any;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort) tableSort: MatSort;

	@ViewChild(' fromInput', {
		read: MatInput
	  })  fromInput: MatInput;
	  
	constructor(private dashService: ServicesService, private myService: MyserviceService, private dialog: MatDialog, public router: Router, private API: ApiService, private utils: Utils) { }


	ngOnInit() {
		//console.log(this.callStartDate, this.callEndDate)
		// this.getCallStatus();
		this.preparePieChart();
		this.api_end_point = this.myService.get_API_End_Point();
		this.settings = this.myService.get_stage_settings();
		this.workHours = this.settings.workhours;


		 

		this.prepareCallSummary();
		this.myUser = this.myService.get_loggedin_user();
		this.refreshData();
	}

	prepareCallSummary() {
		this.dataSourceCallSummary = new MatTableDataSource(this.callSummaryData);

	}

	refreshDataCallSlot() {


		this.currentLoader = true;
		let obj: any = {};
		obj.start_date = this.utils.localToUTC(this.slotStartDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.slotEndDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')


		this.dashService.getDatewiseSlots(obj,this.api_end_point).then((resolve) => {
			//console.log('API Called successfully');
			this.currentLoader = false;
			this.prepareSlotTableData();
		}, (err) => {
			// alert(err);
			this.currentLoader = false;
			this.alert.type = "danger";
			this.alert.message = err;
			this.alertClosed = false;
			setTimeout(() => this.alertClosed = true, 3000);
			this.dataSource = new MatTableDataSource([]);
			this.dataSource.paginator = this.paginator;
		})

	}



	refreshData() {
    if(!this.callStartDate || !this.callEndDate){
      return;
    }
	//	console.log('Table data refreshing');
		this.currentLoader = true;
		let objReq: any = {};
		var not_submit: number = 0, calling: number = 0, wait_l_10: number = 0, wait_g_10: number = 0, on_break: number = 0, log_out: number = 0;
		var my_agents = [];
		var now = moment();
		objReq.start_date = this.utils.localToUTC(this.callStartDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		objReq.end_date = this.utils.localToUTC(this.callEndDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')

		this.API.getFullTeamData(this.api_end_point).subscribe(
			(res: any) => {
				if (res.success) {
					this.myService.set_Full_team(res.success);

					if (this.selectedAgents.length == 0) {
						for (const item of this.myService.getAllIds('user')) {
							let agent = { name: item.name, user_id: item.id, checked: true };
							this.selectedAgents.push(agent);
						}
						objReq.agents_list = this.myService.getAllIds('user', 'id');
						this.selectedAgentsLen = this.selectedAgents.length;
					} else {
						objReq.agents_list = this.selectedAgents.filter(e => e.checked === true).map(f => f.user_id);
						this.selectedAgentsLen = objReq.agents_list.length;
					}

					my_agents = objReq.agents_list;
					objReq.user_group_id = this.myUser.groupid;
					// this.followupCnt = 0;
					// this.followupCntDone = 0;
					// this.API.followupCount(objReq).subscribe(
					// 	(data: any) => {
					// 		if (data.success && data.success.length) {
					// 			if(data.success[0] && data.success[0].flps){
					// 				this.followupCnt = this.followupCnt + data.success[0].flps;
					// 				if(data.success[0].responsestatus == 'completed'){
					// 					this.followupCntDone = data.success[0].flps
					// 				}
					// 			}
								
					// 			if(data.success[1] && data.success[1].flps){
					// 				this.followupCnt = this.followupCnt + data.success[1].flps;
					// 				if(data.success[1].responsestatus == 'completed'){
					// 					this.followupCntDone = data.success[1].flps
					// 				}
					// 			}
								
					// 		}
							this.dashService.getPMDashDataOutbound(objReq,this.api_end_point).then((resolve) => {

								this.currentLoader = false;
								this.API.getAllGroupStatus(this.myUser.groupid,this.api_end_point).subscribe(
									(success: any) => {
										if (success && success.length) {
											for (var i in success) {
												var obj = success[i];
											if(my_agents.includes(obj.id)){
												let user_id = obj['id'];
												this.color_arr[user_id] = '#DEE1E6'
												if (obj.app_state && (obj.app_state.indexOf('On Call') > -1)) {
													if (obj.hasOwnProperty('Last Customer Disconnect At') && (obj['Last Customer Disconnect At'].length > 1)) {
														not_submit++;
														this.color_arr[user_id] = '#FF1493'
													} else {
														this.color_arr[user_id] = '#33EB1A'
														calling++;
													}
												}
												if (obj.app_state && (obj.app_state.indexOf('Waiting for call') > -1)) {
													try {
														var rad_start_at = obj['RAD Started At'];
														var time_diff = moment.duration(now.diff(moment(rad_start_at, 'hh:mm:ss A'))).as('minutes');
														//console.log('printing time difference',time_diff);
														if (!isNaN(time_diff)) {
															if (time_diff < 10) {
																wait_l_10++;
																this.color_arr[user_id] = '#FF8C00'
															} else {
																wait_g_10++;
																this.color_arr[user_id] = '#87CEFA'
															}
														} else {
															throw "not a number"
														}
													} catch (e) {
													//	console.log('Exception received', e);
														this.color_arr[user_id] = '#87CEFA'
														wait_g_10++;
													}
												}
												if (obj.app_state && (obj.app_state.indexOf('On Break') > -1)) {
													this.color_arr[user_id] = '#FF0000'
													on_break++;
												}
												if (obj.app_state && (obj.app_state.indexOf('Logged In') > -1)) {
													this.color_arr[user_id] = '#FF0000'
													on_break++;
												}
												if (obj.app_state && (obj.app_state.indexOf('Logged Out') > -1)) {
													this.color_arr[user_id] = '#D3BA80'
													log_out++;
												}
											}
											
										}
		
										}
										this.prepareTableData(objReq);
											this.agentCallingStatus.calling = calling;
											this.agentCallingStatus.on_break = on_break;
											this.agentCallingStatus.not_submit = not_submit;
											this.agentCallingStatus.wait_l_10 = wait_l_10;
											this.agentCallingStatus.wait_g_10 = wait_g_10;
											this.agentCallingStatus.log_out = log_out;
											this.agentCallingStatus.remaining = this.dashData.length - (
												this.check_number(this.agentCallingStatus.calling) +
												this.check_number(this.agentCallingStatus.not_submit) +
												this.check_number(this.agentCallingStatus.wait_l_10) +
												this.check_number(this.agentCallingStatus.wait_g_10) +
												this.check_number(this.agentCallingStatus.on_break) +
												this.check_number(this.agentCallingStatus.log_out)
										)
									}, (err) => {
									//	console.log(err);
										this.prepareTableData(objReq);
										this.agentCallingStatus.calling = calling;
											this.agentCallingStatus.on_break = on_break;
											this.agentCallingStatus.not_submit = not_submit;
											this.agentCallingStatus.wait_l_10 = wait_l_10;
											this.agentCallingStatus.wait_g_10 = wait_g_10;
											this.agentCallingStatus.log_out = log_out;
											this.agentCallingStatus.remaining = this.dashData.length - (
												this.check_number(this.agentCallingStatus.calling) +
												this.check_number(this.agentCallingStatus.not_submit) +
												this.check_number(this.agentCallingStatus.wait_l_10) +
												this.check_number(this.agentCallingStatus.wait_g_10) +
												this.check_number(this.agentCallingStatus.on_break) +
												this.check_number(this.agentCallingStatus.log_out)
											)
										this.currentLoader = false;
									});
							}, (err) => {
								alert(err);
								this.currentLoader = false;
							})
						
					
				} else {
					alert(res.error)
				}
			}, (err) => {
				alert('Error in the full team fetch')
			});


	}

	check_number(val) {
		if (!isNaN(val)) {
			return parseInt(val)
		} else {
			return 0;
		}
	}

	prepareSlotTableData() {
		this.currentLoader = true;
		let data = null;
		let arr = [];
		this.dashService.pm_dash_slot_data.subscribe((d) => data = d);
		let d = data['success'];

		for (var i in d) {
			let loc = d[i];
			let obj = this._tableDataCallSlot();
			obj['date'] = moment(loc['slot_start_ist'], 'YYYY-MM-DDTHH:mm:ss').format('L');
			obj['time'] = moment(loc['slot_start_ist'], 'YYYY-MM-DDTHH:mm:ss').format('HH:mm') + " - " + moment(loc['slot_end_ist'], 'YYYY-MM-DDTHH:mm:ss').format('HH:mm');
			obj['capacity'] = loc['max_limit'];
			obj['booked'] = loc['current_booked'];
			obj['waiting'] = loc['waiting'];
			obj['occupancy'] = loc['waiting'];
			obj['occupancy'] = loc['waiting'];
			obj['status'] = loc['status'];
			obj['id'] = loc['id'];
			arr.push(obj);
		}

		this.dataSource = new MatTableDataSource(arr);
		this.dataSource.paginator = this.paginator;
		this.dataSource.filterPredicate = function (data, filter: any): boolean {
			return data.date.toString().toLowerCase().includes(filter)
		};
		this.currentLoader = false;
	}

	agent_params(id, obj) {
		var dashRow = {} as any;
		dashRow.isVisible = true;
		dashRow.user_id = id;
		dashRow.name = (obj && obj.name) ? obj.name : '';
		dashRow.email = (obj && obj.name) ? obj.email : '';
		dashRow.phone = (obj && obj.phone) ? obj.phone : '';
		dashRow.role = (obj && obj.role) ? obj.role : '';
		dashRow.agentid = (obj && obj.agentid) ? obj.agentid : '';
		dashRow.active_campaign = (obj && obj.active_campaign) ? obj.active_campaign : '';
		dashRow.tpd_queues = (obj && obj.tpd_queues) ? obj.tpd_queues : '';
		dashRow.idle_time = (obj && obj.idle_time) ? obj.idle_time : 0;
		dashRow.wait_time = (obj && obj.wait_time) ? obj.wait_time : 0;
		dashRow.wrap_time = (obj && obj.wrap_time) ? obj.wrap_time : 0;
		dashRow.active_time = (obj && obj.active_time) ? obj.active_time : 0;
		dashRow.spoken_time = (obj && obj.spoken_time) ? obj.spoken_time : 0;
		// dashRow.color = check_color(obj.last_call);
		dashRow.dialled = (obj && obj.dialled) ? obj.dialled : 0;
		dashRow.contacted = (obj && obj.contacted) ? obj.contacted : 0;
		dashRow.unique_dialled = (obj && obj.unique_dialled) ? obj.unique_dialled : 0;
		dashRow.unique_connected = (obj && obj.unique_connected) ? obj.unique_connected : 0;
		dashRow.forms_filled = (obj && obj.forms_filled) ? obj.forms_filled : 0;
		dashRow.audited_calls = (obj && obj.audited_calls) ? obj.audited_calls : 0;
		dashRow.avg_score = (obj && obj.avg_score) ? obj.avg_score : 0;
		dashRow.ctc_calls = (obj && obj.ctc_calls) ? obj.ctc_calls : 0;
		dashRow.ctc_score = (obj && obj.ctc_score) ? obj.ctc_score : 0;
		return dashRow;
	}

	summarizeData() {
		let obj = {};

		var add_keys = ['followup', 'dialled', 'contacted', 'unique_dialled', 'call_rnr', 'unique_connected', 'forms_filled', 'idle_time', 'wait_time', 'wrap_time', 'active_time', 'spoken_time', 'audited_calls', 'avg_score', 'ctc_calls', 'ctc_score'];
		for (var i in this.dashData) {
			var loc_obj = this.dashData[i];
			for (var k in loc_obj) {
				if (add_keys.indexOf(k) > -1) {
					if (obj.hasOwnProperty(k)) {
						obj[k] += parseInt(loc_obj[k])
					} else {
						obj[k] = parseInt(loc_obj[k])
					}
				}
			}
		}
		//console.log(obj['idle_time'])
		//console.log(this.gethmsformat(obj['idle_time'], 'clock'))
		this.dashSummary = { ...this.dashSummary, ...obj };
		// this.dashSummary.followup = this.followupCnt;
		// this.dashSummary.followupdone = this.followupCntDone;
		// this.dashSummary.followup_perc = this.calculatePercBy(this.dashSummary.followup, this.summary.data.total);
		// this.dashSummary.followupdone_perc = this.calculatePercBy(this.dashSummary.followupdone, this.summary.data.total);
		this.dashSummary.forms_filled_perc = this.calculatePercBy(this.dashSummary.forms_filled, this.summary.contacted.total);
		this.dashSummary.forms_not_filled = (this.summary.contacted.unique > this.dashSummary.forms_filled) ? this.summary.contacted.unique - this.dashSummary.forms_filled : 0;
		this.dashSummary.forms_not_filled_perc = this.calculatePercBy(this.dashSummary.forms_not_filled, this.summary.contacted.total);

		this.callSummaryData = [{
			'type': 'Total',
			'time': "" + this.gethmsformat(this.dashSummary.active_time, 'clock'),
			'spoken_time': "" + this.gethmsformat(this.dashSummary.spoken_time, 'clock'),
			'wrap_time': "" + this.gethmsformat(this.dashSummary.wrap_time, 'clock'),
			'wait_time': "" + this.gethmsformat(this.dashSummary.wait_time, 'clock'),
			'idle_time': "" + this.gethmsformat(this.dashSummary.idle_time, 'clock'),
		},
		{
			'type': "" + `Average of ${this.dashData.length} Agents`,
			'time': "" + this.gethmsformat(this.getDivision(this.dashSummary.active_time, this.dashData.length, undefined, undefined), 'clock'),
			'spoken_time': this.gethmsformat(this.getDivision(this.dashSummary.spoken_time, this.dashData.length, undefined, undefined), 'clock'),
			'wrap_time': "" + this.gethmsformat(this.getDivision(this.dashSummary.wrap_time, this.dashData.length, undefined, undefined), 'clock'),
			'wait_time': "" + this.gethmsformat(this.getDivision(this.dashSummary.wait_time, this.dashData.length, undefined, undefined), 'clock'),
			'idle_time': "" + this.gethmsformat(this.getDivision(this.dashSummary.idle_time, this.dashData.length, undefined, undefined), 'clock'),
		}];


		this.dataSourceCallSummary = new MatTableDataSource(this.callSummaryData);
		this.preparePieChart();

	}

	getDivision(num, denm, delim: any= undefined, type: any= undefined) {
		if (!num || !denm) {
			return 0;
		}
		if (!denm || (denm == 0)) {
			return 0;
		} else {
			let val = Math.abs(num / denm);
			if (type == 'percentage') {
				return Math.abs(num * 100 / denm).toFixed(0)
			} else {
				if ((delim != undefined) && (delim > -1) && (!isNaN(delim))) {
					return val.toFixed(parseInt(delim));
				} else {
					return val.toFixed(1);
				}
			}
		}
	}

	gethmsformat(sec, mode) {
		if (!sec) {
			return 0;
		}
		var hrs, min, seconds;
		hrs = sec / 3600;
		var temp = sec % 3600;
		min = temp / 60;
		seconds = temp % 60;
		function getDigit(digit) {
			if (!digit) {
				return ''
			} else {
				digit = Math.floor(digit);
				if (digit > 9) {
					return digit;
				} else {
					return ('0' + digit);
				}
			}
		}
		switch (mode) {
			case 'clock':

				return getDigit(hrs) + ' : ' + getDigit(min) + ' : ' + getDigit(seconds);
				break;
			default:
				return Math.round(hrs) + 'hrs ' + Math.round(min) + 'min ' + Math.round(seconds) + 'sec';
				break;
		}
	}

	prepareTableData(objReq: any) {
		this.currentLoader = true;
		this.summary = this._summary();
		let data = null;
		let arr = [];
		
		this.dashService.pm_dash_data.subscribe((d) => data = d);
		this.dashData = [];

		// if (data.hasOwnProperty('pd_count_group')) {
		// 	this.summary.data.data_to_call = data['pd_count_group']
		// }
		// if (data.hasOwnProperty('extra_data')) {
		// 	let e = data['extra_data'];
		// 	if (e.hasOwnProperty('total_calls')) {
		// 		// this.summary.dialled.total = e['total_calls'];
		// 	}
		// 	if (e.hasOwnProperty('unique_dialled')) {
		// 		this.summary.dialled.unique = e['unique_dialled'];
		// 		this.summary.dialled.total = e['unique_dialled'];
		// 	}
		// }
		if (data.hasOwnProperty('success')) {
			// for (var i in data.success) {
			// 	this.dashData.push(this.agent_params(i, data.success[i]));
			// 	console.log(data.success[i])
			// }
			let d = data['success'];

			for (var i in d) {
				let agent_data = this.agent_params(i, d[i]);
				this.dashData.push(agent_data)
				let loc = d[i];
				// console.log('printing loc', loc);
				let obj = this._tableDataCallStatus();
				obj = {...obj, ...agent_data};
				obj['user_id'] = i;
				obj['name'] = loc['name'];
				obj['dialled'] = loc['dialled'] || 0;
				obj['unique_dialled'] = loc['unique_dialled'] || 0;
				if (!isNaN(loc['contacted'])) {
					obj['contacted'] = loc['contacted'];
					// this.summary.contacted.total += obj['contacted'];
				}
				if (!isNaN(loc['unique_connected'])) {
					obj['unique_contacted'] = loc['unique_connected'];
					this.summary.contacted.total += obj['unique_contacted'];
					this.summary.contacted.unique += obj['unique_contacted'];
				}
				if (!isNaN(loc['forms_filled'])) {
					obj['forms'] = loc['forms_filled'];
					this.summary.forms.total += obj['forms'];
				}
				obj['audit_score'] = loc['avg_score'];
				obj['ctc_score'] = loc['ctc_score'];
				//   obj['status_color'] = this.getStatusColor(obj);
			//	console.log('arr obj', obj);
				arr.push(obj);
				// this.dashData.push(this.agent_params(i,obj));
			}
		}

		// if (this.summary.data.total == 0) {
		// 	this.summary.data.total = this.summary.data.data_to_call + this.summary.dialled.total;
		// }
		// this.summary.data.total = this.summary.dialled.unique + this.summary.data.data_to_call;
		this.followupCnt = 0;
		this.followupCntDone = 0;
		this.API.getGenericCustomerData(objReq,this.api_end_point)
				.subscribe(
					(res: any) => {
						if (res.errors) {
							
						} else {
							if(res.success){
								this.summary.data.total = res.success.active.total_assigned + res.success.inactive.total_assigned;
								this.summary.contacted.total = res.success.active.total_contacted_calls + res.success.inactive.total_contacted_calls;
								this.summary.contacted.unique= res.success.active.data_contacted + res.success.inactive.data_contacted;
								this.summary.dialled.total = res.success.active.data_dialled + res.success.inactive.data_dialled;
								this.summary.dialled.unique= res.success.active.data_dialled + res.success.inactive.data_dialled;
								this.summary.data.data_to_call = this.summary.data.total - this.summary.dialled.total;
								this.summary.data.total_calls = res.success.active.total_calls + res.success.inactive.total_calls;
								var total_calls = this.summary.data.total;
								if (total_calls > 0) {
									this.summary.data.total_perc = '100.0';
								} else {
									this.summary.data.total_perc = '0.0'
								}
								this.summary.data.data_to_call_perc = this.calculatePercBy(this.summary.data.data_to_call, total_calls);
								this.summary.dialled.total_perc = this.calculatePercBy(this.summary.dialled.total, total_calls);
								this.summary.dialled.unique_perc = this.calculatePercBy(this.summary.dialled.unique, total_calls);

								this.summary.contacted.total_perc = this.calculatePercBy(this.summary.contacted.total, this.summary.data.total_calls);
								this.summary.contacted.unique_perc = this.calculatePercBy(this.summary.contacted.unique, total_calls);
								this.followupCnt = res.success.active.total_followups + res.success.inactive.total_followups;
								this.followupCntDone = res.success.active.total_followups_completed + res.success.inactive.total_followups_completed;
								this.dashSummary.followup = this.followupCnt;
								this.dashSummary.followupdone = this.followupCntDone;
								this.dashSummary.followuptodo = this.followupCnt - this.followupCntDone;
								this.dashSummary.followup_perc = this.calculatePercBy(this.dashSummary.followup, this.summary.data.total);
								this.dashSummary.followupdone_perc = this.calculatePercBy(this.dashSummary.followupdone, this.dashSummary.followup);
								this.dashSummary.followuptodo_perc = this.calculatePercBy(this.dashSummary.followuptodo, this.dashSummary.followup);
							}

							
						}
						this.summarizeData();

							// console.log('table data', arr);
							this.dataSource = new MatTableDataSource(arr);
							this.dataSource.sort = this.tableSort;
							this.dataSource.paginator = this.paginator;
							this.dataSource.filterPredicate = function (data, filter: any): boolean {
								return data.name.toString().toLowerCase().includes(filter) || data.user_id.toString().toLowerCase().includes(filter)
							};
						this.currentLoader = false;
						this.API.genericCallDataDetails({ 'start_date': objReq.start_date, 'end_date': objReq.end_date },this.api_end_point).subscribe((resp:any) => {
							if (resp.success) {
								let call_response = resp.success;
								this.dashSummary.calls_dialled = call_response['calls']['dialled'];
								this.dashSummary.calls_contacted_perc = this.calculatePercBy(this.dashSummary.calls_dialled, this.dashSummary.calls_dialled);
								this.dashSummary.calls_rnr = call_response['calls']['rnr'];
								this.dashSummary.calls_rnr_perc = this.calculatePercBy(this.dashSummary.calls_rnr, this.dashSummary.dialled);
								this.dashSummary.calls_contacted = call_response['calls']['contacted'];
								this.dashSummary.calls_contacted_perc = this.calculatePercBy(this.dashSummary.calls_contacted, this.dashSummary.dialled);
							}
						})
					}, (err) => {
						console.log(err)
					}
				)
		
	}

	getCallStatus() {
		this.refreshData();
	}

	getSlotStatus() {
		this.refreshDataCallSlot()
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}

	addSlot() {
		const dialogRef = this.dialog.open(PmdashboardDailogComponent, {
			width: '70%',
			disableClose: true,
			data: '',
		});

		dialogRef.afterClosed().subscribe(result => {
			//console.log('The dialog was closed', result);
			if (result.refresh) {
				this.refreshDataCallSlot()
			}
		});
	}
	editSlot(element: any) {
		const dialogRef = this.dialog.open(PmdashboardDailogComponent, {
			width: '50%',
			disableClose: true,
			data: element
		});

		dialogRef.afterClosed().subscribe(result => {
			//console.log('The dialog was closed', result);
			if (result.refresh) {
				this.refreshDataCallSlot()
			}
		});
	}
	changeTogleStatus(value) {
		this.selectedTabSts = value;
		if (value == 'slot_status') {
			this.getSlotStatus();
		} else {
			this.getCallStatus();
		}
	}

	getStatusColor(obj) {
		// console.log('color', obj, this.color_arr);
		// console.log('selected color', obj, this.color_arr);
		if(!obj.user_id){
			return '#DEE1E6';
		}
		let user_id = obj.user_id;
		if (typeof user_id != 'string') {
			user_id = user_id.toString();
		}
		if (!this.color_arr.hasOwnProperty(user_id)) {
			return '#DEE1E6';
			// console.log('userid found in the status color', typeof user_id, this.color_arr[user_id])
		} 
		return this.color_arr[obj.user_id];
		/* switch (val) {
		  case '1':
			return 'red';
			break;
		  case '2':
			return 'green';
			break;
		  default:
			return 'orange';
		} */
	}

	change_date_call_status(key: string, event: MatDatepickerInputEvent<Date>) {
		if (key == 'start_date') {
			this.callStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			}
		} else {
			this.callEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.callStartDate).diff(moment(this.callEndDate)) >= 0) {
				this.callEndDate = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this.messageAlert="Start date should be less than end date *";
	               this.showHide_messageAlert=true;
			} else {
				this.showHide_messageAlert=false;
			}

		}
	}
	change_date(key: string, event: MatDatepickerInputEvent<Date>) {

		if (key == 'start_date') {
			this.slotStartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.slotStartDate).diff(moment(this.slotEndDate)) >= 0) {
				this.slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
				// alert("Start date should be less than end date");
				return;
			}
		} else {
			this.slotEndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.slotStartDate).diff(moment(this.slotEndDate)) >= 0) {
				this.slotEndDate = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this.messageAlert="Start date should be less than end date *";
				this.showHide_messageAlert=true;
				return;
			} else {
				this.showHide_messageAlert=false;
			}

		}
	}

	preparePieChart() {
		let chartdata = new Chart({
			title: {
				text: ''
			},
			credits: {
				enabled: false
			},
			plotOptions: {
				pie: {
					cursor: 'pointer',
					dataLabels: {
						distance: -30,
						enabled: true,
						color: 'white',
						style: {
							fontWeight: '500',
						}
					},
					showInLegend: false,
					borderWidth: 5,
				},
			},

			series: [
				{
					states: {
						inactive: {
							opacity: 1
						},
						hover: {
							enabled: false
						}
					},
					name: 'Active',
					data: [

						{
							name: "" + this.dashSummary.forms_filled_perc,
							y: Number(this.dashSummary.forms_filled_perc), color: '#e40f59'
						}, {
							name: "" + this.dashSummary.forms_not_filled_perc,
							y: Number(this.dashSummary.forms_not_filled_perc), color: '#1b7ec7'
						},
					],
					type: 'pie',

					innerSize: '40%',
				}]
		});

		this.agentStatusChart = chartdata;
	}
	calculatePercBy(num1, num2) {
		if (num1 == 0 || num2 == 0) {
			return "0.0";
		}
		var value: any = (num1 / num2) * 100;
		value = value.toFixed(1);
		return value
	}
	selectChildren() {
		if (this.selectedAgents.every(a => a.checked)) {
			this.selectAll = true;
		} else {
			this.selectAll = false;
		}
		this.selectedAgentsLen = this.selectedAgents.filter(e => e.checked === true).length;
	}

	updateCheck() {
		if (this.selectAll === true) {
			this.selectedAgents.map((agent) => {
				agent.checked = true;
			});
			this.selectedAgentsLen = this.selectedAgents.length;
		} else {
			this.selectedAgents.map((agent) => {
				agent.checked = false;
			});
			this.selectedAgentsLen = 0;
		}
	}

	downloadReportGeneral() {
		var current_date = new Date();
		/*end_date = new Date().toISOString();*/
		var obj: any = {};
		obj.start_date = this.utils.localToUTC(this.callStartDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		obj.end_date = this.utils.localToUTC(this.callEndDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss')
		obj.download = true;
		// obj.offset = current_date.getTimezoneOffset() * -1;
		obj.incsales = 1;
		obj.userset = 'Team';
		if (this.selectedAgents.length == 0) {
			for (const item of this.myService.getAllIds('user')) {
				let agent = { name: item.name, user_id: item.id, checked: true };
				this.selectedAgents.push(agent);
			}
			obj.agents_list = this.myService.getAllIds('user', 'id');
		} else {
			obj.agents_list = this.selectedAgents.filter(e => e.checked === true).map(f => f.user_id);
		}
		this.API.downloadCallStatusOutboundReport(obj,null,this.api_end_point).subscribe(
			(res: any) => {
				// debugger;
				if (res.url) {
					this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
						() => {
							// campaign_data.showLoader = false;
						}
					)
				} else {
					alert(res.errors)
				}
			}, (err) => {
				// debugger;
			}
		)
	}

	downloadAuditReport() {
		this.API.downloadAuditOutboundReport(this.utils.localToUTC(this.callStartDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss'), this.utils.localToUTC(this.callEndDate,'YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss'),this.api_end_point).subscribe(
			(res:any) => {
			  // debugger;
			  if (res.url) {
				this.API.downloadReportFromUrl(res.url,null,this.api_end_point).then(
				  () => {
					// campaign_data.showLoader = false;
				  }
				)
			  } else {
				alert(res.errors)
			  }
			}, (err) => {
			  // debugger;
			}
		)
	}
	
	openFeedback() {
	//	console.log('click open new tabl');
		this.router.navigate(['/feedback'],{ queryParams: { start_date: this.callStartDate, end_date: this.callEndDate } });
	}
	
}
