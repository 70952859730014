import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-auditor-project-edit-dialog',
  templateUrl: './auditor-project-edit-dialog.component.html',
  styleUrls: ['./auditor-project-edit-dialog.component.scss']
})
export class AuditorProjectEditDialogComponent implements OnInit {
  createdForm: FormGroup;
  myUser: any;
  auditors: any[] = [];
  auditorsSelected: any[] = [];
  selectedProject: any;
  removeIds: number[] = [];
  insertIds: number[] = [];
  paymentMode: any;
  passingMessage: string = "";
  showHidePassingMessage: boolean = false;
  _color: any = "green";
  submitStatus: boolean = false;
  projectActive: boolean = true;
  constructor(public dialogRef: MatDialogRef<AuditorProjectEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewData, private API: ApiService, private service: MyserviceService, private formbuilder: FormBuilder) {
    this.selectedProject = viewData;
   // console.log(viewData)
    this.createdForm = this.formbuilder.group({
      projectName: ['', Validators.required],
      auditors: [''],
      paymentMode: [''],
      projectEndDate: [''],
    })
    // this.createdForm=new FormGroup({
    //   'projectName' :new FormControl(this.selectedProject.project_name),

    // })
  }

  ngOnInit() {
    this.myUser = this.service.get_loggedin_user();
    this.createdForm.controls.projectName.setValue(this.selectedProject.project_name);
        this.createdForm.controls.auditors.setValue(this.auditorsSelected);
        this.createdForm.controls.paymentMode.setValue("Normal");
        if(this.selectedProject.end_at){
          this.createdForm.controls.projectEndDate.setValue(true);
          this.submitStatus = true;
        }
    this.API.getProjectAuditors(this.myUser.id, this.selectedProject.id).subscribe((response: any) => {
      if (response.success) {
        for (const auditor of response.success) {
          let obj = auditor;
          obj.checked = auditor.project_name ? true : false;
          this.auditors.push(obj)
          if (obj.checked) {
            this.auditorsSelected.push(obj.name);
            this.removeIds.push(obj.id)
          }
        }
        
      }
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }

  updateProject = (createdForm) => {
    var insertIds = [];
    for (const auditor of this.auditors) {
      if (this.auditorsSelected.some(e => e == auditor.name)) {
        insertIds.push(auditor.id);
      }
    }
    // console.log(insertIds);
    // console.log(this.removeIds);
    var obj: any = {
      "audit_client_id": this.selectedProject.id,
      "removeIds": this.removeIds,
      "insertIds": insertIds,
      "payment_type": createdForm.controls.paymentMode.value,
      "cx_touch_point": this.selectedProject.cx_touch_point
    }
    if(createdForm.controls.projectEndDate.value){
      if(!this.selectedProject.end_at){
        obj.end_at = moment().endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
        obj.removeIds = insertIds;
        obj.insertIds = []
      }
    }
    //console.log(obj)
    if(this.submitStatus == false){
      this.submitStatus = true;
    }else{
      return;
    }
    this.API.assignAuditorsToProjects(obj).subscribe((data: any) => {
      if (data.success) {
        this.passingMessage = data.success;
        this.showHidePassingMessage = true;
        setTimeout(() => {
          this.submitStatus = false;
          this.dialogRef.close({refresh: true});
        }, 2000);

      }
      if(data.error){
        this.submitStatus = false;
      }
    });
  }

  auditorChange(value) {
    // console.log(value);
    // console.log(this.createdForm.controls.auditors.value)
    this.auditorsSelected = value;
  }
}
