import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyserviceService } from '../../myservice.service';
import { ServicesService } from '../../dashboard/services.service';
import { ApiService } from '../../api.service';
import { Utils } from '../../utils';

@Component({
	selector: 'app-download-attachment',
	templateUrl: './download-attachment.component.html',
	styleUrls: ['./download-attachment.component.scss']
})
export class DownloadAttachmentComponent implements OnInit {

	api_end_point: any;
	savetoken:any;
	//username: string = '';
	constructor(
		public dialogRef: MatDialogRef<DownloadAttachmentComponent>,
		private API: ApiService,
		private service: MyserviceService,
		private dashService: ServicesService,
		private myservice: MyserviceService,
		private _snackBar: MatSnackBar
	) { }

	ngOnInit(): void {
		this.api_end_point = this.myservice.get_API_End_Point();
		this.savetoken = this.myservice.get_access_token();
	}


	clickme(username:string) {
		console.log('it does nothing', username);
		console.log("--here-attachment-link-is--" + username);
		//username.split("api")
		var obj= username.split("api");
		// if(window.location.href.includes("localhost"))
		// {
		// }
		// console.log(obj[1])
		var link=`${this.api_end_point}/api${obj[1]}?access_token=${this.savetoken}`;
		
		window.open(link)
	}
	closePopup(){
		this.dialogRef.close();
	  }
}
