<h3 class="sBiz-title">SmarterBiz</h3><br>
<div class="text-center">
    <p  class="hdtext"><span> On-Boarding Process</span></p>
    <p class="LText">Boost your operational efficiency with realtime<br>
        customer journey orchestration across channels.</p>
   
</div>

<div>
<mat-card class="m-card">
    <p *ngIf="showHideMessage" style="font-weight: bold;" [ngStyle]="{'color':msgColor}">{{messageDisplay}}</p>
    <form  [formGroup]="createdForm">
    <div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Project Name <span class="starMandatory">*</span> </span>
        <input type="text" maxlength="150"  (keypress)="omit_special_char($event)"  class="input-Type"  formControlName="projectName"/>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Project Type <span class="starMandatory">*</span> </span>
        <mat-checkbox class="mcbx"  formControlName="marketPlace">Market Place</mat-checkbox>
       <mat-checkbox class="mcbx"  formControlName="platform">Platform</mat-checkbox>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">POC - Start date <span class="starMandatory">*</span> </span>
        <mat-form-field class="removeBorderBtm" style="width:100%;margin-bottom: -22px; margin-top: -14px;">
            <input matInput  formControlName="pocStartDate" [matDatepicker]="startpicker" class="input-Type" readonly (click)="startpicker.open()">
            <mat-datepicker #startpicker></mat-datepicker>
          </mat-form-field>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">POC - End date <span class="starMandatory">*</span> </span>
        <mat-form-field class="removeBorderBtm" style="width:100%;margin-bottom: -22px;margin-top: -14px;">
            <input matInput formControlName="pocEndDate" [matDatepicker]="endpicker" class="input-Type" readonly (click)="endpicker.open()">
            <mat-datepicker #endpicker></mat-datepicker>
          </mat-form-field>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">POC Headcount </span>
        <input type="text"  maxlength="15"  class="input-Type" formControlName="pocHeadCount" (keypress)="onlyNumberValidation($event)"/>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Production Headcount </span>
        <input type="text"  maxlength="15"  class="input-Type" formControlName="productionHeadCount" (keypress)="onlyNumberValidation($event)"/>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Team Hierarchy details</span>
        <p style="font-size: 10px;word-spacing: normal;word-break: break-all;">Link to download sample template for hierarchy:<br>
            https://drive.google.com/file/d/1sj4izbeqTg1TL3p5MUMmwJyaC_7-Qguq/view?usp=sharing
        </p>
        <br>
        <div class="upload-btn-wrapper">
            <button class="filebtn"><i class="fa fa-upload"></i>Add File</button>
            <input type="file" id="fileID"  formControlName="addFile"/>
          </div>
    </div>
</div>
<div class="row rowDiv">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span class="labelText">Process Type <span class="starMandatory">*</span> </span>
       <mat-checkbox class="mcbx"  formControlName="inboundCall">Inbound Call</mat-checkbox>
       <mat-checkbox class="mcbx" formControlName="outboundCall">Outbound Call</mat-checkbox>
       <mat-checkbox class="mcbx" formControlName="emailCB">Email</mat-checkbox>
       <mat-checkbox class="mcbx" formControlName="chatbot">Chat Bot</mat-checkbox>
    </div>
</div>
<br><br>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
       <button class="onboard-submit" (click)="onNextBtn(createdForm)">NEXT</button>
    </div>
</div>
<br><br>
</form>
</mat-card>
</div> 


