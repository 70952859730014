import { Component, OnInit,Inject,ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormGroup,FormControl,FormBuilder,Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import {Router} from '@angular/router';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-auditor-project-view-dialog',
  templateUrl: './auditor-project-view-dialog.component.html',
  styleUrls: ['./auditor-project-view-dialog.component.scss']
})
export class AuditorProjectViewDialogComponent implements OnInit {

  displayedColumns = ['name', 'email'];
  dataSource:any = new MatTableDataSource();
  myUser: any;
  auditors: any[] = [];
  selectedProject: any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  constructor(public dialogRef: MatDialogRef<AuditorProjectViewDialogComponent>, @Inject(MAT_DIALOG_DATA) public viewData,public formbuilder:FormBuilder,private API: ApiService, private service: MyserviceService) {
    this.selectedProject = viewData;
   }

  ngOnInit() {
  
  this.myUser = this.service.get_loggedin_user();
  
  this.API.getClientsAuditors(this.selectedProject.id).subscribe((response: any) => {
      if(response.success){
        this.auditors = response.success;
        this.dataSource = new MatTableDataSource(this.auditors);
        this.dataSource.paginator = this.paginator;
      }
  });
  }
  closeDialog(){
    this.dialogRef.close();
  }

}

