import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { MyserviceService } from '../../../myservice.service';

interface STATUS_DISPLAY {
    status?: string,
    color?: string
}

@Component({
    selector: 'app-swift-status',
    templateUrl: './swift-status.component.html',
    styleUrls: ['./swift-status.component.scss']
})

export class SwiftStatusComponent implements OnInit, OnChanges {

    @Input() current_status: string;

    swift_status: any = this.services.get_swift_status();

    display_arr: STATUS_DISPLAY[] = [];

    constructor(private services: MyserviceService) {

    }
    ngOnChanges(changes: SimpleChanges): void {
        this.prepare_display_arr();
    }
    ngOnInit(): void {
        console.log('swift status', this.swift_status);
        this.prepare_display_arr();
    }

    prepare_display_arr(): void {
        try {
            this.display_arr.length = 0;
            let status_matched = false;
            if (this.swift_status) {
                this.swift_status.forEach(e => {
                    if (e.stage_name == this.current_status) {
                        this.display_arr.push({
                            "status": e.stage_name,
                            "color": 'green'
                        })
                        status_matched = true;
                    } else {
                        this.display_arr.push({
                            "status": e.stage_name,
                            "color": status_matched ? 'grey' : 'black'
                        })
                    }
                })
            }
        } catch (e) {

        }
        console.log('final display arr', this.display_arr);
    }

}