
import { Component, OnInit, ElementRef, HostListener, } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/api.service';
import { Title, Meta } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-new-contiinex-menu-page',
  templateUrl: './new-contiinex-menu-page.component.html',
  styleUrls: ['./new-contiinex-menu-page.component.scss']
})
export class NewContiinexMenuPageComponent implements OnInit {
  myTitle = 'Contiinex | Customer Engagement Automation';
  activeItem: string;
  _menuPage: string;

  showHideSubMenuComnexEngage: boolean = false;
  showHideSubMenuComnexExperience: boolean = false;
  showHideSubMenuComnexEnable: boolean = false;
  constructor(private metaTagService: Meta, private titleService: Title, private router: Router, private location: Location, private API: ApiService, protected $gaService: GoogleAnalyticsService) {
    window.scroll(0, 0);
  }
  ngOnInit() {

  }
  LoginBtn() {
    window.scroll(0, 0);
    this.$gaService.event('Clicked Login', 'Home Page');
    this.router.navigateByUrl('/Login');

  }
  openForm() {
    document.getElementById("popupForm").style.display = "block";
  }

  closeForm() {
    document.getElementById("popupForm").style.display = "none";
  }
  documnetDiv(page: string) {
    if (page == "documntPage") {

    }
    else {
      this.setInActiveItem(page);
      this._menuPage = page;
      this.activeItem = page;
    }
  }
  setActiveItem(page: string) {
    this._menuPage = page;
    this.activeItem = page;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (page == "products") {
      this.openForm();
    }
    else if (page == "company" || page == "solutions") {

    }
    else if (page == "productspopup") {
      this.openForm();
    }

    else {
      this.closeForm();
    }
  }
  setInActiveItem(page: string) {
    //debugger;
    this.showHideSubMenuComnexEngage = false;
    this.showHideSubMenuComnexExperience = false;
    this.showHideSubMenuComnexEnable = false;

    if (page == "productspopup") {
      this.closeForm();
      this._menuPage = page;
      this.activeItem = page;
    }
    if (page == "products") {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.closeForm();
      this._menuPage = page;
      this.activeItem = page;
      this.$gaService.pageView('/', 'Click Products');
      this.$gaService.event('Clicked Product From Menu', 'Home Page');
      this.router.navigateByUrl('/');
    }
    if (page == "company") {

      this.router.navigateByUrl('/Company');
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.closeForm();
      this._menuPage = page;
      this.activeItem = page;
      this.$gaService.pageView('/', 'Click Company');
      this.$gaService.event('Clicked Company From Menu', 'Home Page');

    }
    if (page == "solutions") {

      this.router.navigateByUrl('/Contact');
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.closeForm();
      this._menuPage = page;
      this.activeItem = page;
      this.$gaService.pageView('/', 'Click Solution');
      this.$gaService.event('Clicked Solution From Menu', 'Home Page');

    }

  }




  logoWbsiteBtn() {
    this.router.navigateByUrl('/');
  }



  openProductSummary(type: any, popUpType: any) {
    //debugger;
    if (type == "comnexEngage") {
      this.$gaService.pageView('/Unified-Communication-as-a-Service', 'Click Unified Communication as a Service Product');
      if (popUpType == "popup") {
        this.$gaService.event('Clicked Unified Communication as a Service From Product Menu', 'Home Page');
        this.router.navigateByUrl('/Unified-Communication-as-a-Service');
      }
      if (popUpType == "noPopup") {
        this.$gaService.event('Clicked Know More From Unified Communication as a Service Section', 'Home Page');
      }

      if (popUpType == "nopopupEngage") {
        this.$gaService.event('Clicked Know More From Engage Section', 'Home Page');
      }
      if (popUpType == "nopopupDeliver") {
        this.$gaService.event('Clicked Know More From Deliver Section', 'Home Page');
      }

    }
    if (type == "comnexEnable") {
      this.$gaService.pageView('/Comnex-Enable', 'Click Comnex Enable Product');
      if (popUpType == "popup") {
        this.$gaService.event('Clicked Comnex Enable From Product Menu', 'Home Page');
        this.router.navigateByUrl('/Comnex-Enable');
      }

    }

    if (type == "comnexExperience") {
      this.$gaService.pageView('/Quality-Assurance-Automation', 'Click Quality Assurance Automation Product');
      if (popUpType == "popup") {
        this.$gaService.event('Clicked Quality Assurance Automation From Product Menu', 'Home Page');
        this.router.navigateByUrl('/Quality-Assurance-Automation');
      }

    }
    if (type == "whatsAppPi") {
      this.$gaService.pageView('/WhatsApp-Business-Api', 'Click WhatsApp Business Api Product');
      if (popUpType == "popup") {
        this.$gaService.event('Clicked WhatsApp Business Api From Product Menu', 'Home Page');
        this.router.navigateByUrl('/WhatsApp-Business-Api');
      }

    }

    if (type == "gignex") {
      this.$gaService.pageView('/comnex-experience', 'Click Quality Assurance Automation Product');
      if (popUpType == "popup") {
        this.$gaService.event('Clicked Quality Assurance Automation From Product Menu', 'Home Page');
      }
      if (popUpType == "noPopup") {
        this.$gaService.event('Clicked Know More From Quality Assurance Automation Section', 'Home Page');
      }


      if (popUpType == "nopopupSupport") {
        this.$gaService.event('Clicked Know More From Support Section', 'Home Page');
      }
    }

    if (type == "ainex") {
      this.$gaService.pageView('/Ainex', 'Click Ainex Menu Product');
      if (popUpType == "popup") {
        this.$gaService.event('Clicked Ainex From Product Menu', 'Home Page');
      }

      if (popUpType == "nopopupSmartServe") {
        this.$gaService.event('Clicked Know More From Smart Serve Section', 'Home Page');
      }
    }

  }
  removePopup() {
    this.showHideSubMenuComnexEngage = false;
    this.showHideSubMenuComnexExperience = false;
    this.showHideSubMenuComnexEnable = false;
    this.closeForm();
  }
  setActiveItem1() {
    this.openForm();
  }
  setInActiveItem1(page: any) {
    this.showHideSubMenuComnexEngage = false;
    this.showHideSubMenuComnexExperience = false;
    this.showHideSubMenuComnexEnable = false;
    //console.log(page);
    this.closeForm();
  }

  productSubMenu(val: any) {
    this.showHideSubMenuComnexEngage = false;
    this.showHideSubMenuComnexExperience = false;
    this.showHideSubMenuComnexEnable = false;

    if (val == "comnexExperience") {
      this.showHideSubMenuComnexExperience = true;
    }
    if (val == "comnexEngage") {
      this.showHideSubMenuComnexEngage = true;
    }
    if (val == "comnexEnable") {
      this.showHideSubMenuComnexEnable = true;
    }
    if (val == "whatsappApi") {

    }

  }

  setActiveItemSolution1(){
this.openFormSolution();
  }
  removePopupSolution(){
	this.closeFormSolution();
  }
  setInActiveItemSolution1(){
	
      this.closeFormSolution();
  }
  setInActiveItemSoution(){
	this.router.navigateByUrl('/');
  }
  openFormSolution() {
    document.getElementById("popupFormSolution").style.display = "block";
  }

  closeFormSolution() {
    document.getElementById("popupFormSolution").style.display = "none";
  }

}
