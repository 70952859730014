import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { MyserviceService } from 'src/app/myservice.service';
import * as moment from 'moment';

@Component({
  selector: 'app-work-slot-agent-plugin',
  templateUrl: './work-slot-agent-plugin.component.html',
  styleUrls: ['./work-slot-agent-plugin.component.scss']
})
export class WorkSlotAgentPluginComponent implements OnInit {

  displayedColumns = ['time', 'status', 'book'];
  dataSource: any = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  _elementRecord: any;
  user: any = this.myservice.get_loggedin_user();
  api_end_point:any;
  constructor(private dialog: MatDialog, public router: Router, private myservice: MyserviceService, private API: ApiService
  ) {
  }
  ngOnInit() {
	this.api_end_point = this.myservice.get_API_End_Point();
    this.getAgentSlot();
  }
  getAgentSlot() {
    let userid = this.user.id;
    let obj = {
      "userid": userid
    }
    this.API.agentPluginGetAgentSlot(obj,this.api_end_point).subscribe((data: any) => {
      let _response = data.success.data;
      if (_response) {
        this._elementRecord = _response;
        this.dataSource = new MatTableDataSource(this._elementRecord);
      }
    });
  }
  bookRegister(element: any) {
    let userid = this.user.id;
    let obj = {
      "user_id": userid,
      "slot_id": element.slot_id
    }
    this.API.agentPluginGetRequestForBookingSlot(obj,this.api_end_point).subscribe((data: any) => {
      let _response = data.success;
      if (_response == "Successfully booked the slot") {
        this.getAgentSlot();
      }
      else {
      }
    });
  }
  cancelBooked(element) {
    let userid = this.user.id;
    let obj = {
      "user_id": userid,
      "slot_id": element.slot_id
    }
    this.API.agentPluginGetRequestForCancelSlot(obj,this.api_end_point).subscribe((data: any) => {
      let _response = data.success;
      if (_response == "Slot cancelled successfully.") {
        this.getAgentSlot();
      }
      else {
      }
    });
  }
}

