
<!-- 
<div class="upload-btn-wrapper">
    <button class="btnbrowse">Browse File &nbsp;&nbsp;</button>
 <input type="file" #fileUpload class="filecs" />
  </div> -->


  <div class="row  mt-2">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <span>
        <label class="lvX">Select</label>
        <mat-select [ngClass]="'arrowcs'" class="dropdownCs ht-x">
        <mat-option value="Option1">Option1</mat-option>                                   
    </mat-select>
</span>
  </div>
    <div class="col-md-12 col-sm-12 col-xs-12 text-center mt-4">
        <button class="prevAdd mt-0 ml-3" style="color:gray !important;border:1px solid gray !important" mat-button (click)="closePopup()">
            Cancel
        </button>

        <button class="prevAdd mt-0 ml-3" style="color:#0bac1e !important;border:1px solid #0bac1e !important" (click)="submitBtn()" mat-button>
           Submit
        </button>
        </div>
        </div>