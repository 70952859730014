
<div>
	<div class="row">
		<div class="col-md-12 col-sm-12 col-xs-12 text-center">
			<h3>Stop Auto Dialling</h3>
			<p>Do you want to stop receiving calls from Server?</p>
		</div>
	</div>
	
			<div class="mt-4 text-center">
				<mat-form-field  style="width:250px">
					
					<mat-select placeholder="Select"  (selectionChange)="onOptionsSelected($event.value)" >
						<mat-option *ngFor="let sta of stagesetting" [value]="sta">
							{{sta}}</mat-option>
					</mat-select>
				</mat-form-field>
					
			</div>
			<br>
			<div class="row mt-3">

				<div class="col-md-12 col-sm-12 col-xs-12 text-center btn-grp">
					<button mat-button class="action_btn" (click)="closeDialog()">CANCEL</button>
					<button mat-button (click)="submitbtn()">SUBMIT</button>
				</div>

			</div>
	
</div>