import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-story-slider',
	templateUrl: './story-slider.component.html',
	styleUrls: ['./story-slider.component.scss'],
	providers: [NgbCarouselConfig]
})
export class StorySliderComponent implements OnInit, AfterViewInit {
	@ViewChild('slide', {static: false, read:ElementRef}) sRef: ElementRef;
	constructor(
		config: NgbCarouselConfig,
		private elRef: ElementRef,
		private renderer: Renderer2
	) {
		config.interval = 10000;
		config.wrap = true;
		config.keyboard = false;
		config.pauseOnHover = true;
	}

	ngOnInit() {
		// console.log('on init', this.sRef);
	}

	ngAfterViewInit() {
		// this.elRef.nativeElement.children[0].children[0].classList.add('new_ol')
		this.renderer.addClass(this.sRef.nativeElement.children[0],'new_ol')
	}
}
