<!DOCTYPE html>
<html>

<head>
    <meta name="viewport" content="width=device-width, initial-scale=1">

</head>
<!-- <app-home-header></app-home-header> -->

<div class="parentDiv" style="margin-top: 5%;">
    <h2>FAQ</h2>
    <h1 class="sectionHeading pt-4">Section 1. Login Failed</h1>

    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>1. Is the issue occurring for all of you (all the agents?)</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                Generic Issue : If the issue is across all Agents, then inform to Tech team</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>2. Are you sure you are using the correct version of the APK?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If the issue is for Specific Agent : First check the Android version , if Android version 9 then the
                Agent needs to use the local APK / check the Internet speed</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>3. Are you facing the issue only today? Was everything fine till today?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If the issue is for Specific Agent : First check the Android version , if Android version 9 then the
                Agent needs to use the local APK / check the Internet speed</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>4. Are you using the correct combination of UserId, password?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If the issue is for Specific Agent : First check the Android version , if Android version 9 then the
                Agent needs to use the local APK / check the Internet speed</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>5. Can you confirm for yourself if you have entered the correct password using EYE symbol in the
                        password field</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If the issue is for Specific Agent : First check the Android version , if Android version 9 then the
                Agent needs to use the local APK / check the Internet speed</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>6. Have you reset the password recently?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If the issue is for Specific Agent : First check the Android version , if Android version 9 then the
                Agent needs to use the local APK / check the Internet speed</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>7. Can you try Forgot Password option once?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>

        <div class="panel">
            <p><span>Solution:</span>
                If the issue is for Specific Agent : First check the Android version , if Android version 9 then the
                Agent needs to use the local APK / check the Internet speed</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>8. Are you using True caller or any other call tracking related apps?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If the issue is for Specific Agent : First check the Android version , if Android version 9 then the
                Agent needs to use the local APK / check the Internet speed</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>9. What is the memory (RAM) configuration on your mobile?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>

        <div class="panel">
            <p><span>Solution:</span>
                If the issue is for Specific Agent : First check the Android version , if Android version 9 then the
                Agent needs to use the local APK / check the Internet speed</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>10. Are you using mobile data or the official WiFi?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>

        <div class="panel">
            <p><span>Solution:</span>
                If the issue is for Specific Agent : First check the Android version , if Android version 9 then the
                Agent needs to use the local APK / check the Internet speed</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>11. What is the Android version (is it 9.0)?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>

        <div class="panel">
            <p><span>Solution:</span>
                If the issue is for Specific Agent : First check the Android version , if Android version 9 then the
                Agent needs to use the local APK / check the Internet speed</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>12.Can you please share the Internet speed on your mobile</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If the issue is for Specific Agent : First check the Android version , if Android version 9 then the
                Agent needs to use the local APK / check the Internet speed</p>
        </div>
    </div>


    <h1 class="sectionHeading">Section 2. SIM authorized</h1>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>1. Are you facing the issue only today? Was everything fine till today?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When the agent is facing this issue, then follow below instructions:<br>
                If the agent has Dual Sim, check in Settings->SIM management -> Change the calls to the SIM (calls
                should be enabled to the number which is given to us to add in Hierarchy)
                If the agent has only one SIM, then Login ID should be shared to the Backend team to check the Status.
            </p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>2. Have you changed Handset OR Sim?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>

        <div class="panel">
            <p><span>Solution:</span>When the agent is facing this issue, then follow below instructions:<br>
                If the agent has Dual Sim, check in Settings->SIM management -> Change the calls to the SIM (calls
                should be enabled to the number which is given to us to add in Hierarchy)
                If the agent has only one SIM, then Login ID should be shared to the Backend team to check the Status.
            </p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>3. Are you using Dual Sim OR Single Sim<br>
                        Dual Sim: Can you please let me know the settings inSettings->SIM management?Single Sim: Can you
                        please share the login Id....</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When the agent is facing this issue, then follow below instructions:<br>
                If the agent has Dual Sim, check in Settings->SIM management -> Change the calls to the SIM (calls
                should be enabled to the number which is given to us to add in Hierarchy)
                If the agent has only one SIM, then Login ID should be shared to the Backend team to check the Status.
            </p>
        </div>
    </div>

    <h1 class="sectionHeading">Section 3. Incoming call issue</h1>

    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>1. What is the network signal strength in your mobile?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent has an issues which says ( Your call is in progress. Please wait):<br>
                When agent selects START, they have to wait for a min to get the incoming call.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>2. Can you please share the Internet speed on your mobile?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent has an issues which says ( Your call is in progress. Please wait):<br>
                When agent selects START, they have to wait for a min to get the incoming call.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>3. Have you just started calling now?Is this your FIRST Call?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent has an issues which says ( Your call is in progress. Please wait):<br>
                When agent selects START, they have to wait for a min to get the incoming call.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>4. Are you using True caller or any other call tracking related apps?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent has an issues which says ( Your call is in progress. Please wait):<br>
                When agent selects START, they have to wait for a min to get the incoming call.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>5. Are you connecting to a JIO customer</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent has an issues which says ( Your call is in progress. Please wait):<br>
                When agent selects START, they have to wait for a min to get the incoming call.</p>
        </div>
    </div>


    <h1 class="sectionHeading">Section 4. Connecting you to the Customer</h1>

    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>1.What is the network signal strength in your mobile?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When Agents hits on START & waits for the call, the message displays like ( Connecting you to the
                customer... Please wait ):<br>
                In this case, the agent need to wait for 1 min to receive the incoming call. If he/she is not able to
                receive any incoming call, then Click on "STOP" & "START".<br>
                Even then, the incoming call is not landing then inform to Tech team.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>2. Can you please share the Internet speed on your mobile?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When Agents hits on START & waits for the call, the message displays like ( Connecting you to the
                customer... Please wait ):<br>
                In this case, the agent need to wait for 1 min to receive the incoming call. If he/she is not able to
                receive any incoming call, then Click on "STOP" & "START".<br>
                Even then, the incoming call is not landing then inform to Tech team.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>3. Have you just started calling now?Is this your FIRST Call?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When Agents hits on START & waits for the call, the message displays like ( Connecting you to the
                customer... Please wait ):<br>
                In this case, the agent need to wait for 1 min to receive the incoming call. If he/she is not able to
                receive any incoming call, then Click on "STOP" & "START".<br>
                Even then, the incoming call is not landing then inform to Tech team..</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>4. Are you using True caller or any other call tracking related apps?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When Agents hits on START & waits for the call, the message displays like ( Connecting you to the
                customer... Please wait ):<br>
                In this case, the agent need to wait for 1 min to receive the incoming call. If he/she is not able to
                receive any incoming call, then Click on "STOP" & "START".<br>
                Even then, the incoming call is not landing then inform to Tech team.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>5. Are you connecting to a JIO customer</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When Agents hits on START & waits for the call, the message displays like ( Connecting you to the
                customer... Please wait ):<br>
                In this case, the agent need to wait for 1 min to receive the incoming call. If he/she is not able to
                receive any incoming call, then Click on "STOP" & "START".<br>
                Even then, the incoming call is not landing then inform to Tech team..</p>
        </div>
    </div>


    <h1 class="sectionHeading">Section 5. Incoming call not landing</h1>

    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>1. Are you sure you are using the correct version of the APK?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent face (Incoming call was not answered / we were not able to reach you. Please Start again when
                you are ready):<br>
                The agent need to check the Signal Strength & Internet
                bandwidth.(https://play.google.com/store/apps/details?id=org.zwanoo.android.speedtest
                https://play.google.com/store/apps/details?id=com.wilysis.cellinfolite(Agents need to check the SIM
                Signal Strength by using the link shared).<br>
                When the agent Hits on START, make sure he / she will not receive any incoming call in between. If any
                call is been answered, then this message pops up.<br>
                Even after following all the three points, then issue should be upraised to Tech team.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>2. Are you facing the issue only today?Was everything fine till today?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent face (Incoming call was not answered / we were not able to reach you. Please Start again when
                you are ready):<br>
                The agent need to check the Signal Strength & Internet
                bandwidth.(https://play.google.com/store/apps/details?id=org.zwanoo.android.speedtest
                https://play.google.com/store/apps/details?id=com.wilysis.cellinfolite(Agents need to check the SIM
                Signal Strength by using the link shared).<br>
                When the agent Hits on START, make sure he / she will not receive any incoming call in between. If any
                call is been answered, then this message pops up.<br>
                Even after following all the three points, then issue should be upraised to Tech team.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>3. Are you using True caller or any other call tracking related apps?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent face (Incoming call was not answered / we were not able to reach you. Please Start again when
                you are ready):<br>
                The agent need to check the Signal Strength & Internet
                bandwidth.(https://play.google.com/store/apps/details?id=org.zwanoo.android.speedtest
                https://play.google.com/store/apps/details?id=com.wilysis.cellinfolite(Agents need to check the SIM
                Signal Strength by using the link shared).<br>
                When the agent Hits on START, make sure he / she will not receive any incoming call in between. If any
                call is been answered, then this message pops up.<br>
                Even after following all the three points, then issue should be upraised to Tech team.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>4. What is the memory (RAM) configuration on your mobile?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent face (Incoming call was not answered / we were not able to reach you. Please Start again when
                you are ready):<br>
                The agent need to check the Signal Strength & Internet
                bandwidth.(https://play.google.com/store/apps/details?id=org.zwanoo.android.speedtest
                https://play.google.com/store/apps/details?id=com.wilysis.cellinfolite(Agents need to check the SIM
                Signal Strength by using the link shared).<br>
                When the agent Hits on START, make sure he / she will not receive any incoming call in between. If any
                call is been answered, then this message pops up.<br>
                Even after following all the three points, then issue should be upraised to Tech team.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>5. Are you using mobile data or the official WiFi?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent face (Incoming call was not answered / we were not able to reach you. Please Start again when
                you are ready):<br>
                The agent need to check the Signal Strength & Internet
                bandwidth.(https://play.google.com/store/apps/details?id=org.zwanoo.android.speedtest
                https://play.google.com/store/apps/details?id=com.wilysis.cellinfolite(Agents need to check the SIM
                Signal Strength by using the link shared).<br>
                When the agent Hits on START, make sure he / she will not receive any incoming call in between. If any
                call is been answered, then this message pops up.<br>
                Even after following all the three points, then issue should be upraised to Tech team.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>6.What is the Android version (is it 9.0)?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent face (Incoming call was not answered / we were not able to reach you. Please Start again when
                you are ready):<br>
                The agent need to check the Signal Strength & Internet
                bandwidth.(https://play.google.com/store/apps/details?id=org.zwanoo.android.speedtest
                https://play.google.com/store/apps/details?id=com.wilysis.cellinfolite(Agents need to check the SIM
                Signal Strength by using the link shared).<br>
                When the agent Hits on START, make sure he / she will not receive any incoming call in between. If any
                call is been answered, then this message pops up.<br>
                Even after following all the three points, then issue should be upraised to Tech team.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>7.Can you please share the Internet speed on your mobile?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent face (Incoming call was not answered / we were not able to reach you. Please Start again when
                you are ready):<br>
                The agent need to check the Signal Strength & Internet
                bandwidth.(https://play.google.com/store/apps/details?id=org.zwanoo.android.speedtest
                https://play.google.com/store/apps/details?id=com.wilysis.cellinfolite(Agents need to check the SIM
                Signal Strength by using the link shared).<br>
                When the agent Hits on START, make sure he / she will not receive any incoming call in between. If any
                call is been answered, then this message pops up.<br>
                Even after following all the three points, then issue should be upraised to Tech team.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>8.Did you get any incoming call once you hit START button?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When agent face (Incoming call was not answered / we were not able to reach you. Please Start again when
                you are ready):<br>
                The agent need to check the Signal Strength & Internet
                bandwidth.(https://play.google.com/store/apps/details?id=org.zwanoo.android.speedtest
                https://play.google.com/store/apps/details?id=com.wilysis.cellinfolite(Agents need to check the SIM
                Signal Strength by using the link shared).<br>
                When the agent Hits on START, make sure he / she will not receive any incoming call in between. If any
                call is been answered, then this message pops up.<br>
                Even after following all the three points, then issue should be upraised to Tech team.</p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>9.Run me through the complete process you are following?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>

        <div class="panel">
            <p><span>Solution:</span>
                When agent face (Incoming call was not answered / we were not able to reach you. Please Start again when
                you are ready):<br>
                The agent need to check the Signal Strength & Internet
                bandwidth.(https://play.google.com/store/apps/details?id=org.zwanoo.android.speedtest
                https://play.google.com/store/apps/details?id=com.wilysis.cellinfolite(Agents need to check the SIM
                Signal Strength by using the link shared).<br>
                When the agent Hits on START, make sure he / she will not receive any incoming call in between. If any
                call is been answered, then this message pops up.<br>
                Even after following all the three points, then issue should be upraised to Tech team.</p>
        </div>
    </div>

    <h1 class="sectionHeading">Section 6. Auto call disconnect</h1>

    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>1.Are you facing Auto call disconnect?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                When the call gets disconnect within 0 to 10 secs:<br>
                Update it to Tech team immediately.</p>
        </div>
    </div>

    <h1 class="sectionHeading">Section 7. Back to Back calls</h1>

    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>1.Is this a frequently occurring issue</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                While having a conversation with a customer, if you receive any second incoming call:<br>
                Don't receive the second call unless you finish the conversation with the first customer.<br>
                Make sure the agent is not hitting "START and STOP" multiple times, when the agent selects 'START', then
                he/she need to wait for a min for an incoming call to land and not select 'START, STOP' multiple times..
            </p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>2.Don’t receive the 2nd call unless you dispose the first call</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                While having a conversation with a customer, if you receive any second incoming call:<br>
                Don't receive the second call unless you finish the conversation with the first customer.<br>
                Make sure the agent is not hitting "START and STOP" multiple times, when the agent selects 'START', then
                he/she need to wait for a min for an incoming call to land and not select 'START, STOP' multiple times..
            </p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>3.Are you hitting START/STOP buttons multiple times?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                While having a conversation with a customer, if you receive any second incoming call:<br>
                Don't receive the second call unless you finish the conversation with the first customer.<br>
                Make sure the agent is not hitting "START and STOP" multiple times, when the agent selects 'START', then
                he/she need to wait for a min for an incoming call to land and not select 'START, STOP' multiple times..
            </p>
        </div>
    </div>

    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>4.Are you disposing the current call correctly</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                While having a conversation with a customer, if you receive any second incoming call:<br>
                Don't receive the second call unless you finish the conversation with the first customer.<br>
                Make sure the agent is not hitting "START and STOP" multiple times, when the agent selects 'START', then
                he/she need to wait for a min for an incoming call to land and not select 'START, STOP' multiple times..
            </p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>5.Run me through the complete process you are following</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                While having a conversation with a customer, if you receive any second incoming call:<br>
                Don't receive the second call unless you finish the conversation with the first customer.<br>
                Make sure the agent is not hitting "START and STOP" multiple times, when the agent selects 'START', then
                he/she need to wait for a min for an incoming call to land and not select 'START, STOP' multiple times..
            </p>
        </div>
    </div>
    <h1 class="sectionHeading">Section 8. Get Info issue</h1>

    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>1.Can you please tell me the customer number you are connecting to?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If agent is not able to view his 'get info' screen:<br>
                Agent need to observe the incoming number and inform the Tech team.

            </p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>2.Are you able to speak to the customer and still not able to see his details in the screen?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If agent is not able to view his 'get info' screen:<br>
                Agent need to observe the incoming number and inform the Tech team.

            </p>
        </div>
    </div>
    <h1 class="sectionHeading">Section 9. ACP form</h1>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>1.Do you see any error displayed in the disposition screen?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                Specific Issue (When agent comes across this error)<br>
                Check the Android version and the Incoming number.<br>
                If issue still persists, then check with Tech team.

            </p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>2.Did you see something unusual during the entire calling process for this customer?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                Specific Issue (When agent comes across this error)<br>
                Check the Android version and the Incoming number.<br>
                If issue still persists, then check with Tech team.

            </p>
        </div>
    </div>
    <h1 class="sectionHeading">Section 10. Could not reach your number at this moment....</h1>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>1.How is the signal strength in your mobile</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If the agent's number is a prepaid connection, he has to ensure that the account has minimum balance in
                the account to receive incoming calls<br>
                For JIO sims, incoming calls dont land in the first attempt<br>
                If the signal coverage is less, restarting the phone might help in improving the good network
                coverage<br>
                If the agent has blocked the incoming call from the specific numbers, check if this no. is in the
                blocked list. If yes, then he will not get the incoming calls<br>
                The no. given to Smarterbiz for registration should be in the same handset in which UEarn is installed.

            </p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>2.Are you using JIO sim?</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If the agent's number is a prepaid connection, he has to ensure that the account has minimum balance in
                the account to receive incoming calls<br>
                For JIO sims, incoming calls dont land in the first attempt<br>
                If the signal coverage is less, restarting the phone might help in improving the good network
                coverage<br>
                If the agent has blocked the incoming call from the specific numbers, check if this no. is in the
                blocked list. If yes, then he will not get the incoming calls<br>
                The no. given to Smarterbiz for registration should be in the same handset in which UEarn is installed.

            </p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>3.Are you having a prepaid connection</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If the agent's number is a prepaid connection, he has to ensure that the account has minimum balance in
                the account to receive incoming calls<br>
                For JIO sims, incoming calls dont land in the first attempt<br>
                If the signal coverage is less, restarting the phone might help in improving the good network
                coverage<br>
                If the agent has blocked the incoming call from the specific numbers, check if this no. is in the
                blocked list. If yes, then he will not get the incoming calls<br>
                The no. given to Smarterbiz for registration should be in the same handset in which UEarn is installed.

            </p>
        </div>
    </div>
    <div class="mainDiv">
        <table class="wd-100 accordion">
            <tr>
                <td>
                    <p>4.Have you blocked any numbers from receiving incoming calls</p>
                </td>
                <td class="text-right">
                    <span class="caretDown"> <i class="fa fa-angle-down"></i></span>
                </td>
            </tr>
        </table>
        <div class="panel">
            <p><span>Solution:</span>
                If the agent's number is a prepaid connection, he has to ensure that the account has minimum balance in
                the account to receive incoming calls<br>
                For JIO sims, incoming calls dont land in the first attempt<br>
                If the signal coverage is less, restarting the phone might help in improving the good network
                coverage<br>
                If the agent has blocked the incoming call from the specific numbers, check if this no. is in the
                blocked list. If yes, then he will not get the incoming calls<br>
                The no. given to Smarterbiz for registration should be in the same handset in which UEarn is installed.

            </p>
        </div>
    </div>

    <div>

        <div class="row">
           
            <div class="col-md-12 col-sm-12">
                <h2 class="b-title">Smarter BIZ Support</h2>
                <p>Have more questions? Please ask us here.</p>
            </div>
        </div>
        <p *ngIf="showHideMessage" [ngStyle]="{'color':msgColor}">{{messageDisplay}}</p>
        <form [formGroup]="createdForm">

            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <table class="wd-100">
                        <tr>
                            <td>
                                <div class="input-label-group">
                                    <label>Your Name*</label>
                                    <input matInput autocomplete="off" placeholder="Your Name"  (keypress)="omit_special_char($event)"  formControlName="name" required>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="input-label-group">
                                    <label>Email*</label>
                                    <input matInput autocomplete="off" placeholder="Email"   formControlName="email" required>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="input-label-group">
                                    <label>Mobile Number*</label>
                                    <input matInput autocomplete="off" maxlength="10" placeholder="Mobile Number"  (keypress)="onlyNumberValidation($event)"  formControlName="mobileNo" required>
                                </div>

                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="input-label-group">
                                    <label>Subject*</label>
                                    <input matInput autocomplete="off" placeholder="Enter Subject" formControlName="subject" required>
                                </div>

                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="input-label-group">
                                    <label>Query*</label>
                                    <input matInput autocomplete="off" placeholder="Enter your query here" formControlName="query" required>
                                </div>

                            </td>
                        </tr>
                    </table>
                </div>

            </div>
        </form>
        <div class="row">
            <div class="col-md-3 col-sm-12">
                <button class="JoinSubmit"  (click)="submitFaqBtn(createdForm)">Submit</button>
            </div>
        </div>
    </div>

</div>

</html>