
<table width="100%">
    <tr style="border-bottom:1px solid #ccc;">
        <td><b style="color: #224e84;font-size: 14px;"> Add Agents</b> </td>
        <td><span  class="pl-3" style="color: gray;font-size: 12px;">Last refreshed on </span>  
            <span  class="pl-3" style="color: #224e84;font-size: 12px;">{{_lastRefreshDate}}</span>  </td>
        <td colspan="2" class="text-right">
            <mat-form-field>
              <input matInput (input)="changeSearch($event.target.value)" placeholder="Search"/>
            </mat-form-field>
           
          </td>
        <td style="text-align: right;">
            <i class="fa fa-times-circle closeicon" aria-hidden="true" (click)="closePopup()"></i>
        </td>
    </tr>
    </table>
    <p class="textAlertMessage" [ngStyle]="{color:greencss}" *ngIf="showHide_messageAlert">{{messageAlert}}</p>
    <div  class="C-scroll">
        <!-- *ngIf="campaign_newAgents.length && input_obj.active_status" -->

<div *ngIf="campaign_newAgents.length && input_obj.active_status">
    <table width="100%">
        <!-- <tr><td><b> Add agents</b> </td><td> Click to add more agents to the campaign - {{input_obj['campaign']}} </td></tr>
      <tr><td colspan="2" class="text-right">
          <mat-form-field>
            <input matInput (input)="changeSearch($event.target.value)" placeholder="Search"/>
          </mat-form-field>
         
        </td></tr> -->
        <tr><td colspan="2" style="padding:10px;" class="text-right"><span style="color:gray">Selected Agents -</span> {{getSelectedAgentCount()}}</td></tr>
        <tr><td colspan="2"> 
            <section class="add-agent-section">
                <span class="add-agent-list-section">
                    <mat-checkbox class="add-agent-margin" [checked]="selected_all"
                        [indeterminate]="someSelected()" (change)="setAll($event.checked)">
                        Select All Agent
                    </mat-checkbox>
                </span>
                <span class="add-agent-list-section">
                    <ul style="position: relative;left: -27px;">
                        <li *ngFor="let agent of campaign_newAgents">
                            <mat-checkbox [(ngModel)]="agent.add_selected"
                                (ngModelChange)="updateAllSelected()">
                                {{agent.name}}
                            </mat-checkbox>
                        </li>
                    </ul>
                </span>
            </section>
            <button *ngIf="someSelected() || selected_all" mat-stroked-button
                (click)="addAgents_tocampaign()">Add Agents</button>

        </td>
        
    </tr>
    </table>
</div>




            <!-- <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Add agents
                    </mat-panel-title>
                    <mat-panel-description>
                        Click to add more agents to the campaign - {{input_obj['campaign']}}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <section class="add-agent-section">
                    <span class="add-agent-list-section">
                        <mat-checkbox class="add-agent-margin" [checked]="selected_all"
                            [indeterminate]="someSelected()" (change)="setAll($event.checked)">
                            Select All
                        </mat-checkbox>
                    </span>
                    <span class="add-agent-list-section">
                        <ul>
                            <li *ngFor="let agent of campaign_newAgents">
                                <mat-checkbox [(ngModel)]="agent.add_selected"
                                    (ngModelChange)="updateAllSelected()">
                                    {{agent.name}}
                                </mat-checkbox>
                            </li>
                        </ul>
                    </span>
                </section>
                <button *ngIf="someSelected() || selected_all" mat-stroked-button
                    (click)="addAgents_tocampaign()">Add Agents</button>
            </mat-expansion-panel> -->
       
</div>
