import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { ApiService } from 'src/app/api.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SEOService } from 'src/app/canonical_service';

@Component({
  selector: 'app-new-contiinex-whatsapp-api-page',
  templateUrl: './new-contiinex-whatsapp-api-page.component.html',
  styleUrls: ['./new-contiinex-whatsapp-api-page.component.scss']
})
export class NewContiinexWhatsappApiPageComponent implements OnInit {

  myTitle = 'Contiinex | WhatsApp Business API';
  constructor(private seoService: SEOService,private metaTagService: Meta, private titleService: Title,private router:Router,private API : ApiService,protected $gaService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.seoService.updateCanonicalUrl('https://www.contiinex.com/whatsapp-business-api');
    this.titleService.setTitle(this.myTitle);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Connect with your customer through the WhatsApp Business Messaging APIs'},"name='description'"
    );
    this.metaTagService.updateTag(
      { name: 'image', content: 'https://www.contiinex.com/assets/image/wb-banner-img.png'},"name='image'"
    );
    this.metaTagService.updateTag(
      { name: 'url', content: 'https://www.contiinex.com/whatsapp-business-api'},"name='url'"
    );
    this.metaTagService.updateTag(
      { property: 'og:title', content: this.myTitle },"property='og:title'"
    );
    this.metaTagService.updateTag(
      { property: 'og:description', content: 'Connect with your customer through the WhatsApp Business Messaging APIs'}, "property='og:description'"
    );
    this.metaTagService.updateTag(
      { property: 'og:image', content: 'https://www.contiinex.com/assets/image/wb-banner-img.png'}, "property='og:image'"
    );
    this.metaTagService.updateTag(
      { property: 'og:url', content: 'https://www.contiinex.com/whatsapp-business-api'}, "property='og:url'"
    );
    this.metaTagService.updateTag(
      { name: 'twitter:image', content: 'https://www.contiinex.com/assets/image/wb-banner-img.png'}, "name='twitter:image'"
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: 'Connect with your customer through the WhatsApp Business Messaging APIs'}, "name='twitter:description'"
    );
    this.metaTagService.updateTag(
        { itemprop: 'description', content: 'Connect with your customer through the WhatsApp Business Messaging APIs'},"itemprop='description'"
    );
    this.metaTagService.updateTag(
        { itemprop: 'image', content: 'https://www.contiinex.com/assets/image/wb-banner-img.png'},"itemprop='image'"
    );
    this.metaTagService.updateTag(
        { itemprop: 'url', content: 'https://www.contiinex.com/whatsapp-business-api'},"itemprop='url'"
    );
    this.metaTagService.updateTag(
        { itemprop: 'name', content: this.myTitle },"itemprop='name'"
    );
  }
  openRegisterPage(type:any){
    if(type=="banner"){
      this.$gaService.event('Clicked Register From Banner Section', 'WhatsApp Business Api Page');
    }
    if(type=="easySteps"){  
    this.$gaService.event('Clicked Register From Easy Steps Section', 'WhatsApp Business Api Page');
    }
    if(type=="signupText"){
    this.$gaService.event('Clicked Register From Signup Text Section', 'WhatsApp Business Api Page');
    }
    let _DefaultProductValue="WABA";
    this.API.sendDefaultProductValue(_DefaultProductValue);
    this.router.navigateByUrl('/Register');
 
  }
}
