import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-call-checkbox',
  templateUrl: './call-checkbox.component.html',
  styleUrls: ['./call-checkbox.component.scss']
})
export class CallCheckboxComponent implements OnInit {
  
  @Input()
  public updateCheckbox: Function;
    @Input()
  public removeSubSection: Function;
  @Input()
  public untitleToggle: Function;
  @Input() selectedIndex: string;
@Input() selectedChildIndex: string;
  @Input() selectedLevel: any;
  @Input() radioIndex: any;
@Input() checked: any;
  
dynamicArray:Array<DynamicGrid> = [];   
newDynamic: any = {};  
ngOnInit() {  
  this.newDynamic = "option 1";
    this.dynamicArray.push(this.newDynamic);
    this.updateCheckbox(this.dynamicArray, this.selectedIndex, this.selectedLevel,this.radioIndex,this.selectedChildIndex) 
}  
customTrackBy(index: number, obj: any): any {
  return index;
}
addRow() {    
  this.newDynamic =  "option "+(this.dynamicArray.length + 1);
    this.dynamicArray.push(this.newDynamic);
    this.updateCheckbox(this.dynamicArray, this.selectedIndex, this.selectedLevel,this.radioIndex,this.selectedChildIndex)
    return true;  
}  
  
deleteRow(index) {  
    if(this.dynamicArray.length ==1) { 
        return false;  
    } else {  
        this.dynamicArray.splice(index, 1); 
        return true;  
    }  
}

actionType(val: any){
 // console.log(val);
}
modelChange(event, index){
  this.updateCheckbox(this.dynamicArray, this.selectedIndex, this.selectedLevel,this.radioIndex,this.selectedChildIndex)

}
trigerRemoveSubSection(){
  //console.log(this.selectedIndex)
  this.removeSubSection(this.selectedIndex,this.selectedChildIndex, this.selectedLevel,this.radioIndex)
}
triggerToggle(event){
  //console.log(this.selectedIndex)
  this.untitleToggle(this.selectedIndex,event, this.selectedLevel,this.selectedChildIndex, this.radioIndex)
}
}
export class DynamicGrid{     
  inputOne:any; 
} 