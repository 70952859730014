<div style="overflow: hidden auto;height: calc(100vh - 166px);">
	<div class="row">
		<div class="col-md-12">
			<table class="tbl1DD text-center">
				<tr style="background: #f2f2f2;">
					<td *ngIf="_pathCheckUrl !='/LC-Coordinator'"><b>Name </b> </td>
					<td *ngIf="_pathCheckUrl !='/LC-Coordinator'"><b>Phone </b> </td>
					<td *ngIf="_pathCheckUrl !='/LC-Coordinator'"><b>Email </b> </td>
					<td *ngIf="_pathCheckUrl =='/LC-Coordinator'"><b>Agent ID </b> </td>
				</tr>
				<tr>
					<td *ngIf="_pathCheckUrl !='/LC-Coordinator'"> {{viewRecord.name || '--'}}</td>
					<td *ngIf="_pathCheckUrl !='/LC-Coordinator'"> {{viewRecord.number | mask_number:'--'}}</td>
					<td *ngIf="_pathCheckUrl !='/LC-Coordinator'"> {{viewRecord.email || 'NA'}}</td>
					<td *ngIf="_pathCheckUrl =='/LC-Coordinator'"> {{viewRecord.email || 'NA'}}</td>
				</tr>
			</table>
		</div>

	</div>


	<div *ngIf="arrayCustomVs.length > 0">

		<div class="row mt-0">
			<div class="col-md-12" style="column-count: 2">
				<table class="tbl1DD ctbl1">
					<tr *ngFor="let b of arrayCustomVs">
						<td><b> {{getKey(b) | titlecase}} </b></td>
						<td>{{getValue(b)}}</td>
					</tr>
					<tr *ngIf="oddOrEven(arrayCustomVs.length)">
						<td>--</td>
						<td>--</td>
					</tr>
				</table>


			</div>
			<div class="col-md-12" *ngIf="renderArrayAddon1 && renderArrayAddon1.length>0">
				<div>
					<h4 class="text-center mt-2 mb-2">Add-on 1</h4>
					<table class="tbl1DD ctbl1">
						<tr *ngFor="let b1 of renderArrayAddon1">
							<td><b> {{getKey(b1) | titlecase}} </b></td>
							<td>{{getValue(b1)}}</td>
						</tr>
					</table>
				</div>
			</div>

			<div class="col-md-12" *ngIf="renderArrayAddon2 && renderArrayAddon2.length>0">
				<div>
					<h4 class="text-center mt-2 mb-2">Add-on 2</h4>
					<table class="tbl1DD ctbl1">
						<tr *ngFor="let b2 of renderArrayAddon2">
							<td><b> {{getKey(b2) | titlecase}} </b></td>
							<td>{{getValue(b2)}}</td>
						</tr>
					</table>
				</div>
			</div>
			<div class="col-md-12" *ngIf="renderArrayAddon3 && renderArrayAddon3.length>0">
				<div>
					<h4 class="text-center mt-2 mb-2">Add-on 3</h4>
					<table class="tbl1DD ctbl1">
						<tr *ngFor="let b2 of renderArrayAddon3">
							<td><b> {{getKey(b2) | titlecase}} </b></td>
							<td>{{getValue(b2) }}</td>
						</tr>
					</table>
				</div>
			</div>
			<div class="col-md-12" *ngIf="renderArrayAddon4 && renderArrayAddon4.length>0">
				<div>
					<h4 class="text-center mt-2 mb-2">Add-on 4</h4>
					<table class="tbl1DD ctbl1">
						<tr *ngFor="let b2 of renderArrayAddon4">
							<td><b> {{getKey(b2) | titlecase}} </b></td>
							<td>{{getValue(b2)}}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="vOkFx mt-4">

	<p class="text-right">
		<button mat-button class="action_btn" (click)="closeDialog('false')" style="margin-right: 18px;">CANCEL</button>
		<button mat-button class="action_btn" (click)="closeDialog('true')" *ngIf="_pathCheckUrl =='/agentPlugin/campaign-list'">CALL</button>
		<button mat-button class="action_btn" (click)="closeDialog('true')" *ngIf="_pathCheckUrl =='agentPlugin/contact-list'">CALL</button>
	</p>

</div>