import { Component, OnInit,ViewChild } from '@angular/core';
import { ContiinexAddTeamMemberDialogComponent } from '../contiinex-add-team-member-dialog/contiinex-add-team-member-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import {Router} from '@angular/router'
import {MyserviceService} from '../../myservice.service';
import {ApiService} from "../../api.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ContiinexShopifyTeamDeleteDialogComponent } from '../contiinex-shopify-team-delete-dialog/contiinex-shopify-team-delete-dialog.component';
@Component({
  selector: 'app-contiinex-shopify-team',
  templateUrl: './contiinex-shopify-team.component.html',
  styleUrls: ['./contiinex-shopify-team.component.scss']
})
export class ContiinexShopifyTeamComponent implements OnInit {
  currentLoader:boolean=false;
  displayed_columns = ['name', 'email', 'phone', 'job_profile','edit','delete'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  customer_name:any;

  api_end_point:any;
  currentTitle = 'shopify-team';
  currentUrl:any;
  constructor(private dialog: MatDialog, private API: ApiService, private _service:MyserviceService,private router: Router) { }

  ngOnInit() {  
    this.api_end_point = this._service.get_API_End_Point();
    this.currentUrl = this.router.url;
    if (this._service.get_loggedin_user() && this._service.get_loggedin_user().company) {
      this.customer_name = String(this._service.get_loggedin_user().company);
    }
    this.listView();
  }

listView(){
 // debugger;
 this.currentLoader=true;
  this.API.getSopifyAgentTeamList(this.customer_name, this.api_end_point).subscribe((res: any) => 
  {this.currentLoader=false;
let tableData=res;
this.dataSource = new MatTableDataSource(tableData);
this.dataSource.paginator = this.paginator;
  });
}
  addTeamMember(){
    const dialogRef = this.dialog.open(ContiinexAddTeamMemberDialogComponent, {
       width:'500px',
      height:'auto',
      disableClose: true,
      data: "",
    });

    dialogRef.afterClosed().subscribe(result => {
     // console.log('The dialog was closed', result);
      if(result=="true"){
      //  alert('Result Success');
        this.listView();
      }
      else{
      //  alert('false')
      }
    });
  }
  editBtn(element){
    const dialogRef = this.dialog.open(ContiinexAddTeamMemberDialogComponent, {
      width:'500px',
     height:'auto',
     disableClose: true,
     data:element,
   });

   dialogRef.afterClosed().subscribe(result => {
    // console.log('The dialog was closed', result);
     if(result=="true"){
      this.listView();
      //  alert('Result Success');
     }
     else{
     //  alert('false')
     }
   });
  }

  deleteDialog(element){
    const dialogRef = this.dialog.open(ContiinexShopifyTeamDeleteDialogComponent, {
      width:'360px',
     height:'auto',
     disableClose: true,
     data:element,
   });

   dialogRef.afterClosed().subscribe(result => {
    // console.log('The dialog was closed', result);
     if(result=="true"){
       this.listView();
     }
     else{
     //  alert('false')
     }
   });
  }
}
