import { Component, OnInit,ElementRef, HostListener, } from '@angular/core';
import {Router} from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/api.service';
import { Title, Meta } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SEOService } from 'src/app/canonical_service';

@Component({
  selector: 'app-ne-contiinex-comnex-enable-page',
  templateUrl: './ne-contiinex-comnex-enable-page.component.html',
  styleUrls: ['./ne-contiinex-comnex-enable-page.component.scss']
})
export class NeContiinexComnexEnablePageComponent implements OnInit {
  myTitle = 'Contiinex | Comnex Enable';
  constructor(private seoService: SEOService,private metaTagService: Meta,private titleService:Title,private router:Router,private location: Location,private API : ApiService,protected $gaService: GoogleAnalyticsService) { 
    window.scroll(0,0);
  }

  ngOnInit() {
    this.seoService.updateCanonicalUrl('https://www.contiinex.com/Comnex-Enable');
    this.titleService.setTitle(this.myTitle);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Unified Contact Center Automation Platform Next Gen call center automation technology that enables you to manage a complete employee life cycle in a single platform. Ensures you save on OpEx & helps you focus on increasing on operational agility, adhering Compliance.'},"name='description'"
    );
    this.metaTagService.updateTag(
      { name: 'url', content: 'https://www.contiinex.com/Comnex-Enable'},"name='url'"
    );

    this.metaTagService.updateTag(
      { property: 'og:title', content: this.myTitle },"property='og:title'"
    );
    this.metaTagService.updateTag(
      { property: 'og:description', content: 'Unified Contact Center Automation Platform Next Gen call center automation technology that enables you to manage a complete employee life cycle in a single platform. Ensures you save on OpEx & helps you focus on increasing on operational agility, adhering Compliance.'}, "property='og:description'"
    );
    this.metaTagService.updateTag(
      { property: 'og:url', content: 'https://www.contiinex.com/Comnex-Enable'}, "property='og:url'"
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: 'Unified Contact Center Automation Platform Next Gen call center automation technology that enables you to manage a complete employee life cycle in a single platform. Ensures you save on OpEx & helps you focus on increasing on operational agility, adhering Compliance.'}, "name='twitter:description'"
    );
    this.metaTagService.updateTag(
      { itemprop: 'description', content: 'Unified Contact Center Automation Platform Next Gen call center automation technology that enables you to manage a complete employee life cycle in a single platform. Ensures you save on OpEx & helps you focus on increasing on operational agility, adhering Compliance.'},"itemprop='description'"
  );
  this.metaTagService.updateTag(
      { itemprop: 'url', content: 'https://www.contiinex.com/Comnex-Enable'},"itemprop='url'"
  );
  this.metaTagService.updateTag(
      { itemprop: 'name', content: this.myTitle },"itemprop='name'"
  );
  }
  bookDemoBtn(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  this.router.navigateByUrl('/Contact');
  }
  openKnowMore(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  this.router.navigateByUrl('/Contact');
  }
}
