<mat-card class="pCard">
    <div class="row rowBdrBtm" *ngIf="showHideNoHistotyMsg">
      <div class="col-md-12 mt-2"><span class="recName1"
          style="color:red;font-size:13px;font-weight:500">{{NoHistoty}}</span> </div>
    </div>
    <div class="row rowBdrBtm">
      <div class="col-md-2 mt-2"><span class="recName1">Events List</span> </div>
   
      <div class="col-md-3 mt-2">
        <mat-select (selectionChange)="changeYear($event.value)" [ngClass]="'arrowcsDDL'" class="dropdownDDL"
          placeholder="Select" [(value)]="selectedCurYear">
          <mat-option value="2021">2021</mat-option>
          <mat-option value="2020">2020</mat-option>
          <mat-option value="2019">2019</mat-option>
          <mat-option value="2018">2018</mat-option>
          <mat-option value="2017">2017</mat-option>
          <mat-option value="2016">2016</mat-option>
          <mat-option value="2015">2015</mat-option>
        </mat-select>
  
        <mat-select (selectionChange)="changeMonth($event.value)" [ngClass]="'arrowcsDDL'" class="dropdownDDL"
          placeholder="Select" [(value)]="selectedMonth">
          <mat-option value="Jan">Jan</mat-option>
          <mat-option value="Feb">Feb</mat-option>
          <mat-option value="Mar">Mar</mat-option>
          <mat-option value="Apr">Apr</mat-option>
          <mat-option value="May">May</mat-option>
          <mat-option value="Jun">Jun</mat-option>
          <mat-option value="Jul">Jul</mat-option>
          <mat-option value="Aug">Aug</mat-option>
          <mat-option value="Sep">Sep</mat-option>
          <mat-option value="Oct">Oct</mat-option>
          <mat-option value="Nov">Nov</mat-option>
          <mat-option value="Dec">Dec</mat-option>
        </mat-select>
      </div>
      <div class="col-md-4 mt-0">
          <button class="approval-btn-ui" style="background: #f9f8f8;color: #fd6c21;" (click)="refreshBtn()">Refresh</button> 
        <mat-form-field class="ml-2">
          <input matInput #searchInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
          <i matSuffix class="fa fa-search searchicon" style="color: #d5d5d5;" aria-hidden="true"></i>
        </mat-form-field>
      </div>

      <div class="col-md-3 mt-2">
        <button class="app-btn-ui" style="width:auto;padding: 3px 15px;border: 1.6px solid #fd6c21;" (click)="uploadExcelBtn()"><i class="fa fa-upload" aria-hidden="true"></i>&nbsp;&nbsp; Upload Excel </button>
      </div>




    </div>
    <div>
      <mat-table class="matTableCs" [dataSource]="dataSource">
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="owner">
          <mat-header-cell *matHeaderCellDef> Owner </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.owner}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fileName">
          <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.fileName}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="file_type">
          <mat-header-cell *matHeaderCellDef> File Type </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.file_type}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="url">
          <mat-header-cell *matHeaderCellDef> Download </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="viewMore" (click)="download_file(element)"><i class="fa fa-download" aria-hidden="true"></i></span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>
  <div id="cover-spin" *ngIf="currentLoader">
	<mat-spinner strokeWidth="3" [diameter]="100"></mat-spinner>
</div>
  