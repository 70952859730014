import {
    Invitation,
    Inviter,
    InviterOptions,
    Referral,
    Registerer,
    RegistererOptions,
    Session,
    SessionState,
    UserAgent,
    UserAgentOptions,
    InvitationAcceptOptions,
	Web
} from "../../assets/sip.js";

import * as moment from "moment";


export class SIPConnection {
    public static connection_status : boolean = false;
    public static sip_connected : boolean = false;
    public static call_terminated : boolean = false;
    public static call_answered : boolean = false;
    public static call_disconnected : boolean = false;
    public static SIP_USER_INFO : any;
	public static incomingSession: Session = {};
    public static userAgent: any = {};
    checkC2CExitForSIP: any;
    callStatus:any;
    public static callTerminateStatus:any;
    constructor(SIP_USER) {
        console.log("--SIP Connection constructor  desktop-calling", SIP_USER);
        SIPConnection.SIP_USER_INFO = SIP_USER;
        function test_sip_connection(timeout){
            setTimeout(()=>{
                if(SIPConnection.sip_connected){
                    let status = SIPConnection.userAgent.isConnected();
                   // console.log("SIP Connection Status : ",status);
                    if(status){
                        SIPConnection.connection_status = true;
                        test_sip_connection(1000*5);
                    }else{
                        SIPConnection.connection_status = false;
                    }
                }else{
                    test_sip_connection(1000*5);
                }
            },timeout);
        }
        function check_sip_connection(timeout){
            setTimeout(()=>{
                if(SIPConnection.connection_status){
                    check_sip_connection(1000*10);
                }else{
                    SIPConnection.createConnection(SIP_USER);
                    test_sip_connection(1000*10);
                    check_sip_connection(1000*60);
                }
            },timeout);
        }
        // check_sip_connection(1000*1);
        SIPConnection.createConnection(SIP_USER);
    }
    public static createConnection(SIP_USER){

        console.log('createConnection', SIP_USER);
        // let displayName = "SIP.js Demo";
        let displayName = "Demo Agent";
        //let displayName = SIP_USER.sip_auth;
        let caller_address = "sip:"+SIP_USER.sip_auth;
        let sip_server = 'wss://'+SIP_USER.sip_domain;

        //   let simpleUserDelegate: Web.SimpleUserDelegate = {
        //       onCallCreated: (): void => {
        //         console.log(`[${displayName}] Call created`);
        //
        //       },
        //       onCallReceived: (): void => {
        //           console.log(`[${displayName}] Call received`);
        //       },
        //       onCallAnswered: (): void => {
        //           console.log(`[${displayName}] Call answered`);
        //       },
        //       onCallHangup: (): void => {
        //           console.log(`[${displayName}] Call hangup`);
        //       },
        //       onCallHold: (held: boolean): void => {
        //           console.log(`[${displayName}] Call hold ${held}`);
        //       },
        //       onWebSocketClose: (error:any): void =>{
        //           SIPConnection.connection_status = false;
        //           console.log(`[${displayName}] onWebSocketClose ${error}`);
        //       }
        // };
        //   let SIP_User_Options = Web.SimpleUserOptions = {
        //       aor: caller_address, // caller
        //       media: {
        //           constraints: { audio: true, video: false }, // audio only call
        //           remote: { audio: SIP_USER.player } // play remote audio
        //       }
        //   };
        let transportOptions = {
            server: sip_server,
            maxReconnectionAttempts: 10,
            reconnectionTimeout:10,
            traceSip: true
        };
        let uri = UserAgent.makeURI("sip:" + SIP_USER.sip_user);
        const userAgentOptions: UserAgentOptions = {
            authorizationPassword: SIP_USER.sip_password,
            authorizationUsername: SIP_USER.sip_auth,
            transportOptions,
            uri,
			      log: { builtinEnabled: false }
        };
        const userAgent = new UserAgent(userAgentOptions);
        userAgent.delegate = {
            onInvite(invitation: Invitation): void {
                // An Invitation is a Session
                SIPConnection.incomingSession = invitation;
                console.log("---SIPConnection.incomingSession--",SIPConnection.incomingSession);
                // Setup incoming session delegate
                SIPConnection.incomingSession.delegate = {
                    // Handle incoming REFER request.
                    onRefer(referral: Referral): void {
                        // ...
                    }
                };

                const remoteStream = new MediaStream();
                function setupRemoteMedia(session: Session) {
                    console.log('MediaSession', session);
                    session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
                        if (receiver.track) {
                            remoteStream.addTrack(receiver.track);
                        }
                    });
                    SIP_USER.player.srcObject = remoteStream;
                    SIP_USER.player.play();
                }
                SIP_USER.subscription = SIP_USER.myservice.getCallStatus().subscribe(obj => {

					SIP_USER.callStatus = obj;
					console.log("sp-log-debug5",obj);
                    console.log("SipConnection :: 6 ");
                    // if (obj) {



                    //     // console.log(this2.allStatus.includes(this2.callStatus.status))
                    //     if (obj.status == 'end_call_agent') {
                    //         //debugger;
					// 		const currentTime = moment().utc();
                    //         let salesSts = {
                    //             user_id: SIP_USER.user.id,
                    //             contact_person: {
                    //                 number: "",
                    //                 name: ""
                    //             },
                    //             transactionid: "",
                    //             description: "",
                    //             order_potential: "",
                    //             status: "NEW DATA",
                    //             lat: "22.0858725",
                    //             long: "82.1879959",
                    //             start_time: obj.callStart || currentTime,
                    //             substatus1: "",
                    //             substatus2: "",
                    //             machine_status: "",
                    //             wrapup: 0,
                    //             lead_source: '',
                    //             appointment_id: "",
                    //             call_end_time: obj.callEnd || currentTime,
                    //             customkvs: ""
                    //         } as any;
					// 		if (salesSts.start_time != salesSts.call_end_time) {
					// 			salesSts.wrapup = salesSts.call_end_time - salesSts.start_time;
					// 		}
                    //         if(SIP_USER.callStatus.customerInfo){
                    //             salesSts.contact_person.number = SIP_USER.callStatus.customerInfo.customer_number;
                    //             salesSts.contact_person.name = SIP_USER.callStatus.customerInfo.customer_name;
                    //             salesSts.transactionid = SIP_USER.callStatus.customerInfo.transactionid;
                    //         }

                    //         if (SIP_USER.callStatus.callFlowJsonSections) {
                    //             let finalObj = {
                    //                 experience: SIP_USER.callStatus.QA
                    //             };
                    //             salesSts.extranotes = JSON.stringify(finalObj);
                    //         }
                    //         SIP_USER.salesSts = salesSts;
                    //         let _pdCheck = localStorage.getItem("checkPredictiveOnOff");
                    //         let _stayAtHomeCheck = localStorage.getItem("checkStayatHomeOnOff");
                    //         let _getC2CAvailble = localStorage.getItem("c2cCallPresent");
					// 		if(this._custNameDetailsRecord){
                    //         this.checkC2CExitForSIP=this._custNameDetailsRecord.checkC2C;
					// 		}
                    //         console.log("SipConnection :: 5 ");
                    //         console.log("details :: 3 : "+_pdCheck + " : "+_stayAtHomeCheck + " : "+_getC2CAvailble);
                    //          SIP_USER.myservice.clearCallStatus();
                    //         if((_pdCheck == "false" && _stayAtHomeCheck == "true") || _getC2CAvailble == "availableC2C"){
                    //             console.log("SipConnection :: 1 ");
                    //         SIP_USER.subscription.unsubscribe();
                    //         }

                    //         // let interval_id = window.setInterval(() => { }, 99999);
                    //         // for (let i = 0; i < interval_id; i++) {
                    //         //     window.clearInterval(i);
                    //         // }

                    //     }
                    //     console.log("status :: 9 : "+ obj.status + " : "+SIPConnection.call_terminated);
                    //     if (obj.status == 'end_call_agent' && !SIPConnection.call_terminated) {
                    //       // debugger;
                    //         let _pdCheck = localStorage.getItem("checkPredictiveOnOff");
                    //         let _stayAtHomeCheck = localStorage.getItem("checkStayatHomeOnOff");
                    //         let _getC2CAvailble = localStorage.getItem("c2cCallPresent");
					// 		if(this._custNameDetailsRecord){
                    //         this.checkC2CExitForSIP=this._custNameDetailsRecord.checkC2C;
					// 		}
                    //         console.log("details :: 33 : "+_pdCheck + " : "+_stayAtHomeCheck + " : "+_getC2CAvailble);
                    //         if((_pdCheck == "false" && _stayAtHomeCheck == "true") || _getC2CAvailble == "availableC2C"){
                    //             console.log("SipConnection :: 2 ");
                    //             if(SIPConnection.callTerminateStatus != "Terminated"){
                    //                 //SIPConnection.incomingSession.bye();
                    //             }

                    //         }

                    //         SIPConnection.call_terminated = true;
                    //         SIPConnection.call_answered = false;
                    //     }
                    // } else {}
                });
                // Handle incoming session state changes.
                SIPConnection.incomingSession.stateChange.addListener((newState: SessionState) => {
					let updateObj = SIP_USER.myservice.getLastCallStatus() || {};
					//console.log("sp-debug2", updateObj)
                    console.log("SIPConnection newstate :: ", newState)
                    switch(newState) {
                        case SessionState.Establishing:
                            // Session is establishing.

                            SIPConnection.call_terminated = false;
                            SIP_USER.showHideCallingScreen = true;
                            let stateSettings: any = SIP_USER.myservice.get_stage_settings();
                            if (stateSettings && stateSettings.extraoptions) {
                                for (let opt of stateSettings.extraoptions) {
                                    if (opt.name == "questionnaire") {
                                        let questions = JSON.parse(opt.definition)
                                        let callFlowJsonSections = [{ title: "", description: "", mandatory: "n", child: questions }];
                                        updateObj.callFlowJsonSections = callFlowJsonSections;
                                    }
                                }
                            }
                            if (updateObj && updateObj.dialing && updateObj.dialing == 3) {
                                updateObj.dialing = undefined;
                                updateObj.dialNum = undefined;
                            }
                            updateObj.status = 'establishing';
                            updateObj.callStart = moment().utc();
							//console.log("sp-debug3", updateObj)
                            SIP_USER.myservice.updateCallStatus(updateObj);
                            SIP_USER.counter = 0;
                            // this.startCallDial();
                            break;
                        case SessionState.Established:
                            SIPConnection.call_answered = true;
                            SIP_USER.showHideCallingScreen = false;

                            SIPConnection.call_terminated = false;
                            setupRemoteMedia(invitation);
                            updateObj.status = 'accepted';
							console.log("sp-debug4", updateObj)
                            SIP_USER.myservice.updateCallStatus(updateObj);
                            break;
                        case SessionState.Terminated:
                            console.log("SessionState.Terminated")
                            if(SIPConnection.call_answered){
                              if(updateObj.status == 'accepted')
                              {
                                SIPConnection.call_terminated = true;
                                updateObj.status ='accepted' ;//'terminated';
                                updateObj.callEnd = moment().utc();
                              }
                            }else{
                              updateObj.status ='establishing' ;//'terminated';
                              updateObj.callEnd = moment().utc();
                            }

							              //console.log("sp-debug5", updateObj)
                            SIP_USER.myservice.updateCallStatus(updateObj);
                            // Session has terminated.
                            break;
                        default:
                            break;
                    }
                });

                // Handle incoming INVITE request.
                let constrainsDefault: MediaStreamConstraints = {
                    audio: true,
                    video: false,
                }
                const options: InvitationAcceptOptions = {
                    sessionDescriptionHandlerOptions: {
                        constraints: constrainsDefault,
                    },
                }

                function auto_accept_incoming_call(timeout){
                    setTimeout(()=>{
                            SIPConnection.incomingSession.accept(options);
                    },timeout);
                }

                console.log('Ringing now..Auto Answering in 3 seconds');

                auto_accept_incoming_call(3000);

                //SIPConnection.incomingSession.accept(options);
                //SIPConnection.call_answered = true;
            }
        };
        function SIP_Registration(){
            const registerer = new Registerer(userAgent);
            try{
                SIPConnection.sip_connected = true;
                SIPConnection.userAgent = userAgent;
                userAgent.start().then(() => {
                    registerer.register();
                },(error)=>{
                    console.log("SIP Connection Start Error : ",error);
                });
            }catch(error){
                console.log("SIP Connection Start Catch Error : ",error);
            }
        }
        SIP_Registration();
    }

	public static sipOutgoingCall(user_details,callee_number) {

        const peerConnection = new RTCPeerConnection(
			{'iceServers': [{'urls': 'stun:stun.l.google.com:19302'}]
		});

		const inviteOptions = {
            requestDelegate: {
                onAccept: (response) => {
                    console.log("Positive response = " + response);
                },
                onReject: (response) => {
                    console.log("Negative response = " + response);
                }
            },
            sessionDescriptionHandlerOptions: {
                constraints: {
                    audio: true,
                    video: false
                },
				rtcOfferConstraints: {
					offerToReceiveAudio: 1,
					offerToReceiveVideo: 0
				},
				rtcConfiguration: peerConnection
            }
        };

		const remoteStream = new MediaStream();
		function setupRemoteMedia(session: Session) {
			console.log('MediaSession', session);
			session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
				console.log('Got tracks');
				if (receiver.track) {
					remoteStream.addTrack(receiver.track);
				}
			});
			user_details.player.srcObject = remoteStream;
			user_details.player.play();
		}

		let transportOptions = {
            server: 'wss://'+user_details.sip_domain,
            maxReconnectionAttempts: 10,
            reconnectionTimeout:10,
            traceSip: true
        };

        let uri = UserAgent.makeURI("sip:" + user_details.sip_user);

        let userAgentOptions: UserAgentOptions = {
            authorizationPassword: user_details.sip_password,
            authorizationUsername: user_details.sip_auth,
            transportOptions,
            uri
        };

        const userAgent = new UserAgent(userAgentOptions);

		function RegisterToSip() {
			const registerer = new Registerer(userAgent);
			console.log('Registering to SIP..');
			try {
			  userAgent.start().then(
				() => {
				    registerer.register();
				    const target = UserAgent.makeURI("sip:"+callee_number+"@asterisk.contiinex.com");
					let session = new Inviter(userAgent, target);
					session.direction = 'outgoing';
					console.info('Dialing new Call..');

					session.stateChange.on(function (newState) {
						console.log(newState);
					});

					session.invite(inviteOptions).then((request) => {
						console.log("Successfully sent INVITE");
						console.log("INVITE request = " + request);
						setupRemoteMedia(session);
					}).catch((error) => {
						console.log("Failed to send INVITE");
					});
				},
				(error) => {
				  console.log('SIP Connection Start Error : ', error);
				}
			  );
			} catch (error) {
			  console.log('SIP Connection Start Catch Error : ', error);
			}
		}
		
		RegisterToSip();
		return userAgent;
	}

	public static muteConnection() {
		console.log('SIP: muteConnection');
		if (SIPConnection.incomingSession) {
			SIPConnection.incomingSession.sessionDescriptionHandler.peerConnection.getLocalStreams().forEach(function (stream) {
				stream.getAudioTracks().forEach(function (track) {
					track.enabled = false;
					console.log('SIP: getAudioTracks set false');
				});
			});
	    }
	}
	public static unMuteConnection() {
		console.log('SIP: unMuteConnection');
		if (SIPConnection.incomingSession) {
			SIPConnection.incomingSession.sessionDescriptionHandler.peerConnection.getLocalStreams().forEach(function (stream) {
				stream.getAudioTracks().forEach(function (track) {
					track.enabled = true;
					console.log('SIP: getAudioTracks set true');
				});
			});
	    }
	}
	public static holdConnection() {
		console.log('SIP: holdConnection');
		if (SIPConnection.incomingSession) {
			SIPConnection.incomingSession.sessionDescriptionHandler.peerConnection.getLocalStreams().forEach(function (stream) {
				stream.getAudioTracks().forEach(function (track) {
					track.enabled = false;
					console.log('SIP: holdConnection set false');
				});
			});
	    }
	}
	public static unHoldConnection() {
		console.log('SIP: unHoldConnection');
		if (SIPConnection.incomingSession) {
			SIPConnection.incomingSession.sessionDescriptionHandler.peerConnection.getLocalStreams().forEach(function (stream) {
				stream.getAudioTracks().forEach(function (track) {
					track.enabled = true;
					console.log('SIP: holdConnection set true');
				});
			});
	    }
	}
	public static endConnection(){
		console.log('SIP: endConnection');
		if (SIPConnection.userAgent.isConnected()) {
			SIPConnection.userAgent.stop().then(() => {
				console.log("SIP Connection User agent stopped");
			},(error)=>{
				console.log("SIP Connection Start Error : ",error);
			});
	    }
	}
    public static recreateConnection(){
        if(SIPConnection.sip_connected){
           // console.log("Reconnecting SIP Connection");
            try{
                SIPConnection.userAgent.reconnect().then(() => {
                    //console.log("Reconnected SIP Connection");
                },(error)=>{
                    //console.log("SIP Re-Connection Error : ",error);
                    // SIPConnection.sip_connected = false;
                });
            }catch(error){
                //console.log("SIP Connection Start Catch Error : ",error);
            }
		} else if (SIPConnection.SIP_USER_INFO && SIPConnection.SIP_USER_INFO.sip_user && (!SIPConnection.sip_connected || !SIPConnection.connection_status)){
           // console.log("Recreating SIP Connection");
            SIPConnection.createConnection(SIPConnection.SIP_USER_INFO);
        }else{
           // console.log("SIP USer Details not exists");
        }
    }
    public static getConnectionStatus(){
        if(SIPConnection.sip_connected){
            let status = SIPConnection.userAgent.isConnected();
            // console.log("SIP Connection Status : ",status);
            if(status){
                SIPConnection.connection_status = true;
            }else{
                SIPConnection.connection_status = false;
            }
            return SIPConnection.connection_status;
        }
    }
    public static reCheckConnection(){
        function test_sip_connection(timeout){
            setTimeout(()=>{
                if(SIPConnection.sip_connected){
                    let status = SIPConnection.userAgent.isConnected();
                    //console.log("SIP Connection Status Re-Check : ",status);
                    if(status){
                        SIPConnection.connection_status = true;
                        test_sip_connection(1000*5);
                    }else{
                        SIPConnection.connection_status = false;
                        SIPConnection.recreateConnection();
                        test_sip_connection(1000*10);
                    }
                }
            },timeout);
        }
        test_sip_connection(1000);
    }

}
