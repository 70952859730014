<mat-card *ngIf="fromApproval">
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <span style="position: absolute;top: -36px;right: 15px;z-index: 2;cursor: pointer;">
            <img src="../assets/closeicon.svg" width="40px" style="position: absolute;" (click)="closeDialog()" /></span>
    </div>
</div>
<div style="padding:0 15px;">
    <div class="row">
        <div class="col-md-12">
            <h1 class="hOne">Chat</h1>
        </div>
    </div>


    <div class="row">
        <div class="col-md-4 leftScroll" style="min-height: 350px;height: 350px!important;">
            <div class="searchDiv">
                <i class="fa fa-search iconIsearch" aria-hidden="true"></i>
                <input type="text" placeholder="search messages" />
            </div>

            <div class="chat_list" *ngFor="let val of groupList;let $index=index" (click)="tabkeyMenu(val.key,$index)"
                [ngClass]="{active_chat: $index == selectedActive}">
                <div class="chat_people">
                    <table width="100%">
                        <tr>
                            <td style="vertical-align: initial;width: 20%;">
                                <span> <img src="../assets/userImage.png"
                                        style="border: 1px solid #cccccc;width: 35px;height: 35px;border-radius: 50%;">
                                </span>
                            </td>
                            <td>
                                <table class="tableuser">
                                    <tr>
                                        <td style="font-size: 13px;"> <b>{{val.tab_name}}</b> </td>
                                        <td>
                                            <span class="chat_time">
                                                {{val.last_message_time}}
                                                <span *ngIf="val.unread_messages > 0"
                                                    class="badgenewcs">{{val.unread_messages}}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"> <span class="chat_p">{{val.last_message}} </span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="rightMaindiv1" style="width: 100%;">
                <div class="parentdiv" *ngIf="IsVisible" style="min-height: auto !important;">
                    <div class="headerPart">
                        <table width="100%">
                            <tr>
                                <td style="vertical-align: initial; width: 6%;">
                                    <span> <img src="../assets/userImage.png"
                                            style="border: 1px solid #ccc;width: 40px;height: 40px;border-radius: 50%;margin-bottom: 8px;">
                                    </span>
                                </td>
                                <td>
                                    <table class="tableuser">
                                        <tr>
                                            <td> <b>{{_dataTabName}}</b> </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="bodyPart rightScroll" id="messageBody" style="height: 245px;">
                        <div *ngIf="groupList[selectedActive]">
                            <div class="mesgs"
                                *ngFor="let msg of groupList[selectedActive].messages;let $msgIndex=index">
                                <div class="incoming_msg"
                                    *ngIf="myUser.id != msg.from_id && msg.message_type == 'TEXT'">
                                    <div class="received_msg">
                                        <div class="received_withd_msg">
                                            <p><span style="font-size:11px;display:block;color:black"> {{msg.user}}
                                                </span>{{msg.message}} <span class="time_date"> {{msg.message_time}}
                                                </span></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="outgoing_msg"
                                    *ngIf="myUser.id == msg.from_id && msg.message_type == 'TEXT'">
                                    <div class="sent_msg">
                                        <p><span style="font-size:11px;display:block;color:black"> {{msg.user}}
                                            </span>{{msg.message}}
                                            <span class="time_date">{{msg.message_time}}</span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="footerPart" style="bottom: 0;">
                        <span class="reqField" *ngIf="mandatoryFiled">Required field</span>

                        <div class="row">
                            <div class="col-md-9 col-sm-9 mr-2">
                                <input type="text" id="textarea" (keyup)="onTextMessage()" [(ngModel)]="txtmsg"
                                    class="inputtext" placeholder="Type a message here…" required="true"
                                    [disabled]="socketConnection" (keydown.enter)="sendMessage_With_Enter(txtmsg)" />

                                <div class="image-upload">
                                    <label for="file-input" class="fa fa-paperclip input-icon1"></label>
                                    <input id="file-input" type="file" (change)="uploadFile($event)"
                                        file-model="myFile" />
                                </div>
                            </div>
                            <div class="col-md-1  col-sm-2">
                                <!-- <button class="btncs" id="send" style="right:0px" (click)="endSession()"><i
                                        class="fa fa-times" aria-hidden="true"></i></button> -->
                            </div>
                            <div class="col-md-1  col-sm-2">
                                <button class="btncs" style="right: 25px;;background: #4caf50;" id="send"
                                    (click)="sendMessage_With_Enter(txtmsg)"><i class="fa fa-send"
                                        aria-hidden="true"></i></button>
                            </div>
                        </div>


                    </div>
                </div>
                <!--end chat online header-->
            </div>
        </div>
    </div>




    <div *ngIf="socketConnection" class="interNetMesg" id="closeInternetMsg">
        <p><span class="warn-cs"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
            No internet connection.<br>
            Kindly check your internet connectivity</p>
    </div>

    <div id="myModal" class="modalLarge">
        <span class="closeLarge" (click)="closeLarge()">&times;</span>
        <img class="modal-large-content" id="img01">
    </div>



</div>
</mat-card>