<div class="row ml-0 mr-0 mt-3">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <p class="L-head" style="color:#000">Call History </p>
        </div>
        </div>

<div class="row ml-0 mr-0">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <mat-button-toggle-group style="border: none !important;" (change)="togleChange($event.value)" [(ngModel)]="toggleValue">
            <mat-button-toggle [ngClass]="'historyToggle'" value="totalDialed">Total Dialed</mat-button-toggle>
            <mat-button-toggle [ngClass]="'historyToggle'" value="contacted">Contacted</mat-button-toggle>
            <mat-button-toggle [ngClass]="'historyToggle'" value="foloowupscompleted">Follow ups completed</mat-button-toggle>
             <mat-button-toggle [ngClass]="'historyToggle'" value="followupstodo">Follow ups to do</mat-button-toggle>
          </mat-button-toggle-group>
    </div>
</div>

<div class="mt-3" *ngIf="showHideForTotalDialed">
    <table mat-table [dataSource]="dataSource">
    
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Customer Name </th>
      <td mat-cell *matCellDef="let element">{{element.name}} </td>
    </ng-container>
    
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>Mobile Number </th>
      <td mat-cell *matCellDef="let element"> {{maskNumber(element.number)}} </td>
    </ng-container>
    
    <ng-container matColumnDef="start_time">
      <th mat-header-cell *matHeaderCellDef> Dialed Date </th>
      <td mat-cell *matCellDef="let element"> {{element.start_time | slice:0:10 | date:'dd-MMM-yyyy'}} </td>
    </ng-container>
    
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef> Dialed Time </th>
      <td mat-cell *matCellDef="let element"> {{element.start_time | slice:11:16}} </td>
    </ng-container>
    
    <ng-container matColumnDef="iconDialed">
        <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
        <td mat-cell *matCellDef="let element"> 
            <button style="border: none; background: none;" (click)="callCustomer(element)"><i class="fa fa-phone phoneDialIcon"
            aria-hidden="true"></i> </button>  
        </td>
      </ng-container>
   
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
    </table>
    <mat-paginator #pag1 [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>

    <div class="mt-3" *ngIf="showHideForContacted">
      <table mat-table [dataSource]="dataSourceContacted">
      
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Customer Name </th>
        <td mat-cell *matCellDef="let element">{{element.name}} </td>
      </ng-container>
      
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>Mobile Number </th>
        <td mat-cell *matCellDef="let element"> {{element.number}} </td>
      </ng-container>
      
      <ng-container matColumnDef="start_time">
        <th mat-header-cell *matHeaderCellDef> Dialed Date </th>
        <td mat-cell *matCellDef="let element"> {{element.start_time | slice:0:10 | date:'dd-MMM-yyyy'}} </td>
      </ng-container>
      
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef> Dialed Time </th>
        <td mat-cell *matCellDef="let element"> {{element.start_time | slice:11:16}} </td>
      </ng-container>
      
      <ng-container matColumnDef="iconDialed">
          <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
          <td mat-cell *matCellDef="let element"> 
              <i class="fa fa-phone phoneDialIcon"
              aria-hidden="true"></i>   
          </td>
        </ng-container>
     
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
      </table>
      <mat-paginator  #pag2  [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
  
