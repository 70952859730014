<div class="mainDivLoginPage">
    <!-- <div class="row mt-1">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <p class="con-logo-p">
                <img src="../assets/image/contiinexLogoImg.png" class="con-logo-img" (click)="conweb()" />
            </p>
        </div>
    </div> -->
    <div class="row mt-1">
        <div class="col-md-8 col-sm-8 col-xs-12">
            
            <!-- <p class="pimg"><img src="../assets/contiinex/loginBgImage.png" class="loginBgcls" /></p> -->
            <div class="pimg">
				<div class="loginBgcls"></div>
			</div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12 bgimg2">
            <!-- <img src="../assets/contiinex/spinBGLogin.png" class="spinCardBgcs" /> -->

            <mat-card class="cxCard1" *ngIf="showHideRegisterPage">
                <div>
                    <div class="logo_img">
                        <img src="../assets/contiinex/image/contiinexLogoImg.png" class="con-logo-img" (click)="conweb()" />
                    </div>
                    <p class="pText1 pTextReg" style="margin: 17px 0px;text-align: left;position: relative;">
                        {{_heading}} </p>

                    <div class="logDiv">
                        <p *ngIf="showHideMessage" [ngStyle]="{'color':msgColor}">{{messageDisplay}}</p>
                        <form [formGroup]="createdForm">
                            <table class="wd-100">
                                <tr style="display: none;">
                                    <td>
                                        <div class="input-label-group">
                                            <label>Choose Your Product*</label>

                                            <p>
                                                <mat-select placeholder="Select A Product" [(value)]="ddlProductValue"
                                                    (selectionChange)="onChangeProduct($event.value)">
                                                    <!-- <mat-option>None</mat-option> -->
                                                    <mat-option value="Gignex">Gignex</mat-option>
                                                    <mat-option value="Comnex">Comnex</mat-option>
                                                    <mat-option value="Ainex">Ainex</mat-option>
                                                    <mat-option value="WABA">WhatsApp Business API </mat-option>
                                                </mat-select>
                                            </p>
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="input-label-group">
                                            <label>Full Name*</label>
                                            <p> <input matInput autocomplete="off" placeholder="Enter your full name"
                                                    (keypress)="omit_special_char_num($event)"
                                                    formControlName="fullName" required maxlength="100"></p>
                                        </div>
                                        <span class="errCs"
                                            *ngIf="createdForm.get('fullName').invalid && createdForm.get('fullName').touched">Please
                                            enter full name *</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="input-label-group">
                                            <label>Company Name*</label>
                                            <p> <input matInput matInput autocomplete="off"
                                                    placeholder="Enter company name" formControlName="companyName"
                                                    required maxlength="100"></p>
                                        </div>
                                        <span class="errCs"
                                            *ngIf="createdForm.get('companyName').invalid && createdForm.get('companyName').touched">Please
                                            enter company name *</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="input-label-group">
                                            <label>Email*</label>
                                            <p> <input matInput autocomplete="off" placeholder="Enter Valid Email Id"
                                                    formControlName="email" required maxlength="100"></p>
                                        </div>
                                        <span class="errCs"
                                            *ngIf="createdForm.get('email').invalid && createdForm.get('email').touched ">Please
                                            enter valid email *</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="input-label-group">
                                            <label>Mobile Number*</label>
                                            <p> <input matInput autocomplete="off" required maxlength="15"
                                                    placeholder="Enter valid mobile number"
                                                    (keypress)="onlyNumberValidation($event)"
                                                    formControlName="mobileNo"></p>
                                        </div>
                                        <span class="errCs"
                                            *ngIf="createdForm.get('mobileNo').invalid && createdForm.get('mobileNo').touched || createdForm.controls['mobileNo'].hasError('minlength')">Please
                                            enter your mobile number *</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="input-label-group">
                                            <label>Password*</label>
                                            <p> <input matInput autocomplete="off" type="password"
                                                    placeholder="Please enter password" formControlName="password"
                                                    required maxlength="15"></p>
                                        </div>
                                        <span class="errCs"
                                            *ngIf="createdForm.get('password').invalid && createdForm.get('password').touched ">
                                            Please enter password between 8 and 15 character*</span>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="input-label-group">
                                            <label>Confirm Password*</label>
                                            <p><input matInput autocomplete="off" type="password"
                                                    placeholder="Re-enter password" formControlName="confirmPassword"
                                                    required maxlength="15"></p>
                                        </div>
                                        <span class="errCs"
                                            *ngIf="createdForm.get('confirmPassword').invalid && createdForm.get('confirmPassword').touched && createdForm.get('confirmPassword') != createdForm.get('password')">
                                            Please enter password between 8 and 15 character*</span>
                                    </td>
                                </tr>
                            </table>
                            <div class="row">
                                <div class="col-md-12 col-sm-12 text-center">
                                    <button class="go-Submit" style="margin-top: 2px;"
                                        (click)="registerBtn(createdForm)">REGISTER</button>
                                </div>
                            </div>
                            <div class="row mt-2 mb-4">
                                <div class="col-md-12 col-sm-12 text-center">
                                    <span class="sp2" (click)="backToLogin()">
                                        <mat-icon style="vertical-align: bottom;margin-right: 12px;">arrow_back
                                        </mat-icon>Back To Login
                                    </span>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>

            </mat-card>
            <mat-card class="cxCard1" style="display: inline-flex;"
                *ngIf="showHideWPOnBoardingPage">

                <div class="text-center">
                    <p> <img src="../assets/contiinex/image/regWpLog.png" class="vk-wpLogIcon"  /></p>
                    <p class="v-PUserHead mb-1">WhatsApp Business On-Boarding </p>
                    <p class="v-pUser">Welcome {{registration_data.name}}</p>
                    <!-- <p class="v-pUserText">We will guide you in setting up your WhatsApp Business Account in no time.
                        Please select one of the below options</p> -->
                    <div class="wboCs1 text-center">
                        <div  class="mb-1"><img src="../assets/contiinex/image/v-wbo1.png" style="width:25px;"/></div>
                        <p>Select this option if you are a Business Owner and would want to use a Business Phone number as a new WhatsApp Number to connect with your customers</p>
                        <button class="pUserButtonText1 mt-2" (click)="createNewWBA()">Create New WhatsApp Business Account</button>
                    </div>
                    <div class="wboCs1 text-center">
                        <div  class="mb-1"><img src="../assets/contiinex/image/v-wbo2.png"  style="width:25px;"/></div>
                        <p>Select this option if you already have a registered WhatsApp Business Account and would want to migrate the services from your existing vendor into us</p>
                        <button class="pUserButtonText1" (click)="transferExistingWBA()">Transfer Existing WhatsApp Business Account</button>
                    </div>
                    <p class="v-pUserBack"><span (click)="backToRegisterPage()">
                            <mat-icon class="v-pUserIcon"> chevron_left</mat-icon> Back
                        </span></p>
                </div>
            </mat-card>
            <mat-card class="cxCard1" style="display: inline-flex;"
                *ngIf="showHideCreateWPAccountPage">

                <div class="text-center">
                    <p> <img src="../assets/contiinex/image/regWpLog.png" class="vk-wpLogIcon"  /></p>
                    <p class="v-PUserHead">Create New WhatsApp<br>
                        Business Account </p>
                    <!-- <p class="v-pUserText">If you already have a FB business account for your
                        company, you can proceed to fill up your business
                        account details and get started within minutes. If
                        you do not have a FB business account, Please
                        proceed to create a business account and return to
                        this step with the FB account details</p> -->


                        <div class="wboCs1 text-center">
                        <div  class="mb-1"><img src="../assets/contiinex/image/v-wbo3.png"  style="width:25px;"/></div>
                        <p>Select this option if you already have a business account on FB and would want to include a WhatsApp Account on the same account</p>
                        <button class="pUserButtonText1" (click)="alreadyBusinessAccount()">I Already have a FB Business Account</button>
                        </div>
                        <div class="wboCs1 text-center">
                            <div  class="mb-1"><img src="../assets/contiinex/image/v-wbo4.png"  style="width:25px;"/></div>
                            <p>Select this option if you do not yet have a business account on your FB account. On Clicking this option you would be redirected to https://business.facebook.com to create a business account</p>
                            <a href="https://business.facebook.com/" style="text-decoration: none; color: #6c6c6c;" target="_blank">
                            <button class="pUserButtonText1" (click)="createNewBusinessAccount()">Create a new FB business Account</button></a>
                    </div>
                    <p class="v-pUserBack"><span (click)="backToOnboard()">
                            <mat-icon class="v-pUserIcon"> chevron_left</mat-icon> Back
                        </span></p>
                </div>
            </mat-card>
            <mat-card class="cxCard1" style="display: inline-flex;align-items: center;"
                *ngIf="showHideTransferExistingPage">

                <div class="text-center">
                    <p> <img src="../assets/contiinex/image/regWpLog.png" style="width: 45px;" /></p>
                    <p class="v-PUserHead">Transfer an existing<br>
                        WhatsApp Business Account</p>
                    <p class="v-pUserText">Please send a request to our team to assist
                        you on this</p>
                    <p> <button class="pUserButtonText" (click)="sendServiceRequest()" style="width: 200px;padding: 12px 6px;">
                            <mat-icon
                                style="vertical-align: middle;margin-right: 10px;position: relative;bottom: 4px;transform: rotate(316deg);color: #dd133c;">
                                send</mat-icon>SEND A REQUEST
                        </button></p>
<!--
                    <p class="v-pUserBack"><span (click)="backToOnboard()">
                            <mat-icon class="v-pUserIcon"> chevron_left</mat-icon> Back
                        </span></p>
-->
                </div>



            </mat-card>
        </div>
    </div>
</div>
<!-- <div class="footerDivx">
    The only Customer Engagement Technology You’ll Ever Need
</div> -->
