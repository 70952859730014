<div id="campign_details">
	<mat-card>

		<div class="row" style="border-bottom: 1px solid #ccc;margin-bottom: 20px;">
			<div class="col-md-7 col-lg-7 col-sm-12 ">
				<p class="campaign_name">{{getValueByParams(input_obj, 'campaign')}} - Details </p>
			</div>
			<div class="col-md-5 col-lg-5 col-sm-12 ">
				<p class="back_btn" (click)="backmenu()">&lt; BACK TO DASHBOARD</p>
			</div>
		</div>
		<p class="textAlertMessage" *ngIf="showHide_messageAlert">{{messageAlert}}</p>
		<table width="100%">
			<tbody>
				<tr>
					<th style="width:20%">Campaign Name</th>
					<td>{{getValueByParams(input_obj, 'campaign')}}</td>
				</tr>
				<tr>
					<th style="width:20%">Owner</th>
					<td>{{getValueByParams(input_obj, 'name')}}</td>
				</tr>
				<tr>
					<th style="width:20%">Started</th>
					<td>{{campaign_start_at}}</td>
				</tr>
				<tr>
					<th style="width:20%">Dial Mode</th>
					<td>{{ get_dial_priority() }}</td>
				</tr>
				<tr>
					<th style="width:20%">Last Refreshed</th>
					<td>{{last_refreshed_at}}</td>
				</tr>
				<tr>
					<th style="width:20%">Status</th>
					<td [ngStyle]="{'color':statusColor}"> {{getStatusActiveInactive()}}</td>
				</tr>
				<!-- <tr *ngIf="campaign_newAgents.length && input_obj.active_status">
					<td colspan="2">
						<mat-expansion-panel hideToggle>
							<mat-expansion-panel-header>
								<mat-panel-title>
									Add agents
								</mat-panel-title>
								<mat-panel-description>
									Click to add more agents to the campaign - {{ input_obj['campaign'] }}
								</mat-panel-description>
							</mat-expansion-panel-header>
							<section class="add-agent-section">
								<span class="add-agent-list-section">
									<mat-checkbox class="add-agent-margin" [checked]="selected_all"
										[indeterminate]="someSelected()" (change)="setAll($event.checked)">
										Select All
									</mat-checkbox>
								</span>
								<span class="add-agent-list-section">
									<ul>
										<li *ngFor="let agent of campaign_newAgents">
											<mat-checkbox [(ngModel)]="agent.add_selected"
												(ngModelChange)="updateAllSelected()">
												{{agent.name}}
											</mat-checkbox>
										</li>
									</ul>
								</span>
							</section>
							<button *ngIf="someSelected() || selected_all" mat-stroked-button
								(click)="addAgents_tocampaign()">Add Agents</button>
						</mat-expansion-panel>
					</td>
				</tr> -->
			</tbody>
		</table>
		<br>
		<div class="row">
			<div class="col-md-6 col-sm-12"></div>
			<div class="col-md-3  col-sm-12 text-right">
				<mat-form-field>
					<input matInput placeholder="Search" (keyup)="applyFilter($event.target.value)" />
					<i class="fa fa-search" matSuffix aria-hidden="true"></i>
				</mat-form-field>
			</div>
			<div class="col-md-3  col-sm-12 text-center" *ngIf="input_obj.active_status">
				<button mat-button (click)="addAgentCampaign()">Add Agent To This Campaign +</button>
			</div>
		</div>

		<br>
		<mat-table [dataSource]="agentDataSource">
			<ng-container matColumnDef="sl_no">
				<mat-header-cell *matHeaderCellDef> SNo </mat-header-cell>
				<mat-cell *matCellDef="let element; let  i= index"> {{i + 1}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="name">
				<mat-header-cell *matHeaderCellDef> Agent Name </mat-header-cell>
				<mat-cell *matCellDef="let element">
					{{element.name}}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="email">
				<mat-header-cell *matHeaderCellDef> Agent Email </mat-header-cell>
				<mat-cell *matCellDef="let element">
					{{element.email}}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="campaign_status">
				<mat-header-cell *matHeaderCellDef> Agent Status </mat-header-cell>
				<mat-cell *matCellDef="let element">
					<mat-slide-toggle [(ngModel)]="element.campaign_status" [disabled]="!input_obj.active_status">
					</mat-slide-toggle>

				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="data">
				<mat-header-cell *matHeaderCellDef> Agent Data </mat-header-cell>
				<mat-cell *matCellDef="let element">
					{{element.data}}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="dialled">
				<mat-header-cell *matHeaderCellDef> Data Dialled </mat-header-cell>
				<mat-cell *matCellDef="let element">
					{{element.dialled}}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="contacted">
				<mat-header-cell *matHeaderCellDef> Data Contacted </mat-header-cell>
				<mat-cell *matCellDef="let element">
					{{element.contacted}}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="followup">
				<mat-header-cell *matHeaderCellDef> Followups </mat-header-cell>
				<mat-cell *matCellDef="let element">
					{{element.followup}}
				</mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef=agent_table_columns></mat-header-row>
			<mat-row *matRowDef="let row; columns: agent_table_columns"></mat-row>
		</mat-table>
		<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

		<div style="text-align:right" *ngIf="agentDataSource.data.length && input_obj.active_status">

			<button mat-stroked-button color="default" (click)="submit_agent_status()">Submit Changes</button>
		</div>
	</mat-card>
</div>
<div id="cover-spin" *ngIf="currentLoader"></div>