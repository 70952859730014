<div class="row pdt">
    <div class="col-md-12 col-sm-12 col-xs-12">
     <mat-card style="padding-left:3%;">
         <h2 class="d-head">BATCH &nbsp;&nbsp; {{_batch_id}}&nbsp;&nbsp; ({{trainingDayCount}} DAYS TRAINING)<img src="../assets/underline.svg"
            class="linebelow"> </h2>


<div class="searchFix">
    <mat-form-field style="font-size: 12px;" appearance="outline">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="SEARCH">
        <i class="fa fa-search search-icon" matSuffix style="position: relative;
        top: -2px;"></i>
      </mat-form-field>
</div>
<br>

<div class="row">
    <div class="col-md-8 col-sm-12 col-xs-12">
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12">
            <span class="d-dat"> {{_trainingdate | date: 'dd/MMM/yyyy'}}</span>
        </div>
</div>
<div class="row">
    <div class="col-md-3 col-sm-12 col-xs-12">
        <mat-form-field >     
            <mat-select style="  font-family: poppins !important;" placeholder="SELECT" [(ngModel)]="maxDays" (selectionChange)="dayCountChange($event.value)">
              <mat-option  style="  font-family: poppins !important;" value="{{v.day_count}}" *ngFor="let v of batchDaysRecord">Day {{v.day_count}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 bdrLR">
            <span class="t-title">Shift</span>&nbsp;&nbsp;&nbsp;
            <span> <mat-form-field style="width: 35%;" >     
                <mat-select placeholder="From" [(ngModel)]="_shiftFrom"  disabled="true">
                  <mat-option value="{{_shiftFrom}}">{{_shiftFrom}}</mat-option>
                </mat-select>
              </mat-form-field>  </span>&nbsp;&nbsp;&nbsp;
              <span> <mat-form-field  style="width: 35%;">     
                <mat-select placeholder="To" [(ngModel)]="_shiftTo"  disabled="true">
                  <mat-option value="{{_shiftTo}}">{{_shiftTo}}</mat-option>
                </mat-select>
              </mat-form-field>
            </span>
        </div>
        <div class="col-md-3 col-sm-12 col-xs-12">
            <span class="t-title">Trainer</span>&nbsp;&nbsp;&nbsp;
           <span> <mat-form-field  style="width: 60%;">     
                <mat-select [(ngModel)]="_trainerName"  disabled="true">
                  <mat-option [value]="v.trainer_name" *ngFor="let v of batchDaysRecord">{{v.trainer_name}}</mat-option>
                </mat-select>
              </mat-form-field></span>
        </div>
        <div class="col-md-2 col-sm-12 col-xs-12">
          
<div class="DayDiv">
    <span class="TA-day">DAY</span>
    <h1 class="TA-date">{{_dayCount | number: '2.'}}
        <span style="display: none;">{{_trainingdate | date: 'dd/MMM/yyyy'}}</span>
    </h1>   
</div> 
        </div>
        </div>

          <div class="row">
              <div style="float: left;">
              <mat-button-toggle-group class="tgMeetingToggle" [ngClass]="'tgGroupClass'" aria-label="Font Style" (change)="changeTogleBatchDays($event.value)">      
                  <mat-button-toggle class="tabBtn" [ngClass]="'tgClass'" value="attendance" checked>
                    <i class="fa fa-circle circleColor" style="  color: #15d015cf;" aria-hidden="true"></i> Attendance
                  </mat-button-toggle>
                  <mat-button-toggle class="tabBtn" [ngClass]="'tgClass'" value="meeting">
                    <i class="fa fa-circle circleColor" aria-hidden="true"></i> Meeting
                  </mat-button-toggle>
				<mat-button-toggle class="tabBtn" [ngClass]="'tgClass'" value="attach_files" *ngIf="showFileTab">
					<i class="fa fa-circle circleColor" aria-hidden="true"></i> Attach files
				</mat-button-toggle>
                </mat-button-toggle-group>
                <span [style.color]="_color" style="margin-left:20px; font-weight: bold;font-size: 17px;">{{passingMessage}}</span>
            </div>
            <!-- <div style="float: left;">
              <span [style.color]="_color" style="margin-left:20px; font-weight: bold;font-size: 17px;">{{passingMessage}}</span>
            </div> -->
        </div>
  <!-- <mat-tab-group >
    <mat-tab >
       <ng-template mat-tab-label>
        <i class="fa fa-circle circleColor" aria-hidden="true"></i> Attendence
       </ng-template> -->

       <!--start Attandance-->
      <div *ngIf="showHideAttendance">
        <br>
       <div class="scrollTable">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="user_id">
          <th mat-header-cell *matHeaderCellDef>LEARNER </th>
          <td mat-cell *matCellDef="let element" >{{element.agent_reference_id || element.user_id}} </td>
        </ng-container>
        
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> USER </th>
          <td mat-cell *matCellDef="let element" > {{element.name}} </td>
        </ng-container>
        
        <!--<ng-container matColumnDef="attendance">
          <th mat-header-cell *matHeaderCellDef> ATTENDENCE</th>
          <td mat-cell *matCellDef="let element"> 
              <mat-radio-group [(ngModel)]="element.attendance">
            <mat-radio-button value="1" [ngClass]="'radioPresent'" style="padding-right: 12px;">Present</mat-radio-button>
            <mat-radio-button value="0" [ngClass]="'radioAbsent'">Absent</mat-radio-button>
          </mat-radio-group> 
        </td>
        </ng-container>-->

		<ng-container matColumnDef="attendance">
			<th mat-header-cell *matHeaderCellDef> ATTENDANCE</th>
			<td mat-cell *matCellDef="let element"> 
			<mat-form-field appearance="fill" >
				<!--<mat-label>ATTENDANCE</mat-label>-->
				<mat-select placeholder="ATTENDANCE" [(ngModel)]="element.attendance" required>
					<mat-option *ngFor="let locale of attendancelist" [value]="locale.value">
						{{locale.viewValue}}
					</mat-option>
				</mat-select>
				<!--<select placeholder="ATTENDANCE" matNativeControl >
				<option value="1">Present</option>
				<option value="0">Absent</option>
				<option value="2">NCNS</option>
				<option value="3">Moved</option>
				<option value="4">Failure</option>
				<option value="5">Termination</option>
				<option value="6">Resignation</option>
				</select>-->
			</mat-form-field>
			</td>
		</ng-container>
        
        <!--<ng-container matColumnDef="agent_status">
          <th mat-header-cell *matHeaderCellDef> STATUS </th>
          <td mat-cell *matCellDef="let element"  [ngStyle]="getStatusColor(element.status)"> {{element.agent_status}} </td>
        </ng-container>-->
        
        <ng-container matColumnDef="remarks">
            <th mat-header-cell *matHeaderCellDef> REMARKS </th>
            <td mat-cell *matCellDef="let element"> 
                    <input type="text" class="in-put" [(ngModel)]="element.remarks">
             </td>
          </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"  ></tr>
        </table>
        </div>
        <div class="pdt"> 
          <div class="col-md-12 col-sm-12 col-xs-12">
              <mat-checkbox (change)="selectAllPresent( $event)" style="color: #3fb90d;text-decoration: underline;">Select All As Present</mat-checkbox>
          </div>  
          </div>
          
<br>
      <div class="pdt" style="display: flex;"> 
  <div class="col-md-6 col-sm-12 col-xs-12">
    &nbsp;     
  </div>  
      <div class="col-md-6 col-sm-12 col-xs-12 rightside">
          <button mat-button class="cancelbtn" (click)="cancelAttendenceBtn()">Cancel</button>  
			<button mat-button class="submitbtn" [ngClass]="isdisableUpdate==true? 'class1' : ''"  [readonly]="isdisableUpdate==true?true:false" (click)="updateAttendence()">Update</button>
      </div>    
      </div>
    </div>
    <!--end Attandance-->

     <!-- </mat-tab>
     <mat-tab>
      <ng-template mat-tab-label>
       <i class="fa fa-circle circleColor" aria-hidden="true"></i> Meeting
      </ng-template> -->

         <!--start meeting-->
         <div *ngIf="showHideAttachFiles && showFileTab">
			
				<!-- <button class="btnbrowse">Select file</button> -->
				
				
				<div class="row mt-4">
					<div class="col-md-3 col-sm-12 col-xs-12">
						<div class="upload-btn-wrapper">
              <button class="btnbrowse">Select file</button>
							<input type="file" #fileUpload class="filecs" id="fileID" (change)="changeFile($event)" />
							<p style="color: #bababa;">{{currentFileobj.name}}</p>
						</div>
					</div>
					<div class="col-md-3 col-sm-12 col-xs-12">
						<button mat-button class="submitbtn" (click)="updateAttachFiles()">{{_updateName}}</button>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-md-12 col-sm-12 col-xs-12">
						<ul style="list-style: circle;">
							<li *ngFor="let file of batchSessionFilesList" style="color: #7f7f7f;margin: 0 0px 10px 0;font-size: 15px;"> {{file}} </li>
						</ul>
					</div>
				</div>
				
		 </div>
         <div *ngIf="showHideMeeting">
      <h2 class="d-head" style="padding-top: 5%;">CREATE MEETING LINKS </h2>

      <div style="display: inline-flex;flex-wrap: wrap;">
        
        <div><mat-card class="mtDiv" (click)="meetLink('googleMeet')">
          <span><img src="../../assets/GMeetIcon.jpg" width="40"/> </span><span class="ml-2"> Google Meet</span>
        </mat-card></div>
        <div>
          <mat-card class="mtDiv" (click)="meetLink('microsoftMeet')">
            <span><img src="../../assets/microsoftTeamIcon.png" width="40"/> </span><span class="ml-2"> Microsoft Teams</span>
          </mat-card>         
        </div>
        <div>
          <mat-card class="mtDiv"  (click)="meetLink('zoomMeet')">
            <span><img src="../../assets/zoomicon.png" width="40"/> </span><span class="ml-2"> Zoom</span>
          </mat-card>
         </div>
      </div>

      <div class="pdt"> 
        <h2 class="d-head" style="font-size: 13px;font-weight: normal;">Meeting Link </h2>
        <div class="pl-0 col-md-7 col-sm-12 col-xs-12">
          <mat-form-field style="font-size: 12px;" appearance="outline" [ngClass]="'inputBdr'">
            <input matInput style="font-weight: bold;
            color: #6c6864;font-size: 13px !important;" [(ngModel)]="inputLink" (change)="valueChange()">
            <button mat-button class="meetingDeleteLink" (click)="deleteInput()" matSuffix >Delete</button>
          </mat-form-field>
             </div> 
             <h2 class="d-head" *ngIf="showHideMessage" style="color:red;font-size: 14px;font-weight: normal;">* Please enter meeting link</h2> 
        </div>


        <h2 class="d-head pdt">BATCH </h2>
      <div style="display: flex;">
        <div class="pl-0 col-md-2 col-sm-12 col-xs-12">
          <mat-form-field style="font-size: 12px;width: 160px;" appearance="outline">
            <input matInput value="{{_batch_id}}" readonly>           
          </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12">
              <button mat-button class="submitbtn" (click)="sendLink()">SEND LINK</button>
            </div>
    </div>

  </div>
    <!--end Attandance-->

    <!--  </mat-tab>
    <mat-tab>
       <ng-template mat-tab-label>
        <i class="fa fa-circle circleColor" aria-hidden="true"></i> Day 1 Session Training
       </ng-template>
       Content Day 1 Session Training
     </mat-tab>
   
     <mat-tab>
       <ng-template mat-tab-label>
        <i class="fa fa-circle circleColor" aria-hidden="true"></i>Simulation
       </ng-template>
   
       Content Simulation
     </mat-tab>

     <mat-tab>
        <ng-template mat-tab-label>
            <i class="fa fa-circle circleColor" aria-hidden="true"></i>Assessment
        </ng-template>
    
        Content Assessment
      </mat-tab> 
   </mat-tab-group>-->
  </mat-card>
</div>
</div>

