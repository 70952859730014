import { Component, OnInit,ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import {Router} from '@angular/router';
import { AuditorProjectViewDialogComponent } from '../auditor-project-view-dialog/auditor-project-view-dialog.component';
import { AuditorProjectEditDialogComponent } from '../auditor-project-edit-dialog/auditor-project-edit-dialog.component';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-auditor-projects',
  templateUrl: './auditor-projects.component.html',
  styleUrls: ['./auditor-projects.component.scss']
})
export class AuditorProjectsComponent implements OnInit {

  displayedColumns = ['project_name', 'status', 'cx_touch_point', 'created_at','end_at','pushType','total_calls','audited_calls','auditors','action'];
  dataSource:any = new MatTableDataSource();
  myUser: any;
  allProjects: any[] = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  isEditBatch:boolean=false;
  constructor(private API: ApiService, private dialog: MatDialog, public router: Router, private service: MyserviceService,
    ) { 
    }
  ngOnInit() {

    this.myUser = this.service.get_loggedin_user();

    this.fetchProjects();
 
   }

   fetchProjects(){
    this.allProjects = []
    this.API.getClientsAudit(this.myUser.id).subscribe((response: any) => {
      for(const project of response.success){
        let obj: any = project;
        // obj.endAt = '31/10/2010';
        obj.pushType = 'Auto'; obj.data = '2000'; obj.audited = '1000';
        this.allProjects.push(obj);
      }
      this.dataSource = new MatTableDataSource(this.allProjects);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = function (data: any, filter: any): boolean {
        return data.project_name.toString().toLowerCase().includes(filter) || data.status.toString().toLowerCase().includes(filter)
      };
    });
   }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
  viewAuditors(element:any){
    const dialogRef = this.dialog.open(AuditorProjectViewDialogComponent, {
			width: '50%',
			disableClose:true,
			data: element
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
     // console.log('The dialog was closed');
      if(result.refresh){
        this.fetchProjects();
      }
		  });
  }
  editAuditors(element:any){
    const dialogRef = this.dialog.open(AuditorProjectEditDialogComponent, {
			width: '50%',
			disableClose:true,
			data: element
		  });
	  
		  dialogRef.afterClosed().subscribe(result => {
     // console.log('The dialog was closed');
      if(result.refresh){
        this.fetchProjects();
      }
		  });
  }
}

