
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12" style="display: none;" >
        <span  style="position: absolute;top: -36px;right: 15px;z-index: 2;cursor: pointer;">
            <img src="../assets/closeicon.svg" width="40px" style="position: fixed;" (click)="closeDialog()"/></span>
        </div>
</div>
<div class="row pdt">
    <div class="col-md-7 col-sm-7 col-xs-12">
        
        <h2 class="d-head">TRAINER INFO<img src="../assets/underline.svg" class="linebelow"> </h2>
    </div>
    <div class="col-md-5 col-sm-5 col-xs-12 rightside" style="display: none;">
        <span class="editDetails"> Edit Details </span>
        <span class="deleteDetails" (click)="openWarningMessage()">Delete Agent </span>
    </div>
</div>
<form [formGroup]="createdForm">
<div class="row pdt">
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'">
            <input matInput placeholder="Name" formControlName="name" [readonly]="isreadonly">
        </mat-form-field>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'">
            <input matInput placeholder="User Name" formControlName="username" [readonly]="isreadonly">
        </mat-form-field>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field [ngClass]="'inputClr'" >
            <input matInput placeholder="Rating" formControlName="rating" [readonly]="isreadonly">
            <!-- <star-rating-comp matSuffix [starType]="'icon'"  [size]="'medium'" formControlName="rating" [rating]="rating"></star-rating-comp> -->
        </mat-form-field>
    </div>

</div>

<div class="row pdt">
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'">
            <input matInput placeholder="Current Batches" formControlName="currentBatch" [readonly]="isreadonly">
        </mat-form-field>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'">
            <input matInput placeholder="Batch Type" formControlName="batchType" [readonly]="isreadonly">
        </mat-form-field>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'">
            <input matInput placeholder="Last Update on" formControlName="lastUpdateOn" [readonly]="isreadonly">
        </mat-form-field>
    </div>
   
  
</div>

<div class="row pdt">
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'">
            <input matInput placeholder="Aadhar Number" formControlName="aadharNumber" [readonly]="isreadonly">
        </mat-form-field>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12">
        <mat-form-field  [ngClass]="'inputClr'">
            <input matInput placeholder="Location" formControlName="location" [readonly]="isreadonly">
        </mat-form-field>
    </div>
</div>

<div class="row pdt"> 
    <div class="col-md-6 col-sm-12 col-xs-12"></div>  
        <div class="col-md-6 col-sm-12 col-xs-12 rightside">
            <button mat-button class="cancelbtn" (click)="closeDialog()" >Cancel</button>
            <button mat-button class="submitbtn" style="display: none;">Update</button>
        </div>
      
        </div>
    </form>
        <div class="popupoverlay" *ngIf="showHidePopupDelete">
            <div class="delete-popup">
                <h3 class="h3Message">Are you sure want to delete ?</h3>
                <a class="close CR" (click)="closedeletePopup()">&times;</a>
                <div class="content">
              <button class="cancelDelete">Cancel</button>
               <button class="delete-button">Delete</button>
                </div>
            </div>
        </div>