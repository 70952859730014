
  <p class="mt-2 mb-2 errmsg" [ngStyle]="{'color':statusColor}">{{errorMsg}}</p><br>
<mat-table [dataSource]="dataSource" matSort  style="width: 100%;overflow-x: scroll;display: inline-block;">
	<ng-container matColumnDef="applicant_id">
	   <mat-header-cell class="break_space" *matHeaderCellDef mat-sort-header> Applicant Id </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.applicant_id}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="user_id">
		<mat-header-cell class="break_space" *matHeaderCellDef mat-sort-header> Uearn Id </mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.ecode}} </mat-cell>
	 </ng-container>
	<ng-container matColumnDef="name">
	   <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="recruiter_email">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Panelist </mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.recruiter_email}} </mat-cell>
	 </ng-container>
	<ng-container matColumnDef="feedback">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Feedback </mat-header-cell>
		<mat-cell *matCellDef="let element"> <mat-icon *ngIf="!element.feedback_form" class="fdbkCandidate" matTooltip="Feedback" (click)="feedbackCandidatesDialog(element)"> add_circle </mat-icon> 
		 <mat-icon *ngIf="element.feedback_form" class="fdbkCandidate" matTooltip="Feedback" (click)="feedbackCandidatesDialog(element)"> visibility </mat-icon> </mat-cell>
	 </ng-container>
	<ng-container matColumnDef="phone">
	   <mat-header-cell *matHeaderCellDef mat-sort-header> Phone </mat-header-cell>
	   <mat-cell class="cell_space" *matCellDef="let element"> {{element.phone}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="agency_name">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Source</mat-header-cell>
		<mat-cell class="break_space" *matCellDef="let element">
			{{ element.campaign_agency }}
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="email">
		<mat-header-cell  *matHeaderCellDef mat-sort-header style="flex:0 0 190px"> Email </mat-header-cell>
		<mat-cell class="cell_space" *matCellDef="let element" style="flex:0 0 190px"> {{element.email}} </mat-cell>
	 </ng-container>
	<ng-container matColumnDef="gender">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Gender </mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.gender}} </mat-cell>
	 </ng-container>
	 <ng-container matColumnDef="age">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Age </mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.age}} </mat-cell>
	 </ng-container>
	<ng-container matColumnDef="education">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Education </mat-header-cell>
		<mat-cell class="cell_space" *matCellDef="let element"> {{element.education}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="dob">
		<mat-header-cell *matHeaderCellDef mat-sort-header> DOB </mat-header-cell>
		<mat-cell class="cell_space" *matCellDef="let element"> {{element.dob}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="setup">
		<mat-header-cell class="break_space" *matHeaderCellDef mat-sort-header> Laptop/ Desktop </mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.setup}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="connectivity">
		<mat-header-cell *matHeaderCellDef mat-sort-header class="connectivity_space_header"> Connectivity </mat-header-cell>
		<mat-cell class="connectivity_space cell_space"*matCellDef="let element"> {{element.connectivity}} </mat-cell>
	</ng-container>&ensp;&ensp;
	<ng-container matColumnDef="city">&ensp;&ensp;
		<mat-header-cell *matHeaderCellDef mat-sort-header> City </mat-header-cell>
		<mat-cell class="cell_space" *matCellDef="let element"> {{element.city}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="state">
		<mat-header-cell *matHeaderCellDef mat-sort-header> State </mat-header-cell>
		<mat-cell class="cell_space" *matCellDef="let element"> {{element.state}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="language">
		<mat-header-cell class="break_space language_space_header" *matHeaderCellDef mat-sort-header > Language </mat-header-cell>
		<mat-cell class=" connectivity_space cell_space" *matCellDef="let element"> {{element.language}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="created_at">
	   <mat-header-cell *matHeaderCellDef mat-sort-header> Reg Date </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.created_at}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="current_status">
	   <mat-header-cell *matHeaderCellDef mat-sort-header> Recruitment Status </mat-header-cell>
	   <mat-cell *matCellDef="let element">
		   <span class="break_space" [ngStyle]="getStatusColor(element.current_status)"> {{element.current_status}}</span><br/>
		</mat-cell><br>
	</ng-container>
	<ng-container matColumnDef="assessment_score">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Score Total</mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.assessment_score}} </mat-cell>
	 </ng-container>
	 <ng-container matColumnDef="score_combined">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Score Combined</mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.score_combined}} </mat-cell>
	 </ng-container>
	 <ng-container matColumnDef="assessment_id">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Assessment </mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.assessment_id}} </mat-cell>
	 </ng-container>
	<ng-container matColumnDef="event_1_interview">
	   <mat-header-cell class="break_space" *matHeaderCellDef mat-sort-header> Level1 Interview </mat-header-cell>
	   <mat-cell class="break_space" *matCellDef="let element">
		   <span class="capitalize_text" [ngStyle]="getStatusColor(element.event_1_status)">{{ element.event_1_status }}</span> {{element.event_1_interview}}
	   </mat-cell>
	</ng-container>
	<ng-container matColumnDef="event_1_organizer">
	   <mat-header-cell class="break_space" *matHeaderCellDef mat-sort-header> Level1 Agent </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.event_1_organizer}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="event_2_interview">
	   <mat-header-cell class="break_space" *matHeaderCellDef mat-sort-header> Level2 Interview </mat-header-cell>
	   <mat-cell class="break_space" *matCellDef="let element"> 
		   <span class="capitalize_text" [ngStyle]="getStatusColor(element.event_2_status)">{{ element.event_2_status }}</span> {{ element.event_2_interview }} 
		</mat-cell>
	</ng-container>
	<ng-container matColumnDef="event_2_organizer">
	   <mat-header-cell class="break_space" *matHeaderCellDef mat-sort-header> Level2 Agent </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{ element.event_2_organizer }} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="event_3_interview">
		<mat-header-cell class="break_space" *matHeaderCellDef mat-sort-header style="white-space: pre-wrap;"> Client Interview </mat-header-cell>
		<mat-cell class="break_space" *matCellDef="let element">
			<span class="capitalize_text" [ngStyle]="getStatusColor(element.event_3_status)">{{ element.event_3_status }}</span> {{ element.event_3_interview }}
		</mat-cell>
	 </ng-container>
	 <!-- <ng-container matColumnDef="event_3_organizer">
		<mat-header-cell *matHeaderCellDef> Client Agent </mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.event_3_organizer}} </mat-cell>
	 </ng-container> -->
	 <!-- <ng-container matColumnDef="event_3_status">
		<mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: pre-wrap;"> Client Status </mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.event_3_status}} </mat-cell>
	 </ng-container> -->
	<ng-container matColumnDef="training_start_date">
	   <mat-header-cell class="break_space" *matHeaderCellDef mat-sort-header> Training Start </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.training_start_date}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="training_end_date">
	   <mat-header-cell class="break_space" *matHeaderCellDef mat-sort-header> Training End </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.training_end_date}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="trainer_id">
	   <mat-header-cell *matHeaderCellDef mat-sort-header> Trainer </mat-header-cell>
	   <mat-cell *matCellDef="let element"> {{element.trainer_id}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="onboarding_status">
		<mat-header-cell class="break_space" *matHeaderCellDef mat-sort-header> Onboard Status </mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.onboarding_status}} </mat-cell>
	</ng-container>
	<ng-container matColumnDef="agency_type">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Agency Type</mat-header-cell>
		<mat-cell class="break_space" *matCellDef="let element">
			{{ element.master_campaign }} 
		</mat-cell>
	</ng-container>
	
	<ng-container matColumnDef="company">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Company</mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.company}} </mat-cell>
	 </ng-container>
	<ng-container matColumnDef="app_download">
		<mat-header-cell *matHeaderCellDef mat-sort-header> App</mat-header-cell>
		<mat-cell *matCellDef="let element"> {{element.app_download}} </mat-cell>
	 </ng-container>
	<ng-container matColumnDef="action">
		<mat-header-cell *matHeaderCellDef mat-sort-header> Action</mat-header-cell>
		<mat-cell class="break_space" *matCellDef="let element">
			<mat-select placeholder="Profile Status" [(value)]="element.status" (selectionChange)="changeProfileStatus($event.value, element)">
				<mat-option [value]="v" *ngFor="let v of _profileStatus">{{v}}</mat-option>
			   
			</mat-select>
		</mat-cell>
	</ng-container>
	<mat-header-row *matHeaderRowDef=displayedColumns  style="display: flex;"></mat-header-row>
	<mat-row *matRowDef="let row; columns: displayedColumns" style="display: flex;"></mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>