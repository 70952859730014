import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import * as _ from 'underscore';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { ApiService } from '../../api.service';
import { MyserviceService } from '../../myservice.service';
import { Utils } from '../../utils';
import { identifierModuleUrl } from '@angular/compiler';

@Component({
	selector: 'app-campaign-agents',
	templateUrl: './campaign-agents.component.html',
	styleUrls: ['./campaign-agents.component.scss']
})
export class CampaignAgentsComponent implements OnInit {

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	campaign_start_at: any = '';
	last_refreshed_at: any = '';
	agent_details: any = [];
	login_user = this.myService.get_loggedin_user();
	agent_table_arr = [];
	campaign_newAgents = [];
	selected_all: boolean = false;

	agent_table_columns: string[] = ['sl_no', 'name', 'email', 'active_status', 'data', 'dialled', 'contacted', 'followup'];
	public agentDataSource: any = new MatTableDataSource();


	currentLoader:boolean=false;
	api_end_point:any;
	constructor(
		public dialogRef: MatDialogRef<CampaignAgentsComponent>,
		@Inject(MAT_DIALOG_DATA) public input_data: any,
		private utils: Utils,
		private myService: MyserviceService,
		private API: ApiService
	) {
		//console.log('dialog div', this.input_data);
		this.campaign_start_at = this.utils.utcToLocal(this.input_data['created_at'], 'YYYY-MM-DDTHH:mm:ss', 'lll');
		this.last_refreshed_at = this.utils.utcToLocal(this.input_data['updated_at'], 'YYYY-MM-DDTHH:mm:ss', 'lll');
		let t = JSON.parse(this.input_data.agents_list);
		this.agent_details = t[this.login_user.groupid];
	}

	ngOnInit() {
		this.api_end_point = this.myService.get_API_End_Point();
		this.getAgentDataDetails();
		this.agentDataSource.paginator = this.paginator;
	}

	get_dial_priority() {
		switch (this.input_data.priority) {
			case 1:
				return 'Server Dial';
				break;
			case 2:
				return 'Predictive Dial with dial_ratio ' + (this.input_data.dial_ratio || 1);
				break;
			default:
				return 'Normal Dial'
				break;
		}
	}

	closePopup() {
		this.dialogRef.close();
	}

	getAgentDataDetails() {
		// debugger;
		this.currentLoader=true;
		let obj = {
			'campaign': this.input_data['campaign'],
			'center_id': this.login_user.groupid,
			'priority': this.input_data.priority,
			'agents_list': this.input_data.agents_list
		}
		this.API.getCampaignAgentsData(obj).subscribe((d: any) => {
			//console.log('data received for the agents data', d);
			// debugger;
			if (d && d.success) {
				this.currentLoader=false;
				let s = d.success;
				let p = _.flatten(_.values(s));
				this.agent_table_arr = p;
				//console.log('agents data', p);
				this.agentDataSource.data = p;
				this.agentDataSource.paginator = this.paginator;
				let q = d.team;
				for (var i in q) {
					if (!s.hasOwnProperty(q[i]['email'])) {
						q[i]['add_selected'] = false;
						this.campaign_newAgents.push(q[i])
					}
				}
			}
			else if(d.errors){
				alert("Agents not found. So, data is not there.");
				this.currentLoader=false;
			}
		})
	}

	updateAllSelected() {
		this.selected_all = this.campaign_newAgents != null && this.campaign_newAgents.every(t => t.add_selected);
	}
	someSelected(): boolean {
		if (this.campaign_newAgents == null) {
			return false;
		}
		return this.campaign_newAgents.filter(t => t.add_selected).length > 0 && !this.selected_all;
	}
	setAll(select: boolean) {
		this.selected_all = select;
		if (this.campaign_newAgents == null) {
			return;
		}
		this.campaign_newAgents.forEach(t => t.add_selected = select);
	}
	addAgents_tocampaign() {
		//console.log('printing agents list', this.campaign_newAgents);
		let obj = {
            "agents_list": [],
            "campaign_name": this.input_data['Campaign Name'],
            "priority": this.input_data['priority']
		}
		this.campaign_newAgents.forEach(t => {
			if (t.add_selected)
				obj.agents_list.push(t.email)
		})
		if (obj.agents_list.length) {
			this.API.addAgentstoCampaign(obj,this.api_end_point).subscribe((d: any) => {
				if (d.success) {
					alert('Agent add successfully');
				} else if(d.errors) {
					alert(d.errors)
				} else {
					alert('Unknown error');
				}
			})
		} else {
			alert('Select atlease one agent');
		}
	}
	submit_agent_status() {
		//console.log('printing existing agents', this.agentDataSource.data);
		let all_selected = true;
		let prev_status = null;
		let t = { active_list: [this.input_data['Campaign Name']], inactive_list: [this.input_data['Campaign Name']], active_agents_list: [], inactive_agents_list: [] };
		for (var i in this.agentDataSource.data) {
			if (prev_status === null) {
				prev_status = this.agentDataSource.data[i]['active_status'];
			}
			if (prev_status != this.agentDataSource.data[i]['active_status']) {
				all_selected = false;
				break;
			}
		}

		if (all_selected) {
			// Trigger full campaign turnoff/turnon switch
			let obj = {};
			if (prev_status) {
				obj['active_list'] = [this.input_data['Campaign Name']];
			} else {
				obj['inactive_list'] = [this.input_data['Campaign Name']]
			}
	
			this.API.campaignSwitch(obj,this.api_end_point).subscribe((res: any) => {
				if (res.errors) {
					alert('Camapign Switch Failed');
				} else {
					alert('Camapign Switch success.');
				}
			})
		} else {
			// Trigger each agent turn off/ turn on switch
			for (var i in this.agentDataSource.data) {
				if (this.agentDataSource.data[i]['active_status']) {
					t.active_agents_list.push(this.agentDataSource.data[i]['email']);
				} else {
					t.inactive_agents_list.push(this.agentDataSource.data[i]['email']);
				}
			}

			let active_obj = {
				'active_list': t.active_list,
				'active_agents_list': t.active_agents_list
			}
			let inactive_obj = {
				'inactive_list': t.inactive_list,
				'inactive_agents_list': t.inactive_agents_list
			}


			this.API.updateAgentStatus(active_obj,this.api_end_point).subscribe((d) => {
				//console.log('Active list updated');
				this.API.updateAgentStatus(inactive_obj,this.api_end_point).subscribe((d) => {
				//	console.log('Inactive List updated');
					alert('Agents status updated successfully');
				})
			})
		}
	}
}
