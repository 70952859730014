import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as faceapi from '../../../assets/face-api';
import { MyserviceService } from '../../myservice.service';
import { ApiService } from '../../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-face-recognize',
  templateUrl: './face-recognize.component.html',
  styleUrls: ['./face-recognize.component.scss']
})
export class FaceRecognizeComponent implements OnInit {
  showHideCameraDiv :boolean=true;
  showHideCameraAction:boolean=false;
  seconds:any;
  showHideCaptureDone:boolean=false;
  showHideTimer:boolean=false;
  faceShowing: boolean=false;
  captureStarted: boolean=false;
  captureDone: boolean=false;
  photoCount: number=0;
  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('canvas', { static: true }) 
  canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvas2', { static: true }) 
  canvas2: ElementRef<HTMLCanvasElement>;
  uploading: boolean=false;
  errorMessage: string="";// "Hold your face steadily for 30 seconds Align your face in the green oval box keep your eyes open.";
  private ctx: CanvasRenderingContext2D;
  
    videoWidth = 0;
    videoHeight = 0;
    constraints = {
        video: {
            facingMode: "user",
            width: { ideal: 300 },
            height: { ideal: 300 }
        }
    };
    myUser: any;
    welcomeUser: boolean=true;
    instructionCameraUser: boolean=false;
	api_end_point:any;
  constructor(private renderer: Renderer2, private API: ApiService, private service: MyserviceService, private router: Router) {
    
  }

  ngOnInit(): void {
    this.myUser = this.service.get_loggedin_user();
	this.api_end_point = this.service.get_API_End_Point();
	this.router.navigate(['/agentPluginPC/assessment']);
    return;
  }

  startCamera() {
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        navigator.mediaDevices.getUserMedia(this.constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
        
    } else {
        alert('Sorry, camera not available.');
    }
}

attachVideo(stream) {

  this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
      console.log("hi")
        this.videoHeight = this.videoElement.nativeElement.videoHeight;
        this.videoWidth = this.videoElement.nativeElement.videoWidth;
        console.log(this.videoWidth, this.videoHeight)
        //const canvas  = faceapi.createCanvasFromMedia(this.videoElement.nativeElement);
        // document.body.append(canvas)
        //this.canvas.nativeElement = canvas;
        const displaySize = { width: this.videoWidth, height: this.videoHeight }
        // faceapi.matchDimensions(this.canvas.nativeElement, displaySize);
        var scanInterval = setInterval(async () => {
        //   const detections = await faceapi.detectAllFaces(this.videoElement.nativeElement, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
           
          if(true){
            // console.log(detections[0].detection._score)
            this.faceShowing = true;
            if(this.captureStarted && this.photoCount < 30){
              console.log(this.photoCount)
              const displaySize = { width: this.videoWidth, height: this.videoHeight }

              // const detections = await faceapi.detectAllFaces(this.videoElement.nativeElement, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
              // const resizedDetections = faceapi.resizeResults(detections, displaySize);
              this.renderer.setProperty(this.canvas2.nativeElement, 'width', this.videoWidth);
              this.renderer.setProperty(this.canvas2.nativeElement, 'height', this.videoHeight);
              this.canvas2.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
              // faceapi.draw.drawDetections(this.canvas2.nativeElement, resizedDetections)
              // var link = document.createElement("a");
              // link.setAttribute('download', `${this.myUser.id}_${this.photoCount+1}.jpeg`);
              // link.setAttribute('href', this.canvas2.nativeElement.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream"));
              // link.click();this.canvas2.nativeElement.toBlob(resolve, mime, quality);
              
              this.canvas2.nativeElement.toBlob(blob=> {
                console.log(blob.type);
               
                if(this.photoCount < 30 && this.uploading == false){
                  this.uploading = true;
                  this.seconds = 30 - this.photoCount;
                  let formData = new FormData();
                  formData.append('image', blob, `${this.myUser.id}_${this.photoCount+1}.jpeg`);
					this.API.bulkUploadFaceCaptureAinexServer(formData)
                  .subscribe(
                    (res: any) => {
                      if (res.errors) {

                      } else {
                        if(res){
                          // alert(res);
                          this.uploading = false;
                          if(res && res.status == 'success'){
                            this.photoCount = this.photoCount + 1;
                            console.log(this.photoCount)
                            this.errorMessage="";// = "Hold your face steadily for 30 seconds Align your face in the green oval box keep your eyes open."
                          }else{
                            this.errorMessage = res.message;
                          }
                          
                          if(res && res.status == 'success' && this.photoCount == 29){
                            this.captureDone = true;
                            this.setFacialActivated();
                            this.captureStarted = false;
                            this.showHideTimer = false;
							this.API.bulkUploadFaceConfirmAinexServer().subscribe(
                              (res: any) => {

                              })
                            var objProfile = {
                              id: this.myUser.id
                            };
                            this.API.updateVisionStatus(objProfile,this.api_end_point).subscribe((data: any) => {
                              if (data) {
                                // alert("User updated successfully");
                                // alert(data.success);
                              }
                            });
                          }
                        }
                        if(res.file && res.file.split('_')[1].includes('30')){
                            this.captureDone = true;
                            this.setFacialActivated();
                            this.captureStarted = false
                        }
                        console.log(res)
                      }
                  });
                  // console.log(this.photoCount)
                  if(this.photoCount == 29){
                    setTimeout(()=>{
                    
                      
                    // this.captureDone = true;
                    this.canvas.nativeElement.width = 0;
                    this.canvas.nativeElement.height = 0;
                    console.log("taken")
                    }, 1000)
                  }
                  
                  
                }
                
                
                // console.log(this.photoCount)
                  
              }, 'image/jpeg');
	          	
              
            }
          }else{
            this.faceShowing = false;
          }
          // console.log(this.canvas.nativeElement)
          // const resizedDetections = faceapi.resizeResults(detections, displaySize)
          this.canvas.nativeElement.getContext('2d').clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)
          // faceapi.draw.drawDetections(this.canvas.nativeElement, resizedDetections)
          // faceapi.draw.drawFaceLandmarks(this.canvas.nativeElement, resizedDetections)
          // faceapi.draw.drawFaceExpressions(this.canvas.nativeElement, resizedDetections);
          if(this.captureDone){
            clearInterval(scanInterval);
            this.faceShowing = true;
            stream.getTracks().forEach(function(track) {
              track.stop();
            });
          }
          
        }, 500)
    });
    
  // Promise.all([
  //   faceapi.nets.tinyFaceDetector.loadFromUri('../../../assets/models'),
  //   faceapi.nets.faceLandmark68Net.loadFromUri('../../../assets/models'),
  //   faceapi.nets.faceRecognitionNet.loadFromUri('../../../assets/models'),
  //   faceapi.nets.faceExpressionNet.loadFromUri('../../../assets/models')
  // ]).then(()=>{
    
  // })
    
}

navigateAfterFacial(){
  var found = false;
  var stageSettings :any = this.service.get_stage_settings();
  if (stageSettings && stageSettings.extraoptions){
    for(let opt of stageSettings.extraoptions){
      if(opt.name == "assessment" &&  opt.definition == 'true'){
        found = true;
        this.API.checkAssessmentGiven(this.myUser.applicant_group,this.myUser.id,this.api_end_point).subscribe(
          (res: any) => {
            if(res.success == 1){
              this.router.navigate(['/agentPluginPC/assessment']);
              return;
            }else{
              this.router.navigate(['/agentPluginPC/home']);
            }
          }, (err) => {
            console.log(err)
          }
        )
        break;
      }
    }
    if(!found){
      this.router.navigate(['/agentPlugin/home']);
    }
  }else{
    this.router.navigate(['/agentPlugin/home']);
  } 
}

setFacialActivated(){
  var userDup = sessionStorage.getItem('user');
  userDup = JSON.parse(userDup);
  userDup['isVisonEnabled'] = true;
  sessionStorage.setItem('user',JSON.stringify(userDup))
  console.log(userDup)
}

async capture() {
  this.captureStarted = true;
  this.showHideTimer=true;
  // setTimeout(async ()=>{
  //   console.log("taking...")
  //                const noOfPhotos = 10;
  //                for(let i = 0; i < noOfPhotos; i++){
  //                 console.log("photo",i+1);
  //                 const displaySize = { width: this.videoWidth, height: this.videoHeight }

  //                 const detections = await faceapi.detectAllFaces(this.videoElement.nativeElement, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
  //                 const resizedDetections = faceapi.resizeResults(detections, displaySize);
  //                 this.renderer.setProperty(this.canvas2.nativeElement, 'width', this.videoWidth);
  //                 this.renderer.setProperty(this.canvas2.nativeElement, 'height', this.videoHeight);
  //                 this.canvas2.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  //                 faceapi.draw.drawDetections(this.canvas2.nativeElement, resizedDetections)
  //                       // faceapi.draw.drawFaceLandmarks(this.canvas2.nativeElement, resizedDetections)
  //                       // faceapi.draw.drawFaceExpressions(this.canvas2.nativeElement, resizedDetections)
  //                       // var imgData = this.canvas2.nativeElement.getContext('2d').getImageData(0, 0, this.canvas2.nativeElement.width, this.canvas2.nativeElement.height);
  //                       // let pixels = imgData.data;
  //                       // console.log(pixels.length)
  //                       // for (var j = 0; j < pixels.length; j += 4) {
                      
  //                       //   let lightness = parseInt(String((pixels[j] + pixels[j + 1] + pixels[j + 2]) / 3));
                      
  //                       //   imgData.data[j] = lightness;
  //                       //   imgData.data[j + 1] = lightness;
  //                       //   imgData.data[j + 2] = lightness;
  //                       // }
  //                       // console.log("converted");
  //                       // this.canvas2.nativeElement.getContext('2d').putImageData(imgData, 0, 0);
  //                 var link = document.createElement("a");
  //                 link.setAttribute('download', `${this.myUser.id}_${i+1}.jpeg`);
  //                 link.setAttribute('href', this.canvas2.nativeElement.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream"));
  //                 link.click();
  //                 if(i == noOfPhotos - 1){
  //                   this.captureDone = true;
  //                   this.canvas.nativeElement.width = 0;
  //                   this.canvas.nativeElement.height = 0;
  //                   console.log("taken")
  //                 }
  //                }
  // },1000)
        // setTimeout(async ()=>{
        //   this.seconds = 4;

        //  setTimeout(()=>{
        //    this.seconds = 3
        //    setTimeout(()=>{
        //     this.seconds = 2
        //     setTimeout(()=>{
        //       this.seconds = 1
        //       setTimeout(async ()=>{
        //          this.seconds = null;
        //          this.showHideTimer=false;
                 
                 
                 
        //        },1000) 
        //      },1000)        
        //    },1000)
        //  },1000) 
        // },1000)
  
}

handleError(error) {
    console.log('Error: ', error);
}
  readyWebCam(){
    this.showHideCameraDiv =false;
    this.showHideCameraAction=true;
    this.seconds = 30;

    this.welcomeUser=false;
    this.instructionCameraUser=true;
    setTimeout(()=>{
      this.startCamera();
      // setTimeout(()=>{
      //   this.capture();
      // },2000)
     },100)
   
  }
}
