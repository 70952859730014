<mat-drawer-container autosize>
	<mat-drawer #drawer class="gig-sidenav" mode="side" opened="true">
		<app-menu [navigateLink]="currentTitle"></app-menu>
	</mat-drawer>
	<app-logout></app-logout>
	<div class="CN-Main sbPad pd-25p">
		<p class="CN-Tab-head">
			<!-- LC Cordinator -->
			Fulfillment Co-ordinator
		</p>
		<div>
			<mat-card>
				<div class="row">
					<div class="col-md-3">
						<mat-form-field style="width:100%">
							<mat-label>Select Date</mat-label>
							<mat-select [(value)]="selectedDate" (selectionChange)="changeDate($event.value)">
								<mat-option value="Today">Today</mat-option>
								<mat-option value="yesterday">Yesterday</mat-option>
								<mat-option value="customDate">Custom Date</mat-option>
							</mat-select>
						</mat-form-field>

					</div>
					<!-- <div class="col-md-3" *ngIf="isShowDatePicker">
						<mat-form-field style="width:100%">
							<mat-label>Enter a date range</mat-label>
							<input matInput [matDatepicker]="pickerChooseDateFollowUp"
								(dateChange)="handleDateChange($event)" (click)="pickerChooseDateFollowUp.open()"
								readonly>
							<mat-datepicker-toggle matSuffix [for]="pickerChooseDateFollowUp"></mat-datepicker-toggle>
							<mat-datepicker #pickerChooseDateFollowUp></mat-datepicker>
						</mat-form-field>
					</div> -->
					<div class="col-md-3 mb-2">
						<mat-form-field appearance="outline" style="width: 100%;float:right;">
							<input matInput placeholder="Search" autocomplete="off"
								(keyup)="applySearchFilter1($event.target.value)">
							<mat-icon matSuffix
								style="font-size: 24px;position: relative;top: 6px;color: #c0cbd6;">search</mat-icon>
						</mat-form-field>
					</div>
					<div class="col-md-3 mb-2 pl-0">
						<button mat-button (click)="downloadReport()">
							DOWNLOAD
						</button>
						<button mat-button class="action_btn" (click)="uploadBtnClick()" style="margin-left: 20px;">UPLOAD</button>
					</div>
				</div>



				<div class="row"  *ngIf="isShowDatePicker">
					<div class="col-md-3">
						<mat-form-field style="width:100%">
							<mat-label>Start Date</mat-label>
							<input matInput (dateChange)="handleStartDate($event)" [max]="ChooseSelectedEndDateValue" [min]="back_date_thirty" [matDatepicker]="pickerChooseDateStart" [(ngModel)]="ChooseSelectedStartDateValue" (click)="pickerChooseDateStart.open()" readonly>
							<mat-datepicker-toggle matSuffix [for]="pickerChooseDateStart"></mat-datepicker-toggle>
							<mat-datepicker #pickerChooseDateStart></mat-datepicker>							
						</mat-form-field>
					</div>
					<div class="col-md-3">
						<mat-form-field style="width:100%">
							<mat-label>End Date</mat-label>
							<input matInput  (dateChange)="handleEndDate($event)" [min]="ChooseSelectedStartDateValue" [matDatepicker]="pickerChooseDateEnd" [(ngModel)]="ChooseSelectedEndDateValue" (click)="pickerChooseDateEnd.open()" readonly>
							<mat-datepicker-toggle matSuffix [for]="pickerChooseDateEnd"></mat-datepicker-toggle>
							<mat-datepicker #pickerChooseDateEnd></mat-datepicker>							
						</mat-form-field>
					</div>
					<div class="col-md-3 pl-0">
						<button mat-button class="btnAdd" (click)="goRangeBtn()" style="background-color: #fd6c21 !important;" >GO</button>
					</div>
				</div>
				<div class="row">
					<div class="col-md-3">
						<mat-card class="CN-Card cardW">
							<p><span class="CN-Tab-head1">Total Leads Received :- {{totalQAD}}</span></p>
							<p class="cn-p CNP1">{{approved}} <br> <span class="cn-sub-title">Full-filled</span> </p>
							<hr class="hr1">
							<p class="cn-p CNP2">{{rejectcount}}<br> <span class="cn-sub-title"> Not Completed</span>
							</p>

						</mat-card>
					</div>

				</div>
				<div class="row">
					<div class="col-md-12">
						<mat-table [dataSource]="dataSourceA" class="vkTableAgent">
							<ng-container matColumnDef="slno">
								<mat-header-cell *matHeaderCellDef style="flex:0 0 120px;"> Sl No </mat-header-cell>
								<mat-cell *matCellDef="let element;let i = index"
									style="flex:0 0 120px;">{{i+1}}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="customerName">
								<mat-header-cell *matHeaderCellDef>
									Customer&nbsp;Name
								</mat-header-cell>
								<mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
							</ng-container>
							<ng-container matColumnDef="customerNumber">
								<mat-header-cell *matHeaderCellDef>
									<!-- Customer&nbsp;Number -->
									Application&nbsp;Number
								</mat-header-cell>
								<mat-cell *matCellDef="let element">
									<!-- {{element.number}} -->
									{{getApplicationNumber(element) || '--'}}

								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="generationTime">
								<mat-header-cell *matHeaderCellDef>Appointment Details </mat-header-cell>
								<mat-cell *matCellDef="let element">
									<!-- {{element.start_time| slice:0:10 | date:'dd-MMM-yyyy'}}&ensp;{{element.start_time | date:'h:mm a'}} -->
									{{getAppointmentDateTime(element)}}
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="leadFixTime">
								<mat-header-cell *matHeaderCellDef>Lead Fix Time </mat-header-cell>
								<mat-cell *matCellDef="let element">
									{{getLeadFixDateTime(element)}}
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="qdeForms">
								<mat-header-cell *matHeaderCellDef class="flex-80">Leads Received </mat-header-cell>
								<mat-cell *matCellDef="let element" class="flex-80">
									<button mat-button class="action_btn" (click)="contactNameDetails(element)">VIEW</button>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="approve">
								<mat-header-cell *matHeaderCellDef class="flex-120"> &nbsp; </mat-header-cell>
								<mat-cell *matCellDef="let element" class="flex-120">
									<button mat-button class="action_btn f-f-btn" (click)="remarksBtn1(element,'FULL-FILLED')">
										<!-- Completed //(click)="approveBtn(element,'FULL-FILLED')"-->
										FULL-FILLED
									</button>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="decline">
								<mat-header-cell *matHeaderCellDef>&nbsp; </mat-header-cell>
								<mat-cell *matCellDef="let element">
									<!-- <button class="btnnn2" (click)="approveBtn(element,'not_completed')">Not Completed</button> -->
									<button mat-button class="action_btn" (click)="remarksBtn2(element,'not_completed')">NOT COMPLETED</button>

								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="reWork">
								<mat-header-cell *matHeaderCellDef style="display:none">&nbsp; </mat-header-cell>
								<mat-cell *matCellDef="let element" style="display:none">
									<!-- <button class="btnnn3" (click)="approveBtn(element,'rework')">ReWork</button> -->
									<button class="btnnn3" (click)="remarksBtn(element,'REWORK')">Remarks</button>
								</mat-cell>
							</ng-container>
							<mat-header-row *matHeaderRowDef=displayedColumnsA></mat-header-row>
							<mat-row *matRowDef="let row; columns: displayedColumnsA"></mat-row>
						</mat-table>
						<mat-paginator #paginatorA="matPaginator" [pageSizeOptions]="[5, 10, 20]"
							showFirstLastButtons></mat-paginator>
					</div>
				</div>

			</mat-card>
		</div>
	</div>
</mat-drawer-container>

<div class="dc-popupoverlayApprove" *ngIf="showHidePopupApprove">
	<div class="dc-delete-popupApprove">
		<div class="p-4">
			<div class="row mt-0">
				<div class="col-md-12 mt-2">
					<p><b>Approve</b></p>
					<div class="text-right btn-grp">
						<button mat-button class="action_btn" (click)="noDocBtn()">NO DOC</button>
						<button mat-button class="action_btn" (click)="withDocBtn()">WITH DOC</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="dc-popupoverlayApprove" *ngIf="showHideRemarks">
	<div class="dc-delete-popupApprove">
		<div class="p-4">
			<div class="row mt-0">
				<div class="col-md-12 mt-2">
					<p><b>Remarks</b></p>
					<div *ngIf="showHideForNotComplted">
						<mat-form-field style="width:100%">
							<mat-label>Select Reason</mat-label>
							<mat-select (selectionChange)="changeReasonNotCompleted($event.value)"
								[(ngModel)]="notCompletedModel">
								<mat-option value="NOT-INTERESTED">NOT INTERESTED</mat-option>
								<mat-option value="NOT-CONTACTABLE">NOT CONTACTABLE</mat-option>
								<mat-option value="OCL-NON-CAF">OCL / NON-CAF</mat-option>
								<mat-option value="reschedule_date_time">RESCHEDULE DATE & TIME</mat-option>
								<mat-option value="TECHNICAL-ISSUE">TECHNICAL ISSUE</mat-option>
							</mat-select>
						</mat-form-field>
						<div *ngIf="showHideResheduleDateTime">
							<mat-form-field>
								<input matInput [matDatepicker]="pickerReshedule" placeholder="DD/MM/YYYY"
									[(ngModel)]="dateReshedule" autocomplete="off" class="kycDate"
									(click)="pickerReshedule.open()" readonly [min]="minDate2" />
								<mat-datepicker-toggle matSuffix [for]="pickerReshedule"></mat-datepicker-toggle>
								<mat-datepicker #pickerReshedule></mat-datepicker>
							</mat-form-field>
							<input class="reshTime pl-2" type="time" id="timeIdReshedule" name="timeIdReshedule"
								[(ngModel)]="reTimeModel">

						</div>

					</div>
					<div>
						<mat-form-field style="width:100%">
							<input matInput placeholder="Remarks" [(ngModel)]="remarksInputModel">
						</mat-form-field>
					</div>
					<p class="text-right btn-grp">
						<button mat-button class="action_btn" (click)="cancelRemarks()">CANCEL</button>
						<button mat-button (click)="submitRemarks()">SUBMIT</button>
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div class="dc-popupoverlayApprove" *ngIf="showHideResheduleDateTime">
	<div class="dc-delete-popupApprove">
		<div class="p-4">
			<div class="row mt-0">
				<div class="col-md-12 mt-2">
					<p><b>Reschedule Date & Time</b></p>
					<div>
						<mat-form-field>
						<input matInput [matDatepicker]="pickerReshedule" placeholder="DD/MM/YYYY" [(ngModel)]="dateReshedule"
									autocomplete="off" class="kycDate" (click)="pickerReshedule.open()" readonly [min]="minDate2"/>
								<mat-datepicker-toggle matSuffix [for]="pickerReshedule"></mat-datepicker-toggle>
								<mat-datepicker #pickerReshedule></mat-datepicker>
							</mat-form-field>
								<input class="reshTime pl-2" type="time" id="timeIdReshedule" name="timeIdReshedule"  [(ngModel)]="reTimeModel" >

					</div>
					<p class="text-right">

                        <button  class="subDocBtnx2" (click)="submitReshedule()">SUBMIT</button>
					</p>
				</div>
				</div>
		</div>
	</div>
</div> -->
<div id="cover-spin" *ngIf="currentLoader"></div>