import { Component, OnInit, ViewChild,Renderer2,ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-uearn-slide',
  templateUrl: './uearn-slide.component.html',
  styleUrls: ['./uearn-slide.component.scss']
})
export class UearnSlideComponent implements OnInit, AfterViewInit {

	@ViewChild('uearnSlide', {static: false, read: ElementRef}) slide: ElementRef;
	constructor(
		
	) { }

	ngOnInit() {

	}

	ngAfterViewInit() {
		var child = this.slide.nativeElement.children[0].children;
		// console.log('After init',child);
		for (var i in child) {
			// console.log('child', i, child[i]);
			let currentIndex = parseInt(i) + 1;
			if (isNaN(child[i])) {
				child[i].innerText = currentIndex;
			}
		}
		
	}

}
