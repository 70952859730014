import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-desktop-contact-details-page',
  templateUrl: './desktop-contact-details-page.component.html',
  styleUrls: ['./desktop-contact-details-page.component.scss']
})
export class DesktopContactDetailsPageComponent implements OnInit {

  cust:any;
  arrayCustomVs:any=[];
  constructor(public dialogRef: MatDialogRef<DesktopContactDetailsPageComponent>,@Inject(MAT_DIALOG_DATA) public viewData,) { }

  ngOnInit() {
   // console.log(this.viewData)
    if(this.viewData.customkvs){
			this.cust = this.viewData.customkvs.split("\\r");
			for(let i=0;i<this.cust.length ;i++){
				this.arrayCustomVs.push({
					"labelName":this.cust[i].split(":")[0],
					"valueOfLabel":this.cust[i].split(":")[1]?this.cust[i].split(":")[1]:'--'
				})
}
//console.log(this.arrayCustomVs);
	}
  }
  closeDialog() {
    this.dialogRef.close();
  }
   oddOrEven(num){
	if(num % 2 == 0)
		return false;
	return true;
}

}
