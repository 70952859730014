import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'underscore';
import { MyserviceService } from 'src/app/myservice.service';
import { MatDialog } from '@angular/material/dialog';
import { PaymentDashboardDetailsviewDialogComponent } from '../payment-dashboard-detailsview-dialog/payment-dashboard-detailsview-dialog.component';
import { ApiService } from 'src/app/api.service';
import { EarningsAgentPluginComponent } from '../agentPlugin/earnings-agent-plugin/earnings-agent-plugin.component';
import { PaymentDashboardUploadDialogComponent } from '../payment-dashboard-upload-dialog/payment-dashboard-upload-dialog.component';
import { MatInput } from '@angular/material/input';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Utils } from '../utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {FormControl} from '@angular/forms';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';

export const MY_FORMATS = {
	parse: {
	  dateInput: 'MM/YYYY',
	},
	display: {
	  dateInput: 'MM/YYYY',
	  monthYearLabel: 'MMM YYYY',
	  dateA11yLabel: 'LL',
	  monthYearA11yLabel: 'MMMM YYYY',
	},
  };

@Component({
	selector: 'app-payment-dashboard-table',
	templateUrl: './payment-dashboard-table.component.html',
	styleUrls: ['./payment-dashboard-table.component.scss'],
	providers: [
		// `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
		// application's root module. We provide it at the component level here, due to limitations of
		// our example generation script.
		{
		  provide: DateAdapter,
		  useClass: MomentDateAdapter,
		  deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
	
		{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
	  ],
})
export class PaymentDashboardTableComponent implements OnInit {
	displayedColumns: string[] = ['gig_id', 'user_id', 'name', 'email', 'actual_earnings', 'total_deductions', 'opening_balance', 'total_earnings', 'closing_balance', 'details'];
	dataSource: any = new MatTableDataSource();


	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	selectedCurYear: any;
	selectedMonth: any;
	table_data: any = [];
	_numberMonthCurrent: any;
	_historyRecord: any = [];
	currentLoader: boolean = false;
	NoHistoty: any = "";
	showHideNoHistotyMsg: boolean = false;
	currency_code:any;
	startDate = new Date(2021, 0, 1);
	date = new FormControl(moment());
	api_end_point:any;

	@ViewChild('searchInput', {
		read: MatInput
	}) searchInput: MatInput;
	constructor(public dialog: MatDialog,private myService: MyserviceService,private API: ApiService, private utils: Utils,private _snackBar: MatSnackBar) { }

	ngOnInit() {
		this.getCurrentMonthYear();
		this.getTableData();
		this.api_end_point = this.myService.get_API_End_Point();
	}
	getCurrentMonthYear() {
		let _aboutToday = new Date();
		// let _fullDate = _aboutToday.getUTCDate();
		let _getNowMonth = _aboutToday.toLocaleString('default', { month: 'long' });
		let _getCurrentMonth = _getNowMonth.substring(0, 3)
		let _getCurrentYear = _aboutToday.getFullYear();
		this.selectedMonth = _getCurrentMonth;
		this.selectedCurYear = _getCurrentYear.toString();

		this._numberMonthCurrent = _aboutToday.getMonth();

	}

	getTableData() {
		this.table_data = [];
		this.currentLoader = true;
		this.showHideNoHistotyMsg = false;
		// debugger;		
		let firstDay = new Date(this.selectedCurYear, this._numberMonthCurrent, 1);
		let lastDay = new Date(this.selectedCurYear, this._numberMonthCurrent + 1, 0);

		let d1 = moment(firstDay).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
		let d2 = moment(lastDay).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
		let obj = {
			"start_date": d1,
			"end_date": d2
		};
		//console.log(obj);
		this.API.settingsAPI(this.api_end_point).subscribe((data: any) => {
			if (data.errors) {
				console.log(data.errors);
				return;
			}
			if (data.success) {
				//console.log(data.success);
				var setting = data.success;
	
				for (const currencysetting of setting.payment) {
					if (currencysetting.name == "currency_code") {
					//	console.log("currency Symbol is ",currencysetting.definition);
					this.currency_code= currencysetting.definition;
					} 
				}
			}
		})
		this.API.getPaymentdashboardTable(obj,this.api_end_point).subscribe((data: any) => {
			// debugger;
			if (data.errors == "No history found") {
				this.NoHistoty = data.errors;
				this.showHideNoHistotyMsg = true;
				this.currentLoader = false;
				this.dataSource = new MatTableDataSource();
				this.dataSource.paginator = this.paginator;
				setTimeout(() => {
					this.showHideNoHistotyMsg = false;
				}, 4000)
				return;
			}
			else{
			this.currentLoader = false;
			let _response = data.success;
			this._historyRecord = data.success;
			this.NoHistoty="";
			if (_response) {
				for (var key in _response) {
					if (_response.hasOwnProperty(key)) {
						this.table_data.push(_response[key].summary);
					}
				}
				this.dataSource = new MatTableDataSource(this.table_data);
				this.dataSource.paginator = this.paginator;
				this.currentLoader = false;
			}
		}
		});

	}

	viewMore(element: any) {
		//debugger;
		let obj = {
			"element": element,
			"historytable": this._historyRecord
		}
		const dialogRef = this.dialog.open(PaymentDashboardDetailsviewDialogComponent, {
			width: '80%',
			height: '90%',
			disableClose: true,
			panelClass: 'custom-modalbox',
			data: obj
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log("Dialog is closed");
		});
	}
	applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	//	debugger;
	}
	uploadExcelBtn() {
		const dialogRef = this.dialog.open(PaymentDashboardUploadDialogComponent, {
			width: '600px',
			height: '50%',
			disableClose: true,
			data: ""
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log("Dialog is closed");
		});
	}
	/* 
	changeYear(val: any) {
		this.selectedCurYear = "";
		this.selectedCurYear = val;
		this.getTableData();
		this.searchInput.value = '';

	}
	changeMonth(val: any) {
		this.selectedMonth = "";
		this.selectedMonth = val;
		if (this.selectedMonth == "Jan") { this._numberMonthCurrent = 0 }
		if (this.selectedMonth == "Feb") { this._numberMonthCurrent = 1 }
		if (this.selectedMonth == "Mar") { this._numberMonthCurrent = 2 }
		if (this.selectedMonth == "Apr") { this._numberMonthCurrent = 3 }
		if (this.selectedMonth == "May") { this._numberMonthCurrent = 4 }
		if (this.selectedMonth == "Jun") { this._numberMonthCurrent = 5 }
		if (this.selectedMonth == "Jul") { this._numberMonthCurrent = 6 }
		if (this.selectedMonth == "Aug") { this._numberMonthCurrent = 7 }
		if (this.selectedMonth == "Sep") { this._numberMonthCurrent = 8 }
		if (this.selectedMonth == "Oct") { this._numberMonthCurrent = 9 }
		if (this.selectedMonth == "Nov") { this._numberMonthCurrent = 10 }
		if (this.selectedMonth == "Dec") { this._numberMonthCurrent = 11 }

		this.getTableData();
		this.searchInput.value = '';
	} */

	chosenYearHandler(normalizedYear: Moment) {
		const ctrlValue = this.date.value;
		ctrlValue.year(normalizedYear.year());
		this.date.setValue(ctrlValue);
	  }
	
	  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
		const ctrlValue = this.date.value;
		this._numberMonthCurrent = normalizedMonth.month()
		//console.log("selected month "+normalizedMonth.month());
		ctrlValue.month(normalizedMonth.month());
		this.date.setValue(ctrlValue);
		datepicker.close();
		this.getTableData();
		//console.log(this.date.value);
	  }
	refreshBtn() {
		this.getTableData();
		this.searchInput.value = '';
	}

	exportExcel() {
		//console.log('table data', this._historyRecord);
		//console.log('this is datasourcetable',this.dataSource.data);
		//console.log('this is filter data',this.dataSource.filteredData);
		let data = [];
		// return;
		var currentmonth = new Date().getMonth();
		//console.log(this._numberMonthCurrent + "---" +this.NoHistoty);
		if (this.NoHistoty == "No history found") {
			this._snackBar.open("No history found", "close");
		}		
		/* else if (this.dataSource.filteredData) {
			for(var i in this.dataSource.filteredData){
				const summary = this.dataSource.filteredData;
				this.dataSource.filteredData.forEach(obj => {
					obj['gig_id'] = this.dataSource.filteredData['gig_id'];
					obj['user_id'] = this.dataSource.filteredData['user_id'];
					obj['agent_name'] = this.dataSource.filteredData['name'];
					obj['email'] = this.dataSource.filteredData['email'];
					obj['companycode'] = this.dataSource.filteredData['companycode'];
					data.push(obj);
				});
			}
			console.log('data after-1', data);
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet('Approval Report');
			worksheet.addRow(['Gig ID', 'Agent ID', 'Agent Name', 'Agent Email', 'Cost Code', 'Transaction Date', 'Transaction Name', 'Transaction Type', 'Gross Earnings', 'Tax Deductions', 'Opening Balance', 'Amount', 'Closing Balance', 'Description']);

			let table_keys = ['gig_id', 'user_id', 'agent_name', 'email', 'companycode', 'created_at', 'name', 'type', 'actual_earnings', 'deductions', 'opening_balance', 'amount', 'closing_balance', 'description'];

			for (var k in data) {
				let arr = [];
				const loc_obj = data[k];
				for (var i in table_keys) {
					const key = table_keys[i];
					if (loc_obj.hasOwnProperty(key)) {
						if ((key == 'created_at')) {
							if (loc_obj.hasOwnProperty(key)) {
								arr.push(this.utils.utcToLocal(loc_obj[key], 'YYYY-MM-DDTHH:mm:ss', 'lll'))
							}
						} else {
							arr.push(loc_obj[key]);
						}
					} else {
						arr.push('');
					}
				}
				worksheet.addRow(arr);
			}
			workbook.xlsx.writeBuffer().then((buff_data) => {
				let blob = new Blob([buff_data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, 'Agent_transactions.xlsx');
			})
		} */
		else {
			//for (var i in this._historyRecord) {
			/* for(var i in this.dataSource.data){
				const summary = this.dataSource.data;
				this.dataSource.data.forEach(obj => {
					obj['gig_id'] = this.dataSource.data['gig_id'];
					obj['user_id'] = this.dataSource.data['user_id'];
					obj['agent_name'] = this.dataSource.data['name'];
					obj['email'] = this.dataSource.data['email'];
					obj['companycode'] = this.dataSource.data['companycode'];
					data.push(obj);
				});
			} */
			for (var i in this.dataSource.filteredData) {
				const actual_obj = this._historyRecord[this.dataSource.filteredData[i]['user_id']];
				// console.log('actual obj', actual_obj);
				const summary = this.dataSource.filteredData[i]['summary'];
				actual_obj.history.forEach(obj => {
					obj['gig_id'] = actual_obj.summary['gig_id'];
					obj['user_id'] = actual_obj.summary['user_id'];
					obj['agent_name'] = actual_obj.summary['name'];
					obj['email'] = actual_obj.summary['email'];
					obj['companycode'] = actual_obj.summary['companycode'];
					data.push(obj);
				});
			}
			// console.log('data after-2', data);
			//debugger;
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet('Approval Report');
			worksheet.addRow(['Gig ID', 'Agent ID', 'Agent Name', 'Agent Email', 'Cost Code', 'Transaction Date', 'Transaction Name', 'Transaction Type', 'Gross Earnings', 'Tax Deductions', 'Opening Balance', 'Amount', 'Closing Balance', 'Description']);

			let table_keys = ['gig_id', 'user_id', 'agent_name', 'email', 'companycode', 'created_at', 'name', 'type', 'actual_earnings', 'deductions', 'opening_balance', 'amount', 'closing_balance', 'description'];

			for (var k in data) {
				let arr = [];
				const loc_obj = data[k];
				for (var i in table_keys) {
					const key = table_keys[i];
					if (loc_obj.hasOwnProperty(key)) {
						if ((key == 'created_at')) {
							if (loc_obj.hasOwnProperty(key)) {
								arr.push(this.utils.utcToLocal(loc_obj[key], 'YYYY-MM-DDTHH:mm:ss', 'lll'))
							}
						} else {
							arr.push(loc_obj[key]);
						}
					} else {
						arr.push('');
					}
				}
				worksheet.addRow(arr);
			}
			workbook.xlsx.writeBuffer().then((buff_data) => {
				let blob = new Blob([buff_data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, 'Agent_transactions.xlsx');
			})
		}
	}
}

