import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import * as _ from 'underscore';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { MatDialog } from '@angular/material/dialog';

import { ApiService } from '../../api.service';

import { AGENT_LOGIN_RESPONSE } from '../../interface';

import { Utils } from '../../utils';

import { AgentActivityComponent } from '../agent-activity/agent-activity.component';
import { MyserviceService } from '../../myservice.service';

class ActivityTimerValues{
	private api_data = null;
	private TABLE_DATA = [];
	private utils = new Utils();
	constructor(api_response) {
		this.api_data = api_response;
		if (this.api_data) {
			this.prepareTableData();
		}
	}

	clearTableData() {
		this.TABLE_DATA.length = 0;
	}

	prepareTableData() {
		this.clearTableData();
		for (var i in this.api_data) {
			let obj: AGENT_LOGIN_RESPONSE = {
				/* "date": this.api_data[i]['Date'],
				"agent_name": this.api_data[i]['Agent Name'],
				"agent_email": this.api_data[i]['Agent Email'],
				"agent_number": this.api_data[i]['Agent Number'],
				"agent_id": this.api_data[i]['Agent ID'],
				"login_hrs": this.api_data[i]['Login Hours'],
				"active_hrs": this.api_data[i]['Active Hours'],
				'break_hrs': this.api_data[i]['Break Hours'] */
				"agent_id": this.api_data[i]['agent']['id'],
				"agent_name": this.api_data[i]['agent']['name'],
				"agent_email": this.api_data[i]['agent']['email'],
				"login_time": String(this.utils.utcToLocal(this.api_data[i]['summary']['first_login'], 'YYYY-MM-DDTHH:mm:ss', 'hh:mm:ss A')),
				"logout_time": (this.api_data[i]['summary']['logout']) ? String(this.utils.utcToLocal(this.api_data[i]['summary']['logout'], 'YYYY-MM-DDTHH:mm:ss', 'hh:mm:ss A')) : "",
				"active_hrs": this.utils.convertSectoHMS(this.api_data[i]['summary']['active_duration']),
				"break_hrs": this.utils.convertSectoHMS(this.api_data[i]['summary']['break_duration']),
				"data": this.api_data[i]['data']
			};
			this.TABLE_DATA.push(obj);
		}
	}

	exportTableData() {
		return this.TABLE_DATA;
	}
}

@Component({
	selector: 'app-activity',
	templateUrl: './activity.component.html',
	styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
	api_end_point:any;
	constructor(
		private utils: Utils,
		private activateRoute: ActivatedRoute,
		private API: ApiService,
		private dialog: MatDialog,
		private myService:MyserviceService
	) { }

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	
	currentTitle = "activity";
	currentLoader = false;
	maxDate = new Date();
	StartDate = this.utils.getLocalDate('YYYY-MM-DD');
	EndDate = this.utils.getLocalDate('YYYY-MM-DD');
	API_START_DATE = moment(this.StartDate).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
	API_END_DATE = moment(this.StartDate).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
	rawTableData = [];
	activity_table: any = new MatTableDataSource();
	activity_table_columns = ["agent_name", "agent_email", "login_time", "active_hrs", "break_hrs", "logout_time", "data"];

	ngOnInit(): void {
		this.activateRoute.queryParams.subscribe(params => {
			// console.log('on init params', params);
			if (params.start_date) {
				this.StartDate = params['start_date']
			}
			if (params.end_date) {
				this.EndDate = params['end_date']
			}
		})
		this.api_end_point = this.myService.get_API_End_Point();
		this.refreshData();
	}

	change_date_status(key: string, event: MatDatepickerInputEvent<Date>) {
		this.API_START_DATE = moment(this.StartDate).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
		this.API_END_DATE = moment(this.StartDate).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
		/* if (key == 'start_date') {
			this.StartDate = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value).toString();
			if (moment(this.StartDate).diff(moment(this.EndDate)) >= 0) {
				this.EndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value).toString();
			}
		} else {
			this.EndDate = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value).toString();
			if (moment(this.StartDate).diff(moment(this.EndDate)) >= 0) {
				this.EndDate = undefined;
				alert("Start date should be less than end date");
			}

		} */
	}

	refreshData() {
		// console.log('calling refresh data starts');
		this.getAgentActivityDataByAPI();
	}

	exportExcel() {
		// console.log('calling export excel starts');
		let table_data = this.activity_table.data;
		let current_date = moment(this.StartDate,'YYYY-MM-DD').format('ll');
		let excel_columns = [
			{
				title: 'Date',
				key: 'date'
			},
			{
				title: 'Agent Name',
				key: 'agent_name'
			},
			{
				title: 'Agent Email',
				key: 'agent_email'
			},
			{
				title: 'Login Time',
				key: 'login_time'
			},
			{
				title: 'Active Time',
				key: 'active_hrs'
			},
			{
				title: 'Break Time',
				key: 'break_hrs'
			},
			{
				title: 'Logout Time',
				key: 'logout_time'
			}
		]
		if (table_data && table_data.length) {
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet('Call Dispositions');
			worksheet.addRow(_.pluck(excel_columns, 'title'));
			table_data.forEach(d => {
				let arr = [];
				for (var i in excel_columns) {
					if (excel_columns[i]['key'] == 'date') {
						arr.push(current_date);
					} else {
						if (d.hasOwnProperty(excel_columns[i]['key'])) {
							arr.push(d[excel_columns[i]['key']])
						} else {
							arr.push("")
						}
					}
				}
				let row = worksheet.addRow(arr);
				//	console.log('printing row', row);
			})
			//Generate & Save Excel File
			workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, 'AgentActivityReport.xlsx');
			})
		}
	}

	applySearchFilter(val:string) {
		// console.log('Search filter applied', val);
		this.activity_table.filter = val.trim().toLocaleLowerCase();
	}

	getAgentActivityDataByAPI() {
		let obj:any = {};
		// obj.start_date = this.utils.format_to(this.StartDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD');
		// obj.end_date = this.utils.format_to(this.EndDate, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD');
		obj.start_date = this.API_START_DATE;
		obj.end_date = this.API_END_DATE;
		// obj.start_date = moment().startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
		// obj.end_date = moment().endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
		this.currentLoader = true;
		// console.log('printing obj', obj);
		// return;
		this.API.getGroupAgentActivityAPI(obj,this.api_end_point).subscribe((res: any) => {
			// console.log('result coming', res);
			// return;
			if (res.success) {
				let p = new ActivityTimerValues(res.success);
				this.rawTableData = p.exportTableData();
				this.prepareTable(this.rawTableData);
			}
			if (res.errors) {
				alert('Error coming');
			}
			this.currentLoader = false;
		}, (err: any) => {
				// console.log('error coming', err);
				this.currentLoader = false;
		})
	}

	prepareTable(data) {
		this.activity_table = new MatTableDataSource(data);
		this.activity_table.paginator = this.paginator;
		this.activity_table.sort = this.sort;
	}

	openDetailActivity(row) {
		row.selectedDate = this.StartDate;
		// console.log('row element', row);
		// return;
		var dialogRef = this.dialog.open(AgentActivityComponent, {
			width: '60%',
			height:'90%',
			disableClose: true,
			data: row
		});
		/* row.start_date = this.API_START_DATE;
		row.end_date = this.API_END_DATE;
		var dialogRef = this.dialog.open(AgentActivityComponent, {
			width: 'auto',
			hasBackdrop: true,
			data: row
		}); */
	}
}
