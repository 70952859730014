import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-contiinex-privacy-policy-section-page',
  templateUrl: './new-contiinex-privacy-policy-section-page.component.html',
  styleUrls: ['./new-contiinex-privacy-policy-section-page.component.scss']
})
export class NewContiinexPrivacyPolicySectionPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
