import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { DocUploadComponent } from './doc-upload/doc-upload.component';
import { DocViewComponent } from './doc-view/doc-view.component';

import { ServicesService } from '../dashboard/services.service';
import { ApiService } from '../api.service';

import { Utils } from '../utils';
import { DocStatusComponent } from './doc-status/doc-status.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MyserviceService } from '../myservice.service';

@Component({
	selector: 'app-opsdashboard',
	templateUrl: './opsdashboard.component.html',
	styleUrls: ['./opsdashboard.component.scss'],
	providers: [DocUploadComponent, DocViewComponent, DocStatusComponent]
})
export class OpsdashboardComponent implements OnInit {

	currentLoader = false;
	currentTitle = 'opsdashboard';
	today = new Date();
	tomorrow = moment().add(1, 'days');

	rawData = [];
	utils = new Utils();
	startDateFilter = this.utils.localToUTC(moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
	endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false);
	@ViewChild(' fromInput', {
		read: MatInput
	}) fromInput: MatInput;
	showHideDateCondition: boolean = false;
	@ViewChild('_searchValue') _searchValue: ElementRef;
	default_summary = function () {
		return {
			'doc': {
				'in_progress': 0,
				'verified': 0,
				'rejected': 0
			},
			'l1': {
				'total': 0,
				'selected': 0,
				'rejected': 0
			},
			'l2': {
				'total': 0,
				'selected': 0,
				'rejected': 0
			},
			'client': {
				'total': 0,
				'selected': 0,
				'rejected': 0
			}
		}
	}

	displayedColumns = [
		'sl_no',
		'user_id',
		'applicant_name',
		'aadhar_no',
		'applicant_number',
		'reg_date',
		'assessment_score',
		'typing_speed',
		'l1_interview',
		'l2_interview',
		'client_interview',
		'resume',
		'aadhar_doc',
		'pan_doc',
		'service_doc',
		'passbook_doc',
		'SSC_Certificate',
		'HSC_Certificate',
		'education_certificate',
		'typing_certificate',
		'GST_Certificate',
		'ITR_Certificate',
		'status',
		'action',
		'verified_by',
		'last_update'
	];

	ops_summary = this.default_summary();

	@ViewChild(MatPaginator) paginator: MatPaginator;
	dataSource = new MatTableDataSource();
	api_end_point:any;

	constructor(
		private dashService: ServicesService,
		private dialog: MatDialog,
		private API: ApiService,
		private myservice: MyserviceService,
	) {
	//	console.log('ops component construct start');
		this.utils = new Utils();
		this.refreshData();
	}

	ngOnInit() {
		this.api_end_point = this.myservice.get_API_End_Point();
		//console.log('ops component oninit start', this.rawData);
		// this.dashService.applicantData.subscribe((d) => this.rawData = d);
	}

	refreshData() {
		this.currentLoader = true;
	//	console.log('Refreshing the data');
		this.dashService.setOPSTableData(this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.startDateFilter), this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', true, this.endDateFilter),this.api_end_point).then(() => {
			this.dashService.ops_table_data.subscribe(d => this.rawData = d);
			//console.log('data received', this.rawData);
			this.prepareTableData();
			this.currentLoader = false;
			this._searchValue.nativeElement.value = "";
		});
	}

	applyFilter(val: string) {
		// console.log('Filter apply');		
		this.dataSource.filter = val.trim().toLowerCase();
	}

	checkObj(ele, key) {
		let obj = { isUploaded: false };
		// ['AADHAR','PAN','PASS BOOK','SERVICE AGREEMENT', 'OTHERS']
		if (ele.hasOwnProperty(key)) {
			obj.isUploaded = true;
			obj['value'] = ele[key]['value'];
			obj['file_name'] = ele[key]['file_name'];
			obj['url'] = this.API.base_url + '/' + ele[key]['container'] + '/' + ele[key]['file_name'];
			obj['date'] = this.utils.utcToLocal(ele[key]['updated_at'], 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY');
		}
		return obj;
	}

	prepareTableData() {
		this.currentLoader = true;
		this.dataSource = new MatTableDataSource();
		let d: any[] = this.rawData;
		console.log('printing raw data', d);
		if (d && d.length) {
			let arr = [];
			for (var i in d) {
				let loc = d[i];
				let obj = {};
				obj['index'] = i;
				obj['sl_no'] = loc['candidate_id'];
				obj['user_id'] = loc['agent_reference_id'] || loc['candidate_id'];
				obj['applicant_id'] = loc['applicant_id'];
				obj['applicant_email'] = loc['applicant_email'];
				obj['applicant_name'] = loc['applicant_name'];
				var adharObj: any = this.checkObj(loc, 'AADHAR');
				obj['aadhar_no'] = adharObj ? adharObj.value : "";
				obj['applicant_number'] = loc['applicant_number'];
				obj['reg_date'] = this.utils.utcToLocal(loc['applicant_reg_date'], 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY');
				obj['l1_interview_date'] = this.utils.utcToLocal(loc['event_1_date'], 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY');
				obj['l1_status'] = loc['event_1_status'];
				obj['l2_interview_date'] = this.utils.utcToLocal(loc['event_2_date'], 'YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY');
				obj['l2_status'] = loc['event_2_status'];
				obj['client_interview'] = loc['event_3_status'];
				obj['aadhar'] = this.checkObj(loc, 'AADHAR');
				obj['SSC'] = this.checkObj(loc, 'SSC');
				obj['HSC'] = this.checkObj(loc, 'HSC');
				obj['education_certificate'] = this.checkObj(loc, 'EDUCATION');
				//console.log(obj['education_certificate'])
				obj['typing_certificate'] = this.checkObj(loc, 'TYPING_CERTIFICATE');
				obj['GST'] = this.checkObj(loc, 'GST');
				obj['ITR'] = this.checkObj(loc, 'ITR');
				obj['resume'] = this.checkObj(loc, 'RESUME');
				obj['pan'] = this.checkObj(loc, 'PAN');
				obj['pass_book'] = this.checkObj(loc, 'PASS BOOK');
				obj['service'] = this.checkObj(loc, 'SERVICE AGREEMENT');
				obj['others'] = this.checkObj(loc, 'OTHERS');
				obj['doc_status'] = loc['onboard_status'];
				obj['assessment_score'] = loc['assessment_score'];
				obj['typing_speed'] = loc['typing_speed'];
				obj['verified_by'] = loc['verified_by'];
				obj['last_update'] = loc['last_update'];
				arr.push(obj);
			}
		//	console.log('table data', arr);
			this.dataSource = new MatTableDataSource(arr);
			this.dataSource.paginator = this.paginator;
			this.prepareSummaryData();
			this.currentLoader = false;
		} else {
			alert('Data not found');
		}
	}

	prepareSummaryData() {
		let d = this.dataSource.data;
		let summary = this.default_summary();
		if (d && d.length) {
			for (var i in d) {
				let loc = d[i];
				if (loc && loc['doc_status']) {

					if (loc['doc_status']) {
						if (loc['doc_status'] == 'In Progress') {
							summary.doc.in_progress += 1;
						}
						if (loc['doc_status'] == 'Verified')//'VERIFIED'
						{
							summary.doc.verified += 1;
						}
						if (loc['doc_status'] == 'Rejected') //'REJECTED'
						{
							summary.doc.rejected += 1;
						}
					}
				}
				if (loc && loc['l1_status'] && loc['l1_status'].length) {
					if (loc['l1_status'] == 'selected') {
						summary.l1.selected += 1;
						summary.l1.total += 1;
					} else {
						summary.l1.rejected += 1;
						summary.l1.total += 1;
					}
				}
				if (loc && loc['l2_status'] && loc['l2_status'].length) {
					if (loc['l2_status'] == 'selected') {
						summary.l2.selected += 1;
						summary.l2.total += 1;
					} else {
						summary.l2.rejected += 1;
						summary.l2.total += 1;
					}
				}
				if (loc && loc['client_interview'] && loc['client_interview'].length) {
					if (loc['client_interview'] == 'selected') {
						summary.client.selected += 1;
						summary.client.total += 1;
					} else {
						summary.client.rejected += 1;
						summary.client.total += 1;
					}
				}
			}
		}
		this.ops_summary = summary;
	}

	getStatusColorCode(val: string) {
		// console.log('printing color status', val);
		let color = '#174a84';
		switch (val) {
			case 'selected':
			case 'uploaded':
				color = '#44be20';
				break;
			case 'rejected':
				color = '#e5293e';
				break;
			default:
				color = '#a129e5';
				break;
		}
		return color;
	}

	openDocUPload(e?, data?, doc_type?) {
		// alert('doc upload initiated');
		var dailogConfig = new MatDialogConfig();
		if (data) {
			data.selected_doc_type = doc_type
		}
		dailogConfig.width = '40%';
		dailogConfig.autoFocus = true;
		dailogConfig.data = data;
		this.dialog.open(DocUploadComponent, dailogConfig);
	}

	openDocView(event, obj, key) {
	//	console.log('doc view', obj, key);
		var dailogConfig = new MatDialogConfig();
		dailogConfig.width = '40%';
		dailogConfig.autoFocus = true;
		dailogConfig.data = obj;
		dailogConfig.data.key = key;
		this.dialog.open(DocViewComponent, dailogConfig);
	}
	submitFinalStatus(event, obj) {
		//console.log('opened the final submit view', obj);
		var dailogConfig = new MatDialogConfig();
		dailogConfig.width = '600px';
		dailogConfig.height = '85%';
		dailogConfig.autoFocus = true;
		dailogConfig.data = obj;
		const dialogRef = this.dialog.open(DocStatusComponent, dailogConfig);

		dialogRef.afterClosed().subscribe(result => {
			//debugger;
			if (result == "true") {
				this.refreshData();
			}
			console.log('The dialog was closed');
		});



	}
	check_color_code(val: string) {
		let result = 'Inherit';
		try {
			switch (val) {
				case 'Verified':
					result = 'green';
					break;
				case 'Rejected':
					result = 'red';
					break;
				default:
					result = 'Inherit';
					break;
			}
		} catch (e) {

		}
		return result;
	}

	extractDataObject(ref, obj) {
		let arr = [];
		for (var i in ref) {
			if (obj.hasOwnProperty(ref[i])) {
				arr.push(obj[ref[i]])
			} else {
				arr.push('')
			}
		}
		return arr;
	}

	exportExcel() {
		var data_mapper = {
			'sl_no': 'No',
			'applicant_name': 'Name',
			'applicant_number': 'Phone',
			'reg_date': 'Reg Date',
			'assessment_score': 'Score',
			'l1_status': 'L1 Interview',
			'l2_status': 'L2 Interview',
			'client_interview': 'Client Interview',
			'doc_status': 'Document Status ',
		}
		var data = this.dataSource.data;
		var headers = Object.values(data_mapper);
		// console.log('data and headers', data, headers);
		// return;
		if (data && data.length) {
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet('Candidate Data');
			worksheet.addRow(headers);
			data.forEach(d => {
				let row = worksheet.addRow(this.extractDataObject(Object.keys(data_mapper), d));
				//	console.log('printing row', row);
			})
			//Generate & Save Excel File
			workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, 'Onboarding-list.xlsx');
			})
		}
	}

	change_date(key: string, event: MatDatepickerInputEvent<Date>) {
		if (key == 'start_date') {
			this.startDateFilter = this.utils.getStartOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.startDateFilter).diff(moment(this.endDateFilter)) >= 0) {
				this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
				//this.endDateFilter = "";
				//alert("Start date should be less than end date");
				//return;
			}
		} else {
			this.endDateFilter = this.utils.getEndOf('day', 'YYYY-MM-DDTHH:mm:ss', false, event.value);
			if (moment(this.startDateFilter).diff(moment(this.endDateFilter)) >= 0) {
				//	this.endDateFilter = "";
				this.endDateFilter = undefined;
				this.fromInput.value = '';
				//alert("Start date should be less than end date");
				this.showHideDateCondition = true;
				return;
			} else {
				this.showHideDateCondition = false;
			}

		}
	}

	printwindow() {
		window.print();
	}
}
